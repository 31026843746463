import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function CollectionBlock() {

  
  const [inView, setInView] = useState(false);
  const collectionRefs = useRef([]);

  const collectionData = [
    {
        collectionName: "My Demons",
        collectionTitle: "My Demons",
        collectionDescription: "Our My Demons Collections  is a collection that our shop is proud to present. This is a collection of accessories that have a luxurious beauty. decorated with diamonds that makes it look comfortable to the person who wears. Our My Demons Collections got lots of love from you, so we made lots of them for you. Taste the proverbial Discover Now.",
        collectionLink : "xyz",
        collectionImage : "https://s3-alpha-sig.figma.com/img/1cbf/9185/9b0ff125a52716088749a1b8e89034cd?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dby2ss0Ccb1DYXb6hSKAQj6EUCeHA9Pq1oKxHweu5tm8ig7j4udhh~Z6vNmRgxmR0WGMsHb6Le6TeMqZ~W6gUPGDdEypFLTz5BWeCLnC1CFU4D~lafNTIsk3DU7o6dyOcJy77qTlnWgWabdWTE6-ALEIil-DI7cYiDMSRuUVqvKjNHR70KceGNgYLed2w4KcNGkecFVgRJartGmxV4kHTE0ZTQPNjeeFTtyRnmMFGp2b6C3y1TnU1E~IssNxXNhY48HgMMJ8Sfzw6QOiE3~DMkrPkokPLbX8PHEyy5q~x2jTD15mvKMK2KSJO0ga0sxkGmkyM1ESEgHDzi0cYs-sWw__"
    },
    {
      collectionName: "Meet me in forget me not",
      collectionTitle: "New Luxury",
      collectionDescription: "New designs for the new collections “Forget me not” inspired by nostalgia daydreams and our never forget and never-ending love for mix of unique materials and luxury of design and color. Now ready to sparkle with you Discover now.",
      collectionLink : "xyz",
      collectionImage : "https://s3-alpha-sig.figma.com/img/14d9/daef/4ccd091e6c580a91672fcef95ccd7113?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=fPgfe8Od-mde4mimdmN76~~8fE1hjumnnBU1y31VgAUuJqVpm-Q8EBSGSKd5H6-aXphhDg1mwATNDLiD~NCOT2dLdjL1ajo6dDH423islaGcPPP21So~WcQXJDxC07B3Da0TykpmILQJH12m~OV9mW9LkzjS6wqIgzWo671IR46izKQcfRUhwyiBMMFOp5t-ZtSbkn8WJsu5owNEd8GjT3KVIgIFwyJPpYEmnmhatI5JeR79J7NiMJ3y8Iz0-apwW5u6Zuim3-8N13K7UjTGTDbvGir1WlNAJU92XWgmVEf4pD0fGPmEpaeeaIIbRL~W7i~nxBMaovocuLSBdEE9hw__"
  }
]

const {t} = useTranslation()

useEffect(() => {
  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-in'); // Trigger animation
          observer.unobserve(entry.target); // Stop observing after animation starts
        }
      });
    },
    { threshold: 0.5 }
  );

  // Ensure that collectionRefs.current is always an array and all elements are valid before observing them
  collectionRefs.current.forEach((block) => {
    if (block && block instanceof HTMLElement) {
      observer.observe(block); // Start observing each collection block
    }
  });

  return () => {
    collectionRefs.current.forEach((block) => {
      if (block && block instanceof HTMLElement) {
        observer.unobserve(block); // Ensure it's a valid HTMLElement before unobserving
      }
    });
  };
}, []);

  return (
    // <div className='collectionblock'>
    //   <div className='collectionblock-inner'>
    //     {collectionData.length ? collectionData.map((result, key)=>{
    //       return(
    //         <div className={`collectionblock-inner-block ${key%2 == 0 ? "left" : "right"}`} id={key}>
    //           <div className='collectionblock-inner-block-imagesection'>
    //             <img src={result?.collectionImage}/>
    //             <span className='collectionblock-inner-block-imagesection-titletag'>{result?.collectionTitle}</span>
    //           </div>
    //           <div className='collectionblock-inner-block-container'>
    //             <div className='collectionblock-inner-block-container-content'>
    //               <div className='collectionblock-inner-block-container-content-header'>{result?.collectionName}</div>
    //               <div className='collectionblock-inner-block-container-content-description'>
    //                 {result?.collectionDescription}
    //               </div>
    //               <button>{t("Discover")}</button>
    //             </div>
    //           </div>
    //         </div>
    //       )
    //     }) : null}
    //     <div></div>
    //   </div>
    // </div>
    <div className="collectionblock">
    <div className="collectionblock-inner">
      {collectionData.length
        ? collectionData.map((result, key) => {
            return (
              <div
                key={key}
                ref={(el) => (collectionRefs.current[key] = el)} // Reference each collection block
                className={`collectionblock-inner-block ${key % 2 === 0 ? 'left' : 'right'}`}
              >
                <div className="collectionblock-inner-block-imagesection">
                  <img src={result.collectionImage} alt={result.collectionName} />
                  <span className="collectionblock-inner-block-imagesection-titletag">
                    {result.collectionTitle}
                  </span>
                </div>
                <div className="collectionblock-inner-block-container">
                  <div className="collectionblock-inner-block-container-content">
                    <div className="collectionblock-inner-block-container-content-header">
                      {result.collectionName}
                    </div>
                    <div className="collectionblock-inner-block-container-content-description">
                      {result.collectionDescription}
                    </div>
                    <button className='discoverBtn'>{t('Discover')}</button>
                  </div>
                </div>
              </div>
            );
          })
        : 
       null
        }
    </div>
  </div>
  )
}

