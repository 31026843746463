import React from 'react'

export default function EditIcon(props) {
    const { height, width, colors } = props;
    return (
        <svg width={width ? width : "24"} height={height ? height : "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3289_394564)">
                <path d="M20.5 19.5H3.5V4.5H12V3.5H2.5V20.5H21.5V11H20.5V19.5Z" fill={colors ? colors : "black"} />
                <path d="M10.5 9.79297V13.5H14.207L21.707 5.99997L18 2.29297L10.5 9.79297ZM13.793 12.5H11.5V10.207L18 3.70697L20.293 5.99997L13.793 12.5Z" fill={colors ? colors : "black"} />
            </g>
            <defs>
                <clipPath id="clip0_3289_394564">
                    <rect width={width ? width : "24"} height={height ? height : "24"} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
