import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import MessageIcon from '../assets/icons/MessageIcon'
import CrossIcon from '../assets/icons/CrossIcon'
import { _Apiauth } from "../../common/api";

const OtpModal = (props) => {
    const modalRef = useRef(null);
    const { t } = useTranslation();
    const [errormsg, setErrormsg] = useState('')
    const { emailverifydata, setResandmessage, setResandtoken } = props
    const resandemail = async () => {
        try {
            let postdata = {
                "accessToken": emailverifydata?.accessToken
            }
            let res = await _Apiauth(postdata, `auth/resend-verify-user`)
            if (res?.statusCode === 200) {
                if (modalRef.current) {
                    modalRef.current.hide();
                }
                setResandtoken(res?.accessToken)
                setResandmessage(res?.message)
            } else {
                setErrormsg(res?.message)
            }
        }
        catch (err) {
            console.log(err, "err")
            if (err?.response?.status) {
                setErrormsg(err.response.data.message)
            }
        }
    }
    useEffect(() => {
        modalRef.current = new window.bootstrap.Modal(document.getElementById('emailotpModal'));
    }, []);
    return (
        <div
            className="modal fade common-modal otp-modalMain"
            id="emailotpModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            data-bs-backdrop="static"
        >
            <div className="modal-dialog">
                <div className="modal-content otp-modal-contianer">
                    <button className="modalCross-btn" data-bs-toggle="modal" data-bs-target="#emailotpModal"><CrossIcon width={17.41} height={17.41} /></button>
                    <div className="modal-body p-0">
                        <div className="otp-modal-contianer-msgImgBox">
                            <MessageIcon width={38.33} height={30.67} />
                        </div>
                        <h3 className="otp-modal-contianer-headerBox">
                            {t("Resend verification code")}
                        </h3>
                        <p className="otp-modal-contianer-paraBox">{t("We have just sent an email with a new verification code to ")}<b>{emailverifydata?.email}</b></p>
                    </div>
                    <div className="text-center mt-3 text-danger">{t(`${errormsg}`)}</div>
                    <div className="modal-footer otp-modal-contianer-footerBox">
                        <button
                            type="button"
                            className="btnwithborder"
                            data-bs-dismiss="modal"
                        >
                            <span>{t("Got it")}</span>
                        </button>
                        <button className="btnbold" onClick={() => resandemail()}>
                           <span>{t("Send again")}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default OtpModal;
