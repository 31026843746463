import currencyFormatter from "currency-formatter";
export const toFormatPrice = (amount, args = {}) => {
    const OrgSettings1 = localStorage.getItem("formatefunction");
    let OrgSettings = JSON.parse(OrgSettings1);
    var price_format_options = OrgSettings;
    var currency = price_format_options?.currency;
    var decimal_point = price_format_options?.decimal_point || 2;
    var thousand_separator = price_format_options?.thousand_separator || ",";
    var decimal_separator = '.';

    if (thousand_separator.search(".")) {
        decimal_separator = ",";
    }
    var options = {
        code: currency,
        decimal: decimal_separator,
        thousand: thousand_separator,
        precision: decimal_point,
        format: '%v'
    }
    if (args.addSymbol) {
        options.format = '%s %v';
    }
    if (currency === "AED") {
        options.format = '%v %s';
        options.symbol = "AED";
    }
    amount = currencyFormatter.format(amount, options);

    return amount;
}