import React from 'react'

const EditIcon = (props) => {
    const { height, width, colors, type } = props;
    return (
        <>
        {type === 'pencil' ? 
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.4999 16.293H16.5M3.5 16.293L7.13832 15.5599C7.33147 15.521 7.50882 15.4259 7.6481 15.2865L15.7928 7.13734C16.1833 6.74663 16.1831 6.11331 15.7922 5.72292L14.0669 3.99952C13.6762 3.6093 13.0432 3.60957 12.6529 4.00012L4.5073 12.1502C4.36829 12.2892 4.27337 12.4662 4.23442 12.659L3.5 16.293Z" stroke="#0088FF" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        :
        <svg width={width ? width : "24"} height={height ? height : "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3289_394564)">
                <path d="M20.5 19.5H3.5V4.5H12V3.5H2.5V20.5H21.5V11H20.5V19.5Z" fill={colors ? colors : "black"} />
                <path d="M10.5 9.79297V13.5H14.207L21.707 5.99997L18 2.29297L10.5 9.79297ZM13.793 12.5H11.5V10.207L18 3.70697L20.293 5.99997L13.793 12.5Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_3289_394564">
                    <rect width={width ? width : "24"} height={height ? height : "24"} fill="white" />
                </clipPath>
            </defs>
        </svg>
        }
        </>
    )
}

export default EditIcon