import React from 'react'

export default function ConfirmationPopup({setConfirmationResponse, modalRef}) {
  return (
    <div
    className="modal fade confirmpopop"
    id="confirmationpopup"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="confirmationPopup"
    ref={modalRef}
    >
        <div className="modal-dialog modal-dialog-centered confirmpopop-inner" role="document">
            <div className={`modal-content confirmpopop-inner-section`}>
                <div className='confirmpopop-inner-section-title'>Confirm</div>
                <div className='confirmpopop-inner-section-subtitle'>You want to change status?</div>
                <div className='confirmpopop-inner-section-footer'>
                    <button className='cancel' onClick={()=>setConfirmationResponse('cancel')}>Cancel</button>
                    <button className='confirm' onClick={()=>setConfirmationResponse('confirm')}>Confirm</button>
                </div>
            </div>
        </div>
    </div>
  )
}