import React from 'react'

const HeardFillIcon = ({height, color, width}) => {
   
    return (
        <svg width={width ? width : "20"} height={height ? height : "18"} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.222 10.5L10 18L17.778 10.5C19.128 9.4835 20 7.872 20 6.0555C20 2.989 17.511 0.5 14.4445 0.5C12.628 0.5 11.011 1.378 10 2.728C8.989 1.378 7.372 0.5 5.5555 0.5C2.489 0.5 0 2.989 0 6.0555C0 7.872 0.872 9.4835 2.222 10.5Z" fill={color ? color : "#5CC7C0"}/>
        </svg>

    )
}

export default HeardFillIcon