import React, { useEffect, useState } from 'react'
import PlusIcon from '../../assets/icons/PlusIcon'
import DeleteIcon from '../../assets/icons/DeleteIcon';
import { _Api } from '../../../common/api';
import AccountSidebar from './AccountSidebar';
import { Link } from 'react-router-dom';
import EditIcon from '../../assets/icons/EditIcon';
import { useTranslation } from "react-i18next";
const MyAccountAddress = () => {
    const [addressdata, setAddressdata] = useState()
    const { t } = useTranslation();
    const getaddress = async () => {
        try {
            let postdata = {}
            let res = await _Api(postdata, `account/getAddresses`)
            if (res?.statusCode === 200) {
                setAddressdata(res?.data)
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    useEffect(() => {
        getaddress()
    }, [])
    const deleteaddress = async (id, billing_address) => {
        try {
            let postdata = {
                "id": id,
                "billing_address": billing_address ? billing_address : false
            }
            let res = await _Api(postdata, `account/deleteAddress`)
            if (res?.statusCode === 200) {
                getaddress()
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    return (
        <div className='accountDetailMainPage d-flex ecommerce-main-layout-bodyContent'>
            <AccountSidebar />
            <div className="account-details">
                <div className='account-address-container'>
                    <div className='account-com-heading account-address-container-header'>{t("My Address")}</div>
                    <div className='account-address-container-mainBody'>
                        <div className='account-address-container-mainBody-firstBox'>
                            <div className='account-address-container-mainBody-firstBox-shippingAddr '>
                                <span className='account-com-subHeading'>{t("Shipping Address")}</span>
                                <Link className='text-decoration-none'
                                    state={{
                                        id: "",
                                        billing_address: false,
                                        address: "Shipping Address"
                                    }}
                                    to="/account/addressdetails">
                                    <div className='account-address-container-mainBody-firstBox-shippingAddr-addNew'> <span className='addIcon'><PlusIcon width={16} height={16} /></span><p>{t("Add New Address")}</p></div>
                                </Link>

                            </div>
                            {addressdata?.shipping_address?.length ? addressdata?.shipping_address.map((result, key) => {
                                return (
                                    <div className='account-address-container-mainBody-firstBox-addressBox com-addressShowBox' key={key}>
                                        <div className='com-addressShowBox-headerBox'>
                                            <h3 className='com-addressShowBox-headerBox-heading'>{result?.name}</h3>
                                            <div className='com-addressShowBox-headerBox-iconBox'>
                                                <Link className="text-decoration-none" state={{
                                                    id: result?.UDID,
                                                    billing_address: false,
                                                    address: "Shipping Address"
                                                }} to="/account/addressdetails">
                                                    <span>   <EditIcon
                                                        width={22}
                                                        height={22}
                                                    /> </span>
                                                </Link>
                                                {!result?.is_default ?
                                                    <div className="pointer" onClick={() => deleteaddress(result?.UDID, false)}><DeleteIcon width={18} height={18} color={'#DA1414'} /></div>
                                                    : ""}
                                            </div>
                                        </div>
                                        <p className='com-addressShowBox-AddrparaBox'>{result?.completeAddress}</p>
                                        <p className='com-addressShowBox-phonePara'>{result?.phone} </p>
                                        {result?.is_default ?
                                            <button className='com-addressShowBox-defaultAddrBtn'>{t("Default Shipping Address")}</button> : ""}

                                    </div>
                                )
                            }) : ""}

                        </div>
                        <div className='account-address-container-mainBody-firstBox'>
                            <div className='account-address-container-mainBody-firstBox-shippingAddr '>
                                <span className='account-com-subHeading'>{t("Billing Address")}</span>
                                <Link className="text-decoration-none" to="/account/addressdetails"
                                    state={{
                                        id: "",
                                        billing_address: true,
                                        address: "Billing Address"
                                    }}>
                                    <div className='account-address-container-mainBody-firstBox-shippingAddr-addNew'> <span className='addIcon'><PlusIcon width={16} height={16} /></span><p>{t("Add New Address")}</p></div>
                                </Link>
                            </div>
                            {addressdata?.billing_address?.length ? addressdata?.billing_address.map((result, key) => {
                                return (
                                    <div className='account-address-container-mainBody-firstBox-addressBox com-addressShowBox'>
                                        <div className='com-addressShowBox-headerBox'>
                                            <h3 className='com-addressShowBox-headerBox-heading'>{result?.name}</h3>
                                            <div className='com-addressShowBox-headerBox-iconBox'>
                                                <Link className="text-decoration-none" to="/account/addressdetails"
                                                    state={{
                                                        id: result?.UDID,
                                                        billing_address: true,
                                                        address: "Billing Address"
                                                    }}>
                                                    <span>
                                                        <EditIcon
                                                            width={22}
                                                            height={22}
                                                        />
                                                    </span>
                                                </Link>
                                                {!result?.is_default ?
                                                    <div className="pointer" onClick={() => deleteaddress(result?.UDID, true)}><DeleteIcon color={'#DA1414'} /></div>
                                                    : ""}
                                            </div>
                                        </div>
                                        <p className='com-addressShowBox-AddrparaBox'>{result?.completeAddress}</p>
                                        <p className='com-addressShowBox-phonePara'>{result?.phone}</p>
                                        {result?.is_default ?
                                            <button className='com-addressShowBox-defaultAddrBtn' >{t("Default Billing Address")}</button>

                                            : ""}
                                    </div>
                                )
                            }) : ""}
                        </div>
                    </div>

                </div>
            </div >
        </div >
    )
}

export default MyAccountAddress