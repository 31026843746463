import React, { useCallback, useEffect, useState } from 'react'
import './Common.scss'
import RightArrow from '../../../../admin/common/icons/right-arrow'
import SearchIcon from '../../../../admin/common/icons/SearchIcon'
import CloseIcon from '../../../../admin/common/icons/CloseIcon'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { _Api } from '../../../common/api'
import CrossIcon from '../../assets/icons/CrossIcon'
import UserIcon from '../../../../admin/common/icons/UserIcon'
import AppoinmentIcon from '../../assets/icons/AppoinmentIcon'
import ChangePasswordIcon from '../../assets/icons/ChangePasswordIcon'
import SignupBackImg from "../../assets/icons/SignUpBackIcon";
import PurchaseChart from "../../assets/icons/CartIcon";
import CartIcon from '../../assets/icons/CartIcon'
import LikeNotific from "../../assets/images/LikeIcon.png";
import HomeIcon from '../../assets/icons/HomeIcon'
import HeartIcon from '../../../../admin/common/icons/HeartIcon'
import Shoppingbag from '../../../../admin/common/icons/Shoppingbag'
import SignUpBackIcon from '../../assets/icons/SignUpBackIcon'

export default function CommonDropdown({type, openmodal, setOpenmodal, handleCloseCommonPopup}) {
    const {i18n,t} = useTranslation();
    let cartdata = JSON.parse(localStorage.getItem("CartItems"));
    const [LanguageList, setLanguageData] = useState(null);
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    const [CurrentLan_flg, setLanguageSelectData] = useState({ Lan_photo: 'US.png', lang: "english" })
    const accessToken = localStorage.getItem("ecomaccesstoken");
    const [searchlist, setSearchlist] = useState([]);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [wishlistcounts, setWishlistcounts] = useState();
    const [languageDropdown, setLanguageDrodown] = useState(false);
    const [menubar, setMenubar] = useState(false);

    useEffect(()=>{
        languagedata()
    },[])

    const headerSearchToggle = () => {
        setIsSearchOpen(!isSearchOpen);
    };

    const rtlView = useCallback((type, l) => {
        var jsonData = JSON.stringify(l);
        localStorage.setItem("UserLangecom", jsonData);
        setLanguageSelectData(l)
        let bodyid = document.querySelector("#body_id");
        if (type === "rtl") {
            bodyid.classList.add("rtl");
        } else {
            bodyid.classList.remove("rtl");
        }
    }, [setLanguageSelectData]);
    
    const alllogindata = (keyName, value) => {
        setOpenmodal((prevState) => ({
            ...prevState,
            [keyName]: value,
        }));
    };
    const searchdropdonwn = async (event) => {
        try {
            let postdata = {
                search: event,
            };
            let res = await _Api(postdata, `product/search`);
            if (res?.statusCode === 200) {
                setSearchlist(res?.data);
            }
        } catch (err) {
            console.log(err, "err");
        }
    };
    const languagedata = async () => {
        try {
            let postdata = {};
            let res = await _Api(postdata, `language`);
            if (res?.statusCode === 200) {
                setLanguageData(res?.data);
            }
        } catch (err) {
            console.log(err, "err");
        }
    };
  return (
    <div className='commonDropdown'>
        <div className='commonDropdown-inner'>
            <span className='commonDropdown-inner-back' onClick={handleCloseCommonPopup}>
                <RightArrow/>
            </span>
            { type === 'Search' ? 

            //search 

            <div className='commonDropdown-inner-searchsection'>
            
            <div className='commonDropdown-inner-header'>
                {t("Search")}
            </div>
            <div className='commonDropdown-inner-searchsection-bar'>
                <span className='commonDropdown-inner-searchsection-bar-icon left'><SearchIcon/></span>
                <input type='text' className='commonDropdown-inner-searchsection-bar-input' placeholder='Search products...' onChange={(e) => searchdropdonwn(e.target.value)}/>
                <span className='commonDropdown-inner-searchsection-bar-icon right' onClick={handleCloseCommonPopup}><CloseIcon/></span>
            </div>
            <div className='commonDropdown-inner-searchsection-suggestion'>
                <div className='commonDropdown-inner-searchsection-suggestion-item'>
                    {t("Bracelate")}
                </div>
            </div>
            <div className='commonDropdown-inner-header'>
                {t("Suggested")}
            </div>
            <div className='commonDropdown-inner-searchsection-list'>
                <div className='commonDropdown-inner-searchsection-list-item'>
                {searchlist?.length ? 
                    searchlist.map((result, key) => {
                        return (
                            <Link
                                className="commonDropdown-inner-searchsection-list-item-detail"
                                to={`product/${result?.Slug}`}
                                state={{ id: result?._id }}
                                key={key}
                            >
                                <div className='imageWrapper'>
                                <img className="" src={result?.main_image} alt={result?.name} />
                                </div>
                                <div className='details'>
                                    <p className="p-0 m-0" >
                                        {result?.name}
                                    </p>
                                    <p className="p-0 m-0" >
                                        {result?.item_name ?? "Ring"} ||{" "}
                                        {result?.SKU}{" "}
                                    </p>
                                </div>
                            </Link>
                                )})
                :  <div className='commonDropdown-inner-searchsection-list-item-details'><span><SearchIcon/></span><span>{t("No suggetions yet !")}</span></div> }
                   
                </div>
            </div>
            </div>
            :

            //menubar
            <div className='commonDropdown-inner-menubar'>
                <div className='commonDropdown-inner-header'>
                    {t("Menu")}
                </div>
                <div className='commonDropdown-inner-menubar-list'>
                    <Link className='commonDropdown-inner-menubar-list-menuitem'>
                        <span className='commonDropdown-inner-menubar-list-menuitem-iconholder'>
                            <HomeIcon/>
                        </span>
                        <span className='commonDropdown-inner-menubar-list-menuitem-textholder'>
                            {t("Home")}
                        </span>
                    </Link>
                    <Link
                        className="commonDropdown-inner-menubar-list-menuitem"
                        type="submit"
                        to="/wishlist"
                    >
                        <div className='commonDropdown-inner-menubar-list-menuitem-iconwithcount'>
                            <span className='commonDropdown-inner-menubar-list-menuitem-iconholder'>
                                <HeartIcon/>
                            </span>
                        {/* <img
                            src={LikeNotific}
                            className="rightSideContentBox-btnBox-imgShow"
                            alt=""
                        /> */}
                        {wishlistcounts ?
                            <span className="product-count">
                                {wishlistcounts ? wishlistcounts : 0}
                            </span> : ""}
                        </div>
                        <span className='commonDropdown-inner-menubar-list-menuitem-textholder'>{t("Wishlist")}</span>
                    </Link>
                    <Link
                        to="/shoppingcart"
                        className="commonDropdown-inner-menubar-list-menuitem"
                        type="submit"
                    >
                        <div className='commonDropdown-inner-menubar-list-menuitem-iconwithcount'>
                            <span className='commonDropdown-inner-menubar-list-menuitem-iconholder'><Shoppingbag/></span>
                            {/* <img
                                src={CartIcon}
                                className="rightSideContentBox-btnBox-imgShow"
                                alt=""
                            /> */}
                            {cartdata?.length ?
                                <span className="product-count">
                                    {cartdata?.length ? cartdata?.length : 0}
                                </span> : ""}
                        </div>
                            <span className='commonDropdown-inner-menubar-list-menuitem-textholder'>{t("Cart")}</span>
                    </Link>
                    {accessToken ? 
                    <>
                     <Link
                     className="commonDropdown-inner-menubar-list-menuitem"
                     to="/account"
                    >
                         <span className='commonDropdown-inner-menubar-list-menuitem-iconholder filled'>
                            <UserIcon/>
                         </span>
                         <span className='commonDropdown-inner-menubar-list-menuitem-textholder'>{t("My Account")}</span>
                    </Link>
                    <Link
                    to="/logout"
                    className="commonDropdown-inner-menubar-list-menuitem">
                        <span className="commonDropdown-inner-menubar-list-menuitem-iconholder">
                              <SignUpBackIcon/>
                        </span>
                        <span className='commonDropdown-inner-menubar-list-menuitem-textholder'>{t("Sign Out")}</span>
                    </Link>
                    </>
                    :
                    <Link
                    className="commonDropdown-inner-menubar-list-menuitem"
                    onClick={()=>alllogindata("showLoginModel", true)}
                    >
                        <span className='commonDropdown-inner-menubar-list-menuitem-iconholder filled'>
                           <UserIcon/>
                        </span>
                        <span className='commonDropdown-inner-menubar-list-menuitem-textholder'>{t("Login")}</span>
                    </Link>
                    }
                   
                    <div className="commonDropdown-inner-menubar-list-menuitem dropdown rounded-0  shadow-none list-unstyled">
                        <button
                            className="dropdown-toggle border-0 bg-transparent rightSideContentBox-languagedropDown-mainLangToggle showArrowDropdown mt-2"
                            type="button"
                            id="languagedropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded={!languageDropdown}
                            onClick={() => setLanguageDrodown(!languageDropdown)}>
                            <div className="d-flex gap-3">
                                <img
                                    style={{
                                        width: "24px",
                                        height: "24px"
                                    }}
                                    className="rounded-0 shadow-lg"
                                    src={base_assets + 'images/flags/' + CurrentLan_flg?.Lan_photo}
                                    alt="icon"
                                />
                                <p className="language-showSelctLang">{CurrentLan_flg?.lang}</p>
                            </div>
                            <div className={languageDropdown ? "rotateImg" : ""}>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#000"
                                    xmlns="http://www.w3.org/2000/svg"

                                >
                                    <path
                                        d="M9 18L15 12L9 6"
                                        stroke="#000"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                        </button>
                        <ul
                            className="dropdown-menu dropdown-menu-end"
                            aria-labelledby="languagedropdown"
                        >
                            {LanguageList?.map((LanguageCode, key) => (
                                <li key={key}>
                                    {LanguageCode.Lan_value === "arabic" ? (
                                        <Link
                                            className="dropdown-item"
                                            onClick={() => {
                                                i18n.changeLanguage(LanguageCode.Lan_value);
                                                rtlView("rtl", {
                                                    Lan_photo: LanguageCode?.Lan_photo,
                                                    lang: LanguageCode?.Lan_value
                                                });
                                                setMenubar(false)
                                            }}
                                            data-bs-toggle="collapse"
                                            data-bs-target="#navbarSupportedContent"
                                        >
                                            <img
                                                src={
                                                    base_assets +
                                                    "images/flags/" +
                                                    LanguageCode.Lan_photo
                                                }
                                                alt=""
                                            />{" "}
                                            <span>{LanguageCode.Lan_text}</span>
                                        </Link>
                                    ) : (
                                        <Link
                                            className="dropdown-item"
                                            onClick={() => {
                                                i18n.changeLanguage(LanguageCode.Lan_value);
                                                rtlView("ltr",
                                                    {
                                                        Lan_photo: LanguageCode?.Lan_photo,
                                                        lang: LanguageCode?.Lan_value
                                                    });
                                                setMenubar(false)
                                            }}
                                            data-bs-toggle="collapse"
                                            data-bs-target="#navbarSupportedContent"
                                        >
                                            <img
                                                style={{
                                                    width: "25px",
                                                    height: "100%",
                                                }}
                                                src={
                                                    base_assets +
                                                    "images/flags/" +
                                                    LanguageCode.Lan_photo
                                                }
                                                alt=""
                                            />{" "}
                                            <span>{LanguageCode.Lan_text}</span>
                                        </Link>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            {/* <div className="rightSideContentBox3">
           
            {accessToken ? (
                <div className="dropdown rightSideContentBox-accountDropBox userAccountBoxMain">
                    <button
                        className="com-transBtn rightSideContentBox-btnBox dropdown-toggle"
                        id="headeraccountdropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <span>
                            <UserIcon />
                        </span>
                    </button>
                    <ul
                        className="dropdown-menu rightSideContentBox-accountDropBox-listBox"
                        aria-labelledby="headeraccountdropdown"
                    >
                        {accessToken ? (
                            <li>
                                <Link
                                    className="dropdown-item rightSideContentBox-accountDropBox-listBox-SingleList"
                                    to="/account"
                                >
                                    <span className="rightSideContentBox-accountDropBox-listBox-SingleList-imgBox">
                                        <span>
                                            <UserIcon />
                                        </span>
                                    </span>
                                    {t("My Account")}
                                </Link>
                            </li>
                        ) : (
                            <Link
                                className="dropdown-item rightSideContentBox-accountDropBox-listBox-SingleList"
                                to="#"
                            >
                                <span className="rightSideContentBox-accountDropBox-listBox-SingleList-imgBox">
                                    <span>
                                        <UserIcon />
                                    </span>
                                </span>
                                {t("My Account")}
                            </Link>
                        )}

                        <li>
                            <Link
                                className="dropdown-item rightSideContentBox-accountDropBox-listBox-SingleList"
                                to="/account/purchasehistory"
                            >
                                <span className="rightSideContentBox-accountDropBox-listBox-SingleList-imgBox">
                                    <span>
                                        <PurchaseChart />
                                    </span>
                                </span>
                                {t("Purchase History")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="dropdown-item rightSideContentBox-accountDropBox-listBox-SingleList"
                                to="/account/appointmentdata"
                            >
                                <span className="rightSideContentBox-accountDropBox-listBox-SingleList-imgBox">
                                    <span>
                                        <AppoinmentIcon />
                                    </span>
                                </span>
                                {t("Appointment")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="dropdown-item rightSideContentBox-accountDropBox-listBox-SingleList"
                                to="/account/changepassword"
                            >
                                <span className="rightSideContentBox-accountDropBox-listBox-SingleList-imgBox">
                                    <span>
                                        <ChangePasswordIcon />
                                    </span>
                                </span>
                                {t("Change Password")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/logout"
                                className="dropdown-item rightSideContentBox-accountDropBox-listBox-SingleList p-0"
                            >
                                <div className="rightSideContentBox-accountDropBox-listBox-SingleList-SingUpBtn ">
                                    <span className="rightSideContentBox-accountDropBox-listBox-SingleList-imgBox">
                                        <span>
                                            <SignupBackImg />
                                        </span>
                                    </span>
                                    {t("Sign Out")}
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            ) : (
                <button
                    className="com-transBtn rightSideContentBox-btnBox user-profile-button"
                    onClick={() => alllogindata("showLoginModel", true)}
                >
                    <span>
                        <UserIcon />
                    </span>
                </button>
            )}

            <Link
                className="com-transBtn rightSideContentBox-btnBox wishlistTabBtnShow mt-1"
                type="submit"
                to="/wishlist"
            >
                <img
                    src={LikeNotific}
                    className="rightSideContentBox-btnBox-imgShow"
                    alt=""
                />
                {wishlistcounts ?
                    <span className="product-count">
                        {wishlistcounts ? wishlistcounts : 0}
                    </span> : ""}

            </Link>

            <Link
                to="/shoppingcart"
                className="com-transBtn rightSideContentBox-btnBox cartTabIconhide mt-1"
                type="submit"
            >
                <img
                    src={CartIcon}
                    className="rightSideContentBox-btnBox-imgShow"
                    alt=""
                />
                {cartdata?.length ?
                    <span className="product-count">
                        {cartdata?.length ? cartdata?.length : 0}
                    </span> : ""}
            </Link>
            <li className="rightSideContentBox-languagedropDown dropdown rounded-0  shadow-none list-unstyled">
                <button
                    className="dropdown-toggle border-0 bg-transparent rightSideContentBox-languagedropDown-mainLangToggle showArrowDropdown mt-2"
                    type="button"
                    id="languagedropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded={!languageDropdown}
                    onClick={() => setLanguageDrodown(!languageDropdown)}>
                    <div className="d-flex gap-2">
                        <img
                            style={{
                                width: "24px",
                                height: "24px"
                            }}
                            className="rounded-0 shadow-lg"
                            src={base_assets + 'images/flags/' + CurrentLan_flg?.Lan_photo}
                            alt="icon"
                        />
                        <p className="language-showSelctLang">{CurrentLan_flg?.lang}</p>
                    </div>
                    <div className={languageDropdown ? "rotateImg" : ""}>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#000"
                            xmlns="http://www.w3.org/2000/svg"

                        >
                            <path
                                d="M9 18L15 12L9 6"
                                stroke="#000"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                </button>
                <ul
                    className="dropdown-menu dropdown-menu-end rightSideContentBox-languagedropDown-langListBox"
                    aria-labelledby="languagedropdown"
                >
                    {LanguageList?.map((LanguageCode, key) => (
                        <li key={key}>
                            {LanguageCode.Lan_value === "arabic" ? (
                                <Link
                                    className="dropdown-item rightSideContentBox-languagedropDown-langListBox-langSingleList"
                                    onClick={() => {
                                        i18n.changeLanguage(LanguageCode.Lan_value);
                                        rtlView("rtl", {
                                            Lan_photo: LanguageCode?.Lan_photo,
                                            lang: LanguageCode?.Lan_value
                                        });
                                        setMenubar(false)
                                    }}
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent"
                                >
                                    <img
                                        src={
                                            base_assets +
                                            "images/flags/" +
                                            LanguageCode.Lan_photo
                                        }
                                        alt=""
                                    />{" "}
                                    <span>{LanguageCode.Lan_text}</span>
                                </Link>
                            ) : (
                                <Link
                                    className="dropdown-item rightSideContentBox-languagedropDown-langListBox-langSingleList"
                                    onClick={() => {
                                        i18n.changeLanguage(LanguageCode.Lan_value);
                                        rtlView("ltr",
                                            {
                                                Lan_photo: LanguageCode?.Lan_photo,
                                                lang: LanguageCode?.Lan_value
                                            });
                                        setMenubar(false)
                                    }}
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent"
                                >
                                    <img
                                        style={{
                                            width: "25px",
                                            height: "100%",
                                        }}
                                        src={
                                            base_assets +
                                            "images/flags/" +
                                            LanguageCode.Lan_photo
                                        }
                                        alt=""
                                    />{" "}
                                    <span>{LanguageCode.Lan_text}</span>
                                </Link>
                            )}
                        </li>
                    ))}
                </ul>
            </li>
             </div> */}
             </div>
            }
        </div>
    </div>
  )
}
