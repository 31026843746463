import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Dashboard from "../../common/icons/sidebar/Dashboard.js";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import "../../styleV3.scss";
import "./sidebar.scss";
import Calendar from '../../common/icons/sidebar/Calendar.js';
const Sidebar = (props) => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation();
  const { locationId_url } = useParams();
  console.log(locationId_url, "locationId_url on sidebar");
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const moduleparmissiondatashow = useSelector((state) => state.app.ModulePermission);
  // const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const sidebarOverflows = document.querySelectorAll('.main-side-bar-menu-single.dropdown > .dropdown-toggle');
  const sidebarMainDiv = document.querySelector('.main-side-bar-menu');
  if (sidebarOverflows) {
    sidebarOverflows.forEach((sidebarOverflow) => {
      sidebarOverflow.addEventListener('click', () => {
        sidebarMainDiv.style.overflow = 'visible';
      });
    });

  }

  useEffect(() => {
    const sidebarOverflow = document.querySelector('.main-side-bar-menu-single.dropdown');
    const sidebarMainDiv = document.querySelector('.main-side-bar-menu');

    const handleClick = () => {
      sidebarMainDiv.style.display = 'none';
    };

    if (sidebarOverflow) {
      sidebarOverflow.addEventListener('click', handleClick);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      if (sidebarOverflow) {
        sidebarOverflow.removeEventListener('click', handleClick);
      }
    };
  }, []);
  // const [isFirstDropdownOpen, setIsFirstDropdownOpen] = useState(false);
  // const [isSecondDropdownOpen, setIsSecondDropdownOpen] = useState(false);


  // const handleSecondDropdownClick = () => {
  //   setIsFirstDropdownOpen(false);
  //   setIsSecondDropdownOpen(false);
  // };


  return (
    <>
      <aside className={sidebarToggle ? 'main-side-bar flex-shrink-0' : 'main-side-bar flex-shrink-0 resize'}>
        <img className='main-side-bar-toggle-btn' src={base_assets + "images/admin/icons/expand-icon.svg"} onClick={() => setSidebarToggle(current => !current)} alt="" />
        <div className='main-side-bar-logo'>
          <Link className='main-side-bar-logo-a'>
            <img src={base_assets + "images/header-images/gis-logo-white.png"} alt="" />
          </Link>
        </div>

        <ul className='main-side-bar-menu d-block list-unstyled px-0'>
          {moduleparmissiondatashow?.Dashboard ?
            <li className='main-side-bar-menu-single'>
              <Link to="/app/admin/dashboard" className='main-side-bar-menu-single-a'> <Dashboard /> <span className='main-side-bar-menu-single-a-text'>{t("Back")}</span></Link>
            </li> : ""}
          <li className='main-side-bar-menu-single'>
            <Link to="frontcms" state={{ location: locationId_url }} className='main-side-bar-menu-single-a'>  <Calendar width="20px" height="20px" /> <span className='main-side-bar-menu-single-a-text mt-1'>{t("Front CMS")}</span></Link>
          </li>
          <li className='main-side-bar-menu-single'>
            <Link to="pages" state={{ location: locationId_url }} className='main-side-bar-menu-single-a'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
              </svg>
              <span className='main-side-bar-menu-single-a-text mt-1'>{t("Pages")}</span></Link>
          </li>
          <li className='main-side-bar-menu-single'>
            <Link to="banners" state={{ location: locationId_url }} className='main-side-bar-menu-single-a'>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff">
                <path d="M0 0h24v24H0z" fill="none" /><path d="M2 6H0v5h.01L0 20c0 1.1.9 2 2 2h18v-2H2V6zm20-2h-8l-2-2H6c-1.1 0-1.99.9-1.99 2L4 16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zM7 15l4.5-6 3.5 4.51 2.5-3.01L21 15H7z" />
              </svg>
              <span className='main-side-bar-menu-single-a-text mt-1'>{t("Banner")}</span></Link>
          </li>

          <li className='main-side-bar-menu-single'>
            <Link to="blocks" state={{ location: locationId_url }} className='main-side-bar-menu-single-a'>
              <svg fill="#ffff" version="1.1" id="Capa_1" width="24px" height="24px" viewBox="0 0 111.67 111.67" >
                <g>
                  <path d="M70.527,36.011h-29v-29h29V36.011z M96.545,73.815c-8.34,0-15.125,6.785-15.125,15.125s6.785,15.125,15.125,15.125
		S111.67,97.28,111.67,88.94S104.885,73.815,96.545,73.815z M20.63,57.296h9.5h4.059h59.856v12.512h5V54.642h-0.052v-2.346H58.527
		V40.963h-5v11.333h-19.34h-4.059H15.646v2.346H15.63v15.166h5V57.296z M9.075,73.224L0,88.94l9.075,15.719h18.148l9.075-15.719
		l-9.075-15.717H9.075L9.075,73.224z"/>
                </g>
              </svg>
              <span className='main-side-bar-menu-single-a-text mt-1'>{t("Blocks")}</span></Link>
          </li>

        </ul>
      </aside>
    </>
  )
}

export default Sidebar;