import React from 'react'
import UserIcon from "../../assets/icons/UserIcon"
import AppoinmentIcon from "../../assets/icons/AppoinmentIcon"
import CartIcon from "../../assets/icons/CartIcon"
import ChangePasswordIcon from "../../assets/icons/ChangePasswordIcon"
import RightArrowIcon from "../../assets/icons/RightSideArrow"
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const AccountSidebar = () => {

    const location = useLocation()
    const { t } = useTranslation();
    return (
        <>
            <div className="account-sidebar">
                <div className="account-sidebar-title">
                    {t("My Account")}
                </div>
                <div className="account-sidebar-subhead">
                    {t("Settings")}
                </div>
                <div className="account-sidebar-inner">
                    <div className='account-sidebar-inner-account-tabs'>
                        <div className="account-sidebar-inner-tab">
                            <UserIcon /> {t("My Account")}
                        </div>
                        <Link className='text-decoration-none' to="/account">
                            <div className={location.pathname === '/account' ? `account-sidebar-inner-account-insideTab active` : 'account-sidebar-inner-account-insideTab'}>
                                {t("Account Details")} <RightArrowIcon />
                            </div>
                        </Link>
                        <Link className='text-decoration-none' to="/account/address">
                            <div className={location.pathname === '/account/address' ? `account-sidebar-inner-account-insideTab active` : 'account-sidebar-inner-account-insideTab'} >
                                {t("My Addresses")} <RightArrowIcon />
                            </div>
                        </Link>
                    </div>
                    <Link className='text-decoration-none' to="/account/purchasehistory">
                        <div className={location.pathname === `/account/purchasehistory` ? `account-sidebar-inner-tab active` : 'account-sidebar-inner-tab'}>
                            <CartIcon />{t("Purchase History")}
                        </div>
                    </Link>
                    <Link className='text-decoration-none' to='/account/appointmentdata'>
                        <div className={location.pathname === '/account/appointmentdata' ? `account-sidebar-inner-tab active` : 'account-sidebar-inner-tab'}>
                            <AppoinmentIcon /> {t("Appointment")}
                        </div>
                    </Link>
                    <Link className='text-decoration-none' to="/account/changepassword">
                        <div className={location.pathname === '/account/changepassword' ? `account-sidebar-inner-tab active` : 'account-sidebar-inner-tab'}>
                            <ChangePasswordIcon />  {t("Change Password")}
                        </div>
                    </Link>

                </div>
            </div>
            <div className='accountTabMenu'>
                <div className='accountTabMenu-container'>
                    <Link className='text-decoration-none' to="/account">
                        <div className={`accountTabMenu-container-singleMenu`}>
                            {t("Account Details")}
                        </div>
                    </Link>
                    <Link className='text-decoration-none' to="/account/address">
                        <div className={`accountTabMenu-container-singleMenu`}>
                            {t("My Addresses")}
                        </div>
                    </Link>
                    <Link className='text-decoration-none' to="/account/purchasehistory">
                        <div className={`accountTabMenu-container-singleMenu`}>
                            {t("Purchase History")}
                        </div>
                    </Link>
                    <Link className='text-decoration-none' to="/account/appointmentdata">
                        <div className={`accountTabMenu-container-singleMenu`}>
                            {t("Appointment")}
                        </div>
                    </Link>
                    <Link className='text-decoration-none' to="/account/changepassword">
                        <div className={`accountTabMenu-container-singleMenu`}>
                            {t("Change Password")}
                        </div>
                    </Link>
                </div>
            </div>

        </>
    )
}

export default AccountSidebar
