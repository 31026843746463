import React, { useState, useEffect } from "react";
import "../master.scss"
import { _Api } from "../../api/_call";
import { error, loading, Updateloading } from "../../common/helpers/toastify";
import { useTranslation } from 'react-i18next';

const Editmastatcolor = (props) => {
    const { setEditItem, dataid, type, setUpdatedata, create, setCreate, setDataid } = props
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    const [name, setName] = useState()
    const { t } = useTranslation();
    const [code, setCode] = useState()
    const [active, setActive] = useState(true)
    const setitem = async () => {
        if (!name) {
            error(t("Name is required."))
            return
        }
        if (!code) {
            error(t("Code is required."))
            return
        } else {
            if (typeof code === 'string') {
                let checkspace = code.includes(' ');
                if (checkspace) {
                    error(t('Please remove space in code'));
                    return;
                }
            }
        }
        let GetloadingID = loading();
        try {
            let postdata = {
                "id": dataid ? dataid : "",
                "type": type ? type : "",
                "name": name ? name : "",
                "code": code ? code : "",
                "Status": active ? "1" : "0",
                "edit": dataid ? true : false,
            }
            let res = await _Api(postdata, `api/v1/App/master/metalMasterEdit`)
            if (res?.code === 200) {
                Updateloading(t, GetloadingID, (res?.message));
                setUpdatedata(true)
                setEditItem(false)
                setCreate(false)
                setDataid("")
            } else {
                Updateloading(t, GetloadingID, res.errors, 'error');
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }

    const viewmaster = async () => {
        if (create) {
            setName("")
            setCode("")
            setActive(true)
        } else if (dataid && type) {
            try {
                const postdata = {
                    type: type,
                    id: dataid
                }
                const res = await _Api(postdata, `api/v1/App/master/metalMasterViewById`)
                if (res?.code === 200) {
                    setName(res?.data?.name)
                    setCode(res?.data?.code)
                    if (res?.data?.Status === "1") {
                        setActive(true)
                    } else {
                        setActive(false)
                    }

                }
            }
            catch (err) {
                console(err, "err")
            }
        }

    }
    useEffect(() => {
        if (type && (dataid || create)) {
            viewmaster();
        }
        // eslint-disable-next-line
    }, [type, dataid, create]);

    return (
        <>
            <div className="edit-item-area bg-white">
                <div className="edit-item-area-header d-flex align-items-center justify-space-between mb-4">
                    <div className="me-auto">
                        <label className="d-block">{t("Name")}: {name ? name : ""}</label>
                        <label className="d-block">{t("Code")}: {code ? code : ""}</label>
                    </div>
                    <button className="square-btn square-btn-white btn-close focus-none"
                        onClick={() => { setEditItem(false); setCreate(false); setDataid("") }}></button>
                    <button className="square-btn " onClick={() => setitem()}><img src={base_assets + 'images/icons/white-save.png'} alt="" /></button>
                </div>
                <div className="edit-item-area-body">
                    <div className="row mx-0">
                        <div className="col-md-8 ps-0">
                            <div className="v3-input-group">
                                <label className="v3-input-group-label">{t("Name")} <sup>*</sup></label>
                                <input className="v3-input-group-input w-100"
                                    value={name || ''}
                                    onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className="v3-input-group">
                                <label className="v3-input-group-label">{t("Code")} <sup>*</sup></label>
                                <input className="v3-input-group-input w-100"
                                    value={code || ''}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-3 offset-md-1 pe-0 text-end">
                            <div className="form-check form-switch ps-0">
                                <label className="form-check-label" htmlFor="active">{t("Active")}</label>
                                <input className="form-check-input float-none ms-2" type="checkbox" id="active"
                                    checked={active ? true : false}
                                    onChange={(e) => setActive(e.target.checked)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Editmastatcolor;