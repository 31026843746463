import CloseIcon from 'admin/common/icons/CloseIcon';
import PrintIcon from 'admin/common/icons/PrintIcon';
import RightArrow from 'admin/common/icons/right-arrow';
import SearchIcon from 'admin/common/icons/SearchIcon';
import PdfVector from 'admin/common/icons/pdfVector'
import TableLoader from 'admin/common/TableLoader';
import { DateRangeNew } from 'components/common/helpers/daterange';
import { Tablehead } from 'components/common/modules/Tablehead';
import { Pagination } from 'components/common/Pagination';
import { zoneIndexhead } from 'components/common/Tablehead';
import PlusIcon from 'Ecommerce/common/icons/PlusIcon';
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import Select from "react-select";
import ZoneModal from './ZoneModal';
import Apiauth from '../../API/Apiauth.js';
import { error, loading, Updateloading } from 'components/common/helpers/toastify';
export default function ZoneIndex() {
  const { t } = useTranslation();
  const [limit, setLimit] = useState(100);
  const [VaultListData, setVaultListData] = useState([]);
  const [datefilter, setDatefilter] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [datalodaer, setDataLoader] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [filtername, setFiltername] = useState('');
  const [selectAllStatus, setSelectAllStatus] = useState(false);
  const [selecteditem, setSelecteditem] = useState([]);


  const [pages, setPages] = useState({ totalPages: 20, currentPage: 1, });
  const { totalPages, currentPage } = pages;

  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  const getlimit = (limit) => {
    setLimit(limit);
  };

  const getVaultList = useCallback(
    async (page = currentPage, firstLimit = limit) => {
      console.log(datefilter, "Fetching data for page:", page, "with limit:", firstLimit);
      setDataLoader(true);
      try {
        const postdata = {
          search: openSearch ? filtername : "",
          startDate: datefilter ? datefilter[0] : '',
          endDate: datefilter ? datefilter[1] : '',
          limit: firstLimit,
          skip: (page - 1) * firstLimit,
        };
        let apiRes = await Apiauth(postdata, `zone/vault-list`);
        if (apiRes.code === 200) {
          setVaultListData(apiRes.data);
          setSelecteditem([]);
          setPages((prevPages) => ({
            ...prevPages,
            totalPages: Math.ceil(apiRes.total / postdata.limit),
          }));
        }
      } catch (err) {
        console.error(err, "API Error");
      } finally {
        setDataLoader(false);
      }
    },
    [currentPage, limit, datefilter, openSearch, filtername] // limit and currentPage as dependencies
  );

  useEffect(() => {
    getVaultList(currentPage, limit);
  }, [getVaultList, currentPage, limit, datefilter]);

  const handlePaginations = (newPage) => {
    setPages((prevPages) => ({
      ...prevPages,
      currentPage: newPage,
    }));
  };


  const handleChange = (event) => {
    if (event.target.checked) {
      const mainselected = [...selecteditem, event.target.value];
      setSelecteditem(mainselected);
    } else {
      const result = selecteditem.filter((element) => {
        return element !== event.target.value;
      });
      setSelecteditem(result);
    }


  }
  let selectAllStatus_arr = [];

  useEffect(() => {
    var allCheckbox = document.querySelectorAll('.zoneListCheckBox');
    if (selectAllStatus === true) {
      allCheckbox.forEach(function (checkBox_selectAble) {
        checkBox_selectAble.checked = true;
        selectAllStatus_arr.push(checkBox_selectAble.value);
      });
      let arrya = [];
      for (let i = 0; i < allCheckbox.length; i++) {
        if (allCheckbox[i].checked) {
          let product_id = allCheckbox[i].getAttribute('data-id');
          arrya.push(product_id);
        }
      }
      setSelecteditem(arrya);
    } else {
      allCheckbox.forEach(function (checkBox_selectAble) {
        checkBox_selectAble.checked = false;
      });
      setSelecteditem([]);
    }
    setSelecteditem(selectAllStatus_arr);
    // eslint-disable-next-line
  }, [selectAllStatus]);
  console.log(selectAllStatus_arr, ">>>>", selecteditem);

  const printButtonClick = async () => {
    if (selecteditem?.length > 0) {
      let GetloadingID = loading();
      try {
        const postdata = {
          ids: selecteditem
        };
        let apiRes = await Apiauth(postdata, `zone/print-vault-list`);
        if (apiRes.code === 200) {
          // link
          setTimeout(() => {
            const exportLinkElement = document.createElement('a');
            exportLinkElement.hidden = true;
            exportLinkElement.download = apiRes?.name ? apiRes?.name : "report.pdf";
            exportLinkElement.href = apiRes?.link;
            exportLinkElement.text = "downloading...";
            exportLinkElement.setAttribute('target', '_blank');
            document.body.appendChild(exportLinkElement);
            exportLinkElement.click();
            exportLinkElement.remove();
            Updateloading(t, GetloadingID, t("Download successfully"));
          }, 50)

        }
      } catch (err) {
        console.error(err, "API Error");
        error("There is a server-side error; please contact the server team..");

      } finally {
        console.log("API call complete");

      }

    } else {
      error("Please select the record row for the Zone to print.");
    }

  };
  const downloadPdf = (link) => {
    if (!link) {
      console.error('No link provided for download.');
      return;
    }
    const anchorElement = document.createElement('a');
    anchorElement.style.display = 'none'; // Hide the anchor element
    anchorElement.href = link;
    anchorElement.download = ''; // This triggers the download attribute
    anchorElement.target = '_blank';
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
  };

  const [currentKey, setCurrentKey] = useState(null);
  const handleStatusChange = async () => {
    try {
      const postdata = { id: currentKey, Status: 0 }
      let apiRes = await Apiauth(postdata, `zone/edit-issue-stocks`);
      if (apiRes.code === 200) {
        console.log('Status updated successfully');
        getVaultList(currentPage, limit);
      } else {
        console.error('Failed to update status');
      }
    } catch (error) {
      console.error('Error during API call:', error);
    } finally {
      setOpenModal(false);
      setCurrentKey(null);
    }
  };
  return (
    <div className="main-body zone_module">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-between">
            <h1 className="main-body-heading">
              <span>
                <Link to="/app/inventory/myInventory" className='text-decoration-none'> {t('Inventory')}</Link> <span className='ms-2'><RightArrow /></span>
              </span>
              {t("Zone")}
            </h1>
            <Link
              to="createissue"
              className={"main-body-top-tab-bar-right-col-a"}
              state={{ create: true }}
            >
              <PlusIcon color="#fff" />
              <span className="main-body-top-tab-bar-right-col-a-text">
                {t("Create Issue")}
              </span>
            </Link>
          </div>
          <div className='zone_module-inner'>
            <div className="main-body-top-status-bar">
              <Select
                className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                options={selectOptions}
                value={{ label: limit, value: limit }}
                onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                isSearchable={false}
              />

              <div className="main-body-top-status-bar-multi-date-picker">
                <div className={`main-body-top-status-bar-multi-date-picker-iconholder ${openSearch ? 'active' : ''}`}><span onClick={() => setOpenSearch(true)}><SearchIcon color="#A4A4A4" /></span><div className={`main-body-top-status-bar-multi-date-picker-iconholder-searchbar ${openSearch ? 'active' : ""}`}>
                  <input type="text"
                    value={filtername}
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t("Search")}
                    onChange={(e) => setFiltername(e.target.value)}
                  />


                  <span onClick={() => { setOpenSearch(false); setFiltername(""); }}><CloseIcon /></span> </div></div>
                <span onClick={printButtonClick} className='main-body-top-status-bar-multi-date-picker-iconholder'><PrintIcon color="#A4A4A4" /></span>
                <DateRangeNew setDatefilter={setDatefilter} />

              </div>
            </div>
            <div className='zone_module-inner-table'>
              <div
                className="main-body-main-table-wrap position-relative"
                style={{ maxWidth: '100%', overflow: 'auto' }}
              >
                <table
                  id="my_inventory"
                  className="stripe row-border order-column common-table table-striped main-table w-100"
                >
                  <Tablehead tablehead={zoneIndexhead} tblName={'zoneindex_table'} setShowoption={setSelectAllStatus} />
                  <tbody>
                    <>

                      {VaultListData?.length ? VaultListData.map((result, key) => {
                        return (
                          <tr key={key} className="">
                            <td>
                              <div className={'com-check'}>
                                <input key={key}
                                  className='inventorytablecheck inventorytableHead gisClassTh zoneListCheckBox'
                                  id={result?.id}
                                  value={result?.id}
                                  type="checkbox"
                                  onChange={(e) => handleChange(e)}
                                />
                                <label htmlFor={result?.id} className="com-check-label" />
                              </div>
                            </td>
                            <td className="ref-no cursor-pointer" ><Link className='text-decoration-none' to={'/app/inventory/zone/edit/' + result?.id}>{result?.RefNo ? result?.RefNo : "-"}</Link></td>
                            <td className="table-pro-img" >
                              {result?.LocationId ? result?.LocationId : "-"}
                            </td>
                            <td className="">
                              {result?.ZoneId ? result?.ZoneId : "-"}
                            </td>
                            <td>
                              <div className="tooltip-area-up">{result?.SalePersonIds ? result?.SalePersonIds : "-"}</div>
                            </td>
                            <td>
                              <div className="tooltip-area-up">{result?.SKUCount ? result?.SKUCount : "-"}</div>
                            </td>
                            <td>
                              <div className="tooltip-area-up dateandtime"><span>{result?.IssueDate ? result?.IssueDate : ""}</span><span>{result?.IssueTime ? result?.IssueTime : null}</span></div>
                            </td>
                            <td className="">
                              <div className="tooltip-area-up dateandtime"><span>{result?.ReturnDate ? result?.ReturnDate : ""}</span><span>{result?.ReturnTime ? result?.ReturnTime : null}</span></div>
                            </td>
                            <td className="">{result?.issue ? result?.issue : "-"}</td>
                            <td className="">{result?.sold ? result?.sold : "-"}</td>
                            <td className="">{result?.return ? result?.return : "-"}</td>
                            <td className="">{result?.balance ? result?.balance : "-"}</td>

                            <td className="position-relative">
                              <div
                                className={`successStatusBox ${result?.Status === 1 ? 'open' : 'close'}`}
                                onClick={() => {
                                  if (result?.Status === 1) {
                                    setOpenModal(true);
                                    setCurrentKey(result?.id); // Save the key of the item
                                  }
                                }}
                              >
                                {result?.Status === 1 ? (
                                  <div className="successStatusBox-tag">{t('Open')}</div>
                                ) : (
                                  <div className="successStatusBox-tag">{t('Close')}</div>
                                )}
                              </div>
                            </td>
                            <td className="">{result?.created_by ? result?.created_by : "-"}</td>
                            <td className="">
                              {result?.invoice ?
                                <div className='stockidSidebar tooltip-area-up' onClick={() => { downloadPdf(result?.invoice); }}>
                                  <PdfVector />
                                  <span className='tooltip-area-up-text whitenblack'>{t("Export Pdf")}</span>
                                </div>
                                : "-"}</td>

                          </tr>
                        );
                      }) :
                        <td colSpan="13" style={{ textAlign: "center", fontStyle: "italic", color: "#888" }}>
                          {t("No data available")}
                        </td>
                      }
                    </>
                  </tbody>
                </table>

                {datalodaer && <TableLoader />}
              </div>
              {VaultListData.length > 0 ? (
                <Pagination
                  total={totalPages}
                  current={currentPage}
                  pagination={(crPage) => handlePaginations(crPage)}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {/* {openModal && <ZoneModal setOpenModal={setOpenModal} popupContent={{ header: 'You Want to change status', subheader: 'You want to change the status from open to close. The closed status can not be changed.', withicon: false }} />} */}
      {openModal && (
        <ZoneModal
          setOpenModal={setOpenModal}
          popupContent={{
            header: 'You Want to Change Status',
            subheader:
              'You want to change the status from open to close. The closed status cannot be changed.',
            withicon: false,
          }}
          onConfirm={handleStatusChange}
        />
      )}
    </div>
  )
}
