import React from 'react'

export default function UserIcon({type}) {
  return (
    <>
    {type = "withbgfilled" ? 

    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 4C11.93 4 13.5 5.57 13.5 7.5C13.5 9.43 11.93 11 10 11C8.07 11 6.5 9.43 6.5 7.5C6.5 5.57 8.07 4 10 4ZM10 18C7.97 18 5.57 17.18 3.86 15.12C5.6116 13.7457 7.77362 12.9988 10 12.9988C12.2264 12.9988 14.3884 13.7457 16.14 15.12C14.43 17.18 12.03 18 10 18Z" fill="#070707"/>
    </svg>
      :
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 14.75C13 12.6792 10.3135 11 7 11C3.6865 11 1 12.6792 1 14.75M7 8.75C6.00544 8.75 5.05161 8.35491 4.34835 7.65165C3.64509 6.94839 3.25 5.99456 3.25 5C3.25 4.00544 3.64509 3.05161 4.34835 2.34835C5.05161 1.64509 6.00544 1.25 7 1.25C7.99456 1.25 8.94839 1.64509 9.65165 2.34835C10.3549 3.05161 10.75 4.00544 10.75 5C10.75 5.99456 10.3549 6.94839 9.65165 7.65165C8.94839 8.35491 7.99456 8.75 7 8.75Z" fill="#A4A4A4" />
      <path d="M13 14.75C13 12.6793 10.3135 11 7 11C3.6865 11 1 12.6793 1 14.75H13ZM7 8.75C6.00544 8.75 5.05161 8.35491 4.34835 7.65165C3.64509 6.94839 3.25 5.99456 3.25 5C3.25 4.00544 3.64509 3.05161 4.34835 2.34835C5.05161 1.64509 6.00544 1.25 7 1.25C7.99456 1.25 8.94839 1.64509 9.65165 2.34835C10.3549 3.05161 10.75 4.00544 10.75 5C10.75 5.99456 10.3549 6.94839 9.65165 7.65165C8.94839 8.35491 7.99456 8.75 7 8.75Z" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    }
    </>
  )
}
