import CloseIcon from 'admin/common/icons/CloseIcon';
import PageWithPencile from 'admin/common/icons/PageWithPencile'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function ZoneSidebarPopup({ setStockidShowSidebar, onSubmit }) {
    const { t } = useTranslation();
    const [textareaValue, setTextareaValue] = useState('');
    const handleChange = (event) => {
        setTextareaValue(event.target.value);
    };

    const handleSubmit = () => {
        onSubmit(textareaValue);
        setStockidShowSidebar(false);
    };
    return (
        <div className='sidebarpopup'>
            <div className='sidebarpopup-inner'>
                <div className='sidebarpopup-inner-top'>
                    <div className='sidebarpopup-inner-top-content'>
                        <PageWithPencile />
                        <span>{t("Stock ID")}</span>
                    </div>
                    <div className='cursor-pointer' onClick={() => setStockidShowSidebar(false)}>
                        <CloseIcon />
                    </div>
                </div>

                <textarea placeholder={'Type your stock ID here.'}
                    onChange={handleChange}
                    value={textareaValue ? textareaValue : ''}
                />
                <div className='sidebarpopup-inner-bottom'>
                    <button onClick={handleSubmit}>{t("Submit")}</button>
                </div>
            </div>
        </div>
    )
}
