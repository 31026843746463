import React from 'react'

export default function PageWithPencile() {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.8684 12.5088V16.4561C12.8684 16.7456 12.6316 16.9824 12.3421 16.9824H1.02632C0.736842 16.9824 0.5 16.7456 0.5 16.4561V1.19297C0.5 0.903499 0.736842 0.666656 1.02632 0.666656H7.8421L12.8684 5.42981V9.35087" stroke="#A4A4A4"/>
        <path d="M12.8684 5.64034H8.39472C8.10525 5.64034 7.86841 5.40349 7.86841 5.11402V0.92981" stroke="#A4A4A4"/>
        <path d="M2.86841 5.14035H6.28946" stroke="#A4A4A4"/>
        <path d="M2.86841 7.77191H11.0263" stroke="#A4A4A4"/>
        <path d="M2.86841 9.61401H11.0263" stroke="#A4A4A4"/>
        <path d="M2.86841 11.9825H9.1842" stroke="#A4A4A4"/>
        <path d="M2.86841 13.5614H7.34209" stroke="#A4A4A4"/>
        <path d="M7.60529 16.193L8.6316 13.7193L13.9737 8.3772C14.079 8.27193 14.2632 8.27193 14.3948 8.3772L15.4211 9.40351C15.5263 9.50877 15.5263 9.69299 15.4211 9.82456L10.079 15.1667L7.60529 16.193Z" stroke="#A4A4A4" strokeLinejoin="round"/>
        <path d="M13.3685 9.0087L14.7895 10.4298" stroke="#A4A4A4"/>
    </svg>
  )
}
