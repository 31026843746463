import React, { useEffect, useState } from "react";
import PlusIcon from '../../assets/icons/PlusIcon';
import Select, { components } from "react-select";
import 'react-datepicker/dist/react-datepicker.css';
import LeftSideArrowIcon from '../../assets/icons/LeftSideArrowIcon';
import OrderSummaryBox from "./OrderSummaryBox";
import { _Api, _Apiauth } from "../../../common/api";
import EditIcon from '../../assets/icons/EditIcon';
import ConfirmFormPage from './ConfirmFormPage';
import { Link, useLocation, useNavigate } from "react-router-dom";
import StripePayment from './StripePayment';
import AboutIcon from '../../assets/images/AboutIcon.png'
import VissaCardIcon from '../../assets/images/visa.png';
import MasterCardIcon from '../../assets/images/mastercard.png';
import JapanCardIcon from '../../assets/images/JapanCredit.png';
import UnionCardIcon from '../../assets/images/UnionPay.png';
import LockIcon from '../../assets/images/lockIcon.png';
import QrcodeIcon from '../../assets/images/QrImg.png';
import uploadIcon from '../../assets/images/uploadIcon.png';
import confirmicon from '../../assets/images/confirmicon.png';
import ShippingAddrModel from "../../components/ShippingAddrModel";
import { useTranslation } from 'react-i18next';
import MinusIcon from "../../assets/icons/MinusIcon";
import { Nationality } from "../../../../../pos/common/module/nationalityarray";
const ShippingPage = (props) => {
    const { ValueContainer, Placeholder } = components;
    const { t } = useTranslation();
    const accessToken = localStorage.getItem("ecomaccesstoken");
    const navigate = useNavigate()
    let cartdata = JSON.parse(localStorage.getItem('CartItems'))
    const { setOpenmodal } = props;
    const [nationalityoption, setNationalityoption] = useState()
    useEffect(() => {
        if (Nationality?.length) {
            let Nationalitydata = []
            Nationality.forEach((result) => {
                Nationalitydata.push({ value: result, label: result })
            })
            setNationalityoption(Nationalitydata)
        }
    }, [])
    let gender = ["Male", "Female", "Other"]
    const { state } = useLocation()
    const idProofOption = [
        {
            value: "Passport",
            label: "Passport",
        },
        {
            value: "Driving License",
            label: "Driving License",
        },
        {
            value: "Citizen ID",
            label: "Citizen ID",
        },
    ];
    const [userdata, setUserdata] = useState({
        shipping_address: {
            'fname': '',
            'lname': '',
            'nationality': '',
            'title': '',
            'govt_ids': [{ 'idtype': '', 'idnumber': '' }],
            'age': '',
            "contacts": [{ icon: 1, phoneCode: "", number: "" }],
            'gender': '',
            'address': '',
            'country': '',
            'state': '',
            'city': '',
            'zipcode': '',
            'emails': [""],
            "is_default": 0
        },
        'default_billing': false,
        'agree': 0,
        'shipping_method': { 'shiptohome': '', 'storepick': '' },
        'billingaddress': {
            'fname': '', 'lname': '', 'address': '', 'country': '', 'state': '', 'city': '', 'zipcode': '',
            'contacts': [{ icon: 1, phoneCode: "", number: "" }], 'emails': [],
            "is_default": 0
        }
    });

    const [dobvalues, setDobvalues] = useState({ date: [], month: [], year: [] })
    const [shipaddress, setShipaddress] = useState({ ship: false, billing: false })
    const [fromstaps, setFromstaps] = useState({ one: true, two: false, three: false })
    const [countrylist, setCountrylist] = useState([]);
    const [phonecodelist, setPhonecodelist] = useState([]);
    const [changeshipping, setChangeshipping] = useState(true);
    const [shippingmethod, setShippingmethod] = useState();
    const [errormessage, setErrormessage] = useState();
    const [shippinglist, setShippinglist] = useState([])
    const [shippingdata, setShippingdata] = useState();
    const [fromshow, setFromshow] = useState(true)
    const [billingaddressfrom, setBillingaddress] = useState(false)
    const [paymentdata, setPaymentdata] = useState({ Bank: [], Credit_Card: [] })
    const [paymentdatashow, setPaymentdatashow] = useState()
    const [lodaer, setLodaer] = useState(false)
    const [widtth, setWidtth] = useState(window.innerWidth);
    const [billingaddressdata, setBillingaddressdata] = useState(
        {
            'contacts': [{ icon: 1, phoneCode: "", number: "" }],
            'emails': [],
            is_default: 0
        }

    )
    const [creditpaymentdata, setCreditpaymentdata] = useState({
        message: "",
        payment: false,
        submitdata: false
    })
    const [lastpagedata, setLastpagedata] = useState();
    const [isOpenOrder, setIsOpenOrder] = useState(false);

    // get screen width
    useEffect(() => {
        const handleResize = () => {
            setWidtth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleOrderSummary = () => {
        setIsOpenOrder(!isOpenOrder);
    };
    const shippingitem = async () => {
        try {
            let cartids = [];
            if (state?.checkoutItems?.length) { state?.checkoutItems?.map((result) => cartids.push(result?.id)) }
            let postdata = {
                cart_ids: cartids
            }
            let res = await _Api(postdata, `order/getCartDetails`)
            if (res?.statusCode === 200) {
                if (res?.data) {
                    let array = [...res?.data];
                    array?.forEach((result) => {
                        result.qty = parseInt(state?.checkoutItems.filter(e => e?.id === result?.id)[0]['qty']);
                    })
                    setShippinglist(array)
                }

                setShippingdata((state) => ({
                    ...state,
                    CURRENCY: res?.CURRENCY
                }))
                if (res?.deleted_ids?.length) {
                    state?.checkoutItems.forEach((result) => {
                        if (!res?.deleted_ids.includes(result?.id)) {
                            let data = []
                            data.push(result)
                            localStorage.setItem('CartItems', JSON.stringify(data));
                        }
                    })
                }
            }
        }
        catch (err) {
            console.log(err, "Err")
        }
    }
    const callAge = (e) => {
        const current = new Date();
        const year = current.getFullYear();
        const calage = year - e.value;
        setUserdata((state) => ({
            ...state,
            shipping_address: {
                ...state?.shipping_address,
                age: calage
            }
        }))
    };

    const titleoption = [
        {
            value: "Mr",
            label: t("Mr."),
        },
        {
            value: "Miss",
            label: t("Miss"),
        },
        {
            value: "Mrs",
            label: t("Mrs"),
        },
        {
            value: "Ms",
            label: t("Ms"),
        },
    ];

    useEffect(() => {
        if (state?.checkoutItems?.length) {
            shippingitem()
        }
        // eslint-disable-next-line
    }, [state])

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith("image/")) {
                const reader = new FileReader();
                reader.onloadend = async () => {
                    try {
                        const postdata = {
                            image: reader.result,
                        };
                        let res = await _Api(postdata, `redis/uplaod`)
                        if (res.statusCode === 200) {
                            if (userdata?.paymentMethod?.IB) {
                                setUserdata({
                                    ...userdata,
                                    'paymentMethod': {
                                        ...userdata?.paymentMethod,
                                        IB: {
                                            ...userdata?.paymentMethod?.IB,
                                            image: res?.url
                                        }
                                    }
                                });
                            }
                            setErrormessage((state) => ({
                                ...state,
                                'bankimage': 'image is successfully upload'
                            }))
                        }
                    }
                    catch (err) {
                        console.log(err, "err")
                    }
                };
                if (file) {
                    reader?.readAsDataURL(file);
                }
            } else {
                alert("Please select a valid image file.");
            }
        }
    };
    const getuserdetails = async (monthoption) => {
        try {
            setErrormessage();
            let res = await _Api({}, `checkout/accountDetails`)
            if (res?.statusCode === 200) {
                if (res?.billingAddress) {
                    setBillingaddress(true)
                }
                setUserdata({
                    customer_id: res?.user?._id ? res?.user?._id : "",
                    shipping_address: {
                        fname: res?.shippingAddress?.fname ? res?.shippingAddress?.fname : res?.user?.fname,
                        mname: res?.shippingAddress?.mname ? res?.shippingAddress?.mname : res?.user?.mname,
                        lname: res?.shippingAddress?.lname ? res?.shippingAddress?.lname : res?.user?.lname,
                        nationality: res?.shippingAddress?.nationality,
                        title: res?.shippingAddress?.title,
                        govt_ids: res?.shippingAddress?.govt_ids?.length ? res?.shippingAddress?.govt_ids : [{ 'idtype': '', 'idnumber': '' }],
                        date_of_birth: {
                            date: res?.shippingAddress?.date_of_birth?.date,
                            month: monthoption.find(element => element?.value === res?.shippingAddress?.date_of_birth?.month), year: res?.shippingAddress?.date_of_birth?.year
                        },
                        age: res?.shippingAddress?.age,
                        gender: res?.shippingAddress?.gender,
                        address: res?.shippingAddress?.address,
                        country: res?.shippingAddress?.country ? res?.shippingAddress?.country : '',
                        state: res?.shippingAddress?.state,
                        city: res?.shippingAddress?.city,
                        zipcode: res?.shippingAddress?.zipcode,
                        contacts: res?.shippingAddress?.contacts?.length ? res?.shippingAddress?.contacts : [{ "icon": 1, "phoneCode": "", "number": "" }],
                        emails: res?.shippingAddress?.emails?.length ? res?.shippingAddress?.emails : [res?.user?.email],
                        UDID: res?.shippingAddress?.UDID,
                        is_default: res?.shippingAddress?.is_default ? res?.shippingAddress?.is_default : 0,
                    },
                    billingaddress: {
                        'fname': res?.billingAddress?.fname ? res?.billingAddress?.fname : res?.user?.fname,
                        'lname': res?.billingAddress?.lname ? res?.billingAddress?.lname : res?.user?.lname,
                        'address': res?.billingAddress?.address ? res?.billingAddress?.address : "",
                        'country': res?.billingAddress?.country ? res?.billingAddress?.country : "",
                        'state': res?.billingAddress?.state ? res?.billingAddress?.state : "",
                        'city': res?.billingAddress?.city ? res?.billingAddress?.city : "",
                        'zipcode': res?.billingAddress?.zipcode ? res?.billingAddress?.zipcode : "",
                        'contacts': res?.billingAddress?.contacts?.length ? res?.billingAddress?.contacts : [{ icon: 1, phoneCode: "", number: "" }],
                        'emails': res?.billingAddress?.emails?.length ? res?.billingAddress?.emails : [res?.user?.email],
                        "govt_ids": res?.billingAddress?.govt_ids,
                        "is_default": res?.billingAddress?.is_default ? res?.billingAddress?.is_default : 0,
                        "UDID": res?.billingAddress?.UDID,
                        "date_of_birth": res?.billingAddress?.date_of_birth
                    }
                })
                setBillingaddressdata({
                    'fname': res?.billingAddress?.fname ? res?.billingAddress?.fname : res?.user?.fname,
                    'lname': res?.billingAddress?.lname ? res?.billingAddress?.lname : res?.user?.lname,
                    'address': res?.billingAddress?.address,
                    'country': res?.billingAddress?.country,
                    'state': res?.billingAddress?.state,
                    'city': res?.billingAddress?.city,
                    'zipcode': res?.billingAddress?.zipcode,
                    'contacts': res?.billingAddress?.contacts?.length ? res?.billingAddress?.contacts : [{ icon: 1, phoneCode: "", number: "" }],
                    'emails': res?.billingAddress?.emails?.length ? res?.billingAddress?.emails : [res?.user?.email],
                    "govt_ids": res?.billingAddress?.govt_ids,
                    "is_default": res?.billingAddress?.is_default ? res?.billingAddress?.is_default : 0,
                    "UDID": res?.billingAddress?.UDID,
                    "date_of_birth": res?.billingAddress?.date_of_birth
                })
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const ChangeMultipleSelect = (first, key, label, second) => {
        if (label === 'govt_ids') {
            const ids = [...userdata?.shipping_address?.govt_ids];
            ids[key] = { 'idtype': first, 'idnumber': second }
            setUserdata({ ...userdata, 'shipping_address': { ...userdata?.shipping_address, govt_ids: ids } });
        }
        if (label === 'contacts') {
            const contacts = [...userdata?.shipping_address?.contacts];
            contacts[key] = { "icon": 1, 'phoneCode': first, 'number': second }
            setUserdata({ ...userdata, 'shipping_address': { ...userdata?.shipping_address, contacts: contacts } });
        }
        if (label === 'email') {
            const email = [...userdata?.shipping_address?.emails];
            email[key] = first;
            setUserdata({ ...userdata, 'shipping_address': { ...userdata?.shipping_address, emails: email } });
        }
    }
    const AddRow = (element) => {
        if (element === 'govt_ids') {
            const ids = [...userdata?.shipping_address?.govt_ids];
            ids.push({ 'idtype': '', 'idnumber': '' });
            setUserdata({ ...userdata, 'shipping_address': { ...userdata?.shipping_address, govt_ids: ids } });

        }
        if (element === 'contacts') {
            const contacts = [...userdata?.shipping_address?.contacts];
            contacts.push({ "icon": 1, "phoneCode": "", "number": "" });
            setUserdata({ ...userdata, 'shipping_address': { ...userdata?.shipping_address, contacts: contacts } });

        }
        if (element === 'email') {
            const email = [...userdata?.shipping_address?.emails];
            email.push('');
            setUserdata({ ...userdata, 'shipping_address': { ...userdata?.shipping_address, emails: email } });

        }
    }
    const RemoveBlock = (element, key) => {
        if (element === 'govt_ids') {
            let ids = [...userdata?.shipping_address?.govt_ids];
            ids = ids.filter((e, s) => s !== key);
            setUserdata({ ...userdata, 'shipping_address': { ...userdata?.shipping_address, govt_ids: ids } });

        }
        if (element === 'contacts') {
            let contacts = [...userdata?.shipping_address?.contacts];
            contacts = contacts.filter((e, s) => s !== key);
            setUserdata({ ...userdata, 'shipping_address': { ...userdata?.shipping_address, contacts: contacts } });
        }
        if (element === 'email') {
            let email = [...userdata?.shipping_address?.emails];
            email = email.filter((e, s) => s !== key);
            setUserdata({ ...userdata, 'shipping_address': { ...userdata?.shipping_address, emails: email } });
        }
    }
    const getcountry = async () => {
        try {
            let res = await _Apiauth({}, `redis/countryList`)
            if (res?.statusCode === 200) {
                if (res?.data) {
                    let array = [];
                    let phoncodearray = [];
                    res?.data.forEach((result) => {
                        array.push({ 'label': result?.name, 'value': result?.id });
                        phoncodearray.push({ 'label': result?.phoneCode, 'value': result?.phoneCode })
                    })
                    setCountrylist(array);
                    setPhonecodelist(phoncodearray);
                }

            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const getshippings = async () => {
        try {
            let res = await _Apiauth({}, `redis/shipingMathod`)
            if (res?.statusCode === 200) {
                setShippingmethod({ shiptohome: res?.ShipToHome, storepickup: res?.StorePickup });
            } else {
                setShippingmethod({ shiptohome: [], storepickup: [] });
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const ChangeCheck = (label, e) => {
        if (label === 'agree') {
            if (e.target.checked) {
                setUserdata({ ...userdata, 'agree': 1 });
            } else {
                setUserdata({ ...userdata, 'agree': 0 });
            }
        }
        if (label === 'default_billing') {
            if (e.target.checked) {
                setUserdata({
                    ...userdata,
                    'billingaddress': userdata?.shipping_address,
                    default_billing: true
                });

            } else {
                setUserdata({
                    ...userdata,
                    'billingaddress': billingaddressdata,
                    default_billing: false
                });
            }
        }
    }

    const validationfun = (userdata) => {
        let passval = true;
        if (!userdata?.shipping_address?.fname) {
            setErrormessage((state) => ({
                ...state,
                'fname': 'First Name is required'
            }))
            passval = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'fname': ''
            }))
        }
        if (!userdata?.shipping_address?.lname) {
            setErrormessage((state) => ({
                ...state,
                'lname': 'Last Name is required'
            }))
            passval = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'lname': ''
            }))
        }
        if (!userdata?.shipping_address?.nationality) {
            setErrormessage((state) => ({
                ...state,
                'nationality': 'Nationality is required'
            }))
            passval = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'nationality': ''
            }))
        }
        if (!userdata?.shipping_address?.title) {
            setErrormessage((state) => ({
                ...state,
                'title': 'Title is required'
            }))
            passval = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'title': ''
            }))
        }
        if (!userdata?.shipping_address?.govt_ids[0]?.idnumber || !userdata?.shipping_address?.govt_ids[0]?.idtype) {
            setErrormessage((state) => ({
                ...state,
                'govt_ids': 'Id Proof is required'
            }))
            passval = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'govt_ids': ''
            }))
        }
        if (!userdata?.shipping_address?.date_of_birth?.date || !userdata?.shipping_address?.date_of_birth?.month?.value || !userdata?.shipping_address?.date_of_birth?.year) {
            setErrormessage((state) => ({
                ...state,
                'date_of_birth': 'Date of birth is required'
            }))
            passval = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'date_of_birth': ''
            }))
        }
        if (!userdata?.shipping_address?.age) {
            setErrormessage((state) => ({
                ...state,
                'age': 'Age is required'
            }))
            passval = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'age': ''
            }))
        }
        if (!userdata?.shipping_address?.gender) {
            setErrormessage((state) => ({
                ...state,
                'gender': 'Gender is required'
            }))
            passval = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'gender': ''
            }))
        }
        if (!userdata?.shipping_address?.address) {
            setErrormessage((state) => ({
                ...state,
                'address': 'Address is required'
            }))
            passval = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'address': ''
            }))
        }
        if (!userdata?.shipping_address?.country?.value) {
            setErrormessage((state) => ({
                ...state,
                'country': 'Country is required'
            }))
            passval = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'country': ''
            }))
        }
        if (!userdata?.shipping_address?.state) {
            setErrormessage((state) => ({
                ...state,
                'state': 'State is required'
            }))
            passval = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'state': ''
            }))
        }
        if (!userdata?.shipping_address?.city) {
            setErrormessage((state) => ({
                ...state,
                'city': 'City is required'
            }))
            passval = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'city': ''
            }))
        }
        if (!userdata?.shipping_address?.zipcode) {
            setErrormessage((state) => ({
                ...state,
                'zipcode': 'Zipcode is required'
            }))
            passval = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'zipcode': ''
            }))
        }
        if (!userdata?.shipping_address?.emails[0]) {
            setErrormessage((state) => ({
                ...state,
                'emails': 'Emails is required'
            }))
            passval = false;
        } else if (!isValidEmail(userdata?.shipping_address?.emails[0])) {
            setErrormessage((state) => ({
                ...state,
                'emails': 'Invalid Email'
            }))
            passval = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'emails': ''
            }))
        }
        if (!userdata?.shipping_address?.contacts[0]?.phoneCode || !userdata?.shipping_address?.contacts[0]?.number) {
            setErrormessage((state) => ({
                ...state,
                'contacts': 'Contacts is required'
            }))
            passval = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'contacts': ''
            }))
        }
        if (!passval) {
            setFromshow(true)
            return;
        }
        if (changeshipping) {
            if (!userdata?.shipping_method?.shiptohome) {
                setErrormessage((state) => ({
                    ...state,
                    'shipping_method': 'Shipping method is required'
                }))
                passval = false;
            } else {
                setErrormessage((state) => ({
                    ...state,
                    'shipping_method': ''
                }))
            }
        } else {
            if (!userdata?.shipping_method?.storepick) {
                setErrormessage((state) => ({
                    ...state,
                    'shipping_method': 'Shipping method is required'
                }))
                passval = false;
            } else {
                setErrormessage((state) => ({
                    ...state,
                    'shipping_method': ''
                }))
            }
        }
        if (passval) {
            setFromstaps({ one: false, two: true, three: false })
        }
    }
    const validationfunsecound = () => {
        let fromisvalid = true;
        if (!userdata?.billingaddress?.fname) {
            setErrormessage((state) => ({
                ...state,
                'billingfname': 'First Name is required'
            }))
            fromisvalid = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'billingfname': ''
            }))
        }
        if (!userdata?.billingaddress?.lname) {
            setErrormessage((state) => ({
                ...state,
                'billinglname': 'Last Name is required'
            }))
            fromisvalid = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'billinglname': ''
            }))
        }
        if (!userdata?.billingaddress?.address) {
            setErrormessage((state) => ({
                ...state,
                'billingaddress': 'Address is required'
            }))
            fromisvalid = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'billingaddress': ''
            }))
        }
        if (!userdata?.billingaddress?.country?.value) {
            setErrormessage((state) => ({
                ...state,
                'billingcountry': 'Country is required'
            }))
            fromisvalid = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'billingcountry': ''
            }))
        }
        if (!userdata?.billingaddress?.state) {
            setErrormessage((state) => ({
                ...state,
                'billingstate': 'State is required'
            }))
            fromisvalid = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'billingstate': ''
            }))
        }
        if (!userdata?.billingaddress?.city) {
            setErrormessage((state) => ({
                ...state,
                'billingcity': 'City is required'
            }))
            fromisvalid = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'billingcity': ''
            }))
        }
        if (!userdata?.billingaddress?.zipcode) {
            setErrormessage((state) => ({
                ...state,
                'billingzipcode': 'Zipcode is required'
            }))
            fromisvalid = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'billingzipcode': ''
            }))
        }
        if (!userdata?.billingaddress?.contacts[0]?.number) {
            setErrormessage((state) => ({
                ...state,
                'billingcontacts': 'Contacts is required'
            }))
            fromisvalid = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'billingcontacts': ''
            }))
        }
        if (!userdata?.billingaddress?.emails[0]) {
            setErrormessage((state) => ({
                ...state,
                'billingemails': 'Email is required'
            }))
            fromisvalid = false;
        } else if (!isValidEmail(userdata?.billingaddress?.emails[0])) {
            setErrormessage((state) => ({
                ...state,
                'billingemails': 'Invalid Email'
            }))
            fromisvalid = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'billingemails': ''
            }))
        }
        if (!fromisvalid) {
            setBillingaddress(false)
        }
        if (!userdata?.agree) {
            setErrormessage((state) => ({
                ...state,
                'privacy': 'Select privacy is required'
            }))
            fromisvalid = false;
        } else {
            setErrormessage((state) => ({
                ...state,
                'privacy': ''
            }))
        }
        if (!paymentdatashow?.bank && !paymentdatashow?.Credit) {
            setErrormessage((state) => ({
                ...state,
                'payment': 'Pleace select payment method is required'
            }))
            fromisvalid = false;
        } else {
            if (paymentdatashow?.bank) {
                if (!userdata?.paymentMethod?.IB?.name) {
                    setErrormessage((state) => ({
                        ...state,
                        'bankselect': 'Pleace select bank is required'
                    }))
                    fromisvalid = false;
                } else {
                    setErrormessage((state) => ({
                        ...state,
                        'bankselect': ''
                    }))
                }
                if (!userdata?.paymentMethod?.IB?.image) {
                    setErrormessage((state) => ({
                        ...state,
                        'bankimage': 'Image is required'
                    }))
                    fromisvalid = false;
                } else {
                    setErrormessage((state) => ({
                        ...state,
                        'bankimage': ''
                    }))
                }
            }
            if (paymentdatashow?.Credit) {
                if (creditpaymentdata?.stripekey) {
                    setUserdata((state) => ({
                        ...state,
                        paymentMethod: {
                            paymentID: creditpaymentdata?.stripekey,
                            paymentMethodId: paymentdata?.Credit_Card[0]?.id,
                        }
                    }))
                } else {
                    setErrormessage((state) => ({
                        ...state,
                        'payment': creditpaymentdata?.message
                    }))
                    fromisvalid = false;
                }
            }

        }

        if (fromisvalid) {
            saveorder()
        }

    }
    useEffect(() => {
        if (paymentdatashow?.Credit) {
            if (creditpaymentdata?.payment) {
                setFromstaps({ one: false, two: false, three: true })
                let setupdatearray = [...state?.checkoutItems]
                const newArray1 = cartdata.filter((obj1) => !setupdatearray.some((obj2) => obj2.id === obj1.id));
                localStorage.setItem('CartItems', JSON.stringify(newArray1));
            }
        }
        // eslint-disable-next-line
    }, [creditpaymentdata?.payment])
    const getpayment = async () => {
        try {
            let postdata = {}
            let res = await _Api(postdata, `checkout/get-payment`)
            if (res?.statusCode === 200) {
                setPaymentdata({
                    Bank: res?.data?.Bank ? res?.data?.Bank : [],
                    Credit_Card: res?.data?.Credit_Card ? res?.data?.Credit_Card : []
                })
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    useEffect(() => {
        getcountry();
        getshippings();
        getpayment()
    }, []);

    useEffect(() => {
        const Dateoption = [...new Array(31)].map((each, index) => ({
            label: index + 1,
            value: index + 1,
        }));
        setDobvalues((state) => ({
            ...state,
            date: Dateoption
        }));
        const monthoption = [
            {
                value: 1,
                label: "JAN",
            },
            {
                value: 2,
                label: "FEB",
            },
            {
                value: 3,
                label: "MAR",
            },
            {
                value: 4,
                label: "APR",
            },
            {
                value: 5,
                label: "MAY",
            },
            {
                value: 6,
                label: "JUN",
            },
            {
                value: 7,
                label: "JUL",
            },
            {
                value: 8,
                label: "AUG",
            },
            {
                value: 9,
                label: "SEP",
            },
            {
                value: 10,
                label: "OCT",
            },
            {
                value: 11,
                label: "NOV",
            },
            {
                value: 12,
                label: "DEC",
            },

        ]
        setDobvalues((state) => ({
            ...state,
            month: monthoption
        }));
        const current = new Date();
        const lastyear = current.getFullYear() - 5;
        const firstyear = lastyear - 50;
        const yearoption = [...new Array(lastyear - firstyear + 2)]
            .fill()
            .map((_, idx) => ({ label: firstyear + idx, value: firstyear + idx }));
        setDobvalues((state) => ({
            ...state,
            year: yearoption
        }));
        if (accessToken) {
            getuserdetails(monthoption);
            setFromshow(false)
        }
        // eslint-disable-next-line
    }, [])
    const saveorder = async () => {
        let array = [...state?.checkoutItems]
        array.forEach((result) =>
            delete result?.price
        )
        let obj = { ...userdata }
        let usershipping_addressobj = { ...obj?.shipping_address }
        if (usershipping_addressobj?.date_of_birth?.month?.value) {
            usershipping_addressobj.date_of_birth.month = userdata?.shipping_address?.date_of_birth?.month?.value
        }
        let userbilling = { ...obj?.billingaddress }
        if (userbilling?.date_of_birth?.month?.value) {
            userbilling.date_of_birth.month = userdata?.billingaddress?.date_of_birth?.month?.value
        }
        usershipping_addressobj.country = userdata?.shipping_address?.country?.value
        userbilling.country = userdata?.billingaddress?.country?.value
        if (paymentdatashow?.Credit) {
            obj.paymentMethod = {
                paymentID: creditpaymentdata?.stripekey,
                paymentMethodId: paymentdata?.Credit_Card[0]?.id,
            }
        }
        setLodaer(true)
        try {
            let postdata = {
                customer_id: obj?.customer_id ? obj?.customer_id : "",
                cart: array,
                shipping_method: obj?.shipping_method,
                shipping_address: usershipping_addressobj,
                paymentMethod: obj?.paymentMethod,
                default_billing: obj?.default_billing ? obj?.default_billing : false,
                billing_address: userbilling
            }
            let res = await _Api(postdata, `checkout/placeOrder`)
            if (res?.statusCode === 200) {
                setLastpagedata(res)
                setShippinglist([])
                setLodaer(false)
                setCreditpaymentdata((state) => ({
                    ...state,
                    submitdata: true
                }))
            } else {
                setErrormessage((state) => ({
                    ...state,
                    'ordererror': res?.message
                }))
                setLodaer(false)
            }
        }
        catch (err) {
            setLodaer(false)
            console.log(err, "err")
            setErrormessage((state) => ({
                ...state,
                'ordererror': err?.response?.data?.message,
            }))
        }
    }


    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        return emailRegex.test(email);
    };

    const CustomValueContainer = ({ children, ...props }) => {
        return (
            <ValueContainer {...props}>
                {React.Children.map(children, (child) =>
                    child && child.type !== Placeholder ? child : null
                )}
            </ValueContainer>
        );
    };

    return (
        <>
            <div className="shippingPage-main ecommerce-main-layout-bodyContent">
                {state?.checkoutItems?.length ?
                    <>
                        <div className="shippingPage-main-bothSideBox">
                            <form id="shippingStepform" className="shipping-step-mainForm">
                                <div className="shippingPage-main-bothSideBox-leftSide shipping">
                                    <div className="navigation-lineBox">
                                        <ul className="navigation-lineBox-container" id="progressbar">
                                            <li className="position-relative w-100 " id="account">
                                                {fromstaps?.two || fromstaps?.three ?
                                                    <div className="navigation-lineBox-container-firstStartBox w-100">
                                                        <img src={confirmicon} className="com-nav-lightPointer " alt="" />
                                                        <div className="com-nav-lineShow"></div>
                                                    </div> :
                                                    <div className="navigation-lineBox-container-firstStartBox w-100 active">
                                                        <span className="com-nav-lightPointer ">1</span>
                                                        <div className="com-nav-lineShow"></div>
                                                    </div>}
                                                <span className="navig-bottom-text">{t("Shipping")}</span>
                                            </li>
                                            <li className="position-relative w-100" id="personal">
                                                {fromstaps?.three ?
                                                    <div className={`navigation-lineBox-container-firstStartBox w-100 `}>
                                                        <img src={confirmicon} className="com-nav-lightPointer " alt="" />
                                                        <div className="com-nav-lineShow"></div>
                                                    </div> :
                                                    <div className={`navigation-lineBox-container-firstStartBox w-100 ${fromstaps?.two || fromstaps?.three ? "active" : ""}`}>
                                                        <span className="com-nav-lightPointer ">2</span>
                                                        <div className="com-nav-lineShow"></div>
                                                    </div>}

                                                <span className="navig-bottom-text">{t("Payment")}</span>
                                            </li>
                                            <li className="position-relative" id="payment">
                                                {fromstaps?.three ?
                                                    <div className={`navigation-lineBox-container-firstStartBox w-100`}>
                                                        <img src={confirmicon} className="com-nav-lightPointer " alt="" />
                                                        <div className="com-nav-lineShow"></div>
                                                    </div> :
                                                    <div className={`navigation-lineBox-container-firstStartBox w-100 ${fromstaps?.three ? "active" : ""} `}>
                                                        <span className="com-nav-lightPointer ">3</span>
                                                        <div className="com-nav-lineShow"></div>
                                                    </div>
                                                }
                                                <span className="navig-bottom-text">{t("Confirmation")}</span>
                                            </li>
                                        </ul>
                                        <div></div>
                                    </div>

                                    <div className="shipping-step-mainForm-Container">
                                        {fromstaps?.one ? <fieldset className="shipping-step-mainForm-Container-fieldsetBox">
                                            {!accessToken ?
                                                <div className="shipping-alertBox">
                                                    <p className="shipping-alertBox-paraAlert">{t("Already a member")}? <span
                                                        onClick={() => {
                                                            setOpenmodal(prevState => ({
                                                                ...prevState,
                                                                showLoginModel: true,
                                                                showRegisterModel: false,
                                                                showemailvarifymodal: false,
                                                                forgetpassword: false,
                                                                resetpassword: false,
                                                                newpassword: false
                                                            }))
                                                        }}> {t("Sign-in")}</span> {t("for faster checkout")} </p>
                                                    <p className="shipping-alertBox-paraAlert">{t("Don’t have an account")}? <span
                                                        onClick={() => {
                                                            setOpenmodal(prevState => ({
                                                                ...prevState,
                                                                showLoginModel: false,
                                                                showRegisterModel: true,
                                                                showemailvarifymodal: false,
                                                                forgetpassword: false,
                                                                resetpassword: false,
                                                                newpassword: false
                                                            }))
                                                        }}>{t("Create Account")}</span></p>
                                                </div>
                                                : ''}
                                            <div className="shipping-addressFromBox">
                                                <div className="shipping-addressFromBox-headBox d-flex">
                                                    <h3 className="com-shippingPage-headingMain mb-2 w-100">{t("Shipping Address")}</h3>
                                                    {accessToken && !userdata?.default_billing ?
                                                        <div className=" mt-2 w-100 text-end cursor-pointer" data-bs-toggle="modal" data-bs-target="#shiipingAddrmodal"
                                                            onClick={() => setShipaddress((state) => ({
                                                                ...state,
                                                                ship: true,
                                                                billing: false
                                                            }))}>{t("Change Shipping Address")}</div>
                                                        : ""}
                                                </div>
                                                {fromshow ?
                                                    <div className='addAdressBox-formBody'>
                                                        <div className='addAdressBox-formBody-stepfirst'>
                                                            <div className='addAdressBox-formBody-nameBox'>
                                                                <div className='com-formInputBox'>
                                                                    <label className=''>{t("First Name")} <span className='com-starShow'>*</span></label>
                                                                    <input
                                                                        className={errormessage?.fname ? 'form-control border-danger' : 'form-control'}
                                                                        value={userdata?.shipping_address?.fname}
                                                                        onChange={(e) => setUserdata({
                                                                            ...userdata,
                                                                            shipping_address: {
                                                                                ...userdata?.shipping_address,
                                                                                fname: e.target.value
                                                                            }
                                                                        })} />
                                                                    {errormessage?.fname ? <div className='text-danger'>{t(`${errormessage?.fname}`)}</div> : ""}
                                                                </div>
                                                                <div className='com-formInputBox'>
                                                                    <label className=''>{t("Middle Name")}</label>
                                                                    <input
                                                                        className='form-control'
                                                                        value={userdata?.shipping_address?.mname}
                                                                        onChange={(e) => setUserdata({
                                                                            ...userdata,
                                                                            shipping_address: {
                                                                                ...userdata?.shipping_address,
                                                                                mname: e.target.value
                                                                            }
                                                                        })} />
                                                                </div>
                                                                <div className='com-formInputBox'>
                                                                    <label className=''>{t("Last Name")} <span className='com-starShow'>*</span></label>
                                                                    <input
                                                                        className={errormessage?.lname ? 'form-control border-danger' : 'form-control'}
                                                                        value={userdata?.shipping_address?.lname}
                                                                        onChange={(e) => setUserdata({
                                                                            ...userdata,
                                                                            shipping_address: {
                                                                                ...userdata?.shipping_address,
                                                                                lname: e.target.value
                                                                            }
                                                                        })} />
                                                                    {errormessage?.lname ? <div className='text-danger'>{t(`${errormessage?.lname}`)}</div> : ""}
                                                                </div>
                                                            </div>
                                                            <div className='addAdressBox-formBody-nameBox'>

                                                                <div className='com-formInputBox'>
                                                                    <label className=''>{t("Nationality")} <span className='com-starShow'>*</span></label>
                                                                    <div className={errormessage?.nationality ? 'selectError_border border-danger' : 'selectError_border'
                                                                    }
                                                                    >
                                                                        <Select
                                                                            name='nationality'
                                                                            value={{ label: userdata?.shipping_address?.nationality, value: userdata?.shipping_address?.nationality }}
                                                                            onChange={(e) => setUserdata({
                                                                                ...userdata,
                                                                                shipping_address: {
                                                                                    ...userdata?.shipping_address,
                                                                                    nationality: e.value
                                                                                }
                                                                            })}
                                                                            options={nationalityoption}
                                                                            className="ecommerce-dropdown"
                                                                            components={{
                                                                                ValueContainer: CustomValueContainer,
                                                                            }}
                                                                            placeholder={t("Nationality")}
                                                                            menuPlacement="auto"
                                                                            classNamePrefix="common-select"
                                                                            getOptionLabel={(opt) => opt.label}
                                                                            isSearchable={true}
                                                                        />
                                                                    </div>
                                                                    {errormessage?.nationality ? <div className='text-danger'>{t(`${errormessage?.nationality}`)}</div> : ""}
                                                                </div>

                                                                <div className='com-formInputBox'>
                                                                    <label className=''>{t("Title")}<span className='com-starShow'>*</span></label>
                                                                    <div className={errormessage?.title ? 'selectError_border border-danger' : 'selectError_border'
                                                                    }
                                                                    >
                                                                        <Select
                                                                            value={{ label: userdata?.shipping_address.title, value: userdata?.shipping_address.title }}
                                                                            onChange={(e) => setUserdata({
                                                                                ...userdata,
                                                                                shipping_address: {
                                                                                    ...userdata?.shipping_address,
                                                                                    title: e.value
                                                                                }
                                                                            })}
                                                                            className="ecommerce-dropdown"
                                                                            placeholder={t("Title")}
                                                                            options={titleoption}
                                                                            components={{
                                                                                ValueContainer: CustomValueContainer,
                                                                            }}
                                                                            menuPlacement="auto"
                                                                            classNamePrefix="common-select"
                                                                            getOptionLabel={(e) => (
                                                                                <div className="">
                                                                                    <span>{e.label}</span>
                                                                                </div>
                                                                            )}
                                                                            isSearchable={false}
                                                                        />
                                                                    </div>
                                                                    {errormessage?.title ? <div className='text-danger'>{t(`${errormessage?.title}`)}</div> : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='addAdressBox-formBody-stepfirst pt-0'>
                                                            {userdata?.shipping_address?.govt_ids?.length ? userdata?.shipping_address?.govt_ids.map((result, key) => {
                                                                return (
                                                                    <div className='addAdressBox-formBody-nameBox' key={key}>
                                                                        <div className='com-formInputBox'>
                                                                            <label className=''>{t("ID")} <span className='com-starShow'>*</span></label>
                                                                            <div className={errormessage?.govt_ids ? 'selectError_border     border-danger' : 'selectError_border'
                                                                            }
                                                                            >
                                                                                <Select
                                                                                    className='ecommerce-dropdown'
                                                                                    options={idProofOption}
                                                                                    classNamePrefix="common-select"
                                                                                    value={{ label: result?.idtype, value: result?.idtype }}
                                                                                    onChange={(e) => ChangeMultipleSelect(e?.value, key, 'govt_ids', result?.idnumber)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='com-formInputBox'>
                                                                            <label className=''>{t("ID Number")} <span className='com-starShow'>*</span></label>
                                                                            <div className='formBox-withMinusBtn border-0 d-grid'>
                                                                                <div className="d-flex align-items-center w-100">
                                                                                    <input
                                                                                        className={errormessage?.govt_ids ? 'form-control border-danger' : 'form-control'
                                                                                        }
                                                                                        value={result?.idnumber}
                                                                                        onChange={(e) => ChangeMultipleSelect(result?.idtype, key, 'govt_ids', e.target.value)} />
                                                                                    {key !== 0 ? <span className='formBox-withMinusBtn-removebtn handleIcon ms-2' onClick={() => RemoveBlock('govt_ids', key)}><MinusIcon /></span> : ''}

                                                                                </div>
                                                                                {errormessage?.govt_ids ? <div className='text-danger'>{t(`${errormessage?.govt_ids}`)}</div> : ""}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                )
                                                            }) : ''}

                                                            <div className='com-addFormItem-box'>
                                                                <div className='com-addFormItem-box-inner' onClick={(e) => AddRow('govt_ids')}>
                                                                    <span className='d-flex'><PlusIcon width={16} height={16} color={'#1CACAC'} /></span>
                                                                    {t("Add ID")}
                                                                </div>
                                                            </div>
                                                            <div className='addAdressBox-formBody-nameBox'>
                                                                <div className='com-formInputBox'>
                                                                    <label className=''>{t("Birthdate (Date, Month, Year)")} <span className='com-starShow'>*</span></label>

                                                                    <div className="birthday-calendarBox">
                                                                        <div className="birthday-calendarBox-inner col-md-4 col-sm-12 col-3">
                                                                            <div className={errormessage?.date_of_birth ? 'selectError_border border-danger' : 'selectError_border'}
                                                                            >
                                                                                <Select
                                                                                    className="ecommerce-dropdown withrtlfix"
                                                                                    placeholder=""
                                                                                    options={dobvalues?.date}
                                                                                    value={{ value: userdata?.shipping_address?.date_of_birth?.date, label: userdata?.shipping_address?.date_of_birth?.date }}
                                                                                    onChange={(e) => setUserdata((state) => ({
                                                                                        ...state,
                                                                                        shipping_address: {
                                                                                            ...state?.shipping_address,
                                                                                            date_of_birth: {
                                                                                                ...state?.shipping_address?.date_of_birth,
                                                                                                date: e.value
                                                                                            }
                                                                                        }
                                                                                    }))}
                                                                                    classNamePrefix="common-select"
                                                                                    isSearchable={false}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="birthday-calendarBox-inner col-md-4 col-sm-12 col-3">
                                                                            <div className={errormessage?.date_of_birth ? 'selectError_border border-danger' : 'selectError_border'}
                                                                            >
                                                                                <Select
                                                                                    className="ecommerce-dropdown withrtlfix"
                                                                                    placeholder=""
                                                                                    options={dobvalues?.month}
                                                                                    value={userdata?.shipping_address?.date_of_birth?.month || null}
                                                                                    onChange={(e) => setUserdata((state) => ({
                                                                                        ...state,
                                                                                        shipping_address: {
                                                                                            ...state?.shipping_address,
                                                                                            date_of_birth: {
                                                                                                ...state?.shipping_address?.date_of_birth,
                                                                                                month: e
                                                                                            }
                                                                                        }
                                                                                    }))}
                                                                                    classNamePrefix="common-select"
                                                                                    isSearchable={false}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="birthday-calendarBox-inner col-md-4 col-sm-12 col-3">
                                                                            <div className={errormessage?.date_of_birth ? 'selectError_border border-danger' : 'selectError_border'}
                                                                            >
                                                                                <Select
                                                                                    className="ecommerce-dropdown withrtlfix"
                                                                                    placeholder=""
                                                                                    options={dobvalues?.year}
                                                                                    value={{
                                                                                        value: userdata?.shipping_address?.date_of_birth?.year,
                                                                                        label: userdata?.shipping_address?.date_of_birth?.year
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        setUserdata((state) => ({
                                                                                            ...state,
                                                                                            shipping_address: {
                                                                                                ...state?.shipping_address,
                                                                                                date_of_birth: {
                                                                                                    ...state?.shipping_address?.date_of_birth,
                                                                                                    year: e.value
                                                                                                }
                                                                                            }
                                                                                        }));
                                                                                        callAge(e);
                                                                                    }}
                                                                                    classNamePrefix="common-select"
                                                                                    isSearchable={false}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    {errormessage?.date_of_birth ? <div className='text-danger'>{t(`${errormessage?.date_of_birth}`)}</div> : ""}
                                                                </div>
                                                                <div className='com-formInputBox'>
                                                                    <label className=''>{t("Age")} <span className='com-starShow'>*</span></label>
                                                                    <input
                                                                        disabled
                                                                        className={errormessage?.age ? 'form-control border-danger' : 'form-control'}
                                                                        value={userdata?.shipping_address?.age}
                                                                        onChange={(e) => setUserdata({
                                                                            ...userdata,
                                                                            shipping_address: {
                                                                                ...userdata?.shipping_address,
                                                                                age: e.target.value
                                                                            }
                                                                        })} />
                                                                    {errormessage?.age ? <div className='text-danger'>{t(`${errormessage?.age}`)}</div> : ""}
                                                                </div>
                                                            </div>
                                                            <div className='gender-checkBox'>
                                                                <div className='com-formInputBox'>
                                                                    <label className=''>{t("Gender")}<span className='com-starShow'>*</span></label>
                                                                </div>
                                                                <div className='gender-checkBox-checkContainer d-block'>
                                                                    <div className="d-flex w-100">
                                                                        {gender.map((result, key) => {
                                                                            return (
                                                                                <div className='gender-checkBox-checkContainer-singleCheck' key={key}>
                                                                                    <input type="radio" id={result} checked={userdata?.shipping_address?.gender === result ? true : false} name="gender" value={result} onChange={(e) => setUserdata({
                                                                                        ...userdata,
                                                                                        shipping_address: {
                                                                                            ...userdata?.shipping_address,
                                                                                            gender: e.target.value
                                                                                        }
                                                                                    })} />
                                                                                    <label htmlFor={result}>{t(`${result}`)}</label>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                    {errormessage?.gender ? <div className='text-danger'>{t(`${errormessage?.gender}`)}</div> : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='addAdressBox-formBody-stepfirst pt-0 mb-0'>
                                                            <h3 className='contact-header-box'>{t("Contact")}</h3>
                                                            <div className='addAdressBox-formBody-nameBox'>
                                                                <div className='com-formInputBox'>
                                                                    <label className=''>{t("Address")} <span className='com-starShow'>*</span></label>
                                                                    <input
                                                                        className={errormessage?.address ? 'form-control border-danger' : 'form-control'}
                                                                        value={userdata?.shipping_address?.address}
                                                                        onChange={(e) => setUserdata({
                                                                            ...userdata,
                                                                            shipping_address: {
                                                                                ...userdata?.shipping_address,
                                                                                address: e.target.value
                                                                            }
                                                                        })} />
                                                                    {errormessage?.address ? <div className='text-danger'>{t(`${errormessage?.address}`)}</div> : ""}
                                                                </div>
                                                            </div>
                                                            <div className='addAdressBox-formBody-nameBox fullscreen-select'>
                                                                <div className='com-formInputBox'>

                                                                    <label className=''>{t("Country")} <span className='com-starShow'>*</span></label>
                                                                    <div className={errormessage?.country ? 'selectError_border border-danger' : 'selectError_border'
                                                                    }
                                                                    >
                                                                        <Select
                                                                            className="ecommerce-dropdown"
                                                                            options={countrylist}
                                                                            value={userdata?.shipping_address?.country}
                                                                            onChange={(e) => setUserdata({
                                                                                ...userdata,
                                                                                shipping_address: {
                                                                                    ...userdata?.shipping_address,
                                                                                    country: e
                                                                                }
                                                                            })}
                                                                            classNamePrefix="common-select"
                                                                            isSearchable={widtth > 560 ? true : false}
                                                                        />
                                                                    </div>
                                                                    {errormessage?.country ? <div className='text-danger'>{t(`${errormessage?.country}`)}</div> : ""}
                                                                </div>
                                                                <div className='com-formInputBox'>
                                                                    <label className=''>{t("Province/State")} <span className='com-starShow'>*</span></label>
                                                                    <input
                                                                        className={errormessage?.state ? 'form-control border-danger' : 'form-control'}
                                                                        value={userdata?.shipping_address?.state}
                                                                        onChange={(e) => setUserdata({
                                                                            ...userdata,
                                                                            shipping_address: {
                                                                                ...userdata?.shipping_address,
                                                                                state: e.target.value
                                                                            }
                                                                        })} />
                                                                    {errormessage?.state ? <div className='text-danger'>{t(`${errormessage?.state}`)}</div> : ""}
                                                                </div>
                                                            </div>
                                                            <div className='addAdressBox-formBody-nameBox'>
                                                                <div className='com-formInputBox'>
                                                                    <label className=''>{t("City")} <span className='com-starShow'>*</span></label>
                                                                    <input
                                                                        className={errormessage?.city ? 'form-control border-danger' : 'form-control'}
                                                                        value={userdata?.shipping_address?.city}
                                                                        onChange={(e) => setUserdata({
                                                                            ...userdata,
                                                                            shipping_address: {
                                                                                ...userdata?.shipping_address,
                                                                                city: e.target.value
                                                                            }
                                                                        })} />
                                                                    {errormessage?.city ? <div className='text-danger'>{t(`${errormessage?.city}`)}</div> : ""}
                                                                </div>
                                                                <div className='com-formInputBox'>
                                                                    <label className=''>{t("Zip Code")} <span className='com-starShow'>*</span></label>
                                                                    <input
                                                                        className={errormessage?.zipcode ? 'form-control border-danger' : 'form-control'}
                                                                        value={userdata?.shipping_address?.zipcode}
                                                                        onChange={(e) => setUserdata({
                                                                            ...userdata,
                                                                            shipping_address: {
                                                                                ...userdata?.shipping_address,
                                                                                zipcode: e.target.value
                                                                            }
                                                                        })} />
                                                                    {errormessage?.zipcode ? <div className='text-danger'>{t(`${errormessage?.zipcode}`)}</div> : ""}
                                                                </div>
                                                            </div>
                                                            <div className='addAdressBox-formBody-nameBox align-items-start'>
                                                                <div className='com-formInputBox'>
                                                                    <label className=''>{t("Contact")} <span className='com-starShow'>*</span></label>
                                                                    <div className='form-phoneAddContainer'>
                                                                        {userdata?.shipping_address?.contacts?.length ? userdata?.shipping_address?.contacts.map((result, key) => {
                                                                            return (
                                                                                <>


                                                                                    <div className="d-flex align-items-start w-100" key={key}>
                                                                                        <div
                                                                                            // className={errormessage?.contacts ? 'formBox-withMinusBtn border-danger' : 'formBox-withMinusBtn '}
                                                                                            className={errormessage?.contacts ? 'd-flex align-items-start border border-danger rounded w-100' : "d-flex align-items-start border rounded w-100"}
                                                                                        >
                                                                                            <div className='selectError_border w-50 border-0'>
                                                                                                <Select
                                                                                                    options={phonecodelist}
                                                                                                    className="ecommerce-dropdown w-100"
                                                                                                    classNamePrefix="common-select"
                                                                                                    value={{ 'label': result?.phoneCode, 'value': result?.phoneCode }}
                                                                                                    onChange={(e) => ChangeMultipleSelect(e?.value, key, 'contacts', result?.number)}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="d-grid w-100">

                                                                                                <div className="d-flex align-items-center">
                                                                                                    <input
                                                                                                        type='number'
                                                                                                        className='form-control border-0'
                                                                                                        value={result?.number}
                                                                                                        onChange={(e) => ChangeMultipleSelect(result?.phoneCode, key, 'contacts', e.target.value)}
                                                                                                    />
                                                                                                </div>


                                                                                            </div>
                                                                                        </div>
                                                                                        {key !== 0 ? <span className='formBox-withMinusBtn-removebtn handleIcon ms-2 mt-2' onClick={() => RemoveBlock('contacts', key)}><MinusIcon /></span> : ''}
                                                                                    </div>
                                                                                    {errormessage?.contacts ? <div className='text-danger'>{t(`${errormessage?.contacts}`)}</div> : ""}
                                                                                </>
                                                                            )
                                                                        }) : ''}

                                                                        <div className='com-addFormItem-box'>
                                                                            <div className='com-addFormItem-box-inner' onClick={() => AddRow('contacts')}>
                                                                                <span className='d-flex'><PlusIcon width={16} height={16} color={'#1CACAC'} /></span>
                                                                                {t("Add contacts")}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='com-formInputBox'>
                                                                    <label className=''>{t("Email")} <span className='com-starShow'>*</span></label>
                                                                    <div className='form-phoneAddContainer'>
                                                                        {userdata?.shipping_address?.emails?.length ? userdata?.shipping_address?.emails?.map((result, key) => {
                                                                            return (
                                                                                <>
                                                                                    <div className='formBox-withMinusBtn border-0' key={key}>
                                                                                        <input
                                                                                            className={errormessage?.emails ? 'form-control border-danger' : 'form-control'}
                                                                                            value={result} onChange={(e) => ChangeMultipleSelect(e.target.value, key, 'email', '')} />

                                                                                        {key !== 0 ? <span className='formBox-withMinusBtn-removebtn handleIcon' onClick={() => RemoveBlock('email', key)}><MinusIcon /></span> : ''}
                                                                                    </div>
                                                                                    {errormessage?.emails ? <div className='text-danger'>{t(`${errormessage?.emails}`)}</div> : ""}
                                                                                </>
                                                                            );
                                                                        }) : ''}

                                                                        <div className='com-addFormItem-box'>
                                                                            <div className='com-addFormItem-box-inner' onClick={() => AddRow('email')}>
                                                                                <span className='d-flex'><PlusIcon width={16} height={16} color={'#1CACAC'} /></span>
                                                                                {t("Add Email")}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="addAdressBox-formBody-radioSwitchBox">
                                                                <p className="addAdressBox-formBody-radioSwitchBox-textShow">{t("Default Shipping Address")}</p>
                                                                <div className="com-customToggleCheck">
                                                                    <input type="checkbox" id="togglebtn1" className="com-customToggleCheck-toogleInput"
                                                                        defaultChecked={userdata?.shipping_address?.is_default ? true : false}
                                                                        onChange={(e) => setUserdata((state) => ({
                                                                            ...userdata,
                                                                            shipping_address: {
                                                                                ...userdata?.shipping_address, is_default: e.target.checked ? 1 : 0
                                                                            }
                                                                        }))} />
                                                                    <label htmlFor="togglebtn1" className="com-customToggleCheck-toggleLabel"></label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div> :
                                                    <div className="shipping-addressFromBox paymentStepPage-main-paymntAddrBox">
                                                        <div className="com-addressShowBox paymentStepPage-main-paymntAddrBox-addressSetBox">
                                                            <div className="com-addressShowBox-headerBox">
                                                                <h3 className="com-addressShowBox-headerBox-heading">
                                                                    {userdata?.shipping_address?.fname + ' ' + (userdata?.shipping_address?.mname ? userdata?.shipping_address?.mname : '') + ' ' + userdata?.shipping_address?.lname}
                                                                </h3>
                                                                <div className="com-addressShowBox-headerBox-iconBox" >
                                                                    <span onClick={() => { setFromshow(true) }}>
                                                                        <EditIcon />{" "}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <p className="com-addressShowBox-AddrparaBox fixed-widthSet">
                                                                {userdata?.shipping_address?.address ? userdata?.shipping_address?.address + " " + userdata?.shipping_address?.city + " " + userdata?.shipping_address?.state + " " + userdata?.shipping_address?.zipcode : ""}
                                                            </p>
                                                            <p className="com-addressShowBox-phonePara">
                                                                {userdata?.shipping_address?.contacts?.[0]?.['number'] ?
                                                                    `(+${userdata?.shipping_address?.contacts?.[0]?.['phoneCode']}) ${userdata?.shipping_address?.contacts?.[0]?.['number']}`
                                                                    : ''}
                                                            </p>
                                                        </div>
                                                    </div>
                                                }
                                                {shippingmethod?.shiptohome?.length || shippingmethod?.storepickup?.length ?
                                                    <div className="shipping-addressFromBox-shipngMethodBox">
                                                        <h3 className="com-shippingPage-headingMain">{t("Select Shipping Method")}</h3>
                                                        <div className="shipping-addressFromBox-shipngMethodBox-selectMethod">
                                                            {shippingmethod?.shiptohome?.length ?
                                                                <div className="com-radiobtnShowBox">
                                                                    <input type="radio" onChange={() => setChangeshipping(true)} className="com-radiobtnShowBox-inputField" id="shipHome" name="shippingOption" checked={changeshipping ? true : false} />
                                                                    <label className="com-radiobtnShowBox-labeltext" htmlFor="shipHome">{t("Ship to home")}</label>
                                                                </div>
                                                                : ""}
                                                            {shippingmethod?.storepickup?.length ?
                                                                <div className="com-radiobtnShowBox">
                                                                    <input type="radio" onChange={() => setChangeshipping(false)} className="com-radiobtnShowBox-inputField" id="storePickup" name="shippingOption" checked={!changeshipping ? true : false} />
                                                                    <label className="com-radiobtnShowBox-labeltext" htmlFor="storePickup">{t("Store Pickup")}</label>
                                                                </div>
                                                                : ""}
                                                        </div>
                                                        {changeshipping ?
                                                            shippingmethod?.shiptohome?.length ? shippingmethod?.shiptohome.map((result, key) => {
                                                                return (
                                                                    <div className="shipping-addressFromBox-shipngMethodBox-homeDeliveryAddrBox" key={key}>
                                                                        <div className="shipping-addressFromBox-shipngMethodBox-homeDeliveryAddrBox-selectStandard" onClick={() => {
                                                                            setUserdata({ ...userdata, shipping_method: { shiptohome: result?._id } }); setShippingdata((state) => ({
                                                                                ...state,
                                                                                shipprice: result?.price
                                                                            }))
                                                                        }}>
                                                                            <div className="shipping-addressFromBox-shipngMethodBox-homeDeliveryAddrBox-selectStandard-radioBox">
                                                                                <div className="com-radiobtnShowBox" >
                                                                                    <input type="radio" className="com-radiobtnShowBox-inputField"
                                                                                        id={result?._id + key} name="shiphome"
                                                                                        checked={userdata?.shipping_method?.shiptohome === result?._id}
                                                                                    />
                                                                                    <label className="com-radiobtnShowBox-labeltext"
                                                                                        htmlFor={result?._id + key}></label>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="shipping-addressFromBox-shipngMethodBox-homeDeliveryAddrBox-selectStandard-shippDetailsBox">
                                                                                <div className="standardShipping-Show">
                                                                                    <p className="standardShipping-Show-headingText">{t(`${result?.type}`)}</p>
                                                                                    <p className="standardShipping-Show-arrivesText">{t(`${result?.description}`)}</p>
                                                                                </div>
                                                                                <h2 className="shippingCostAmt">{result?.formatted_price}</h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }) : ''
                                                            :

                                                            <div className="d-flex gap-3 flex-wrap w-100 justify-content-between">
                                                                {shippingmethod?.storepickup?.length ? shippingmethod?.storepickup.map((result, key) => {
                                                                    const items = [];

                                                                    for (let key in result?.store_timeSchedule) {

                                                                        items.push(<> <div className="shipping-timeSetBox-singleDay" key={key}><p className="userShippAddressBox-contentBox-lightText"> {`${key}`}</p>
                                                                            <p className="userShippAddressBox-contentBox-lightText">{`${result?.store_timeSchedule[key]}`}</p></div></>)

                                                                    }

                                                                    return (
                                                                        <div className="userShippAddressBox" key={key}>
                                                                            <div className="userShippAddressBox-selectBox">
                                                                                <div className="com-radiobtnShowBox">
                                                                                    <input type="radio"
                                                                                        onClick={() => {
                                                                                            setUserdata({ ...userdata, shipping_method: { storepick: result?._id } }); setShippingdata((state) => ({
                                                                                                ...state,
                                                                                                shipprice: 0
                                                                                            }))
                                                                                        }}
                                                                                        className="com-radiobtnShowBox-inputField" id={result?._id} name="selectAdd"
                                                                                        checked={userdata?.shipping_method?.storepick === result?._id} />
                                                                                    <label className="com-radiobtnShowBox-labeltext" htmlFor={result?._id}></label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="userShippAddressBox-contentBox">
                                                                                <div className="userDetailShipp">
                                                                                    <h3 className="userShippAddressBox-contentBox-boldText">{result?.name}</h3>
                                                                                    <p className="userShippAddressBox-contentBox-paraText">{result?.store_Address}</p>
                                                                                </div>
                                                                                <div className="d-flex gap-1">
                                                                                    <h3 className="userShippAddressBox-contentBox-boldText">{t("contacts number")}:</h3>
                                                                                    <p className="userShippAddressBox-contentBox-lightText">{result?.contacts}</p>
                                                                                </div>
                                                                                <div className="d-flex gap-1" >
                                                                                    <h3 className="userShippAddressBox-contentBox-boldText">{t("Store Hours")}</h3>
                                                                                    <p className="userShippAddressBox-contentBox-lightText"></p>
                                                                                </div>
                                                                                <div className="d-flex gap-5">
                                                                                    <div className=" shipping-timeSetBox">
                                                                                        {items?.length ? items.map((result) => {
                                                                                            return (result)
                                                                                        }) : ''}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }) : ''}

                                                            </div>

                                                        }
                                                        {errormessage?.shipping_method ? <div className='text-danger'>{t(`${errormessage?.shipping_method}`)}</div> : ""}

                                                    </div> :
                                                    shippingmethod !== undefined ?
                                                        <div className="mt-5">
                                                            <div className="text-danger">{t("You Don’t have a shipping method")}</div>
                                                        </div> : ""
                                                }
                                            </div>
                                            <div className="shipping-formBottom-btn">
                                                <div className='shipping-formBottom-btn-backCartBtnBox'><span className='arrowIconCart'><LeftSideArrowIcon width={16.71} height={16.71} /></span >
                                                    <Link className="text-decoration-none" to="/shoppingcart" state={{ shippingdata: shippinglist }}><span className='cartBackShow'>{t("Return to cart")}</span></Link></div>
                                                <button type="button" className='com-ecomSendBtn shipping-continBtn'
                                                    onClick={() => validationfun(userdata)}>{t("Continue")}</button>
                                            </div>
                                        </fieldset> :
                                            fromstaps?.two ?
                                                <fieldset className="shipping-step-mainForm-Container-fieldsetBox">
                                                    <div className="paymentStepPage">
                                                        <div className="shipping-addressFromBox paymentStepPage-main">
                                                            <div className="shipping-addressFromBox paymentStepPage-main-paymntAddrBox">
                                                                <h3 className="com-shippingPage-headingMain m-0">{t("Shipping Address")}</h3>
                                                                <div className="com-addressShowBox paymentStepPage-main-paymntAddrBox-addressSetBox">
                                                                    <div className="com-addressShowBox-headerBox">
                                                                        <h3 className="com-addressShowBox-headerBox-heading">
                                                                            {userdata?.shipping_address?.fname + ' ' + (userdata?.shipping_address?.mname ? userdata?.shipping_address?.mname : '') + ' ' + userdata?.shipping_address?.lname}
                                                                        </h3>
                                                                        <div className="com-addressShowBox-headerBox-iconBox" >
                                                                            <span onClick={() => { setFromstaps({ one: true, two: false, three: false }); setFromshow(true) }} >
                                                                                <EditIcon />{" "}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <p className="com-addressShowBox-AddrparaBox fixed-widthSet">
                                                                        {userdata?.shipping_address?.address ? userdata?.shipping_address?.address + " " + userdata?.shipping_address?.city + " " + userdata?.shipping_address?.state + " " + userdata?.shipping_address?.zipcode : ""}
                                                                    </p>
                                                                    <p className="com-addressShowBox-phonePara">
                                                                        {userdata?.shipping_address?.contacts?.[0]?.['number'] ?
                                                                            `(+${userdata?.shipping_address?.contacts?.[0]?.['phoneCode']}) ${userdata?.shipping_address?.contacts?.[0]?.['number']}`
                                                                            : ''}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="paymentStepPage-main-paymentMethodBox">
                                                                <div className="paymentStepPage-main-paymentMethodBox-headBox">
                                                                    <h3 className="com-shippingPage-headingMain m-0">
                                                                        {t("Select Payment Method")}
                                                                    </h3>
                                                                    <div className="conditionPriv-checkBox m-0">
                                                                        <div className="com-ecomCheckBox">
                                                                            <input
                                                                                value={userdata?.default_billing}
                                                                                type="checkbox"
                                                                                className=" com-ecomCheckBox-inputcheck me-2 cursor-pointer"
                                                                                id="AddrCondition"
                                                                                onChange={(e) => ChangeCheck('default_billing', e)}
                                                                            />
                                                                            <label
                                                                                className="com-ecomCheckBox-labelBox cursor-pointer fw-semibold"
                                                                                htmlFor="AddrCondition"
                                                                            >
                                                                                {t("Billing address same as shipping details")}.
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {accessToken && !userdata?.default_billing ?
                                                                    <div className="text-end">
                                                                        <span
                                                                            className="cursor-pointer"
                                                                            data-bs-toggle="modal" data-bs-target="#shiipingAddrmodal"
                                                                            onClick={() => setShipaddress((state) => ({
                                                                                ...state,
                                                                                billing: true,
                                                                                ship: false
                                                                            }))}>{t("Change Billing Address")}</span>
                                                                    </div>
                                                                    : ""}
                                                                <div className="paymentStepPage-main-paymentMethodBox-billingAddrBox">
                                                                    {billingaddressfrom ?
                                                                        <div className="com-addressShowBox paymentStepPage-main-paymntAddrBox-addressSetBox">
                                                                            <div className="com-addressShowBox-headerBox">
                                                                                <h3 className="com-addressShowBox-headerBox-heading">
                                                                                    {userdata?.billingaddress?.fname + ' ' + (userdata?.billingaddress?.mname ? userdata?.billingaddress?.mname : '') + ' ' + userdata?.billingaddress?.lname}
                                                                                </h3>
                                                                                <div className="com-addressShowBox-headerBox-iconBox">
                                                                                    <span onClick={() => setBillingaddress(false)}>
                                                                                        <EditIcon />{" "}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <p className="com-addressShowBox-AddrparaBox fixed-widthSet">
                                                                                {userdata?.billingaddress?.address ? userdata?.billingaddress?.address + " " + userdata?.billingaddress?.city + " " + userdata?.billingaddress?.state + " " + userdata?.billingaddress?.zipcode : ""}
                                                                            </p>
                                                                            <p className="com-addressShowBox-phonePara">
                                                                                {userdata?.billingaddress?.contacts?.[0]?.['number'] ?
                                                                                    `(+${userdata?.billingaddress?.contacts?.[0]?.['phoneCode']}) ${userdata?.billingaddress?.contacts?.[0]?.['number']}`
                                                                                    : ''}
                                                                            </p>
                                                                        </div> :
                                                                        <form className="form-billingAddress">
                                                                            <h3 className="form-billingAddress-headBilling">
                                                                                {t("Billing Address")}
                                                                            </h3>
                                                                            <div className="form-billingAddress-multifieldRow">
                                                                                <div className="com-formInputBox">
                                                                                    <label className="">
                                                                                        {t("First Name")} <span className="com-starShow">*</span>
                                                                                    </label>
                                                                                    <input
                                                                                        className={errormessage?.billingfname ? 'form-control border-danger' : 'form-control'}
                                                                                        value={userdata?.billingaddress?.fname ? userdata?.billingaddress?.fname : ""}
                                                                                        disabled={userdata?.default_billing ? true : false}
                                                                                        onChange={(e) => setUserdata({
                                                                                            ...userdata,
                                                                                            billingaddress: { ...userdata?.billingaddress, fname: e.target.value }
                                                                                        })} />
                                                                                    {errormessage?.billingfname ? <div className='text-danger'>{t(`${errormessage?.billingfname}`)}</div> : ""}
                                                                                </div>
                                                                                <div className="com-formInputBox">
                                                                                    <label className="">
                                                                                        {t("Last Name")} <span className="com-starShow">*</span>
                                                                                    </label>
                                                                                    <input
                                                                                        className={errormessage?.billinglname ? 'form-control border-danger' : 'form-control'}
                                                                                        value={userdata?.billingaddress?.lname ? userdata?.billingaddress?.lname : ""}
                                                                                        disabled={userdata?.default_billing ? true : false}
                                                                                        onChange={(e) => setUserdata({
                                                                                            ...userdata,
                                                                                            billingaddress: { ...userdata?.billingaddress, lname: e.target.value }
                                                                                        })} />
                                                                                    {errormessage?.billinglname ? <div className='text-danger'>{t(`${errormessage?.billinglname}`)}</div> : ""}
                                                                                </div>
                                                                            </div>
                                                                            <div className="com-formInputBox">
                                                                                <label className="">
                                                                                    {t("Address")}<span className="com-starShow">*</span>
                                                                                </label>
                                                                                <input
                                                                                    className={errormessage?.billingaddress ? 'form-control border-danger' : 'form-control'}
                                                                                    value={userdata?.billingaddress?.address ? userdata?.billingaddress?.address : ""}
                                                                                    disabled={userdata?.default_billing ? true : false}
                                                                                    onChange={(e) => setUserdata({
                                                                                        ...userdata,
                                                                                        billingaddress: { ...userdata?.billingaddress, address: e.target.value }
                                                                                    })} />
                                                                                {errormessage?.billingaddress ? <div className='text-danger'>{t(`${errormessage?.billingaddress}`)}</div> : ""}
                                                                            </div>
                                                                            <div className="form-billingAddress-multifieldRow">
                                                                                <div className="com-formInputBox">
                                                                                    <label className="">
                                                                                        {t("Country")} <span className="com-starShow">*</span>
                                                                                    </label>
                                                                                    <Select
                                                                                        className="ecommerce-dropdown"
                                                                                        options={countrylist}
                                                                                        isDisabled={userdata?.default_billing ? true : false}
                                                                                        value={userdata?.billingaddress?.country}
                                                                                        onChange={(e) => setUserdata({
                                                                                            ...userdata,
                                                                                            billingaddress: { ...userdata?.billingaddress, country: e }
                                                                                        })}
                                                                                        classNamePrefix="common-select"
                                                                                    />
                                                                                    {errormessage?.billingcountry ? <div className='text-danger'>{t(`${errormessage?.billingcountry}`)}</div> : ""}
                                                                                </div>
                                                                                <div className="com-formInputBox">
                                                                                    <label className="">
                                                                                        {t("Province/State")} <span className="com-starShow">*</span>
                                                                                    </label>
                                                                                    <input
                                                                                        className={errormessage?.billingstate ? 'form-control border-danger' : 'form-control'}
                                                                                        value={userdata?.billingaddress?.state ? userdata?.billingaddress?.state : ""}
                                                                                        disabled={userdata?.default_billing ? true : false}
                                                                                        onChange={(e) => setUserdata({
                                                                                            ...userdata,
                                                                                            billingaddress: { ...userdata?.billingaddress, state: e.target.value }
                                                                                        })} />
                                                                                    {errormessage?.billingstate ? <div className='text-danger'>{t(`${errormessage?.billingstate}`)}</div> : ""}
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-billingAddress-multifieldRow">
                                                                                <div className='com-formInputBox'>
                                                                                    <label className=''>{t("City")} <span className='com-starShow'>*</span></label>
                                                                                    <input
                                                                                        className={errormessage?.billingcity ? 'form-control border-danger' : 'form-control'}
                                                                                        value={userdata?.billingaddress?.city ? userdata?.billingaddress?.city : ""}
                                                                                        disabled={userdata?.default_billing ? true : false}
                                                                                        onChange={(e) => setUserdata({
                                                                                            ...userdata,
                                                                                            billingaddress: { ...userdata?.billingaddress, city: e.target.value }
                                                                                        })} />
                                                                                    {errormessage?.billingcity ? <div className='text-danger'>{t(`${errormessage?.billingcity}`)}</div> : ""}
                                                                                </div>
                                                                                <div className='com-formInputBox'>
                                                                                    <label className=''>{t("Zip Code")} <span className='com-starShow'>*</span></label>
                                                                                    <input
                                                                                        className={errormessage?.billingzipcode ? 'form-control border-danger' : 'form-control'}
                                                                                        value={userdata?.billingaddress?.zipcode ? userdata?.billingaddress?.zipcode : ""}
                                                                                        disabled={userdata?.default_billing ? true : false}
                                                                                        onChange={(e) =>
                                                                                            setUserdata({
                                                                                                ...userdata,
                                                                                                billingaddress: { ...userdata?.billingaddress, zipcode: e.target.value }
                                                                                            })} />
                                                                                    {errormessage?.billingzipcode ? <div className='text-danger'>{t(`${errormessage?.billingzipcode}`)}</div> : ""}
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-billingAddress-multifieldRow">
                                                                                <div className='com-formInputBox'>
                                                                                    <label className=''>{t("Contact Number")} <span className='com-starShow'>*</span></label>
                                                                                    <div className='form-phoneAddContainer'>
                                                                                        {userdata?.billingaddress?.contacts?.length ? userdata?.billingaddress?.contacts.map((result, key) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <div className="d-flex align-items-start w-100" key={key}>
                                                                                                        <div
                                                                                                            className={errormessage?.billingcontacts ? 'd-flex align-items-start border border-danger rounded w-100' : "d-flex align-items-start border rounded w-100"}
                                                                                                        >
                                                                                                            <div className='selectError_border w-50 border-0'>
                                                                                                                <Select
                                                                                                                    options={phonecodelist}
                                                                                                                    className="ecommerce-dropdown w-100"
                                                                                                                    classNamePrefix="common-select"
                                                                                                                    value={{ 'label': result?.phoneCode, 'value': result?.phoneCode }}
                                                                                                                    onChange={(e) =>
                                                                                                                        setUserdata({
                                                                                                                            ...userdata,
                                                                                                                            billingaddress: { ...userdata?.billingaddress, contacts: [{ icon: 1, phoneCode: e.value, number: result?.number }] }
                                                                                                                        })}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div className="d-grid w-100">

                                                                                                                <div className="d-flex align-items-center">
                                                                                                                    <input
                                                                                                                        type='number'
                                                                                                                        className='form-control border-0'
                                                                                                                        value={result?.number}
                                                                                                                        onChange={(e) =>
                                                                                                                            setUserdata({
                                                                                                                                ...userdata,
                                                                                                                                billingaddress: { ...userdata?.billingaddress, contacts: [{ icon: 1, phoneCode: result?.phoneCode, number: e.target.value }] }
                                                                                                                            })}
                                                                                                                    />
                                                                                                                </div>


                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        }) : ''}
                                                                                        {errormessage?.billingcontacts ? <div className='text-danger'>{t(`${errormessage?.billingcontacts}`)}</div> : ""}
                                                                                    </div>

                                                                                </div>
                                                                                <div className='com-formInputBox'>
                                                                                    <label className=''>{t("Email")} <span className='com-starShow'>*</span></label>
                                                                                    <input
                                                                                        className={errormessage?.billingemails ? 'form-control border-danger' : 'form-control'}
                                                                                        value={userdata?.billingaddress?.emails[0] ? userdata?.billingaddress?.emails[0] : ""}
                                                                                        disabled={userdata?.default_billing ? true : false}
                                                                                        onChange={(e) => setUserdata({
                                                                                            ...userdata,
                                                                                            billingaddress: { ...userdata?.billingaddress, emails: [e.target.value] }
                                                                                        })} />
                                                                                    {errormessage?.billingemails ? <div className='text-danger'>{t(`${errormessage?.billingemails}`)}</div> : ""}
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    }

                                                                </div>
                                                            </div>
                                                            {paymentdata?.Credit_Card?.length || paymentdata?.Bank?.length ?
                                                                <div className="paymentStepPage-main-paymentMethodBox">
                                                                    {paymentdata?.Credit_Card?.length && isValidEmail(userdata?.billingaddress?.emails[0]) && userdata?.billingaddress?.fname ?
                                                                        <div className="stripPaymentCont">
                                                                            <div className="stripPaymentCont-firstBox">
                                                                                <div className="stripPaymentCont-firstBox-CardHeader"> {t("Credit / Debit Cards")} <span><img src={AboutIcon} alt="" /></span> </div>
                                                                                <div className="stripPaymentCont-firstBox-CardIconBox">
                                                                                    <div className="com-radiobtnShowBox">
                                                                                        <input type="radio"
                                                                                            checked={paymentdatashow?.Credit}
                                                                                            className="com-radiobtnShowBox-inputField" id="payCard" name="payCard" onChange={((e) => setPaymentdatashow((state) => ({
                                                                                                ...state,
                                                                                                Credit: e.target.checked,
                                                                                                bank: false
                                                                                            })))} />

                                                                                        <label className="com-radiobtnShowBox-labeltext d-flex gap-3" htmlFor="payCard">
                                                                                            <div className="allCardIconBox">
                                                                                                <span className="singleCardIcon">
                                                                                                    <img src={VissaCardIcon} alt="" />
                                                                                                </span>
                                                                                                <span className="singleCardIcon">
                                                                                                    <img src={MasterCardIcon} alt="" />
                                                                                                </span>
                                                                                                <span className="singleCardIcon">
                                                                                                    <img src={JapanCardIcon} alt="" />
                                                                                                </span>
                                                                                                <span className="singleCardIcon">
                                                                                                    <img src={UnionCardIcon} alt="" />
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="stripPaymentCont-firstBox-CardHeader"> {t("Credit / Debit Cards")}</div>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="stripPaymentCont-paymentInfoBox">
                                                                                <div className="stripPaymentCont-paymentInfoBox-headBox">
                                                                                    <h4>{t("Payment information")}</h4>
                                                                                    <div className="stripPaymentCont-paymentInfoBox-headBox-securedBox">
                                                                                        <span className="stripPaymentCont-paymentInfoBox-headBox-securedBox-lockIconSpan">
                                                                                            <img src={LockIcon} alt="" />
                                                                                        </span>
                                                                                        {t("Secured by Stripe")}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="stripPaymentCont-paymentInfoBox-stripFormBox">
                                                                                    <StripePayment
                                                                                        paymentdata={paymentdata?.Credit_Card}
                                                                                        payamount={shippingdata?.shipprice ? shippingdata?.shipprice + shippinglist.reduce(
                                                                                            (accumulator, product) => accumulator + product.UnFormatPrice * product?.qty,
                                                                                            0
                                                                                        ) : 0 + shippinglist.reduce(
                                                                                            (accumulator, product) => accumulator + product.UnFormatPrice * product?.qty,
                                                                                            0
                                                                                        )}
                                                                                        setCreditpaymentdata={setCreditpaymentdata}
                                                                                        creditpaymentdata={creditpaymentdata}
                                                                                        alldata={{
                                                                                            name: userdata?.billingaddress?.fname,
                                                                                            email: userdata?.billingaddress?.emails[0]
                                                                                        }} />
                                                                                </div>
                                                                            </div>

                                                                        </div> : ""}
                                                                    {paymentdata?.Bank?.length ?
                                                                        <div className="internetBankPayment">
                                                                            <div className="internetBankPayment-checkBoxHead">
                                                                                <div className="com-radiobtnShowBox">
                                                                                    <input type="radio" className="com-radiobtnShowBox-inputField" id="internetBanking1" name="payCard"
                                                                                        checked={paymentdatashow?.bank}
                                                                                        onChange={((e) => setPaymentdatashow((state) => ({
                                                                                            ...state,
                                                                                            Credit: false,
                                                                                            bank: e.target.checked
                                                                                        })))} />
                                                                                    <label className="com-radiobtnShowBox-labeltext d-flex gap-3" htmlFor="internetBanking1">
                                                                                        <div className="internetBankPayment-checkBoxHead-headingdetail"> {t("Internet Banking")}
                                                                                            <span >
                                                                                                <img src={AboutIcon} alt="" />
                                                                                            </span>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            {paymentdatashow?.bank ?
                                                                                <div className="internetBankPayment-selectBankBox">
                                                                                    <div className="internetBankPayment-selectBankBox-AllbankCheckBox">
                                                                                        <div className="internetBankPayment-selectBankBox-AllbankCheckBox-singleCheckBank d-flex flex-column gap-3">
                                                                                            {paymentdata?.Bank?.length ? paymentdata?.Bank.map((result, key) => {
                                                                                                return (
                                                                                                    <div className="com-radiobtnShowBox" key={key}>
                                                                                                        <input type="radio"
                                                                                                            defaultChecked={result?.PaymentMethod === userdata?.paymentMethod?.paymentMethodId}
                                                                                                            onClick={() => setUserdata((state) => ({
                                                                                                                ...state,
                                                                                                                paymentMethod: {
                                                                                                                    paymentMethodId: result?.PaymentMethod,
                                                                                                                    IB: {
                                                                                                                        name: result?.name,
                                                                                                                        image: ""
                                                                                                                    }
                                                                                                                }
                                                                                                            }))}
                                                                                                            className="com-radiobtnShowBox-inputField" id={result?.id + key} name="selectBank"

                                                                                                        />
                                                                                                        <label className="com-radiobtnShowBox-labeltext d-flex gap-3" htmlFor={result?.id + key}>
                                                                                                            <div className="shippBankDetailBox">
                                                                                                                <span className="shippBankDetailBox-qrBox" >
                                                                                                                    <img src={QrcodeIcon} alt="" />
                                                                                                                </span>
                                                                                                                <div className="shippBankDetailBox-innerDetailBox">
                                                                                                                    <span className="shippBankDetailBox-innerDetailBox-backLogoBox" >
                                                                                                                        <img src={result?.logo} alt="" />
                                                                                                                    </span>
                                                                                                                    <div className="shippBankDetailBox-innerDetailBox-bankContentBox">
                                                                                                                        <h3>{result?.name}</h3>
                                                                                                                        <p>123-4-56789-0</p>
                                                                                                                        <span>{t("Jewel Prosoft Co., Ltd.")}</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                )
                                                                                            }) : ""}

                                                                                        </div>
                                                                                    </div>
                                                                                    {userdata?.paymentMethod?.IB?.name ?
                                                                                        <div className="internetBankPayment-selectBankBox-uploadDataBox">
                                                                                            <label className="internetBankPayment-selectBankBox-uploadDataBox-boxLabel">{t("Report evidence of money transfer")}</label>

                                                                                            <label className="internetBankPayment-selectBankBox-uploadDataBox-innerUploadInput">
                                                                                                <input
                                                                                                    type="file"
                                                                                                    className="d-none"
                                                                                                    hidden
                                                                                                    accept="image/*"
                                                                                                    id="profile-details"
                                                                                                    onChange={handleImageChange} />
                                                                                                <span >
                                                                                                    <img src={uploadIcon} alt="" />
                                                                                                </span>
                                                                                                {t(" Upload evidence of transfer...")}
                                                                                            </label>


                                                                                        </div> : ""}
                                                                                    {errormessage?.bankselect ?
                                                                                        <div className="text-danger"> {t(`${errormessage?.bankselect}`)}</div>
                                                                                        : ""}
                                                                                    {errormessage?.bankimage ?
                                                                                        <div className="text-danger"> {t(`${errormessage?.bankimage}`)}</div>
                                                                                        : ""}
                                                                                </div>
                                                                                : ""}

                                                                        </div> : ""}

                                                                    {errormessage?.payment || creditpaymentdata?.message ? <div className="text-danger">
                                                                        {errormessage?.payment ? errormessage?.payment :
                                                                            creditpaymentdata?.message}</div> :
                                                                        ""}
                                                                </div> :
                                                                <div className="text-danger">
                                                                    {t("No payment method available. please contact with support team")}
                                                                </div>}

                                                            <div className="">
                                                                <div className="com-ecomCheckBox">
                                                                    <input type="checkbox" checked={userdata?.agree ? true : false} onChange={(e) => ChangeCheck('agree', e)} className=" com-ecomCheckBox-inputcheck me-2 cursor-pointer" id="conditioAgree" />
                                                                    <label className="com-ecomCheckBox-labelBox cursor-pointer fw-semibold" htmlFor="conditioAgree">{t("I agree to the terms and conditions and the privacy policy")}</label>
                                                                </div>
                                                                {errormessage?.privacy ? <div className="text-danger">{t(`${errormessage?.privacy}`)}</div> : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {errormessage?.ordererror ? <div className="text-danger text-end">{t(`${errormessage?.ordererror}`)}</div> : ""}
                                                    <button type="button" className=' d-none com-ecomSendBtn shipping-continBtn previous' id="previous_btn" name="previous" value="Previous">{t("Pervious")}</button>
                                                    <div className="shipping-formBottom-btn">
                                                        <div className='shipping-formBottom-btn-backCartBtnBox'><span className='arrowIconCart'><LeftSideArrowIcon width={16.71} height={16.71} /></span ><span className='cartBackShow'
                                                            onClick={() => setFromstaps({ one: true, two: false, three: false })} >
                                                            {t("Return to cart")}</span></div>
                                                        {lodaer ?
                                                            <div className='com-ecomSendBtn shipping-continBtn next'>
                                                                <div className="border-bottom-0 border-width-2px text-white d-block mx-auto spinner-border flex-shrink-0" role="status"></div>
                                                            </div> :
                                                            <button type="button" className='com-ecomSendBtn shipping-continBtn next' name="next" value="Next" id='firststep_2'
                                                                onClick={() => validationfunsecound(userdata)}>{t("Continue")}</button>}
                                                    </div>
                                                </fieldset> :

                                                <fieldset className="shipping-step-mainForm-Container-fieldsetBox">
                                                    <div className="confirmPurchasePage">
                                                        <ConfirmFormPage lastpagedata={lastpagedata} countrylist={countrylist} />
                                                    </div>

                                                </fieldset>}


                                    </div>
                                </div>
                            </form>
                            {fromstaps?.one || fromstaps?.two ?
                                <div className="shippingPage-main-bothSideBox-rightSide">
                                    <div className={`tabHideOrder-box ${isOpenOrder ? 'open' : 'bg-white'}`} onClick={toggleOrderSummary}>
                                        <p className='tabHideOrder-box-contentText'>{t("Hide order summary")}</p>
                                        <span className='arrow'></span>
                                    </div>
                                    <div id="shippingOrderSumm" className={isOpenOrder ? 'active' : ''}>
                                        <OrderSummaryBox shippinglist={shippinglist} shippingdata={shippingdata} />
                                        <div className="shippingPage-main-bothSideBox-rightSide-editBottomBox">
                                            <h3 className="com-shippingPage-headingMain mb-4">{shippinglist?.length} {t("Items")}</h3>
                                        </div>
                                    </div>
                                </div> : ""}

                        </div>
                    </>
                    :
                    <>
                        {navigate("/shoppingcart", { state: { shippingdata: [] } })}
                    </>}

            </div>
            <ShippingAddrModel
                shipaddress={shipaddress}
                setShipaddress={setShipaddress}
                setUserdata={setUserdata}
                setFromshow={setFromshow}
                setBillingaddress={setBillingaddress}
                dobvalues={dobvalues} />

        </>
    );
};

export default ShippingPage;
