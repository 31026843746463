import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import Spinner from 'Ecommerce/common/Spinner';
import { _Api } from 'Ecommerce/common/api';
import { wishlistobjectheader } from 'source/app/appSlice';
import CalendarIcon from 'admin/common/icons/sidebar/CalendarIcon';
import { useDispatch } from 'react-redux';
import Shoppingbag from 'admin/common/icons/Shoppingbag';
import HeartBgIcon from 'Ecommerce/common/icons/HeartBgIcon'
import LeftArrow from 'Ecommerce/common/icons/LeftArrow';
import './wishlist.scss';
import EmptyWishlist from 'Ecommerce/common/images/emptyWishlist.png'
import RightArrow from 'admin/common/icons/right-arrow';
import useScreenSize from 'Ecommerce/template_four/include/Common/useScreenSize';
import ShareIcon from 'admin/common/icons/ShareIcon';
import DownloadIcon from 'admin/common/icons/DownloadIcon';
import PrintIcon from 'admin/common/icons/PrintIcon';
import PlusIcon from 'admin/common/icons/PlusIcon';
import MinusIcon from 'Ecommerce/common/icons/MinusIcon';
import HeartIcon from 'admin/common/icons/HeartIcon';
import EditIcon from 'admin/common/icons/EditIcon';
import AddCartModal from 'Ecommerce/template_four/components/Cart/AddCartModal';
import MessageIcon from 'admin/common/icons/MessageIcon';
import DeleteIcon from 'admin/common/icons/DeleteIcon';
import Deletedeletepopup from 'Ecommerce/template_four/components/Delete/DeleteConfirmationPopup';
import BottomBar from 'Ecommerce/template_four/include/Common/BottomBar';

export default function MainWishlistPage({ setOpenmodal }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loadeing, setloading] = useState(true);
    const [singleDeleteItem, setSingleDeleteItem] = useState()
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    let localmywishlist = localStorage.getItem('mywishlist') ? JSON.parse(localStorage.getItem('mywishlist')) : [];
    let localmywishlistremove = localStorage.getItem('mywishlistremove') ? JSON.parse(localStorage.getItem('mywishlistremove')) : [];
    const accessToken = localStorage.getItem("ecomaccesstoken");
    const [wishlistdata, setWishlistdata] = useState([]);
    const [selectedItems, setSelectedItems] = useState([])
    const [isopencart, setisopencart] = useState(false);
    const screenSize = useScreenSize()
    const getwishlist = async () => {
        setloading(true)
        try {
            let postdata = {
                "wishlistproduct": localmywishlist,
                "wishlistremove": localmywishlistremove,
            }
            let res = await _Api(postdata, `product/wishlist-product-info`)
            if (res?.statusCode === 200) {
                setloading(false)
                let array = [];
                if (res.data.length) {
                    res.data.forEach((result) => {
                        result.qty = 1;
                        array.push(result);
                    })
                }
                setWishlistdata(array)
            } else {
                setloading(false)
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    useEffect(() => {
        getwishlist()
        // eslint-disable-next-line
    }, [])
    const removewishlist = async (id) => {
        if (accessToken) {
            try {
                let postdata = {
                    "id": id
                }
                let res = await _Api(postdata, `product/deleteWishList`)
                if (res?.statusCode === 200) {
                    let deletearry = [...wishlistdata]
                    const updated = deletearry.filter(removeid => removeid?._id !== id);
                    setWishlistdata(updated)
                    let removewislistarr = [...localmywishlistremove]
                    removewislistarr.push(id)
                    const uniqueIdsSet = new Set(removewislistarr);
                    let unique_ids = Array.from(uniqueIdsSet);
                    localStorage.setItem('mywishlistremove', JSON.stringify(unique_ids ? unique_ids : []));
                    let updateaddlist = [...localmywishlist]
                    const updatedData = updateaddlist.filter(removeid => removeid !== id);
                    localStorage.setItem('mywishlist', JSON.stringify(updatedData ? updatedData : []));
                    dispatch(wishlistobjectheader(updatedData));
                }
            }
            catch (err) {
                console.log(err, "err")
            }
        } else {
            let deletearry = [...wishlistdata]
            const updated = deletearry.filter(removeid => removeid?._id !== id);
            setWishlistdata(updated)
            let removewislistarr = [...localmywishlistremove]
            removewislistarr.push(id)
            const uniqueIdsSet = new Set(removewislistarr);
            let unique_ids = Array.from(uniqueIdsSet);
            localStorage.setItem('mywishlistremove', JSON.stringify(unique_ids ? unique_ids : []));
            let updateaddlist = [...localmywishlist]
            const updatedData = updateaddlist.filter(removeid => removeid !== id);
            localStorage.setItem('mywishlist', JSON.stringify(updatedData ? updatedData : []));
            dispatch(wishlistobjectheader(updatedData));
        }
    }
    const changeqty = (label, id) => {
        const updatedWishlistData = wishlistdata.map(e => {
            if (e._id === id) {
                if (label === 'minus' && e.qty > 1) {
                    return { ...e, qty: e.qty - 1 };
                } else if (label !== 'minus') {
                    return { ...e, qty: e.qty + 1 };
                }
            }
            return e;
        });
        setWishlistdata(updatedWishlistData);
    };
    const addtocart = () => {
        if(selectedItems?.length > 0){
       
        selectedItems?.map((item)=>{
            let localitem = localStorage.getItem('CartItems') ? JSON.parse(localStorage.getItem('CartItems')) : [];
            
            let indexofitem = localitem.findIndex((check)=> check.id === item?.id)
            if(indexofitem !== -1){
                // let newOne = [...localitem]
                // newOne[indexofitem].qty = newOne[indexofitem].qty + 1
                // console.log(newOne, indexofitem, "already exist")
            }else{
                localitem.push({id: item.id, qty : item.qty})
            }
            localStorage.setItem('CartItems', JSON.stringify(localitem));
        })
       
        setisopencart(true);
        }
    }

    const handleConfirm = () => {
            removewishlist(singleDeleteItem);
            setIsPopupVisible(false);
      };
      
      const handleCancel = () => {
        setIsPopupVisible(false);
      };

    const downloadPageAsPNG = () => {
        const rootElement = document.getElementById('wishlistpng');
        html2canvas(rootElement).then((canvas) => {
            const imageURL = canvas.toDataURL('image/png');
            const anchor = document.createElement('a');
            anchor.download = 'wishlist.png';
            anchor.href = imageURL;
            anchor.click();
        });
        // const rootElement = document.getElementById('wishlistpng');
        // const images = rootElement.getElementsByTagName('img');
        // for (let img of images) {
        //   img.crossOrigin = 'anonymous';
        // }
        // html2canvas(rootElement, {
        //   letterRendering: 1,
        //   allowTaint: false, 
        //   useCORS: true 
        // }).then(canvas => {
        //   const imageURL = canvas.toDataURL('image/png');
        //   const anchor = document.createElement('a');
        //   anchor.download = 'wishlist.png';
        //   anchor.href = imageURL;
        //   anchor.click();
        // }).catch(err => {
        //   console.error('Error capturing canvas:', err);
        // });
    };
    const handlePrint = () => {
        window.print();
    };

    const handleCheckBox = (event, data) => {
        console.log(event, data, "check my items", wishlistdata)
        if (data?._id) {
            setSelectedItems((prevChecked) =>
                event.target.checked
                    ?  [...prevChecked, { id: data?._id, qty: data?.qty, price: data?.UnFormatPrice }]
                    : prevChecked.filter((item) => item?.id !== data?._id)
            )
            console.log(selectedItems, "check selected item")
        } else {
            let fulldata = []
            if (event.target.checked) {
                console.log(wishlistdata, "chekc wish list data")
                wishlistdata?.forEach((result) => {
                        fulldata.push({ id: result?._id, qty: result?.qty, price: result?.UnFormatPrice })
                });
            }
            console.log(fulldata, "Check full data")
            setSelectedItems((prev)=>prev=fulldata);
        }
    }

    const handleBookAnAppointment = () =>{
        navigate("/appointment", { state: { checkoutItems : selectedItems }})
    }
  return (
    <div className='wishlistholder ecommerce-main-layout-bodyContent' id='wishlistpng'>
    <div className='wishlistholder-head'>
        <span className='cursor-pointer' onClick={()=>navigate('/')}>{t("Home")}</span> 
        <span className='SlashPath'><RightArrow/></span> 
        <span className='text'>{t("Wishlist")}</span>
    </div>
   
    {
        loadeing ?
        <div className=" w-100 d-flex justify-content-center align-items-center  ">
        <Spinner />
        </div> :
        <div className='wishlistdesign'>
            <div className='wishlistdesign-container'>
                <div className='wishlistdesign-container-headerBox'>
                    <div className='wishlistdesign-container-headerBox-headerName'>{t("Wishlist")} {wishlistdata?.length ? `(${wishlistdata?.length} Item)` : ""}</div>
                    <div className='wishlistdesign-container-headerBox-secondBox'>

                        <div className='wishlistdesign-container-headerBox-secondBox-leftSide'>
                            {!accessToken ? "To view items saved in your account, please sign in." : ""}</div>

                        <div className='wishlistdesign-container-headerBox-secondBox-rightSideBox'>
                            {/* {!accessToken ?
                                <div className='wishlistdesign-container-headerBox-secondBox-rightSideBox-questShopper'
                                    onClick={() => setOpenmodal(
                                        {
                                            showLoginModel: true,
                                            showRegisterModel: false,
                                            showemailvarifymodal: false,
                                            forgetpassword: false,
                                            resetpassword: false,
                                            newpassword: false
                                        }
                                    )}>
                                    <span className='d-flex' ><img src={PersonQuesIcon} alt='' /></span>
                                    <span className='text-show ' > {t("Guest Shopper")}</span>
                                </div> : ""} */}
                            <div className='wishlistdesign-container-headerBox-secondBox-rightSideBox-iconBox'>
                                <span className='shareIcon'><ShareIcon type="new"/><span className='tooltip-data'><span><ShareIcon type="threepoint"/></span><span><MessageIcon type="email"/></span></span></span>
                                <span onClick={() => downloadPageAsPNG()}><DownloadIcon color="#767676"/></span>
                                <span onClick={() => handlePrint()}><PrintIcon color="#767676"/></span>
                            </div>
                        </div>
                    </div>
                </div>
                {wishlistdata?.length ?
                <div className='wishlistdesign-container-subheader'>
                                <div className='wishlistdesign-container-subheader-leftcontent'>
                                 <div className="ecom-blackthemed-checkbox">
                                                        <input type="checkbox" className="itemscheckbox"
                                                           checked={selectedItems?.length === wishlistdata?.length}
                                                           id="allselect"
                                                           onChange={(e) => handleCheckBox(e, "")} 
                                                            />
                                                        <label className="ecom-blackthemed-checkbox-label" htmlFor='allselect'></label>
                                                    </div>
                                </div>
                                <div className='wishlistdesign-container-subheader-rightcontent'>
                                    <div className={`wishlistdesign-container-subheader-rightcontent-item ${selectedItems.length > 0 ? "active" : ""}`}>({selectedItems?.length}/{wishlistdata?.length} {t("Item")})</div>
                                    <div className='wishlistdesign-container-subheader-rightcontent-delete'><DeleteIcon type="curved"/> {t("Clear All")}</div>
                                    <div onClick={handleBookAnAppointment} className={`wishlistdesign-container-subheader-rightcontent-appointment ${selectedItems.length > 0 ? "active" : ""}`}>
                                        <span><CalendarIcon width={24} height={24} color={selectedItems.length > 0 ? "#212121" : "#8A8A8A"}/></span> 
                                        <span className='text appointmenttext'>{t("Book an Appointment")}</span>
                                    </div>
                                    {/* <div className={`wishlistdesign-container-subheader-rightcontent-appointment ${selectedItems.length > 0 ? "active" : ""}`} data-bs-toggle="modal" data-bs-target="#AddToCartModal"  onClick={() => addtocart()}> <Shoppingbag color={selectedItems.length > 0 ? "#212121" : ""}/> <div className='text'> {t("Add to Cart")}</div></div> */}
                                </div>
                </div>
                : ""}
                        {wishlistdata?.length ?
                                            <div className='wishlistdesign-container-wrapper'>
                                                <div className='wishlistdesign-container-wrapper-left'>
                                                    <div className="ecom-blackthemed-checkbox">
                                                        <input type="checkbox" className="itemscheckbox"
                                                           checked={selectedItems?.length === wishlistdata?.length}
                                                           id="allselect"
                                                           onChange={(e) => handleCheckBox(e, "")} 
                                                            />
                                                        <label className="ecom-blackthemed-checkbox-label" htmlFor='allselect'></label>
                                                    </div>
                                                    <span>{t("Product")}</span>
                                                </div>
                                                <div className='wishlistdesign-container-wrapper-right'>
                                                    <div className='responsive'>{t("Price")}</div>
                                                    <div className='responsive'>{t("Stock Status")}</div>
                                                    <div className='responsive'>{t("Quantity")}</div>
                                                </div>
                                            </div>
                                            : null }
                <div className='wishlistdesign-container-bodyProCont'>
                    {wishlistdata?.length ? wishlistdata.map((result, key) => {
                        return (
                            <div className='wishlistdesign-container-bodyProCont-singlePro' key={key}>
                                <div className='wishlistdesign-container-bodyProCont-singlePro-productType'>
                               
                                    <div className='wishlistdesign-container-bodyProCont-singlePro-productType-imageBox'>
                                    <div className='wishlistdesign-container-bodyProCont-singlePro-productType-imageBox-first'>
                                        <div className="ecom-blackthemed-checkbox">
                                                            <input type="checkbox" className="itemscheckbox"
                                                            checked={selectedItems?.some(item => item.id === result?._id)}
                                                            id={result?.SKU}
                                                            onChange={(e) => handleCheckBox(e, result)} 
                                                                />
                                                            <label className="ecom-blackthemed-checkbox-label" htmlFor={result?.SKU}></label>
                                        </div>
                                    {/* <span className='delete' onClick={() => removewishlist(result?._id)}><DeleteIcon type='curved'/></span> */}
                                    <span className='delete' onClick={() => {setIsPopupVisible(true);setSingleDeleteItem(result?._id)}}><DeleteIcon type='curved'/></span>
                                    </div>
                                    <span className='product-img-span'><img src={result?.main_image} alt='' /></span>
                                    <div className='wishlistdesign-container-bodyProCont-singlePro-productType-details-firstBox'>
                                            <div className='sku'><span>{result?.SKU}</span><span className='price'>{result?.price}</span></div>
                                            <Link to={`/product/${result?.Slug}`} state={{ id: result?._id }}
                                                className='text-decoration-none'>
                                                <p className='wishlistdesign-container-bodyProCont-singlePro-productType-details-firstBox-productName'>{result?.name}</p>
                                            </Link>
                                            <div className='wishlistdesign-container-bodyProCont-singlePro-productType-details-firstBox-content'>
                                                {result?.Metal ? <p className='contentPara'>{result?.Metal}</p> : null }
                                                {result?.Stone ? <p className='contentPara'>{result?.Stone}</p> : null }
                                                {result?.Size ? <p className='contentPara'><span>#</span> {result?.Size} </p> : null}
                                                
                                            </div>
                                            <div className='mobileresponsive'>
                                                    <span>{ result?.isStock === 1 ? t("In Stock") : ""}</span>
                                                    <span className='addtocart'><span className='addtocart-inner' data-bs-toggle="modal" data-bs-target="#AddToCartModal"  onClick={() => addtocart()}>{t("Add to Cart")}</span></span>
                                                </div>
                                        </div>
                                    </div>
                                    <div className='wishlistdesign-container-bodyProCont-singlePro-productType-details'>
                                       
                                        <div className='wishlistdesign-container-bodyProCont-singlePro-productType-details-secondBox'>
                                            <div className='priceShow marginLeftAuto'>{result?.price}</div>
                                            {/* <div className='productCountShow marginLeftAuto'><button
                                                onClick={() => changeqty('minus', result?._id
                                                )} className='productCountShow-minusIcon'><MinusIcon width={14} height={14} color={'#1D1D1F'} /></button><span className='productCountShow-count'>{result?.qty}</span><button onClick={() => changeqty('plus', result?._id
                                                )} className='productCountShow-plusIcon'><PlusIcon width={14} height={14} color={'#1D1D1F'} /></button>
                                            </div> */}
                                            {/* <div className='wishAddToCartBtn marginLeftAuto' data-bs-toggle="modal" data-bs-target="#AddToCartModal" onClick={() => addtocart(result?._id, result?.qty)}>{t("Add to Cart")}</div> */}
                                            {/* <div className='product-iconListBox '>
                                                <div className='product-iconListBox-removeBtn marginLeftAuto'>
                                                    <span ><EditIcon width={24} height={24}/></span>
                                                    <span className='heart-icon-box'><HeartBgIcon width={24} height={24} /></span>
                                                    <span className='product-iconListBox-removeBtn-text'
                                                        onClick={() => removewishlist(result?._id)}>{t("Remove from Wishlist")}
                                                    </span>
                                                </div>
                                            </div> */}
                                            <span className='instock'>{ result?.isStock === 1 ? t("In Stock") : ""}</span>
                                            <span className='addtocart'><span className='addtocart-inner' data-bs-toggle="modal" data-bs-target="#AddToCartModal"  onClick={() => addtocart()}>{t("Add to Cart")}</span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }) :
                        <div className='wishlistdesign-container-bodyBox'>
                            <div className='wishlistdesign-container-bodyBox-header'>
                                <span className='wishlistdesign-container-bodyBox-header-title'>{t("Want to be sure you can access your Favorites across all your devices?")}</span>
                               { accessToken ? "" : <span className='signin'>{t("Sign In")} / {t("Create an Account")}</span>}
                            </div>
                            <div className='wishlistdesign-container-bodyBox-innerContent'>
                                <span><HeartIcon/></span>
                                <p className='wishlistdesign-container-bodyBox-innerContent-para'>{t("Your Favourites is empty")}</p>
                                <Link className='text-decoration-none' to="/collection">
                                    <div className='wishlistdesign-container-bodyBox-innerContent-addProductBtnBox'>
                                        <span>{t("Continue Shopping")}</span>
                                    </div>
                                </Link>

                            </div>
                        </div>}
                </div>
            </div>
        </div>
    }
    <Deletedeletepopup 
        isVisible={isPopupVisible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        message="Remove Items"
    />
    <BottomBar/>
    <AddCartModal setisopencart={setisopencart} isopencart={isopencart} />
</div>
  )
}
