import React from 'react'

const FIlterIcon = (props) => {
    const { width, height } = props
    return (
        <svg width={width ? width : "16"} height={height ? height : "16"} viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M35.334 0.666656H4.66732C2.55898 0.666656 0.833984 2.39166 0.833984 4.49999V27.5C0.833984 29.6083 2.55898 31.3333 4.66732 31.3333H35.334C37.4423 31.3333 39.1673 29.6083 39.1673 27.5V4.49999C39.1673 2.39166 37.4423 0.666656 35.334 0.666656ZM4.66732 2.58332H35.334C36.0058 2.58332 36.5961 2.93216 36.9382 3.45732L20.0007 20.3949L3.06307 3.45732C3.40519 2.93216 3.99553 2.58332 4.66732 2.58332ZM35.334 29.4167H4.66732C3.61028 29.4167 2.75065 28.557 2.75065 27.5V5.85507L20.0007 23.1051L37.2506 5.85507V27.5C37.2506 28.557 36.391 29.4167 35.334 29.4167Z" fill="white"/>
        </svg>

    )
}

export default FIlterIcon