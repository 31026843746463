import React from 'react';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
const PayPalButton = ({ paymentdatashow, userdata, payamount, currency, setCreditpaymentdata }) => {
    const initialOptions = {
        "client-id": paymentdatashow?.clientKey ? paymentdatashow?.clientKey : "AeJHMorrvj5SklB9nvyR-RGzGLlrKoi3xyz9jLf3HjRfn9KIy7DmXtHU4WQakl20oTMvj2wIwLszdJte",
        currency: currency ? currency : "USD",
        intent: "capture",
    };
    console.log({ paymentdatashow, userdata, payamount, currency });

    return (
        <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
                style={{ layout: "vertical" }}
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    value: payamount, // Replace with the amount
                                },
                            },
                        ],
                    });
                }}
                onApprove={(data, actions) => {
                    return actions.order.capture().then((details) => {
                        console.log(details?.id, "<<<<<<<,Transaction completed by >>>>>>>>>>..", details);
                        // Handle success (e.g., redirect, update state);

                        setCreditpaymentdata((state) => ({
                            ...state,
                            payment: true,
                            message: "",
                            submitdata: false,
                            stripekey: details?.id,
                            Paymentmethod_type: paymentdatashow?.Paymentmethod_type,
                            payment_slag: paymentdatashow?.payment_slag,
                            id: paymentdatashow?.id,
                            PaymentMethod: paymentdatashow?.PaymentMethod,
                            PaymentMethod_id: paymentdatashow?.id,
                        }))

                    });
                }}
                onError={(err) => {
                    console.error(err);
                    // Handle error
                }}
            />
        </PayPalScriptProvider>
    );
};

export default PayPalButton;
