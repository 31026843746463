import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next';

export default function KanbanFilter({ handleShowFilter, applyfilter, setApplyfilter, activeKanban, location }) {
    const datePickerRef = useRef(null)
    const customBtnRef = useRef(null)
    const [filters, setFilters] = useState({ OrderType: [], Status: [], start_date: "", end_date: "", activedates: "", location: [], create_at: [], order_type: [], duesFilters: [] });
    const [alreadyExist, setAlreadyExist] = useState(false);
    const [valueAlreadyExist, setValueAlradyExist] = useState([]);
    const [popupPosition, setPopupPosition] = useState({ top: 0 });

    useEffect(() => {
        // Initialize filters based on applyfilter, only on component mount or when applyfilter changes
        if (applyfilter?.OrderType?.length > 0 || applyfilter?.Status?.length > 0 || applyfilter?.activedates !== "" || applyfilter?.create_at?.length > 0 || applyfilter?.location?.length > 0 || applyfilter?.duesFilters?.length > 0) {
            setFilters({ ...applyfilter }); // Use spread operator to create a copy
            setAlreadyExist(true);
        } else {
            setFilters({ OrderType: [], Status: [], start_date: "", end_date: "", activedates: "", location: [], create_at: [], order_type: [], duesFilters: [] });
        }
    }, [applyfilter]);

    //   useEffect(() => {
    //     if(applyfilter?.location?.length === 0){
    //         setFilters(state => 
    //             ({...state, location: location })
    //         )
    //     }
    //   }, [])

    const { t } = useTranslation();

    let Status = activeKanban === 'po' ? ["Backlog", "Pending", "Approved", "Cancel", "Completed"] : activeKanban === 'purchase' ? ["Backlog", "Pending", "Approved", "Cancel"] : ["Backlog", "Pending", "Approved", "Cancel", "Completed", "Transit"]
    let DueDate = ["due", "overdue", "nextDay", "nextWeek"]



    const onFilterCLick = (label, value) => {
        let updatedArray = filters[label];
        if (updatedArray.includes(value)) {
            updatedArray = updatedArray.filter(item => item !== value);
        } else {
            updatedArray.push(value);
            if (alreadyExist) {
                setValueAlradyExist(prev => ([...prev, { key: label, value: value }]))
            }
        }
        setFilters(state =>
            ({ ...state, [label]: updatedArray })
        )

    }

    const handleApplyFilterButton = () => {
        handleShowFilter();
        setApplyfilter(prevState => ({
            ...prevState,  // Spread the previous state
            ...filters     // Spread the new filters object
        }));
    }

    const handleFilterCloseButton = () => {
        setFilters(prevState => ({
            ...prevState,
            location: [],
            duesFilters: []
        }));
        if (alreadyExist) {
            const updatedApplyFilter = { ...applyfilter };

            valueAlreadyExist.forEach(obj => {
                const { key, value } = obj;
                if (updatedApplyFilter[key]) {
                    updatedApplyFilter[key] = updatedApplyFilter[key].filter(item => item !== value);
                }
            });
            setApplyfilter(updatedApplyFilter)
        }

        handleShowFilter();
    }

    const positionDatePicker = () => {
        const buttonNode = customBtnRef.current;
        const popupNode = datePickerRef.current;
        if (buttonNode && popupNode) {
            const buttonRect = buttonNode.getBoundingClientRect();
            setPopupPosition(prevState => ({
                ...prevState,
                top: buttonRect.top
            }));
        }
    }

    useEffect(() => {
        if (datePickerRef.current) {
            window.addEventListener('resize', positionDatePicker);
            return () => window.removeEventListener('resize', positionDatePicker);
        }
        // eslint-disable-next-line
    }, [datePickerRef.current])

    return (
        <div className='calendarfilter'>
            <div className='calendarfilter-inner'>
                <div className='calendarfilter-inner-title'>
                    <div>{t("Filters")}</div>
                    <div className='cursor-pointer calendarfilter-inner-title-closeicon'
                        onClick={() => handleFilterCloseButton()}
                    > <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#767676" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
                <div className='calendarfilter-inner-content'>

                    <div className='calendarfilter-inner-content-data'>
                        <span>{t("Status")}</span>
                        <div className='calendarfilter-inner-content-data-filter'>
                            {Status?.length ? Status?.map((result, key) => {
                                return (
                                    <div className=
                                        {`calendarfilter-inner-content-data-filter-item ${filters?.Status?.includes(result) ? "active" : ""}`}
                                        key={key}
                                        onClick={() => onFilterCLick("Status", result)}>
                                        {t(result)}
                                    </div>
                                )
                            }) : ""}
                        </div>
                    </div>
                    <div className='calendarfilter-inner-content-data'>
                        <span>{t("Due Date")}</span>
                        <div className='calendarfilter-inner-content-data-filter'>
                            {DueDate?.length ? DueDate.map((result, key) => {
                                return (
                                    <div className={`calendarfilter-inner-content-data-filter-item me-2 mb-2 
                                        ${filters?.duesFilters?.includes(result) ? "active" : ""}`} key={key}
                                        onClick={() => onFilterCLick("duesFilters", result)}>
                                        {result === 'due' ? t("Due") : result === 'overdue' ? t("Overdue") : result === 'nextDay' ? t("Next day") : t("Next week")}

                                    </div>
                                )
                            }) : ""}
                        </div>
                    </div>
                    <div className='calendarfilter-inner-content-data'>
                        <span>{t("Location")}</span>
                        <div className='calendarfilter-inner-content-data-filter'>
                            {location?.length ? location?.map((result, key) => {
                                return (
                                    <div className=
                                        {`calendarfilter-inner-content-data-filter-item ${filters?.location?.includes(result) ? "active" : ""}`}
                                        key={key}
                                        onClick={() => onFilterCLick("location", result)}>
                                        {t(result)}
                                    </div>
                                )
                            }) : ""}
                        </div>
                    </div>

                </div>
            </div>

            <div className='calendarfilter-inner-bottom'
                onClick={() => { handleApplyFilterButton() }}>
                <button>
                    {t("Apply Filters")}
                </button>
            </div>
        </div>
    )
}
