import React from 'react'
import './contactus.scss'
import RightArrow from '../../../../../admin/common/icons/right-arrow'
import { useTranslation } from 'react-i18next';
import mapImage from '../../assets/images/contactusmap.png'
import locationimg from '../../assets/images/rectangle2.png'

export default function ContactUs() {
    const { t } = useTranslation();
  return (
    <div className='contactuspage'>
        <div className='contactuspage-inner'>
            <div className='contactuspage-inner-header'>
                <span className='contactuspage-inner-header-parent'>{t("Categories")}</span>
                <span className='contactuspage-inner-header-icon'><RightArrow color="#6A6A6A"/></span>
                <span className='contactuspage-inner-header-child'>{t("Contact Us")}</span>
            </div>
            <div className='contactuspage-inner-content'>
                <div className='contactuspage-inner-content-details'>
                    <div className='contactuspage-inner-content-details-left'>
                        <img src={locationimg} alt='location'/>
                    </div>
                    <div className='contactuspage-inner-content-details-right'>
                        <div className='contactuspage-inner-content-details-right-head'>
                            <span className='contactuspage-inner-content-details-right-head-parent'>{t("Home")}</span>
                            <span className='contactuspage-inner-content-details-right-head-icon'><RightArrow color="#6A6A6A"/></span>
                            <span className='contactuspage-inner-content-details-right-head-child'>{t("Contact Us")}</span>
                        </div>
                        <div className='contactuspage-inner-content-details-right-title'>
                            {t("CONTACT US")}
                        </div>
                        <div className='contactuspage-inner-content-details-right-card'>
                            <div className='contactuspage-inner-content-details-right-card-header'>{t("Client Care")}</div>
                            <div className='contactuspage-inner-content-details-right-card-items'>
                                <div className='contactuspage-inner-content-details-right-card-items-data'>
                                    <span></span>
                                    <span>{t("mail@company.com")}</span>
                                </div>
                                <div className='contactuspage-inner-content-details-right-card-items-data'>
                                    <span></span>
                                    <span>{t("02-888-8888 Ext. 1")}</span>
                                </div>
                                <div className='contactuspage-inner-content-details-right-card-items-data'>
                                    <span></span>
                                    <span>{t("Monday – Thursday: 9 AM – 7 PM ET, Friday: 9 AM – 2 PM ET")}</span>
                                </div>
                            </div>
                        </div>
                        <div className='contactuspage-inner-content-details-right-card'>
                            <div className='contactuspage-inner-content-details-right-card-header'>{t("Repair Service")}</div>
                            <div className='contactuspage-inner-content-details-right-card-items'>
                                <div className='contactuspage-inner-content-details-right-card-items-data'>
                                    <span></span>
                                    <span>{t("mail@company.com")}</span>
                                </div>
                                <div className='contactuspage-inner-content-details-right-card-items-data'>
                                    <span></span>
                                    <span>{t("02-888-8888 Ext. 1")}</span>
                                </div>
                                <div className='contactuspage-inner-content-details-right-card-items-data'>
                                    <span></span>
                                    <span>{t("Monday – Thursday: 9 AM – 7 PM ET, Friday: 9 AM – 2 PM ET")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='contactuspage-inner-content-map'>
                    <img src={mapImage} alt='map'/>
                </div>
            </div>
        </div>
    </div>
  )
}
