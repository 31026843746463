import React, { useEffect, useState, useRef } from 'react';
import { Route, Outlet, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { _Apiauth } from '../Ecommerce/common/api.js';
import CustomSwitch from '../PublicCustomSwitch.js';
import HomePage from '../Ecommerce/template_three/Pages/HomePage.js';
import Footer from '../Ecommerce/template_three/include/Footer.js';
import CookieConsent from '../Ecommerce/template_three/components/Cookie/CookieConsent.js';
import HeaderNew from '../Ecommerce/template_three/include/HeaderNew.js';
import MainPage from '../Ecommerce/template_three/Pages/Main/MainPage.js';
import ProductPage from 'Ecommerce/template_three/Pages/Productpage/ProductPage.js';
import ShoppinCart from 'Ecommerce/template_three/Pages/Cart/ShoppinCart.js';
import MainWishlistPage from 'Ecommerce/template_three/Pages/wishlist/MainWishlistPage.js';
import AppointmentPage from 'Ecommerce/template_three/Pages/Appointmen/AppointmentPage.js';
import Shipping from 'Ecommerce/template_three/Pages/Shipping/Shipping.js';
import useScreenSize from 'Ecommerce/template_three/include/Common/useScreenSize.js';
import AccountMainPage from 'Ecommerce/template_three/Pages/Account/AccountMainPage.js';
import MixAndMatchMainPage from 'Ecommerce/template_three/Pages/MixandMatch/MixAndMatchMainPage.js';
import LogOut from 'Ecommerce/template_three/auth/LogOut.js';
import AccountBillingAndShipping from 'Ecommerce/template_three/Pages/Account/AccountBillingAndShipping.js';
import AccountChangePassword from 'Ecommerce/template_three/Pages/Account/AccountChangePassword.js';
import AccountPurchaseHistory from 'Ecommerce/template_three/Pages/Account/AccountPurchaseHistory.js';
import AccountAppointmentPage from 'Ecommerce/template_three/Pages/Account/AccountAppointmentPage.js';
import AccountIndexPage from 'Ecommerce/template_three/Pages/Account/AccountIndexPage.js';
import AccountAddressAdd from 'Ecommerce/template_three/Pages/Account/AccountAddressAdd.js';
import LocationPage from 'Ecommerce/template_three/components/Location/LocationPage.js';

export default function EcommerceRoutes() {
    const dataFetched = useRef();
    const [websiteinfo, setWebsiteinfo] = useState()
    const [showecomdata, setShowecomdata] = useState(false)
    const location = useLocation();
    const [openmodal, setOpenmodal] = useState({
        showLoginModel: false,
        showRegisterModel: false,
        showemailvarifymodal: false,
        forgetpassword: false,
        resetpassword: false,
        newpassword: false
    })
    const [newpassworddata, SetNewpassworddata] = useState()
    const [forgetemail, setForgetemail] = useState()
    const [emailverifydata, setEmailVerifydata] = useState()
    const ecominfo = async () => {
        try {
            let postdata = {}
            let res = await _Apiauth(postdata, `info`)
            if (res?.statusCode === 200) {
                setWebsiteinfo(res?.data)
            }
            setShowecomdata(true)
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const formatefunction = async () => {
        try {
            let postdata = {}
            let res = await _Apiauth(postdata, `formatefunction`)
            if (res?.statusCode === 200) {
                var jsonData = JSON.stringify(res?.data);
                localStorage.setItem("formatefunction", jsonData);
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    useEffect(() => {
        if (dataFetched.current) return;
        dataFetched.current = true
        ecominfo()
        formatefunction()
    }, [])
    const AdminReactActive = function () {
        const screenSize = useScreenSize();
        const shouldHideHeader = location.pathname === '/shipping' || location.pathname === '/appointment' || location.pathname === '/shoppingcart' || location.pathname === '/account' || location.pathname === '/account/details' || location.pathname === '/account/address' || location.pathname === '/account/addressdetails' || location.pathname === '/account/appointmentdata'  || location.pathname === '/account/purchasehistory' || location.pathname === '/account/changepassword' || location.pathname === '/location';
      
        return (
            <>
                <div className={openmodal.showLoginModel || openmodal.showRegisterModel || openmodal.showemailvarifymodal || openmodal.forgetpassword || openmodal.resetpassword || openmodal.newpassword ? "ecommerce-main-layout overflow-hidden vh-100" : "ecommerce-main-layout"}>
                    {showecomdata ?
                        <>
                        {shouldHideHeader && screenSize.width < 1200 ? 
                        null :
                        (
                            <div className="ecommerce-main-layout-header">
                                    <HeaderNew
                                    websiteinfo={websiteinfo}
                                    openmodal={openmodal}
                                    setOpenmodal={setOpenmodal}
                                    SetNewpassworddata={SetNewpassworddata}
                                    newpassworddata={newpassworddata}
                                    setForgetemail={setForgetemail}
                                    forgetemail={forgetemail}
                                    setEmailVerifydata={setEmailVerifydata}
                                    emailverifydata={emailverifydata}
                                    />
                                   
                            </div>
                             )}
                            <Outlet />
                            <div className="">
                                <Footer websiteinfo={websiteinfo}/>
                            </div>
                        </>
                        : ""}
                        <CookieConsent/>
                </div>
            </>
        );

    };
    return (
        <>
            <Helmet>
                <title>{websiteinfo?.websiteInfo?.metaTitle}</title>
                <link rel="icon" type="image/png" href={websiteinfo?.websiteInfo?.favicon} sizes="16x16" />
                <meta name="description" content={websiteinfo?.websiteInfo?.metaDescription} />
            </Helmet>
            <CustomSwitch>
                <Route element={<AdminReactActive />}>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/Collection" element={<MainPage/>} />
                    {/* <Route path="/Collection/:token" element={<MainPage/>} />
                    <Route path="/Category/:token" element={<MainPage />} /> */}
                    <Route path="/:mainCategory" element={<MainPage />} />
                    <Route path="/:mainCategory/:subCategory" element={<MainPage />} />
                    <Route path="/product/:token" element={<ProductPage />} />
                    <Route path="/shoppingcart" element={<ShoppinCart />} />
                    <Route path="/wishlist" element={<MainWishlistPage />} />
                    <Route path="/appointment" element={<AppointmentPage />} />
                    <Route path="/shipping" element={<Shipping setOpenmodal={setOpenmodal} />} />
                    <Route path="/account" element={<AccountIndexPage />} />
                    <Route path="/account/details" element={<AccountMainPage />} />
                    <Route path="/account/address" element={<AccountBillingAndShipping />} />
                    <Route path="/account/addressdetails" element={<AccountAddressAdd />} />
                    <Route path="/account/changepassword" element={<AccountChangePassword />} />
                    <Route path="/account/purchasehistory" element={<AccountPurchaseHistory />} />
                    <Route path="/account/appointmentdata" element={<AccountAppointmentPage />} />
                    <Route path="/mixandmatch" element={<MixAndMatchMainPage />} />
                    <Route path="/logout" element={<LogOut />} />
                    <Route path='/contactus' element={<LocationPage/>}/>
                 
                </Route>
            </CustomSwitch>
        </>
    );
}

