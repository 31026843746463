import React, { useState, useEffect } from 'react'
import '../master.scss'
import { _Api } from '../../api/_call'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { store } from '../../../source/index'
import { LocationList, GetTexgraph } from '../../common/Commongraphql'
import moment from 'moment'
import { error, loading, Updateloading } from '../../common/helpers/toastify'
import { useTranslation } from 'react-i18next'

const EditVoucher = (props) => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { setEditItem, dataid, setShowedit, create, setDataid, setCreate } = props
  const [group, setGroup] = useState()
  const [method, setMethod] = useState()
  const [print, setPrint] = useState([])
  const [tax, setTax] = useState([])
  const { t } = useTranslation()
  const [name, setName] = useState('')
  const [code, setCode] = useState('')
  const [LocationarrayList, setLocationarrayList] = useState([])
  const [leading_zero, setLeadingzero] = useState()
  const [printOption, setPrintOption] = useState([])
  const [width, setWidth] = useState()
  const [configuration, setConfiguration] = useState({
    conf_date: '',
    conf_prefix: '',
    conf_startno: '',
    conf_suffix: '',
    conf_updated_no: 1,
  })
  const [printFormat, setPrintFormat] = useState({
    headername: '',
    subtitle: '',
    Jurisdiction: '',
  })
  const [texgrapharray, setTexgrapharray] = useState([])
  const [active, setActive] = useState(true)
  const [checked, setChecked] = useState([])
  let groupOption = [
    {
      value: 'Custom_Order',
      label: 'Custom Order',
    },
    {
      value: 'Deposit',
      label: 'Deposit',
    },
    {
      value: 'Diamond_Reserve',
      label: 'Diamond Reserve',
    },
    {
      value: 'Exchange',
      label: 'Exchange',
    },
    {
      value: 'Gift_Card',
      label: 'Gift Card',
    },
    {
      value: 'Inventory_Reserve',
      label: 'Inventory Reserve',
    },
    {
      value: 'Low_Stock_Planning',
      label: 'Low Stock Planning',
    },
    {
      value: 'Mix_And_Match',
      label: 'Mix And Match',
    },
    {
      value: 'Purchase_order',
      label: 'Purchase order',
    },
    {
      value: 'Purchase',
      label: 'Purchase',
    },
    {
      value: 'POS',
      label: 'POS',
    },
    {
      value: 'Quotation',
      label: 'Quotation',
    },
    {
      value: 'Refund',
      label: 'Refund',
    },
    {
      value: 'Repair_Order',
      label: 'Repair Order',
    },
    {
      value: 'Stock_Transfer',
      label: 'Stock Transfer',
    },
    {
      value: 'Stock_Take',
      label: 'Stock Take',
    },
  ]

  const methodOption = [
    {
      value: 'Manual',
      label: 'Manual',
    },
    {
      value: 'Auto',
      label: 'Auto',
    },
    {
      value: 'Never',
      label: 'Never',
    },
  ]
  var state = store.getState()
  var OrgSettings = state?.app?.organisation_settings
  const date_format_change = (date_format) => {
    var response
    if (date_format === 'DD/MM/YYYY') {
      response = 'dd/MM/yyyy'
    } else if (date_format === 'YYYY/MM/DD') {
      response = 'yyyy/MM/dd'
    } else if (date_format === 'MM/DD/YYYY') {
      response = 'MM/dd/yyyy'
    } else if (date_format === 'MM/YYYY/DD') {
      response = 'MM/yyyy/dd'
    }
    return response
  }

  const editvoucherview = async () => {
    if (create) {
      setName('')
      setCode('')
      setGroup()
      setMethod({
        value: '',
        label: '',
      })
      setLeadingzero('')
      setWidth('')
      setConfiguration((existingValues) => ({
        ...existingValues,
        conf_date: new Date(),
      }))
      setConfiguration((existingValues) => ({
        ...existingValues,
        conf_prefix: '',
      }))
      setConfiguration((existingValues) => ({
        ...existingValues,
        conf_startno: '',
      }))
      setConfiguration((existingValues) => ({
        ...existingValues,
        conf_suffix: '',
      }))
      setConfiguration((existingValues) => ({
        ...existingValues,
        conf_updated_no: 1,
      }))
      setPrintFormat({
        headername: '',
        subtitle: '',
        Jurisdiction: '',
      })
      setTax({
        value: '',
        label: '',
      })
      setActive(true)
      setChecked([])
      setPrint()
    } else if (dataid) {
      try {
        let postdata = {
          id: dataid,
        }
        let res = await _Api(postdata, `api/v1/App/master/voucherMaster`)
        if (res?.code === 200) {
          setName(res?.data?.name)
          setCode(res?.data?.code)
          setMethod({
            value: res?.data?.Methodofvouvher,
            label: res?.data?.Methodofvouvher,
          })
          setLeadingzero(res?.data?.prefillWithZero)
          setWidth(res?.data?.widthStartNumber)
          const momentDate = moment(res?.data?.Configuration?.conf_date)
          setConfiguration((existingValues) => ({
            ...existingValues,
            conf_date: momentDate?._d,
          }))
          setConfiguration((existingValues) => ({
            ...existingValues,
            conf_prefix: res?.data?.Configuration?.conf_prefix,
          }))
          setConfiguration((existingValues) => ({
            ...existingValues,
            conf_startno: res?.data?.Configuration?.conf_startno,
          }))
          setConfiguration((existingValues) => ({
            ...existingValues,
            conf_suffix: res?.data?.Configuration?.conf_suffix,
          }))
          setConfiguration((existingValues) => ({
            ...existingValues,
            conf_updated_no: res?.data?.Configuration?.conf_updated_no,
          }))
          setPrintFormat(res?.data?.details)
          setChecked(res?.data?.locations)
          setGroup({ label: res?.data?.group, value: res?.data?.group })
          texgrapharray.forEach((result) => {
            if (res?.data?.tax === result?.value) {
              setTax({
                value: result?.value,
                label: result?.label,
              })
            }
          })
          if (res?.data?.Status === "1") {
            setActive(true)
          } else {
            setActive(false)
          }
          const printTemplateUrl = res?.data?.print_template;
          const fileName = printTemplateUrl.split("/").pop();
          setprinttemplate(res?.data?.group.replace(/\s/g, '_'), fileName)
        }
      } catch (err) {
        console.log(err, 'err')
      }
    }
  }
  useEffect(() => {
    if (dataid || create) {
      editvoucherview()
    }
    // eslint-disable-next-line
  }, [dataid, create])

  const createvoucher = async () => {
    if (!name) {
      error(t('Name is required.'))
      return
    }
    if (!code) {
      error(t('Code is required.'))
      return
    } else {
      if (typeof code === 'string') {
        let checkspace = code.includes(' ');
        if (checkspace) {
          error(t('Please remove space in code'));
          return;
        }
      }
    }
    if (!group?.value) {
      error(t('Group is required.'))
      return
    }
    if (!width) {
      error(t('width is required.'))
      return
    }
    setShowedit(false)
    let GetloadingID = loading()
    try {
      let postdata = {
        id: dataid,
        name: name ? name : '',
        code: code ? code : '',
        group: group?.value ? group?.value : '',
        Methodofvouvher: method?.value ? method?.value : '',
        widthStartNumber: width,
        prefillWithZero: leading_zero ? '1' : '0',
        Configuration: configuration,
        headername: printFormat?.headername,
        subtitle: printFormat?.subtitle,
        Jurisdiction: printFormat?.Jurisdiction,
        tax: tax?.value,
        Status: active ? '1' : '0',
        locations: checked?.length ? checked : [],
        print_template: print?.value,
        edit: dataid ? true : false,
      }
      let res = await _Api(postdata, `api/v1/App/master/voucherMasterEdit`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message))
        setEditItem(false)
        setShowedit(true)
        setDataid("")
        setCreate(false)
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error')
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const handleCheckAllChange = (e) => {
    setChecked(
      e.target.checked ? LocationarrayList.map((c) => c?.id) : []
    );
  };

  const handleCountryChange = (e, c) => {
    let location = [...checked];
    if (e.target.checked) {
      location.push(c);
      setChecked(location);
    } else {
      location = location.filter(item => item !== c);
      setChecked(location);

    }
  };
  const setprinttemplate = async (event, fileName) => {
    try {
      let postdata = {
        "group": event
      }
      let res = await _Api(postdata, `api/v1/App/template/get-list`)
      if (res?.code === 200) {
        if (fileName) {
          let templatearr = res?.data.filter((item) => item.label === fileName)
          setPrintOption(res?.data)
          setPrint(templatearr[0])
        } else {
          setPrintOption(res?.data)
          setPrint(res?.data[0])
        }
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }

  return (
    <>
      <div className="edit-item-area bg-white">
        <div className="edit-item-area-header d-flex align-items-center justify-space-between mb-4">
          <div className="me-auto">
            <label className="d-block" htmlFor=''>
              {t('Name')}: {name}{' '}
            </label>
            <label className="d-block" htmlFor=''>
              {t('Code')}: {code}{' '}
            </label>
          </div>
          <button
            className="square-btn square-btn-white btn-close focus-none"
            onClick={() => {
              setEditItem(false);
              setDataid("");
              setCreate(false)
            }}
          ></button>
          <button onClick={() => createvoucher()} className="square-btn">
            <img src={base_assets + 'images/icons/white-save.png'} alt="" />
          </button>
        </div>
        <div className="edit-item-area-body">
          <div className="row">
            <div className="col-md-9 ps-0 edit-item-area-body-scroll">
              <div className="row mx-0">
                <div className="col-md-12">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label" htmlFor='name'>
                      {t('Name')} <sup>*</sup>
                    </label>
                    <input
                      id='name'
                      type="text"
                      className="v3-input-group-input w-100"
                      value={name || ''}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label" htmlFor='code'>
                      {t('Code')} <sup>*</sup>
                    </label>
                    <input
                      type="text"
                      id="code"
                      className="v3-input-group-input w-100"
                      value={code || ''}
                      onChange={(e) => setCode(e.target.value)}

                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label" htmlFor='group'>
                      {t('Group')} <sup>*</sup>
                    </label>
                    <Select
                      className="select-set select w-100 p-0"
                      placeholder=""
                      options={groupOption}
                      value={group || ''}
                      id="group"
                      onChange={(e) => { setGroup(e); setprinttemplate(e.value) }}
                      classNamePrefix="common-select"
                      isSearchable={false}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label" htmlFor='method'>
                      {t('Method of Voucher no.')}
                    </label>
                    <Select
                      id="method"
                      className="select-set select w-100 p-0"
                      placeholder=""
                      options={methodOption}
                      value={method || ''}
                      onChange={(e) => setMethod(e)}
                      classNamePrefix="common-select"
                      isSearchable={false}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label opacity-0" htmlFor='leading_zero'>
                      {'Leading Zero'}
                    </label>
                    <div className="form-check form-switch ps-0 mt-3">
                      <input
                        id='leading_zero'
                        className="form-check-input float-none me-2 ms-0 cursor-pointer"
                        type="checkbox"
                        checked={leading_zero ? true : false}
                        onChange={(e) => setLeadingzero(e.target.checked)}
                      />
                      <label className="form-check-label" htmlFor='leading_zero'>
                        {t('Leading Zero')}
                      </label>
                    </div>
                  </div>
                </div>
                {leading_zero &&
                  <div className="col-lg-6 col-md-12">
                    <div className="v3-input-group">
                      <label className="v3-input-group-label" htmlFor='startno'>
                        {t('Start No. Width')} <sup>*</sup>
                      </label>
                      <input
                        id='startno'
                        type="text"
                        className="v3-input-group-input w-100"
                        value={width || ''}
                        onChange={(e) => setWidth(e.target.value)}
                      />
                    </div>
                  </div>
                }
                <div className="col-md-12 mb-3">
                  <div className="table-responsive">
                    <table className="edit-item-area-body-table">
                      <thead>
                        <tr>
                          <th className="fw-semibold" style={{ width: '32%' }}>
                            {t('Start Date')}
                          </th>
                          <th className="fw-semibold">{t('Prefix')}</th>
                          <th className="fw-semibold">{t('Start No.')}</th>
                          <th className="fw-semibold">{t('Suffix')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="v3-input-group mb-0">
                            <DatePicker
                              className="placeholder-up-input-field v3-input-group-input w-100"
                              selected={configuration?.conf_date}
                              onChange={(e) =>
                                setConfiguration((existingValues) => ({
                                  ...existingValues,
                                  conf_date: e,
                                }))
                              }
                              name="DeliveryDate"
                              dateFormat={date_format_change(
                                OrgSettings?.date_format,
                              )}
                              placeholderText={'DD/MM/YYYY'}
                              autoComplete="off"
                            />
                          </td>
                          <td className="v3-input-group mb-0">
                            <input
                              type="text"
                              className="v3-input-group-input w-100"
                              value={configuration?.conf_prefix || ''}
                              onChange={(e) =>
                                setConfiguration((existingValues) => ({
                                  ...existingValues,
                                  conf_prefix: e.target.value,
                                }))
                              }
                            />
                          </td>
                          <td className="v3-input-group mb-0">
                            <input
                              type="text"
                              className="v3-input-group-input w-100"
                              value={configuration?.conf_startno || ''}
                              onChange={(e) =>
                                setConfiguration((existingValues) => ({
                                  ...existingValues,
                                  conf_startno: e.target.value,
                                }))
                              }
                            />
                          </td>
                          <td className="v3-input-group mb-0">
                            <input
                              type="text"
                              className="v3-input-group-input w-100"
                              value={configuration?.conf_suffix || ''}
                              onChange={(e) =>
                                setConfiguration((existingValues) => ({
                                  ...existingValues,
                                  conf_suffix: e.target.value,
                                }))
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="partition mb-4"></div>
                </div>
                <div className="col-md-12">
                  <label className="" htmlFor='referenceno'>
                    <span className="c0-color me-1" id='referenceno'>
                      {t('Your reference no. will show')}:
                    </span>{' '}
                    <b>
                      {configuration?.conf_prefix ? configuration?.conf_prefix : "" +
                        configuration?.conf_startno ? configuration?.conf_startno : "" +
                          configuration?.conf_suffix ? configuration?.conf_suffix : ""
                      }
                    </b>{' '}
                  </label>
                </div>
                <div className="col-md-12">
                  <div className="partition my-4"></div>
                </div>
                <div className="col-md-12">
                  <div className="v3-input-group">
                    <label className="v3-input-group-heading" htmlFor=''>
                      {t('Print Format')}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label" htmlFor=''>
                      {t('Header Name')}
                    </label>
                    <input
                      type="text"
                      className="v3-input-group-input w-100"
                      value={printFormat?.headername || ''}
                      onChange={(e) =>
                        setPrintFormat((existingValues) => ({
                          ...existingValues,
                          headername: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label" htmlFor='subtitle'>
                      {t('Subtitle')}
                    </label>
                    <input
                      id='subtitle'
                      type="text"
                      className="v3-input-group-input w-100"
                      value={printFormat?.subtitle || ''}
                      onChange={(e) =>
                        setPrintFormat((existingValues) => ({
                          ...existingValues,
                          subtitle: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label" htmlFor='jurisdic'>
                      {t('Jurisdiction statement')}
                    </label>
                    <input
                      htmlFor='jurisdic'
                      type="text"
                      className="v3-input-group-input w-100"
                      value={printFormat?.Jurisdiction | ''}
                      onChange={(e) =>
                        setPrintFormat((existingValues) => ({
                          ...existingValues,
                          Jurisdiction: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label" htmlFor='printtemp'>
                      {t('Print Template')}
                    </label>
                    <Select
                      id='printtemp'
                      className="select-set select w-100 p-0"
                      placeholder=""
                      options={printOption}
                      value={print || ''}
                      onChange={(e) => setPrint(e)}
                      classNamePrefix="common-select"
                      isSearchable={false}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="v3-input-group">
                    <label className="v3-input-group-heading" htmlFor='location'>
                      {t('Location')}
                    </label>
                  </div>
                  <div className="d-flex gap-lg-4 gap-xxl-5">
                    <div className="flex-shrink-0 mb-4">
                      <input
                        className="form-check-input fill-com-check me-3 cursor-pointer"
                        onChange={handleCheckAllChange}
                        checked={checked?.length === LocationarrayList?.length}
                        type="checkbox"
                        id="allLocations"
                        value="Jaipur "
                      />
                      <label
                        className="form-check-label cursor-pointer"
                        htmlFor="allLocations"
                      >
                        {t('All Locations')}{' '}
                      </label>
                    </div>
                    <div className="d-flex flex-wrap">
                      {LocationarrayList?.length ? LocationarrayList.map((result, key) => {
                        return (
                          <div className="mb-4 me-4" key={key}>
                            <input className="form-check-input fill-com-check me-3 cursor-pointer"
                              type="checkbox"
                              id={result?.id}
                              onChange={(e) => handleCountryChange(e, result?.id)}
                              checked={checked.includes(result.id)} />
                            <label className="form-check-label cursor-pointer"
                              htmlFor={result?.id}>{result?.name ? result?.name : ""} </label>
                          </div>
                        )
                      }) : ""}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="v3-input-group mb-4">
                    <label className="v3-input-group-label" htmlFor='taxtype'>Tax</label>
                    <Select
                      id="taxtype"
                      className="select-set select w-100 p-0"
                      placeholder=""
                      options={texgrapharray}
                      value={tax || ''}
                      onChange={(e) => setTax(e)}
                      classNamePrefix="common-select"
                      isSearchable={false}
                      menuPlacement='top'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 text-end ps-0">
              <div className="form-check form-switch ps-0">
                <label className="form-check-label" htmlFor="mixmatch">
                  {t("Active")}
                </label>
                <input
                  className="form-check-input float-none ms-2"
                  type="checkbox"
                  id="mixmatch"
                  checked={active ? true : false}
                  onChange={(e) => setActive(e.target.checked)}
                />
              </div>
            </div>
          </div>
        </div>
        <LocationList setLocationarrayList={setLocationarrayList} />
        <GetTexgraph setTexgrapharray={setTexgrapharray} />
      </div>
    </>
  )
}

export default EditVoucher
