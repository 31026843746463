import React, { useEffect, useRef, useState } from 'react'
import './Engraving.scss'
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import ImageUploading from 'react-images-uploading';
import ImageGallary from '../../../assets/icons/ImageGallary';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ArroeIcontop from '../../../assets/images/arrowIcontop.png'
import ConfirmPopup from './ConfirmPopup';
import PlusIcon from '../../../assets/icons/PlusIcon';
import { _Api, _Apiauth } from '../../../../common/api';

export default function Engraving({setActiveTab, flowMode, diamondProduct, engravingData, setEngravingData, setShowEngravingPage}) {
    const itemArray =  [
        { label: 'Left', value: 'Left' },
        { label: 'Right', value: 'Right' },
        { label: 'Center', value: 'Center' }
      ]

      const fontarray = [
        { value: 'Serif', label: 'Serif' },
        { value: 'Sans-serif', label: 'Sans-serif' },
        { value: 'Monospace ', label: 'Monospace ' },
      ]
    
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    const [DBimages, setDBImages] = useState();
    const navigate = useNavigate();
    const [images, setImages] = useState([]);
    const { t } = useTranslation();
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [engravingFormData, setEngravingFormData] = useState(engravingData ? engravingData : {customizeText: "", inorout: "", textposition: itemArray[0], fonts:fontarray[0], imageFile: {}, imgPosition: itemArray[0], preview: ""})
    const engravingPageRef = useRef();
    const showPopup = () => {
        setIsPopupVisible(true);
      };

      const handleConfirm = () => {
        console.log(engravingData, engravingFormData,"findEngraving data")
        setEngravingData((state)=>engravingFormData)
        setIsPopupVisible(false)
        setShowEngravingPage(false)
        setActiveTab('Final')
      };
    
      const handleCancel = () => {
        setIsPopupVisible(false);
      };





      const onChange = (imageList, addUpdateIndex) => {
       
        const file = imageList[0]?.file;
        console.log(file, "imageList")
        if(file){
            if (file.type.startsWith("image/")) {
               
            const reader = new FileReader();
            console.log(file.type.startsWith("image/"), reader?.result,"result")
            reader.onloadend = async () => {
                try {
                    const postdata = {
                        image: reader.result,
                    };
                    console.log(postdata, "postdaa")
                    let res = await _Api(postdata, `redis/uplaod`)
                    if (res.statusCode === 200) {
                        console.log(res?.url, "engraving logo")
                        setEngravingFormData((state)=>({...state, imageFile: {data_url : res?.url, name : file?.name, size : file?.size}}))
                    }
                }
                catch (err) {
                    console.log(err, "err")
                }
            };
            if (file) {
                reader?.readAsDataURL(file);
            }
        }
        }
        // setEngravingFormData((state)=>({...state, imageFile: imageList[0]}))
      };

      const handleCLoseICon = (index) =>{
        setEngravingFormData((state)=>({...state, imageFile : {}}))
      }

      const handelBacktosettings = () =>{
        setShowEngravingPage(false);
      }

      useEffect(()=>{
        console.log(engravingFormData)
      },[engravingFormData])

      useEffect(()=>{
        if (engravingPageRef.current) {
            engravingPageRef.current.scrollIntoView({ behavior: 'smooth' });
          }
      },[])
  return (
    <div className='engravingpage' ref={engravingPageRef}>
        <div className='engravingpage-inner'>
            <div className='engravingpage-inner-header'>
                <span>{t("Engraving")}</span>
            </div>
            <div className='engravingpage-inner-wrapper'>
                <div className='engravingpage-inner-wrapper-left'>
                    <div className='engravingpage-inner-wrapper-left-title'>
                        <span>{t("Engrave")}</span>
                    </div>
                    <div className='engravingpage-inner-wrapper-left-content'>
                        <div className='engravingpage-inner-wrapper-left-content-head light'>{t("Customize Text")}</div>
                        <textarea className='engravingpage-inner-wrapper-left-content-textarea' placeholder='Type something' onChange={(e)=>setEngravingFormData((state)=>({...state, customizeText : e.target.value}))}>{engravingFormData?.customizeText}</textarea>
                    </div>
                    <div className='engravingpage-inner-wrapper-left-content'>
                        <div className='engravingpage-inner-wrapper-left-content-checkboxholder'>
                            <div className='engravingpage-inner-wrapper-left-content-checkboxholder-item'> 
                                <input 
                                onChange={(e)=>setEngravingFormData((state)=>({...state, inorout : 'Outside' }))} 
                                className="fill-com-check form-check-input filter-checkbox d-inline-block m-0 cursor-pointer" 
                                type='checkbox' 
                                checked={engravingFormData?.inorout === "Outside" ? true : false}
                                />
                                <label>{("Outside")}</label>
                            </div>
                            <div className='engravingpage-inner-wrapper-left-content-checkboxholder-item'> 
                                <input 
                                onChange={(e)=>setEngravingFormData((state)=>({...state, inorout : 'Inside' }))}  
                                className="fill-com-check form-check-input filter-checkbox d-inline-block m-0 cursor-pointer" 
                                type='checkbox' 
                                checked={engravingFormData?.inorout === "Inside" ? true : false}
                                />
                                <label>{("Inside")}</label>
                                </div>
                        </div>
                    </div>
                    <div className='engravingpage-inner-wrapper-left-content'>
                        <div className='engravingpage-inner-wrapper-left-content-head'>{t("Position")}</div>
                        <Select
                            options={itemArray}
                            className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                            height='46px'
                            width='100%'
                            isSearchable={false}
                            value={engravingFormData?.textposition}
                            onChange={(e)=>{setEngravingFormData((state)=>({...state, textposition : e}))}}
                        />
                    </div>
                    <div className='engravingpage-inner-wrapper-left-content'>
                        <div className='engravingpage-inner-wrapper-left-content-head'>{t("Fonts")}</div>
                        <Select
                            options={fontarray}
                            className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                            height='46px'
                            width='100%'
                            isSearchable={false}
                            value={engravingFormData?.fonts}
                            onChange={(e)=>{setEngravingFormData((state)=>({...state, fonts : e}))}}
                        />
                    </div>
                    <div className='engravingpage-inner-wrapper-left-content'>
                        <div className='engravingpage-inner-wrapper-left-content-head light'>{t("Preview")}</div>
                            <div
                                        className="engravingpage-inner-wrapper-left-content-preview"
                                        style={{fontFamily: engravingFormData?.fonts?.value, justifyContent: engravingFormData?.textposition?.value}}
                                      >
                                        <span   
                                       >
                                        {
                                          engravingFormData?.customizeText ? engravingFormData?.customizeText : "Message text goes here"
                                        }
                                        </span>
                                      </div>
                    </div>
                </div>
                <div className='engravingpage-inner-wrapper-right'>
                <div className='engravingpage-inner-wrapper-left-title'>
                        <span>{t("Image")}</span>
                    </div>
                <div className='engravingpage-inner-wrapper-left-content'>
                        <div className='engravingpage-inner-wrapper-left-content-head light'>{t("Upload Image")}</div>
                        <div className='engravingpage-inner-wrapper-left-content-dragdrop'>
                        <ImageUploading
                            value={images}
                            onChange={onChange}
                            // maxNumber={maxNumber}
                            dataURLKey="data_url"
                            >
                            {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                            }) => (
                                <div className={`engravingpage-inner-wrapper-left-content-dragdrop-inner ${isDragging ? 'border-active' : ""}`}
                                {...dragProps}>
                                        <div className="engravingpage-inner-wrapper-left-content-dragdrop-inner-container">
                                        <div><ImageGallary/></div>
                                        <div className="engravingpage-inner-wrapper-left-content-dragdrop-inner-container-message">
                                            <span
                                            className='heighlight'
                                            onClick={onImageUpload}
                                            >
                                           {t("Click to upload")}
                                            </span>
                                            <span>{(" or drag and drop")} </span>
                                        </div>
                                        <div className='engravingpage-inner-wrapper-left-content-dragdrop-inner-container-submsg'>{("JPG, JPEG, PNG less than 1 MB")}</div>
                                        </div>
                                </div>
                            )}
                            </ImageUploading>
                            
                        </div>
                    </div>
                    <div className='engravingpage-inner-wrapper-left-content-subdata'>{t("Only support .jpg, .png, .svg and zip files")}</div>
                
                <div className='engravingpage-inner-wrapper-left-barrier'><div className='engravingpage-inner-wrapper-left-barrier-line'></div><div className='or'>{t("OR")}</div><div className='engravingpage-inner-wrapper-left-barrier-line'></div></div>
                
                <div className='engravingpage-inner-wrapper-left-title'>
                        <span>{t("Upload from URL")}</span>
                    </div>

                    <div className='engravingpage-inner-wrapper-left-uploadurl'>
                            <input className='' placeholder={t("Add file URL")} />
                            <button>{t("Upload")}</button>
                    </div>
                   
                            {engravingFormData?.imageFile?.data_url ? 
                             <div className='engravingpage-inner-wrapper-left-imagelist'>
                                        <div className='engravingpage-inner-wrapper-left-imagelist-data'>
                                            <div className='engravingpage-inner-wrapper-left-imagelist-data-content'>
                                                <img src={engravingFormData?.imageFile?.data_url} alt='logo'/>
                                                <div className='engravingpage-inner-wrapper-left-imagelist-data-content-details'>
                                                    <div className='name'>{engravingFormData?.imageFile?.name}</div>
                                                    <div className='size'>{engravingFormData?.imageFile?.size ? engravingFormData?.imageFile?.size : ""}</div>
                                                </div>
                                            </div>
                                            <div className='engravingpage-inner-wrapper-left-imagelist-data-closeicon' onClick={()=>handleCLoseICon()}>
                                                <PlusIcon/>
                                            </div>
                                        </div>
                                </div>
                        : ""}
                    
                    <div className='engravingpage-inner-wrapper-left-content'>
                        <div className='engravingpage-inner-wrapper-left-content-head'>{t("Position")}</div>
                        <Select
                            options={itemArray}
                            className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                            height='46px'
                            width='100%'
                            isSearchable={false}
                            value={engravingFormData?.imgPosition}
                            onChange={(e)=>{setEngravingFormData((state)=>({...state, imgPosition : e}))}}
                        />
                    </div>
                </div>
            </div>
            <div className='engravingpage-inner-footer'>
            <div className="diamond-product-details-section-buttons-backtosetting" onClick={()=>handelBacktosettings()}>
                <span className="diamond-product-details-section-buttons-backtosetting-img"><img src={ArroeIcontop} alt="" /></span> <span className="diamond-product-details-section-buttons-backtosetting-title">{t("Back to Settings")}</span>
            </div>
            <Link 
                                        onClick={showPopup}
                                        className="mixandmatch-product-details-section-buttons-addToCartBtn">
                                        {t("Submit")}
                                    </Link>
            </div>
        </div>
        <ConfirmPopup 
          isVisible={isPopupVisible}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          message="Confirm Customize Your Item"
        />
    </div>
  )
}
