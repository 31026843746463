import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DateTime from '../common/DateTime'
import { Tablehead } from '../common/modules/Tablehead'
import { rolesSubTablethead } from '../common/Tablehead'
import { loading, Updateloading } from '../common/helpers/toastify'
import { _Api } from '../api/_call'
const RolesUser = (props) => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const [userrolelist, setUserrolelist] = useState([])
  const getroleslist = async () => {
    try {
      const postdata = {
        role_id: state?.role_id,
      }
      let res = await _Api(
        postdata,
        `api/v1/Inventory/permissions/getPermissions`,
      )
      if (res.code === 200) {
        setUserrolelist(res?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const pestroleslist = async () => {
    let GetloadingID = loading()

    try {
      const postdata = {
        role_id: state?.role_id,
        userrolelist: userrolelist,
      }
      let res = await _Api(
        postdata,
        `api/v1/Inventory/permissions/savePermissions`,
      )
      if (res.code === 200) {
        Updateloading(t, GetloadingID, t('Successfully Save Permissions'))
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    getroleslist()
    // eslint-disable-next-line
  }, [])

  const change_parmission = (event, value, moduleid, submoduleid) => {
    let updatedList = [...userrolelist]
    updatedList.forEach((result) => {
      if (result?.id === moduleid) {
        result.modules.forEach((data, key) => {
          if (data?.id === submoduleid) {
            if (value === 'view') {
              if (event.target.checked) {
                data.permission.view = 1
              } else {
                data.permission.view = 0
              }
            } else if (value === 'add') {
              if (event.target.checked) {
                data.permission.add = 1
              } else {
                data.permission.add = 0
              }
            } else if (value === 'edit') {
              if (event.target.checked) {
                data.permission.edit = 1
              } else {
                data.permission.edit = 0
              }
            } else if (value === 'delete') {
              if (event.target.checked) {
                data.permission.delete = 1
              } else {
                data.permission.delete = 0
              }
            } else if (value === 'status') {
              if (event.target.checked) {
                data.permission.status = 1
              } else {
                data.permission.status = 0
              }
            } else if (value === 'print') {
              if (event.target.checked) {
                data.permission.print = 1
              } else {
                data.permission.print = 0
              }
            }
            setUserrolelist(updatedList)
          }
        })
      }
    })
  }

  return (
    <>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <h1 className="main-body-heading mb-4"> <Link to="/app/users/roles" className='text-decoration-none main-body-heading'>{t(state?.name)}</Link></h1>
              <div
                className="main-body-top-status-bar"
              >
                <div
                  onClick={() => pestroleslist()}
                  className="main-body-top-inputs-bar-col-right-submit-btn com-btn ms-auto d-flex align-items-center cursor-pointer"
                >
                  {t('Save')}
                </div>
              </div>
              <div
                className="main-body-main-table-wrap position-relative"
              >
                <table
                  className="stripe row-border order-column common-table table-striped main-table w-100"

                >
                  <Tablehead tablehead={rolesSubTablethead} />
                  <tbody>
                    <>
                      {userrolelist?.length
                        ? userrolelist.map((result, key) => {
                          return (
                            <React.Fragment key={key}>
                              <tr key={key}>
                                <td>
                                  <h5 className="text-start fw-semibold">
                                    {result?.name ? t(result?.name) : '-'}
                                  </h5>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              {result?.modules?.length
                                ? result?.modules.map((data, key) => {
                                  return (
                                    <tr key={key}>
                                      <td className="text-start py-4">
                                        {data?.name ? data?.name : ''}
                                      </td>

                                      <td>
                                        {data?.checkBox?.view ? (
                                          <div
                                            className={
                                              state?.disablecheck ||
                                                state?.role_id ===
                                                '5fe4229711b7f036ce3b7e82'
                                                ? 'com-check checkbox-disabled'
                                                : 'com-check'
                                            }
                                          >
                                            <input
                                              type="checkbox"
                                              defaultChecked={
                                                data?.permission?.view
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) =>
                                                change_parmission(
                                                  e,
                                                  'view',
                                                  result?.id,
                                                  data?.id,
                                                )
                                              }
                                              value="view"
                                              id={`permission_view${data?.id}`}
                                              className="checked data-permission_value"
                                            />
                                            <label
                                              className="com-check-label mx-auto"
                                              htmlFor={`permission_view${data?.id}`}
                                            ></label>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </td>
                                      <td>
                                        {data?.checkBox?.add ? (
                                          <div
                                            className={
                                              state?.disablecheck ||
                                                state?.role_id ===
                                                '5fe4229711b7f036ce3b7e82'
                                                ? 'com-check checkbox-disabled'
                                                : 'com-check'
                                            }
                                          >
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                change_parmission(
                                                  e,
                                                  'add',
                                                  result?.id,
                                                  data?.id,
                                                )
                                              }
                                              defaultChecked={
                                                data?.permission?.add
                                                  ? true
                                                  : false
                                              }
                                              value="Add"
                                              id={`permission_add${data?.id}`}
                                              className="checked data-permission_value"
                                            />
                                            <label
                                              className="com-check-label mx-auto"
                                              htmlFor={`permission_add${data?.id}`}
                                            ></label>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </td>
                                      <td>
                                        {data?.checkBox?.edit ? (
                                          <div
                                            className={
                                              state?.disablecheck ||
                                                state?.role_id ===
                                                '5fe4229711b7f036ce3b7e82'
                                                ? 'com-check checkbox-disabled'
                                                : 'com-check'
                                            }
                                          >
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                change_parmission(
                                                  e,
                                                  'edit',
                                                  result?.id,
                                                  data?.id,
                                                )
                                              }
                                              defaultChecked={
                                                data?.permission?.edit
                                                  ? true
                                                  : false
                                              }
                                              id={`permission_edit${data?.id}`}
                                              className="checked"
                                            />
                                            <label
                                              className="com-check-label mx-auto"
                                              htmlFor={`permission_edit${data?.id}`}
                                            ></label>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </td>
                                      <td>
                                        {data?.checkBox?.delete ? (
                                          <div
                                            className={
                                              state?.disablecheck ||
                                                state?.role_id ===
                                                '5fe4229711b7f036ce3b7e82'
                                                ? 'com-check checkbox-disabled'
                                                : 'com-check'
                                            }
                                          >
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                change_parmission(
                                                  e,
                                                  'delete',
                                                  result?.id,
                                                  data?.id,
                                                )
                                              }
                                              defaultChecked={
                                                data?.permission?.delete
                                                  ? true
                                                  : false
                                              }
                                              id={`permission_delete${data?.id}`}
                                              className="checked"
                                            />
                                            <label
                                              className="com-check-label mx-auto"
                                              htmlFor={`permission_delete${data?.id}`}
                                            ></label>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </td>
                                      <td>
                                        {data?.checkBox?.status ? (
                                          <div
                                            className={
                                              state?.disablecheck ||
                                                state?.role_id ===
                                                '5fe4229711b7f036ce3b7e82'
                                                ? 'com-check checkbox-disabled'
                                                : 'com-check'
                                            }
                                          >
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                change_parmission(
                                                  e,
                                                  'status',
                                                  result?.id,
                                                  data?.id,
                                                )
                                              }
                                              defaultChecked={
                                                data?.permission?.status
                                                  ? true
                                                  : false
                                              }
                                              id={`permission_status${data?.id}`}
                                              className="checked"
                                            />
                                            <label
                                              className="com-check-label mx-auto"
                                              htmlFor={`permission_status${data?.id}`}
                                            ></label>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </td>
                                      <td>
                                        {data?.checkBox?.print ? (
                                          <div
                                            className={
                                              state?.disablecheck ||
                                                state?.role_id ===
                                                '5fe4229711b7f036ce3b7e82'
                                                ? 'com-check checkbox-disabled'
                                                : 'com-check'
                                            }
                                          >
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                change_parmission(
                                                  e,
                                                  'print',
                                                  result?.id,
                                                  data?.id,
                                                )
                                              }
                                              defaultChecked={
                                                data?.permission?.print
                                                  ? true
                                                  : false
                                              }
                                              id={`permission_print${data?.id}`}
                                              className="checked"
                                            />
                                            <label
                                              className="com-check-label mx-auto"
                                              htmlFor={`permission_print${data?.id}`}
                                            ></label>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </td>
                                    </tr>
                                  )
                                })
                                : ''}
                            </React.Fragment>
                          )
                        })
                        : ''}
                    </>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default RolesUser
