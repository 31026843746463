import React from 'react'
import "../Footer/footer.scss"
import { useTranslation } from 'react-i18next';
const Footer = ({websiteInfo}) => {
    const { t } = useTranslation();
    return (
        <div className='ecommerce-footer'>
            <div className='ecommerce-footer-inner'>
                <span>{websiteInfo?.websiteInfo?.footerText ? websiteInfo?.websiteInfo?.footerText  : t("GIS © 2024 Jeweal Prosoft Co., Ltd. All rights reserved.")}</span>
                <ul>
                    <li className='ecommerce-footer-inner-singleList'>
                        <span className='ecommerce-footer-inner-singleList-dotBox'></span>{t("Privacy policy")}</li>
                    <li className='ecommerce-footer-inner-singleList'><span className='ecommerce-footer-inner-singleList-dotBox'></span>{t("Terms of service")}</li>
                </ul>
            </div>
        </div >
    )
}

export default Footer