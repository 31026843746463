import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RightSideArrow from "../../assets/icons//RightSideArrow";
import TableDropdown from "../../assets/icons/TableDropDown";
import AccountSidebar from "./AccountSidebar";
import { _Api } from "../../../common/api";

const PurchaseHistory = () => {
  const { t } = useTranslation();
  const [purchaselist, setPurchaselist] = useState([])
  const [lowercount, setLowercount] = useState()
  const [showSubTable, setShowsubTable] = useState(null)
  const [subtabledata, setsubTabledata] = useState([])
  const openSubTable = async (key, cartid) => {
    setShowsubTable(showSubTable === key ? null : key);
    try {
      let postdata = {
        cart_ids: cartid
      }
      let res = await _Api(postdata, `account/getSubTabel`)
      if (res?.statusCode === 200) {
        setsubTabledata(res?.data)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const getpurchasedata = async () => {
    try {
      let postdata = {}
      let res = await _Api(postdata, `account/purchase-history`)
      if (res?.statusCode === 200) {
        setPurchaselist(res?.data)
        setLowercount(res?.count)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  useEffect(() => {
    getpurchasedata()
  }, [])
  return (
    <div className="accountDetailMainPage d-flex ecommerce-main-layout-bodyContent">
      <AccountSidebar />
      <div className="account-details">
        <div className="appointmentMain-Box">
          <div className="appointmentMain-Box-headBox">
            <h2>{t("Purchase History")}</h2>
          </div>
          <div className="com-ecomTableMain">
            <table
              id="appointmentdata"
              className="com-ecomTableMain-table table align-middle m-0"
            >
              <thead className="com-ecomTableMain-table-thead">
                <tr className="text-nowrap align-middle">
                  <th>
                    <div>#</div>
                  </th>
                  <th className="user-name-width ">
                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                      {t("Date Time")}
                      <div className="dropdown com-ecomTableMain-table-thead-singleRowHead-dropdownBox">
                        <div
                          className={
                            "dropdown-menu com-ecomTableMain-table-thead-singleRowHead-dropdownBox-dropdownMenu border-0 overflow-hidden"
                          }
                        >
                          <div className="dropdown-content">
                            {t("select")}
                          </div>
                          <div className="dropdown-content">
                            <input type="text" className="dropdown-content-inputBox" placeholder="Search..." />
                          </div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="user-name-width ">
                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                      {t("Ref No.")}
                      <div className="dropdown com-ecomTableMain-table-thead-singleRowHead-dropdownBox">
                        <button
                          className="after-none bg-transparent border-0 dropdown-toggle p-0 "
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-auto-close="outside"
                        >
                          <span className="dropdownToggle_img"><TableDropdown width={15} height={10} /></span>
                        </button>
                        <div
                          className={
                            "dropdown-menu com-ecomTableMain-table-thead-singleRowHead-dropdownBox-dropdownMenu border-0 overflow-hidden"
                          }
                        >
                          <div className="dropdown-content">
                            {t("select")}
                          </div>
                          <div className="dropdown-content">
                            <input type="text" className="dropdown-content-inputBox" placeholder="Search..." />
                          </div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="user-name-width ">
                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                      {t("SKU")}
                      <div className="dropdown com-ecomTableMain-table-thead-singleRowHead-dropdownBox">
                        <button
                          className="after-none bg-transparent border-0 dropdown-toggle p-0 "
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-auto-close="outside"
                        >
                          <span className="dropdownToggle_img"><TableDropdown width={15} height={10} /></span>
                        </button>
                        <div
                          className={
                            "dropdown-menu com-ecomTableMain-table-thead-singleRowHead-dropdownBox-dropdownMenu border-0 overflow-hidden"
                          }
                        >
                          <div className="dropdown-content">
                          {t("select")}
                          </div>
                          <div className="dropdown-content">
                            <input type="text" className="dropdown-content-inputBox" placeholder="Search..." />
                          </div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th></th>
                  <th className="user-name-width ">
                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                      {t("Qty")}
                      <div className="dropdown com-ecomTableMain-table-thead-singleRowHead-dropdownBox">
                        <button
                          className="after-none bg-transparent border-0 dropdown-toggle p-0 "
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-auto-close="outside"
                        >
                          <span className="dropdownToggle_img"><TableDropdown width={15} height={10} /></span>
                        </button>
                        <div
                          className={
                            "dropdown-menu com-ecomTableMain-table-thead-singleRowHead-dropdownBox-dropdownMenu border-0 overflow-hidden"
                          }
                        >
                          <div className="dropdown-content">
                          {t("select")}
                          </div>
                          <div className="dropdown-content">
                            <input type="text" className="dropdown-content-inputBox" placeholder="Search..." />
                          </div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="user-name-width ">
                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                      {t("Payment Type")}
                      <div className="dropdown com-ecomTableMain-table-thead-singleRowHead-dropdownBox">
                        <button
                          className="after-none bg-transparent border-0 dropdown-toggle p-0 "
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-auto-close="outside"
                        >
                          <span className="dropdownToggle_img"><TableDropdown width={15} height={10} /></span>
                        </button>
                        <div
                          className={
                            "dropdown-menu com-ecomTableMain-table-thead-singleRowHead-dropdownBox-dropdownMenu border-0 overflow-hidden"
                          }
                        >
                          <div className="dropdown-content">
                          {t("select")}
                          </div>
                          <div className="dropdown-content">
                            <input type="text" className="dropdown-content-inputBox" placeholder="Search..." />
                          </div>

                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="user-name-width ">
                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                      {t("Amount")}
                      <div className="dropdown com-ecomTableMain-table-thead-singleRowHead-dropdownBox">
                        <button
                          className="after-none bg-transparent border-0 dropdown-toggle p-0 "
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-auto-close="outside"
                        >
                          <span className="dropdownToggle_img"><TableDropdown width={15} height={10} /></span>
                        </button>
                        <div
                          className={
                            "dropdown-menu com-ecomTableMain-table-thead-singleRowHead-dropdownBox-dropdownMenu border-0 overflow-hidden"
                          }
                        >
                          <p className="singleSelectdropdownTable">
                            {t("Sorting Ascending")}
                          </p>
                          <p className="singleSelectdropdownTable">
                            {t("Sorting Descending")}
                          </p>
                          <div className="dropdown-content dropdownSearchBoxtable">
                            <input type="text" className="dropdown-content-inputBox" placeholder="Search..." />
                          </div>
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className="d-flex gap-3 align-items-center ">
                              <div className="com-ecomCheckBox">
                                <input type="checkbox" className="itemscheckbox com-ecomCheckBox-inputcheck me-2 cursor-pointer" id='check111' />
                                <label className="com-ecomCheckBox-labelBox cursor-pointer fw-semibold" htmlFor='check111'></label>
                              </div>
                              <div>{t("Select All")}</div>
                            </div>
                            <span className="d-flex ">{t("Count")}</span>
                          </div>
                          <div className="d-flex gap-3 align-items-center ms-2">
                            <div className="com-ecomCheckBox">
                              <input type="checkbox" className="itemscheckbox com-ecomCheckBox-inputcheck me-2 cursor-pointer" id='check111' />
                              <label className="com-ecomCheckBox-labelBox cursor-pointer fw-semibold" htmlFor='check111'></label>
                            </div>
                            <div>{t("Color1")}</div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="user-name-width ">
                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                      {t("Location")}
                      <div className="dropdown com-ecomTableMain-table-thead-singleRowHead-dropdownBox">
                        <button
                          className="after-none bg-transparent border-0 dropdown-toggle p-0 "
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-auto-close="outside"
                        >
                          <span className="dropdownToggle_img"><TableDropdown width={15} height={10} /></span>
                        </button>
                        <div
                          className={
                            "dropdown-menu com-ecomTableMain-table-thead-singleRowHead-dropdownBox-dropdownMenu border-0 overflow-hidden"
                          }
                        >
                          <div className="dropdown-content">
                          {t("select")}
                          </div>
                          <div className="dropdown-content">
                            <input type="text" className="dropdown-content-inputBox" placeholder="Search..." />
                          </div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="user-name-width ">
                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                      {t("Shipping Details")}
                      <div className="dropdown com-ecomTableMain-table-thead-singleRowHead-dropdownBox">
                        <button
                          className="after-none bg-transparent border-0 dropdown-toggle p-0 "
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-auto-close="outside"
                        >
                          <span className="dropdownToggle_img"><TableDropdown width={15} height={10} /></span>
                        </button>
                        <div
                          className={
                            "dropdown-menu com-ecomTableMain-table-thead-singleRowHead-dropdownBox-dropdownMenu border-0 overflow-hidden"
                          }
                        >
                          <div className="dropdown-content">
                          {t("select")}
                          </div>
                          <div className="dropdown-content">
                            <input type="text" className="dropdown-content-inputBox" placeholder="Search..." />
                          </div>

                        </div>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="com-ecomTableMain-table-tbody">
                {purchaselist?.length ? purchaselist.map((result, key) => {
                  return (
                    <>
                      <tr className="com-ecomTableMain-table-tbody-trBox" key={key}>
                        <td>{key + 1}</td>
                        <td>{result?.date ? result?.date : "-"}</td>
                        <td className="refNo-tdBox" style={{ cursor: "pointer" }}>
                          {result?.ref_no ? <div onClick={() => {
                            if (result?.url) {
                              window.open(result?.url, '_blank', 'noreferrer')
                            }
                          }}>{result?.ref_no ? result?.ref_no : "-"}</div>
                            : "-"
                          }
                        </td>
                        <td>{result?.SKU ? result?.SKU : "-"}</td>
                        <td onClick={() => openSubTable(key, result?.cart_ids)} className={showSubTable === key ? "arrowIcon" : ""}><RightSideArrow width={24} height={24} color={'#606060'} /></td>
                        <td>{result?.QTY ? result?.QTY : "-"}</td>
                        <td>{result?.Payment_type ? result?.Payment_type : "-"}</td>
                        <td>{result?.amount ? result?.amount : "-"}</td>
                        <td>{result?.location ? result?.location : "-"}</td>
                        <td>{result?.shipping_type ? result?.shipping_type : "-"}</td>
                      </tr>
                      <tr className={showSubTable === key ? "com-ecomTableMain-table table align-middle m-0" : "com-ecomTableMain-table table align-middle m-0 d-none"}>
                        <td colSpan="100">
                          <table width="100%">
                            <thead className="com-ecomTableMain-table-thead" >
                              <tr className=" text-nowrap align-middle " >
                                <th>#</th>
                                <th>{t("Product Name")} </th>
                                <th>{t("SKU")}</th>
                                <th>{t("Metal")}</th>
                                <th>{t("Size")}</th>
                                <th>{t("Stone")}</th>
                                <th>{t("Price")}</th>
                                <th>{t("Qty")}</th>
                                <th>{t("Amount")}</th>
                              </tr>
                            </thead>

                            <tbody className="com-ecomTableMain-table-tbody" >
                              {subtabledata?.length ? subtabledata.map((item, index) => {
                                return (
                                  <tr className="com-ecomTableMain-table-tbody-trBox" key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item?.name}</td>
                                    <td>{item?.SKU}</td>
                                    <td>{item?.metal}</td>
                                    <td>{item?.size}</td>
                                    <td>{item?.stone}</td>
                                    <td>{item?.price}</td>
                                    <td>{item?.qty}</td>
                                    <td>{item?.amount}</td>
                                  </tr>
                                )
                              }) : ""}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </>
                  )
                }) : <tr>
                  <td colSpan="10" className="text-center">{t("No Data Available In Table")}</td>
                  </tr>}

              </tbody>
              <tfoot className="com-ecomTableMain-table-tfootBox">
                <tr>
                  <td colspan="5" className="com-ecomTableMain-table-tfootBox-tdBox">
                  </td>
                  <td className="com-ecomTableMain-table-tfootBox-tdBox">
                    <div className="total-qnty-show me-5">{lowercount?.qty}</div>
                  </td>
                  <td className="com-ecomTableMain-table-tfootBox-tdBox">
                  </td>
                  <td className="com-ecomTableMain-table-tfootBox-tdBox">
                    <div className="total-qnty-show me-5">{lowercount?.amount}</div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseHistory;
