import React, { useEffect, useState } from 'react'
// import ConfirmDoneIcon from '../../assets/images/ConfirmIconGreen.png';
// import ShareIcon from '../../assets/icons/ShareIcon';
// import DownloadIcon from '../../assets/icons/DownloadIcon';
// import PrintIcon from '../../assets/icons/PrintIcon';
// import CartIcon from '../../assets/icons/CopyIcon';
import html2canvas from 'html2canvas';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CartIcon from 'Ecommerce/template_three/assets/icons/CartIcon';
import CircledTick from 'admin/common/icons/sidebar/CircledTick';
import RightArrow from 'admin/common/icons/right-arrow';
import LeftArrow from 'Ecommerce/common/icons/LeftArrow';
import LeftIcon from 'admin/common/icons/sidebar/LeftIcon';
import CopyIcon from 'admin/common/icons/CopyIcon';
import './shipping.scss'
import PrintIcon from 'admin/common/icons/PrintIcon';
import ShareIcon from 'admin/common/icons/ShareIcon';
import DownloadIcon from 'admin/common/icons/DownloadIcon';

export default function ConfirmPage() {
    const { t } = useTranslation();
    const [appoinmentconfirm, setappoinmentconfirm] = useState(true);
    const navigate = useNavigate();
    const {state, countrylist, lastpagedata, shippinglist} = useLocation();
    console.log(state, lastpagedata, countrylist, shippinglist,"Chekc state of this page")
    const [showstatus, setShowstatus] = useState(false)
    const [orderdetails, setOrderdetails] = useState({});
    const [finalPage, setFinalpage] = useState(false);
    const [privacyPolicy, setPrivacyPolicy] = useState({first: false, last: false});
    // const { lastpagedata, countrylist } = props
    console.log(lastpagedata, orderdetails, countrylist, "chekc last page data")
    const handleCopyClick = async (cupyinput) => {
        try {
            await navigator.clipboard.writeText(cupyinput);
            setShowstatus(true)
            setTimeout(() => {
                setShowstatus(false);
            }, 2000);
        } catch (err) {
            console.log(err, "err");
        }
    };
    const downloadPageAsPNG = () => {
        const rootElement = document.getElementById('saveorder');
        html2canvas(rootElement).then((canvas) => {
            const imageURL = canvas.toDataURL('image/png');
            const anchor = document.createElement('a');
            anchor.download = `${orderdetails?.order_no}.png`;
            anchor.href = imageURL;
            anchor.click();
        });
    };
    const handlePrint = () => {
        window.print();
    };
    const setalldata = () => {
        if (lastpagedata?.statusCode === 200) {
            if (countrylist?.length) {
                var shippingcountryname = countrylist.find((item) => item?.value === lastpagedata?.data?.shipping?.country)
                var billingcountryname = countrylist.find((item) => item?.value === lastpagedata?.data?.billing?.country)
            }
            let dataobj = {
                shipping: {},
                billing: {},
                order_no: ""
            }
            let shippingname = [
                lastpagedata?.data?.shipping?.fname ?? '',
                lastpagedata?.data?.shipping?.mname ?? '',
                lastpagedata?.data?.shipping?.lname ?? ''
            ]
            dataobj.shipping.name = shippingname.filter(part => part).join(' ');;
            let shippingaddressParts = [
                lastpagedata?.data?.shipping?.address ?? '',
                lastpagedata?.data?.shipping?.city ?? '',
                lastpagedata?.data?.shipping?.state ?? '',
                shippingcountryname?.label ?? '',
                lastpagedata?.data?.shipping?.zipcode ?? ''
            ];

            dataobj.shipping.address = shippingaddressParts.filter(part => part).join(', ');
            dataobj.shipping.contact = lastpagedata?.data?.shipping?.contacts?.length ? lastpagedata?.data?.shipping?.contacts[0]?.number : ""
            dataobj.shipping.email = lastpagedata?.data?.shipping?.emails?.length ? lastpagedata?.data?.shipping?.emails[0] : ""
            let billingname = [
                lastpagedata?.data?.billing?.fname ?? '',
                lastpagedata?.data?.billing?.mname ?? '',
                lastpagedata?.data?.billing?.lname ?? ''
            ]
            dataobj.billing.name = billingname.filter(part => part).join(' ');
            let addressParts = [
                lastpagedata?.data?.billing?.address ?? '',
                lastpagedata?.data?.billing?.city ?? '',
                lastpagedata?.data?.billing?.state ?? '',
                billingcountryname?.label ?? '',
                lastpagedata?.data?.billing?.zipcode ?? ''
            ];

            dataobj.billing.address = addressParts.filter(part => part).join(', ');
            dataobj.billing.contact = lastpagedata?.data?.billing?.contacts?.length ? lastpagedata?.data?.billing?.contacts[0]?.number : ""
            dataobj.billing.email = lastpagedata?.data?.billing?.emails?.length ? lastpagedata?.data?.billing?.emails[0] : ""
            dataobj.order_no = lastpagedata?.data?.order_no
            dataobj.tax_id = lastpagedata?.data?.tax_id
            setOrderdetails(dataobj)
        }
    }
    useEffect(() => {
        console.log(lastpagedata?.statusCode, "HHHHHHHHHHHHHHHHHHHHHHHh", lastpagedata);

        if (lastpagedata?.statusCode === 200) {
            setalldata()
        }
        // eslint-disable-next-line
        console.log(orderdetails, "check orders details")
    }, [lastpagedata?.statusCode])

    const handleFinal = () =>{
            setFinalpage(true);
    }

    const handleContinueShopping = () => {
        if(privacyPolicy?.last){
            navigate('/collection')
        }
    }

    const orderItemList = [
        {img : 'alf', title: 'C-CLASSIC PAVE BAND', subtitle:'SOLITAIRE COLLECTION', metal: 'Silver', size: '45', price: '฿ 31,800.00', qty:1},
        {img : 'alf', title: 'C-CLASSIC PAVE BAND', subtitle:'SOLITAIRE COLLECTION', metal: 'Silver', size: '45', price: '฿ 31,800.00', qty:1}
    ]
  return (
    <>
    { finalPage ? 
     <div className='ecom-confirmPurchasePage-main' id="saveorder">
     <div className='ecom-confirmPurchasePage-main-headerBox'>
     <span className='ecom-confirmPurchasePage-main-headerBox-doneIconBox'>
             <CircledTick type="curledTick"/>
         </span>
         <h3 className='ecom-confirmPurchasePage-main-headerBox-thankYouText'>{t("Thank you for your purchase")}!</h3>
        <h4 className='ecom-confirmPurchasePage-main-headerBox-subtext'><span>{t("ORDER NO.")}: #{orderdetails?.order_no}</span> <span onClick={() => handleCopyClick(orderdetails?.order_no)}><CopyIcon width="19px" height="19px"/></span></h4>
        <span className='text-center'>{showstatus ? t("Copy Successfully") : ""} </span>
     </div>
     <div className='ecom-confirmPurchasePage-main-containerBox'>
         {/* <div className='ecom-confirmPurchasePage-main-containerBox-getDocsIconBox'>
             <span className='ecom-confirmPurchasePage-main-containerBox-getDocsIconBox-Iconspan'><ShareIcon /></span>
             <span className='ecom-confirmPurchasePage-main-containerBox-getDocsIconBox-Iconspan' onClick={() => downloadPageAsPNG()}><DownloadIcon /></span>
             <span className='ecom-confirmPurchasePage-main-containerBox-getDocsIconBox-Iconspan' onClick={() => handlePrint()}><PrintIcon /></span>
         </div> */}
         {/* <div className='ecom-confirmPurchasePage-main-containerBox-middleContentBox'>
             <div className='ecom-confirmPurchasePage-main-containerBox-middleContentBox-purchaseIdBox'><span className='idShows'>{t("ID")} : #{orderdetails?.order_no}</span> <span onClick={() => handleCopyClick(orderdetails?.order_no)}><CartIcon widt={'19px'} height={'19px'} /> </span></div>
             <span className='text-center'>{showstatus ? t("Copy Successfully") : ""} </span>
             <div className='ecom-confirmPurchasePage-main-containerBox-middleContentBox-thanksParaBox'>{("Thank you for your order, you have successfully placed your order, you can track your order status")} <span>{t("here")}</span>,{t(" Below are related products that goes with what you just purchased")}</div>
         </div> */}
         <div className='ecom-confirmPurchasePage-main-containerBox-productwrapper'>
            <div className='ecom-confirmPurchasePage-main-containerBox-productwrapper-heading'>{t("You have ordered")} {2} {t("products")}</div>
            <div className='ecom-confirmPurchasePage-main-containerBox-productwrapper-list'>
                {orderItemList.length ? orderItemList.map((item, index)=>{
                    return(
                        <div className='item_container' key={index}>
                            <div className='item_container_image'><img src={item?.img} alt='product_image'/></div>
                            <div className='item_container_content'>
                                <div className='item_container_content-left'>
                                    <span>{item?.title}</span>
                                    <span>{item?.subtitle}</span>
                                    <span className='graytext'>{item?.metal}</span>
                                    <span className='sizetext'>{t("Size")}: {item?.title}</span>
                                    <span className='price'>{item?.price}</span>
                                </div>
                                <div className='item_container_content-right'>X{item?.qty}</div>
                            </div>
                        </div>
                    )
                }) : null}
            </div>
            <div className='ecom-confirmPurchasePage-main-containerBox-productwrapper-price'>
                <div className='item'><span>{t("Items")}</span><span>{orderItemList.length}</span></div>
                <div className='item'><span>{t("Sub Total")}</span><span>{orderItemList.length}</span></div>
                <div className='item'><span>{t("Shipping")}</span><span>{orderItemList.length}</span></div>
                <div className='item'><span>{t("Vat")} 7%</span><span>{orderItemList.length}</span></div>
            </div>
            <div className='ecom-confirmPurchasePage-main-containerBox-productwrapper-price border-0'>
            <div className='item'><span>{t("Total")}</span><span>{orderItemList.length}</span></div>
            </div>
         </div>
         <div className='ecom-confirmPurchasePage-main-containerBox-allshippingAddrShow'>
             <div className="ecom-addressShowBox ecom-confirmPurchasePage-main-containerBox-allshippingAddrShow-singleShippAddr">
                 <div className="ecom-addressShowBox-headerBox">
                     <h2 className='shippingaddrHeader'>{t("Shipping Addresses")}</h2>
                 </div>
                 <h3 className="ecom-addressShowBox-AddrparaBox">
                     {orderdetails?.shipping?.name}
                 </h3>
                 <p className="ecom-addressShowBox-AddrparaBox w-100">
                     {orderdetails?.shipping?.address}
                 </p>
                 <p className="ecom-addressShowBox-phonePara">
                     {orderdetails?.shipping?.contact}
                 </p>
                 <p className="ecom-addressShowBox-phonePara">
                     {orderdetails?.shipping?.email}
                 </p>
             </div>
             <div className="ecom-addressShowBox ecom-confirmPurchasePage-main-containerBox-allshippingAddrShow-singleShippAddr d-flex flex-row justify-content-between">
                 <div className="ecom-addressShowBox-headerBox w-auto">
                     <h2 className='shippingaddrHeader'>{t("Billing Addresses")}</h2>
                 </div>
                 <div>
                 <h3 className="ecom-addressShowBox-AddrparaBox">
                     {orderdetails?.billing?.name}
                 </h3>
                 <p className="ecom-addressShowBox-AddrparaBox w-100">
                     {orderdetails?.billing?.address}
                 </p>
                 <p className="ecom-addressShowBox-AddrparaBox w-100">
                 {t("Tax ID")}:
                 <span>{orderdetails?.tax_id}</span>
                 </p>
                 <p className="ecom-addressShowBox-phonePara">
                     {orderdetails?.billing?.contact}
                 </p>
                 <p className="ecom-addressShowBox-phonePara">
                     {orderdetails?.billing?.email}
                 </p>
                 </div>
             </div>
         </div>
     </div>
     <div className="ecom-darkthemed-checkbox ecom-checkbox-wrapper">
                                                    <input
                                                    type="checkbox"
                                                    id="privacy-policy-final"
                                                    onChange={() =>  setPrivacyPolicy((state)=>({...state, last: !privacyPolicy?.last}))}
                                                    checked={privacyPolicy?.last ? true : false}
                                                    />
                                                    <label htmlFor="privacy-policy-final" className="ecom-darkthemed-checkbox-label">
                                                    </label>
                                                    <span className={`mobilefilter-inner-wrapper-content-filterdata-holder-label`}>{t("I accept the")}{" "}<span className="text-decoration-underline">{t("Privacy Policy")}</span>{" "}{t("and")}{" "}<span className="text-decoration-underline">{t("Terms and conditions")}</span>.</span>
                                                </div>
     {appoinmentconfirm ?
         <div className='ecom-confirmPurchasePage-main-footerBox d-flex gap-4 justify-content-center'>
             <Link to="/account/purchasehistory" className='btnwithborder text-decoration-none'>{t("View Purchase History")}</Link>
             <Link to={"/collection"} className='btnbold text-decoration-none'><span>{t("Continue Shopping")}</span></Link>
         </div> : ""}
     </div>
     : 
     <div className="shippingpage shipping-step-mainForm-Container-fieldsetBox">
        <div className="confirmPurchasePage">
            <div className='ecom-shipping_stepthree'>
                
            <div className="ecom-shipping_stepthree-title">
                                <span onClick={()=>navigate(-1)} className="cursor-pointer"> {t("Home")}</span><span><RightArrow/></span><span className="current">{("Checkout")}</span>
            </div>
                {/* <div className='ecom-shipping_stepthree-head'>
                    <span><CircledTick/></span><span>{t("Payment Successful!")}</span>
                </div> */}
                <div className='ecom-shipping_stepthree-content'>
                    <div className='ecom-shipping_stepthree-content-subhead'><span className='title'>{t("Your Order Confirmed!")}</span><span className='data'>{t("Your order is being prepared. You’ll receive another email once your order has shipped at zhao.lala93@gmail.com.")}</span></div>
                    <div className='ecom-shipping_stepthree-content-metadata'>
                        <div className='ecom-shipping_stepthree-content-metadata-details'>
                            <span className='date'>{t("Your order from")}</span>
                            <div className='icons'>
                                <span className='com-confirmPurchasePage-main-containerBox-getDocsIconBox-Iconspan'><ShareIcon type ='new' color='#767676'/></span>
                                <span className='com-confirmPurchasePage-main-containerBox-getDocsIconBox-Iconspan' onClick={() => downloadPageAsPNG()}><DownloadIcon color="#767676"/></span>
                                <span className='com-confirmPurchasePage-main-containerBox-getDocsIconBox-Iconspan' onClick={() => handlePrint()}><PrintIcon color="#767676"/></span>
                            </div>
                        </div>
                        <div className='ecom-shipping_stepthree-content-metadata-addressblock'>
                            <div className='ecom-shipping_stepthree-content-metadata-addressblock-box'>
                                <div className='ecom-shipping_stepthree-content-metadata-addressblock-box-item'>
                                    <span className='title'>{t("Date")}</span>
                                    <span className='value'></span>
                                </div>
                                <div className='ecom-shipping_stepthree-content-metadata-addressblock-box-item'>
                                    <span className='title'>{t("Order number")}</span>
                                    <span className='order'>#0668978978</span>
                                </div>
                            </div>
                            <div className='ecom-shipping_stepthree-content-metadata-addressblock-box'>
                                <div className='ecom-shipping_stepthree-content-metadata-addressblock-box-item'>
                                    <span className='title'>{t("Payment option")}</span>
                                    <span className='value'></span>
                                </div>
                                <div className='ecom-shipping_stepthree-content-metadata-addressblock-box-item'>
                                    <span className='title'>{t("Shipping method")}</span>
                                    <span className='shipping'>{state?.shipping_method?.storepick ? 'Ship to store' : 'Standard method'}</span>
                                </div>
                            </div>
                        </div>
                        <div className='ecom-shipping_stepthree-content-metadata-addBox'>
                        <div className='ecom-shipping_stepthree-content-addresswrapper'>
                            <div className='ecom-shipping_stepthree-content-addresswrapper-title'>{t("Billing Address")}</div>
                            <div className='ecom-shipping_stepthree-content-addresswrapper-list'>
                                <div className='ecom-shipping_stepthree-content-addresswrapper-list-item dark'>{state?.billingaddress?.company}</div>
                                <div className='ecom-shipping_stepthree-content-addresswrapper-list-item lightdark'>{state?.billingaddress?.address}</div>
                                <div className='ecom-shipping_stepthree-content-addresswrapper-list-item'>{state?.billingaddress?.fname}{" "}{state?.billingaddress?.lname}: {state?.billingaddress?.contacts?.[0]?.number}</div>
                                <div className='ecom-shipping_stepthree-content-addresswrapper-list-item'>{t("Tax ID")} : {state?.billingaddress?.taxId}</div>
                                <div className='ecom-shipping_stepthree-content-addresswrapper-list-item'>{t("Email")} : {orderdetails?.billing?.email?.[0]}</div>
                            </div>
                        </div>
                        <div className='ecom-shipping_stepthree-content-addresswrapper'>
                            <div className='ecom-shipping_stepthree-content-addresswrapper-title'>{t("Shipping Address")}</div>
                            <div className='ecom-shipping_stepthree-content-addresswrapper-list'>
                                <div className='ecom-shipping_stepthree-content-addresswrapper-list-item dark'>{state?.shipping_address?.company}</div>
                                <div className='ecom-shipping_stepthree-content-addresswrapper-list-item lightdark'>{state?.shipping_address?.address}</div>
                                <div className='ecom-shipping_stepthree-content-addresswrapper-list-item'>{state?.shipping_address?.fname}{" "}{state?.shipping_address?.lname}: {state?.shipping_address?.contacts?.[0]?.number}</div>
                                <div className='ecom-shipping_stepthree-content-addresswrapper-list-item'>{t("Tax ID")} : {state?.shipping_address?.taxId}</div>
                                <div className='ecom-shipping_stepthree-content-addresswrapper-list-item'>{t("Email")} : {orderdetails?.shipping_address?.email?.[0]}</div>
                            </div>
                        </div>
                        </div>
                        {/* <div className='ecom-shipping_stepthree-content-metadata-item'>
                            <div className='ecom-shipping_stepthree-content-metadata-title'>{t("Payment to")}</div>
                            <div className='ecom-shipping_stepthree-content-metadata-data'>
                                <div><img alt='' src=''/></div>
                                <div className='ecom-shipping_stepthree-content-metadata-data-inner'>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div className='ecom-shipping_stepthree-content-metadata-item'>
                            <div className='ecom-shipping_stepthree-content-metadata-title'>{t("Ref Number")}</div>
                            <div className='ecom-shipping_stepthree-content-metadata-data'>
                                asfafasfas
                            </div>
                        </div>
                        <div className='ecom-shipping_stepthree-content-metadata-item'>
                            <div className='ecom-shipping_stepthree-content-metadata-title'>{t("Payment Method")}</div>
                            <div className='ecom-shipping_stepthree-content-metadata-data'>
                                afsdf
                            </div>
                        </div>
                        <div className='ecom-shipping_stepthree-content-metadata-item'>
                            <div className='ecom-shipping_stepthree-content-metadata-title'>{t("Payment Status")}</div>
                            <div className='ecom-shipping_stepthree-content-metadata-data success'>
                                {t("Success")}
                            </div>
                        </div>
                        <div className='ecom-shipping_stepthree-content-metadata-item'>
                            <div className='ecom-shipping_stepthree-content-metadata-title'>{t("Payment Time")}</div>
                            <div className='ecom-shipping_stepthree-content-metadata-data'>
                                
                            </div>
                        </div>
                        <div className='ecom-shipping_stepthree-content-metadata-item'>
                            <div className='ecom-shipping_stepthree-content-metadata-title'>{t("Customer Name")}</div>
                            <div className='ecom-shipping_stepthree-content-metadata-data'>
                                
                            </div>
                        </div>
                        <div className='ecom-shipping_stepthree-content-metadata-item'>
                            <div className='ecom-shipping_stepthree-content-metadata-title'>{t("Total Payment")}</div>
                            <div className='ecom-shipping_stepthree-content-metadata-data'>
                                
                            </div>
                        </div> */}
                    </div>
                    <div className='ecom-shipping_stepthree-content-productlist'>
                        <div className='ecom-shipping_stepthree-content-productlist-title'>
                            <span>{t("Items Order")}</span>
                            <span>{t("Price")}</span>
                        </div>
                        <div className='ecom-shipping_stepthree-content-productlist-items'>
                            {shippinglist?.length ? 
                            shippinglist.map((item, i)=>{
                                return(
                                    <div className='productbox'>
                                        <div className='productbox-img'>
                                            <img alt='productimg'/>
                                        </div>
                                        <div className='productbox-data'>
                                            <div className='productbox-data-title'>
                                                <span>{item?.name}</span>
                                                <span>{item?.price}</span>
                                            </div>
                                            <div className='productbox-data-values'>
                                                <span>{item?.SKU}</span>
                                                <span>{item?.Metal}</span>
                                                <span>{t("Size")}: {item?.SKU}</span>
                                            </div>
                                            <div className='productbox-data-qty'>
                                                {t("Qty")}:{" "}{item?.Qty}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            : null }
                        </div>
                    </div>
                    <div className='ecom-shipping_stepthree-content-metadata'>
                        <div className='ecom-shipping_stepthree-content-metadata-item'>
                            <div className='ecom-shipping_stepthree-content-metadata-title'>{t("Items")}</div>
                            <div className='ecom-shipping_stepthree-content-metadata-data'>
                                {shippinglist?.length}
                            </div>
                        </div>
                        <div className='ecom-shipping_stepthree-content-metadata-item'>
                            <div className='ecom-shipping_stepthree-content-metadata-title'>{t("Subtotal")}</div>
                            <div className='ecom-shipping_stepthree-content-metadata-data'>
                                asfafasfas
                            </div>
                        </div>
                        <div className='ecom-shipping_stepthree-content-metadata-item'>
                            <div className='ecom-shipping_stepthree-content-metadata-title'>{t("Shipping")}</div>
                            <div className='ecom-shipping_stepthree-content-metadata-data'>
                                afsdf
                            </div>
                        </div>
                        <div className='ecom-shipping_stepthree-content-metadata-item'>
                            <div className='ecom-shipping_stepthree-content-metadata-title'>{t("VAT 7%")}</div>
                            <div className='ecom-shipping_stepthree-content-metadata-data success'>
                                {t("Success")}
                            </div>
                        </div>
                        <div className='ecom-shipping_stepthree-content-metadata-item last'>
                            <div className='ecom-shipping_stepthree-content-metadata-title'>{t("Grand Total")}</div>
                            <div className='ecom-shipping_stepthree-content-metadata-data'>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className='ecom-shipping_stepthree-bottom'>
                    <div className='ecom-shipping_stepthree-bottom-navigator'>
                        <div className='navigatebtn' onClick={()=>navigate('/account/purchasehistory')}>
                            <span>{t("View Purchase History")}</span>
                        </div>
                        <div className='themeBtn' onClick={(e)=> navigate('/collection')}>
                            <span>{t("Continue Shopping")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </div>
    }
   
    </>
  )
}
