import { jsPDF } from "jspdf";
import { getBarcodeBySku, getBarcodeByStockId,getSelectedDataList,getQrcodeByProductId,getQrcodeByStockId } from "../helpers/barcodeHelper";
import { _Api } from "components/api/_call";

function getBase64ImageByteCount(base64String) {
  // Remove the base64 header (data:image/png;base64,) if it exists
  const base64Data = base64String.split(',')[1];
  
  // Calculate the length of the base64 string
  const length = base64Data.length;

  // Calculate the byte count
  const byteCount = (length * 3) / 4;

  return byteCount;
}

function generateHexCode(str) {
  var result = '';
  for (var i=0; i<str.length; i++) {
    result += str.charCodeAt(i).toString(16);
  }
  return result;
}

function encodeToHex(number) {
  // Convert number to string
  let str = number.toString();
  // Convert string to hex representation
  let hex = str.split('').map(char => char.charCodeAt(0).toString(16).padStart(2, '0')).join('');
  // Pad with leading and trailing zeros to match the desired length (32 characters in this case)
  return hex.padStart(32 - hex.length / 2, '0').padEnd(32, '0');
}

export const template3 = async (selectList ,dataList , config = {}) => {
  console.log(config,'template 3')
  const domain = /:\/\/([^\/]+)/.exec(window.location.href)[1];
  const qrurl = window.location.protocol+"//"+domain+"/viewer/product/"
  let info = await _Api({}, `api/v1/store-info/get-db-info`);

  const allSelectedDataList = getSelectedDataList(selectList, dataList);
  const stock_id = getSelectedDataList(selectList, dataList, "stock_id");
  const product_id = getSelectedDataList(selectList, dataList, "product_id");
  const sku = getSelectedDataList(selectList, dataList, "SKU");

  
  let prnCommand = "" ;
  let prnCommandList = []
  let stock_id_list = [];
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}




dataList.map((v,k)=>{
  stock_id_list.push(v.stock_id)
})

let qrCode;

    
    
if (config.qr === true) {
  if (config.qr_type_display === "offline") {

    qrCode = await _Api({ qrCodeData: stock_id, dataList: allSelectedDataList,templateProfile:config,qr_width:150,return_type:"final_data"}, "api/v1/qr-code/createofflinetext")
 

  } else if (config.qr_type_display === "online") {
    qrCode = await _Api({ url: config.qr_url_display,qr_width:150,return_type:"final_data"}, "api/v1/qr-code/createwebsitelink")

  }else if (config.qr_type_display === "product_page") {
    qrCode = await _Api({ qrCodeData: product_id, dataList: allSelectedDataList,templateProfile:config,website_url:qrurl,info: btoa(info.data),qr_width:150,return_type:"buffer" }, "api/v1/qr-code/createproductpagelink")
  }else {
    qrCode = await _Api({ qrCodeData: stock_id, dataList: allSelectedDataList,templateProfile:config,qr_width:150,return_type:"buffer"}, "api/v1/qr-code/createofflinetext")
  }
}




  console.log(qrCode,'template_03_qr')
  const fontSizeStoneBylength = {
    "2": 5,
    "3": 4,
    "4": 3,
    "5": 3
  }

  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'mm',
    format: [75, 13]
  });

  // console.log(InventoryList,'InventoryList')




  //  prnCommand += "^XZ"
  dataList.map((v, k) => {

    let qr;
 
    prnCommand += "^LH0,0";
    prnCommand += "^LS0";
    prnCommand += "^PW826";
    prnCommand += "^LL354";

    prnCommand += "^FO525,70^A0N,27,27^FD"+v.SKU+"^FS";
    prnCommand += "^FO525,98^BY2^BCN,45,N,N,N^FD"+v.stock_id+"^FS";
    

    prnCommand += "^FO525,150^A0N,27,27^FD"+v.stock_id+"^FS";
  
   

    doc.text(v.stock_id, 31.5, 9.8, 0);

    if (config.metal === true) {
      if(v.metal_code){
        prnCommand += "^FO690,150^A0N,27,27^FD"+v.metal_code+"^FS"
         

      }
    }


    let numericString ;
    if(isNaN(v.price)){
      numericString = v.price.replace(/[^0-9.]/g, '');
    }else{
      numericString = v.price;
    }

    if (config.price === true) {

if(v.price){

  if (config.price_display==="with_currency") {
    if (isNaN(numericString)) {
      prnCommand += "^FO525,175^A0N,27,27^FD"+v.currency + " " +numberWithCommas(parseInt(numericString))+"^FS"

    } else {
      console.log('else esle')
      
      prnCommand += "^FO525,175^A0N,27,27^FD"+v.currency + " " +numberWithCommas(parseInt(numericString))+"^FS"

    }

  } else {
    if (isNaN(numericString)) {
      console.log('mmm')
      prnCommand += "^FO525,175^A0N,27,27^FD"+numberWithCommas(numericString)+"^FS"


    }else{
      prnCommand += "^FO525,175^A0N,27,27^FD"+numberWithCommas(parseFloat(numericString).toFixed(2))+"^FS"

    }
  }
  


}
   

    }
    const nwt = v.NetWt + " G"
    const gwt = v.GrossWt + " G"

    if (config.weight === true) {
      if (config.weight_display === "nwt") {
        doc.text(nwt, 45.5, 12, 0);
        prnCommand += "^FO700,175^A0N,27,27^FD"+nwt+"^FS"
        // doc.text( nwt, 47 ,12, 0);

      } else {
        prnCommand += "^FO700,175^A0N,27,27^FD"+gwt+"^FS"
   
        // doc.text( gwt, 47 ,12, 0);

      }
    }

    function checkType(value) {
      if (Array.isArray(value)) {
        return "Array";
      } else if (typeof value === "object" && value !== null) {
        return "Object";
      } else {
        return "Neither Object nor Array";
      }
    }
let stone_list ;
if(checkType( v.Stones_Data)  === "Array"){
  stone_list = v.Stones_Data
}else{
  stone_list = []
  stone_list.push(v.Stones_Data)
}
console.log(stone_list,'stone_list')

let list_stone = {};

stone_list.map((v,k,arr)=>{

// const key = `${config.stone_name&&v.stone_details._id?v.stone_details._id:"no_sc"}_${config.stone_group&&v.stone_group_details._id?v.stone_group_details._id:"no_sg"}_${config.clarity&&v.stone_clarity_details._id?v.stone_clarity_details._id:"no_clar"}_${config.shape&&v.stone_shape_details._id?v.stone_shape_details._id:"no_sh"}`;
const key = `${config.stone_name&&v.stone_details?v.stone_details._id:"no_sc"}_${config.stone_group&&v.stone_group_details?v.stone_group_details._id:"no_sg"}_${config.clarity&&v.stone_clarity_details?v.stone_clarity_details._id:"no_clar"}_${config.shape&&v.stone_shape_details._id?v.stone_shape_details._id:"no_sh"}_${config.cut&&v.stone_cut_details?v.stone_cut_details._id:"no_cut"}_${config.color&&v.stone_color_details?v.stone_color_details._id:""}_${config.stone_size&&v.stone_size_details?v.stone_size_details._id:""}`;

  if (!list_stone[key]) {

    list_stone[key] = {...v};
} else {
  console.log(list_stone,'else ees')
  // console.log(merged[key],' merged[key]')
  // console.log(merged[key].Cts,' merged[key].Cts')
  // console.log(stone.Cts,' stone.Cts')
  list_stone[key].Cts = parseFloat(parseFloat(list_stone[key].Cts)+ parseFloat(v.Cts)).toFixed(2) ;
  list_stone[key].Pcs = parseInt(list_stone[key].Pcs) + parseInt(v.Pcs);

}
 
})
const stone_merge_array = Object.values(list_stone).map(stone => ({
  ...stone,
 
}));
console.log(stone_merge_array,'stone_merge_array')
// console.log(getBase64ImageByteCount("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAACWCAYAAAA8AXHiAAAAAklEQVR4AewaftIAAAO9SURBVO3BQY4cCQ4EQQ+i/v9lX2HO1CGBJKpbG2YB5BdQ2SThCZU3JGGj8kQSNiqbJPwGQ9WBoerAUHVgqDowVB348Bcq35CEJ1S+QeUnUfmGJGyGqgND1YGh6sBQdWCoOvDhoSS8QeUNSdioPJGEb1C5lIQ3qDwxVB0Yqg4MVQeGqgND1YEP/6gkbFSeUKnnhqoDQ9WBoerAUHVgqDrw4f9MEt6QhEsqv9lQdWCoOjBUHRiqDgxVBz48pPKTqGySsFF5IgkblU0SNirfoPINQ9WBoerAUHVgqDowVB348BdJ+A2SsFHZJGGj8g1J2Kg8kYSfZKg6MFQdGKoODFUHhqoD8Q/+QUnYqGySsFHZJOENKv+ioerAUHVgqDowVB0Yqg4EkEMqTyRho7JJwkblUhKeUNkk4QmVJ5LwBpVNEjZD1YGh6sBQdWCoOjBUHYh/sEjCJZUnkrBR2SRho/KTJGGj8kQSNipvSMJGZTNUHRiqDgxVB4aqA0PVgQCyUPmGJGxUfoMkbFTekIQnVL5hqDowVB0Yqg4MVQeGqgMB5AtU3pCEjcoTSfgGlU0SnlB5IglPqDwxVB0Yqg4MVQeGqgND1YEPf6HyhiQ8kYSNykZlk4SNyhMqv1kSNiqbJGyS8MRQdWCoOjBUHRiqDgxVB+IfLJKwUdkkYaNyKQmXVDZJ2KhskrBR2SThksobkrAZqg4MVQeGqgND1YGh6sCHY0n4BpVNEjYqmyRsVDZJ2KhcUtkk4YkkPKGyGaoODFUHhqoDQ9WBoepAAFmobJLwBpUnkvAGlSeS8ITKJglvUNkk4QmVNyRhM1QdGKoODFUHhqoDQ9WB+AeHkvAGlU0SLqlcSsK/aKg6MFQdGKoODFUHhqoDH46p/AYqmyQ8ofIGlTckYaOyScJGZZOEzVB1YKg6MFQdGKoODFUHPkn4DVQ2Kk8kYZOEJ1S+IQkblUtJeGKoOjBUHRiqDgxVB4aqAx/+QuUbknBJ5YkkvCEJb1C5pLJJwhND1YGh6sBQdWCoOjBUHQggC5VNEt6gsknCRuUNSbik8kQSfhKVNwxVB4aqA0PVgaHqwFB14MMvl4Q3qGyS8EQSnlDZJOEJlU0SnkjCG4aqA0PVgaHqwFB1YKg68KH+k4Q3qGySsEnCN6hskvCEymaoOjBUHRiqDgxVB4aqAx8eUvkNVH4SlSeSsFH5BpUnhqoDQ9WBoerAUHVgqDrw4S+S8Jsl4QmVTRI2KpskbFQuJeGJJDyRhCeGqgND1YGh6sBQdWCoOvA/84NYN+nAXAwAAAAASUVORK5CYII="),'stone_merge_array')

if(config.qr ===true){
  
  if (config.qr_type_display === "online") {
  

     console.log(qrCode.data,'qrCode.data')
      
    // const hexData = qrCode.data.toString('hex').toUpperCase();
    // const bytesPerRow = Math.ceil(94 / 8); // Rounded up as 8 pixels per byte (1-bit per pixel)
    // ^GFA,4608,12,96,:Z64:<compressed_hex_data>^FS
    prnCommand +=  "^FO550,210^BQN,2,3,2^FDLA,"+qrCode.data+"^FS"

      // prnCommand +=  "^FO540,230^BQN,2,10^FD>:"+qrCode.data+"^FS"
      // prnCommand +=  "^FO540,230^BQN,2,10^FD>:"+qrCode.data+"^FS"
      
      // ^FO50,50 ^BQN,2,1^FDLA,Hello, World!^FS


//  prnCommand += "^FO540,230"^A0N,25,25^FD"+v.stone_details.code+"^FS"

  }else if(config.qr_type_display === "offline") {
    console.log(qrCode.data[0],'qrCode.data[0].qrcodeImg')
    prnCommand +=  "^FO550,210^BQN,2,4,2^FDLA,"+qrCode.data[0].qrcodeImg.replace("\n",",")+"^FS"
     console.log(qrCode.data,'qrCode.data offline')
     console.log(prnCommand,'prnCommand qrCode.data offline')
  }else if (config.qr_type_display === "product_page"){
    console.log(qrCode.data,'qrCode.data product_page')
    console.log(prnCommand,'prnCommand qrCode.data product_page')

prnCommand +=  "^FO550,210^BQN,2,3,2^FDLA,"+qrCode.data[0].url+"^FS"
  }else {
    prnCommand +=  "^FO550,210^BQN,2,3,2^FDLA,"+qrCode.data+"^FS"

  }


}else{
  stone_merge_array.map((v, k, arr) => {
     
    if (v.stone_details.code) {
      if (v.stone_details.code.length >= v.stone_shape_details.code.length) {
        doc.setFontSize(fontSizeStoneBylength[v.stone_details.code.length])
      } else {
        doc.setFontSize(fontSizeStoneBylength[v.stone_shape_details.code.length]);
      }
    }
 
    let space = 30;
    let topOffset = 200
    // const text = v.stone_code+" "+v.shape_code+" "+" "+v.Pcs+" "+v.Cts;
    let space_val = space * (k + 1) + topOffset;
    if (config.stone_name === true) {
      if (config.stone_group === true){
        prnCommand += "^FO525,"+space_val+"^A0N,25,25^FD"+v.stone_details.code+"^FS"

      }else{
        if(config.clarity === false){

          prnCommand += "^FO525,"+space_val+"^A0N,25,25^FD"+v.stone_details.code+"^FS"

                    //30 60 doc.text(v.stone_details.code, 55, space * (k + 1), 0);

        }
     
      }

    }else{
      if(config.clarity === false){
                if(config.stone_group === true){
                  prnCommand += "^FO525,"+space_val+"^A0N,25,25^FD"+v.stone_details.code+"^FS"

                // doc.text(v.stone_group_details.code, 55, space * (k + 1), 0);

                }
      }
    }

    if (config.stone_group === true&&config.stone_name === false) {
      prnCommand += "^FO525,"+space_val+"^A0N,25,25^FD"+v.stone_details.code+"^FS"

                      // doc.text(v.stone_details.code, 55, space * (k + 1), 0);

      

    }




    if (config.clarity === true && config.stone_group === false && config.stone_name === false ) {
      if(v.clarity){
         prnCommand += "^FO575,"+space_val+"^A0N,25,25^FD"+v.stone_clarity_details.code+"^FS"
      // doc.text(v.stone_clarity_details.code, 60, space * (k + 1),);


      }

  }

if(v.shape){
if (config.shape === true) {
      if(config.clarity === true){
prnCommand += "^FO525,"+space_val+"^A0N,25,25^FD"+v.stone_shape_details.code+"^FS"
        // doc.text(v.stone_shape_details.code, 55, space * (k + 1),);

      }else{

        if(config.stone_group === false&&config.stone_name === false){
          prnCommand += "^FO525,"+space_val+"^A0N,25,25^FD"+v.stone_shape_details.code+"^FS"
    
          // doc.text(v.stone_shape_details.code,55, space * (k + 1),);
        }
        if(config.stone_group === false&&config.stone_name === true){
          prnCommand += "^FO575,"+space_val+"^A0N,25,25^FD"+v.stone_shape_details.code+"^FS"
    
          // doc.text(v.stone_shape_details.code,55, space * (k + 1),);
        }
      }
  }



}
    

if(v.color){
if (config.color === true) {
  if(v.stone_color_details){
    prnCommand += "^FO575,"+space_val+"^A0N,25,25^FD"+v.stone_color_details.code+"^FS"

  }

  }
}
    

if(v.Cut){
if (config.cut === true) {
  if(v.stone_cut_details){
    prnCommand += "^FO575,"+space_val+"^A0N,25,25^FD"+v.stone_cut_details.code+"^FS"

  // doc.text(v.stone_cut_details.code, 60, space * (k + 1),);

  }
}
}
    
    

  if(v.Size){
    if (config.stone_size === true) {
      prnCommand += "^FO555,"+space_val+"^A0N,25,25^FD"+v.stone_size_details.code?v.stone_size_details.code:""+"^FS"

      // doc.text(v.stone_size_details.code?v.stone_size_details.code:"", 58, space * (k + 1),);
    }

  }
   
  if(v.Pcs){

    if (config.stone_pcs === true) {
      prnCommand += "^FO625,"+space_val+"^A0N,25,25^FD"+v.Pcs+"^FS";
      // doc.text(v.Pcs.toString(), 65.5, space * (k + 1));

    }

  }

    if (config.stone_weight === true) {
      prnCommand += "^FO675,"+space_val+"^A0N,25,25^FD"+v.Cts.toString() + v.Unit.substring(0, 1).toLowerCase()+"^FS"
      // doc.text(v.Cts.toString() + v.Unit.substring(0, 1).toLowerCase(), 69, space * (k + 1),);

    }
  })
}


    // if (k + 1 < dataList.length) {
    //   doc.addPage();

    // }
    prnCommand += "^PQ1,0,1,Y"
    prnCommand += "^FN2^RFR,H,2,12,1^FS"

    prnCommand += "^RFW,H,1,2,1^FD3000^FS";
    prnCommand += "^RFW,H,2,2,1^FD"+encodeToHex("-"+v.stock_id)+"^FS"  

     prnCommandList.push(prnCommand)

  
  })

console.log(prnCommand,'prnCommand.length')
return prnCommandList;

}