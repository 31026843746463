import React, { useEffect } from 'react'
import "./common3.scss"
import loginsideBanner from "../assets/images/LoginSideBanner.png"
import CrossIcon from '../assets/icons/CrossIcon'
import { useState } from 'react'
import { _Apiauth } from '../../common/api'
import { useTranslation } from "react-i18next";
const Login = (props) => {
    const { setOpenmodal, setEmailVerifydata } = props
    const { t } = useTranslation()
    const [logindata, setLogindata] = useState()
    const [validation, setValidation] = useState()
    const [lodaer, setLodaer] = useState(false)
    const alllogindata = (keyName, value) => {
        setLogindata((prevState) => ({
            ...prevState,
            [keyName]: value,
        }))
    }
    // eslint-disable-next-line
    const ecomlogin = async () => {
        const validfrom = () => {
            let fromisvalid = true
            if (!logindata?.email) {
                setValidation(prevState => ({
                    ...prevState,
                    emailvalid: {
                        message: "Email is required",
                        status: true
                    }
                }));
                fromisvalid = false
            } else {
                const isEmail = (email) =>
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
                if (!isEmail(logindata?.email)) {
                    setValidation(prevState => ({
                        ...prevState,
                        emailvalid: {
                            message: "Invalid Email",
                            status: true
                        }
                    }));
                    fromisvalid = false
                } else {
                    setValidation(prevState => ({
                        ...prevState,
                        emailvalid: {
                            message: "",
                            status: false
                        }
                    }));
                }
            }
            if (!logindata?.password) {
                setValidation(prevState => ({
                    ...prevState,
                    passwordvalid: {
                        message: "Password is required",
                        status: true
                    }
                }));
                fromisvalid = false
            } else {
                setValidation(prevState => ({
                    ...prevState,
                    passwordvalid: {
                        message: "",
                        status: false
                    }
                }));
            }
            return fromisvalid;
        }
        if (validfrom()) {
            setLodaer(true)
            try {
                const postdata = logindata
                var res = await _Apiauth(postdata, `auth/login`)
                if (res?.statusCode === 200) {
                    if (res?.isVarified === 1) {
                        setEmailVerifydata({ accessToken: res?.accessToken, email: logindata?.email })
                        setOpenmodal(prevState => ({
                            ...prevState,
                            showLoginModel: false,
                            showRegisterModel: false,
                            showemailvarifymodal: true,
                            forgetpassword: false,
                            resetpassword: false,
                            newpassword: false
                        }))
                    } else {
                        localStorage.setItem('ecomaccesstoken', (res?.accessToken));
                        setOpenmodal(prevState => ({
                            ...prevState,
                            showLoginModel: false,
                            showRegisterModel: false,
                            showemailvarifymodal: false,
                            forgetpassword: false,
                            resetpassword: false,
                            newpassword: false
                        }));
                    }
                    setLodaer(false)
                } else {
                    setValidation(prevState => ({
                        ...prevState,
                        sussess: {
                            message: res?.message,
                            status: true
                        }
                    }));
                    setLodaer(false)
                }
            }
            catch (err) {
                setLodaer(false)
                console.log(err, "err")
                if (err?.response?.status) {
                    setValidation(prevState => ({
                        ...prevState,
                        sussess: {
                            message: err.response.data.message,
                            status: true
                        }
                    }));
                }

            }
        }
    }
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                ecomlogin();
            }
        };
        document.addEventListener('keypress', handleKeyPress);
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
        // eslint-disable-next-line
    }, [ecomlogin]);
    return (
        <div className='ecommerce-loginpage-overlay'>
            <div className='ecommerce-loginpage'>
                <span className='Cross_icon' onClick={() => {
                    setOpenmodal(prevState => ({
                        ...prevState,
                        showLoginModel: false,
                        showRegisterModel: false,
                        showemailvarifymodal: false,
                        forgetpassword: false,
                        resetpassword: false,
                        newpassword: false
                    }));
                }} >
                    <CrossIcon width={30} height={30} />
                </span>
                <div className='ecommerce-loginpage-inner'>
                    <div className='formdata'>
                        <div className='d-flex flex-column'>                    
                            <img className='ecommerce-loginpage-inner-logo' src={loginsideBanner} alt='side_banner' />
                    <div className='ecommerce-loginpage-inner-title'>
                        <div className='ecommerce-loginpage-inner-title-heading'>{t("Welcome!")}</div>
                        <div className='ecommerce-loginpage-inner-title-sub-title'> {t("Login to Account")}</div>
                    </div >
                    <div className='ecommerce-loginpage-inner-input_section'>
                    <div className="ecom-formInputBox">
                        <label className={`ecom-formInputBox-label ${logindata?.email ? 'active' : ''}`}>
                            {t("Email")}
                        </label>
                        <input
                            className={`ecom-formInputBox-input ${validation?.emailvalid?.status ? 'border-danger' : ''}`}
                            value={logindata?.email ? logindata?.email : ""}
                            onChange={(e) => alllogindata("email", e.target.value)} 
                        />
                            {validation?.emailvalid?.status ? <div className='text-danger'>{t(`${validation?.emailvalid?.message}`)}</div> : ""}
                    </div>
                        {/* <div className={`ecommerce-loginpage-inner-input_section-inner ${validation?.emailvalid?.status && "required"}`}>
                           
                            <input className={`ecommerce-loginpage-inner-input_section-inner-input ${validation?.emailvalid?.status && "border-danger"}`}
                                onChange={(e) => alllogindata("email", e.target.value)} placeholder='Email'/>
                            {validation?.emailvalid?.status ?
                                <div className='text-danger'>
                                    {t(`${validation?.emailvalid?.message}`)}
                                </div>
                                : ""}
                        </div> */}

                        <div className="ecom-formInputBox">
                            <label className={`ecom-formInputBox-label ${logindata?.password ? 'active' : ''}`}>
                                {t("Password")}
                            </label>
                            <input
                                className={`ecom-formInputBox-input ${validation?.passwordvalid?.status ? 'border-danger' : ''}`}
                                value={logindata?.password ? logindata?.password : ""}
                                onChange={(e) => alllogindata("password", e.target.value)} 
                            />
                                {validation?.passwordvalid?.status ? <div className='text-danger'>{t(`${validation?.passwordvalid?.message}`)}</div> : ""}
                        </div>


                        {/* <div className={`ecommerce-loginpage-inner-input_section-inner ${validation?.passwordvalid?.status && "required"}`}>
                           
                            <input className={`ecommerce-loginpage-inner-input_section-inner-input ${validation?.passwordvalid?.status && "border-danger"}`}
                                type='password'
                                onChange={(e) => alllogindata("password", e.target.value)}
                                placeholder='Password'
                            />
                            {validation?.passwordvalid?.status ?
                                <div className='text-danger'>
                                    {t(`${validation?.passwordvalid?.message}`)}
                                </div>
                                : ""}
                           
                        </div> */}
                        <div className='ecommerce-loginpage-inner-input_section-inner-forget_password'
                                onClick={() => setOpenmodal(prevState => ({
                                    ...prevState,
                                    showLoginModel: false,
                                    showRegisterModel: false,
                                    showemailvarifymodal: false,
                                    forgetpassword: true,
                                    resetpassword: false,
                                    newpassword: false
                                }))}>
                                {t("Forgot Password")}?
                            </div>
                        {validation?.sussess?.status ?
                            <div className='text-danger'>{t(validation?.sussess?.message)}</div> : ""
                        }
                       
                    </div>
                    {lodaer ? <button className='ecommerce-loginpage-inner-input_section-btn'>
                            <div className="border-bottom-0 border-width-2px text-white d-block mx-auto spinner-border flex-shrink-0" role="status"></div>
                        </button> :
                            <button className='btnbold' onClick={() => ecomlogin()}>
                               <span> {t("Login")}</span>
                            </button>}

                    </div>
                    <div className='ecommerce-loginpage-inner-create-account'>
                        <div className='ecommerce-loginpage-inner-create-account-register_btn'>
                            {t("Require an account")}?
                        </div>
                        <div className='ecommerce-loginpage-inner-create-account-register_btn'>
                        <span onClick={() => {
                                setOpenmodal(prevState => ({
                                    ...prevState,
                                    showLoginModel: false,
                                    showRegisterModel: true,
                                    showemailvarifymodal: false,
                                    forgetpassword: false,
                                    resetpassword: false,
                                    newpassword: false
                                }));
                            }}>
                                {t("SIGN UP")} {" "}
                            </span> 
                            {t("to fill your information")}?
                            
                        </div>
                      
                    </div>
                    </div>
                </div >
                <div className='ecommerce-loginpage-sidebanner'>
                    <div className='ecommerce-loginpage-sidebanner-holder'>
                        <img src={loginsideBanner} alt='side_banner' />
                    </div>
                </div >
            </div >
        </div >
    )
}

export default Login