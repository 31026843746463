import React, { useEffect, useState, useRef } from "react";
// import RecentView from "../../components/RecentView";
import CollectionSlider from "../../include/Common/CollectionSlider";
import SortIcon from '../../assets/icons/SortIcon';
import FilterIcon from '../../assets/icons/FIlterIcon';
import HeartIcon from '../../assets/icons/HeartIcon'
import { useTranslation } from 'react-i18next';
import { _Api } from "../../../common/api"
import { Link, useLocation, useParams } from "react-router-dom";
// import FilterEcommerce from "../../components/FilterEcommerce";
import ArrowDownside from '../../assets/icons/ArrowDownSide';
import FitlerIconTab from '../../assets/images/filterIcon.png';
import Spinner from "../../../common/Spinner";
import BottomBar from "../../include/Common/BottomBar";
import './MainPage.scss'
import FilterBar from "../../components/Filter/FilterBar";
import RecentViewProducts from "Ecommerce/template_three/components/RecentViewProducts";
import WishlishHandler from "Ecommerce/template_three/components/WishlishHandler";
// import NoProductAvilable from "../../../common/NoProductAvilable";
// import Mywishlist from "../../components/Mywishlist"

export default function MainPage() {

    const { t } = useTranslation();
    let isArabic = localStorage.getItem('i18nextLng');
    const accessToken = localStorage.getItem("ecomaccesstoken");
    const dataFetched = useRef();
    const dataFetched3 = useRef();
    const [productdata, setProductdata] = useState([])
    const [totaldata, setTotaldata] = useState()
    const [skip, setSkip] = useState(0)
    const [filterdata, setFilterdata] = useState()
    const [datatype, setDatatype] = useState('catalog');
    const [toggleMobileFilter, setToggleMobileFilter] = useState(false);
    const [filters, setFilters] = useState({
      size: [], stone: [], item: "", metal: [],
      price: {}
    })
    const [dataload, setDataload] = useState(false)
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    let localmywishlist = localStorage.getItem('mywishlist') ? JSON.parse(localStorage.getItem('mywishlist')) : [];
    let localmywishlistremove = localStorage.getItem('mywishlistremove') ? JSON.parse(localStorage.getItem('mywishlistremove')) : [];
    const [shortobj, setShortobj] = useState({})
    const [loadeing, setloading] = useState(true)
    const [shortByActiveTab, setShortByActiveTab] = useState('')
    const fullPath = useLocation();
    const parts = fullPath.pathname.split('/');
    const {mainCategory, subCategory} = useParams();
    const [wishlistdata, setWishliatdata] = useState({ id: "", remove: false, edit: false })
   
    const productlist = async (skipdata) => {
      let filterdata = { ...filters }
      if (filters?.metal?.length) {
        let metal = []
        filters?.metal?.forEach((result) => {
          metal.push(result?._id)
        })
        filterdata.metal = metal
      }
      if (filters?.size?.length) {
        let size = []
        filters?.size?.forEach((result) => {
          size.push(result?._id)
        })
        filterdata.size = size
      }
      if (filters?.stone?.length) {
        let stone = []
        filters?.stone?.forEach((result) => {
          stone.push(result?._id)
        })
        filterdata.stone = stone
      }
      try {
  
  
        let postdata = {
          "wishlistproduct": localmywishlist,
          "wishlistremove": localmywishlistremove,
          "sort": { [shortobj?.key]: shortobj?.value },
          "limit": 20,
          "skip": skipdata ? skipdata : 0,
          "type": datatype,
          "size": filterdata?.size ? filterdata?.size : [],
          "stone": filterdata?.stone ? filterdata?.stone : [],
          "item": filterdata?.item ? filterdata?.item : "",
          "metal": filterdata?.metal ? filterdata?.metal : [],
          // "category_slug": parts[1] === "Category" ? parts[2] : "",
          // "collection_slug": parts[1] === "Collection" ? parts[2] : "",
          "price": filterdata?.price ? filterdata?.price : ""
        }
 
        if (mainCategory === "Category") {
          postdata.category_slug = subCategory;
        } else if (mainCategory === "Collection") {
          postdata.collection_slug = subCategory;
        } else if (mainCategory === "Style") {
          postdata.style_slug = subCategory;
        } else {
          postdata.category_slug = subCategory ? subCategory : mainCategory;
        }


        if (postdata?.skip === 0) {
          setloading(true)
        } else {
          setDataload(true)
        }
        let res = await _Api(postdata, `product/list`)
        if (res?.statusCode === 200) {
          setTotaldata(res?.count)
          setSkip(20 + postdata?.skip)
          if (postdata?.skip === 0) {
            setProductdata(res?.data)
          } else {
            let finaldata = productdata.concat(res?.data)
            setProductdata(finaldata)
          }
          dataFetched.current = false
          setloading(false)
          setDataload(false)
        }
  
      }
      catch (err) {
        console.log(err, "err")
      }
    }
  
    const toWishlist = async () => {
      try {
        let postdata = {
          "wishlistproduct": localmywishlist
        }
        let res = await _Api(postdata, `product/toWishlist`)
        if (res?.statusCode === 200) {
          productlist()
        }
      }
      catch (err) {
  
      }
    }
  
    const productfilter = async () => {
      try {
        let postdata = {
          "category_slug": '',
          "collection_slug": '',
          "style_slug": ''
        }

        if (mainCategory === "Category") {
          postdata.category_slug = subCategory;
        } else if (mainCategory === "Collection") {
          postdata.collection_slug = subCategory;
        } else if (mainCategory === "Style") {
          postdata.style_slug = subCategory;
        } else {
          postdata.category_slug = subCategory ? subCategory : mainCategory;
        }
        let res = await _Api(postdata, `product/filter`)
        if (res?.statusCode === 200) {
          setFilterdata(res?.data)
          if (dataFetched3.current) return;
          dataFetched3.current = true
          if (!res?.data?.price?.max) {
            productlist()
          }
        }
      }
      catch (err) {
        console.log(err, "err")
      }
    }
    useEffect(() => {
      productfilter()
      // eslint-disable-next-line
    }, [datatype, fullPath])
  
    useEffect(() => {
      if (accessToken && !filters?.price?.max) {
        toWishlist();
      } else {
        productlist()
      }
      // eslint-disable-next-line
    }, [datatype, filters, shortobj, fullPath]);
    

    
    const closeMobileFilter = (revert = false) => {
     setToggleMobileFilter(false)
    };


  return (
    <div className="ecommerce-mainpage">
        <div className={isFilterOpen ? 'mainbody-overflowSet ecommerce-main-layout-bodyContent' : 'ecommerce-main-layout-bodyContent'}>
            <FilterBar filterdata={filterdata} setFilters={setFilters} filters={filters} datatype={datatype} setDatatype={setDatatype} toggleMobileFilter={toggleMobileFilter} closeMobileFilter={closeMobileFilter}/>
        {/* product filter */}
        <div className='filter-section mobilescreen'>
            <div className='filter-section-filter'>
                <p className="m-0">{t("Filter")}</p>
                <span className="firstFilterIcon">
                <FilterIcon width={20} header={20} />
                </span>
                <span className="secondFilterIcon" onClick={()=>setToggleMobileFilter(!toggleMobileFilter)}>
                <img src={FitlerIconTab} alt="" />
                </span>
            </div>
            <div className='filter-section-sorting'>
                {/* {datatype === "inventory" ?
                <span className='In-stock-indicator catalog'
                    onClick={() => setDatatype("catalog")}>
                    {t("In stock")}
                </span> :
                <span className='In-stock-indicator inventory'
                    onClick={() => setDatatype("inventory")}>
                    {t("In stock")}
                </span>} */}

                <div className="dropdown sortByDropdownNew">

                <div className='filter-section-sorting-btn' id="headeraccountdropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false" >
                    <span className="filter-section-sorting-btn-title">
                    {t("Sort By")}
                    </span>

                    <SortIcon width={20} height={20} />

                </div>
                <ul
                    className="dropdown-menu sortByDropdownNew-List-box"
                    aria-labelledby="headeraccountdropdown"
                >
                    <li className={shortByActiveTab === 'bestselling' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => setShortByActiveTab('bestselling')}>
                    {t("Best selling")}
                    </li>
                    <li className={shortByActiveTab === 'nameascending' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "name", value: 1 }); setShortByActiveTab('nameascending') }} >
                    {t("Alphabetically, A-Z")}
                    </li>
                    <li className={shortByActiveTab === 'namedescending' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "name", value: -1 }); setShortByActiveTab('namedescending') }}>
                    {t("Alphabetically, Z-A")}
                    </li>
                    <li className={shortByActiveTab === 'pricehtol' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "price", value: -1 }); setShortByActiveTab('pricehtol') }}>
                    {t("Price, high to low")}
                    </li>
                    <li className={shortByActiveTab === 'priceltoh' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "price", value: 1 }); setShortByActiveTab('priceltoh') }} >
                    {t("Price, low to high")}
                    </li>
                    <li className={shortByActiveTab === 'oldtonew' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "createdAt", value: 1 }); setShortByActiveTab('oldtonew') }} >
                    {t("Date, old to new")}
                    </li>
                    <li className={shortByActiveTab === 'newtoold' ? 'avtive' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "createdAt", value: -1 }); setShortByActiveTab('newtoold') }}>
                    {t("Date, new to old")}
                    </li>
                </ul>
                </div>
            </div>
        </div>
        <div className='filter-section'>
        <div className='filter-section-filter'>
            <span className="firstFilterIcon">
                {productdata.length} {t("Results")}
            </span>
            <span className="secondFilterIcon">
             {t("Results")}: {productdata.length} {t("Items")}
            </span>
        </div>
        <div className='filter-section-sorting'>
            <div className="dropdown sortByDropdown">

            <div className='filter-section-sorting-btn' id="headeraccountdropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false" >
                <span className="filter-section-sorting-btn-title">
                <span>{t("Sort By")}</span> <span className="filter-section-sorting-btn-title-icon"> <SortIcon width={18} height={18} /></span>
                </span>
            </div>
            <ul
                className="dropdown-menu sortByDropdownNew-List-box"
                aria-labelledby="headeraccountdropdown"
            >
                <li className={shortByActiveTab === 'bestselling' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => setShortByActiveTab('bestselling')}>
                {t("Best selling")}
                </li>
                <li className={shortByActiveTab === 'nameascending' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "name", value: 1 }); setShortByActiveTab('nameascending') }} >
                {t("Alphabetically, A-Z")}
                </li>
                <li className={shortByActiveTab === 'namedescending' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "name", value: -1 }); setShortByActiveTab('namedescending') }}>
                {t("Alphabetically, Z-A")}
                </li>
                <li className={shortByActiveTab === 'pricehtol' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "price", value: -1 }); setShortByActiveTab('pricehtol') }}>
                {t("Price, high to low")}
                </li>
                <li className={shortByActiveTab === 'priceltoh' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "price", value: 1 }); setShortByActiveTab('priceltoh') }} >
                {t("Price, low to high")}
                </li>
                <li className={shortByActiveTab === 'oldtonew' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "createdAt", value: 1 }); setShortByActiveTab('oldtonew') }} >
                {t("Date, old to new")}
                </li>
                <li className={shortByActiveTab === 'newtoold' ? 'avtive' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "createdAt", value: -1 }); setShortByActiveTab('newtoold') }}>
                {t("Date, new to old")}
                </li>
            </ul>
            </div>
        </div>
        </div>
        {/* product filter end */}
        <div className="ecommerce-main-layout-products-container">
        {/* {isFilterOpen &&
            <FilterEcommerce filterdata={filterdata} setFilters={setFilters} toggleFilter={toggleFilter} filters={filters} />
        } */}
        {loadeing ?
            (<div className=" w-100 d-flex justify-content-center align-items-center  ">
            <Spinner themed={true}/>
            </div>) : (
            <>
                {
                productdata.length ? (<div className="ecommerce-main-layout-products-container-show-all_products">
                    {productdata?.map((item, key) => {
                    return (

                        <Link to={`/product/${item?.Slug}`} state={{ id: item?._id }} className='ecom-productcard' key={key}>
                        <div className='ecom-productcard-image'>
                            <div className="ecom-productcard-image-imgBoxShow">
                            <img src={item?.main_image ? item?.main_image : ""} alt='product' /></div>
                            <div className={isArabic === 'arabic' ? 'heartIconBoxrtl' : 'heartIconBox'} onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}>
                            {item?.isWishlist ?
                                <i onClick={() => {
                                setWishliatdata((state) => ({
                                    ...state,
                                    id: item?._id,
                                    add: false,
                                    remove: true,
                                    index: key
                                }))
                                }} className="fa fa-sharp fa-solid fa-heart text-danger" style={{ fontSize: "20px" }}></i> :
                                <span onClick={() => {
                                setWishliatdata((state) => ({
                                    ...state,
                                    id: item?._id,
                                    add: true,
                                    remove: false,
                                    index: key
                                }))
                                }
                                }>   <HeartIcon color={"red"} width={24} height={24} /></span>}
                            </div>
                        </div>
                        <div className="ecom-productcard-details text-decoration-none ">
                            <div className='ecom-productcard-details-wrapper'>
                            <div className="ecom-productcard-details-wrapper-productName">
                                {item?.name}
                            </div>
                            <span>{item?.SKU} </span>
                            <div className="ecom-productcard-details-wrapper-colorthemewrapper">
                                <div className="black"></div>
                                <div className="titanium"></div>
                                <div className="sunshine"></div>
                                <div className="peach"></div>
                            </div>
                            </div>
                            <div className='ecom-productcard-price'>
                            {item?.price}
                            </div>
                        </div>
                        </Link>

                    )
                    })}
                </div>) : (
                    <>
                    ""
                    {/* <NoProductAvilable /> */}
                    </>
                )
                }
            </>

            )
        }
        </div>
        {skip < totaldata && productdata.length ?
        dataload ? <div className=" w-100 d-flex justify-content-center align-items-center">
            <Spinner />
        </div> :
            <div className="text-center cursor-pointer loadmore_button" onClick={() => productlist(skip)}>{t("See more")}
            </div>
        : ""}

        <div className="ecommerce-main-layout-collection">
        <CollectionSlider title="Our Jewellery Collection"/>
        </div>
        <div className="ecommerce-main-layout-recent_view-product">
        
        </div>
        <WishlishHandler
        wishlistdata={wishlistdata}
        productdata={productdata}
        setProductdata={setProductdata}
        />
        </div>
        <BottomBar withTitle={false}/>
    </div>
  )
}
