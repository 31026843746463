import React, { useEffect, useRef } from 'react'
import { _Api } from '../../../common/api';

export default function CollectionBlock() {
  const collectionRefs = useRef([]);
  const bottomRef = useRef(null)
  const CollectionBlockData = async () => {
    try {
      let postdata = {
        slug: ["block-info2"]
      }
      let res = await _Api(postdata, `Cms/get-ecom-blocks`)
      if (res?.status === 200) {
        if (bottomRef.current) {
          const content = res?.data && Array.isArray(res.data) && res.data[0]?.content
            ? res.data[0].content
            : "";
          bottomRef.current.innerHTML = content;
        }
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }


  useEffect(() => {
    CollectionBlockData();

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-in'); // Trigger animation
            observer.unobserve(entry.target); // Stop observing after animation starts
          }
        });
      },
      { threshold: 0.5 }
    );

    // Ensure that collectionRefs.current is always an array and all elements are valid before observing them
    collectionRefs.current.forEach((block) => {
      if (block && block instanceof HTMLElement) {
        observer.observe(block); // Start observing each collection block
      }
    });

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      collectionRefs.current.forEach((block) => {
        if (block && block instanceof HTMLElement) {
          observer.unobserve(block); // Ensure it's a valid HTMLElement before unobserving
        }
      });
    };
  }, []);

  return (
    <div style={{ display: "contents" }} ref={bottomRef}>
      {/* <div className="collectionblock-inner">
     <img src='https://gis247.s3.amazonaws.com/2023in0076/Customer/banner2.png/1727945683102.png' alt='collectionbanner'/>
      <div className='collectionblock-inner-content'>
        <div className='title'>{t("Discover Your Perfect Chain Symphony in Metal.")}</div>
        <div className='subtitle'>{t("Detiam in sapien odio eu senenatis leo maecenas nor de lorem.")}</div>
        <div className='button'>{t("Shop the Categories")}</div>
      </div>
    </div> */}
    </div>
  )
}

