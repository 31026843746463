import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { _Api } from "Ecommerce/common/api";
import CrossIcon from "Ecommerce/template_three/assets/icons/CrossIcon";
import Spinner from "Ecommerce/common/Spinner";
import RightArrow from "admin/common/icons/right-arrow";

export default function CartItemsNotify(props) {
    const { setIsopennotifycart, setisopencart, isopennotifycart } = props;
    const { t } = useTranslation();
    const [notifycartitems, setNotifycartitems] = useState([]);
    const [, setCheckoutItems] = useState([]);
    const [loading, setLoading] = useState(false)
    let localitem = localStorage.getItem('CartItems') ? JSON.parse(localStorage.getItem('CartItems')) : [];
    const navigate = useNavigate()


    const getcartItems = async () => {
        setLoading(true)
        let cartids = [];
        let details = [];
        let product = [];
        let postdata = {}
        if (localitem?.length) { product.push(localitem[localitem?.length - 1]) }
       

        details.push(
            {
                "id": product[0]?.id,
                "qty": product[0]?.qty,
                "diamond_id": product[0]?.diamonddetails?.id,
                "temp_cart_id": product[0]?.temp_cart_id,

            }
        )

        try {
            postdata = { "diamond_ids": details, cart_ids: cartids }
            let res = await _Api(postdata, `order/getCartDetails`)
            if (res?.statusCode === 200) {
                if (res?.data) {
                    console.log(res?.data, "check this")
                    let array = [...res?.data];
                    array?.forEach((result) => {
                        result.qty = parseInt(localitem.filter(e => e?.id === result?.id)[0]['qty']);
                    })
                    console.log(array, "find array")
                    setNotifycartitems(array);
                    
                }
                setLoading(false)
                if (res?.deleted_ids?.length) {
                    localitem.forEach((result) => {
                        if (!res?.deleted_ids.includes(result?.id)) {
                            let data = []
                            data.push(result)
                            localStorage.setItem('CartItems', JSON.stringify(data));
                        }
                    })
                }
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const gotocheckout = () => {
        let checkoutitemarray = [];
        let array = [...notifycartitems];
        checkoutitemarray.push({ 'id': array[0]?.id, temp_cart_id: array[0]?.temp_cart_id, diamond_id: array[0]?.diamond?.id, 'qty': array[0]?.qty, 'price': array[0]?.UnFormatPrice })
        setCheckoutItems(checkoutitemarray);
        if (checkoutitemarray?.length) {
            navigate("/shipping", { state: { checkoutItems: checkoutitemarray } })
        }
    }
    useEffect(() => {
        if (isopennotifycart) {
            getcartItems();
        }
        // eslint-disable-next-line
    }, [isopennotifycart])
  return (
    <>
            <div onClick={() => setIsopennotifycart(false)} className="customize_modal" id="cartN">
                <div className="customize_modal-modalBox">
                    <div className="customize_modal-modalBox-content">
                        <div className="customize_modal-modalBox-content-headBox">
                           
                            <span className="close" onClick={() => setIsopennotifycart(false)}><CrossIcon width={'16.71px'} height={'16.71px'} /></span>
                        </div>
                        {loading ? (<div style={{ marginTop: '5px', marginBottom: '5px' }}>

                            <Spinner />

                        </div>) : (
                            <>

                                {notifycartitems?.length ?
                                    notifycartitems.map((result, key) => {
                                        
                                        return (
                                            <>
                                                <div key={key} className={`ecom_cartproduct`}>
                                                    <div className="ecom_cartproduct-imageBox customize_modal-modalBox-content-bodyBox-imgBox">
                                                        <span className="product-img-span">
                                                            <img src={result?.main_image} alt="" />
                                                        </span>
                                                    </div>
                                                    <div className="ecom_cartproduct-detailsBox">
                                                        <div className="ecom_cartproduct-detailsBox-header">
                                                            <div>
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M7 12L11.9497 16.9497L22.5572 6.34326M2.0498 12.0503L6.99955 17M17.606 6.39355L12.3027 11.6969" stroke="#6A6A6A" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg> 
                                                                <span className="ps-2">{t("Just Added")}</span>
                                                            </div>
                                                            <span className="view" data-bs-toggle="modal" data-bs-target="#AddToCartModal" onClick={() => { setisopencart(true); setIsopennotifycart(false) }}>{t("View Cart")}</span>
                                                        </div>
                                                        <div className="ecom_cartproduct-detailsBox-firstBox">
                                                            <span className="ecom_cartproduct-detailsBox-firstBox-sku">{result?.SKU}</span>
                                                            <p className="ecom_cartproduct-detailsBox-firstBox-productName">
                                                                {result?.name}
                                                            </p>
                                                            <div className="ecom_cartproduct-detailsBox-firstBox-items">
                                                                {result?.Metal}, {result?.Size}
                                                            </div>
                                                            <div className="ecom_cartproduct-detailsBox-firstBox-content">
                                                                <div className="ecom_cartproduct-detailsBox-firstBox-price"> {result?.qty ? `${result.qty} X` : ''} {result?.price}</div>
                                                            </div>
                                                            <div className="ecom_cartproduct-detailsBox-firstBox-continue" onClick={()=>navigate("/")}>
                                                                {t("Continue Shopping")} <RightArrow/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </>
                                        )
                                    })
                                    : ""}
                            </>
                        )}


                        {/* <div style={{ marginTop: '0' }} className="customize_modal-modalBox-content-footerBox">
                            <div className="customize_modal-modalBox-content-footerBox-wrapper">
                                <button className='btnwithborder roundbtn w-100' data-bs-toggle="modal" data-bs-target="#AddToCartModal" onClick={() => { setisopencart(true); setIsopennotifycart(false) }}> {t("View Cart")} ({localitem?.length})</button>
                                <button onClick={gotocheckout} className='btnbold roundbtn' disabled={notifycartitems?.length ? false : true}>{t("Checkout")}</button>
                            </div>
                            <div className="customize_modal-modalBox-content-footerBox-continueShoppLink">
                                <Link to="/"><button className=' customize_modal-modalBox-content-footerBox-continueShoppLink-btnShow'>{t("Continue Shopping")}</button></Link>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
  )
}
