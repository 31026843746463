import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import './header.scss';
import { useSelector } from 'react-redux';
import { _Api } from '../../common/api';

import RightSideArrow from '../assets/icons/RightSideArrow';
import UserIcon from "../assets/icons/UserIcon";
import AppoinmentIcon from "../assets/icons/AppoinmentIcon";
import PurchaseChart from "../assets/icons/CartIcon";
import Login from "../auth/Login";
import Register from "../auth/Register";
import EmailVerify from "../auth/EmailVerify";
import ForgetPassword from "../auth/ForgetPassword";
import ResetPassword from "../auth/ResetPassword";
import SetNewPassword from "../auth/SetNewPassword";
import SearchPopup from "../components/SearchPopup/SearchPopup.js";
import CloseIcon from '../../../admin/common/icons/CloseIcon';
import SearchIcon from '../../../admin/common/icons/SearchIcon';
import HeartIcon from '../../../admin/common/icons/HeartIcon.js';
import Shoppingbag from '../../../admin/common/icons/Shoppingbag.js';
import Hamburger from '../assets/icons/Hamburger.js';
import CommonDropdown from './Common/CommonDropdown.js';
import useScreenSize from './Common/useScreenSize.js';
import BottomSliderBar from './Common/BottomSliderBar';
import SignUpBackIcon from '../assets/icons/SignUpBackIcon';

export default function HeaderNew(props) {
    const dropdownRef = useRef(null);
    const {
        websiteinfo,
        setOpenmodal,
        openmodal,
        SetNewpassworddata,
        newpassworddata,
        setForgetemail,
        forgetemail,
        setEmailVerifydata,
        emailverifydata,
    } = props;
    const { t } = useTranslation();
    const screenSize = useScreenSize();
    const navigate = useNavigate();
    let cartdata = JSON.parse(localStorage.getItem("CartItems"));
    let localmywishlist = localStorage.getItem('mywishlist') ? JSON.parse(localStorage.getItem('mywishlist')) : [];
    let localmywishlistremove = localStorage.getItem('mywishlistremove') ? JSON.parse(localStorage.getItem('mywishlistremove')) : [];
    const dataFetched = useRef();
    const [setLanguageData] = useState(null);
    const [headerdata, setHeaderdata] = useState([]);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const accessToken = localStorage.getItem("ecomaccesstoken");
    const [activeMenu, setActiveMenu] = useState('');
    const [activeDropdowns, setActiveDropdowns] = useState([false]);
    const [searchlist, setSearchlist] = useState([]);
    const [wishlistcounts, setWishlistcounts] = useState();
    const [menubar, setMenubar] = useState(false);
    const [checkApiCall, setCheckApiCall] = useState(true);
    const [showCommonPopup, setShowCommonPopup] = useState(false);
    const [commonPopupCall, setCommonPopupCall] = useState("");
    const [toggleMobileFilter, setToggleMobileFilter] = useState(false);
    const iswishlistobjectheader = useSelector((state) => state.app.iswishlistobjectheader);
    const [showAccountModal, setShowAccountModal] = useState(false);
    const [accountData, setAccountdata] = useState();

    useEffect(() => {
        if (screenSize.width < 1200) {
            setIsSearchOpen(false);
        }
    }, [screenSize])

    let langdata = JSON.parse(localStorage.getItem('UserLangecom'))
    const toggleDropdown = (index) => {
        if (activeDropdowns !== index) {
            if (activeDropdowns[index]) {
                setActiveDropdowns((prev) => {
                    return [false]
                })
            } else {
                setActiveDropdowns((prev) => {
                    const newDropdowns = [];
                    newDropdowns[index] = !newDropdowns[index];
                    return newDropdowns;
                });
            }
        } else {
            setActiveDropdowns((prev) => {
                return [false]
            })
        }
    };

    const handleCloseCommonPopup = () => {
        setShowCommonPopup(false)
    }

    const alllogindata = (keyName, value) => {
        setOpenmodal((prevState) => ({
            ...prevState,
            [keyName]: value,
        }));
    };
    const headerSearchToggle = () => {
        const x = window.matchMedia("(max-width: 1200px)")
        if (x.matches) {
            setCommonPopupCall('Search')
            setShowCommonPopup(true);
        } else {
            setIsSearchOpen(!isSearchOpen);
        }

    };

    const Headerstaics = async () => {
        try {
            let postdata = {
                type: "menu",
            };
            let res = await _Api(postdata, `redis/menu`);
            if (res?.statusCode) {
                setHeaderdata(res?.data?.menu);
            }
        } catch (err) {
            console.log(err, "err");
        }
    };

    const languagedata = async () => {
        setCheckApiCall(false)
        try {
            let postdata = {};
            let res = await _Api(postdata, `language`);
            if (res?.statusCode === 200) {
                setLanguageData(res?.data);
            }
        } catch (err) {
            console.log(err, "err");
        }
    };

    const rtlView = useCallback((type, l) => {
        var jsonData = JSON.stringify(l);
        localStorage.setItem("UserLangecom", jsonData);
        let bodyid = document.querySelector("#body_id");
        if (type === "rtl") {
            bodyid.classList.add("rtl");
        } else {
            bodyid.classList.remove("rtl");
        }
    }, []);
    useEffect(() => {
        if (langdata?.Lan_photo === 'SA.png') {
            rtlView('rtl', langdata)
        }
        // eslint-disable-next-line
    }, [])
    const searchdropdonwn = async (event) => {
        try {
            let postdata = {
                search: event,
            };
            let res = await _Api(postdata, `product/search`);
            if (res?.statusCode === 200) {
                setSearchlist(res?.data);
            }
        } catch (err) {
            console.log(err, "err");
        }
    };
    const wishlistcount = async () => {
        try {
            let postdata = {
                "wishlistproduct": localmywishlist,
                "wishlistremove": localmywishlistremove,
            };
            let res = await _Api(postdata, `product/wishlistCount`);
            if (res?.statusCode === 200) {
                setWishlistcounts(res?.count);
            }
        } catch (err) {
            console.log(err, "err");
        }
    };
    useEffect(() => {
        if (dataFetched.current) return;
        dataFetched.current = true;
        Headerstaics();
        languagedata();
        getaccountdetails();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        wishlistcount();
        // eslint-disable-next-line
    }, [accessToken]);

    useEffect(() => {
        setWishlistcounts(iswishlistobjectheader?.length)
    }, [iswishlistobjectheader])
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setActiveMenu("");
                setActiveDropdowns([false])
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const navigatetoAccount = () => {
        navigate('/account')
    }

    const getaccountdetails = async () => {
        if (accessToken) {
            try {
                let postdata = {};
                let res = await _Api(postdata, `account/getDetails`);
                if (res?.statusCode === 200) {
                    setAccountdata(res?.data);
                }
            } catch (err) {
                console.log(err, "err");
            }
        }
    };

    return (
        <>
            <div className='main-WebHeader template_three'>
                <div className='main-webHeader-navigation'>

                    <div className='main-webHeader-navigation-logobox'>
                        <div className='main-webHeader-navigation-list'>
                            <div className='main-webHeader-navigation-list-icon' onClick={() => { setCommonPopupCall('Menu'); setShowCommonPopup(true) }}>
                                <Hamburger />
                            </div>
                        </div>
                        <Link className='main-webHeader-navigation-logobox-image' to="/">
                            <img src={websiteinfo?.websiteInfo?.logo} alt="" />
                        </Link>
                        <div className='main-webHeader-navigation-headings'>
                            {/* <Link
                                    className="main-webHeader-navigation-headings-title"
                                    to="/"

                                >
                                    <li
                                        className={`main-webHeader-navigation-headings-title-single  dropdown-toggle${activeMenu === "Mixandmatch" && "active"
                                            }`}
                                        onClick={() => { setActiveMenu("Mixandmatch"); setMenubar(false) }}
                                        data-bs-toggle="collapse"
                                        data-bs-target="#navbarSupportedContent"
                                    >
                                        {t("Home")}
                                    </li>
                                </Link> */}
                            {headerdata?.length ?
                                headerdata.map((result, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            <li className="main-webHeader-navigation-headings-title dropdown list-unstyled">
                                                <div
                                                    className={`dropdown-toggle d-flex position-relative main-webHeader-navigation-headings-title-single showArrowDropdown ${activeMenu === result.name ? "active"
                                                        : ''}`}

                                                    onClick={() => {
                                                        if (result?.subcategories.length) {
                                                            activeMenu === result.name ? setActiveMenu('') : setActiveMenu(result.name)

                                                        } else {
                                                            navigate(`/${result.slug}`)
                                                        }
                                                    }
                                                    }
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                >
                                                    <p className="m-0">{t(`${result.name}`)}</p>
                                                    {
                                                        !result?.subcategories.length ? "" : activeMenu === result.name ?
                                                            <svg xmlns="http://www.w3.org/2000/svg" width='24' height='24' fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                                            </svg>
                                                            : <svg
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                stroke="#000"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M9 18L15 12L9 6"
                                                                    stroke="#000"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                    }

                                                </div>
                                            </li>
                                            {activeMenu === result.name && (
                                                <div className="main-WebHeader-content-dropdown dropdown-item">
                                                    <div className="main-WebHeader-content-dropdown-inner d-flex">
                                                        {result?.subcategories?.length
                                                            ? result?.subcategories?.map((element, index) => {
                                                                return (
                                                                    <li key={index} className="w-100 ">
                                                                        {element.subcategories?.length ? (
                                                                            <div
                                                                                className="d-flex justify-content-between"
                                                                                onClick={() => toggleDropdown(index)}
                                                                            >
                                                                                <b className="cursor-pointer main-WebHeader-content-dropdown-inner-title">
                                                                                    {element.name}
                                                                                </b>
                                                                                {element.subcategories?.length > 0 && (

                                                                                    <span className="cursor-pointer text-end">
                                                                                        {activeDropdowns[index] ? <svg xmlns="http://www.w3.org/2000/svg" width='24' height='24' fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                                                                        </svg> : <span><RightSideArrow color={"#000000"} /> </span>}
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        ) : (
                                                                            <Link
                                                                                to={`/${activeMenu}/${element.slug}`}
                                                                                className="main-webHeader-navigation-headings-title"
                                                                            >
                                                                                <div
                                                                                    className="main-webHeader-navigation-headings-title-single border-0"
                                                                                    onClick={() => { setMenubar(false); setActiveMenu(""); setActiveDropdowns([false]) }}
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target="#navbarSupportedContent"
                                                                                >
                                                                                    <b className="cursor-pointer main-WebHeader-content-dropdown-inner-title">
                                                                                        {element.name}
                                                                                    </b>
                                                                                </div>
                                                                            </Link>
                                                                        )}
                                                                        {activeDropdowns[index] && (
                                                                            <ul className="d-flex flex-md-column subDropdownUlBox">
                                                                                {element?.subcategories?.length
                                                                                    ? element.subcategories.map(
                                                                                        (value, count) => (
                                                                                            <Link
                                                                                                className="text-decoration-none main-WebHeader-content-dropdown-inner-title-list subDropdownUlBox-sublist"
                                                                                                to={`/${activeMenu}/${value.slug}`}
                                                                                                key={count}
                                                                                            >
                                                                                                <li
                                                                                                    style={{
                                                                                                        listStyle: "none",
                                                                                                    }}
                                                                                                    key={count}
                                                                                                    className={`${index === 0
                                                                                                        ? "main-WebHeader-content-dropdown-inner-title-list p-0"
                                                                                                        : " p-0"
                                                                                                        }`}
                                                                                                    data-bs-toggle="collapse"
                                                                                                    data-bs-target="#navbarSupportedContent"
                                                                                                    onClick={() => { setMenubar(false); setActiveMenu(""); setActiveDropdowns([false]) }}
                                                                                                >
                                                                                                    {value.name}
                                                                                                </li>
                                                                                            </Link>
                                                                                        )
                                                                                    )
                                                                                    : ""}
                                                                            </ul>
                                                                        )}
                                                                    </li>
                                                                )

                                                            })
                                                            : ""}
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )
                                }) : ""}

                            {/* <Link
                                    className="main-webHeader-navigation-headings-title"
                                    to="/mixandmatch"

                                >
                                    <li
                                        className={`main-webHeader-navigation-headings-title-single  dropdown-toggle${activeMenu === "Mixandmatch" && "active"
                                            }`}
                                        onClick={() => { setActiveMenu("Mixandmatch"); setMenubar(false) }}
                                        data-bs-toggle="collapse"
                                        data-bs-target="#navbarSupportedContent"
                                    >
                                        {t("Mix&Match")}
                                    </li>
                                </Link> */}

                            <Link
                                className="main-webHeader-navigation-headings-title"
                                to="/appointment"

                            >
                                <li
                                    className={`main-webHeader-navigation-headings-title-single  dropdown-toggle${activeMenu === "Appointment" && "active"
                                        }`}
                                    onClick={() => { setActiveMenu("Appointment"); setMenubar(false) }}
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent"
                                >
                                    {t("Book an Appointment")}
                                </li>
                            </Link>

                            {/* contact us */}

                            <Link
                                className="main-webHeader-navigation-headings-title"
                                to="/contactus"

                            >
                                <li
                                    className={`main-webHeader-navigation-headings-title-single  dropdown-toggle${activeMenu === "Appointment" && "active"
                                        }`}
                                    onClick={() => { setActiveMenu("ContactUs"); setMenubar(false) }}
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent"
                                >
                                    {t("Contact Us")}
                                </li>
                            </Link>
                        </div>
                    </div>

                    <div className='main-webHeader-navigation-rightlogo'>
                        <div className="main-webHeader-navigation-rightlogo-searchBarMain">
                            {!isSearchOpen && (
                                <div
                                    className="dropdown-item main-webHeader-navigation-rightlogo-btnBox-SingleList"
                                    type="submit"
                                    onClick={headerSearchToggle}
                                >
                                    <SearchIcon />
                                    {/* <img
                                                className="main-webHeader-navigation-rightlogo-searchBarMain-default-imgShow"
                                                src={SearchIcon}
                                                alt=""
                                                cons="true"
                                            /> */}
                                </div>
                            )}
                            {isSearchOpen && (
                                <>
                                    <SearchPopup isSearchOpen={isSearchOpen} searchlist={searchlist} headerSearchToggle={headerSearchToggle} setIsSearchOpen={setIsSearchOpen} />
                                </>
                            )}
                            <div className={`main-webHeader-navigation-rightlogo-searchBarMain-bar ${isSearchOpen ? 'active' : ""}`}>
                                <span className="main-webHeader-navigation-rightlogo-searchBarMain-bar-searcgicon" onClick={headerSearchToggle}>
                                    <span className="main-webHeader-navigation-rightlogo-searchBarMain-bar-searcgicon-search"><SearchIcon /></span>
                                    {/* <img
                                                    className="main-webHeader-navigation-rightlogo-searchBarMain-bar-searcgicon-search"
                                                    src={SearchIcon}
                                                    alt=""
                                                /> */}
                                </span>
                                <div className="dropdown w-100">
                                    <input
                                        className="main-webHeader-navigation-rightlogo-searchBarMain-bar-inputbox dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        placeholder="Search ..."
                                        onChange={(e) => searchdropdonwn(e.target.value)}
                                    />
                                    <ul
                                        className="dropdown-menu rightSideContentBox-searchBoxshow-listBox"
                                    >
                                        {searchlist?.length
                                            ? searchlist.map((result, key) => {
                                                return (
                                                    <Link
                                                        className="text-decoration-none text-secondary"
                                                        to={`product/${result?.Slug}`}
                                                        state={{ id: result?._id }}
                                                        key={key}
                                                    >
                                                        <li className="dropdown-item  rightSideContentBox-searchBoxshow-listBox-singleList">
                                                            <span className="rightSideContentBox-searchBoxshow-listBox-singleList-spanBox">
                                                                {" "}
                                                                <img
                                                                    className=""
                                                                    width={30}
                                                                    height={30}
                                                                    src={result?.main_image}
                                                                    alt=""
                                                                />
                                                            </span>
                                                            <div>
                                                                <p
                                                                    style={{ fontSize: "14px" }}
                                                                    className="p-0 m-0"
                                                                >
                                                                    {result?.name}
                                                                </p>
                                                                <p
                                                                    style={{
                                                                        fontSize: "10px",
                                                                        fontWeight: "bold",
                                                                        color: "#5CC7C0",
                                                                    }}
                                                                    className="p-0 m-0"
                                                                >
                                                                    {result?.item_name ?? "Ring"} ||{" "}
                                                                    {result?.SKU}{" "}
                                                                </p>
                                                            </div>
                                                        </li>
                                                    </Link>
                                                );
                                            })
                                            : ""}
                                    </ul>
                                </div>
                                <span
                                    className="main-webHeader-navigation-rightlogo-searchBarMain-bar-close"
                                    onClick={headerSearchToggle}
                                >
                                    <CloseIcon />
                                    {/* <img
                                                    className="main-webHeader-navigation-rightlogo-searchBarMain-bar-close-icon"
                                                    src={CrossIcon}
                                                    alt=""
                                                /> */}
                                </span>
                            </div>

                        </div>

                        <div className="dropdown-item main-webHeader-navigation-rightlogo-btnBox"
                            onClick={() => { accessToken ? setShowAccountModal(!showAccountModal) : alllogindata("showLoginModel", true) }}>
                            <span className="dropdown-item main-webHeader-navigation-rightlogo-btnBox-SingleList cursor-pointer">
                                <UserIcon />
                            </span>
                            {showAccountModal ?
                                <ul className='accountDropdown'>
                                    <li className='profile' onClick={() => { accessToken ? navigatetoAccount() : alllogindata("showLoginModel", true) }}>
                                        <div>
                                            <img src={accountData?.profile} alt={accountData?.fname} />
                                        </div>
                                        <div className='profile-data'>
                                            <span className='bold'>{accountData?.fname} {accountData?.lname}</span>
                                            <span>{accountData?.email ? accountData?.email : ""}</span>
                                        </div>
                                    </li>
                                    <li className='tag'><span>{t("Account")}</span></li>
                                    <li onClick={() => navigate('/account')}><span className=''><UserIcon /></span><span>{t("My Account")}</span></li>
                                    <li onClick={() => navigate('/account/purchasehistory')}><span className='nofill'><PurchaseChart /></span><span>{t("Purchase History")}</span></li>
                                    <li onClick={() => navigate('/appointment')}><span className='nofill'><AppoinmentIcon /></span><span>{t("Appointment")}</span></li>
                                    <li className="signout"><span><SignUpBackIcon /></span><span>{t("Sign Out")}</span></li>
                                </ul>
                                : null}
                        </div>
                        <Link className="com-transBtn main-webHeader-navigation-rightlogo-btnBox mt-1"
                            type="submit"
                            to="/wishlist">
                            <span>  <HeartIcon /></span>
                            {wishlistcounts ?
                                <span className="product-count">
                                    {wishlistcounts ? wishlistcounts : 0}
                                </span> : ""}
                        </Link>
                        <Link to="/shoppingcart" className="com-transBtn main-webHeader-navigation-rightlogo-btnBox mt-1" type="submit" >
                            <span className="dropdown-item main-webHeader-navigation-rightlogo-btnBox-SingleList">
                                <Shoppingbag />
                            </span>
                            {cartdata?.length ?
                                <span className="product-count">
                                    {cartdata?.length ? cartdata?.length : 0}
                                </span> : ""}
                        </Link>
                    </div>
                </div>
                {/* <div className='main-webHeader-contentSlider'>
                    <div className='main-webHeader-contentSlider-data'>
                        <div className='main-webHeader-contentSlider-data-capsule' onClick={()=>setToggleMobileFilter(true)}>
                            <span>{t("All")}</span>
                        </div>
                        <div className='main-webHeader-contentSlider-data-capsule' onClick={()=>navigate('/Category/new-arrival')}>
                            <span>{t("New Arrivals")}</span>
                        </div>
                        {headerdata?.length ?
                                    headerdata.map((result, key) => {
                                        return (
                                            <React.Fragment key={key}>
                                                <li className="main-webHeader-contentSlider-data-capsule">
                                                    <div
                                                        className={`dropdown-toggle main-webHeader-contentSlider-data-capsule-inner ${activeMenu === result.name ? "active"
                                                            : ''}`}

                                                        onClick={() =>
                                                            activeMenu === result.name ? setActiveMenu('') : setActiveMenu(result.name)
                                                        }
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                    >
                                                        <p className="m-0">{t(`${result.name}`)}</p>
                                                        {
                                                            activeMenu === result.name ?
                                                                <svg xmlns="http://www.w3.org/2000/svg" width='24' height='24' fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                                                </svg>
                                                                : <svg
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="#000"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M9 18L15 12L9 6"
                                                                        stroke="#000"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                        }

                                                    </div>
                                                </li>

                                                {activeMenu === result.name && screenSize.width > '1200' ? (
                                                    <div className="main-WebHeader-content-dropdown dropdown-item">
                                                        <div className="main-WebHeader-content-dropdown-inner d-flex">
                                                            {result?.subcategories?.length
                                                                ? result?.subcategories?.map((element, index) => {
                                                                    return (
                                                                        <li key={index} className="w-100 ">
                                                                            {element.subcategories?.length ? (
                                                                                <div
                                                                                    className="d-flex justify-content-between"
                                                                                    onClick={() => toggleDropdown(index)}
                                                                                >
                                                                                    <b className="cursor-pointer main-WebHeader-content-dropdown-inner-title">
                                                                                        {element.name}
                                                                                    </b>
                                                                                    {element.subcategories?.length > 0 && (

                                                                                        <span className="cursor-pointer text-end">
                                                                                            {activeDropdowns[index] ? <svg xmlns="http://www.w3.org/2000/svg" width='24' height='24' fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                                                                            </svg> : <span><RightSideArrow color={"#000000"} /> </span>}
                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                            ) : (
                                                                                <Link
                                                                                    to={`/${activeMenu}/${element.slug}`}
                                                                                    className="main-WebHeader-headings-title text-decoration-none"
                                                                                >
                                                                                    <div
                                                                                        className="main-WebHeader-headings-title-single"
                                                                                        onClick={() => { setMenubar(false); setActiveMenu(""); setActiveDropdowns([false]) }}
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target="#navbarSupportedContent"
                                                                                    >
                                                                                        <b className="cursor-pointer main-WebHeader-content-dropdown-inner-title">
                                                                                            {element.name}
                                                                                        </b>
                                                                                    </div>
                                                                                </Link>
                                                                            )}
                                                                            {activeDropdowns[index] && (
                                                                                <ul className="d-flex flex-md-column subDropdownUlBox">
                                                                                    {element?.subcategories?.length
                                                                                        ? element.subcategories.map(
                                                                                            (value, count) => (
                                                                                                <Link
                                                                                                    className="text-decoration-none main-WebHeader-content-dropdown-inner-title-list subDropdownUlBox-sublist p-0 ps-4"
                                                                                                    to={`/${activeMenu}/${value.slug}`}
                                                                                                    key={count}
                                                                                                >
                                                                                                    <li
                                                                                                        style={{
                                                                                                            listStyle: "none",
                                                                                                        }}
                                                                                                        key={count}
                                                                                                        className={`${index === 0
                                                                                                            ? "main-WebHeader-content-dropdown-inner-title-list p-0"
                                                                                                            : " p-0"
                                                                                                            }`}
                                                                                                        data-bs-toggle="collapse"
                                                                                                        data-bs-target="#navbarSupportedContent"
                                                                                                        onClick={() => { setMenubar(false); setActiveMenu(""); setActiveDropdowns([false]) }}
                                                                                                    >
                                                                                                        {value.name}
                                                                                                    </li>
                                                                                                </Link>
                                                                                            )
                                                                                        )
                                                                                        : ""}
                                                                                </ul>
                                                                            )}
                                                                        </li>
                                                                    )

                                                                })
                                                                : ""}
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </React.Fragment>
                                        )
                                    }) : ""}
                        <div className='main-webHeader-contentSlider-data-capsule'>
                            <span>{t("Mix & Match")}</span>
                        </div>
                        <div className='main-webHeader-contentSlider-data-capsule'>
                            <span>{t("Appointment")}</span>
                        </div>
                        <div className='main-webHeader-contentSlider-data-capsule'>
                            <span>{t("Contact Us")}</span>
                        </div>
                    </div>
             </div> */}
                {showCommonPopup &&
                    < CommonDropdown type={commonPopupCall} openmodal={openmodal} setOpenmodal={setOpenmodal} handleCloseCommonPopup={handleCloseCommonPopup} />}
                {toggleMobileFilter && <BottomSliderBar headerdata={headerdata} toggleMobileFilter={toggleMobileFilter} setToggleMobileFilter={setToggleMobileFilter} />}
            </div>

            {openmodal?.showLoginModel ? (
                <Login
                    setOpenmodal={setOpenmodal}
                    setEmailVerifydata={setEmailVerifydata}
                />
            ) : openmodal?.showRegisterModel ? (
                <Register
                    setOpenmodal={setOpenmodal}
                    setEmailVerifydata={setEmailVerifydata}
                />
            ) : openmodal?.showemailvarifymodal ? (
                <EmailVerify
                    setOpenmodal={setOpenmodal}
                    emailverifydata={emailverifydata}
                />
            ) : openmodal?.forgetpassword ? (
                <ForgetPassword
                    setOpenmodal={setOpenmodal}
                    setForgetemail={setForgetemail}
                />
            ) : openmodal?.resetpassword ? (
                <ResetPassword
                    setOpenmodal={setOpenmodal}
                    forgetemail={forgetemail}
                    SetNewpassworddata={SetNewpassworddata}
                />
            ) : openmodal?.newpassword ? (
                <SetNewPassword
                    setOpenmodal={setOpenmodal}
                    newpassworddata={newpassworddata}
                />
            ) : null}
        </>
    )
}
