import CrossIcon from 'admin/common/icons/CrossIcon';
import SearchIcon from 'admin/common/icons/SearchIcon';
import { _Api } from 'Ecommerce/common/api';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './newSearchpopupstyles.scss'

export default function SearchPopup(props) {
    const [searchlist, setSearchlist] = useState()
    const { isSearchOpen, setIsSearchOpen } = props;
    const searchdropdonwn = async (event) => {
      try {
        let postdata = {
          search: event,
        };
        let res = await _Api(postdata, `product/search`);
        if (res?.statusCode === 200) {
          setSearchlist(res?.data);
          if (res?.data?.length) {
            setIsSearchOpen(true)
          } else {
            setIsSearchOpen(false)
          }
        }
      } catch (err) {
        console.log(err, "err");
      }
    };
    const headerSearchToggle = () => {
      setIsSearchOpen(!isSearchOpen);
    };
  return (
    <div className="serchpopup">
      <div className="ecommerce-main-layout-products-container-serchpopup-inner">
        <div className="searchBarMain">
          {!isSearchOpen && (
            <button
              className="com-transBtn rightSideContentBox-btnBox"
              type="submit"
              onClick={headerSearchToggle}
            >
              <img
                className="rightSideContentBox-btnBox-imgShow"
                src={SearchIcon}
                alt=""
                cons
              />
            </button>
          )}
          {isSearchOpen && (
            <div className="rightSideContentBox-searchBoxshow">
              <span className="rightSideContentBox-searchBoxshow-ImgFirstBox">
                <img
                  className="rightSideContentBox-searchBoxshow-ImgFirstBox-firstImg"
                  src={SearchIcon}
                  alt=""
                />
              </span>
              <div className="dropdown w-100">
                <input
                  className="rightSideContentBox-searchBoxshow-inputbox dropdown-toggle"
                  data-bs-toggle="dropdown"
                  placeholder="Search products..."
                  onChange={(e) => searchdropdonwn(e.target.value)}
                />
                <ul
                  className="dropdown-menu rightSideContentBox-searchBoxshow-listBox"
                >
                  {searchlist?.length
                    ? searchlist.map((result, key) => {
                      return (
                        <Link
                          className="text-decoration-none text-white"
                          to={`product/${result?.Slug}`}
                          state={{ id: result?._id }}
                          onClick={headerSearchToggle}
                        >
                          <li className="dropdown-item  rightSideContentBox-searchBoxshow-listBox-singleList">
                            <span className="rightSideContentBox-searchBoxshow-listBox-singleList-spanBox">
                              {" "}
                              <img
                                className=""
                                width={30}
                                height={30}
                                src={result?.main_image}
                                alt=""
                              />
                            </span>
                            <div>
                              <p
                                style={{ fontSize: "14px" }}
                                className="p-0 m-0"
                              >
                                {result?.name}
                              </p>
                              <p

                                style={{
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                }}
                                className="p-0 m-0 spinnercolor"
                              >
                                {result?.item_name ?? "Ring"} ||{" "}
                                {result?.SKU}{" "}
                              </p>
                            </div>
                          </li>
                        </Link>
                      );
                    })
                    : ""}
                </ul>
              </div>
              <span
                className="rightSideContentBox-searchBoxshow-ImgSecondBox"
                onClick={headerSearchToggle}
              >
                <img
                  className="rightSideContentBox-searchBoxshow-ImgSecondBox-secondImg"
                  src={CrossIcon}
                  alt=""
                />
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
