import React from 'react'

export default function ImageGallary() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.2758 2.40625H27.6096C29.294 2.40625 30.6654 3.79283 30.6654 5.49568V19.0883C30.6654 20.7912 29.294 22.1778 27.6096 22.1778H9.2758C7.59173 22.1778 6.22024 20.7912 6.22024 19.0883V5.49568C6.22024 3.79283 7.59173 2.40625 9.2758 2.40625ZM27.6096 4.87775H9.2758C8.93857 4.87775 8.66483 5.15445 8.66483 5.49562V17.1595L12.0382 13.7501C12.873 12.905 14.2334 12.905 15.0693 13.7501L16.5482 15.2416L21.0878 9.73293C21.4936 9.24104 22.0876 8.95687 22.7207 8.95328C23.3574 8.96793 23.9502 9.22749 24.3598 9.71307L28.2208 14.268V5.49568C28.2208 5.15451 27.9471 4.87775 27.6096 4.87775ZM4.64117 20.8788C5.27323 22.7645 7.01368 24.0311 8.97036 24.0311H26.7138L25.6127 27.5713C25.3009 28.7751 24.1886 29.5918 22.9714 29.5918C22.7338 29.5916 22.4972 29.5609 22.2673 29.5004L3.36749 24.3832C1.90944 23.9766 1.04042 22.4507 1.42176 20.9788L4.38691 10.972V19.3972C4.38691 19.8174 4.44936 20.2374 4.59843 20.7367L4.64117 20.8788ZM14.776 8.58468C14.776 9.94769 13.6798 11.0562 12.3316 11.0562C10.9835 11.0562 9.88704 9.94763 9.88704 8.58468C9.88704 7.22173 10.9834 6.11318 12.3316 6.11318C13.6798 6.11318 14.776 7.22167 14.776 8.58468Z" fill="#A3A3A3"/>
</svg>

  )
}
