import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import { _Api } from "../../api/_call";
import { template1 } from "../barcodeTemplate/template1";
import { template2 } from "../barcodeTemplate/template2";
import { template3 } from "../barcodeTemplate/template3";
const getSelectedDataList = (selectedList, DataList, dataField = "") => {
  let data = [];
  if (selectedList.length > 0) {

    selectedList.map(function (v) {
      console.log(v)
      const filteredDataList = DataList.filter((l, idx) => l.id === v);
      if (filteredDataList.length > 0) {
        if (dataField !== "") {
          data.push(filteredDataList[0][dataField])

        } else {
          data.push(filteredDataList[0])

        }
      }
    })
  } else {
    return [];
  }

  return data;
}




const BarcodeModal = (props) => {

  const { t } = useTranslation();


  const dataList = getSelectedDataList(props.selectList, props.inventoryList);
  const stock_id = getSelectedDataList(props.selectList, props.inventoryList, "stock_id");
  const product_id = getSelectedDataList(props.selectList, props.inventoryList, "product_id");
  const sku = getSelectedDataList(props.selectList, props.inventoryList, "SKU");
  console.log(sku, 'skuskusku')


  const [templateProfileList, setTemplateProfileList] = useState(false)

  useEffect(() => {
    async function fetchData() {
      const listDataConfig = await _Api({ template_type: "barcode_printout_template" }, "api/v1/template-printout/list");
      let listProfileData = [];
      const { data } = await listDataConfig;
      data.map((v, k) => {
        if (k === 0) {
          listProfileData.push({

            label: "Please choose print layout",
            value: ""
          })
        }
        listProfileData.push({
          label: v.profile_name,
          value: v._id
        })
      })


      setTemplateProfileList(listProfileData)

    }

    fetchData();

  }, [])


  const printBarcode = async (templateProfileId) => {
    let barcode;
    let qrCode;
    console.log(templateProfileId)
    const templateProfile = await _Api({ _id: templateProfileId }, "api/v1/template-printout/getByid")
    console.log(templateProfile, 'templateProfile templateProfile templateProfile')
    if (templateProfile.data.profile_setup_info.barcode === true) {
      if (templateProfile.data.profile_setup_info.barcode_display === "stock_id") {
        barcode = await _Api({ barcodedata: stock_id }, "api/v1/barcode/create")

      } else {
        barcode = await _Api({ barcodedata: sku, dataType: "sku" }, "api/v1/barcode/create")

      }
    }

    if (templateProfile.data.profile_setup_info.qr === true) {
      if (templateProfile.data.profile_setup_info.qr_type_display === "offline") {
        qrCode = await _Api({ qrCodeData: stock_id, dataList: dataList }, "api/v1/qr-code/createofflinetext")

      } else if (templateProfile.data.profile_setup_info.qr_type_display === "online") {
        console.log(templateProfile.data.profile_setup_info.qr_url_displa)
        qrCode = await _Api({ url: templateProfile.data.profile_setup_info.qr_url_display }, "api/v1/qr-code/createwebsitelink")

      } else if(templateProfile.data.profile_setup_info.qr_type_display === "product_page"){
        qrCode = await _Api({ qrCodeData: product_id, dataList: dataList }, "api/v1/qr-code/createproductpagelink")
      
    }else{
      qrCode = await _Api({ url: templateProfile.data.profile_setup_info.qr_url_display }, "api/v1/qr-code/createwebsitelink")
    }
    }



    const { data } = await templateProfile;
    console.log(data.profile_setup_info)
    if (data.current_template_layout_selected.value === "template_1") {
      template1(barcode, dataList, data.profile_setup_info);

    } 
    
    if (data.current_template_layout_selected.value === "template_2") {

      template2(barcode, dataList, qrCode, 'offline', data.profile_setup_info);

    } 
    
    if (data.current_template_layout_selected.value === "template_3") {
      template3(props.selectList, dataList, data.profile_setup_info);

    }




  }


  let [currentSelectTemplate, setCurrentSelectTemplate] = useState("");

  let [showQrTypeBox, setShowQrTypeBox] = useState(false)

  let [qrType, setQrtype] = useState(1);



  function changeQrstyle(e) {
    setQrtype(e.target.value)
  }


  return (
    <>

      <div
        className="modal fade common-modal"
        id="barcodeModal"
        tabIndex={-1}
        aria-labelledby="barcodeModalLabel"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">

              <h5 className="modal-title" id="customerSearchFilterModalLabel">
                {t('Select Your Barcode Printing Layout')}
              </h5>
              <button
                type="button"
                className="btn-close focus-none shadow-none"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Select
                className="limit-select short mx-3"
                options={templateProfileList}
                value={currentSelectTemplate}
                defaultInputValue={currentSelectTemplate}
                onChange={(e) => {
                  setCurrentSelectTemplate(e)


                }}
              />

              {showQrTypeBox ?
                <div className="radio_qr_style">
                  <label style={{ marginRight: "10px" }}>
                    <input type="radio" value="offline" onChange={changeQrstyle} checked={qrType === "offline"} />
                    <span style={{ marginLeft: "5px" }}>QR OFFLINE</span>
                  </label>
                  <label>
                    <input type="radio" value="online" onChange={changeQrstyle} checked={qrType === "online"} />
                    <span style={{ marginLeft: "5px" }}>QR ONLINE</span>

                  </label>
                </div>


                : ""}



            </div>
            <div className="modal-footer">

              <button
                type="button"
                className="btn"

                onClick={() => {
                  console.log(currentSelectTemplate)
                  printBarcode(currentSelectTemplate.value)
                }}
              >
                {t("Print Barcode")}
              </button>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BarcodeModal;
