import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import RightArrow from '../common/icons/right-arrow';
import Select from "react-select"
import { _Api } from "../api/_call";
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import $ from 'jquery';
import { Pagination } from "../common/Pagination";
import blankimg from "../../assets/images/icons/blankimg.png"
import TableLoader from '../common/TableLoader';
const AppointmentHistory = () => {
  const dropdownRefname = useRef(null);
  const dropdownReflocation = useRef(null)
  const [statuscount, setStatuscount] = useState("")
  const [limit, setLimit] = useState({ label: "100", value: "100" });
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [appointmentlist, setAppointmentlist] = useState([])
  const [TransactionDate, setTransactionDate] = useState()
  const [shortobj, setShortobj] = useState({})
  const [searchVal, setSearchVal] = useState("");
  const [namechecked, setNamehecked] = useState([]);
  const [filtername, setFiltername] = useState('')
  const [namefilter, setNamefilter] = useState([])
  const [locationfilter, setLocationfilter] = useState([])
  const [locationchecked, setLocationchecked] = useState([])
  const [rowchecked, setRowchecked] = useState([])
  const [showLoader, setShowLoader] = useState(true);
  const selectOptions = [
    { label: "100", value: "100" },
    { label: "200", value: "200" },
    { label: "300", value: "300" }
  ]
  const select_status = async (id, event) => {

    try {
      let postdata = {
        "id": id,
        "status": event?.value
      }
      let res = await _Api(postdata, `api/v1/Customer/appointment/status-update`)
      if (res?.code === 200) {
        getappointmentdata()
      }
    }
    catch (err) {
      console.log(err, "Err")
    }
  };
  const colorStyles = {
    option: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.color
      };
    },
    singleValue: (styles, { data }) => ({
      ...styles,
      backgroundColor: data.color,
    })
  };
  const selectTransactionDate = (input) => {
    setTransactionDate(input)
  }
  const filterbyname = () => {
    getappointmentdata(currentPage, limit);
    setstate({ ...state, currentPage: 1 });
  };
  const getlimit = (limit) => {
    setLimit(limit);
    getappointmentdata(1, limit);
    setstate({ ...state, currentPage: 1 });

  };
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });
  const { totalPages, currentPage } = state;
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current });
    getappointmentdata(current, "");

  };
  const getappointmentdata = async (current, firstlimit) => {
    let time = TransactionDate ? new Date(TransactionDate) : null;
    let formattedDate = time ? `${time.getFullYear()}-${(time.getMonth() + 1).toString().padStart(2, '0')}-${time.getDate().toString().padStart(2, '0')}` : null;
    try {
      let postdata = {
        "limit": firstlimit?.value ? parseInt(firstlimit?.value) : parseInt(limit?.value),
        "skip": current ? (current - 1) * parseInt(limit?.value) : 0,
        "search": filtername,
        "name": namechecked,
        "location": locationchecked,
        "sort": { [shortobj?.key]: shortobj?.value },
        "appointment_date": formattedDate

      }
      let res = await _Api(postdata, `api/v1/Customer/appointment/list`)
      if (res?.code === 200) {
        if (res.data) {
          setAppointmentlist(res?.data)
          setShowLoader(false);
          setStatuscount(res?.statusCount)
        } else {
          setShowLoader(true);
        }
        setstate({
          ...state,
          totalPages: Math.ceil(res?.count / postdata.limit),
          currentPage: current ? current : 1
        });
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  useEffect(() => {
    getappointmentdata()
    // eslint-disable-next-line
  }, [shortobj, TransactionDate])
  const setSubTablecustom = async (id, product, location_id, currency, checkbox) => {
    var e = document.querySelector("#table-btn_" + id);
    var row = document.querySelector(`common-main-table-wrap-${id}`);
    var classList_ = e.classList;
    if (classList_.contains("rotate-180")) {
      classList_.remove("rotate-180");
      let alllist_data = [...appointmentlist];
      alllist_data.filter(item => item._id === id).forEach((result) => {
        result.subTabledata = []
        result.subTable = false
      })
      setAppointmentlist(alllist_data);
      let ids = [...rowchecked]
      const newArray = ids.filter(item => item !== id);
      setRowchecked(newArray)
    } else if (checkbox) {
      classList_.add("rotate-180");
      let alllist_data = [...appointmentlist];
      const postdata = {
        "Product": product,
        "location": location_id,
        "currency": currency ? currency : "USD"
      }
      let prioritydata = await _Api(postdata, `api/v1/Customer/appointment/sub-list`);
      if (prioritydata?.code === 200) {
        alllist_data.filter(item => item._id === id).forEach((result) => {
          result.subTabledata = prioritydata?.data
          result.subTable = true
        })
        let ids = [...rowchecked]
        ids.push(id)
        setRowchecked(ids)
        setAppointmentlist(alllist_data);
      } else {
        alllist_data.filter(item => item._id === id).forEach((result) => {
          result.subTabledata = []
          result.subTable = true
        })
        setAppointmentlist(alllist_data);
        let ids = [...rowchecked]
        const newArray = ids.filter(item => item !== id);
        setRowchecked(newArray)
      }
      var checkRowSub = setInterval(function () {
        var rowSub = document.querySelector('#common-sub-table-wrap-' + id);
        if (rowSub) {
          $(row).after(rowSub);
          clearInterval(checkRowSub);
        }
      }, 100);

    }
  }
  const appointmentfilter = async () => {
    try {
      let postdata = {
      }
      let res = await _Api(postdata, `api/v1/Customer/appointment/filter`)
      if (res?.code === 200) {
        setNamefilter(res?.name)
        setLocationfilter(res?.location)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const hendledropdownhide = (lable) => {
    if (lable === "name") {
      dropdownRefname.current.classList.remove("show")
    } else if (lable === "location") {
      dropdownReflocation.current.classList.remove("show")
    }
  }
  const getscarchvalue = async (event, lable) => {
    if (lable === "name") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...namefilter];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setNamefilter(arr)
        });
      } else {
        appointmentfilter()
      }
    } else if (lable === "location") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...locationfilter];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.name?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setLocationfilter(arr)
        });
      } else {
        appointmentfilter()
      }
    }
  }
  useEffect(() => {
    appointmentfilter()
  }, [])
  const handleCheckAllChange = (e, lable) => {
    if (lable === "name") {
      setNamehecked(
        e.target.checked ? namefilter.map((c) => c) : []
      );
    } else if (lable === "location") {
      setLocationchecked(
        e.target.checked ? locationfilter.map((c) => c) : []
      );
    }
  };
  const handlefilerChange = (event, data, lable) => {
    if (lable === "name") {
      setNamehecked((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data]
          : prevChecked.filter((item) => item !== data)

      );
    } else if (lable === "location") {
      setLocationchecked((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data]
          : prevChecked.filter((item) => item !== data)

      );
    }
  };
  return (
    <div className="main-body main-content-wrapper-body">
      <div className="row">
        <div className="col-lg-12">
          <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
            <div className="main-content-wrapper-body-top-bar-left">
              <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
                {t("Customer")} <RightArrow /> <Link className='text-decoration-none' to="/app/admin/customers">{t("Customers")}</Link> <RightArrow /> <span>{t("Appointment")}</span>
              </h1>
            </div>
          </div>
          <div className="main-body-top-inventory main-content-wrapper-body-top-cards">
            <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
              <span>{t("Appointment History")}</span>
            </h1>
            <div className="main-body-top-status-bar mb-3 main-content-wrapper-body-top-status-bar mt-3">

              <Select
                className="limit-select"
                options={selectOptions}
                value={limit}
                onChange={(e) => { setLimit(e); getlimit(e) }}
              />
              <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center ms-auto gap-4">
                <div className="customer-inner-data-payment-table-top-bar-right placeholder-up-input-date date position-relative">
                  <DatePicker
                    className="placeholder-up-input-field h-40"
                    selected={TransactionDate}
                    onChange={selectTransactionDate}
                    name="TransactionDate"
                    dateFormat="MM/dd/yyyy"
                    placeholderText={t("Appointment Date")}
                    autoComplete='none'
                  />
                  <img
                    className="placeholder-up-input-field-calender"
                    src={base_assets + 'images/icons/calendar-green.svg'}
                    alt=""
                  />
                </div>

                <div className="com-search-bar position-relative large">
                  <button
                    onClick={filterbyname}
                    className="main-body-top-status-bar-filter-search pe-1 h-auto">
                    <img className="com-search-bar-search-img" src={base_assets + "images/admin/icons/gray-search.png"} alt="" />
                  </button>
                  <input
                    type="text"
                    className="h-100 border-0 bg-transparent"
                    placeholder={t("Search User")}
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname()}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                </div>
                <button className="print-export-dropdown-selected-icon d-none">
                  <img
                    src={base_assets + "images/icons/printer-icon.png"}
                    alt=""
                  />
                </button>
                <div className="print-export-dropdown dropdown">
                  <button
                    className="btn dropdown-toggle text-center d-flex align-items-center rounded-1 ms-3 justify-content-center bg-white toggle-btn"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={base_assets + "images/admin/icons/gray-ellipsis.png"}
                      alt=""
                    />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item print-dropdown-item">
                      <div className="dropdown-item-img">
                        <img
                          src={base_assets + "images/icons/printer-icon.png"}
                          alt=""
                        />
                      </div>
                      <span>{t("print")}</span>
                    </li>
                    <li className="dropdown-item"
                    >
                      <div className="dropdown-item-img"   >
                        <img
                          src={base_assets + "images/icons/export-x-icon.png"}
                          alt=""
                        />
                      </div>
                      <span>{t("export")}</span>
                    </li>
                    <li className="dropdown-item">
                      <div className="dropdown-item-img">
                        <img
                          src={base_assets + "images/icons/export.png"}
                          alt=""
                        />
                      </div>
                      <span >{t("Import")}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div id="appointmentHolder" className="collapse show mt-4">
              <div className="main-body-top-color-bar">
                <div className="main-body-top-color-bar-single">
                  <div className='main-body-top-color-bar-single-color yellow-bg' />
                  <span className='main-body-top-color-bar-single-color-status yellow-text'>
                    Open
                  </span>
                  <span>
                    {statuscount?.open ? statuscount?.open : 0}
                  </span>
                </div>
                <div className="main-body-top-color-bar-single">
                  <div className='main-body-top-color-bar-single-color main-green-bg' />
                  <span className='main-body-top-color-bar-single-color-status main-green-text'>
                    Close
                  </span>
                  <span>
                    {statuscount?.close ? statuscount?.close : 0}
                  </span>
                </div>
                <div className="main-body-top-color-bar-single">
                  <div className='main-body-top-color-bar-single-color danger-bg' />
                  <span className='main-body-top-color-bar-single-color-status danger-text'>
                    Cancel
                  </span>
                  <span className='mx-2'>
                    {statuscount?.cancel ? statuscount?.cancel : 0}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div
                  className="main-body-main-table-wrap com-custom-table vertical-scroll-table table-responsive position-relative"
                >
                  <table
                    id="my_customer"
                    className="com-custom-table-tag table align-middle bg-white">
                    <thead>
                      <tr className="text-nowrap align-middle">
                        <th className="pe-0">
                          <div className="com-check">
                            <input id="check1" type="checkbox" />
                            <label htmlFor="check1" className="com-check-label" />
                          </div>
                        </th>
                        <th>#</th>
                        <th>{t("Image")}</th>
                        <th>{t("Name")}
                          <div className="dropdown d-inline-block com-custom-table-tag-filter">
                            <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                              <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                            </button>
                            <div className={"dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"}
                              ref={dropdownRefname}>
                              <div className="asc-des-order">
                                <div onClick={() => { setShortobj({ key: "name", value: 1 }); hendledropdownhide("name") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" />{t("Sort Ascending")}</div>
                                <div onClick={() => { setShortobj({ key: "name", value: -1 }); hendledropdownhide("name") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                              </div>
                              <div className="com-custom-table-tag-filter-menu-search">
                                <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                                  <img src={base_assets + "images/icons/search-icon.png"} alt="" />
                                  <input className="border-0" type="text" placeholder="Search"
                                    onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "name")}
                                    onChange={(e) => setSearchVal(e.target.value)} />
                                </div>
                              </div>
                              <div className="com-custom-table-tag-filter-menu-select">
                                <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                                  <div>
                                    <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="name"
                                      onChange={(e) => handleCheckAllChange(e, "name")}
                                      checked={namechecked.length === namefilter.length} />
                                    <label className="form-check-label cursor-pointer fw-semibold" htmlFor="name">{t("Select All")}</label>
                                  </div>
                                  <label>{namechecked.length}/{namefilter.length} {t("Selected")}</label>
                                </div>
                                <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                                  {namefilter?.length ? namefilter.map((result, key) => {
                                    return (
                                      <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                        <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={"name" + key}
                                          checked={
                                            namechecked ? namechecked.includes(result) : ""
                                          }
                                          onChange={(e) => handlefilerChange(e, result, "name")} />
                                        <label className="form-check-label cursor-pointer" htmlFor={"name" + key}>{result}</label>
                                      </li>
                                    )
                                  }) : ""}

                                </ul>
                              </div>
                              <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2"
                              >
                                <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor" onClick={() => { setNamehecked([]); setShortobj({}); hendledropdownhide("name") }}>{t("Clear")}</button>
                                <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { getappointmentdata(); hendledropdownhide("name") }}>{t("Apply")}</button>
                              </div>
                            </div>
                          </div>
                        </th>
                        <th>{t("Date Time")}</th>
                        <th>{t("Ref No.")}</th>
                        <th>{t("SKU")}</th>
                        <th>{t("Location")}
                          <div className="dropdown d-inline-block com-custom-table-tag-filter">
                            <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                              <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                            </button>
                            <div className="dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"
                              ref={dropdownReflocation}>
                              <div className="asc-des-order">
                                <div onClick={() => { setShortobj({ key: "location.name", value: 1 }); hendledropdownhide("location") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                                <div onClick={() => { setShortobj({ key: "location.name", value: -1 }); hendledropdownhide("location") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                              </div>
                              <div className="com-custom-table-tag-filter-menu-search">
                                <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                                  <img src={base_assets + "images/icons/search-icon.png"} alt=""
                                  />
                                  <input className="border-0" type="text" placeholder="Search"
                                    onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "location")}
                                    onChange={(e) => setSearchVal(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="com-custom-table-tag-filter-menu-select">
                                <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                                  <div>
                                    <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="location"
                                      onChange={(e) => handleCheckAllChange(e, "location")}
                                      checked={locationchecked.length === locationfilter.length} />
                                    <label className="form-check-label cursor-pointer fw-semibold" htmlFor="location">{t("Select All")}</label>
                                  </div>
                                  <label>{locationchecked?.length}/{locationfilter?.length} {t("Selected")}</label>
                                </div>
                                <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                                  {locationfilter?.length ? locationfilter.map((result, key) => {
                                    return (
                                      <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                        <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox"
                                          id={result}
                                          onChange={(e) => handlefilerChange(e, result, "location")}
                                          checked={
                                            locationchecked ? locationchecked.includes(result) : ""
                                          } />
                                        <label className="form-check-label cursor-pointer" htmlFor={result}>{result}</label>
                                      </li>
                                    )
                                  }) : ""}
                                </ul>
                              </div>
                              <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2">
                                <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                                  onClick={() => { setLocationchecked([]); setShortobj({}); hendledropdownhide("location") }}>{t("Clear")}</button>
                                <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { getappointmentdata(); hendledropdownhide("location") }}>{t("Apply")}</button>
                              </div>
                            </div>
                          </div>
                        </th>
                        <th>{t("Appointment Date")}</th>
                        <th>{t("Qty")}</th>
                        <th>{t("Amount")} </th>
                        <th>{t("Status")} </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {appointmentlist?.length ? appointmentlist.map((result, index) => {
                        let status = {}
                        var valueofstatus = []
                        if (result?.status === 0) {
                          status = { label: t("Open"), value: 0, color: "#5CC7C1" }
                          valueofstatus = [
                            { label: t("Close"), value: 1, color: "#F9BC44" },
                            { label: t("Cancel"), value: 2, color: "#FF5757" },
                          ]
                        } else if (result?.status === 1) {
                          status = { label: t("Close"), value: 1, color: "#F9BC44" }
                          valueofstatus = []
                        } else {
                          status = { label: t("Cancel"), value: 2, color: "#FF5757" }
                          valueofstatus = []
                        }
                        return (
                          <React.Fragment key={index}>
                            <tr className="text-nowrap" key={result?._id}>
                              <td className="pe-0">
                                <div className="com-check">
                                  <input type="checkbox"
                                    id={result?._id}
                                    onChange={(e) =>
                                      setSubTablecustom(
                                        result?._id,
                                        result?.product,
                                        result?.location_id,
                                        result?.currency,
                                        e.target.checked
                                      )}
                                    checked={rowchecked.includes(result?._id)}
                                  />
                                  <label className="com-check-label"
                                    htmlFor={result?._id} />
                                </div>
                              </td>
                              <td>{index + 1}</td>
                              <td>
                                <div className="pro-border-img">
                                  <img className="h-100 w-100 mw-100 object-fit-cover"
                                    src={result?.image ? result?.image : blankimg} alt="" />
                                </div>
                              </td>

                              <td className='text-forname'>{result?.name}</td>

                              <td >{result?.date}</td>

                              <td className='text-refno'>{result?.ref_no}</td>

                              <td
                              >
                                {result?.SKU}
                                <img
                                  id={"table-btn_" + result?._id}
                                  alt='expandicon'
                                  className="sku-col-arrow cursor-pointer mx-2"
                                  src={
                                    base_assets +
                                    "images/admin/icons/expand-icon.png"
                                  }
                                  onClick={() =>
                                    setSubTablecustom(
                                      result?._id,
                                      result?.product,
                                      result?.location_id,
                                      result?.currency,
                                      true
                                    )}
                                />
                              </td>

                              <td className="text-center">
                                {result?.location}
                              </td>
                              <td>{result?.appointment_date}</td>
                              <td>{result?.qty}</td>
                              <td>{result?.amount}</td>
                              <td>
                                <div
                                  className={
                                    "dropdown status-dropdown status-dropdown-select"
                                  }
                                  style={result?.status === 0 ? { backgroundColor: '#5CC7C1', width: 'fit-content' } : result?.status === 1 ? { backgroundColor: '#F9BC44', width: 'fit-content' } : { backgroundColor: '#FF5757', width: 'fit-content' }}
                                >

                                  <Select
                                    options={valueofstatus}
                                    value={status}
                                    onChange={(event) =>
                                      select_status(result._id, event)
                                    }
                                    classNamePrefix="status-dropdown-select"
                                    styles={colorStyles}
                                    getOptionLabel={(e) => (
                                      <div className="">
                                        {e.label}
                                      </div>
                                    )}
                                    isSearchable={false}
                                  />
                                </div>
                              </td>
                            </tr>

                            <tr className={result?.subTable ? "com-custom-table-tag-subtable" : "com-custom-table-tag-subtable d-none"}>
                              <td colSpan="80">
                                <table width="100%">
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>{t("Image")}</th>
                                      <th>{t("SKU")}</th>
                                      <th>{t("Metal")}</th>
                                      <th>{t("Stone")}</th>
                                      <th>{t("Size")}</th>
                                      <th>{t("Stock ID")}</th>
                                      <th>{t("Qty")}</th>
                                      <th>{t("Price")}<button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                                        <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                                      </button></th>
                                      <th>{t("Amount")}<button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                                        <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                                      </button></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {result.subTabledata?.length ? result.subTabledata.map((element, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>
                                            <div className="pro-border-img">
                                              <img className="h-100 w-100 mw-100 object-fit-cover"
                                                src={element?.image ? element?.image : blankimg} alt="" />
                                            </div>
                                          </td>
                                          <td>{element.SKU ? element.SKU : "-"}</td>
                                          <td>{element.metal ? element.metal : "-"}</td>
                                          <td>{element.stone ? element.stone : "-"}</td>
                                          <td>{element.size ? element.size : "-"}</td>
                                          <td>{element.stock_id ? element.stock_id : "-"}</td>
                                          <td>{element.qty ? element.qty : "-"}</td>
                                          <td>{element.price ? element.price : "-"}</td>
                                          <td>{element.amount ? element.amount : "-"}</td>
                                        </tr>
                                      )
                                    }) : <tr>
                                      <td colSpan="50" className="text-center">{t("No Data Available In Table")}</td>
                                    </tr>
                                    }

                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      }) : !showLoader && <tr>
                        <td colSpan="50" className="text-center">{t("No Data Available In Table")}</td>
                      </tr>
                      }
                    </tbody>
                  </table>
                  {showLoader && <TableLoader />}
                </div>
              </div>
              {appointmentlist?.length > 0 ? (
                <Pagination
                  total={totalPages}
                  current={currentPage}
                  pagination={(crPage) => handlePaginations(crPage)}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppointmentHistory;
