import React, { useEffect, useState } from 'react'
// import 'react-awesome-slider/dist/styles.css';
// import "../style.scss"
import Slider from "react-slick";
import { Link, useLocation ,useNavigate} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { _Api } from '../../../common/api';
import './CollectionBestSeller.scss';
export default function CollectionBestSeller({title}) {
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    const { pathname } = useLocation()
    const { t } = useTranslation();
    const [sliderlist, setSliderlist] = useState()
    const navigate = useNavigate();
   
    const sliderdata = async () => {
        try {
            let postdata = {
                type: "collection"
            }
            let res = await _Api(postdata, `product/best-seller`)
            if (res?.statusCode === 200) {
               
                setSliderlist(res?.data)
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // function SampleNextArrow2(props) {
    //     const { onClick } = props;
    //     return (
    //         <div
    //             className={`sliderarrow top-50 ms-2 translate-middle position-absolute cursor-pointer`}
    //             style={{ right:0 }}
    //             onClick={onClick}
    //         >
                
    //             <svg xmlns="http://www.w3.org/2000/svg" color='#fff' width={50} height={50} fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="size-6">
    //                 <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
    //             </svg>
    //         </div>
    //     );
    // }

    // function SamplePrevArrow2(props) {
    //     const { onClick } = props;
    //     return (
    //         <div
    //             className={`sliderarrow lg:ms-5 ms-4 top-50 start-0 translate-middle position-absolute cursor-pointer`}
    //             style={{ zIndex: 10 }}
    //             onClick={onClick}
    //         >
    //                        <svg xmlns="http://www.w3.org/2000/svg" color='#fff' width={50} height={50} fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="size-6">
    //                 <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
    //             </svg>


    //         </div>
    //     );
    // }
    function SampleNextArrow(props) {   
        const { onClick } = props;
        return (
            <div
                className={`sliderarrow top-50 ms-2 translate-middle position-absolute cursor-pointer`}
                style={{ right:0 }}
                onClick={onClick}
            >
                
             <svg width={30} height={30} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="20" transform="matrix(-1 0 0 1 40 0)" fill="white"/>
            <path d="M18 15L23 20L18 25" stroke="#6A6A6A" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { onClick } = props;
        return (
            <div
                className={`sliderarrow lg:ms-5 ms-4 top-50 start-0 translate-middle position-absolute cursor-pointer`}
                style={{ zIndex: 10 }}
                onClick={onClick}
            >
                            <svg width={30} height={30} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill="white"/>
                <path d="M23 25L18 20L23 15" stroke="#6A6A6A" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>


            </div>
        );
    }
    useEffect(() => {
        sliderdata()
    }, [])


    // var settings2 = {
    //     arrows: true,
    //     nextArrow: <SampleNextArrow2 />,
    //     prevArrow: <SamplePrevArrow2 />,
    //     dots: false,
    //     speed: 500,
    //     slidesToShow: 5,
    //     cssEase: "ease-in-out",
    //     slidesToScroll: 1,
        
    //     infinite: true,
       
    //     responsive: [
           
    //         {
    //             breakpoint: 1200,
    //             settings2: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 2,
    //     cssEase: "ease-in-out",
    //     infinite: true,
    //                 dots: false
    //             }
    //         },
    //         {
    //             breakpoint: 525,
    //             settings2: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 2,
    //     cssEase: "ease-in-out",
    //     infinite: true,
    //                 dots: false
    //             }
    //         },

    //     ]
    // };


    const settings = {
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
      };
  return (
    <div className='product-slider-wrapper position-relative'>
    <div>
        {title ? (
            <div className='product-slider-title'>
                {title}
            </div>
        ) : null }
    </div>

    <div className='product-slider-grid'>
        {/* <Slider {...settings2}> */}
        {sliderlist?.length ? sliderlist.map((product, key) => 
            
         (   <div className="product-card">
                <div className="product-card-slider">
              <Slider {...settings}>
                {product.images.map((image, index) => (
                  <div key={index} className="slider-image">
                    <img src={image.original} alt={`product-image-${index}`} />
                  </div>
                ))}
              </Slider>
              <div className={`product-card-hearticon ${product.isWishlist ? "active" : ""}`}><svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.3913 5.59377C24.1566 5.59377 25.8153 6.28177 27.0633 7.52844C28.3009 8.76833 28.996 10.4486 28.996 12.2004C28.996 13.9523 28.3009 15.6326 27.0633 16.8724L15.9993 27.9378L4.93396 16.8724C3.69677 15.6326 3.00195 13.9526 3.00195 12.2011C3.00195 10.4496 3.69677 8.7696 4.93396 7.52977C5.546 6.91417 6.27407 6.42609 7.07603 6.09377C7.87798 5.76145 8.73788 5.59151 9.60596 5.59377C11.3713 5.59377 13.03 6.28177 14.278 7.52844L15.2913 8.54177L15.9993 9.24977L16.706 8.54177L17.7193 7.52844C18.3316 6.91326 19.0597 6.42554 19.8616 6.09346C20.6636 5.76139 21.5233 5.59155 22.3913 5.59377Z" stroke="#545454"/>
</svg>
</div>
<div className='QuickViewDiv'>
    <button  className='QuickViewButton' onClick={()=> navigate(`/Category/best-seller`)}>Quick View</button>
    </div>
            </div>
            <div className="product-card-title">
              <h2>{product.SKU}</h2>
              <p >{product.Description}</p>
              <span className="price">{product.price}</span>
            </div>
      
            {/* Image slider */}
            
      
            {/* <div className="product-card-buttons">
              <button className="prev-button">Left</button>
              <button className="next-button">Right</button>
            </div> */}
          </div>
        )) : null}
        {/* </Slider> */}
    </div>
</div>

  )
}






