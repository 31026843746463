import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import List from '../components/transaction/po/list';
import CreatePO from '../components/transaction/po/createpo';
import TransactionPurchase from '../components/transaction/purchase/transactionPurchase';
import PurchaseOrderOutstanding from '../components/transaction/purchase/purchaseOrderOutstanding';
import Stocktransfer from '../components/transaction/stock_transfer/stock_transfer';
import EditStockTransfer from '../components/transaction/stock_transfer/editstocktransfer';
import StockTransferinventorylist from '../components/transaction/stock_transfer/Inventorylist';
import TransactionStockReceive from '../components/transaction/stock_transfer/transactionStockReceive';
import AllocationPO from '../components/transaction/po/allocationpo';
import AllocationList from '../components/transaction/po/allocationlist';
import CreateReplenishOrder from '../components/transaction/po/createpoReplenishOrder';
import CreatePurchase from '../components/transaction/purchase/createPurchase';
import EditPurchase from '../components/transaction/purchase/editpurchase';
import Outstandingpurchaseorder from '../components/transaction/purchase/outstandingPurchaseOrder';
import Editpurchaseoutstanding from '../components/transaction/purchase/editpurchaseoutstanding';
import EditPurchaseOrder from '../components/transaction/po/editpoPOS';
import EditPurchaseOrderReplenish from '../components/transaction/po/EditpoReplenish.js';
import CreateTransferInventory from '../components/transaction/stock_transfer/createTransferInventory';
import CreateTransfer from '../components/transaction/stock_transfer/createTransfer1';
import MyInventory from '../components/inventory/MyInventory.js';
import ReserveList from '../components/inventory/ReserveList.js';
import ItemSearch from '../components/inventory/ItemSearch.js';
import DiamondDetail from '../components/diamond/DetailDiamond';
import CreateReserve from '../components/diamond/CreateReserve';
import TransactionHistory from '../components/inventory/TransactionHistory';
import POSOrder from '../components/inventory/PosOrder';
import POSOrderlist from '../components/transaction/po/posorderlist';
import POSOrdermain from '../components/transaction/po/posordermain';
import DiamondSearch from '../components/diamond/DiamondSearch';
import ReportReserve from '../components/report/Reserve';
import ReportPO from '../components/report/po';
import ReportPurchase from '../components/report/purchase';
import ReportStockTransfer from '../components/report/stockTransfer';
import ReportStockReceive from '../components/report/stockReceive';
import ReportStockTake from '../components/report/stockTake';
import ReportLowStockPlanning from '../components/report/lowStockPlanning';
import ReportVendor from '../components/report/vendor';
import VendorPurchaseOrder from '../components/report/vendorPurchaseOrder';
import VendorPurchase from '../components/report/vendorPurchase';
import InventorySummary from '../components/inventory/summary';
import LowStockPlanning from '../components/inventory/LowStockPlanning';
import Createreserveinventory from '../components/inventory/CreateReserve';
import QuickView from '../components/inventory/QuickView';
import StockTake from '../components/StockTake/StockTake';
import SkuMovement from '../components/inventory/SkuMovement.js';
import SkuMovementdetails from '../components/inventory/SkuMovementdetails';
import StockidMovementdetails from '../components/inventory/Stockidmovementdetails';
import StockIDMovement from '../components/inventory/stockidmovement';
import CustomSwitch from '../CustomSwitch.js';
import { transactionTotals } from '../components/common/helpers/function';
import InventoryHeader from './InventoryHeader';
import PageNotFound from '../components/common/404.js';
import { useSelector } from 'react-redux';
import ZoneIndex from 'components/zonemodule/ZoneIndex';
import ZoneCreateAndEdit from 'components/zonemodule/ZoneCreateAndEdit';
import AddInventory from 'components/zonemodule/AddInventory';
export default function InventoryRoutes(props) {
    const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
    const setsettings = useSelector((state) => state.app.generalsettings)
    let generalsettings = setsettings ? setsettings : { data: { generalsetup: { productChoice: "1" } } }
    const { Fulhandle } = props;
    useEffect(() => {
        localStorage.removeItem("customerdetails")
        localStorage.removeItem("salesdetails")
    }, [])
    return (
        <CustomSwitch>
            <Route element={<InventoryHeader Fulhandle={Fulhandle} />}>
                <Route path='/' element={
                    parmissiondatashow?.purchase_order?.View ?
                        <List transactionTotals={transactionTotals} /> :
                        parmissiondatashow?.inventory_transaction_purchase?.View ?
                            <TransactionPurchase transactionTotals={transactionTotals} /> :
                            parmissiondatashow?.inventory_transaction_stock_transfer?.View ?
                                <Stocktransfer transactionTotals={transactionTotals} /> :
                                parmissiondatashow?.inventory_transaction_stock_Receive?.View ?
                                    <Stocktransfer transactionTotals={transactionTotals} /> :
                                    <MyInventory />} />
                {parmissiondatashow?.purchase_order?.View &&
                    <>
                        <Route path='createpo' element={<CreatePO />} ></Route>
                        <Route path='allocationpo' element={<AllocationPO />} > </Route>
                        <Route path='allocatedlist' element={<AllocationList />} > </Route>
                        <Route path='createpoReplenishOrder' element={<CreateReplenishOrder />} > </Route>
                        <Route path='edit-po-pos' element={<EditPurchaseOrder />} > </Route>
                        <Route path='edit-po-Replenish' element={<EditPurchaseOrderReplenish />} > </Route>
                        <Route path='transaction_posorder' element={< POSOrderlist />} > </Route>
                        <Route path='posorderlist' element={< POSOrdermain />} > </Route>
                    </>}
                {parmissiondatashow?.inventory_transaction_purchase?.View &&
                    <>
                        <Route path='transactionPurchase' element={<TransactionPurchase />} > </Route>
                        <Route path='createPurchase' element={< CreatePurchase />} > </Route>
                        <Route path='editPurchase' element={< EditPurchase />} > </Route>
                        <Route path='purchaseOrderOutstanding' element={<PurchaseOrderOutstanding />} > </Route>
                        <Route path='purchaseOrdersOutstanding' element={<Outstandingpurchaseorder />} > </Route>
                        <Route path='editpurchaseOrdersOutstanding' element={<Editpurchaseoutstanding />} > </Route>
                    </>
                }
                {parmissiondatashow?.inventory_transaction_stock_transfer?.View &&
                    <>
                        <Route path='stock_transfer' element={<Stocktransfer />} > </Route>
                        <Route path='createTransferInventory' element={< CreateTransferInventory />} > </Route>
                        <Route path='edit-stocktransfer' element={<EditStockTransfer />} > </Route>
                        <Route path='inventorylist' element={<StockTransferinventorylist />} > </Route>
                        <Route path='createTransfer' element={<CreateTransfer />} > </Route>
                    </>
                }

                {/* zone routes */}
                <Route path='zone' element={<ZoneIndex />} />
                <Route path='zone/createissue' element={<ZoneCreateAndEdit />} />
                <Route path='zone/edit/:viewEditIssueId' element={<ZoneCreateAndEdit />} />
                {/* <Route path='zone/createissue/addinventory' element={<AddInventory/>}/>
                <Route path='zone/edit/addinventory' element={<AddInventory/>}/> */}
                {/* zone routes end */}

                {parmissiondatashow?.inventory_transaction_stock_Receive?.View &&
                    <Route path='transactionStockReceive' element={<TransactionStockReceive />} > </Route>
                }
                {parmissiondatashow?.inventory_diamond_search?.View && generalsettings?.data?.generalsetup?.productChoice === '1' &&
                    <Route path='diamondSearch' element={<DiamondSearch />} > </Route>}
                <Route path='detailDiamond' element={< DiamondDetail />} > </Route>
                {parmissiondatashow?.my_inventory?.View &&
                    <>
                        <Route path='myInventory' element={< MyInventory />} > </Route>
                        <Route path='posorder' element={<POSOrder />} > </Route>
                    </>}


                <Route path='quickview' element={< QuickView />} > </Route>
                {parmissiondatashow?.reserve_list?.View &&
                    <Route path='reserveList' element={< ReserveList />} > </Route>}
                {parmissiondatashow?.item_search?.View &&
                    <Route path='itemSearch' element={< ItemSearch />} > </Route>}
                <Route path='createReserve' element={< CreateReserve />} > </Route>
                <Route path='transactionHistory' element={< TransactionHistory />} > </Route>
                <Route path='createreserves' element={< Createreserveinventory />} > </Route>
                {parmissiondatashow?.stock_analysis?.View &&
                    <>
                        <Route path='stockidmovement' element={< StockIDMovement />} > </Route>
                        <Route path='StockidMovementdetails' element={< StockidMovementdetails />} > </Route>
                    </>
                }
                {parmissiondatashow?.sku_analysis?.View &&
                    <>
                        <Route path='skumovement' element={< SkuMovement />} > </Route>
                        <Route path='SkuMovementdetails' element={< SkuMovementdetails />} > </Route>
                    </>
                }
                {parmissiondatashow?.Summary?.View &&
                    <Route path='summary' element={< InventorySummary />} > </Route>}
                {parmissiondatashow?.Low_Stock_Planning?.View &&
                    <Route path='lowStockPlanning' element={< LowStockPlanning />} > </Route>}
                {parmissiondatashow?.stock_take?.View &&
                    <Route path='stocktake' element={< StockTake />} > </Route>}
                {parmissiondatashow?.inventory_reports?.View &&
                    <>
                        <Route path='report/reserve' element={<ReportReserve />} > </Route>
                        <Route path='report/po' element={<ReportPO />} > </Route>
                        <Route path='report/purchase' element={<ReportPurchase />} > </Route>
                        <Route path='report/stocktransfer' element={<ReportStockTransfer />} > </Route>
                        <Route path='report/stockreceive' element={<ReportStockReceive />} > </Route>
                        <Route path='report/stocktake' element={<ReportStockTake />} > </Route>
                        <Route path='report/lowstockplanning' element={<ReportLowStockPlanning />} > </Route>
                        <Route path='report/vendor' element={<ReportVendor />} > </Route>
                        <Route path='report/vendorPurchase' element={<VendorPurchase />} > </Route>
                        <Route path='report/vendorPurchaseOrder' element={<VendorPurchaseOrder />} > </Route>
                    </>
                }

                <Route path='*' element={<PageNotFound />} ></Route>


            </Route>
        </CustomSwitch>
    );
}

