import React, { useState, useEffect } from 'react'
import Select from "react-select"
import Apiauth from '../../../API/Apiauth.js';
import { useTranslation } from 'react-i18next';
import RightArrow from '../../common/icons/right-arrow.js';
import { Pagination } from '../../common/Pagination.js';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ConfirmationPopup from '../Common/ConfirmationPopup.js';
import Spinner from '../../../Ecommerce/template_one/common/Spinner.js';

const BlockList = () => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showloader, setShowloader] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [limit, setLimit] = useState(100);
  const [dataArray, setDataArray] = useState();
  const [pageDeleteId, setPageDeleteId] = useState();
  const [states, setstates] = useState({
    totalPages: 20,
    currentPage: 1,
  });
  const { totalPages, currentPage } = states;

  const [searchBar, setSearchBar] = useState()

  useEffect(() => {
    getPageList(1, 100, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const getPageList = async (current, firstlimit, search) => {
    setShowloader(true)
    try {
      var skipNumber = current ? (current - 1) * limit : 0;
      let postdata = {
        "location_id": state?.location,
        "Limit": firstlimit ? firstlimit : limit,
        "skip": skipNumber,
        "search": search ? search : ""
      }
      let result = await Apiauth(postdata, `cms/block-list`)
      if (result.code === 200) {
        setDataArray(result?.data)
        setShowloader(false)
        setstates({
          ...states,
          totalPages: Math.ceil(result?.total / postdata.Limit),
          currentPage: current ? current : 1
        });
      }
    } catch (err) {
      console.log(err, "error")
    }
  }

  const selectOptions = [
    { label: "100", value: "100" },
    { label: "200", value: "200" },
    { label: "300", value: "300" }
  ]

  const getlimit = (limit) => {
    setLimit(limit);
    getPageList(1, limit);
    setstates({ ...states, currentPage: 1 });
  };

  const handlePaginations = (current) => {
    setstates({ ...states, currentPage: current });
    getPageList(current, "");
  };

  const handleConfirm = () => {
    setIsPopupVisible(false);
    handleDelete();
  };

  const handleCancel = () => {
    setIsPopupVisible(false);
    setPageDeleteId(null);
  };



  const handleDelete = async () => {
    console.log(pageDeleteId, "item to be deleted")
    try {
      let postdata = {
        "id": pageDeleteId
      }
      let result = await Apiauth(postdata, `cms/delete-page`)
      if (result.code === 200) {
        getPageList();
      }
    } catch (err) {
      console.log(err, "Error")
    }
  }

  return (
    <div className="main-content-wrapper-body">
      <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
        <div className="main-content-wrapper-body-top-bar-left">
          <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0 cursor-pointer" onClick={() => navigate("/app/admin/settings/cmslocations")}>{t("CMS")} <RightArrow /> <span className="cursor-pointer">{t("Blocks")}</span></h1>
        </div>
        <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">
          <Select
            className="limit-select mx-3"
            options={selectOptions}
            value={{ label: limit, value: limit }}
            onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
          />
          <div className="com-search-bar position-relative">
            <img className="com-search-bar-search-img"
              src={base_assets + "images/admin/icons/gray-search.png"} alt="" />
            <input placeholder="Search Page"
              className="h-100 border-0 bg-transparent"
              value={searchBar}
              onKeyPress={(e) => e.key === 'Enter' && getPageList(1, 100, e.target.value)}
              onChange={(e) => setSearchBar(e.target.value)}
            />

            {searchBar?.length ?
              <img onClick={() => { setSearchBar(""); getPageList(1, 100, "") }} className="search-bar-clear position-absolute end-0 mt-2"

                src={base_assets + 'images/icons/False.png'} alt="" /> : ""}


          </div>

          <Link to={"add"} state={{ location: state?.location }} className={"com-plus-text-btn text-white text-center fw-semibold d-flex align-items-center border-0 rounded-1 ms-3"}
          >
            <img className="me-2" src={base_assets + "images/admin/icons/add-icon-white.png"} alt="" />{t("Create")}
          </Link>
        </div>
      </div>
      <div className="row">
        <div className={"col-md-12"}>
          <div
            className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
          >
            <table
              id="my_customer"
              className="com-custom-table-tag table align-middle">
              <thead>
                <tr className="text-nowrap align-middle">
                  <th>#</th>
                  <th className="user-name-width">{t("Title")}
                  </th>
                  <th>{t("Last Modified Date")}</th>
                  <th>{t("Status")}</th>
                </tr>
              </thead>
              <tbody>

                {dataArray?.length ?
                  dataArray.map((result, key) => {
                    return (
                      <tr className="text-nowrap" key={key}>
                        <td>{key + 1}</td>
                        <td className='link-color cursor-pointer text-decoration-none'><Link className='link-color text-decoration-none' to={`add/${result?._id}`} state={{ pageDetails: result, location: state?.location, pageId: result?._id }}>  {result?.title ? result?.title : ""}</Link></td>

                        <td>{result?.lastModified ? result?.lastModified : ""}</td>
                        <td>
                          {result?.status === 1 ?
                            <span className="active-badge  rounded-pill px-2 py-1 fw-semibold"> <span className="active-badge-dot me-2"></span>{t("Active")}</span>
                            :
                            <span className="inactive-badge  rounded-pill px-2 py-1 fw-semibold"> <span className="inactive-badge-dot me-2"></span>{t("InActive")}</span>
                          }

                        </td>

                      </tr>
                    )
                  }) :
                  <tr>

                    <td colSpan="50" className="text-center">  {!dataArray?.length && showloader ? <Spinner /> : t("No Data Available In Table")}</td>

                  </tr>
                }
              </tbody>
            </table>

          </div>

          {dataArray?.length > 0 ? (
            <Pagination
              total={totalPages}
              current={currentPage}
              pagination={(crPage) => handlePaginations(crPage)}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <ConfirmationPopup
        isVisible={isPopupVisible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        message="Delete This Page ?"
      />
    </div>
  )
}

export default BlockList
