import React, { useEffect, useState } from 'react'
import EditIcon from '../../../Ecommerce/template_one/default/assets/icons/EditIcon'
import FIlterIcon from '../../../Ecommerce/template_one/default/assets/icons/ArrowDownSide'
import Select, { components } from "react-select";
import { useTranslation } from 'react-i18next';

export default function AddressSection(props) {
  const { t } = useTranslation();
  const {
    type,
    noDropdown,
    completeDataObject,
    setCompleteObject,
    addaddressobj,
    setAddaddressobj,
    Countryarray,
    cusid,
    add,
    setShowAddContent,
    setAddressindex,
  } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  let defaultaddress = completeDataObject?.length ? completeDataObject.find(address => address.is_default === 1) : ""
  const [getaddress, setAddress] = useState()
  const [showaddress, serShowaddress] = useState(completeDataObject)
  const { ValueContainer, Placeholder } = components;
  const [flagvalue, setFlagvalue] = useState()
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  useEffect(() => {
    if (defaultaddress) {
      setAddress(defaultaddress)
      completeDataObject.forEach((res, key) => {
        if (res?.is_default === 1) {
          setFlagvalue(key)
        }
      })
      serShowaddress(completeDataObject)
    }
    else if (!cusid) {
      if (add) {
        setAddress({})
      } else {
        setAddress(completeDataObject[0])
      }
    }
    // eslint-disable-next-line
  }, [defaultaddress, noDropdown, cusid])
  const hendleedit = (values) => {
    type === "Billing" ?
      setAddaddressobj((state) => ({
        ...state,
        billing_address: values

      })) : setAddaddressobj((state) => ({
        ...state,
        shipping_address: values

      }))
  }
  const hendlechange = (keyname, value) => {
    let ary = [...completeDataObject]
    if (!noDropdown) {
      if (ary?.length) {
        ary[flagvalue ? flagvalue : 0][keyname] = value
        setAddress((state) => ({
          ...state,
          [keyname]: value
        }))
        if (type === "Billing") {
          setCompleteObject((state) => ({
            ...state,
            billing_address: ary
          }))
        } else if (type === "Shipping") {
          setCompleteObject((state) => ({
            ...state,
            shipping_address: ary
          }))
        }
      }
    }
  }

  const handleSearch = (searchQuery) => {
    let searchResults = [...completeDataObject];
    searchResults = searchResults.filter(obj =>
      Object.values(obj).some(value =>
        typeof value === 'string' && value.match(new RegExp(searchQuery, 'i'))
      )
    );
    serShowaddress(searchResults)
  };

  return (
    <div className='addsecforpopup'>
      <div className='addsecforpopup-inner'>
        <div className='addsecforpopup-inner-content-billing'>
          <div className='addsecforpopup-inner-content-billing-addressbox'>
            {!noDropdown &&
              <div className='addsecforpopup-inner-content-billing-addressbox-default' onClick={() => setShowDropdown(!showDropdown)}>
                <div>
                  {getaddress?.fullAddress}
                </div>
                {showDropdown ? <div className='addsecforpopup-inner-content-billing-addressbox-default-upicon'><FIlterIcon /></div> : <FIlterIcon />}
              </div>
            }

            {showDropdown &&
              <div className='addsecforpopup-inner-content-billing-addressbox-dropdownbox'>
                <div className='addsecforpopup-inner-content-billing-addressbox-dropdownbox-inside'>
                  <div className='addsecforpopup-inner-content-billing-addressbox-dropdownbox-inside-searchbar' >
                    <img src={base_assets + 'images/pos/icons/magnifying-glass.png'} alt='' />
                    <input type='text' onChange={(e) => handleSearch(e.target.value)} />
                  </div>

                  {showaddress?.length ? showaddress?.map((result, key) => {
                    return (
                      <div className='addsecforpopup-inner-content-billing-addressbox-dropdownbox-inside-searchlist' key={key}>
                        <div className='addsecforpopup-inner-content-billing-addressbox-dropdownbox-inside-searchlist-item'>
                          <div onClick={() => { setAddress(result); setFlagvalue(key); setShowDropdown(!showDropdown) }}>{result?.fullAddress}</div>
                          <div onClick={() => { hendleedit(result); setShowDropdown(!showDropdown); setShowAddContent(type); setAddressindex(key); }}>
                            <EditIcon />
                          </div>
                        </div>
                      </div>
                    )
                  }) : ""}
                </div>
              </div>
            }
            <div className="customerinformation-inner-left-formitems-wrapper">
              <div className="customerinputs form-floating addsecforpopup-inner-content-billing-addressbox-custominput col">
                <input
                  readOnly={!noDropdown}
                  onChange={(e) => {
                    !noDropdown ? hendlechange("company", e.target.value) :
                      type === "Billing" ?
                        setAddaddressobj((state) => ({
                          ...state,
                          billing_address: {
                            ...state?.billing_address,
                            company: e.target.value
                          }

                        })) : setAddaddressobj((state) => ({
                          ...state,
                          shipping_address: {
                            ...state?.shipping_address,
                            company: e.target.value
                          }

                        }))
                  }}
                  value={
                    (!noDropdown && getaddress?.company) ? getaddress.company :
                      (addaddressobj?.company ? addaddressobj.company : "")
                  }
                  type="text" className="input-set customerinputs-field form-control" id="company" placeholder="company" />
                <label className="customerinputs-label" htmlFor="">
                  {t("Company")}
                </label>
              </div>
            </div>
            <div className="customerinformation-inner-left-formitems-wrapper mt-3">
              <div className="text-box customerinputs form-floating textarea w-100">
                <textarea
                  readOnly={!noDropdown}
                  onChange={(e) => {
                    !noDropdown ? hendlechange("address", e.target.value) :
                      type === "Billing" ?
                        setAddaddressobj((state) => ({
                          ...state,
                          billing_address: {
                            ...state?.billing_address,
                            address: e.target.value
                          }

                        })) : setAddaddressobj((state) => ({
                          ...state,
                          shipping_address: {
                            ...state?.shipping_address,
                            address: e.target.value
                          }

                        }))
                  }}
                  value={
                    (!noDropdown && getaddress?.address) ? getaddress.address :
                      (addaddressobj?.address ? addaddressobj.address : "")
                  }
                  className="text-box-inner col form-control customerinputs-field customerinformation-inner-left-formitems-wrapper-addrestextarea" style={{ height: '4vw' }} rows="4" cols="50" placeholder="Address"></textarea>
                <label className="customerinputs-label">{t("Address")}</label>
              </div>
            </div>
            <div className="customerinformation-inner-left-formitems-wrapper">
              <Select
                isDisabled={!noDropdown}
                onChange={(e) => {
                  !noDropdown ? hendlechange("country", e) :
                    type === "Billing" ?
                      setAddaddressobj((state) => ({
                        ...state,
                        billing_address: {
                          ...state?.billing_address,
                          country: e
                        }

                      })) : setAddaddressobj((state) => ({
                        ...state,
                        shipping_address: {
                          ...state?.shipping_address,
                          country: e
                        }
                      }))
                }}
                value={!noDropdown ? getaddress?.country : addaddressobj?.country}
                className="select-set select col"
                placeholder={t("Country")}
                components={{
                  ValueContainer: CustomValueContainer,
                }}
                options={Countryarray}
                classNamePrefix="common-select"
                getOptionLabel={(opt) => opt.label}
                isSearchable={true}
              />
              <div className="customerinputs form-floating addsecforpopup-inputs-wrapper-inputfield col">
                <input
                  readOnly={!noDropdown}
                  onChange={(e) => {
                    !noDropdown ? hendlechange("state", e.target.value) :
                      type === "Billing" ?
                        setAddaddressobj((state) => ({
                          ...state,
                          billing_address: {
                            ...state?.billing_address,
                            state: e.target.value
                          }

                        })) : setAddaddressobj((state) => ({
                          ...state,
                          shipping_address: {
                            ...state?.shipping_address,
                            state: e.target.value
                          }

                        }))
                  }}
                  value={
                    (!noDropdown && getaddress?.state) ? getaddress.state :
                      (addaddressobj?.state ? addaddressobj.state : "")
                  }
                  type="text" className="input-set customerinputs-field form-control addsecforpopup-inner-content-billing-addressbox-custominput" id="source" placeholder="State" />
                <label className="customerinputs-label" htmlFor="">
                  {t("State")}
                </label>
              </div>
            </div>
            <div className="customerinformation-inner-left-formitems-wrapper">
              <div className="customerinputs form-floating addsecforpopup-inner-content-billing-addressbox-custominput col">
                <input
                  readOnly={!noDropdown}
                  onChange={(e) => {
                    !noDropdown ? hendlechange("city", e.target.value) :
                      type === "Billing" ?
                        setAddaddressobj((state) => ({
                          ...state,
                          billing_address: {
                            ...state?.billing_address,
                            city: e.target.value
                          }

                        })) : setAddaddressobj((state) => ({
                          ...state,
                          shipping_address: {
                            ...state?.shipping_address,
                            city: e.target.value
                          }

                        }))
                  }}
                  value={
                    (!noDropdown && getaddress?.city) ? getaddress.city :
                      (addaddressobj?.city ? addaddressobj.city : "")
                  }
                  type="text" className="input-set customerinputs-field form-control" id="city" placeholder="City" />
                <label className="customerinputs-label" htmlFor="">
                  {t("City")}
                </label>
              </div>
              <div className="customerinputs form-floating addsecforpopup-inner-content-billing-addressbox-custominput col mt-2">
                <input
                  readOnly={!noDropdown}
                  onChange={(e) => {
                    !noDropdown ? hendlechange("zipcode", e.target.value) :
                      type === "Billing" ?
                        setAddaddressobj((state) => ({
                          ...state,
                          billing_address: {
                            ...state?.billing_address,
                            zipcode: e.target.value
                          }

                        })) : setAddaddressobj((state) => ({
                          ...state,
                          shipping_address: {
                            ...state?.shipping_address,
                            zipcode: e.target.value
                          }

                        }))
                  }}
                  value={
                    (!noDropdown && getaddress?.zipcode) ? getaddress.zipcode :
                      (addaddressobj?.zipcode ? addaddressobj.zipcode : "")
                  }
                  type="text" className="input-set customerinputs-field form-control" id="zipcode" placeholder="Zipcode" />
                <label className="customerinputs-label" htmlFor="">
                  {t("Zip Code")}
                </label>
              </div>
            </div>
            <div className="customerinformation-inner-left-formitems-wrapper col">
              <div className="customerinputs form-floating w-100">
                <input
                  readOnly={!noDropdown}
                  onChange={(e) => {
                    !noDropdown ? hendlechange("tax_number", e.target.value) :
                      type === "Billing" ?
                        setAddaddressobj((state) => ({
                          ...state,
                          billing_address: {
                            ...state?.billing_address,
                            tax_number: e.target.value
                          }

                        })) : setAddaddressobj((state) => ({
                          ...state,
                          shipping_address: {
                            ...state?.shipping_address,
                            tax_number: e.target.value
                          }

                        }))
                  }}
                  value={
                    (!noDropdown && getaddress?.tax_number) ? getaddress.tax_number :
                      (addaddressobj?.tax_number ? addaddressobj.tax_number : "")
                  }
                  type="text"
                  className="input-set customerinputs-field form-control addsecforpopup-inner-content-billing-addressbox-custominput" placeholder="Tax ID No.(optional)" />
                <label className="customerinputs-label" htmlFor="">
                  {t("Tax ID No.(optional)")}
                </label>
              </div>
            </div>
            <div className='customerinformation-inner-left-formitems-wrapper justify-content-between addsecforpopup-inputs-radiobtn'>
              <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{t("Default")} {t(type)} {t("Address")}</label>
              <div className="form-check form-switch">
                <input
                  disabled={!noDropdown}
                  onChange={(e) => {
                    !noDropdown ? hendlechange("is_default", e.target.checked ? 1 : 0) :
                      type === "Billing" ?
                        setAddaddressobj((state) => ({
                          ...state,
                          billing_address: {
                            ...state?.billing_address,
                            is_default: e.target.checked ? 1 : 0
                          }

                        })) : setAddaddressobj((state) => ({
                          ...state,
                          shipping_address: {
                            ...state?.shipping_address,
                            is_default: e.target.checked ? 1 : 0
                          }

                        }))
                  }}
                  checked={!noDropdown ? getaddress?.is_default === 1 ? true : false : addaddressobj?.is_default === 1 ? true : false}
                  value={!noDropdown ? getaddress?.is_default === 1 ? 1 : 0 : addaddressobj?.is_default === 1 ? 1 : 0}
                  className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
