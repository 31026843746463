import React, { useEffect, useState } from "react";
import { _Api } from "../../common/api";
import { Link, useNavigate } from "react-router-dom";
import CrossIcon from "../assets/icons/CrossIcon";
import Spinner from "../../common/Spinner";
import { useTranslation } from "react-i18next";

const CartNotification = (props) => {
    const { setIsopennotifycart, setisopencart, isopennotifycart } = props;
    const { t } = useTranslation();
    const [notifycartitems, setNotifycartitems] = useState([]);
    const [, setCheckoutItems] = useState([]);
    const [loading, setLoading] = useState(false)
    let localitem = localStorage.getItem('CartItems') ? JSON.parse(localStorage.getItem('CartItems')) : [];
    const navigate = useNavigate()


    const getcartItems = async () => {
        console.log("from cart notification")
        setLoading(true)
        let cartids = [];
        let details = [];
        let product = [];
        let postdata = {}
        if (localitem?.length) { product.push(localitem[localitem?.length - 1]) }

        console.log(product, details, cartids, "from cart notification")

        details.push(
            {
                "id": product[0]?.id,
                "qty": product[0]?.qty,
                "diamond_id": product[0]?.diamonddetails?.id,
                "temp_cart_id": product[0]?.temp_cart_id,

            }
        )

        try {
            postdata = { "diamond_ids": details, cart_ids: cartids }
            console.log(postdata, cartids, notifycartitems, "i was called from hre")
            let res = await _Api(postdata, `order/getCartDetails`)
            if (res?.statusCode === 200) {
                if (res?.data) {
                    console.log(res?.data, "check this")
                    let array = [...res?.data];
                    array?.forEach((result) => {
                        result.qty = parseInt(localitem.filter(e => e?.id === result?.id)[0]['qty']);
                    })
                    setNotifycartitems(array);
                }
                setLoading(false)
                if (res?.deleted_ids?.length) {
                    localitem.forEach((result) => {
                        if (!res?.deleted_ids.includes(result?.id)) {
                            let data = []
                            data.push(result)
                            localStorage.setItem('CartItems', JSON.stringify(data));
                        }
                    })
                }
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const gotocheckout = () => {
        let checkoutitemarray = [];
        let array = [...notifycartitems];
        checkoutitemarray.push({ 'id': array[0]?.id, temp_cart_id: array[0]?.temp_cart_id, diamond_id: array[0]?.diamond?.id, 'qty': array[0]?.qty, 'price': array[0]?.UnFormatPrice })
        setCheckoutItems(checkoutitemarray);
        if (checkoutitemarray?.length) {
            navigate("/shipping", { state: { checkoutItems: checkoutitemarray } })
        }
    }
    useEffect(() => {
        if (isopennotifycart) {
            getcartItems();
        }
        // eslint-disable-next-line
    }, [isopennotifycart])
    return (
        <>
            <div onClick={() => setIsopennotifycart(false)} className="custom-modal" id="cartN">
                <div className="custom-modal-modalBox">
                    <div className="custom-modal-modalBox-content">
                        <div className="custom-modal-modalBox-content-headBox">
                            <div className="custom-modal-modalBox-content-headBox-addToCartBox"><span></span>{t("Added to cart")}</div>
                            <span className="close" onClick={() => setIsopennotifycart(false)}><CrossIcon width={'16.71px'} height={'16.71px'} /></span>
                        </div>
                        {loading ? (<div style={{ marginTop: '5px', marginBottom: '5px' }}>

                            <Spinner />

                        </div>) : (
                            <>

                                {notifycartitems?.length ?
                                    notifycartitems.map((result, key) => {
                                        console.log(result, "cart notification")
                                        return (
                                            <>
                                                <div key={key} className=" com-ecomCartProductContShow m-0 border-0 custom-modal-modalBox-content-bodyBox">
                                                    <div className="com-ecomCartProductContShow-imageBox custom-modal-modalBox-content-bodyBox-imgBox">
                                                        <span className="product-img-span">
                                                            <img src={result?.main_image} alt="" />
                                                        </span>
                                                    </div>
                                                    <div className=" com-ecomCartProductContShow-detailsBox">
                                                        <div className="com-ecomCartProductContShow-detailsBox-firstBox">
                                                            <p className="com-ecomCartProductContShow-detailsBox-firstBox-productName">
                                                                {result?.name}
                                                            </p>
                                                            <div className="com-ecomCartProductContShow-detailsBox-firstBox-content">
                                                                <p className="contentPara">{result?.SKU}</p>
                                                                <p className="com-ecomCartProductContShow-detailsBox-firstBox-price">{result?.price}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {result?.diamond ?

                                                    <div key={key} className=" com-ecomCartProductContShow m-0 border-0 custom-modal-modalBox-content-bodyBox">
                                                        <div className="com-ecomCartProductContShow-imageBox custom-modal-modalBox-content-bodyBox-imgBox">
                                                            <span className="product-img-span">
                                                                <img src={result?.diamond?.main_image} alt="" />
                                                            </span>
                                                        </div>
                                                        <div className=" com-ecomCartProductContShow-detailsBox">
                                                            <div className="com-ecomCartProductContShow-detailsBox-firstBox">
                                                                <p className="com-ecomCartProductContShow-detailsBox-firstBox-productName">
                                                                    {result?.diamond?.name}
                                                                </p>
                                                                <div className="com-ecomCartProductContShow-detailsBox-firstBox-content d-flex gap-1 flex-column">
                                                                    <p className="contentPara">{result?.diamond?.SKU}</p>
                                                                    <p className="com-ecomCartProductContShow-detailsBox-firstBox-price">{result?.diamond?.price}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    : ""}
                                            </>
                                        )
                                    })
                                    : ""}
                            </>
                        )}


                        <div style={{ marginTop: '0' }} className="custom-modal-modalBox-content-footerBox">
                            <button className='com-ecomCancelBtn bg-white w-100' style={{ border: '1px solid #1D1D1F' }} data-bs-toggle="modal" data-bs-target="#AddToCartModal" onClick={() => { setisopencart(true); setIsopennotifycart(false) }}> {t("View Cart")} ({localitem?.length})</button>
                            <button onClick={gotocheckout} className=' com-ecomSendBtn  w-100 ' disabled={notifycartitems?.length ? false : true}>{t("Checkout")}</button>
                            <div className="custom-modal-modalBox-content-footerBox-continueShoppLink">
                                <Link to="/"><button className=' custom-modal-modalBox-content-footerBox-continueShoppLink-btnShow'>{t("Continue Shopping")}</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CartNotification;
