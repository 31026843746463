import React, { useState, useEffect, useRef } from "react";
import RightArrow from "../common/icons/right-arrow";
import { useTranslation } from 'react-i18next';
import EditVendor from './common/EditVendor';
import { success, error, loading, Updateloading } from "../common/helpers/toastify"
import Confirmationmodel from "../common/modules/confirmationmodel"
import { _Api, Api_form } from "../api/_call";
import { Pagination } from "../common/Pagination";
import { Importmodal } from "../common/modules/Importmodal"
import { useSelector } from 'react-redux';
import TableLoader from "../common/TableLoader";
import Select from "react-select";
const MasterVendor = () => {
  const dropdownReforganization = useRef(null);
  const dropdownRefvendortype = useRef(null);
  const dropdownRefstatus = useRef(null);
  const dropdownRefcountry = useRef(null)
  const vendorListRef = useRef();
  const dropdownRefcontact_person = useRef(null);
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation();
  const [editItem, setEditItem] = useState(false);
  const [getvendotlist, setVendorlist] = useState([])
  const [filtername, setFiltername] = useState('');
  const [dataid, setDataid] = useState("")
  const [create, setCreate] = useState(false)
  const [showloader, setShowloader] = useState(false)
  const [editSales, setEditSales] = useState(false)
  const [searchVal, setSearchVal] = useState("");
  const [organizationfilter, setOrganizationfilter] = useState([])
  const [organizationchecked, setOrganizationhecked] = useState([])
  const [vendortypefilter, setVendorfilter] = useState([])
  const [vendortypechecked, setVendorchecked] = useState([]);
  const [statuschecked, setStatuschecked] = useState("")
  const [statusfilter, setStatusfilter] = useState([])
  const [countryfilter, setcountryfilter] = useState([])
  const [countrychecked, setcountrychecked] = useState([])
  const [contact_personfilter, setCodefilter] = useState([])
  const [contact_personchecked, setContact_personchecked] = useState([]);
  const [shortobj, setShortobj] = useState({})
  const [exportid, setExportid] = useState([])
  const [importfile, setImportfile] = useState()
  const [limit, setLimit] = useState(100);
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const filterbyname = (search) => {
    getvendor(currentPage, limit, search);
    setstate({ ...state, currentPage: 1 });
  };
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });
  const { totalPages, currentPage } = state;
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current });
    getvendor(current, "");
  };
  const getvendor = async (current, firstlimit, search) => {
    setShowloader(true);
    if (editSales) {
      setContact_personchecked([]);
      setVendorchecked([])
      setOrganizationhecked([])
      setStatuschecked("")
      setcountrychecked([])
    }
    try {
      var skipNumber = current ? (current - 1) * limit : 0;
      let postdata = {
        "search": search ? search : "",
        "limit": firstlimit ? firstlimit : limit,
        "skip": skipNumber,
        "filter": {
          "vendortype": vendortypechecked ? vendortypechecked : [],
          "organization": organizationchecked ? organizationchecked : [],
          "status": statuschecked ? statuschecked : "",
          "country": countrychecked ? countrychecked : [],
          "contact_person": contact_personchecked ? contact_personchecked : [],
        },
        "sort": { [shortobj?.key]: shortobj?.value }
      }
      let res = await _Api(postdata, `api/v1/App/master/vendorsMasterView`)
      if (res?.code === 200) {
        setVendorlist(res?.data)
        setShowloader(false);
        setstate({
          ...state,
          totalPages: Math.ceil(res?.total / postdata.limit),
          currentPage: current ? current : 1
        });
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const getlimit = (limit) => {
    setLimit(limit);
    getvendor(1, limit);
    setstate({ ...state, currentPage: 1 });
  };
  const vendorfilter = async () => {
    try {
      let postdata = {}
      let res = await _Api(postdata, `api/v1/App/master/vendorfilter`)
      if (res?.code === 200) {
        setOrganizationfilter(res?.data?.organization);
        setVendorfilter(res?.data?.vendortype)
        setStatusfilter(res?.data?.status)
        setcountryfilter(res?.data?.country)
        setCodefilter(res?.data?.contact_person)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  useEffect(() => {
    getvendor()
    vendorfilter()
    // eslint-disable-next-line
  }, [editSales, shortobj])

  const vendordelete = async () => {
    if (!dataid) {
      error("ID Is required")
      return;
    }
    let GetloadingID = loading();
    try {
      const postdata = {
        id: dataid
      }
      const res = await _Api(postdata, `api/v1/App/master/vendorMasterDelete`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, t("Successfully Delete"));
        getvendor()
      } else {
        Updateloading(t, GetloadingID, t("Somthing Went Wrong"), 'error');
      }

    }
    catch (err) {
      console.log(err, "err")
    }
  }

  const hendledropdownhide = (lable) => {
    if (lable === "organization") {
      dropdownReforganization.current.classList.remove("show")
    } else if (lable === "vendortype") {
      dropdownRefvendortype.current.classList.remove("show")
    } else if (lable === "status") {
      dropdownRefstatus.current.classList.remove("show")
    } else if (lable === "country") {
      dropdownRefcountry.current.classList.remove("show")
    } else if (lable === "contact_person") {
      dropdownRefcontact_person.current.classList.remove("show")
    }
  }
  const handleCheckAllChange = (e, lable) => {
    if (lable === "organization") {
      setOrganizationhecked(
        e.target.checked ? organizationfilter.map((c) => c) : []
      );
    } else if (lable === "vendortype") {
      setVendorchecked(
        e.target.checked ? vendortypefilter.map((c) => c) : []
      );
    } else if (lable === "country") {
      setcountrychecked(
        e.target.checked ? countryfilter.map((c) => c?.id) : []
      );
    } else if (lable === "contact_person") {
      setContact_personchecked(
        e.target.checked ? contact_personfilter.map((c) => c) : []
      );
    } else if (lable === "export") {
      setExportid(
        e.target.checked ? getvendotlist.map((c) => c?.id) : []
      )
    }
  };
  const handlefilerChange = (event, data, lable) => {
    if (lable === "organization") {
      setOrganizationhecked((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data]
          : prevChecked.filter((item) => item !== data)

      );
    } else if (lable === "vendortype") {
      setVendorchecked((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data]
          : prevChecked.filter((item) => item !== data)

      );
    } else if (lable === "country") {
      setcountrychecked((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data?.id]
          : prevChecked.filter((item) => item !== data?.id)
      );
    } else if (lable === "contact_person") {
      setContact_personchecked((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data]
          : prevChecked.filter((item) => item !== data)

      );
    } else if (lable === "export") {
      setExportid((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data]
          : prevChecked.filter((item) => item !== data)
      )
    }

  };

  const getscarchvalue = async (event, lable) => {
    if (lable === "organization") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...organizationfilter];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setOrganizationfilter(arr)
        });
      } else {
        vendorfilter()
      }
    } else if (lable === "vendortype") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...vendortypefilter];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setVendorfilter(arr)
        });
      } else {
        vendorfilter()
      }
    } else if (lable === "country") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...countryfilter];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.name?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setcountryfilter(arr)
        });
      } else {
        vendorfilter()
      }
    } else if (lable === "contact_person") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...contact_personfilter];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setCodefilter(arr)
        });
      } else {
        vendorfilter()
      }
    }
  }
  const SampleExcel = () => {
    const exportLinkElement = document.createElement('a')
    exportLinkElement.hidden = true
    exportLinkElement.download = 'vendorExport.xlsx'
    exportLinkElement.href = base_assets + "master_xlsx/vendorExport.xlsx"
    exportLinkElement.text = 'downloading...'
    document.body.appendChild(exportLinkElement)
    exportLinkElement.click()
    exportLinkElement.remove()
  }
  const downloadexclsheet = async () => {
    let GetloadingID = loading()
    try {
      let postdata = {
        ids: exportid
      }
      let res = await _Api(postdata, `api/v1/App/Export/vendorExport`)
      if (res?.code === 200) {
        window.open(res?.filexlsx, '_blank', 'noreferrer')
        Updateloading(t, GetloadingID, res?.message)
      } else {
        Updateloading(t, GetloadingID, res?.message, 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const readExcel = async () => {
    if (!importfile) {
      error(t('Choose file!'));
      return;
    }
    try {
      const formData = new FormData()
      formData.append('file', importfile)
      formData.append('type', 'Vendor')
      let res = await Api_form(
        formData,
        `api/v1/App/Import/vendorImport`,
      )
      if (res.code === 200) {
        success(
          t(res?.message),
        )
        getvendor()
      } else {
        error(t(res?.message))
      }
    } catch (err) {
      console.log(err, 'err')
    }

  }
  const selectOptions = [
    { label: "100", value: "100" },
    { label: "200", value: "200" },
    { label: "300", value: "300" }
  ]
  const handleScrollToThetop = () =>{
    setTimeout(()=>{
      if (vendorListRef.current) {
        vendorListRef.current.scrollIntoView({ behavior: 'smooth', block: 'start'});
      }
    },100)
  }
  return (
    <>
      <div className="main-content-wrapper-body">
        <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
          <div className="main-content-wrapper-body-top-bar-left">
            <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">{t("Master")} <RightArrow /> <span className="cursor-pointer" onClick={() => setEditSales(true)}>{t("Vendor")}</span></h1>
          </div>
          <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">
            <Select
              className="limit-select mx-3"
              options={selectOptions}
              value={{ label: limit, value: limit }}
              onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
            />
            <div className="com-search-bar position-relative">
              <img onClick={filterbyname} className="com-search-bar-search-img" src={base_assets + "images/admin/icons/gray-search.png"} alt="" />
              <input placeholder="Search Vendor" value={filtername} className="h-100 border-0 bg-transparent"
                onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                onChange={(e) => setFiltername(e.target.value)} />
              {filtername ? <img className="search-bar-clear" onClick={(e) => {
                setFiltername("");
                getvendor(1, 100, "")
              }} src={base_assets + 'images/icons/False.png'} alt="" /> : ""}
            </div>
            <div className="print-export-dropdown dropdown">
              <button
                className="btn dropdown-toggle text-center d-flex align-items-center rounded-1 ms-3 justify-content-center bg-white toggle-btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={base_assets + "images/admin/icons/gray-ellipsis.png"} alt="" />
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li className="dropdown-item print-dropdown-item">
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + "images/icons/printer-icon.png"}
                      alt=""
                    />
                  </div>
                  <span>{t("print")}</span>
                </li>
                <li className="dropdown-item"
                  onClick={() => downloadexclsheet()}>
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + "images/icons/export-x-icon.png"}
                      alt=""
                    />
                  </div>
                  <span>{t("export")}</span>
                </li>
                <li className="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#ImportModal">
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + "images/icons/export.png"}
                      alt=""
                    />
                  </div>
                  <span >{t("Import")}</span>
                </li>
              </ul>
            </div>
            <button className={parmissiondatashow?.Master?.Add ? "com-plus-text-btn text-white text-center fw-semibold d-flex align-items-center border-0 rounded-1 ms-3" : "d-none"}
              onClick={() => { setEditItem(true); setCreate(true); setDataid("") }}>
              <img className="me-2" src={base_assets + "images/admin/icons/add-icon-white.png"} alt="" />{t("Create")}
            </button>
          </div>
        </div>
        <div className="row">
          <div className={editItem ? "col-md-6" : "col-md-12"}>
            <div
              className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
            >
              <table
                id="my_customer"
                className="com-custom-table-tag table align-middle">
                <thead>
                  <tr className="text-nowrap align-middle">
                    <th className="pe-0">
                      <div className="com-check">
                        <input id="check1" type="checkbox"
                          onChange={(e) => handleCheckAllChange(e, "export")}
                          checked={getvendotlist?.length ? exportid?.length === getvendotlist?.length : false} />
                        <label htmlFor="check1" className="com-check-label"
                        />
                      </div>
                    </th>
                    <th>#</th>
                    <th className="px-2"></th>
                    <th className="user-name-width">{t("Organization")}
                      <div className="dropdown d-inline-block com-custom-table-tag-filter">
                        <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                          <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                        </button>
                        <div className={"dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"}
                          ref={dropdownReforganization}>
                          <div className="asc-des-order">
                            <div onClick={() => { setShortobj({ key: "organization", value: 1 }); hendledropdownhide("organization") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                            <div onClick={() => { setShortobj({ key: "organization", value: -1 }); hendledropdownhide("organization") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-search">
                            <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                              <img src={base_assets + "images/icons/search-icon.png"} alt="" />
                              <input className="border-0" type="text" placeholder="Search"
                                onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "organization")}
                                onChange={(e) => setSearchVal(e.target.value)} />
                            </div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-select">
                            <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                              <div>
                                <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="organization"
                                  onChange={(e) => handleCheckAllChange(e, "organization")}
                                  checked={organizationchecked.length === organizationfilter.length} />
                                <label className="form-check-label cursor-pointer fw-semibold" htmlFor="organization">{t("Select All")}</label>
                              </div>
                              <label>{organizationchecked.length}/{organizationfilter.length} {t("Selected")}</label>
                            </div>
                            <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                              {organizationfilter?.length ? organizationfilter.map((result, key) => {
                                return (
                                  <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                    <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={key + "organization"}
                                      checked={
                                        organizationchecked ? organizationchecked.includes(result) : ""
                                      }
                                      onChange={(e) => handlefilerChange(e, result, "organization")} />
                                    <label className="form-check-label cursor-pointer" htmlFor={key + "organization"}>{result}</label>
                                  </li>
                                )
                              }) : ""}

                            </ul>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2"
                          >
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                              onClick={() => { setOrganizationhecked([]); setShortobj({}); hendledropdownhide("organization") }}>{t("Clear")}</button>
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { getvendor(); hendledropdownhide("organization") }}>{t("Apply")}</button>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th>{t("Vendor Type")}
                      <div className="dropdown d-inline-block com-custom-table-tag-filter">
                        <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                          <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                        </button>
                        <div className="dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden code"
                          ref={dropdownRefvendortype} >
                          <div className="asc-des-order">
                            <div onClick={() => { setShortobj({ key: "vendortype", value: 1 }); hendledropdownhide("vendortype") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                            <div onClick={() => { setShortobj({ key: "vendortype", value: -1 }); hendledropdownhide("vendortype") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-search">
                            <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                              <img src={base_assets + "images/icons/search-icon.png"} alt="" />
                              <input className="border-0" type="text" placeholder="Search"
                                onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "vendortype")}
                                onChange={(e) => setSearchVal(e.target.value)} />
                            </div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-select">
                            <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                              <div>
                                <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="vendortype"
                                  onChange={(e) => handleCheckAllChange(e, "vendortype")}
                                  checked={vendortypechecked.length === vendortypefilter.length} />
                                <label className="form-check-label cursor-pointer fw-semibold" htmlFor="vendortype">{t("Select All")}</label>
                              </div>
                              <label>{vendortypechecked.length}/{vendortypefilter.length} {t("Selected")}</label>
                            </div>
                            <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                              {vendortypefilter?.length ? vendortypefilter.map((result, key) => {
                                return (
                                  <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                    <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={key + "vendortype"}
                                      checked={
                                        vendortypechecked ? vendortypechecked.includes(result) : ""
                                      }
                                      onChange={(e) => handlefilerChange(e, result, "vendortype")} />
                                    <label className="form-check-label cursor-pointer" htmlFor={key + "vendortype"}>{result}</label>
                                  </li>
                                )
                              }) : ""}

                            </ul>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2">
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                              onClick={() => { setVendorchecked([]); setShortobj({}); hendledropdownhide("vendortype") }}>{t("Clear")}</button>
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { getvendor(); hendledropdownhide("vendortype") }}>{t("Apply")}</button>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th>{t("Contact Person")}
                      <div className="dropdown d-inline-block com-custom-table-tag-filter">
                        <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                          <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                        </button>
                        <div className="dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden code"
                          ref={dropdownRefcontact_person} >
                          <div className="asc-des-order">
                            <div onClick={() => { setShortobj({ key: "contact_person", value: 1 }); hendledropdownhide("contact_person") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                            <div onClick={() => { setShortobj({ key: "contact_person", value: -1 }); hendledropdownhide("contact_person") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-search">
                            <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                              <img src={base_assets + "images/icons/search-icon.png"} alt="" />
                              <input className="border-0" type="text" placeholder="Search"
                                onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "contact_person")}
                                onChange={(e) => setSearchVal(e.target.value)} />
                            </div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-select">
                            <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                              <div>
                                <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="contact_person"
                                  onChange={(e) => handleCheckAllChange(e, "contact_person")}
                                  checked={contact_personchecked.length === contact_personfilter.length} />
                                <label className="form-check-label cursor-pointer fw-semibold" htmlFor="contact_person">{t("Select All")}</label>
                              </div>
                              <label>{contact_personchecked.length}/{contact_personfilter.length} {t("Selected")}</label>
                            </div>
                            <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                              {contact_personfilter?.length ? contact_personfilter.map((result, key) => {
                                return (
                                  <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                    <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={key + "contact_person"}
                                      checked={
                                        contact_personchecked ? contact_personchecked.includes(result) : ""
                                      }
                                      onChange={(e) => handlefilerChange(e, result, "contact_person")} />
                                    <label className="form-check-label cursor-pointer" htmlFor={key + "contact_person"}>{result}</label>
                                  </li>
                                )
                              }) : ""}

                            </ul>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2">
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                              onClick={() => { setContact_personchecked([]); setShortobj({}); hendledropdownhide("contact_person") }}>{t("Clear")}</button>
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { getvendor(); hendledropdownhide("contact_person") }}>{t("Apply")}</button>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th>{t("Country")}
                      <div className="dropdown d-inline-block com-custom-table-tag-filter">
                        <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                          <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                        </button>
                        <div className={"dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"}
                          ref={dropdownRefcountry}>
                          <div className="asc-des-order">
                            <div onClick={() => { setShortobj({ key: "country", value: 1 }); hendledropdownhide("country") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                            <div onClick={() => { setShortobj({ key: "country", value: -1 }); hendledropdownhide("country") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-search">
                            <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                              <img src={base_assets + "images/icons/search-icon.png"} alt="" />
                              <input className="border-0" type="text" placeholder="Search"
                                onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "country")}
                                onChange={(e) => setSearchVal(e.target.value)} />
                            </div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-select">
                            <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                              <div>
                                <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="country"
                                  onChange={(e) => handleCheckAllChange(e, "country")}
                                  checked={countrychecked.length === countryfilter.length} />
                                <label className="form-check-label cursor-pointer fw-semibold" htmlFor="country">{t("Select All")}</label>
                              </div>
                              <label>{countrychecked.length}/{countryfilter.length} {t("Selected")}</label>
                            </div>
                            <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                              {countryfilter?.length ? countryfilter.map((result, key) => {
                                return (
                                  <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                    <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={key + "country"}
                                      checked={
                                        countrychecked ? countrychecked.includes(result?.id) : ""
                                      }
                                      onChange={(e) => handlefilerChange(e, result, "country")} />
                                    <label className="form-check-label cursor-pointer" htmlFor={key + "country"}>{result?.name}</label>
                                  </li>
                                )
                              }) : ""}

                            </ul>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2"
                          >
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                              onClick={() => { setcountrychecked([]); setShortobj({}); hendledropdownhide("country") }}>{t("Clear")}</button>
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { getvendor(); hendledropdownhide("country") }}>{t("Apply")}</button>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th>{t("Phone No.")} </th>
                    <th>{t("Last Modified Date")} </th>
                    <th>{t("Status")}
                      <div className="dropdown d-inline-block com-custom-table-tag-filter">
                        <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                          <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                        </button>
                        <div className="dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"
                          ref={dropdownRefstatus}
                        >
                          <div className="asc-des-order">
                            <div onClick={() => { setShortobj({ key: "Status", value: -1 }); hendledropdownhide("status") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                            <div onClick={() => { setShortobj({ key: "Status", value: 1 }); hendledropdownhide("status") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-select">
                            <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                              {statusfilter?.length ? statusfilter.map((result, key) => {
                                return (
                                  <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                    <input className="fill-com-check form-check-input radio d-inline-block me-2 cursor-pointer" name="mixmatchradio" type="radio" id={key + "status"} value={result?.value}
                                      onClick={(e) => setStatuschecked(result?.value)} />
                                    <label className="form-check-label cursor-pointer" htmlFor={key + "status"}>{result?.lable}</label>
                                  </li>
                                )
                              }) : ""}

                            </ul>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2">
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                              onClick={(e) => { setStatuschecked(""); setShortobj({}); hendledropdownhide("status") }} >{t("Clear")}</button>
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { getvendor(); hendledropdownhide("status") }}>{t("Apply")}</button>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {getvendotlist?.length ? getvendotlist.map((result, key) => {
                    return (
                      <tr className="text-nowrap" key={key}>
                        <td className="pe-0">
                          <div className="com-check">
                            <input id={result?.id} type="checkbox"
                              onChange={(e) => handlefilerChange(e, result?.id, "export")}
                              checked={
                                exportid ? exportid.includes(result?.id) : ""
                              } />
                            <label htmlFor={result?.id} className="com-check-label" />
                          </div>
                        </td>
                        <td>{key + 1}</td>
                        <td className="px-2">
                          <img className="table-user-img rounded-circle" src={result?.profile ? result?.profile : base_assets + "/images/icons/customer-card-icon.png"} alt="" />
                        </td>
                        {parmissiondatashow?.Master?.Edit ?
                          <td className="link-color cursor-pointer" onClick={() => { setEditItem(true); setDataid(result?.id); setCreate(false); handleScrollToThetop() }}>{result?.organisation ? result?.organisation : ""}</td> :
                          <td>{result?.organisation ? result?.organisation : ""}</td>
                        }
                        <td>{result?.vendor_type ? result?.vendor_type : ""}</td>
                        <td> {result?.contact_person ? result?.contact_person : ""}</td>
                        <td>{result?.country?.name ? result?.country?.name : ""}</td>
                        <td>{result?.contacts[0].code ? "+" : ""}{result?.contacts[0].code ? `${result?.contacts[0]?.code}` : ""}{result?.contacts[0]?.no ? -result?.contacts[0]?.no : ""} </td>
                        <td>{result?.updated ? result?.updated : ""}</td>
                        <td>
                          {result?.Status === "1" ? <span className="active-badge  rounded-pill px-2 py-1 fw-semibold"> <span className="active-badge-dot me-2"></span>{t("Active")}</span>
                            : <span className="inactive-badge  rounded-pill px-2 py-1 fw-semibold"> <span className="inactive-badge-dot me-2"></span>{t("InActive")}</span>}

                        </td>
                        <td className="text-center">
                          <img
                            onClick={() => { setEditItem(true); setDataid(result?.id); setCreate(false); handleScrollToThetop() }}
                            className={parmissiondatashow?.Master?.Edit ? "cursor-pointer me-4" : "d-none"}
                            src={base_assets + "images/admin/icons/Edit.png"} alt="" />
                          <img
                            className={parmissiondatashow?.Master?.Delete ? "cursor-pointer" : "d-none"}
                            data-bs-toggle="modal" data-bs-target="#createPurchaseModal"
                            onClick={() => setDataid(result?.id)} src={base_assets + "images/admin/icons/Delete.png"} alt="" />
                        </td>
                      </tr>
                    )
                  }) :
                    <tr>
                      <td colSpan="50" className="text-center"> {t("No Data Available In Table")}</td>
                    </tr>
                  }
                </tbody>
              </table>
              {showloader && <TableLoader />}
            </div>
            {getvendotlist?.length > 0 ? (
              <Pagination
                total={totalPages}
                current={currentPage}
                pagination={(crPage) => handlePaginations(crPage)}
              />
            ) : (
              ""
            )}
          </div>
          <div className={editItem ? "col-md-6 d-block" : "d-none"} ref={vendorListRef}>
            <EditVendor setEditItem={setEditItem}
              dataid={dataid}
              create={create}
              setEditSales={setEditSales}
              setDataid={setDataid}
              setCreate={setCreate}
            />
          </div>
        </div>
        <Confirmationmodel statement={"Delete"} handler_submit={vendordelete} ></Confirmationmodel>
        <Importmodal
          setImportfile={setImportfile}
          readExcel={readExcel}
          importfile={importfile}
          SampleExcel={SampleExcel} />
      </div>
    </>
  )
}

export default MasterVendor;