import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { _Api } from '../common/api/_call';


export default function AppointmentFilter(props) {
  const { setToggleAppointmentFilter, applyfilter } = props
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [filterdata, setFilterdata] = useState()
  const [onchangefilter, setOnchangefilter] = useState({
    location: [],
    status: []
  })
  const clearall = () => {
    setOnchangefilter({
      location: [],
      status: []
    })
  }
  const appointmentfilter = async () => {
    try {
      let postdata = {
      }
      let res = await _Api(postdata, `api/v1/Customer/appointment/filter`)
      if (res?.code === 200) {
        let filterarray =
        {
          location: res?.location,
          status: [
            {
              label: "Open",
              value: 0
            },
            {
              label: "Close",
              value: 1
            },
            {
              label: "Cancel",
              value: 2
            },
          ]
        }


        setFilterdata(filterarray)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const filters = (value, event) => {
    var filterdata = { ...onchangefilter }
    if (value === "location") {
      if (event.target.checked) {
        filterdata.location.push(event.target.value)
      } else {
        let filteredArray = onchangefilter?.location.filter((item, index) => item !== event.target.value);
        filterdata.location = filteredArray
      }
    }
    if (value === "status") {
      if (event.target.checked) {
        filterdata.status.push(parseInt(event.target.value))
      } else {
        let filteredArray = onchangefilter?.status.filter((item, index) => item !== parseInt(event.target.value));
        filterdata.status = filteredArray
      }
    }
    setOnchangefilter(filterdata)
  }
  const submitapplyfilter = () => {
    applyfilter(onchangefilter)
    setToggleAppointmentFilter(false)
  }
  useEffect(() => {
    appointmentfilter()
  }, [])
  return (
    <div className='customer-search-filter-modal d-flex'>
      <div className="modal fade d-block" id="appointmentFilter" aria-labelledby="appointmentFilterModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button className="clear-btn btn-style link-color" onClick={clearall}>Clear All</button>
              <h5 className="modal-title" id="customerSearchFilterModalLabel">Filter</h5>
              <button type="button" className="btn-close focus-none shadow-none" onClick={() => setToggleAppointmentFilter(false)}></button>
            </div>
            <div className="modal-body">
              <div className="customer-search-filter-modal-row">
                <div className="customer-search-filter-modal-row-col col">
                  <div className="customer-search-filter-modal-row-col-header">
                    <label className="customer-search-filter-modal-row-col-header-label">Location</label>
                    <button className="customer-search-filter-modal-row-col-header-btn btn-style"
                      onClick={() => setOnchangefilter((state) => ({
                        ...state,
                        location: filterdata?.location
                      }))}>Select All</button>
                  </div>
                  <div className="customer-search-filter-modal-row-col-body">
                    <div className="customer-search-filter-modal-row-col-body-search">
                      <img className="" src={base_assets + "images/pos/icons/magnifying-glass.png"} alt="" />
                      <input type="text" placeholder="Search"
                      />
                    </div>
                    <div className="customer-search-filter-modal-row-col-body-check-data">
                      {filterdata?.location?.length ?
                        filterdata?.location.map((result, key) => {
                          return (
                            <div className="customer-search-filter-modal-row-col-body-check-data-single"
                              key={key}>
                              <div className="com-check">
                                <input type="checkbox" name="clear"
                                  id={result + key}
                                  value={result}
                                  checked={onchangefilter?.location.includes(result)}
                                  onChange={(e) => {
                                    filters("location", e)
                                  }} />
                                <label className="com-check-label" htmlFor={result + key}></label>
                              </div>
                              <label htmlFor={result + key}>{result}</label>
                            </div>
                          )
                        })
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="customer-search-filter-modal-row-col col">
                  <div className="customer-search-filter-modal-row-col-header">
                    <label className="customer-search-filter-modal-row-col-header-label">Status</label>
                    <button className="customer-search-filter-modal-row-col-header-btn btn-style"
                      onClick={() => setOnchangefilter((state) => ({
                        ...state,
                        status: filterdata?.status.map(option => option.value)
                      }))}>Select All</button>
                  </div>
                  <div className="customer-search-filter-modal-row-col-body">
                    <div className="customer-search-filter-modal-row-col-body-search">
                      <img className="" src={base_assets + "images/pos/icons/magnifying-glass.png"} alt="" />
                      <input type="text" placeholder="Search" />
                    </div>
                    <div className="customer-search-filter-modal-row-col-body-check-data">
                      {filterdata?.status?.length ?
                        filterdata?.status.map((result, key) => {
                          return (
                            <div className="customer-search-filter-modal-row-col-body-check-data-single"
                              key={key}>
                              <div className="com-check">
                                <input type="checkbox" name="clear"
                                  id={"status" + result?.value + key}
                                  value={result?.value}
                                  checked={onchangefilter?.status.includes(result?.value)}
                                  onChange={(e) => {
                                    filters("status", e)
                                  }}
                                />
                                <label className="com-check-label" htmlFor={"status" + result?.value + key}></label>
                              </div>
                              <label htmlFor={"status" + result?.value + key}>{result?.label}</label>
                            </div>
                          )
                        })
                        : ""}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn-style" onClick={() =>
                submitapplyfilter()}>{t("Apply")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
