import { useEffect } from 'react';
// import { wishlistobjectheader } from "../../../../source/app/appSlice";
import { useDispatch } from 'react-redux';
import { wishlistobjectheader } from 'source/app/appSlice';



const WishlishHandler = (props) => {
    const { wishlistdata, productdata, setProductdata } = props
    let localmywishlist = localStorage.getItem('mywishlist') ? JSON.parse(localStorage.getItem('mywishlist')) : [];
    let localmywishlistremove = localStorage.getItem('mywishlistremove') ? JSON.parse(localStorage.getItem('mywishlistremove')) : [];
    const dispatch = useDispatch();
    const Addwislist = (id) => {
        let array = [...productdata]
        let wishlistobj = array.find((item) => item?._id === wishlistdata?.id);
        if (wishlistobj) {
            wishlistobj.isWishlist = wishlistobj?.isWishlist === 1 ? 0 : 1;
        }
        let addwislistarr = [...localmywishlist]
        addwislistarr.push(id)
        const uniqueIdsSet = new Set(addwislistarr);
        let unique_ids = Array.from(uniqueIdsSet);
        localStorage.setItem('mywishlist', JSON.stringify(unique_ids ? unique_ids : []));
        dispatch(wishlistobjectheader(unique_ids));
        if (localmywishlistremove?.length) {
            const updatedData = localmywishlistremove.filter(removeid => removeid !== id);
            localStorage.setItem('mywishlistremove', JSON.stringify(updatedData ? updatedData : []));

        }
        setProductdata(array);
    }
    const removewishlist = (idToRemove) => {
        let array = [...productdata]
        let wishlistobj = array.find((item) => item?._id === wishlistdata?.id);
        if (wishlistobj) {
            wishlistobj.isWishlist = wishlistobj?.isWishlist === 1 ? 0 : 1;
        }
        let removewislistarr = [...localmywishlistremove]
        removewislistarr.push(idToRemove)
        const uniqueIdsSet = new Set(removewislistarr);
        let unique_ids = Array.from(uniqueIdsSet);
        localStorage.setItem('mywishlistremove', JSON.stringify(unique_ids ? unique_ids : []));
        if (localmywishlist?.length) {
            const updatedData = localmywishlist.filter(id => id !== idToRemove);
            localStorage.setItem('mywishlist', JSON.stringify(updatedData ? updatedData : []));
            dispatch(wishlistobjectheader(updatedData));
        }
        setProductdata(array)

    }
    useEffect(() => {
        if (wishlistdata?.add) {
            Addwislist(wishlistdata?.id)
        }
        if (wishlistdata?.remove) {
            removewishlist(wishlistdata?.id)
        }
        // eslint-disable-next-line
    }, [wishlistdata])
}

export default WishlishHandler
