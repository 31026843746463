import React from 'react'

export default function FilterIconMain(props) {
    const { width, height, shape } = props
  return (
    <>
    {shape === 'cone' ? 
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clipRule="evenodd" d="M17.4813 2.78C12.5083 2.22533 7.48918 2.22533 2.51626 2.78C2.44003 2.78874 2.36753 2.81778 2.30634 2.8641C2.24516 2.91041 2.19752 2.97229 2.1684 3.04329C2.13929 3.11429 2.12976 3.1918 2.14082 3.26774C2.15188 3.34368 2.18311 3.41525 2.23126 3.475L6.62751 8.91C8.20222 10.8567 9.0613 13.2849 9.06126 15.7888V19.4L10.9363 20.775V15.7875C10.9365 13.284 11.7956 10.8564 13.37 8.91L17.7663 3.475C17.8144 3.41525 17.8456 3.34368 17.8567 3.26774C17.8678 3.1918 17.8582 3.11429 17.8291 3.04329C17.8 2.97229 17.7524 2.91041 17.6912 2.8641C17.63 2.81778 17.5575 2.78874 17.4813 2.78ZM2.30876 0.916254C7.41994 0.345095 12.5788 0.345095 17.69 0.916254C19.4975 1.11875 20.3688 3.23875 19.225 4.65375L14.8288 10.0888C13.5236 11.7018 12.8114 13.7138 12.8113 15.7888V22.625C12.8114 22.7984 12.7634 22.9684 12.6727 23.1161C12.582 23.2639 12.4522 23.3836 12.2975 23.462C12.1429 23.5403 11.9695 23.5743 11.7967 23.56C11.6239 23.5458 11.4585 23.4839 11.3188 23.3813L7.56876 20.6313C7.4501 20.5441 7.35364 20.4302 7.28718 20.2988C7.22073 20.1674 7.18616 20.0222 7.18626 19.875V15.7875C7.18626 13.7125 6.47376 11.7013 5.17001 10.0875L0.773764 4.655C-0.371236 3.24 0.498764 1.11875 2.30876 0.916254Z" fill="white"/>
    </svg>
    :
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 15.6H15C15.1499 15.5997 15.2945 15.6556 15.4053 15.7566C15.5161 15.8576 15.5851 15.9965 15.5986 16.1458C15.6122 16.2951 15.5693 16.4441 15.4785 16.5634C15.3877 16.6827 15.2555 16.7637 15.108 16.7904L15 16.8H9C8.85006 16.8003 8.70546 16.7444 8.59466 16.6434C8.48386 16.5424 8.4149 16.4035 8.40135 16.2542C8.3878 16.1049 8.43065 15.9559 8.52146 15.8366C8.61227 15.7173 8.74446 15.6363 8.892 15.6096L9 15.6ZM6.6 10.8H17.4C17.5499 10.7997 17.6945 10.8556 17.8053 10.9566C17.9161 11.0576 17.9851 11.1965 17.9986 11.3458C18.0122 11.4951 17.9693 11.6441 17.8785 11.7634C17.7877 11.8827 17.6555 11.9637 17.508 11.9904L17.4 12H6.6C6.45006 12.0003 6.30546 11.9444 6.19466 11.8434C6.08386 11.7424 6.0149 11.6035 6.00135 11.4542C5.9878 11.3049 6.03065 11.1559 6.12146 11.0366C6.21227 10.9173 6.34446 10.8363 6.492 10.8096L6.6 10.8ZM4.2 6H19.8C19.9499 5.99973 20.0945 6.0556 20.2053 6.15661C20.3161 6.25763 20.3851 6.39646 20.3986 6.54579C20.4122 6.69511 20.3693 6.84409 20.2785 6.9634C20.1877 7.08271 20.0555 7.16369 19.908 7.1904L19.8 7.2H4.2C4.05006 7.20028 3.90546 7.14441 3.79466 7.04339C3.68386 6.94238 3.6149 6.80354 3.60135 6.65422C3.5878 6.50489 3.63065 6.35591 3.72146 6.2366C3.81227 6.1173 3.94446 6.03631 4.092 6.0096L4.2 6Z" fill="#A4A4A4"/>
    </svg>
    }
    </>
  )
}
