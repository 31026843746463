import React, { useEffect, useState } from 'react'
import ConfirmDoneIcon from '../../assets/images/ConfirmIconGreen.png';
import ShareIcon from '../../assets/icons/ShareIcon';
import DownloadIcon from '../../assets/icons/DownloadIcon';
import PrintIcon from '../../assets/icons/PrintIcon';
import CartIcon from '../../assets/icons/CopyIcon';
import html2canvas from 'html2canvas';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const ConfirmFormPage = (props) => {
    const { t } = useTranslation();
    const [appoinmentconfirm, setappoinmentconfirm] = useState(true)
    const [showstatus, setShowstatus] = useState(false)
    const [orderdetails, setOrderdetails] = useState({})
    const { lastpagedata, countrylist } = props
    const handleCopyClick = async (cupyinput) => {
        try {
            await navigator.clipboard.writeText(cupyinput);
            setShowstatus(true)
            setTimeout(() => {
                setShowstatus(false);
            }, 2000);
        } catch (err) {
            console.log(err, "err");
        }
    };
    const downloadPageAsPNG = () => {
        const rootElement = document.getElementById('saveorder');
        html2canvas(rootElement).then((canvas) => {
            const imageURL = canvas.toDataURL('image/png');
            const anchor = document.createElement('a');
            anchor.download = `${orderdetails?.order_no}.png`;
            anchor.href = imageURL;
            anchor.click();
        });
    };
    const handlePrint = () => {
        window.print();
    };
    const setalldata = () => {
        if (lastpagedata?.statusCode === 200) {
            if (countrylist?.length) {
                var shippingcountryname = countrylist.find((item) => item?.value === lastpagedata?.data?.shipping?.country)
                var billingcountryname = countrylist.find((item) => item?.value === lastpagedata?.data?.billing?.country)
            }
            let dataobj = {
                shipping: {},
                billing: {},
                order_no: ""
            }
            let shippingname = [
                lastpagedata?.data?.shipping?.fname ?? '',
                lastpagedata?.data?.shipping?.mname ?? '',
                lastpagedata?.data?.shipping?.lname ?? ''
            ]
            dataobj.shipping.name = shippingname.filter(part => part).join(' ');;
            let shippingaddressParts = [
                lastpagedata?.data?.shipping?.address ?? '',
                lastpagedata?.data?.shipping?.city ?? '',
                lastpagedata?.data?.shipping?.state ?? '',
                shippingcountryname?.label ?? '',
                lastpagedata?.data?.shipping?.zipcode ?? ''
            ];

            dataobj.shipping.address = shippingaddressParts.filter(part => part).join(', ');
            dataobj.shipping.contact = lastpagedata?.data?.shipping?.contacts?.length ? lastpagedata?.data?.shipping?.contacts[0]?.number : ""
            dataobj.shipping.email = lastpagedata?.data?.shipping?.emails?.length ? lastpagedata?.data?.shipping?.emails[0] : ""
            let billingname = [
                lastpagedata?.data?.billing?.fname ?? '',
                lastpagedata?.data?.billing?.mname ?? '',
                lastpagedata?.data?.billing?.lname ?? ''
            ]
            dataobj.billing.name = billingname.filter(part => part).join(' ');
            let addressParts = [
                lastpagedata?.data?.billing?.address ?? '',
                lastpagedata?.data?.billing?.city ?? '',
                lastpagedata?.data?.billing?.state ?? '',
                billingcountryname?.label ?? '',
                lastpagedata?.data?.billing?.zipcode ?? ''
            ];

            dataobj.billing.address = addressParts.filter(part => part).join(', ');
            dataobj.billing.contact = lastpagedata?.data?.billing?.contacts?.length ? lastpagedata?.data?.billing?.contacts[0]?.number : ""
            dataobj.billing.email = lastpagedata?.data?.billing?.emails?.length ? lastpagedata?.data?.billing?.emails[0] : ""
            dataobj.order_no = lastpagedata?.data?.order_no
            dataobj.tax_id = lastpagedata?.data?.tax_id
            setOrderdetails(dataobj)
        }
    }
    useEffect(() => {
        if (lastpagedata?.statusCode === 200) {
            setalldata()
        }
        // eslint-disable-next-line
    }, [lastpagedata?.statusCode])
    return (
        <div className='com-confirmPurchasePage-main' id="saveorder">
            <div className='com-confirmPurchasePage-main-headerBox mt-5'>
                <h3 className='com-confirmPurchasePage-main-headerBox-thankYouText'>{t("Thank you for your purchase")}!</h3>
                <span className='com-confirmPurchasePage-main-headerBox-doneIconBox'>
                    <img src={ConfirmDoneIcon} alt='' />
                </span>
            </div>
            <div className='com-confirmPurchasePage-main-containerBox'>
                <div className='com-confirmPurchasePage-main-containerBox-getDocsIconBox'>
                    <span className='com-confirmPurchasePage-main-containerBox-getDocsIconBox-Iconspan'><ShareIcon /></span>
                    <span className='com-confirmPurchasePage-main-containerBox-getDocsIconBox-Iconspan' onClick={() => downloadPageAsPNG()}><DownloadIcon /></span>
                    <span className='com-confirmPurchasePage-main-containerBox-getDocsIconBox-Iconspan' onClick={() => handlePrint()}><PrintIcon /></span>
                </div>
                <div className='com-confirmPurchasePage-main-containerBox-middleContentBox'>
                    <div className='com-confirmPurchasePage-main-containerBox-middleContentBox-purchaseIdBox'><span className='idShows'>{t("ID")} : #{orderdetails?.order_no}</span> <span onClick={() => handleCopyClick(orderdetails?.order_no)}><CartIcon widt={'19px'} height={'19px'} /> </span></div>
                    <span className='text-center'>{showstatus ? t("Copy Successfully") : ""} </span>
                    <div className='com-confirmPurchasePage-main-containerBox-middleContentBox-thanksParaBox'>{("Thank you for your order, you have successfully placed your order, you can track your order status")} <span>{t("here")}</span>,{t(" Below are related products that goes with what you just purchased")}</div>
                </div>
                <div className='com-confirmPurchasePage-main-containerBox-allshippingAddrShow'>
                    <div className="com-addressShowBox com-confirmPurchasePage-main-containerBox-allshippingAddrShow-singleShippAddr">
                        <div className="com-addressShowBox-headerBox">
                            <h2 className='shippingaddrHeader'>{t("Shipping Addresses")}</h2>
                        </div>
                        <h3 className="com-addressShowBox-AddrparaBox">
                            {orderdetails?.shipping?.name}
                        </h3>
                        <p className="com-addressShowBox-AddrparaBox w-100">
                            {orderdetails?.shipping?.address}
                        </p>
                        <p className="com-addressShowBox-phonePara">
                            {orderdetails?.shipping?.contact}
                        </p>
                        <p className="com-addressShowBox-phonePara">
                            {orderdetails?.shipping?.email}
                        </p>
                    </div>
                    <div className="com-addressShowBox com-confirmPurchasePage-main-containerBox-allshippingAddrShow-singleShippAddr">
                        <div className="com-addressShowBox-headerBox">
                            <h2 className='shippingaddrHeader'>{t("Billing Addresses")}</h2>
                            <div className="taxIDBox">
                                {t("Tax ID")}:
                                <span>{orderdetails?.tax_id}</span>
                            </div>
                        </div>
                        <h3 className="com-addressShowBox-AddrparaBox">
                            {orderdetails?.billing?.name}
                        </h3>
                        <p className="com-addressShowBox-AddrparaBox w-100">
                            {orderdetails?.billing?.address}
                        </p>
                        <p className="com-addressShowBox-phonePara">
                            {orderdetails?.billing?.contact}
                        </p>
                        <p className="com-addressShowBox-phonePara">
                            {orderdetails?.billing?.email}
                        </p>
                    </div>
                </div>
            </div>
            {appoinmentconfirm ?
                <div className='com-confirmPurchasePage-main-footerBox'>
                    <Link to="/" className='com-confirmPurchasePage-main-footerBox-continueShopBtn d-flex justify-content-center align-items-center text-decoration-none'>{t("Continue Shopping")}</Link>
                    <button className='com-confirmPurchasePage-main-footerBox-completeBtn' onClick={() => setappoinmentconfirm(false)}>{t("Ok")}</button>
                </div> : ""}

        </div>
    )
}

export default ConfirmFormPage