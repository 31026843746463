import React from 'react'

export default function MessageIcon({ width, height, type}) {
  return (
    <>
    {type === 'email' ?
    
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 7.00005L10.2 11.65C11.2667 12.45 12.7333 12.45 13.8 11.65L20 7" stroke="#181818" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5Z" stroke="#181818" stroke-linecap="round"/>
    </svg>
    
    : 

    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9999 10H12.0999M7.8999 10H7.9999M15.9999 10H16.0999" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21 4V16C21 16.2652 20.8946 16.5196 20.7071 16.7071C20.5196 16.8946 20.2652 17 20 17H13L8 21V17H4C3.73478 17 3.48043 16.8946 3.29289 16.7071C3.10536 16.5196 3 16.2652 3 16V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4Z" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    }
    </>
  )
}
