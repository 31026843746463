import React from 'react'

export default function Hamburger() {
  return (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.6875 16.5C0.6875 16.2514 0.786272 16.0129 0.962088 15.8371C1.1379 15.6613 1.37636 15.5625 1.625 15.5625H20.375C20.6236 15.5625 20.8621 15.6613 21.0379 15.8371C21.2137 16.0129 21.3125 16.2514 21.3125 16.5C21.3125 16.7486 21.2137 16.9871 21.0379 17.1629C20.8621 17.3387 20.6236 17.4375 20.375 17.4375H1.625C1.37636 17.4375 1.1379 17.3387 0.962088 17.1629C0.786272 16.9871 0.6875 16.7486 0.6875 16.5ZM0.6875 9C0.6875 8.75136 0.786272 8.5129 0.962088 8.33709C1.1379 8.16127 1.37636 8.0625 1.625 8.0625H20.375C20.6236 8.0625 20.8621 8.16127 21.0379 8.33709C21.2137 8.5129 21.3125 8.75136 21.3125 9C21.3125 9.24864 21.2137 9.4871 21.0379 9.66291C20.8621 9.83873 20.6236 9.9375 20.375 9.9375H1.625C1.37636 9.9375 1.1379 9.83873 0.962088 9.66291C0.786272 9.4871 0.6875 9.24864 0.6875 9ZM0.6875 1.5C0.6875 1.25136 0.786272 1.0129 0.962088 0.837088C1.1379 0.661272 1.37636 0.5625 1.625 0.5625H20.375C20.6236 0.5625 20.8621 0.661272 21.0379 0.837088C21.2137 1.0129 21.3125 1.25136 21.3125 1.5C21.3125 1.74864 21.2137 1.9871 21.0379 2.16291C20.8621 2.33873 20.6236 2.4375 20.375 2.4375H1.625C1.37636 2.4375 1.1379 2.33873 0.962088 2.16291C0.786272 1.9871 0.6875 1.74864 0.6875 1.5Z" fill="black"/>
    </svg>

  )
}
