import React, { useState, useEffect, useCallback } from "react";
import "./login-signup.scss";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { _Apiauth } from "../../api/_call";
import { useTranslation } from 'react-i18next';
const Otpverification = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    const { state } = useLocation();
    const [otpdata, setOtpdata] = useState()
    const [checkdata, setCheckdata] = useState()
    const [resendotpmeg, setResendotpmdg] = useState({ errormeage: "", successmessage: "", buttonshow: true })
    const Otpverify = useCallback(async () => {
        const handleValidation = (e) => {
            let formIsValid = true;
            if (!otpdata) {
                setCheckdata(prevState => ({
                    ...prevState,
                    checkotp: {
                        message: "Pleace enter OTP",
                        status: true
                    }
                }));
                formIsValid = false;
            }
            return formIsValid;
        }
        if (handleValidation()) {
            setCheckdata(prevState => ({
                ...prevState,
                lodear: {
                    status: true
                }
            }));
            localStorage.removeItem('UserToken');
            localStorage.removeItem('updateModulePermission');
            localStorage.removeItem('updateUserPermission');
            try {
                let postdata = {
                    "authToken": state?.access_tokens,
                    "otp": otpdata
                }
                let res = await _Apiauth(postdata, `Auth/Web/otp-verification`)
                if (res?.code === 200) {
                    setCheckdata(prevState => ({
                        ...prevState,
                        checkotp: {
                            message: res?.message,
                            status: false
                        }
                    }));
                    if (res?.data?.storeUrl) {
                        let rediractDomain = res?.data?.storeUrl;
                        const tokenPath = `/authentication/${res?.data?.access_tokens}`;
                        if (window.location.hostname !== new URL(rediractDomain).hostname) {
                            // Redirect to subdomain or different domain
                            const tokenPath_r = `authentication/${res?.data?.access_tokens}`;
                            window.location.assign(rediractDomain + tokenPath_r);
                        } else {
                            // Same domain: use React navigate
                            navigate(tokenPath, { state: { domain: rediractDomain } });
                        }
                    } else {
                        navigate('/login');
                    }
                }
                else {

                    setCheckdata(prevState => ({
                        ...prevState,
                        checkotp: {
                            message: res?.message,
                            status: true
                        }
                    }));
                    setCheckdata(prevState => ({
                        ...prevState,
                        lodear: {
                            status: false
                        }
                    }));
                }
            }
            catch (err) {
                console.log(err, "err")
            }
        }
    }, [navigate, otpdata, state?.access_tokens])
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                Otpverify();
            }
        };
        document.addEventListener('keypress', handleKeyPress);
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, [Otpverify]);
    const resendotp = async () => {
        try {
            let postdata = {
                "authToken": state?.access_tokens,
            }
            let res = await _Apiauth(postdata, `Auth/Web/resendOtp`)
            if (res?.code === 200) {
                setResendotpmdg({
                    successmessage: res?.message,
                    buttonshow: true
                })
            } else {
                setResendotpmdg({
                    errormeage: res?.message,
                    buttonshow: true
                })
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    return (
        <div className="main-body bg-white p-0">
            <div className="login-page position-relative d-flex align-items-center justify-content-center">
                <img className="login-page-bg position-absolute end-0 bottom-0 w-75" src={base_assets + "images/login-bg.png"} alt="" />
                <div className="login-page-row d-flex justify-content-center align-items-center w-100">
                    <div className="text-center login-page-left-col">
                        <Link to="/">
                            <img className="login-page-left-col-img" src={base_assets + "images/gis-xl-logo.png"} alt="" />
                        </Link>
                        <span className="d-block mt-4 fs-4 fw-semibold login-page-left-col-text">{t("Start your journey with us")}<span className="main-green-text">{t(" today")}</span></span>
                    </div>
                    <div className="login-page-right-col">
                        <h1 className="login-page-right-col-heading fs-2 fw-semibold">{t("OTP Verification")}</h1>
                        <div className="login-page-right-col-form">
                            <div className="col-md-12">
                                <div className="login-page-right-col-form-otp-msg main-green-text mb-4 fs-14">
                                    {t("A verification code has been sent to")} <b>{state?.maskEmail}</b>. {t("Enter the code to continue and be redirected.")}
                                </div>

                                <div className={`placeholder-up-input w-100 form-floating ${checkdata?.checkotp?.status ? "md-1" : "mb-4"}`}>
                                    <input type="email" className={`placeholder-up-input-field form-control fs-6 px-3 ${checkdata?.checkotp?.status ? "border-danger" : ""}`} id="" placeholder="OTP"
                                        onChange={(e) => setOtpdata(e.target.value)} />
                                    <label className="placeholder-up-input-label fs-6" htmlFor="">
                                        {t("OTP")}
                                    </label>
                                </div>
                                {checkdata?.checkotp?.status ? <span className="d-block mb-4 text-danger">{checkdata?.checkotp?.message ? checkdata?.checkotp?.message : ""}</span> : ""}

                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-2 fs-14">
                                <span onClick={() => {
                                    resendotp();
                                    setResendotpmdg({ buttonshow: false })
                                }} className={`text-decoration-underline main-green-text fw-semibold cursor-pointer ${!resendotpmeg?.buttonshow ? "d-none" : ""}`}>{t("Resend Otp")}</span>
                                <p className="text-end login-page-right-col-form-forgot-pass mb-0"> {t("Go Back To ")}
                                    <Link to="/login" className="text-decoration-none fw-semibold main-green-text">{t("Login")}</Link>?
                                </p>
                            </div>
                            {resendotpmeg?.errormeage ?
                                <div className="align-items-center mb-2 fs-14 text-danger">{resendotpmeg?.errormeage}</div> :
                                <div className="align-items-center mb-2 fs-14 main-green-text">{resendotpmeg?.successmessage}</div>
                            }
                            <button className="login-page-right-col-form-login-btn main-green-bg text-white text-center w-100 border-0 rounded-1 fs-6 fw-semibold d-flex align-items-center justify-content-center">
                                {checkdata?.lodear?.status ?
                                    <div className="border-bottom-0 border-width-2px d-block text-white mx-auto spinner-border flex-shrink-0" role="status"></div> :
                                    <span className="h-100 w-100 justify-content-center align-items-center d-flex"
                                        onClick={() => Otpverify()} >{t("Submit")}</span>
                                }
                            </button>

                        </div>
                        <div className="">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Otpverification;