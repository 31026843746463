import React from 'react'

const TableDropdown = (props) => {
    const { height, width} = props;
    return (
        
        <svg width={width ? width : "24"} height={height ? height : "24"} viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.66667 10.166H1.33333C1.09722 10.166 0.899445 10.086 0.74 9.92601C0.58 9.76657 0.5 9.56879 0.5 9.33268C0.5 9.09657 0.58 8.89879 0.74 8.73935C0.899445 8.57935 1.09722 8.49935 1.33333 8.49935H4.66667C4.90278 8.49935 5.10083 8.57935 5.26083 8.73935C5.42028 8.89879 5.5 9.09657 5.5 9.33268C5.5 9.56879 5.42028 9.76657 5.26083 9.92601C5.10083 10.086 4.90278 10.166 4.66667 10.166ZM14.6667 1.83268H1.33333C1.09722 1.83268 0.899445 1.75296 0.74 1.59352C0.58 1.43352 0.5 1.23546 0.5 0.999349C0.5 0.763238 0.58 0.565182 0.74 0.405182C0.899445 0.245738 1.09722 0.166016 1.33333 0.166016H14.6667C14.9028 0.166016 15.1006 0.245738 15.26 0.405182C15.42 0.565182 15.5 0.763238 15.5 0.999349C15.5 1.23546 15.42 1.43352 15.26 1.59352C15.1006 1.75296 14.9028 1.83268 14.6667 1.83268ZM9.66667 5.99935H1.33333C1.09722 5.99935 0.899445 5.91935 0.74 5.75935C0.58 5.5999 0.5 5.40213 0.5 5.16602C0.5 4.9299 0.58 4.73185 0.74 4.57185C0.899445 4.4124 1.09722 4.33268 1.33333 4.33268H9.66667C9.90278 4.33268 10.1008 4.4124 10.2608 4.57185C10.4203 4.73185 10.5 4.9299 10.5 5.16602C10.5 5.40213 10.4203 5.5999 10.2608 5.75935C10.1008 5.91935 9.90278 5.99935 9.66667 5.99935Z" fill="#343434"/>
        </svg>

    )
}

export default TableDropdown