import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { toFormatPrice } from '../../../common/Formateprice';
import MinusIcon from '../../assets/icons/MinusIcon';
import PlusIcon from '../../assets/icons/PlusIcon';
const OrderSummaryBox = (props) => {
    const { t } = useTranslation();
    const { shippinglist, shippingdata, setShippinglist } = props;
    const [editToggle, setEditToggle] = useState(false)
    let localitems = JSON.parse(localStorage.getItem('CartItems'));
    const getQuantity = (index, lable, qty) => {
        let array = [...shippinglist];
        let newValue = [...localitems]
        let indexOf = newValue.findIndex(item=>item.id === array[index].id);
        if (lable === "plus") {
            array[index].qty = qty + 1;
            if(indexOf !== -1){
                newValue[indexOf].qty = qty+1
            }
        } else if (lable === "minus" && qty > 1) {
            array[index].qty = qty - 1
            if(indexOf !== -1){
                newValue[indexOf].qty = qty-1
            }
        }
        setShippinglist(array)
        localStorage.setItem('CartItems', JSON.stringify(newValue));
    }

    return (
        <>
        <div className='orderSummaryCont'>
            <div className='orderSummaryCont-cardwrapper'>
            <h3 className='com-shippingPage-headingMain m-0'>{t("Order Summary")}</h3>
            <div className='orderSummaryCont-cardwrapper-cardholder'>
            {shippinglist?.length ? shippinglist.map((result, key) => {
                return (
                    <>
                    <div className={`orderSummaryCont-cardwrapper-cardholder-card ${result?.diamond? 'mixandmatch' : ''}`} key={key}>
                        <div className="orderSummaryCont-cardwrapper-cardholder-card-imagebox">
                                <img src={result?.main_image} alt="" />
                        </div>
                        <div className="orderSummaryCont-cardwrapper-cardholder-card-detailbox">
                            <div className="orderSummaryCont-cardwrapper-cardholder-card-detailbox-firstBox">
                                <span className="orderSummaryCont-cardwrapper-cardholder-card-detailbox-firstBox-productName">
                                    {result?.name}
                                </span>
                                <div className="orderSummaryCont-cardwrapper-cardholder-card-detailbox-firstBox-content">
                                    <div className="">{result?.SKU}</div>
                                    <div className="">{result?.Metal}</div>
                                    <div className="">{t("Size")}:
                                        <div>{result?.Size}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='orderSummaryCont-cardwrapper-cardholder-card-detailbox-secondCountBox'>
                                <div className={`orderSummaryCont-cardwrapper-cardholder-card-detailbox-secondCountBox-qtyeditor ${!result?.diamond && editToggle ? "" : "disabled"}`}>
                                            <button disabled={result?.qty > 1 ? false : true} onClick={!result?.diamond && editToggle ? () => getQuantity(key, "minus", result?.qty) : null}>
                                                <MinusIcon width={14} height={14} color={!result?.diamond && editToggle ? "#1D1D1F" :'#8A8A8A'} />
                                            </button>
                                            <div className='digit'>{result?.qty}</div>
                                            <button 
                                                onClick={!result?.diamond && editToggle ? () => getQuantity(key, "plus", result?.qty) : null}><PlusIcon width={14} height={14} color={!result?.diamond && editToggle ? "#1D1D1F" :'#8A8A8A'} />
                                            </button>
                                </div>
                                <span className='orderSummaryCont-cardwrapper-cardholder-card-detailbox-secondCountBox-price'>
                                {toFormatPrice(result?.UnFormatPrice * result?.qty, { addSymbol: true })}
                                </span>
                            </div>
                        </div>
                    </div>
                    {result?.diamond ? 
                     <div className="orderSummaryCont-cardwrapper-cardholder-card">
                     <div className="orderSummaryCont-cardwrapper-cardholder-card-imagebox">
                             <img src={result?.diamond?.main_image} alt="" />
                     </div>
                        <div className="orderSummaryCont-cardwrapper-cardholder-card-detailbox">
                            <div className="orderSummaryCont-cardwrapper-cardholder-card-detailbox-firstBox">
                                <span className="orderSummaryCont-cardwrapper-cardholder-card-detailbox-firstBox-productName">
                                    {result?.diamond?.name}
                                </span>
                                <div className="orderSummaryCont-cardwrapper-cardholder-card-detailbox-firstBox-content">
                                    <div className="">{result?.SKU}</div>
                                </div>
                            </div>
                            <div className='orderSummaryCont-cardwrapper-cardholder-card-detailbox-secondCountBox'>
                                <div className={`orderSummaryCont-cardwrapper-cardholder-card-detailbox-secondCountBox-qtyeditor disabled`}>
                                            <button disabled={result?.qty > 1 ? false : true} onClick={result?.diamond ? null :() => getQuantity(key, "minus", result?.qty)}>
                                                <MinusIcon width={14} height={14} color={result?.diamond ? "#8A8A8A" :'#1D1D1F'} />
                                            </button>
                                            <div className='digit'>{result?.qty}</div>
                                            <button 
                                                onClick={result?.diamond ? null :() => getQuantity(key, "plus", result?.qty)}><PlusIcon width={14} height={14} color={result?.diamond ? "#8A8A8A" :'#1D1D1F'} />
                                            </button>
                                </div>
                                <span className='orderSummaryCont-cardwrapper-cardholder-card-detailbox-secondCountBox-price'>
                                {toFormatPrice(result?.diamond?.price * result?.qty, { addSymbol: true })}
                                </span>
                            </div>
                        </div>
                    </div>
                    : ""}
                    </>
                )
            }) : ""}
            </div>
            <div className='orderSummaryCont-amountCalcBox'>
                <div className='orderSummaryCont-amountCalcBox-subTotalBox'>
                    <p className='orderSummaryCont-amountCalcBox-subTotalBox-textCheck'>{t("Subtotal")} ({shippinglist?.length} {t("Items")})</p>
                    <p className='orderSummaryCont-amountCalcBox-subTotalBox-subAmountShow'> {toFormatPrice(shippinglist?.length ? shippinglist.reduce(
                        (accumulator, product) => accumulator + product.UnFormatPrice * product?.qty,
                        0
                    ) : 0, { addSymbol: true })}</p>
                </div>
                <div className='orderSummaryCont-amountCalcBox-subTotalBox'>
                    <p className='orderSummaryCont-amountCalcBox-subTotalBox-textCheck'>{t("Shipping")}</p>
                    <p className='orderSummaryCont-amountCalcBox-subTotalBox-subAmountShow'>  {toFormatPrice(shippingdata?.shipprice, { addSymbol: true })}</p>
                </div>
            </div>
            </div>
            <div className='orderSummaryCont-amountCalcBox m-0'>
                <div className='grandTotalBox'>
                    <p className='grandTotalBox-grandTotalText'>{("Grand Total")}</p>
                    <p className='com-shippingPage-headingMain m-0'> 
                        {toFormatPrice(shippinglist?.length ?
                        shippingdata?.shipprice ? shippingdata?.shipprice + shippinglist.reduce(
                            (accumulator, product) => accumulator + product.UnFormatPrice * product?.qty,
                            0
                        ) : shippinglist.reduce(
                            (accumulator, product) => accumulator + product.UnFormatPrice * product?.qty,
                            0
                        ) : 0 + 0, { addSymbol: true })}
                    </p>
                </div>
            </div>
        </div>
        <div className="shippingPage-main-bothSideBox-rightSide-editBottomBox">
        <div className="com-shippingPage-headingMain mb-4">
            <span className="shippingPage-main-bothSideBox-rightSide-editBottomBox-item">{shippinglist?.length} {t("ITEMS")}</span>
            <span className="shippingPage-main-bothSideBox-rightSide-editBottomBox-edit" onClick={()=>setEditToggle(!editToggle)}>{t("Edit")}</span>
        </div>
    </div>
    </>
    )
}

export default OrderSummaryBox