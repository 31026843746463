import React, { useState, useEffect, useCallback } from "react";
import "./login-signup.scss";
import Select, { components } from "react-select";
import { Link, useNavigate } from 'react-router-dom';
import { _Apiauth } from "../../api/_call";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useTranslation } from 'react-i18next';
const SignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [captchaToken, setCaptchaToken] = useState('');
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [Default_currency, setCurrency] = useState();
  const [country, setCountry] = useState();
  const [completeDataObject, setCompleteObject] = useState({});
  const [passEye, setPassEye] = useState(false);
  const [spinner, setSpinner] = useState(false)
  const [selectvalues, setSelectvalues] = useState()
  const { ValueContainer, Placeholder } = components;
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  const seletctlist = async () => {
    try {
      let postdata = {}
      let rescountry = await _Apiauth(postdata, `Auth/Web/country-list`)
      let rescurrency = await _Apiauth(postdata, `Auth/Web/currency-list`)
      if (rescountry?.code === 200 || rescurrency?.code === 200) {
        setCountry(rescountry?.data)
        setCurrency(rescurrency?.data)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const handleHCaptchaVerify = (token) => {
    setCaptchaToken(token);
  };
  const checkstore = async (value) => {
    try {
      let posstdata = {
        "storeName": value
      }
      let res = await _Apiauth(posstdata, `Auth/Web/store-Valid`)
      if (res?.code === 200) {
        if (res?.status) {
          setCompleteObject(prevState => ({
            ...prevState,
            storeName: value
          }));
          setSelectvalues(prevState => ({
            ...prevState,
            storeName: {
              message: "",
              status: false
            }
          }));
        } else {
          setSelectvalues(prevState => ({
            ...prevState,
            storeName: {
              message: res?.message,
              status: true
            }
          }));
          setCompleteObject(prevState => ({
            ...prevState,
            storeName: ""
          }));
        }
      } else {
        setSelectvalues(prevState => ({
          ...prevState,
          strormessage: false
        }));
        setCompleteObject(prevState => ({
          ...prevState,
          storeName: ""
        }));
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const checkemail = async (value) => {
    try {
      let posstdata = {
        "email": value
      }
      let res = await _Apiauth(posstdata, `Auth/Web/store-email-valid`)
      if (res?.code === 200) {
        if (res?.status === false) {
          setSelectvalues(prevState => ({
            ...prevState,
            email: {
              message: res?.message,
              status: true
            }
          }));
          setCompleteObject(prevState => ({
            ...prevState,
            email: ""
          }));
        } else {
          setCompleteObject(prevState => ({
            ...prevState,
            email: value
          }));
          setSelectvalues(prevState => ({
            ...prevState,
            email: {
              message: "",
              status: false
            }
          }));
        }
      } else {
        setSelectvalues(prevState => ({
          ...prevState,
          email: {
            message: res?.message,
            status: true
          }
        }));
        setCompleteObject(prevState => ({
          ...prevState,
          email: ""
        }));
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const signupgis = useCallback(async () => {
    const handleValidation = (e) => {
      let formIsValid = true;
      if (!completeDataObject?.first_name) {
        formIsValid = false;
        setSelectvalues(prevState => ({
          ...prevState,
          frist_name: {
            message: "Pleace enter frist name",
            status: true
          }
        }));
      } else {
        formIsValid = true;
        setSelectvalues(prevState => ({
          ...prevState,
          frist_name: {
            message: "",
            status: false
          }
        }));
      }
      if (!completeDataObject?.email) {
        formIsValid = false;
        setSelectvalues(prevState => ({
          ...prevState,
          email: {
            message: "Pleace enter email",
            status: true
          }
        }));
      } else if (selectvalues?.emailststus === false) {
        formIsValid = false;
      } else {
        formIsValid = true;
        setSelectvalues(prevState => ({
          ...prevState,
          email: {
            message: "",
            status: false
          }

        }));
      }
      if (!completeDataObject?.storeName) {
        formIsValid = false;
        setSelectvalues(prevState => ({
          ...prevState,
          storeName: {
            message: "Pleace enter store name",
            status: true
          }
        }));
      } else if (selectvalues?.storestatus === false) {
        formIsValid = false;
      } else {
        formIsValid = true;
        setSelectvalues(prevState => ({
          ...prevState,
          storeName: {
            message: "",
            status: false
          }
        }));
      }
      if (!completeDataObject?.organization) {
        formIsValid = false;
        setSelectvalues(prevState => ({
          ...prevState,
          organization: {
            message: "Pleace enter organization",
            status: true
          }
        }));
      } else {
        formIsValid = true;
        setSelectvalues(prevState => ({
          ...prevState,
          organization: {
            message: "",
            status: false
          }
        }));
      }
      if (!completeDataObject?.country) {
        formIsValid = false;
        setSelectvalues(prevState => ({
          ...prevState,
          country: {
            message: "Pleace select country",
            status: true
          }
        }));
      } else {
        formIsValid = true;
        setSelectvalues(prevState => ({
          ...prevState,
          country: {
            message: "",
            status: false
          }
        }));
      }
      if (!completeDataObject?.password) {
        formIsValid = false;
        setSelectvalues(prevState => ({
          ...prevState,
          password: {
            message: "Pleace enter password",
            status: true
          }
        }));
      } else {
        formIsValid = true;
        setSelectvalues(prevState => ({
          ...prevState,
          password: {
            message: "",
            status: false
          }
        }));
      }
      if (completeDataObject?.contacts?.length) {
        if (!completeDataObject?.contacts[0]?.code) {
          formIsValid = false;
          setSelectvalues(prevState => ({
            ...prevState,
            contacts: {
              message: "Pleace enter phonecode",
              status: true
            }
          }));
        } else if (!completeDataObject?.contacts[0]?.no) {
          formIsValid = false;
          setSelectvalues(prevState => ({
            ...prevState,
            contacts: {
              message: "Pleace enter number",
              status: true
            }
          }));
        } else {
          formIsValid = true;
          setSelectvalues(prevState => ({
            ...prevState,
            contacts: {
              message: "",
              status: false
            }
          }));
        }
      } else {
        formIsValid = false;
        setSelectvalues(prevState => ({
          ...prevState,
          contacts: {
            message: "Pleace enter contact",
            status: true
          }
        }));
      }
      if (!completeDataObject?.Default_currency) {
        formIsValid = false;
        setSelectvalues(prevState => ({
          ...prevState,
          Default_currency: {
            message: "Pleace select currency",
            status: true
          }
        }));
      }
      if (!selectvalues?.conditions?.status) {
        formIsValid = false;
        setSelectvalues(prevState => ({
          ...prevState,
          conditions: {
            message: "Pleace choose Terms & Conditions",
            status: selectvalues?.conditions?.status
          }
        }));
        if (!captchaToken) {
          formIsValid = false;
          setSelectvalues(prevState => ({
            ...prevState,
            captcha: {
              message: "Pleace Choose Captcha",
              status: true
            }
          }));
        }
      }
      return formIsValid;
    }
    if (handleValidation()) {
      localStorage.removeItem('UserToken');
      localStorage.removeItem('updateModulePermission');
      localStorage.removeItem('updateUserPermission');
      setSpinner(true)
      let finalobj = { ...completeDataObject }
      finalobj.country = completeDataObject?.country?.value
      try {
        let postdata = finalobj
        let res = await _Apiauth(postdata, `Auth/Web/register`)
        if (res?.code === 200) {
          setSpinner(false)
          if (res?.data?.domain) {
            let rediractDomain = res?.data?.domain;
            const tokenPath = `/authentication/${res?.data?.access_tokens}`;
            if (window.location.hostname !== new URL(rediractDomain).hostname) {
              // Redirect to subdomain or different domain
              const tokenPath_r = `authentication/${res?.data?.access_tokens}`;
              window.location.assign(rediractDomain + tokenPath_r);
            } else {
              // Same domain: use React navigate
              navigate(tokenPath, { state: { domain: rediractDomain } });
            }
          }
          setSelectvalues(prevState => ({
            ...prevState,
            error: {
              message: res?.message,
              status: true
            }
          }));
        } else {
          setSpinner(false)
          if (res?.message) {
            setSelectvalues(prevState => ({
              ...prevState,
              error: {
                message: res?.message,
                status: true
              }
            }));
          } else {
            setSelectvalues(prevState => ({
              ...prevState,
              error: {
                message: res?.errors,
                status: true
              }
            }));
          }

        }
      }
      catch (err) {
        setSpinner(false)
        console.log(err, "err")
      }
    }
    // eslint-disable-next-line
  }, [captchaToken, completeDataObject, selectvalues?.conditions?.status, selectvalues?.emailststus, selectvalues?.storestatus])
  useEffect(() => {
    seletctlist()
    // eslint-disable-next-line
  }, [])
  const handleSelectChange = (code, no) => {
    let obj = { ...completeDataObject }
    setSelectvalues(prevState => ({
      ...prevState,
      code: code,
      no: no
    }));

    let contacts = [{ code: "", no: "", icon: 1 }]
    contacts[0] = { code: code?.phoneCode, no: no, icon: 1 }
    obj.contacts = contacts
    setCompleteObject(obj)
  };
  const selectvaluedata = (keyName, value) => {
    let obj = { ...completeDataObject }
    setSelectvalues(prevState => ({
      ...prevState,
      [keyName]: value
    }));
    if (keyName === "Default_currency") {
      obj.Default_currency = value?.value
    }
    setCompleteObject(obj)
  }
  const onChangeValueUpdate = (keyName, value, type = false) => {
    setCompleteObject(prevState => ({
      ...prevState,
      [keyName]: value
    }));
  }
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        signupgis();
      }
    };
    document.addEventListener('keypress', handleKeyPress);
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [signupgis]);
  const countryOptions = country ? country : [];

  const uniqueSortedCountry = Array.from(
    new Set(countryOptions.map((option) => option.phoneCode))
  ).map((phoneCode) => {
    const { id, value, ...rest } = countryOptions.find((option) => option.phoneCode === phoneCode);
    return { ...rest, value: phoneCode };
  });
  const sortedCountry = [...uniqueSortedCountry].sort((a, b) => parseInt(a.phoneCode, 10) - parseInt(b.phoneCode, 10));
  useEffect(() => {
    let bodyid = document.querySelector('#body_id');
    if (localStorage.getItem("UserLang") === 'SA.png') {
      bodyid.classList.add('rtl');
    } else {
      bodyid.classList.remove('rtl');
    }
  }, [])
  const onChangevalid = (value, label) => {
    if (label === "first_name") {
      if (!value) {
        setSelectvalues(prevState => ({
          ...prevState,
          frist_name: {
            message: "Pleace enter frist name",
            status: true
          }
        }));
      } else {
        setSelectvalues(prevState => ({
          ...prevState,
          frist_name: {
            message: "",
            status: false
          }
        }));
      }
    }
    if (label === "organization") {
      if (!value) {
        setSelectvalues(prevState => ({
          ...prevState,
          organization: {
            message: "Pleace enter organization",
            status: true
          }
        }));
      } else {
        setSelectvalues(prevState => ({
          ...prevState,
          organization: {
            message: "",
            status: false
          }
        }));
      }
    }
    if (label === "country") {
      if (!value) {
        setSelectvalues(prevState => ({
          ...prevState,
          country: {
            message: "Pleace select country",
            status: true
          }
        }));
      } else {
        setSelectvalues(prevState => ({
          ...prevState,
          country: {
            message: "",
            status: false
          }
        }));
      }
    }
    if (label === "phoneCode" || label === "number") {
      if (!value) {
        setSelectvalues(prevState => ({
          ...prevState,
          contacts: {
            message: "enter the right format number Ex:1234567891 or phone code",
            status: true
          }
        }));
      } else {
        setSelectvalues(prevState => ({
          ...prevState,
          contacts: {
            message: "",
            status: false
          }
        }));
      }
    }
  }
  return (
    <div className="main-body bg-white p-0">
      <div className="login-page position-relative d-flex align-items-center justify-content-center py-5 gap-0">
        <img className="login-page-bg position-absolute end-0 bottom-0 w-75" src={base_assets + "images/login-bg.png"} alt="" />
        <div className="d-flex justify-content-center align-items-center w-100 ps-5 signup-mob">
          <div className="login-page-left-col w-85">
            <div className="text-center">
              <Link to="/">
                <img className="login-page-left-col-img" src={base_assets + "images/gis-xl-logo.png"} alt="" />
              </Link>
              <span className="d-block mt-4 fs-4 fw-semibold login-page-left-col-text">{t("Start your journey with us ")} <span className="main-green-text">{t(" today")}</span></span>
            </div>
          </div>
          <div className="login-page-right-col w-100 d-flex justify-content-center">
            <div className="sign-page-right-col">
              <h1 className="login-page-right-col-sub-heading fs-2 fw-semibold">{t("Sign Up")}</h1>
              <div className="login-page-right-col-form">
                <div className="row">
                  <div className="col-md-6">
                    <div className={`placeholder-up-input w-100 form-floating ${selectvalues?.frist_name?.status ? "md-1" : "mb-4"}`}>
                      <input type="text" className={`placeholder-up-input-field form-control fs-6 px-3 ${selectvalues?.frist_name?.status ? "border-danger" : ""}`} placeholder="First Name"
                        value={completeDataObject?.first_name}
                        onChange={(e) => {
                          onChangeValueUpdate("first_name", e.target.value);
                          onChangevalid(e.target.value, "first_name");
                        }} />
                      <label className="placeholder-up-input-label fs-6" htmlFor="">
                        {t("First Name")} <span>*</span>
                      </label>
                    </div>
                    {selectvalues?.frist_name?.status ? <span className="d-block mb-4 text-danger">{selectvalues?.frist_name?.message ? t(selectvalues?.frist_name?.message) : ""}</span> : ""}
                  </div>
                  <div className="col-md-6">
                    <div className={`placeholder-up-input w-100 form-floating ${selectvalues?.last_name?.status ? "md-1" : "mb-4"}`}>
                      <input type="text" className={`placeholder-up-input-field form-control fs-6 px-3 ${selectvalues?.last_name?.status ? "border-danger" : ""}`} placeholder="Last Name"
                        value={completeDataObject?.last_name}
                        onChange={(e) => {
                          onChangeValueUpdate("last_name", e.target.value)
                        }} />
                      <label className="placeholder-up-input-label fs-6" htmlFor="">
                        {t("Last Name")}
                      </label>
                    </div>
                    {selectvalues?.last_name?.status ? <span className="d-block mb-4 text-danger">{selectvalues?.last_name?.message ?
                      t(selectvalues?.last_name?.message) : ""}</span> : ""}
                  </div>
                  <div className="col-md-6">
                    <div className={`placeholder-up-input w-100 form-floating ${selectvalues?.email?.status ? "md-1" : "mb-4"}`}>
                      <input type="email" className={`placeholder-up-input-field form-control fs-6 px-3 ${selectvalues?.email?.status ? "border-danger" : ""}`} placeholder="email"
                        onChange={(e) => checkemail(e.target.value)} />
                      <label className="placeholder-up-input-label fs-6" htmlFor="">
                        {t("Email")} <span>*</span>
                      </label>
                    </div>
                    {selectvalues?.email?.status ? <span className="d-block mb-4 text-danger">{selectvalues?.email?.message ?
                      t(selectvalues?.email?.message) : ""}</span> : ""}
                  </div>
                  <div className="col-md-6">
                    <div className={`placeholder-up-input w-100 form-floating com-pass-eye-field ${selectvalues?.password?.status ? "md-1" : "mb-4"}`}>
                      <input type={passEye ? "text" : "password"} className={`placeholder-up-input-field form-control fs-6 px-3 ${selectvalues?.password?.status ? "border-danger" : ""}`} placeholder="password"
                        value={completeDataObject?.password}
                        onChange={(e) => onChangeValueUpdate("password", e.target.value)} />
                      <i onClick={() => setPassEye(current => !current)} className={passEye ? "fa fa-eye pass-eye com-pass-eye-field-eye" : "fa fa-eye-slash pass-eye com-pass-eye-field-eye"} aria-hidden="true"></i>

                      <label className="placeholder-up-input-label fs-6" htmlFor="">
                        {t("Password")} <span>*</span>
                      </label>
                    </div>
                    {selectvalues?.password?.status ? <span className="d-block mb-4 text-danger">{selectvalues?.password?.message ?
                      t(selectvalues?.password?.message) : ""}</span> : ""}
                  </div>
                  <div className="col-md-12">
                    <div className={`placeholder-up-input w-100 form-floating ${selectvalues?.storeName?.status ? "md-1" : "mb-4"}`}>
                      <input type="text" className={`placeholder-up-input-field form-control fs-6 px-3 ${selectvalues?.storeName?.status ? "border-danger" : ""}`} placeholder="Store Name"
                        onChange={(e) => checkstore(e.target.value)} />
                      <label className="placeholder-up-input-label fs-6" htmlFor="">
                        {t("Store Name")}
                      </label>
                      <span className="placeholder-up-input-inner-position main-green-text position-absolute">.gis247.net</span>
                    </div>
                    {selectvalues?.storeName?.status ?
                      <span className="d-block mb-4 text-danger"> {selectvalues?.storeName?.message ? t(selectvalues?.storeName?.message) : ""}</span> : ""}

                  </div>
                  <div className="col-md-6">
                    <div className={`placeholder-up-input w-100 form-floating ${selectvalues?.organization?.status ? "md-1" : "mb-4"}`}>
                      <input type="text" className={`placeholder-up-input-field form-control fs-6 px-3 ${selectvalues?.organization?.status ? "border-danger" : ""}`} placeholder="Organization"
                        value={completeDataObject?.organization}
                        onChange={(e) => {
                          onChangeValueUpdate("organization", e.target.value);
                          onChangevalid(e.target.value, "organization");
                        }} />
                      <label className="placeholder-up-input-label fs-6" htmlFor="">
                        {t("Organization")} <span>*</span>
                      </label>
                    </div>
                    {selectvalues?.organization?.status ? <span className="d-block mb-4 text-danger">{selectvalues?.organization?.message ? t(selectvalues?.organization?.message) : ""}</span> : ""}
                  </div>
                  <div className="col-md-6">
                    <div className={`placeholder-up-input w-100 form-floating ${selectvalues?.website?.status ? "md-1" : "mb-4"}`}>
                      <input type="text" className={`placeholder-up-input-field form-control fs-6 px-3 ${selectvalues?.website?.status ? "border-danger" : ""}`} placeholder="Website"
                        value={completeDataObject?.website}
                        onChange={(e) => onChangeValueUpdate("website", e.target.value)} />
                      <label className="placeholder-up-input-label fs-6" htmlFor="">
                        {t("Website")}
                      </label>
                    </div>
                    {selectvalues?.website?.status ? <span className="d-block mb-4 text-danger">{selectvalues?.website?.message ?
                      t(selectvalues?.website?.message) : ""}</span> : ""}
                  </div>
                  <div className={`col-md-6 new-select ${selectvalues?.contacts?.status ? "md-1" : ""}`}>

                    <div className={`w-100 phoneBook-mob row m-0 ${selectvalues?.contacts?.status ? "border-danger" : ""}`}>
                      <div className="col-md-4 px-0 mr-1">
                        <Select
                          className="select-set select p-0"
                          placeholder={t("Code")}
                          value={selectvalues?.code}
                          onChange={(e) => {
                            handleSelectChange({ phoneCode: e.phoneCode }, selectvalues?.no);
                            onChangevalid(e.phoneCode, "phoneCode");
                          }}
                          options={sortedCountry}
                          classNamePrefix="common-select"
                          isSearchable={true}
                          getOptionLabel={(opt) => opt.phoneCode}
                        />
                      </div>
                      <div className="px-0 col-md-7 mx-2">
                        <div className="placeholder-up-input form-floating w-100">
                          <input type="number" className="placeholder-up-input-field form-control fs-6 px-3" placeholder="Phone No."
                            value={selectvalues?.no}
                            onChange={(e) => {
                              handleSelectChange(selectvalues?.code, e.target.value);
                              onChangevalid(e.target.value, "number");
                            }} />
                          <label className="placeholder-up-input-label fs-6" htmlFor="">
                            {t("Phone No.")}
                          </label>
                        </div>
                      </div>
                      {selectvalues?.contacts?.status ? <span className="d-block mb-4 text-danger">{selectvalues?.contacts?.message ?
                        t(selectvalues?.contacts?.message) : ""}</span> : ""}
                    </div>

                  </div>
                  <div className={`col-md-6 new-select ${selectvalues?.country?.status ? "md-1" : "mb-4"}`}>
                    <Select
                      className={`select-set select  p-0 ${selectvalues?.country?.status ? "border-danger" : ""}`}
                      placeholder={t("Country")}
                      value={completeDataObject?.country}
                      onChange={(e) => {
                        onChangeValueUpdate("country", { value: e.value, label: e.label });
                        onChangevalid(e.value, "country")
                      }}
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      options={country}
                      classNamePrefix="common-select"
                      isSearchable={true}
                      getOptionLabel={(opt) => opt.label}
                    />
                    {selectvalues?.country?.status ? <span className="d-block mb-4 text-danger">{selectvalues?.country?.message ?
                      t(selectvalues?.country?.message) : ""}</span> : ""}
                  </div>
                  <div className="col-md-12">
                    <div className={`placeholder-up-input w-100 form-floating ${selectvalues?.address?.status ? "md-1" : "mb-4"}`}>
                      <input type="text" className={`placeholder-up-input-field form-control fs-6 px-3 ${selectvalues?.address?.status ? "border-danger" : ""}`} placeholder="Address "
                        value={completeDataObject?.address}
                        onChange={(e) => onChangeValueUpdate("address", e.target.value)} />
                      <label className="placeholder-up-input-label fs-6" htmlFor="">
                        {t("Address")}
                      </label>
                    </div>
                    {selectvalues?.address?.status ? <span className="d-block mb-4 text-danger">{selectvalues?.address?.message ?
                      t(selectvalues?.address?.message) : ""}</span> : ""}
                  </div>
                  <div className="col-md-6">
                    <div className={`placeholder-up-input w-100 form-floating ${selectvalues?.state?.status ? "md-1" : "mb-4"}`}>
                      <input type="text" className={`placeholder-up-input-field form-control fs-6 px-3 ${selectvalues?.state?.status ? "border-danger" : ""}`} placeholder="Province/State"
                        value={completeDataObject?.state}
                        onChange={(e) => onChangeValueUpdate("state", e.target.value)} />
                      <label className="placeholder-up-input-label fs-6" htmlFor="">
                        {t("Province/State")}
                      </label>
                    </div>
                    {selectvalues?.state?.status ? <span className="d-block mb-4 text-danger">{selectvalues?.state?.message ?
                      t(selectvalues?.state?.message) : ""}</span> : ""}
                  </div>
                  <div className="col-md-6">
                    <div className={`placeholder-up-input w-100 form-floating ${selectvalues?.city?.status ? "md-1" : "mb-4"}`}>
                      <input type="text" className={`placeholder-up-input-field form-control fs-6 px-3 ${selectvalues?.city?.status ? "border-danger" : ""}`} placeholder="City"
                        value={completeDataObject?.city}
                        onChange={(e) => onChangeValueUpdate("city", e.target.value)} />
                      <label className="placeholder-up-input-label fs-6" htmlFor="">
                        {t("City")}
                      </label>
                    </div>
                    {selectvalues?.city?.status ? <span className="d-block mb-4 text-danger">{selectvalues?.city?.message ?
                      t(selectvalues?.city?.message) : ""}</span> : ""}
                  </div>
                  <div className="col-md-6">
                    <div className={`placeholder-up-input w-100 form-floating ${selectvalues?.zipcode?.status ? "md-1" : "mb-4"}`}>
                      <input type="text" className={`placeholder-up-input-field form-control fs-6 px-3 ${selectvalues?.zipcode?.status ? "border-danger" : ""}`} placeholder="Zipcode"
                        value={completeDataObject?.zipcode}
                        onChange={(e) => onChangeValueUpdate("zipcode", e.target.value)} />
                      <label className="placeholder-up-input-label fs-6" htmlFor="">
                        {t("Zipcode")}
                      </label>
                    </div>
                    {selectvalues?.zipcode?.status ? <span className="d-block mb-4 text-danger">{selectvalues?.zipcode?.message ?
                      t(selectvalues?.zipcode?.message) : ""}</span> : ""}
                  </div>
                  <div className={`col-md-6 new-select ${selectvalues?.Default_currency?.status ? "md-1" : "mb-4"}`}>
                    <Select
                      className={`select-set select  p-0  ${selectvalues?.Default_currency?.status ? "border-danger" : ""}`}
                      placeholder={`${t("Default Currency")} *`}
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      value={selectvalues?.Default_currency}
                      onChange={(e) => {
                        selectvaluedata("Default_currency", { value: e.value, label: e.label });
                      }}
                      options={Default_currency}
                      classNamePrefix="common-select"
                      isSearchable={true}
                      getOptionLabel={(opt) => opt.label}
                    />
                    {selectvalues?.Default_currency?.status ? <span className="d-block mb-4 text-danger">{selectvalues?.Default_currency?.message ? t(selectvalues?.Default_currency?.message) : ""}</span> : ""}
                  </div>
                </div>

                <p className="login-page-right-col-form-forgot-pass">
                  <input type="checkbox"
                    onChange={(e) => setSelectvalues(prevState => ({
                      ...prevState,
                      conditions: {
                        message: "Pleace choose Terms & Conditions",
                        status: e.target.checked
                      }
                    }))}
                    className="form-check-input d-inline-block me-3 cursor-pointer fill-com-check" />
                  <label className="form-check-label">{t("I agree with")}
                    <span className="text-decoration-none fw-semibold main-green-text"> {t("Terms & Conditions")}</span></label>
                  {!selectvalues?.conditions?.status ? <span className="d-block mb-4 text-danger">{selectvalues?.conditions?.message ?
                    t(selectvalues?.conditions?.message) : ""}</span> : ""}
                </p>
                <div className={`w-100 form-floating ${selectvalues?.captcha?.status ? "md-1 border-danger" : "mb-4"}`}>
                  <HCaptcha
                    sitekey="a305b532-315f-494a-ac96-99b340e1a197"
                    onVerify={handleHCaptchaVerify}
                  />
                  {selectvalues?.captcha?.status ? <span className="d-block mb-4 text-danger">{selectvalues?.captcha?.message ?
                    t(selectvalues?.captcha?.message) : ""}</span> : ""}
                </div>
                {selectvalues?.error?.status ?
                  <span className="d-block mb-4 text-danger">{selectvalues?.error?.message}</span>
                  :
                  ""
                }
                <button className="login-page-right-col-form-login-btn main-green-bg text-white text-center w-100 border-0 rounded-1 fs-6 fw-semibold d-flex align-items-center justify-content-center">
                  {spinner ?
                    <div className="border-bottom-0 border-width-2px d-block text-white mx-auto spinner-border flex-shrink-0" role="status"></div> :
                    <span className="h-100 w-100 justify-content-center align-items-center d-flex" onClick={() => signupgis()}>{t("Sign Up")}</span>
                  }
                </button>
                <p className="login-page-right-col-form-foot text-center mb-0">{t("Already have an account")}?
                  <Link to="/login" className="main-green-text fw-semibold text-decoration-none"> {t("Login")}</Link>
                </p>
              </div>
              <div className="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default SignUp;