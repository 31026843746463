import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import ImageGallery from 'react-image-gallery';
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import PlusIcon from "Ecommerce/template_three/assets/icons/PlusIcon";
import MinusIcon from "Ecommerce/template_three/assets/icons/MinusIcon";
import HeartIcon from "admin/common/icons/HeartIcon";
import Spinner from "Ecommerce/common/Spinner";
import { wishlistobjectheader } from "source/app/appSlice";
import { _Api } from "../../../common/api";
import ArroeIcontop from '../../assets/images/arrowIcontop.png'
import LeftIcon from "admin/common/icons/sidebar/LeftIcon";
import './Productpage.scss'
import SizeGuildeModal from "Ecommerce/template_four/components/SizeGuilde/SizeGuildeModal";
import CartItemsNotify from "Ecommerce/template_four/components/Cart/CartItemsNotify";
import AddCartModal from "Ecommerce/template_four/components/Cart/AddCartModal";
import RecentViewProducts from "Ecommerce/template_four/components/RecentViewProducts";
import CollectionSlider from "Ecommerce/template_four/include/Common/CollectionSlider";
import RightArrow from "admin/common/icons/right-arrow";
import SizeScaleIcon from "admin/common/icons/SizeScaleIcon";
import Calendar from "admin/common/icons/sidebar/Calendar";
import BottomBar from "Ecommerce/template_four/include/Common/BottomBar";
// import BottomBar from "Ecommerce/template_three/include/Common/BottomBar";
// import SizeGuildeModal from "Ecommerce/template_three/components/SizeGuilde/SizeGuildeModal";
// import AddCartModal from "Ecommerce/template_three/components/Cart/AddCartModal";
// import RecentViewProducts from "Ecommerce/template_three/components/RecentViewProducts";
// import CartItemsNotify from "Ecommerce/template_three/components/Cart/CartItemsNotify";

export default function ProductPage() {
    const dispatch = useDispatch();
    const { state } = useLocation()
    const { t } = useTranslation();
    const [quantity, setQuantity] = useState(1);
    const [showdropDown, setShowDropdown] = useState()
    const [productdetails, setProductdetails] = useState()
    const [variantcombo, setVariantcombo] = useState({ metal: '', stone: '', size: '' });
    const [isopennotifycart, setIsopennotifycart] = useState(false)
    const [isopencart, setisopencart] = useState(false);
    const [activeThumbnail, setActiveThumbnail] = useState(1);
    const [loading, setloading] = useState(true)
    const fullPath = useLocation();
    const parts = fullPath.pathname.split('/');
    const params = new URLSearchParams(fullPath.search);
    const vValue = params.get('v');
    let localmywishlist = localStorage.getItem('mywishlist') ? JSON.parse(localStorage.getItem('mywishlist')) : [];
    let localmywishlistremove = localStorage.getItem('mywishlistremove') ? JSON.parse(localStorage.getItem('mywishlistremove')) : [];
    let navigate = useNavigate();
    const [variantdata, setVarientdata] = useState([])
    const [varientloading, setVarientloading] = useState(false)
    const [wishlistdata, setWishliatdata] = useState({ id: "", remove: false, edit: false })
    const setwishlist = () => {
        let setobj = { ...productdetails }
        if (setobj.isWishlist) {
            setobj.isWishlist = 0
            let removewislistarr = [...localmywishlistremove]
            removewislistarr.push(setobj?._id)
            const uniqueIdsSet = new Set(removewislistarr);
            let unique_ids = Array.from(uniqueIdsSet);
            localStorage.setItem('mywishlistremove', JSON.stringify(unique_ids ? unique_ids : []));
            if (localmywishlist?.length) {
                const updatedData = localmywishlist.filter(id => id !== setobj?._id);
                localStorage.setItem('mywishlist', JSON.stringify(updatedData ? updatedData : []));
                dispatch(wishlistobjectheader(updatedData));
            }
        } else {
            setobj.isWishlist = 1
            let addwislistarr = [...localmywishlist]
            addwislistarr.push(setobj?._id)
            const uniqueIdsSet = new Set(addwislistarr);
            let unique_ids = Array.from(uniqueIdsSet);
            localStorage.setItem('mywishlist', JSON.stringify(unique_ids ? unique_ids : []));
            dispatch(wishlistobjectheader(unique_ids));
            if (localmywishlistremove?.length) {
                const updatedData = localmywishlistremove.filter(removeid => removeid !== setobj?._id);
                localStorage.setItem('mywishlistremove', JSON.stringify(updatedData ? updatedData : []));
            }
        }
        setProductdetails(setobj)
    }
    const getproductdetails = async () => {
        setloading(true)

        let localRecentViewed = localStorage.getItem('RecentViewed') ? JSON.parse(localStorage.getItem('RecentViewed')) : [];
        setTimeout(() => {
            if (!localRecentViewed.filter(e => e === state?.id).length) {
                localRecentViewed.push(state?.id);
                localStorage.setItem('RecentViewed', JSON.stringify(localRecentViewed));
            }
        }, 15000)

        try {
            let postdata = {
                id: parts[2],
                "wishlistproduct": localmywishlist,
                "wishlistremove": localmywishlistremove,
            }
            let res = await _Api(postdata, `product/getProductDetails`)
            if (res?.statusCode === 200) {
                setProductdetails(res?.data);
                if (!vValue) {
                    const combo = { metal: res?.data?.metal?.length ? res?.data?.metal[0] : '', stone: res?.data?.stone?.length ? res?.data?.stone[0] : '', size: '' }
                    setVariantcombo(combo);
                }
            }
            setloading(false)
        }
        catch (err) {
            console.log(err, "err")
        }
    }

    const getvarient = async () => {
        setVarientloading(true)
        try {
            let postdata = {
                id: parts[2],
                "wishlistproduct": localmywishlist,
                "wishlistremove": localmywishlistremove,
            }
            let res = await _Api(postdata, `product/get-product-variants`)
            if (res?.statusCode === 200) {
                setVarientdata(res?.data)
                if (vValue) {
                    if (res?.data?.length) {
                        res?.data?.forEach((result) => {
                            if (vValue === result?._id) {
                                const combo = { metal: result?.metal_name ? result?.metal_name : '', stone: result?.stone_name ? result?.stone_name : '', size: result?.size_name ? result?.size_name : '' }
                                setVariantcombo(combo);
                            }
                        });
                    }
                }
                setVarientloading(false)
            } else {
                setVarientloading(false)
            }
        }
        catch (err) {
            console.log(err, "err")
        }

    }
    const combination_variant = (variant, data) => {
        let variant_sku = '';
        if (variant === 'metal') {
            variant_sku = productdetails?.SKU + data?.value + (variantcombo?.stone ? variantcombo?.stone?.value : '') + '-' + (variantcombo?.size ? variantcombo?.size?.value : '');
            setVariantcombo({ ...variantcombo, metal: data });
        }
        if (variant === 'stone') {
            variant_sku = productdetails?.SKU + (variantcombo?.metal ? variantcombo?.metal?.value : '') + data?.value + '-' + (variantcombo?.size ? variantcombo?.size?.value : '');
            setVariantcombo({ ...variantcombo, stone: data });
        }
        if (variant === 'size') {
            let allsize = document.querySelectorAll(".sizeselect");
            allsize.forEach(sizze => {
                sizze.classList.remove('selectedSize');
                if (sizze.textContent === data?.label) {
                    sizze.classList.add('selectedSize');
                }
            });
            variant_sku = productdetails?.SKU + (variantcombo?.metal ? variantcombo?.metal?.value : '') + (variantcombo?.stone ? variantcombo?.stone?.value : '') + '-' + data?.value;
            setVariantcombo({ ...variantcombo, size: data });
        }
        if (variant_sku) {
            let variant = variantdata?.length ? variantdata.filter(e => e?.SKU === variant_sku)[0] : '0.00';
            document.getElementById("variantprice").textContent = variant?.price;
            document.getElementById("variantprice").setAttribute('data-caritemid', variant?._id);
            if (variant?.main_image) {
                let imagearray = [{
                    "original": variant?.main_image,
                    "thumbnail": variant?.main_image
                }];
                if (productdetails?.images?.length) {
                    const newArr2 = [...productdetails?.images];
                    newArr2[0] = imagearray[0];
                    setProductdetails({
                        ...productdetails,
                        images: newArr2
                    });
                }

            }
        }
        console.log(variantcombo , "check variant combo")
    }
    const addtocart = () => {
        const itemid = document.getElementById("variantprice").getAttribute('data-caritemid');
        if (itemid) {
            let localitem = localStorage.getItem('CartItems') ? JSON.parse(localStorage.getItem('CartItems')) : [];
            let checkitem = localitem.filter(e => e?.id === itemid);
            if (checkitem?.length) {
                localitem.forEach((e) => {
                    if (e?.id === itemid) {
                        e.qty += parseInt(quantity);
                    }
                });
                localStorage.setItem('CartItems', JSON.stringify(localitem));
            } else {
                localitem.push({ 'id': itemid, 'qty': quantity });
                localStorage.setItem('CartItems', JSON.stringify(localitem));
            }
            setIsopennotifycart(true);

        }

    }
    useEffect(() => {
        if (state?.id || parts[2]) {
            getproductdetails();
            getvarient()
        } else {
            navigate("/");
        }
        // eslint-disable-next-line 
    }, [state?.id, vValue, parts[2]])

    const handleThubnailclick = (i) =>{
        setActiveThumbnail(i+1)
    }

  return (
    <>
    {
        loading ?
            (<div className=" w-100 vh-100 d-flex justify-content-center align-items-center" >
                <Spinner />
            </div >) : (


        <div className="ecommerce-main-layout productpagefour">
                            <div className="productpagefour-header">
                                <span>{t("Home")}</span>
                                <span className="iconholder"><RightArrow/></span>
                                <span>{t("Categories")}</span>
                                <span className="iconholder"><RightArrow/></span>
                                <span className="light">{t("All Categories")}</span>
                            </div>
        <div className="productpagefour-product-details">
            <div className="productpagefour-product-details-image-section">
                <ImageGallery
                    items={productdetails?.images ? productdetails?.images : []}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    showNav={false}
                    infinite={true}
                    disableSwipe={false}
                    disableThumbnailSwipe={false}
                    additionalClass="img-view-slider"
                />
            </div>
    <div className="productpagefour-product-details-section">
        <div className="productpagefour-product-details-section-product-details">
            <div className={`productpagefour-product-details-section-product-details-name d-flex justify-content-between align-items-center ${productdetails?.isWishlist ? "likeHeartBtn" : ""}`}>
                {productdetails?.name}
                {/* <div className="d-inline-block" onClick={() => setwishlist()}>
                    <HeartIcon />
                </div> */}
            </div>
            <span className="productpagefour-product-details-section-product-details-serialNumber">
                {productdetails?.SKU}
            </span>
            <div className="productpagefour-product-details-section-product-details-collectionName d-flex justify-content-between align-items-center">
                {productdetails?.Matatag}
            </div>
        </div>
        <span className="productpagefour-product-details-section-product-price " id="variantprice" data-caritemid={productdetails?._id} >
            {productdetails?.price} <span className="small">{t("Tax included")}.</span>
        </span>
        <div className="productpagefour-product-details-section-product-addtowishlist">
            <span>
                <div className="d-inline-block" onClick={() => setwishlist()}>
                    <HeartIcon />
                </div>
            </span> 
            <span className="">{t("Add To Wish List")}</span>
        </div>
        {varientloading ? (<div style={{ marginTop: '20px', marginBottom: '20px' }} >
            <Spinner />
        </div>) : (
            <div>
                {productdetails?.metal?.length ?
                    <div className="productpagefour-product-details-section-product-attributes">
                        <label>{t("Metal")}</label>
                        <Select
                            className=""
                            options={productdetails?.metal}
                            value={variantcombo?.metal}
                            onChange={(e) => combination_variant('metal', e)}
                            classNamePrefix="newDropdownDark"
                            isSearchable={false}

                        />
                    </div>
                    : ''}
                {productdetails?.stone?.length ?
                    <div className="productpagefour-product-details-section-product-attributes">
                        <label>{t("Stone")}</label>
                        <Select
                            className=""
                            options={productdetails?.stone}
                            value={variantcombo?.stone}
                            onChange={(e) => combination_variant('stone', e)}
                            classNamePrefix="newDropdownDark"
                            isSearchable={false}

                        />
                    </div>
                    : ""}

                {productdetails?.size?.length ?
                    <>
                        <div className="productpagefour-product-details-section-product-attributes">
                            <div className="d-flex justify-content-between align-items-center">
                                <label>{t("Size")} : {variantcombo?.size ? variantcombo?.size?.label : ""}</label>
                                <button data-bs-toggle="modal" data-bs-target="#sizeGuideBack" className="size-guide"> <span><SizeScaleIcon/></span> <span> {t("Sizing Guide")}</span></button>
                            </div>
                            <div className="productpagefour-product-details-section-product-attributes-ringSizes-container d-flex flex-wrap align-items-center">
                                {productdetails?.size?.length ? productdetails?.size.map((result, key) => {
                                    return (
                                        <span key={key} onClick={() => combination_variant('size', result)} className={variantcombo?.size?.label === result?.label ? "d-inline-flex align-items-center justify-content-center sizeselect selectedSize" : "d-inline-flex align-items-center justify-content-center sizeselect"}>
                                            {result?.label}
                                        </span>
                                    )
                                }) : ""}
                            </div>
                        </div>
                    </>
                    : ""}
            </div>
        )}
        <div className="d-flex align-items-end gap-4">
        <div className="productpagefour-product-details-section-product-attributes">
            <label className="productpagefour-product-details-section-product-attributes-quantityLabel">{t("Quantity")}</label>
            <div className="productpagefour-product-details-section-product-attributes-qutantityCounter">
                <button disabled={quantity > 1 ? false : true} onClick={() => { setQuantity(quantity - 1) }} className="minusCountBtn">-</button>
                <span className="d-inline-block text-center quantityShow">{quantity}</span>
                <button onClick={() => setQuantity(quantity + 1)} className="plusCountBtn">+</button>
            </div>
        </div>
        <span className="instocktext"> 
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 12L11.9497 16.9497L22.5572 6.34326M2.0498 12.0503L6.99955 17M17.606 6.39355L12.3027 11.6969" stroke="#2F9E44" strokeLinecap="round" strokeLinejoin="round" />
            </svg> 
            <span>{("24 Items In Stock")}</span>
        </span>
        </div>

        <div className="productpagefour-product-details-section-product-attributes buttons">
            <button disabled={!productdetails?.size?.length ? (!variantcombo?.size ? false : true) : (!variantcombo?.size ? true : false)}
                onClick={addtocart} className="productpagefour-product-details-section-addToCartBtn">
                {t("Add to Cart")}
            </button>
            <button disabled={!productdetails?.size?.length ? (!variantcombo?.size ? false : true) : (!variantcombo?.size ? true : false)}
                onClick={addtocart} className="themeBtn productpagefour-product-details-section-buynow">
                {t("BUY IT NOW")}
            </button>
        </div>
        <div className="productpagefour-product-details-section-product-attributes" onClick={()=>navigate('/appointment')}>
            <button className="darkbtn"> 
                <Calendar withclock="true"/>
                <span>{t(" Schedule store appointment")}</span>
            </button>
        </div>

        <div className={`productpagefour-product-details-section-product-discription ${showdropDown && "border-line"} `}>
            <div className="d-flex justify-content-between align-items-center title" onClick={() => {showdropDown !== 0 ? setShowDropdown(0) : setShowDropdown(null)}}>
                <label>{t("Description")}</label>
                {showdropDown === 0 ? <MinusIcon width={20} height={20} /> :
                    <PlusIcon width={20} height={20} />}
            </div>
            {showdropDown === 0 ? <div className="productpagefour-product-details-section-product-discription-text">
                {productdetails?.Description}
            </div> : null}
        </div>
        <div className={`productpagefour-product-details-section-product-productdetails ${showdropDown && "border-line"} `}>
            <div className="d-flex justify-content-between align-items-center title" onClick={() => {showdropDown !== 1 ? setShowDropdown(1) : setShowDropdown(null)}}>
                <label>{t("Product Details")}</label>
                {showdropDown === 1 ? <MinusIcon width={20} height={20} /> :
                    <PlusIcon width={20} height={20} />}
            </div>
            {showdropDown === 1? 
                <div className="productpagefour-product-details-section-product-productdetails-data">
                    <div className="productpagefour-product-details-section-product-productdetails-wrapper"><span>{t("Material")}</span><span>{variantcombo?.metal ? variantcombo?.metal?.label : "-"}</span></div>
                    <div className="productpagefour-product-details-section-product-productdetails-wrapper"><span>{t("Stone Weight")}</span><span>{variantcombo?.stone ? variantcombo?.stone?.value : "-"}</span></div>
                    <div className="productpagefour-product-details-section-product-productdetails-wrapper"><span>{t("Reference Number")}</span><span></span></div>
                </div> 
            : null}
        </div>
    </div>
</div>

<div className="ecommerce-main-layout-recent_view-product">
    <RecentViewProducts currentid={state?.id}
        setWishliatdata={setWishliatdata} wishlistdata={wishlistdata} />
</div>
<div>
    <BottomBar/>
</div>
<AddCartModal setisopencart={setisopencart} isopencart={isopencart} />
{isopennotifycart ? <CartItemsNotify isopennotifycart={isopennotifycart} setIsopennotifycart={setIsopennotifycart} setisopencart={setisopencart} /> : ''}
                <SizeGuildeModal/>
</div >
            )}
</>
  )
}
              