import React, { useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import './HomePage.scss'
import { _Api } from "../../common/api";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import SaleContent from "../include/Common/SaleContent";
import CollectionBlock from "../include/Common/CollectionBlock";
import CollectionSlider from "../include/Common/CollectionSlider";
import RightArrowIcon from "../assets/icons/RightArrowIcon";
import BottomBar from "../include/Common/BottomBar";
import { useNavigate } from "react-router-dom";

const HomePage = (props) => {
    const { t } = useTranslation();
    const [bannerList, setBannerList] = useState([]);
    const navigate = useNavigate();

    const jwelledata = [
        { image:"https://s3-alpha-sig.figma.com/img/34c9/9d9b/37959a601d110e620cbe4b85ce69f7ce?Expires=1728864000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=I10jA~seoi-J0j4bcv07P38nCXDivuS5dVvF~yvQjETN6ILiCJUxKa0hWhfTjrXpECz93FUk1sVfvPBR2vSPAEi6htDf0mSaaPowSpNN6iLkJrzigjYis5lcSErepaj0AaJ7xWlUz3J2D3rf69OJTWNl7LS~jD4zIq1K~YdnjYy~6HGQk2ikY3DYjW~FePZ-muhMmv7ZJBBg5CzWE81fqTBOInOHkwP~hdodFYah8TuTc8oWvmz4PPEnULVHOOfolhtGUjW0bxxRfKLtOQMeOGz2Ez5tZZdTVTw1Pg~mZALHWlcqUtcf8HPps9lUkYZSTas58MlK7taa--vbsGqAoQ__", title: "Rings" },
        { image:"https://s3-alpha-sig.figma.com/img/c0e8/83ec/141a705304c00d1cef8ef704b7a10c16?Expires=1728864000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ga1LziH~PKtI-6Z6QWWXQQj39CCeHbr0XYfavOBlfwzscbCVh921h-Pz71s2baQiCzx8JDifdVrKwFZ2knYTnR6p4oBTK9pbwUvJ7x3c7O1u-f5Q6fA2BJaSp5ru6Dr7timj~QcryTn8X6lPw3eBKZBQGKmQQj4Pr14gsMBNC3X4U0hBS-oceuMhtSM2E-rNx3BJEbEfSJMD3vT6lCna-YJwn~reefqn713epyreZhubnpfPNtRDXtQRS3rI5ndMbVd6nEtYgrnLJpzqGyX1qyjzAk-Zt53LAbJGJCyjbqcrJ0bY52Nm5ZoDBYPy~0h2rDtp1YYvaNGmNZevs583MQ__", title: "Earrings" },
        { image:"https://s3-alpha-sig.figma.com/img/ca29/1de2/57e5f1682dfc994d3881eab95873cc53?Expires=1728864000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=VmBzpT9HVgLObtugOTo1OF33X66lqDvsgv0lIJFoCfyCWBUBW6RE5HLyEh-M9J2vzyvyhRsaK7IhDON03ig4ky96BCxDXFVjPtsRiIXN1pdKYPvOPGNT0mfOhnv39rxzuxiIqugaXZvgow5~lIU-xlZdEp8RHniCuHNpcKeNuj5LbchKWa0dQbSsS7pX~c6ewTMOTWlDDzOZmvjdXRXRuByjONJP9pvg7YXAAYvjCzQqjFploESvZTB9rHnVmv1i6dFCMBfJytxVI1z00xKrM2H3p4AQJnYgFq-Ml3MRahWF6WIKdVWTOGRK2mgaTYQpyqGIyYI7j8W~MAP0HD2nCg__", title: "Bracelate" },
        { image:"https://s3-alpha-sig.figma.com/img/4dc7/0079/99dafd40328c8714346b02311cc856ce?Expires=1728864000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bEEFtO6hBZh0AltQD9WBAgabK4Siz6AoRMjOsivMCjXCA9kbBvwbylH9My3z~9oldUZylOUz0OD2hpXWoSyN-YyFtDdPUdPjM9NWQJDGOMEr6KKC8RFKSYVR2Fb6MDm3nLbZM1Yt3ebzUzhvAyPYouXSxAnt5LwVMXupBJ57zPpaHZnHaHdrv7GVLKLvn9GaiRF45r1RL6kgYBNO21w8mDFfKqt8Ymz8JcEUpd33dQCeVw0A7CmUqg3CE4hf-XFt~wiSNdSnEHBIKPHYMmrwcgR0cQqv5FaiDibDY5spi~eP2~GhU-7o4F0kV-p7Dv4DOT~lIezrVfqtFvFGi3qs7A__", title: "Necklace" },
        { image:"https://s3-alpha-sig.figma.com/img/6374/2b11/480cfb5c880d6923e9c9e57fac993d50?Expires=1728864000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=JfXFHChS-e-h-Qyufd-7bLoTW6zuprWGD2b~v6MziRJ1asNn4fqMIh9hoSUnAnAP6yNtjCOjzZnmVQuSSRaX8qdkTKiGUyr8s-ODjiMzCsmHID5Q1gjMi6EtIonZhyovj8QAQS-eh6ie2WVLLbWQ-tNibPZ62eiY0sleg6SaTt8d51s2SNgCshs26VNWtpzS8VHudc25aYic8cJBWZhdDXsnuRLfCzJgy-KVGIYmQLDwt0O4k-SIlZk7fbKfM0WudDFtjMMnn9Oto~nTmRFyjhpAKWYGACuxnvGr8yEARklXroCmnu~7GIt7ZoLrDiQpGWTotko20at8CppnG3rsnQ__", title: "Anklets" },
    ]
    const bestSellersData = [
       "Rings", "Errings", "Bracelets" 
    ]

    useEffect(()=>{
        getBanners();
    },[]);

    const getBanners = async() =>{
        try{
            let postdata = {}
            let res = await _Api(postdata, `Cms/get-ecom-bannerdata`);
            if(res.status === 200){
                setBannerList(res.data)
                console.log(res, "Here is response")
            }
        }
        catch(err){
            console.log(err, "Error")
        }
    }
    var settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        fade: true,
      };
    return (
        <>
            <div className={'ecommerce-main-layout-bodyContent position-relative'}>
                {/* product filter */}
                <div className="ecommerce-homepage">
                    {/* <SubHeader/> */}
                    <div className="ecommerce-homepage-top_banner">
                        <Slider {...settings}>
                           {bannerList?.length ? bannerList.map((bannerItem, key)=>{
                            return(
                                <div className="banner_holder" key={key}>
                                    <img src={bannerItem.Imageurl} alt="banner"/>
                                </div>
                            )
                           }) : 
                           <div className="banner_holder">
                                <img src="https://s3-alpha-sig.figma.com/img/7fe2/db8a/35619450b344f2dfd54a380e5621a76b?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Wf75sikDnYs~4WtD04Dvb4gF2UkiwGzk0ipl3h9AisH28dJ6AEDph2WjfWc7K-MDe7OC0UlVWtOMzFMNJVVy5G9mjSb~9rspnu3OBwLpR0VRwYfQMYUaBfklijTVJZFCmvWbqDsqN0vowO86jekeBN7-SwKcNNPuwY6C1q-O9RnH7MBL4oqUnMvsBAvkWipPggaZ5cTO1Nusc4QErsQr-CFGj903p6P0kXF4nKeUmr7wE7L-sEiJdOokh4AYfedZj-3828oOSf3nROT-i3VFeb0JDxNZzuwoD5HgFBGjQfwipt5CDe7pE4vvQBiBsbocLy6ibl9gyOWGJ2Hi-t2kpA__" alt="defaultbanner"/>
                            </div>
                            }
                        </Slider>
                        <div className="ecommerce-homepage-top_banner-icon ">
                            <RightArrowIcon/>
                        </div>
                    </div>
                    <div className="ecommerce-homepage-salesection">
                           <SaleContent/>
                    </div>
                    <div className="ecommerce-homepage-jwelle_container">
                        <div className="ecommerce-homepage-jwelle_container-inner">
                           { jwelledata.length ? jwelledata.map((item, i)=>{
                            return(
                                <div className="ecommerce-homepage-jwelle_container-inner-block">
                                    <img src={item?.image} alt="jewell"/>
                                    <span className="ecommerce-homepage-jwelle_container-inner-block-label">{item?.title}</span>
                                </div>
                            )
                           })
                           : null
                           }
                           </div>
                    </div>
                    <div className="ecommerce-homepage-collections">
                           <CollectionBlock/>
                    </div>
                    <div className="ecommerce-homepage-bestsellers">
                        <div className="ecommerce-homepage-bestsellers-title">{t("Best Sellers")}</div>
                        <div className="ecommerce-homepage-bestsellers-items">
                           { bestSellersData.length ? bestSellersData.map((item, key)=>{
                            return(
                                <div className="ecommerce-homepage-bestsellers-items-button">
                                    {t(`${item}`)}
                                </div>
                            )
                           })
                           :null
                           }
                        </div>
                    </div>
                    <div className="ecommerce-homepage-exploreallbtn">
                        <button onClick={()=>navigate("/collection")}>{t("Explore All")}</button>
                    </div>

                    <div className="ecommerce-homepage-home_collectionslider">
                        <div className="ecommerce-homepage-home_collectionslider-title">
                           {t("Love to see more collections")}
                        </div>
                           <CollectionSlider/>
                    </div>
                    <BottomBar withTitle={true}/>
                    {/* <div className="ecommerce-homepage-bottombar">
                        <div className="ecommerce-homepage-bottombar-title">
                            {t("Our Services")}
                            </div>
                        <div className="ecommerce-homepage-bottombar-inner">
                            
                            {bottomdata.length ? bottomdata.map((item, i)=>{
                                return(
                                    <div className={`ecommerce-homepage-bottombar-inner-container ${i === 0 ? "" : "hasborder"}`}>
                                        <span className="ecommerce-homepage-bottombar-inner-container-icon">{ item.icon ? item.icon : <SearchIcon/>}</span>
                                        <div className="ecommerce-homepage-bottombar-inner-container-content">
                                            <span className="ecommerce-homepage-bottombar-inner-container-content-title">{item.title}</span>
                                            <span className="ecommerce-homepage-bottombar-inner-container-content-subtitle">{item.subtitle}</span>
                                        </div>
                                    </div>
                                )
                            }) : null}
                            
                        </div>
                    </div> */}
                </div>
               
            </div >
        </>
    );
}
export default HomePage;