import { _Api } from 'Ecommerce/common/api';
import Sidebar from './Sidebar'
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TableDropdown from 'Ecommerce/template_three/assets/icons/TableDropDown';
import RightSideArrow from 'Ecommerce/template_three/assets/icons/RightSideArrow';
import useScreenSize from 'Ecommerce/template_three/include/Common/useScreenSize';
import { useNavigate } from 'react-router-dom';
import LeftIcon from 'admin/common/icons/sidebar/LeftIcon';
import CrossIcon from 'admin/common/icons/CrossIcon';
// import RightSideArrow from "../../assets/icons//RightSideArrow";
// import TableDropdown from "../../assets/icons/TableDropDown";
// import { _Api } from "../../../common/api";

export default function AccountPurchaseHistory() {
  const { t } = useTranslation();
  const screenSize = useScreenSize();
  const navigate = useNavigate();
  const [purchaselist, setPurchaselist] = useState([])
  const [lowercount, setLowercount] = useState()
  const [showSubTable, setShowsubTable] = useState(null)
  const [subtabledata, setsubTabledata] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState();
  const openSubTable = async (key, cartid) => {
    setShowsubTable(showSubTable === key ? null : key);
    try {
      let postdata = {
        cart_ids: cartid
      }
      let res = await _Api(postdata, `account/getSubTabel`)
      if (res?.statusCode === 200) {
        setsubTabledata(res?.data)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const getpurchasedata = async () => {
    try {
      let postdata = {}
      let res = await _Api(postdata, `account/purchase-history`)
      if (res?.statusCode === 200) {
        setPurchaselist(res?.data)
        setLowercount(res?.count)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  useEffect(() => {
    getpurchasedata()
  }, [])
  return (
    <div className="account_page d-flex ecommerce-main-layout-bodyContent">
    <Sidebar/>
    <div className="account_details">
        {screenSize.width < 525 ?
        <div className="account_details_topbar">
            <div className='account_details_topbar-icon' onClick={()=>navigate('/account')}>
              <LeftIcon/>
            </div>
            <span>{t("Purchase History")}</span>
        </div>
        :null}
        {screenSize.width < 1200 && screenSize.width > 525 ?
        <div className="account_details_desktoptopbar">
            <div className='account_details_desktoptopbar-icon' onClick={()=>navigate('/account')}>
              <LeftIcon/>
            </div>
            <span>{t("Purchase History")}</span>
        </div>
        :null}
        <div className="address_appointment purchase_history">
          <div className="address_appointment-headBox">
            <h2>{t("Purchase History")}</h2>
            <h6>{t("View your purchase history")}.</h6>
          </div>
          <div className="com-ecomTableMain">
            <table
              id="appointmentdata"
              className="com-ecomTableMain-table table align-middle m-0"
            >
              <thead className="com-ecomTableMain-table-thead">
                <tr className="text-nowrap align-middle">
                  <th className="user-name-width ">
                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                      {t("Product Name")}
                      <div className="dropdown com-ecomTableMain-table-thead-singleRowHead-dropdownBox">
                        <div
                          className={
                            "dropdown-menu com-ecomTableMain-table-thead-singleRowHead-dropdownBox-dropdownMenu border-0 overflow-hidden"
                          }
                        >
                          <div className="dropdown-content">
                            {t("select")}
                          </div>
                          <div className="dropdown-content">
                            <input type="text" className="dropdown-content-inputBox" placeholder="Search..." />
                          </div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="user-name-width ">
                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                      {t("Date Time")}
                      <div className="dropdown com-ecomTableMain-table-thead-singleRowHead-dropdownBox">
                        <div
                          className={
                            "dropdown-menu com-ecomTableMain-table-thead-singleRowHead-dropdownBox-dropdownMenu border-0 overflow-hidden"
                          }
                        >
                          <div className="dropdown-content">
                            {t("select")}
                          </div>
                          <div className="dropdown-content">
                            <input type="text" className="dropdown-content-inputBox" placeholder="Search..." />
                          </div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="user-name-width ">
                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                      {t("Purchase No.")}
                      <div className="dropdown com-ecomTableMain-table-thead-singleRowHead-dropdownBox">
                        <button
                          className="after-none bg-transparent border-0 dropdown-toggle p-0 "
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-auto-close="outside"
                        >
                          <span className="dropdownToggle_img"><TableDropdown width={15} height={10} /></span>
                        </button>
                        <div
                          className={
                            "dropdown-menu com-ecomTableMain-table-thead-singleRowHead-dropdownBox-dropdownMenu border-0 overflow-hidden"
                          }
                        >
                          <div className="dropdown-content">
                            {t("select")}
                          </div>
                          <div className="dropdown-content">
                            <input type="text" className="dropdown-content-inputBox" placeholder="Search..." />
                          </div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="user-name-width ">
                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                      {t("SKU")}
                      <div className="dropdown com-ecomTableMain-table-thead-singleRowHead-dropdownBox">
                        <button
                          className="after-none bg-transparent border-0 dropdown-toggle p-0 "
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-auto-close="outside"
                        >
                          <span className="dropdownToggle_img"><TableDropdown width={15} height={10} /></span>
                        </button>
                        <div
                          className={
                            "dropdown-menu com-ecomTableMain-table-thead-singleRowHead-dropdownBox-dropdownMenu border-0 overflow-hidden"
                          }
                        >
                          <div className="dropdown-content">
                          {t("select")}
                          </div>
                          <div className="dropdown-content">
                            <input type="text" className="dropdown-content-inputBox" placeholder="Search..." />
                          </div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th></th>
                  <th className="user-name-width ">
                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                      {t("Qty")}
                      <div className="dropdown com-ecomTableMain-table-thead-singleRowHead-dropdownBox">
                        <button
                          className="after-none bg-transparent border-0 dropdown-toggle p-0 "
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-auto-close="outside"
                        >
                          <span className="dropdownToggle_img"><TableDropdown width={15} height={10} /></span>
                        </button>
                        <div
                          className={
                            "dropdown-menu com-ecomTableMain-table-thead-singleRowHead-dropdownBox-dropdownMenu border-0 overflow-hidden"
                          }
                        >
                          <div className="dropdown-content">
                          {t("select")}
                          </div>
                          <div className="dropdown-content">
                            <input type="text" className="dropdown-content-inputBox" placeholder="Search..." />
                          </div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="user-name-width ">
                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                      {t("Payment Type")}
                      <div className="dropdown com-ecomTableMain-table-thead-singleRowHead-dropdownBox">
                        <button
                          className="after-none bg-transparent border-0 dropdown-toggle p-0 "
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-auto-close="outside"
                        >
                          <span className="dropdownToggle_img"><TableDropdown width={15} height={10} /></span>
                        </button>
                        <div
                          className={
                            "dropdown-menu com-ecomTableMain-table-thead-singleRowHead-dropdownBox-dropdownMenu border-0 overflow-hidden"
                          }
                        >
                          <div className="dropdown-content">
                          {t("select")}
                          </div>
                          <div className="dropdown-content">
                            <input type="text" className="dropdown-content-inputBox" placeholder="Search..." />
                          </div>

                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="user-name-width ">
                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                      {t("Amount")}
                      <div className="dropdown com-ecomTableMain-table-thead-singleRowHead-dropdownBox">
                        <button
                          className="after-none bg-transparent border-0 dropdown-toggle p-0 "
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-auto-close="outside"
                        >
                          <span className="dropdownToggle_img"><TableDropdown width={15} height={10} /></span>
                        </button>
                        <div
                          className={
                            "dropdown-menu com-ecomTableMain-table-thead-singleRowHead-dropdownBox-dropdownMenu border-0 overflow-hidden"
                          }
                        >
                          <p className="singleSelectdropdownTable">
                            {t("Sorting Ascending")}
                          </p>
                          <p className="singleSelectdropdownTable">
                            {t("Sorting Descending")}
                          </p>
                          <div className="dropdown-content dropdownSearchBoxtable">
                            <input type="text" className="dropdown-content-inputBox" placeholder="Search..." />
                          </div>
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className="d-flex gap-3 align-items-center ">
                              <div className="com-ecomCheckBox">
                                <input type="checkbox" className="itemscheckbox com-ecomCheckBox-inputcheck me-2 cursor-pointer" id='check111' />
                                <label className="com-ecomCheckBox-labelBox cursor-pointer fw-semibold" htmlFor='check111'></label>
                              </div>
                              <div>{t("Select All")}</div>
                            </div>
                            <span className="d-flex ">{t("Count")}</span>
                          </div>
                          <div className="d-flex gap-3 align-items-center ms-2">
                            <div className="com-ecomCheckBox">
                              <input type="checkbox" className="itemscheckbox com-ecomCheckBox-inputcheck me-2 cursor-pointer" id='check111' />
                              <label className="com-ecomCheckBox-labelBox cursor-pointer fw-semibold" htmlFor='check111'></label>
                            </div>
                            <div>{t("Color1")}</div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="com-ecomTableMain-table-tbody">
                {purchaselist?.length ? purchaselist.map((result, key) => {
                  return (
                    <>
                      <tr className="com-ecomTableMain-table-tbody-trBox" key={key}>
                        <td>
                          <div className='appointment-productnamecol'>
                            <div className='appointment-productnamecol-holder'>
                              <img src={result?.img ? result?.img : ''} alt='product image'/>
                            </div>
                            <div className='appointment-productnamecol-name'>
                                {result?.name}
                            </div>
                          </div>
                        </td>
                        <td>{result?.date ? result?.date : "-"}{result?.time}</td>
                        <td className="refNo-tdBox" style={{ cursor: "pointer" }}>
                          {result?.ref_no ? <div onClick={() => {
                            if (result?.url) {
                              window.open(result?.url, '_blank', 'noreferrer')
                            }
                          }}>{result?.ref_no ? result?.ref_no : "-"}</div>
                            : "-"
                          }
                        </td>
                        <td>{result?.SKU ? result?.SKU : "-"}</td>
                        <td onClick={() => openSubTable(key, result?.cart_ids)} className={showSubTable === key ? "arrowIcon" : ""}><RightSideArrow width={24} height={24} color={'#606060'} /></td>
                        <td>{result?.QTY ? result?.QTY : "-"}</td>
                        <td>{result?.Payment_type ? result?.Payment_type : "-"}</td>
                        <td>{result?.amount ? result?.amount : "-"}</td>
                      </tr>
                      <tr className={showSubTable === key ? "com-ecomTableMain-table table align-middle m-0" : "com-ecomTableMain-table table align-middle m-0 d-none"}>
                        <td colSpan="100">
                          <table width="100%">
                            <thead className="com-ecomTableMain-table-thead" >
                              <tr className=" text-nowrap align-middle " >
                                <th>{t("Product Name")} </th>
                                <th>{t("Date Time")}</th>
                                <th>{t("Purchse No.")}</th>
                                <th>{t("SKU")}</th>
                                <th>{t("Payment Type")}</th>
                                <th>{t("Location")}</th>
                                <th>{t("Shipping Details")}</th>
                              </tr>
                            </thead>

                            <tbody className="com-ecomTableMain-table-tbody" >
                              {subtabledata?.length ? subtabledata.map((item, index) => {
                                return (
                                  <tr className="com-ecomTableMain-table-tbody-trBox" key={index}>
                                    <td>
                                    <div className='appointment-productnamecol'>
                                      <div className='appointment-productnamecol-holder'>
                                        <img src={item?.img ? item?.img : ''} alt='product image'/>
                                      </div>
                                      <div className='appointment-productnamecol-name'>
                                          {item?.name ? item?.name : ""}
                                      </div>
                                    </div>
                                    </td>
                                    <td>{item?.data ? item?.data : ""}{item?.time ? item?.time : ''}</td>
                                    <td>
                                    {item?.ref_no ? 
                                      <div onClick={() => {
                                          if (item?.url) {
                                            window.open(item?.url, '_blank', 'noreferrer')
                                          }
                                        }}>{item?.ref_no ? item?.ref_no : "-"}
                                      </div>
                                          : "-"
                                      }
                                    </td>
                                    <td>{item?.SKU ? item?.SKU : ""}</td>
                                    <td>{item?.shipping_type ? item?.shipping_type : "-"}</td>
                                    <td>{item?.location ? item?.location : "-"}</td>
                                    <td>{item?.shipping_details ? item?.shipping_details : "-"}</td>
                                  </tr>
                                )
                              }) : ""}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </>
                  )
                }) : <tr>
                  <td colSpan="10" className="text-center">{t("No Data Available In Table")}</td>
                  </tr>}

              </tbody>
              <tfoot className="com-ecomTableMain-table-tfootBox">
                <tr>
                  <td colspan="5" className="com-ecomTableMain-table-tfootBox-tdBox">
                  </td>
                  <td className="com-ecomTableMain-table-tfootBox-tdBox">
                    <div className="total-qnty-show me-5">{lowercount?.qty}</div>
                  </td>
                  <td className="com-ecomTableMain-table-tfootBox-tdBox">
                  </td>
                  <td className="com-ecomTableMain-table-tfootBox-tdBox">
                    <div className="total-qnty-show me-5">{lowercount?.amount}</div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className='address_appointment_purchasehistory'>
              <div className='address_appointment_purchasehistory_content'>
                  <div className='address_appointment_purchasehistory_content_list'>
                      {purchaselist?.length ? purchaselist.map((result, key) => {
                      return (
                        <div className='address_appointment_purchasehistory_content_list-item'>
                            <div className='address_appointment_purchasehistory_content_list-item-holder'>
                              <img src={result?.img ? result?.name : ""} alt='productimage'/>
                            </div>
                            <div className='address_appointment_purchasehistory_content_list-item-content'>
                                <div className='address_appointment_purchasehistory_content_list-item-content-name'>
                                  <span>{result?.name ? result?.name : ""}</span>
                                  <div className='address_appointment_purchasehistory_content_list-item-content-metadata'><span>{result?.date ? result?.date : ""} {result?.time ? result?.time : ''}</span> •  <span>{result?.location ? result?.location : ''}</span></div>
                                </div>
                                <div className='address_appointment_purchasehistory_content_list-item-content-price'>{result?.price ? result?.price : ""}</div>
                                <div className='address_appointment_purchasehistory_content_list-item-content-seedetails' onClick={()=>{setActiveDropdown(key); openSubTable(key, result?.cart_ids)}}>{("See details")}</div>
                            </div>
                        </div>
                     )})
                    :
                    <div className='address_appointment_purchasehistory_content_list-empty'>
                      <span>{t("No Purchase yet")}</span>,{" "}
                      <span className='textwithunderline' onClick={()=>navigate("/collection")}>{t("Continue Shopping")}</span>
                    </div>  
                    } 
                  </div>
              </div>
              { activeDropdown >= 0 && activeDropdown != null 
              && subtabledata.length 
              ?
              <div className='address_appointment_purchasehistory-popup'>
                  <div className='address_appointment_purchasehistory-popup-inner'>
                      <button className="crossIcon" onClick={()=>setActiveDropdown(null)}><CrossIcon width={17.41} height={17.41} /></button>
                      {subtabledata?.length ? subtabledata.map((item, index) => {
                                return (
                                  <tr className="com-ecomTableMain-table-tbody-trBox" key={index}>
                                    <td>
                                    <div className='appointment-productnamecol'>
                                      <div className='appointment-productnamecol-holder'>
                                        <img src={item?.img ? item?.img : ''} alt='product image'/>
                                      </div>
                                      <div className='appointment-productnamecol-name'>
                                          {item?.name ? item?.name : ""}
                                      </div>
                                    </div>
                                    </td>
                                    <td>{item?.data ? item?.data : ""}{item?.time ? item?.time : ''}</td>
                                    <td>
                                    {item?.ref_no ? 
                                      <div onClick={() => {
                                          if (item?.url) {
                                            window.open(item?.url, '_blank', 'noreferrer')
                                          }
                                        }}>{item?.ref_no ? item?.ref_no : "-"}
                                      </div>
                                          : "-"
                                      }
                                    </td>
                                    <td>{item?.SKU ? item?.SKU : ""}</td>
                                    <td>{item?.shipping_type ? item?.shipping_type : "-"}</td>
                                    <td>{item?.location ? item?.location : "-"}</td>
                                    <td>{item?.shipping_details ? item?.shipping_details : "-"}</td>
                                  </tr>
                                )
                              }) : ""}
                  <div className='address_appointment_purchasehistory-popup-inner-details'>
                    <div className='address_appointment_purchasehistory-popup-inner-details-holder'>
                        <img src={subtabledata?.[0]?.img ? subtabledata[0]?.img : ""} alt='productimage'/>
                    </div>
                    <div className='price'>{subtabledata?.[0]?.price ? subtabledata[0]?.price : ""}</div>
                    <div className='qty'>x {subtabledata?.[0]?.QTY ? subtabledata[0]?.QTY : ""}</div>
                    <div className='address_appointment_purchasehistory-popup-inner-details-data'>
                        <div className='address_appointment_purchasehistory-popup-inner-details-data-head'>{t("Product Name")}</div>
                        <div className='address_appointment_purchasehistory-popup-inner-details-data-value'>{subtabledata?.[0]?.name ? subtabledata[0]?.name : ""}</div>
                        <div className='address_appointment_purchasehistory-popup-inner-details-data-head'>{t("Date Time")}</div>
                        <div className='address_appointment_purchasehistory-popup-inner-details-data-value'>{subtabledata?.[0]?.date ? subtabledata[0]?.date : ""}{subtabledata?.[0]?.time ? subtabledata[0]?.time : ""}</div>
                        <div className='address_appointment_purchasehistory-popup-inner-details-data-head'>{t("Purchase No.")}</div>
                        <div className='address_appointment_purchasehistory-popup-inner-details-data-value'>
                                      {subtabledata?.[0]?.ref_no ? 
                                      <div onClick={() => {
                                          if (subtabledata?.[0]?.url) {
                                            window.open(subtabledata?.[0]?.url, '_blank', 'noreferrer')
                                          }
                                        }}>{subtabledata?.[0]?.ref_no ? subtabledata?.[0]?.ref_no : "-"}
                                      </div>
                                          : "-"
                                      }</div>
                        <div className='address_appointment_purchasehistory-popup-inner-details-data-head'>{t("SKU")}</div>
                        <div className='address_appointment_purchasehistory-popup-inner-details-data-value'>{subtabledata?.[0]?.SKU ? subtabledata[0]?.SKU : ""}</div>
                        <div className='address_appointment_purchasehistory-popup-inner-details-data-head'>{t("Payment Type")}</div>
                        <div className='address_appointment_purchasehistory-popup-inner-details-data-value'>{subtabledata?.[0]?.shipping_type ? subtabledata[0]?.shipping_type : ""}</div>
                        <div className='address_appointment_purchasehistory-popup-inner-details-data-head'>{t("Location")}</div>
                        <div className='address_appointment_purchasehistory-popup-inner-details-data-value'>{subtabledata?.[0]?.location ? subtabledata[0]?.location : ""}</div>
                        <div className='address_appointment_purchasehistory-popup-inner-details-data-head'>{t("Shipping Details")}</div>
                        <div className='address_appointment_purchasehistory-popup-inner-details-data-value'>{subtabledata?.[0]?.shipping_details ? subtabledata[0]?.shipping_details : ""}</div>
                    </div>
                  </div>
                  </div>
              </div>
              :""
              }
          </div>
        </div>
      </div>
    </div>
  )
}
