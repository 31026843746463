import React from 'react';
import { Route, Outlet } from 'react-router-dom';
import LogOutControl from '../LogOutControl.js';
import AccessControl from '../components/AccessControl.js';
import LoginPage from '../components/common/login-signup/Login';
import ForgotPassword from '../components/common/login-signup/ForgotPassword';
import Changepassword from '../components/common/login-signup/Changepassword.js';
import Otpverification from '../components/common/login-signup/Otpverification.js';
import ForgotPin from '../components/common/login-signup/ForgotPin';
import SignUp from '../components/common/login-signup/SignUp';
import CustomSwitch from '../PublicCustomSwitch.js';
import ReportSwitch from '../ReportSwitch.js';
import NotFound404 from '../components/common/404.js';
import Loginwithpin from '../components/common/login-signup/Loginwithpin.js';
import Changepin from '../components/common/login-signup/Changepin.js';
import LandiingPage from '../landing/Main.js'
import ProductViewer from '../components/viewer/ProductViewer.js';

export default function PublicRoutes() {
    const AdminReactActive = function () {
        return (<><div className='main-layout-apps'><Outlet /></div></>);
    };
    return (
        <CustomSwitch>
            <Route element={<AdminReactActive />}>
            <Route path="/viewer/product/:product_id/:dbname" element={<ProductViewer />} />
            <Route path="/viewer/product/:product_id/:dbname/:config_template_id" element={<ProductViewer />} />

                <Route path="/loginwithpin" element={<Loginwithpin />} />
                <Route path="/logout" element={<LogOutControl />} />
                <Route path='/authentication/:token' element={<AccessControl />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path='/appauth/forgotpassword' element={<ForgotPassword />} />
                <Route path='/appauth/Changepin' element={<Changepin />} />
                <Route path='/appauth/changepassword/:token' element={<Changepassword />} />
                <Route path='/appauth/verification' element={<Otpverification />} />
                <Route path='/appauth/signup' element={<SignUp />} > </Route>
                <Route path='/appauth/forgotpin/:token' element={<ForgotPin />} > </Route>
                <Route path="/reports/:token" element={<ReportSwitch />} />
                <Route path="/unauthorized" element={<NotFound404 />} />
                <Route path="/" element={<LandiingPage />} />

                <Route path='*' element={<NotFound404 />} > </Route>

            </Route>
        </CustomSwitch>
    );
}
