import React, { useEffect, useState } from 'react';
import PayPalButton from './Paypal/PayPalButton';
import StripePayment from './StripePayment/StripePayment';
import { _Api } from '../../../common/api';
import AboutIcon from '../../assets/images/AboutIcon.png'
import LockIcon from '../../assets/images/lockIcon.png';
import uploadIcon from '../../assets/images/uploadIcon.png';
import { toGetCurrency } from '../../../common/Formateprice';
import { useTranslation } from 'react-i18next';
import useScreenSize from 'Ecommerce/template_three/include/Common/useScreenSize';
const PaymentTabs = ({ userdata, payamount, setCreditpaymentdata, setUserdata, creditpaymentdata }) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(false);
    const [paymentdata, setPaymentdata] = useState([]);
    const [paymentdatashow, setPaymentdatashow] = useState(null);
    const [errormessage, setErrormessage] = useState(null);
    const [activeData, setActiveData] = useState()
    const currency = toGetCurrency();
    const screenSize = useScreenSize();
    const getpayment = async () => {
        try {
            let postdata = {};
            let res = await _Api(postdata, `checkout/get-payment`);
            if (res?.statusCode === 200) {
                setPaymentdata(res?.paymetpaylist);
            }
        } catch (err) {
            console.log(err, "err");
        }
    };

    useEffect(() => {
        getpayment();
    }, []);
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith("image/")) {
                const reader = new FileReader();
                reader.onloadend = async () => {
                    try {
                        const postdata = {
                            image: reader.result,
                        };
                        let res = await _Api(postdata, `redis/uplaod`)
                        if (res.statusCode === 200) {
                            if (userdata?.paymentMethod?.IB) {
                                setUserdata({
                                    ...userdata,
                                    'paymentMethod': {
                                        ...userdata?.paymentMethod,
                                        IB: {
                                            ...userdata?.paymentMethod?.IB,
                                            image: res?.url
                                        }
                                    }
                                });
                            }
                            setErrormessage((state) => ({
                                ...state,
                                'bankimage': 'image is successfully upload'
                            }))
                        }
                    }
                    catch (err) {
                        console.log(err, "err")
                    }
                };
                if (file) {
                    reader?.readAsDataURL(file);
                }
            } else {
                alert("Please select a valid image file.");
            }
        }
    };
    const renderTabContent = () => {
        switch (paymentdatashow?.payment_slag) {
            case 'stripe-payment':
                return <StripePayment currency={currency} userdata={userdata} payamount={payamount}
                    paymentdatashow={paymentdatashow}
                    creditpaymentdata={creditpaymentdata}
                    setCreditpaymentdata={setCreditpaymentdata}
                />;
            case 'paypal-payment':
                return <PayPalButton currency={currency} userdata={userdata} payamount={payamount}
                    paymentdatashow={paymentdatashow}
                    setCreditpaymentdata={setCreditpaymentdata}
                />;
            // Add other cases for different payment methods
            default:
                return <PayPalButton />;
        }
    };

    return (
        <div className='payment_methods'>
            {paymentdata?.length && userdata?.billingaddress?.emails?.length && (userdata?.billingaddress?.fname || userdata?.billingaddress?.name) ? 
            (
                <div className='payment_methods'>
                { paymentdata.map((result, key) => (
                    <div key={key} className="paymentStepPage-main-paymentMethodBox">
                        <div className="stripPaymentCont">
                            <div className="stripPaymentCont-firstBox">
                                {/* <div className="stripPaymentCont-firstBox-CardHeader">
                                    {t(result?.name)}
                                    
                                </div> */}
                                <div className="stripPaymentCont-firstBox-CardIconBox">
                                    <div className="ecom-radiobtnShowBox">
                                        <input
                                            type="radio"
                                            checked={activeTab === key}
                                            className="ecom-radiobtnShowBox-inputField"
                                            id={`payCard${key}`}
                                            name="payCard"
                                            onChange={() => {
                                                setPaymentdatashow({
                                                    payment_slag: result?.payment_slag,
                                                    environment: result?.environment,
                                                    id: result?.id,
                                                    PaymentMethod: result?.PaymentMethod,
                                                    Paymentmethod_type: result?.Paymentmethod_type,
                                                    clientKey: result?.key,
                                                    PaymentMethod_id: result?.id,

                                                });
                                                setActiveData(result);
                                                // Call another function here
                                                setActiveTab(key); // Replace with your actual function name
                                            }}
                                        />
                                        <label className="ecom-radiobtnShowBox-labeltext" htmlFor={`payCard${key}`}>
                                            <div className="stripPaymentCont-firstBox-CardHeader">
                                                {t("Credit / Debit Cards")}
                                                <span><img src={AboutIcon} alt="" /></span>
                                            </div>
                                            <div className="allCardIconBox">
                                                <span className="singleCardIcon">
                                                    <img src={result?.logo} alt={t(result?.name)} /> 
                                                </span>
                                                <span className='allCardIconBox-insidetxt'>{t("Credit / Debit Cards")}</span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>

                           
                        </div>
                        {/* {activeTab === key && screenSize.width > 1200 && (
                                <div className="stripPaymentCont-paymentInfoBox">
                                    <div className="stripPaymentCont-paymentInfoBox-headBox">
                                        <h4>{t("Payment information")}</h4>
                                        <div className="stripPaymentCont-paymentInfoBox-headBox-securedBox">
                                            <span className="stripPaymentCont-paymentInfoBox-headBox-securedBox-lockIconSpan">
                                                <img src={LockIcon} alt="Lock" />
                                            </span>
                                            {t("Secured by ")} {t(result?.name)}
                                        </div>
                                    </div>
                                    <div className="stripPaymentCont-paymentInfoBox-stripFormBox">
                                        {renderTabContent()}

                                        {(result?.Paymentmethod_type === "Bank") && (result?.payment_slag !== "paypal-payment") ?
                                            <div className="internetBankPayment-selectBankBox-uploadDataBox">
                                                <label className="internetBankPayment-selectBankBox-uploadDataBox-boxLabel">{t("Report evidence of money transfer")}</label>

                                                <label className="internetBankPayment-selectBankBox-uploadDataBox-innerUploadInput">
                                                    <input
                                                        type="file"
                                                        className="d-none"
                                                        hidden
                                                        accept="image/*"
                                                        id="profile-details"
                                                        onChange={handleImageChange} />
                                                    <span >
                                                        <img src={uploadIcon} alt="" />
                                                    </span>
                                                    {t(" Upload evidence of transfer...")}
                                                </label>


                                            </div> : ""}
                                    </div>
                                </div>
                            )} */}
                    </div>
                ))}
                { activeData ? (
                    <div className="stripPaymentCont-paymentInfoBox">
                        <div className="stripPaymentCont-paymentInfoBox-headBox">
                            <h4>{t("Payment information")}</h4>
                            <div className="stripPaymentCont-paymentInfoBox-headBox-securedBox">
                                <span className="stripPaymentCont-paymentInfoBox-headBox-securedBox-lockIconSpan">
                                    <img src={LockIcon} alt="Lock" />
                                </span>
                                {t("Secured by")} {t(activeData?.name)}
                            </div>
                        </div>
                        <div className="stripPaymentCont-paymentInfoBox-stripFormBox">
                            {renderTabContent()}

                            {(activeData?.Paymentmethod_type === "Bank") && (activeData?.payment_slag !== "paypal-payment") ?
                                <div className="internetBankPayment-selectBankBox-uploadDataBox">
                                    <label className="internetBankPayment-selectBankBox-uploadDataBox-boxLabel">{t("Report evidence of money transfer")}</label>

                                    <label className="internetBankPayment-selectBankBox-uploadDataBox-innerUploadInput">
                                        <input
                                            type="file"
                                            className="d-none"
                                            hidden
                                            accept="image/*"
                                            id="profile-details"
                                            onChange={handleImageChange} />
                                        <span >
                                            <img src={uploadIcon} alt="" />
                                        </span>
                                        {t(" Upload evidence of transfer...")}
                                    </label>


                                </div> : ""}
                        </div>
                    </div>
                ) : null}
                </div>
            )
             : (
                <div className="text-danger">
                    {t("No payment method available. Please contact support team")}
                </div>
            )}

            {/* {(errormessage?.payment || creditpaymentdata?.message) && (
                <div className="text-danger">
                    {errormessage?.payment || creditpaymentdata?.message}
                </div>
            )} */}
        </div>
    );
};

export default PaymentTabs;
