import React, { useEffect, useRef, useState } from 'react'
import RightIcon from '../../common/icons/sidebar/RightIcon'
import Location from '../../common/icons/sidebar/Location'
import CellPhoneGreen from '../../common/icons/sidebar/CellPhoneGreen'
import Messegeboxblue from '../../common/icons/sidebar/Messegeboxblue'
import { useTranslation } from 'react-i18next';
import CalendarIcon from '../../common/icons/sidebar/CalendarIcon'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Calendar from '../../common/icons/sidebar/Calendar'
import Lodingspinner from '../../../components/common/Lodingspinner'
import { Link } from 'react-router-dom'
import { store } from '../../../source'
import { _Api } from '../../api/_call'
import UserIconCo from '../../common/icons/UserIconCo'
import UserIconRepair from '../../common/icons/UserIconRepair'
import CircledTick from '../../common/icons/sidebar/CircledTick'

export default function KanbanModal({ GetOrderDetails, Activity, setCardBordListUpdate }) {
  const { t } = useTranslation();
  const calendardrop = useRef(null);
  var date_format = store.getState()
  var OrgSettings = date_format?.app?.organisation_settings
  const [showmore, setShowmore] = useState(false);
  const [editmodel, setEditModel] = useState(false);
  const [selectCalender, setSelectCalendar] = useState(false)
  const [modaldata, setModaldata] = useState()
  const [skuimage, setSkuimage] = useState();
  const [skuSelected, setSelected] = useState(0);
  const [editted, setEditted] = useState(false);
  const [lodingdata, setLodingdata] = useState(false)
  const [savelodaer, setSaveloader] = useState(false)

  const date_format_change = (date_format) => {
    var response
    if (date_format === 'DD/MM/YYYY') {
      response = 'dd/MM/yyyy'
    } else if (date_format === 'YYYY/MM/DD') {
      response = 'yyyy/MM/dd'
    } else if (date_format === 'MM/DD/YYYY') {
      response = 'MM/dd/yyyy'
    } else if (date_format === 'MM/YYYY/DD') {
      response = 'MM/yyyy/dd'
    }
    return response
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendardrop.current && !calendardrop.current.contains(event.target)) {
        setSelectCalendar(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getmodaldata = async (GetOrderDetails) => {
    setLodingdata(true)
    try {
      let postdata = GetOrderDetails;
      let res = await _Api(postdata, `api/v1/App/kanban/getkanbanDetailbyOrderNo`)
      if (res?.code === 200) {
        let data = { ...res?.data }
        const isDate = !isNaN(Date.parse(data.CompletedDate));
        data.type = isDate ? 'date' : 'description'
        setModaldata(data)
        setSkuimage(data?.Skulist?.[0]?.image)
        setLodingdata(false);
      } else {
        setLodingdata(false);
      }
    }
    catch (err) {
      console.log(err, "Err")
    }
  }

  useEffect(() => {
    if (GetOrderDetails?.order_number) {
      getmodaldata(GetOrderDetails)
    }
  }, [GetOrderDetails])

  const updateduedate = (dateString, format) => {
    const date = new Date(dateString);
    setEditted(true)
    let formattedDate = '';
    switch (format) {
      case 'DD/MM/YYYY':
        formattedDate = `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`;
        break;
      case 'YYYY/MM/DD':
        formattedDate = `${date.getFullYear()}/${pad(date.getMonth() + 1)}/${pad(date.getDate())}`;
        break;
      case 'MM/DD/YYYY':
        formattedDate = `${pad(date.getMonth() + 1)}/${pad(date.getDate())}/${date.getFullYear()}`;
        break;
      case 'MM/YYYY/DD':
        formattedDate = `${pad(date.getMonth() + 1)}/${date.getFullYear()}/${pad(date.getDate())}`;
        break;
      default:
        throw new Error(`Unsupported date format: ${format}`);
    }
    setModaldata((state) => ({
      ...state,
      Delivery_date: formattedDate,
      start: `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`
    }));
  };
  const handleParsedDate = (modaldate, format) => {
    const dateString = modaldate;
    const dateParts = dateString.split('/');
    let formattedDate = '';
    switch (format) {
      case 'DD/MM/YYYY':

        formattedDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
        break;
      case 'YYYY/MM/DD':
        formattedDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        break;
      case 'MM/DD/YYYY':
        formattedDate = new Date(dateParts[1] - 1, dateParts[2], dateParts[0]);
        break;
      case 'MM/YYYY/DD':
        formattedDate = new Date(dateParts[1] - 1, dateParts[0], dateParts[2]);;
        break;
      default:
        throw new Error(`Unsupported date format: ${format}`);
    }
    return formattedDate;
  }
  const pad = (value) => {
    return value < 10 ? `0${value}` : value;
  };

  const editcalanderdats = async () => {
    if (editted) {
      setSaveloader(true)
      try {
        let postdata = {
          "start": modaldata?.start,
          "title": modaldata?.Activity,
          "id": modaldata?._id,
        }
        GetOrderDetails.start = modaldata?.start;
        console.log(GetOrderDetails);
        postdata = GetOrderDetails;
        let res = await _Api(postdata, `api/v1/App/kanban/update-date-drag`)
        if (res?.code === 200) {
          setCardBordListUpdate(new Date())
          getmodaldata(GetOrderDetails)
          setSaveloader(false)

        }
      }
      catch (err) {
        console.log(err, "Err")
      }
      setEditted(false);
      setEditModel(false);
      setSaveloader(false);
    }
  }
  return (
    <div
      className="modal fade taskDropdown"
      id="showtaskmodel"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="showTaskModel"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className={`modal-content ${selectCalender ? 'long' : ''}`}>
          {lodingdata ?
            <div className='position-absolute top-50 start-50 translate-middle'><Lodingspinner /></div> :
            <div>
              <div className="modal-header border-0 taskDropdown-header">
                <div className="modal-title">
                  <span>{Activity ? Activity : ""} {" "}
                    {modaldata?.From_location ? modaldata?.Ship_location : ""} :{" "}
                    {modaldata?.OrderNumber ? modaldata?.OrderNumber : ""}
                  </span>
                  {modaldata?.TransactionDate && (
                    <span className="taskDropdown-header-small text-gray ps-2">
                      {modaldata.TransactionDate}
                    </span>
                  )}
                </div>
                <div className='d-flex align-items-center'>
                  {/* {(modaldata?.Completed_date === "" && GetOrderDetails?.order_type !== "CO") ? ( */}
                  {(modaldata?.Completed_date === "") ? (
                    <div
                      onClick={() => setEditModel(!editmodel)}
                      className='taskDropdown-header-editicon cursor-pointer'
                    >
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.32379 1.85277H3.05892C1.64567 1.85277 0.5 2.9984 0.5 4.41161V12.9412C0.5 14.3544 1.64567 15.5 3.05892 15.5H11.5887C13.0019 15.5 14.1476 14.3544 14.1476 12.9412L14.1476 8.67638M4.76487 11.2352L7.86826 10.6099C8.03301 10.5767 8.18429 10.4956 8.30309 10.3767L15.2503 3.42587C15.5834 3.09262 15.5832 2.55242 15.2498 2.21945L13.7782 0.749481C13.4449 0.416642 12.905 0.416869 12.572 0.749988L5.62407 7.70154C5.5055 7.82018 5.42454 7.97114 5.39131 8.13554L4.76487 11.2352Z" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                  ) : ""}
                  <button
                    type="button"
                    className="shadow-none border-0 bg-transparent"
                    aria-label="Close"
                    data-bs-dismiss="modal"
                  >
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13 13L7 7M7 7L1 1M7 7L13 1M7 7L1 13" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="modal-body" >
                <div className='taskDropdown-content'>
                  <div className='taskDropdown-content-row w-padding w-padding extrapadding'>
                    <div className='taskDropdown-content-row-title d-flex align-items-start'>{t("Delivery Date")}</div>
                    <div className='taskDropdown-content-row-data align-items-center'>
                      {editmodel ?
                        <div className='taskDropdown-content-row-data-datepicker' ref={calendardrop}>
                          <div className='taskDropdown-content-row-data-datepicker-tag cursor-pointer'
                            onClick={() => setSelectCalendar(!selectCalender)}>
                            <span>
                              <Calendar withclock={true} color={"#A4A4A4"} width={'14px'} height={'14px'} />
                            </span>
                            {modaldata?.Delivery_date && (
                              <span className='px-2'>
                                {modaldata.Delivery_date}
                              </span>
                            )}
                          </div>
                          {selectCalender && modaldata?.Completed_date === "" ?
                            <div className="taskDropdown-content-row-data-datepicker-dropdown">
                              <div className='d-flex justify-content-center p-2'>
                                <div className={`taskDropdown-content-row-data-datepicker-dropdown-date`}>
                                  <Calendar color="#A0A0A0" />
                                  <DatePicker
                                    selected={new Date()}
                                    shouldCloseOnSelect={true}
                                    className={`border taskDropdown-content-row-data-datepicker-dropdown-date-calendar`}
                                    autoComplete='none'
                                    dateFormat={date_format_change(
                                      OrgSettings?.date_format,
                                    )}
                                    open={false}
                                    disabled={true}
                                  />
                                </div>
                                <div className={`taskDropdown-content-row-data-datepicker-dropdown-date secondone`}>
                                  <Calendar color="#A0A0A0" />
                                  <DatePicker
                                    onChange={(date) => updateduedate(date, OrgSettings?.date_format)}
                                    className={`border taskDropdown-content-row-data-datepicker-dropdown-date-calendar active`}
                                    autoComplete='none'
                                    dateFormat={date_format_change(
                                      OrgSettings?.date_format,
                                    )}
                                    open={true}
                                    selected={handleParsedDate(modaldata?.Delivery_date, OrgSettings?.date_format)}
                                  />
                                </div>
                              </div>
                            </div>
                            : ""}
                        </div> :
                        <div className='taskDropdown-content-row-data-fixdate'>
                          <span >
                            <CalendarIcon color={"#A4A4A4"} width={'24px'} height={'24px'} />
                          </span>
                          {modaldata?.Delivery_date && (
                            <span className='px-2'>
                              {modaldata.Delivery_date}
                            </span>
                          )}
                        </div>
                      }
                    </div>
                  </div>
                  <div className='taskDropdown-content-row w-padding'>
                    <div className='taskDropdown-content-row-title'>{t("Complete Date")}</div>
                    <div className='taskDropdown-content-row-data d-flex align-items-center'>
                      {modaldata?.Completed_date ?
                        <>
                          <CircledTick width={'18px'} height={'18px'} color="#52CBC4" /> <span className='taskDropdown-content-row-data-success mx-2'>{modaldata?.Completed_date}</span>
                        </> :
                        <>
                          <CircledTick width={'18px'} height={'18px'} color="#FF5757" /> <span className='taskDropdown-content-row-data-danger mx-2'>{modaldata?.Completed_date}</span>
                        </>
                      }
                    </div>
                  </div>

                  <div className='taskDropdown-content-row extrapadding'>
                    <div className='taskDropdown-content-row-title skutitle'>
                      {t("SKU")}<img
                        src={skuimage ? skuimage : modaldata?.Skulist?.[0]?.image}
                        alt='img' />
                    </div>
                    <div className='taskDropdown-content-row-data skuleft'>
                      <div className={`taskDropdown-content-row-data-list ${showmore ? 'edit' : ""}`}>
                        {modaldata?.Skulist?.map((result, key) => {
                          return (
                            <div className='sku' key={key}>
                              <div
                                className={`sku-first cursor-pointer ${key === skuSelected && "active"
                                  }`}
                                onClick={() => {
                                  setSkuimage(result?.image);
                                  setSelected(key);
                                }}
                              >
                                <img src={result?.image || ""} alt='img' />
                                <span>{result?.SKU || ""}</span>
                              </div>

                              <div className='sku-second d-flex align-items-center'>
                                {result?.PONumber && <span style={{ color: "black" }} className='ps-2 PONumbertext'>{result?.PONumber}</span>}

                                {result?.pos && (
                                  result.pos.order_type === "repair_order" ? (
                                    <span className='ps-2'><UserIconRepair /></span>
                                  ) : (
                                    <span className='ps-2'><UserIconCo /></span>
                                  )
                                )}
                              </div>
                            </div>
                          );
                        })}

                      </div>
                      {modaldata?.Skulist?.length > 3 ?
                        <div
                          className={`taskDropdown-content-row-data-list-showmore ${showmore ? 'active' : ''} cursor-pointer`}
                        >
                          <div className='d-flex align-items-center' onClick={() => setShowmore(!showmore)}>
                            <div>
                              <RightIcon color="#A0A0A0" />
                            </div>
                            <div className='text-ao'>
                              {showmore ? t('Show Less') : t('Show More')}
                            </div>
                          </div>
                        </div>
                        : ""}
                    </div>
                  </div>
                  <div className='taskDropdown-content-row w-padding extrapadding'>
                    <div className='taskDropdown-content-row-title'>{t("Qty")}</div>
                    <div className='taskDropdown-content-row-data'>{modaldata?.Qty ? modaldata?.Qty : 1}</div>
                  </div>
                  {modaldata?.customer_name ?
                    <div className='taskDropdown-content-row w-padding extrapadding'>
                      <div className='taskDropdown-content-row-title'>{t("Customer")}</div>
                      <div className='taskDropdown-content-row-data d-flex align-items-center'>
                        <div className='saleperson'>
                          <img src={modaldata?.customer_profile} alt='' />
                          <span className='ms-2'>
                            {modaldata?.customer_name ? modaldata?.customer_name : ""}</span>
                        </div>
                        {modaldata?.customer_phone ? <Link to={`tel:${modaldata?.customer_phone}`}><CellPhoneGreen width="24px" height="24px" /></Link> : ""}
                        <Link to={`mailto:${modaldata?.customer_email}`}><Messegeboxblue width="30px" height="30px" /></Link>
                      </div>
                    </div> : ""}
                  {modaldata?.From_location ?
                    <div className='taskDropdown-content-row w-padding extrapadding'>
                      <div className='taskDropdown-content-row-title'>{t("From")}</div>
                      <div className='taskDropdown-content-row-data d-flex'><Location forcalendar={true} color={"#A4A4A4"} />{" "}
                        {modaldata?.From_location}
                      </div>
                    </div>
                    : ""}
                  {modaldata?.Ship_location ?
                    <div className='taskDropdown-content-row w-padding extrapadding'>
                      <div className='taskDropdown-content-row-title'>{t("Ship to")}</div>
                      <div className='taskDropdown-content-row-data'><Location forcalendar={true} color={"#A4A4A4"} />
                        {modaldata?.Ship_location}
                        {modaldata?.ShipLocationPhone ? <Link to={`tel:${modaldata?.ShipLocationPhone}`}><CellPhoneGreen /></Link> : ""}
                      </div>
                    </div>
                    : ""}
                  {modaldata?.Agent ?
                    <div className='taskDropdown-content-row w-padding extrapadding'>
                      <div className='taskDropdown-content-row-title'>{t("Agent")}</div>
                      <div className='taskDropdown-content-row-data'>
                        {modaldata?.Agent}
                      </div>
                    </div>
                    : ""}

                  {modaldata?.AwbNumber ?
                    <div className='taskDropdown-content-row w-padding extrapadding'>
                      <div className='taskDropdown-content-row-title'>{t("AWB NO")}</div>
                      <div className='taskDropdown-content-row-data'>
                        {modaldata?.AwbNumber}
                      </div>
                    </div>
                    : ""}
                  {modaldata?.Created_By ?
                    <div className='taskDropdown-content-row w-padding extrapadding'>
                      <div className='taskDropdown-content-row-title'>{t("Created by")}</div>
                      <div className='taskDropdown-content-row-data'>
                        <div
                          className='saleperson'>
                          <img
                            src={modaldata?.Created_By_image ? modaldata?.Created_By_image : ""}
                            alt=""
                          />
                          <span className='ms-2'>
                            {modaldata?.Created_By ? modaldata?.Created_By : ""}
                          </span></div></div>
                    </div>
                    : ""}
                  <div className='taskDropdown-content-row w-padding extrapadding'>
                    <div className='taskDropdown-content-row-title'>{t("Remark")}</div>
                    <div className='taskDropdown-content-row-data'><div>
                      {modaldata?.Remark ? modaldata?.Remark : ""}</div></div>
                  </div>
                </div>
              </div>


              <div className="taskDropdown-footer modal-footer border-0 d-flex justify-content-center">
                {editmodel ?
                  <div className='taskDropdown-footer-inner'>
                    <button className='taskDropdown-footer-inner-cancelbtn' onClick={() => { setEditModel(false); setEditted(false) }}>{t("Cancel")}</button>

                    <button className={`taskDropdown-footer-inner-savebtn ${editted ? 'active' : 'disable'}`} onClick={() => editcalanderdats()}>
                      {t("Save")}
                      {savelodaer && editted ?
                        <div className="spinner-border text-light ms-2" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                        : null}
                    </button>
                    {/* <button className={`taskDropdown-footer-inner-savebtn disable`} onClick={() => editcalanderdats()}>
                        {t("Save")}
                      </button> */}

                  </div>
                  : ""
                }
              </div>

            </div>
          }
        </div>

      </div>
    </div >
  )
}
