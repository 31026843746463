import './appointment.scss'
import React, { useEffect, useState } from 'react'
import { _Api, _Apiauth } from 'Ecommerce/common/api'
import PlusIcon from 'Ecommerce/template_three/assets/icons/PlusIcon'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Spinner from 'Ecommerce/common/Spinner'
import Select from "react-select";
import DatePicker from 'react-datepicker'
import Calendar from 'admin/common/icons/sidebar/Calendar'
import LeftArrow from 'Ecommerce/common/icons/LeftArrow';
import AppointmentCheckIcon from 'Ecommerce/common/icons/AppointmentCheckIcon';
import EmpltyList from 'Ecommerce/common/images/outOfStock.png'
import AppointmentMap from 'Ecommerce/template_two/default/assets/images/appointment_map.png'
import CalendarIcon from 'admin/common/icons/sidebar/CalendarIcon';
import CircleClockIcon from 'Ecommerce/common/icons/CircleClockIcon';
import CellPhoneGreen from 'admin/common/icons/sidebar/CellPhoneGreen';
import Messegeboxblue from 'admin/common/icons/sidebar/Messegeboxblue';
import Location from 'admin/common/icons/sidebar/Location';
import Barcode from 'Ecommerce/common/images/Barcode.png';
import html2canvas from 'html2canvas';
import CircledTick from 'admin/common/icons/sidebar/CircledTick';
import GreenTick from 'admin/common/icons/GreenTick';
import CircledTickGray from 'admin/common/icons/sidebar/CircledTickGray';
import appointmentMap from '../../../common/images/appointment_map.png' 
import RightArrow from 'admin/common/icons/right-arrow';
import useScreenSize from 'Ecommerce/template_four/include/Common/useScreenSize';
import CrossIcon from 'admin/common/icons/CrossIcon';
import BottomBar from 'Ecommerce/template_four/include/Common/BottomBar';
import ConfirmPopup from 'Ecommerce/template_four/components/Confirmpopup/ConfirmPopup';

export default function AppointmentPage() {

    const { state } = useLocation();
    const screenSize = useScreenSize();
    const [loadeing, setloading] = useState(true);
    const { t } = useTranslation();
    const [cartitems, setCartitems] = useState([]);
    let localitem = localStorage.getItem('CartItems') ? JSON.parse(localStorage.getItem('CartItems')) : [];
    const [appointmentdtaa, setAppointmentdtaa] = useState([])
    const [locationdata, setLocationdata] = useState([])
    const [allfromdata, setAllfromdata] = useState({"appointment_date" : new Date()})
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isOpenOrder, setIsOpenOrder] = useState(false);
    const [enableButton, setEnableButton] = useState(false);
    const [selectdata, setSelectdata] = useState();
    const [validation, setValidation] = useState();
    const [showInformationPopup ,setShowInformationPopup] = useState(false)
    const [activeStep, setActiveStep] = useState(1);
    const [locationAlldata, setLocationAlldata] = useState();
    const [confirmationData, setConfirmationData] = useState();
    const navigate = useNavigate();
    let cartdata = JSON.parse(localStorage.getItem('CartItems'))
    const getappointmentdata = async () => {
        console.log(state, "Check the state")
        setloading(true)
        try {
            let cartids = [];
            let diamondids = [];
            let hasMixandmatch = false
            if (state?.checkoutItems?.length){ 
                state?.checkoutItems?.map((result) => {
                    if(result?.temp_cart_id){
                hasMixandmatch = true
            }})
            if(hasMixandmatch){
                state?.checkoutItems?.map((result)=>{
                    let newItem = {...result}
                    delete newItem.price
                    diamondids.push(newItem);
                }) 
            }else{
                state?.checkoutItems?.map((result)=>{
                    cartids.push(result?.id)
                })
            }
            }else{
                cartids.push(state?.checkoutItems)
            }
           
            let postdata = diamondids?.length ? {diamond_ids: diamondids} : {cart_ids: cartids}
            let res = await _Api(postdata, `order/getCartDetails`)
            if (res?.statusCode === 200) {
                setloading(false)
                let array = [...res?.data];
                array?.forEach((result) => {
                    result.qty = parseInt(state?.checkoutItems.filter(e => e?.id === result?.id)[0]['qty']);
                })
                setAppointmentdtaa(array);
                setCartitems(array)
               
                console.log(array, "find new data")
                setAllfromdata((privcestate) => ({
                    ...privcestate,
                    product: state?.checkoutItems
                }))
                if (res?.deleted_ids?.length) {
                    if(state?.checkoutItems.length){
                    state?.checkoutItems.forEach((result) => {
                        if (!res?.deleted_ids.includes(result?.id)) {
                            let data = []
                            data.push(result)
                            localStorage.setItem('CartItems', JSON.stringify(data));
                        }
                    })
                }else{
                    localStorage.setItem('CartItems', JSON.stringify([]))
                }
                }
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const getlocationdata = async () => {  
        try {
            let postdata = {}
            let res = await _Api(postdata, `redis/shipingMathod`)
            if (res?.statusCode === 200) {
                if(res?.StorePickup?.length){
                    let NewLocation = [];
                    res?.StorePickup.map((item)=>{
                        NewLocation.push({label: item.name, value: item._id})
                    })
                    console.log(NewLocation, "Check new location");
                    setLocationdata(NewLocation)
                    setLocationAlldata(res?.StorePickup);
                }
                
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const toggleDiv = () => {
        setIsOpenOrder(!isOpenOrder);
    };
    const getcountry = async () => {
        try {
            let postdata = {}
            let res = await _Apiauth(postdata, `redis/countryList`) 
            if (res?.statusCode === 200) {
                let phonecode = []
                res?.data.forEach((result) => {
                    phonecode.push({
                        label: result?.phoneCode,
                        value: result?.phoneCode
                    })
                })
                setSelectdata(phonecode)
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const timeArray =[
        {label: "00:00 AM", value: "00:00 AM"}, {label: "01:00 AM", value: "01:00 AM"}, {label :"02:00 AM", value: "02:00 AM"},{label :"03:00 AM", value: "03:00 AM"},
        {label :"04:00 AM", value: "04:00 AM"}, {label :"05:00 AM", value: "05:00 AM"}, {label :"06:00 AM", value: "06:00 AM"}, {label :"07:00 AM", value: "07:00 AM"},
        {label :"08:00 AM", value: "08:00 AM"}, {label :"09:00 AM", value: "09:00 AM"}, {label :"10:00 AM", value: "10:00 AM"}, {label :"11:00 AM", value: "11:00 AM"},
        {label :"12:00 AM", value: "12:00 AM"},
        
        {label: "00:00 PM", value: "00:00 PM"}, {label: "01:00 PM", value: "01:00 PM"}, {label :"02:00 PM", value: "02:00 PM"},{label :"03:00 PM", value: "03:00 PM"},
        {label :"04:00 PM", value: "04:00 PM"}, {label :"05:00 PM", value: "05:00 PM"}, {label :"06:00 PM", value: "06:00 PM"}, {label :"07:00 PM", value: "07:00 PM"},
        {label :"08:00 PM", value: "08:00 PM"}, {label :"09:00 PM", value: "09:00 PM"}, {label :"10:00 PM", value: "10:00 PM"}, {label :"11:00 PM", value: "11:00 PM"},
        {label :"12:00 PM", value: "12:00 PM"}
    ]
    const setalldata = (keyname, value) => {
        setAllfromdata((state) => ({
            ...state,
            [keyname]: value
        }))
        setValidation((state) => ({
            ...state,
            [keyname] : ""
        }))
    }
    useEffect(() => {
        getappointmentdata()
        getlocationdata()
        getcountry()
        // eslint-disable-next-line
    }, []);

    const handleCancel = () => {
        setIsPopupVisible(false);
      };

      const handleConfirm = () => {
        setIsPopupVisible(false);
        finaliseAppointment();
      }

    useEffect(()=>{
        if(appointmentdtaa.length){
           setEnableButton(true)
        }else{
            if(allfromdata?.interests?.length > 0  ){
                    setEnableButton(true)
                }
        }
    },[allfromdata])


    const interestArray = [
        "Earrings", "Necklaces & Pendants", "Rings", "Anklets", "Other Inquiries"
    ]

    const location = [
        "centeral lefparao", "Central world"
    ]

    const handleChange = (e, type) => {
        console.log(e, type, "on change")
    }

    const handleAppointmentConfirm = async() =>{
        console.log( allfromdata, validation,"check form data")
        const validfrom = () => {
            let fromisvalid = true
            if (!allfromdata?.appointment_date) {
                setValidation((state) => ({
                    ...state,
                    appointment_date: "Date is required"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    appointment_date: ""
                }))
            }
            if (!allfromdata?.name) {
                setValidation((state) => ({
                    ...state,
                    name: "Please enter your full name."
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    name: ""
                }))
            }
            if (!allfromdata?.email) {
                setValidation((state) => ({
                    ...state,
                    email: "Please enter your email"
                }))
                fromisvalid = false
            } else {
                const isEmail = (email) =>
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
                if (!isEmail(allfromdata?.email)) {
                    setValidation(prevState => ({
                        ...prevState,
                        email: "Please enter valid email"
                    }));
                    fromisvalid = false
                } else {
                    setValidation((state) => ({
                        ...state,
                        email: ""
                    }))
                }
            }
            if(!allfromdata?.time){
                setValidation((state) => ({
                    ...state,
                    time: "Please fill in all mandatory fields"
                }))
                fromisvalid = false
            }else {
                setValidation((state) => ({
                    ...state,
                    time: ""
                }))
            }
            if(!allfromdata?.store){
                setValidation((state) => ({
                    ...state,
                    store: "Please Select a store"
                }))
                fromisvalid = false
            }else {
                setValidation((state) => ({
                    ...state,
                    store: ""
                }))
            }
            if (!allfromdata?.mobile || !allfromdata?.phonecode) {
                setValidation((state) => ({
                    ...state,
                    mobile: "Mobile number is required"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    mobile: ""
                }))
            }
            if(!allfromdata?.policy){
                setValidation((state) => ({
                    ...state,
                    policy: "Please confirm that you agree to the Global Privacy Policy by checking the checkbox above."
                }))
                fromisvalid = false
            }else {
                setValidation((state) => ({
                    ...state,
                    policy: ""
                }))
            }
            return fromisvalid;
        }
        if (validfrom()) {
            showPopup();
           
        }
    }

    const downloadPageAsPNG = () => {
        const rootElement = document.getElementById('appointment_page');
        html2canvas(rootElement).then((canvas) => {
            const imageURL = canvas.toDataURL(`image/png`);
            const anchor = document.createElement('a');
            anchor.download = `${state?.appointment_id}.png`;
            anchor.href = imageURL;
            anchor.click();
            showPopup();
        });
       
    }

    const finaliseAppointment = async() =>{
        setloading(true)
           try {
               let obj = { ...allfromdata }
               obj.mobile = "+" + allfromdata?.phonecode + "-" + allfromdata?.mobile
               obj?.product?.forEach((result) => {
                   delete result?.price
               })
               // obj?.location = locationAlldata?.filter((item)=> item?._id === allfromdata?.value)
               delete obj?.phonecode
               delete obj?.store
               delete obj?.policy
               
               if(!appointmentdtaa.length){
                   obj = {
                       ...obj, product : []
                   }
               }
               obj.appointment_date = new Date(allfromdata?.appointment_date.setHours(12, 10, 10, 999))
               obj = {
                   ...obj, location : locationAlldata?.filter((item)=> item?._id === allfromdata?.store?.value)[0], time : obj?.time?.value, message : allfromdata?.message ? allfromdata?.message : ""
               }
               
               let postdata = obj
               console.log(postdata, validation, allfromdata, locationAlldata?.filter((item)=> item?._id === allfromdata?.store?.value), "whole details")
              
               let res = await _Api(postdata, `Appointment/create-Appointment`)
               setloading(false);
              
               if (res?.statusCode === 200) {
                   // if (modalRef.current) {
                   //     modalRef.current.hide();
                   // }
                  
                   navigate("/appointmentconfirm", { state: res?.data })
                   let setupdatearray = [...postdata?.product]
                   const newArray1 = cartdata.filter((obj1) => !setupdatearray.some((obj2) => obj2.id === obj1.id));
                   console.log(postdata?.product, setupdatearray, newArray1,  localStorage,"Check out new items")
                   localStorage.setItem('CartItems', JSON.stringify(newArray1));
               } else {

                   setValidation(prevState => ({
                       ...prevState,
                       "error": res?.message
                   }));
               }
           }
           catch (err) {
               console.log(err, "err")

               if (err.response.status) {
                   setValidation(prevState => ({
                       ...prevState,
                       "error": err.response.data.message
                   }));
               }
           }
   }

    const showPopup = () => {
        setIsPopupVisible(true);
      };

      const handleNextStep = () => {
        let fromisvalid = true;
        if(activeStep === 1){
            if(allfromdata.store){
                setActiveStep(2)
            }else{
                if(!allfromdata?.store){
                    setValidation((state) => ({
                        ...state,
                        store: "Please Select a store"
                    }))
                    fromisvalid = false
                }else {
                    setValidation((state) => ({
                        ...state,
                        store: ""
                    }))
                }
            }
        }
        if(activeStep === 2 ){
            if(allfromdata.appointment_date && allfromdata.time){
                setActiveStep(3)
            }else{
                if (!allfromdata?.appointment_date) {
                    setValidation((state) => ({
                        ...state,
                        appointment_date: "Date is required"
                    }))
                    fromisvalid = false
                } else {
                    setValidation((state) => ({
                        ...state,
                        appointment_date: ""
                    }))
                }
                if(!allfromdata?.time){
                    setValidation((state) => ({
                        ...state,
                        time: "Please fill in all mandatory fields"
                    }))
                    fromisvalid = false
                }else {
                    setValidation((state) => ({
                        ...state,
                        time: ""
                    }))
                }
            }
        }
        if(activeStep === 3 ){
            const isEmail = (email) =>
                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
            if(allfromdata.firstname && allfromdata.lastname &&  allfromdata.phonecode && allfromdata.mobile ){
                finaliseAppointment();
            }else{
                if (!allfromdata?.firstname) {
                    setValidation((state) => ({
                        ...state,
                        firstname: "Please enter your full name."
                    }))
                    fromisvalid = false
                } else {
                    setValidation((state) => ({
                        ...state,
                        firstname: ""
                    }))
                }
                if (!allfromdata?.lastname) {
                    setValidation((state) => ({
                        ...state,
                        lastname: "Please enter your full name."
                    }))
                    fromisvalid = false
                } else {
                    setValidation((state) => ({
                        ...state,
                        lastname: ""
                    }))
                }
                if (!allfromdata?.mobile || !allfromdata?.phonecode) {
                    setValidation((state) => ({
                        ...state,
                        mobile: "Mobile number is required"
                    }))
                    fromisvalid = false
                } else {
                    setValidation((state) => ({
                        ...state,
                        mobile: ""
                    }))
                }
                if (!allfromdata?.email) {
                    setValidation((state) => ({
                        ...state,
                        email: "Please enter your email"
                    }))
                    fromisvalid = false
                } else {
                    const isEmail = (email) =>
                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
                    if (!isEmail(allfromdata?.email)) {
                        setValidation(prevState => ({
                            ...prevState,
                            email: "Please enter valid email"
                        }));
                        fromisvalid = false
                    } else {
                        setValidation((state) => ({
                            ...state,
                            email: ""
                        }))
                    }
                }
            }
        }
        if(activeStep === 4){
            setActiveStep(1)
           
            setAllfromdata({})
        }
      }

      const handleSteps = (stepno) => {
        console.log(allfromdata, "Check all form data");
        let fromisvalid = true
        if(stepno === 1 ){
            if(allfromdata.store){
                setActiveStep(1)
            }else{
                if(!allfromdata?.store){
                    setValidation((state) => ({
                        ...state,
                        store: "Please Select a store"
                    }))
                    fromisvalid = false
                }else {
                    setValidation((state) => ({
                        ...state,
                        store: ""
                    }))
                }
            }
        }
        if(stepno === 2 && allfromdata.appointment_date && allfromdata.time){
            setActiveStep(2)
        }
        if(stepno === 3 && allfromdata.firstname && allfromdata.lastname && allfromdata.email && allfromdata.phoneCode && allfromdata.mobile ){
            setActiveStep(3)
        }
        if(stepno === 4 && allfromdata.store && allfromdata.appointment_date && allfromdata.time && allfromdata.firstname && allfromdata.lastname && allfromdata.email && allfromdata.phonecode && allfromdata.mobile){
            setActiveStep(4)
        }
      }

      const changeqty = (label, id) => {
        let array = [...cartitems];
        let updatedLocalItems = [...localitem];
    
        array = array.map(e => {
          if (e.id === id) {
            if (label === 'minus' && e.qty > 1) {
              e.qty -= 1;
            } else if (label !== 'minus') {
              e.qty += 1;
            }
          }
          return e;
        });
    
        updatedLocalItems = updatedLocalItems.map(e => {
          if (e.id === id) {
            if (label === 'minus' && e.qty > 1) {
              e.qty -= 1;
            } else if (label !== 'minus') {
              e.qty += 1;
            }
          }
          return e;
        });
    
        localStorage.setItem('CartItems', JSON.stringify(updatedLocalItems));
        // setCartitems(array);
      };

      const handlePrint = () => {
        window.print();
        
    }; 

  return (
    <div className='' id='appointment_page'>
     <div className='bookappointmentpage ecommerce-main-layout-bodyContent'>
            <div className='bookappointmentpage-header'>
                <span className=''>{t("Home")}</span> 
                <span className='SlashPath'><RightArrow/></span> 
                <span className='text'>{t("Appointment")}</span>
            </div>
          
                <div className='bookappointmentpage-container'>
                    <div className='bookappointmentpage-container-headerBox'>
                        <div className={`bookappointmentpage-container-headerBox-headerName`}>{t("Book an Appointment")}</div>
                        <div className={`bookappointmentpage-container-headerBox-subhead`}>{t("We look forward to welcoming you!")}</div>
                    </div>
                    {loadeing ?
                        (<div className=" w-100 d-flex justify-content-center align-items-center  ">
                            <Spinner />
                        </div>) :
                        // appointmentdtaa?.length ?
                        //     <div className='bookappointmentpage-container-contentPage' >
                        //         <div className={`tabHideOrder-box ${isOpenOrder ? 'open' : 'bg-white'}`} onClick={toggleDiv}>
                        //             <p className='tabHideOrder-box-contentText'>{t("Hide order Appointment")}</p>
                        //             <span className='arrow'></span>
                        //         </div>
                        //         <div className={isOpenOrder ? 'active wishlistMain-container-bodyProCont col-8 h-100  overflow-auto bookappointmentpage-container-contentPage-leftSideBox' : ' wishlistMain-container-bodyProCont col-8 h-100  overflow-auto bookappointmentpage-container-contentPage-leftSideBox'} id='appointmentOrder'
                        //             style={{ scrollbarColor: "transparent transparent" }}>
                        //             {appointmentdtaa?.length ? appointmentdtaa.map((result, key) => {
                        //                 return (
                        //                     <div className='wishlistMain-container-bodyProCont-singlePro  ps-0 bookappointmentpage-container-contentPage-leftSideBox-singleProductdetail' key={key}>
                        //                         <div className='wishlistMain-container-bodyProCont-singlePro-productType'>
                        //                             <div className='wishlistMain-container-bodyProCont-singlePro-productType-imageBox'>
                        //                                 <span className='product-img-span'><img src={result?.main_image} alt='' /></span>
                        //                             </div>
                        //                             <div className='wishlistMain-container-bodyProCont-singlePro-productType-details'>
                        //                                 <div className='wishlistMain-container-bodyProCont-singlePro-productType-details-firstBox'>
                        //                                     <p className='wishlistMain-container-bodyProCont-singlePro-productType-details-firstBox-productName'>{result?.name}</p>
                        //                                     <div className='wishlistMain-container-bodyProCont-singlePro-productType-details-firstBox-content'>
                        //                                         <p className='contentPara'>{result?.SKU}</p>
                        //                                         <p className='contentPara'>{result?.Metal}</p>
                        //                                         <p className='contentPara'><span>{t("Size")}:</span> {result?.Size} </p>
                        //                                     </div>
                        //                                 </div>
                        //                             </div>
                        //                         </div>
                        //                     </div>
                        //                 )
                        //             }) : ""}
                        //         </div>

                        //         <div className='col h-100 overflow-auto d-flex flex-column gap-4  bookappointmentpage-container-contentPage-rightSideBox'>
                        //             <div className='appointmentTabMain_header'>{t("Appointment")}</div>
                        //             {locationdata?.length ? locationdata.map((result, key) => {
                        //                 return (
                        //                     <div className="com-ShippAddressBox w-100" key={key} >
                        //                         <div className="com-ShippAddressBox-contentBox" key={key}>
                        //                             <div className="userDetailShipp">
                        //                                 <h3 className="com-ShippAddressBox-contentBox-boldText">{result?.name}</h3>
                        //                                 <p className="com-ShippAddressBox-contentBox-paraText">{result?.store_Address}</p>
                        //                             </div>
                        //                             <div className="d-flex gap-1">
                        //                                 <h3 className="com-ShippAddressBox-contentBox-boldText">{t("Phone Number")}:</h3>
                        //                                 <p className="com-ShippAddressBox-contentBox-lightText">{result?.phone}</p>
                        //                             </div>
                        //                             <div className="d-flex gap-1" >
                        //                                 <h3 className="com-ShippAddressBox-contentBox-boldText">{t("Store Hours")}</h3>
                        //                                 <p className="com-ShippAddressBox-contentBox-lightText"></p>
                        //                             </div>
                        //                             <div className='shipping-timeSetBox' >
                        //                                 {Object.keys(result?.store_timeSchedule || {}).map((key) => (
                        //                                     <div className='shipping-timeSetBox-singleDay' key={key}>
                        //                                         <div className='shipping-timeSetBox-singleDay-day'>
                        //                                             <p className='com-ShippAddressBox-contentBox-lightText' >{key}</p>
                        //                                         </div>
                        //                                         <div className='shipping-timeSetBox-singleDay-time'>
                        //                                             <p className='com-ShippAddressBox-contentBox-lightText'>{result?.store_timeSchedule[key]}</p>
                        //                                         </div>
                        //                                     </div>
                        //                                 ))}
                        //                             </div>
                        //                             <button className="com-ecomSendBtn mt-2 w-100" data-bs-toggle="modal" data-bs-target="#Appointmentmodal"
                        //                                 onClick={() => setAllfromdata((start) => ({
                        //                                     ...start,
                        //                                     location: result,
                        //                                     "name": "",
                        //                                     "mobile": "",
                        //                                     "email": "",
                        //                                     "message": "",
                        //                                     "appointment_date": "",
                        //                                     "phonecode": ""
                        //                                 }))}
                        //                             >
                        //                                 {t("Appointment")}
                        //                             </button>
                        //                         </div>
                        //                     </div>

                        //                 )
                        //             }) : ""}
                        //         </div>
                        //     </div>
                        //     :
                        //     <div className='wishlistMain-container-bodyBox'>
                        //         <div className='wishlistMain-container-bodyBox-innerContent'>
                        //             <p className='wishlistMain-container-bodyBox-innerContent-para'>{t("This list is empty")}.</p>
                        //             <Link className='text-decoration-none' to="/">
                        //                 <div className='wishlistMain-container-bodyBox-innerContent-addProductBtnBox'>
                        //                     <PlusIcon width={16} height={16} color={"#FFFFFF"} />
                        //                     <span>{t("Add Product")}</span>
                        //                 </div>
                        //             </Link>

                        //         </div>
                        //     </div>
                        <div className='bookappointmentpage-container-content'>
                            <div className='bookappointmentpage-container-content-wrapper'>
                                <div className='bookappointmentpage-container-content-wrapper-left'>
                                    <div className='bookappointmentpage-container-content-wrapper-left-top'>
                                        <div className='bookappointmentpage-container-content-wrapper-left-top-details'>
                                            <div className='bookappointmentpage-container-content-wrapper-left-top-details-title'>{t("Find Your Store")}</div>
                                            <div className="bookappointmentpage-container-content-wrapper-left-top-details-singledetail">
                                                <div className={`bookappointmentpage-container-content-wrapper-left-top-details-singledetail-name ${validation?.store ? "error" : ""}`}>
                                                    <span className='headings-name'>{t("Store")}<span className='text-danger'>*</span></span>
                                                </div>
                                                <div className="bookappointmentpage-container-content-wrapper-left-top-details-singledetail-option">
                                                    <Select
                                                        options={locationdata}
                                                        className={`${validation?.store ? "border-danger" : ""}`}
                                                        height='46px'
                                                        width='100%'
                                                        value={allfromdata?.store || null}
                                                        placeholder={"Centeral World"}
                                                        onChange={(e)=>setalldata("store", e)}
                                                        isSearchable={false}
                                                        classNamePrefix='newDropdownDark'
                                                        />
                                                </div>
                                                {validation?.store ? 
                                                <div className='bookappointmentpage-container-content-wrapper-left-top-details-singledetail-error'>
                                                    {t(`${validation?.store}`)}
                                                </div>
                                                : "" }
                                            </div>
                                            <div className='bookappointmentpage-container-content-wrapper-left-top-details-title'>{t("Your Appointment")}</div>
                                            <div className='bookappointmentpage-container-content-wrapper-left-top-details-holder'>
                                            <div className="bookappointmentpage-container-content-wrapper-left-top-details-singledetail">
                                                <div className="bookappointmentpage-container-content-wrapper-left-top-details-singledetail-name">
                                                    <span>{t("Date")}<span className='text-danger'>*</span></span>
                                                </div>
                                                <div className="bookappointmentpage-container-content-wrapper-left-top-details-singledetail-option">
                                                <DatePicker
                                                    selected={allfromdata?.appointment_date}
                                                    onChange={(date) => setalldata("appointment_date", date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    className=''
                                                    minDate={new Date()}
                                                    autoComplete='none'
                                                    showIcon={true} 
                                                    icon={<Calendar color="#6A6A6A" width="18px" height="18px"/>}
                                                />
                                                </div>
                                                <div className='bookappointmentpage-container-content-wrapper-left-top-details-singledetail-message'>
                                                    {t("All dates and times are in GMT+07:00")}
                                                </div>
                                            </div>
                                            <div className="bookappointmentpage-container-content-wrapper-left-top-details-singledetail">
                                                <div className={`bookappointmentpage-container-content-wrapper-left-top-details-singledetail-name ${validation?.time ? "error" : ""}`}>
                                                    <span className='headings-name'>{t("Time")}<span className='text-danger'>*</span></span>
                                                </div>
                                                <div className="bookappointmentpage-container-content-wrapper-left-top-details-singledetail-option">
                                                    <Select
                                                        options={timeArray}
                                                        className={`select-set select p-0 main-content-wrapper-body-top-status-bar-select-num ${validation?.time ? "border-danger" : ""}`}
                                                        height='46px' 
                                                        width='100%'
                                                        value={allfromdata?.time}
                                                        onChange={(date) => setalldata("time", date)}
                                                        placeholder={"Select Time"}
                                                        isSearchable={false}
                                                        classNamePrefix='newDropdownDark'
                                                        />
                                                </div>
                                                {validation?.time ? 
                                                <div className='bookappointmentpage-container-content-wrapper-left-top-details-singledetail-error'>
                                                    {t(`${validation?.time}`)}
                                                </div>
                                                : "" }
                                            </div>
                                            </div>
                                        </div>
                                        <div className='bookappointmentpage-container-content-wrapper-left-top-map'>
                                            <img src={appointmentMap} alt='map'/>
                                        </div>
                                    </div>
                                    { appointmentdtaa?.length > 0 ? 
                                   
                                    <div className='bookappointmentpage-container-content-wrapper-left-areaofinterest'>
                                        <div className='bookappointmentpage-container-content-wrapper-left-areaofinterest-title'><div>{("Area of Interest")} <span className='sub'>{appointmentdtaa?.length ? `${appointmentdtaa?.length} Items` : ""}</span></div><Link to='/' className='continue'>{("Continue Shopping")} <span><RightArrow color="#A3A3A3"/></span></Link></div>
                                        <div>
                                        <div className="ecommerce-main-layout-products-container-show-all_products">
                                        {appointmentdtaa?.map((item, key) => {
                                        return (

                                        <Link to={`/product/${item?.motherProduct?.Slug}`} state={{ id: item?._id }} className='ecommerce-recent_view-container-inner-product' key={key}>
                                        <div className='ecommerce-recent_view-container-inner-product-image'>
                                            <div className="ecommerce-recent_view-container-inner-product-image-imgBoxShow">
                                            <img src={item?.main_image ? item?.main_image : ""} alt='product' /></div>
                                        </div>
                                        <div className="ecommerce-recent_view-container-inner-product-details text-decoration-none ">
                                            <div className='ecommerce-recent_view-container-inner-product-details-wrapper'>
                                            <div className="ecommerce-recent_view-container-inner-product-details-wrapper-productName">
                                                {item?.name}
                                            </div>
                                            <span>{item?.SKU} </span>
                                            </div>
                                        </div>
                                        </Link>

                                    )
                                    })}
                                    </div>
                                    </div>
                                    </div>
                                    :
                                    <div className='bookappointmentpage-container-content-wrapper-left-interest'>
                                    <div className='bookappointmentpage-container-content-wrapper-left-interest-title'>{("Please choose one or more interests")} <span className='important'>*</span></div>
                                    <div className='bookappointmentpage-container-content-wrapper-left-interest-interestlist'>
                                        {interestArray.map((item,i)=>{
                                            return(
                                            <div className='bookappointmentpage-container-content-wrapper-left-interest-interestlist-option' key={i}>
                                               
                                                  <div className="ecom-blackthemed-checkbox d-flex align-items-center gap-2">
                                                        <input type="checkbox" className="itemscheckbox"
                                                           id={item}
                                                           onClick={()=>setAllfromdata((state)=>({...state, "interests" :  state.interests ? [...state.interests, item] : [item]}))} 
                                                            />
                                                        <label className="ecom-blackthemed-checkbox-label" htmlFor={item}></label>
                                                        <span className='ps-2'>{t(`${item}`)}</span>
                                                    </div>
                                            </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                }
                                </div>
                                <div className='bookappointmentpage-container-content-wrapper-right'>
                                    <div className='bookappointmentpage-container-content-wrapper-right-title'>{("Your Details")}</div>
                                    <div className="bookappointmentpage-container-content-wrapper-left-top-details-singledetail">
                                                <div className={`bookappointmentpage-container-content-wrapper-left-top-details-singledetail-name ${validation?.name ? "error" : ""}`}>
                                                    <span className='headings-name'>{t("Full Name")}<span className='important'>*</span></span>
                                                </div>
                                                <div className="bookappointmentpage-container-content-wrapper-left-top-details-singledetail-option">
                                                   <input className={`bookappointmentpage-container-content-wrapper-left-top-details-singledetail-option-input ${validation?.name ? "error" : ""}`} type='text' value={allfromdata?.name}
                                                    onChange={(e) => setalldata("name", e.target.value)}/>
                                                </div>
                                                {validation?.name ? 
                                                <div className='bookappointmentpage-container-content-wrapper-left-top-details-singledetail-error'>
                                                    {t(`${validation?.name}`)}
                                                </div>
                                                : "" }
                                               
                                            </div>
                                            <div className="bookappointmentpage-container-content-wrapper-left-top-details-singledetail">
                                                <div className={`bookappointmentpage-container-content-wrapper-left-top-details-singledetail-name ${validation?.email ? "error" : ""}`}>
                                                    <span className='headings-name'>{t("Email")}<span className='important'>*</span></span>
                                                </div>
                                                <div className="bookappointmentpage-container-content-wrapper-left-top-details-singledetail-option">
                                                   <input className={`bookappointmentpage-container-content-wrapper-left-top-details-singledetail-option-input ${validation?.email ? "border-danger" : ""}`} type='text'  value={allfromdata?.email}
                                                    onChange={(e) => setalldata("email", e.target.value)}/>
                                                </div>
                                                {validation?.email ? 
                                                <div className='bookappointmentpage-container-content-wrapper-left-top-details-singledetail-error'>
                                                    {t(`${validation?.email}`)}
                                                </div>
                                                : "" }
                                            </div>
                                            <div className="bookappointmentpage-container-content-wrapper-left-top-details-singledetail ">
                                                <div className={`bookappointmentpage-container-content-wrapper-left-top-details-singledetail-name ${validation?.mobile ? "error" : ""}`}>
                                                    <span className='headings-name'>{t("Phone Number")}<span className='important'>*</span></span>
                                                </div>
                                                <div className={`bookappointmentpage-container-content-wrapper-left-top-details-singledetail-option phonefield ${validation?.mobile ? "border-danger" : ""}`}>
                                                    <Select
                                                        options={selectdata}
                                                        className={`ecommerce-dropdown noborder border-0 ${validation?.mobile ? "noborder" : ""}`}
                                                        value={{ label: allfromdata?.phonecode, value: allfromdata?.phonecode }}
                                                        onChange={(e) => setalldata("phonecode", e.value)}
                                                        classNamePrefix='newDropdownDark'
                                                    />
                                                    <input className={`bookappointmentpage-container-content-wrapper-left-top-details-singledetail-option-input border-0`} 
                                                    type='number' 
                                                    value={allfromdata?.mobile}
                                                    onChange={(e) => setalldata("mobile", e.target.value)}
                                                    placeholder='Telephone Number'
                                                    />
                                               
                                                </div>
                                                {validation?.mobile ? 
                                                <div className='bookappointmentpage-container-content-wrapper-left-top-details-singledetail-error'>
                                                    {t(`${validation?.mobile}`)}
                                                </div>
                                                : "" }
                                            </div>
                                            <div className='bookappointmentpage-container-content-wrapper-right-inputtype'>
                                            <div className="bookappointmentpage-container-content-wrapper-left-top-details-singledetail">
                                                <div className="bookappointmentpage-container-content-wrapper-left-top-details-singledetail-name">
                                                    <span className='headings-name'>{t("Message")}</span>
                                                </div>
                                                <div className="bookappointmentpage-container-content-wrapper-left-top-details-singledetail-option">
                                                   <textarea className='bookappointmentpage-container-content-wrapper-left-top-details-singledetail-option-textarea' placeholder='Message goes here'  value={allfromdata?.message}
                                                    onChange={(e) => setalldata("message", e.target.value)}></textarea>
                                                </div>
                                            </div>
                                            </div>
                                            <div className='bookappointmentpage-container-content-wrapper-right-tnc'>
                                                <div className=' d-flex'>
                                                    <div className="ecom-blackthemed-checkbox">
                                                            <input
                                                            type="checkbox" 
                                                            className="itemscheckbox"
                                                                id="one" 
                                                                />
                                                            <label className="ecom-blackthemed-checkbox-label"  htmlFor="one"></label>
                                                            
                                                    </div>
                                                <div className="ps-2 fw-semibold bookappointmentpage-container-content-wrapper-left-interest-interestlist-option-tag" htmlFor="one"> {t("I would like to receive updates from JEWELRY using any of the method(s) that I have provided above. You can unsubscribe at any time.")}</div>
                                                </div>
                                                <div className='bookappointmentpage-container-content-wrapper-right-tnc-one'>
                                                <div className='d-flex'>
                                                <div className="ecom-blackthemed-checkbox d-flex align-items-center gap-2">
                                                        <input type="checkbox" checked={allfromdata?.policy} className="itemscheckbox"
                                                            id="two"
                                                            onChange={(e)=>e.target.checked ? setalldata("policy", true) : setalldata("policy", false)} 
                                                            />
                                                        <label className="ecom-blackthemed-checkbox-label"  htmlFor="two"></label>
                                                    </div>
                                                    <div className='ps-2 fw-semibold bookappointmentpage-container-content-wrapper-left-interest-interestlist-option-tag'><span className='important'>*</span> 
                                                   {t(" By submitting this form, I certify that I have read and accepted the Privacy Policy of JEWELRY")}.</div>
                                                </div>
                                                {validation?.policy ? 
                                                <div className='bookappointmentpage-container-content-wrapper-left-top-details-singledetail-error ps-4'>
                                                    {t(`${validation?.policy}`)}
                                                </div>
                                                : "" }
                                                </div>
                                                <div></div>
                                            </div>
                                </div>
                            </div>
                            <div className={`bookappointmentpage-container-content-bottom ${enableButton ? "" : "disable"}`}>
                            <button className=""
                                                        onClick={enableButton ? () => handleAppointmentConfirm() : null}
                                                    >
                                                        {t("Book My Appointment")}
                                                    </button>
                            </div>
                        </div>
                             }
                      
                </div>
              
        </div>
        <BottomBar/>
        <ConfirmPopup 
        isVisible={isPopupVisible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        message="Confirm Book An Appointment"
        sub="Are sure you want to cancel this appointment?"
        leftbtn={"Cancel"}
        rightbtn={"Yes"}
        />
        {/* {isPopupVisible &&
            <div className='appointment_page-container-content-wrapper-inner-holder-downloadpopup' onClick={()=>setIsPopupVisible(false)}>
                                <div className='appointment_page-container-content-wrapper-inner-holder-downloadpopup-modal' onClick={(e)=>e.stopPropagation()}>
                                <button type="button" className="modalonright-headerBox-crossbtn" onClick={()=>setIsPopupVisible(false)}>
                                        <CrossIcon width={18} height={18} />
                                </button>
                                <div className='appointment_page-container-content-wrapper-inner-holder-downloadpopup-download'>
                                    <div className='appointment_page-container-content-wrapper-inner-holder-downloadpopup-download-title'><span>{t("Download Complete")}</span></div>
                                    <div className='appointment_page-container-content-wrapper-inner-holder-downloadpopup-download-icon'><CircledTickGray/> </div>
                                    <div className='appointment_page-container-content-wrapper-inner-holder-downloadpopup-download-metadata'>
                                        <span>{t("Files Downloaded !")}</span>
                                        <span>{t("Save your file on the drive")}</span>
                                        <span>{t("Show your appointment barcode at the store")}</span>
                                    </div>
                                </div>
                                </div>
            </div>
        } */}
</div >
  )
}
