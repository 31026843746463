import React, { useEffect } from 'react'
import "./common.scss"
import loginsideBanner from "../assets/images/LoginSideBanner.png"
import CrossIcon from '../assets/icons/CrossIcon'
import { useState } from 'react'
import { _Apiauth } from '../../common/api'
import { useTranslation } from 'react-i18next';
const SetNewPassword = (props) => {
    const { setOpenmodal, newpassworddata } = props
    const { t } = useTranslation()
    const [logindata, setLogindata] = useState()
    const [validation, setValidation] = useState()
    const [lodaer, setLodaer] = useState(false)
    const alllogindata = (keyName, value) => {
        setLogindata((prevState) => ({
            ...prevState,
            [keyName]: value,
        }))
    }
    // eslint-disable-next-line
    const getnewpassword = async () => {
        if (logindata?.password === logindata?.confirmpassword) {
            try {
                setLodaer(true)
                let postdata = {
                    "password": logindata?.confirmpassword,
                    "resetpasswordToken": newpassworddata
                }
                let res = await _Apiauth(postdata, `auth/reset-password`)
                if (res?.statusCode === 200) {
                    setLodaer(false)
                    setOpenmodal(prevState => ({
                        ...prevState,
                        showLoginModel: true,
                        showRegisterModel: false,
                        showemailvarifymodal: false,
                        forgetpassword: false,
                        resetpassword: false,
                        newpassword: false
                    }))
                } else {
                    setLodaer(false)
                }
            }
            catch (err) {
                console.log(err, "err")
                setLodaer(false)
                setValidation(prevState => ({
                    ...prevState,
                    sussess: {
                        message: err.response.data.message,
                        status: true
                    }
                }));
            }
        } else {
            setLodaer(false)
            setValidation(prevState => ({
                ...prevState,
                sussess: {
                    message: "Password Not Match",
                    status: true
                }
            }));
        }

    }
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                getnewpassword();
            }
        };
        document.addEventListener('keypress', handleKeyPress);
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
        // eslint-disable-next-line
    }, [getnewpassword]);
    return (
        <div className='ecommerce-login-overlay'>
            <div className='ecommerce-login'>
                <div className='ecommerce-login-inner'>
                    <div className='ecommerce-login-inner-title'>
                        <div className='ecommerce-login-inner-title-heading text-center'>{t("Set New Password")}</div>
                        <div className='ecommerce-login-inner-title-sub-title text-center'>{t("Must be at least 8 characters")}. </div>
                    </div >
                    <div className='ecommerce-login-inner-input_section'>
                        <div className={`ecommerce-login-inner-input_section-inner ${validation?.passwordvalid?.status && "required"}`}>
                            <label>
                                {t("Password")}
                            </label>
                            <input className={`ecommerce-login-inner-input_section-inner-input ${validation?.passwordvalid?.status && "border-danger"}`}
                                type='password'
                                onChange={(e) => alllogindata("password", e.target.value)}
                            />
                            {validation?.passwordvalid?.status ?
                                <div className='text-danger'>
                                    {t(`${validation?.passwordvalid?.message}`)}
                                </div>
                                : ""}
                        </div>
                        <div className={`ecommerce-login-inner-input_section-inner ${validation?.passwordvalid?.status && "required"}`}>
                            <label>
                                {t("Confirm Password")}
                            </label>
                            <input className={`ecommerce-login-inner-input_section-inner-input ${validation?.passwordvalid?.status && "border-danger"}`}
                                type='password'
                                onChange={(e) => alllogindata("confirmpassword", e.target.value)}
                            />
                            {validation?.passwordvalid?.status ?
                                <div className='text-danger'>
                                    {t(`${validation?.passwordvalid?.message}`)}
                                </div>
                                : ""}
                        </div>
                        {validation?.sussess?.status ?
                            <div className='text-danger'>{t(`${validation?.sussess?.message}`)}</div> : ""
                        }
                        {lodaer ?
                            <button className='ecommerce-login-inner-input_section-btn'>
                                <div className="border-bottom-0 border-width-2px text-white d-block mx-auto spinner-border flex-shrink-0" role="status"></div>
                            </button> :
                            <button className='ecommerce-login-inner-input_section-btn' onClick={() => getnewpassword()}>{t("SIGN IN")}</button>}

                    </div>
                    <div className='ecommerce-login-inner-create-account'>
                        <div className='ecommerce-login-inner-create-account-register_btn'>
                            {t("Back to Sign In")}
                            <span onClick={() => {
                                setOpenmodal(prevState => ({
                                    ...prevState,
                                    showLoginModel: false,
                                    showRegisterModel: true,
                                    showemailvarifymodal: false,
                                    forgetpassword: false,
                                    resetpassword: false,
                                    newpassword: false
                                }));
                            }}>
                                {t("SIGN UP")}
                            </span>
                        </div>
                        <div className='ecommerce-login-inner-create-account-privacy_notice'>
                            {t("By continuing, I confirm that I have read and agree to the")} {t("Privacy Notice")} {t("and Term and Conditions")}
                        </div>
                    </div>
                </div >
                <div className='ecommerce-login-sidebanner'>
                    <span className='Cross_icon' onClick={() => {
                        setOpenmodal(prevState => ({
                            ...prevState,
                            showLoginModel: false,
                            showRegisterModel: false,
                            showemailvarifymodal: false,
                            forgetpassword: false,
                            resetpassword: false,
                            newpassword: false
                        }));
                    }} >
                        <CrossIcon width={30} height={30} />
                    </span>
                    <img src={loginsideBanner} alt='side_banner' />
                </div >
            </div >
        </div >
    )
}

export default SetNewPassword