import React, { useEffect, useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import './calendar.scss'
import TaskSidebar from './TaskSidebar'
import { useTranslation } from 'react-i18next';
import CalendarFilter from './CalendarFilter'
import DatePicker from 'react-datepicker'
import { _Api } from '../api/_call'
import RightIcon from '../common/icons/sidebar/RightIcon'
import ConfirmDragDropdown from './ConfirmDragDropdown'
import { loading, Updateloading } from "../common/helpers/toastify";
export default function CallCalendar({ showFilter, setShowFilter, handleShowFilter, setFilterCount }) {
  const [rerenderKey, setRerenderKey] = useState(0);
  const closeFilter = useRef(null);
  const modalRef = useRef(null);
  const [currentView, setCurrentView] = useState({ value: 'dayGridMonth', label: 'Month' });
  const calendarRef = useRef(null);
  const perioddropdownRef = useRef(null);
  const [calendarlist, setCalendarlist] = useState([]);
  const [showPeriodDropdown, setShowPeriodDropdown] = useState(false);
  const [activePeriod, setActivePeriod] = useState('Month');
  const [calendarTitle, setCalendarTitle] = useState('')
  const [showTitleCalendar, setShowTitleCalendar] = useState(false);
  const [dragdata, setDragdata] = useState()
  const [taskbarlistupdate, setTaskbarlistupdate] = useState(false);
  const { t } = useTranslation();
  const [applyfilter, setApplyfilter] = useState({ OrderType: [], Status: [], start_date: "", end_date: "", activedates: "" })
  const [taskbardata, setTaskbardata] = useState({ runapi: false, date: "" })
  const handleEventClick = async (clickInfo) => {
    let date = new Date(clickInfo?.event?.start.setHours(12, 10, 10, 999))
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`
    setTaskbardata({ runapi: true, date: formattedDate })
    setTimeout(() => {
      setRerenderKey(rerenderKey + 1);
    }, 600);
  }
  // const [filterData, setFilterData] = useState({ OrderType: [], Status: [], start_date: "", end_date: "", activedates: '' });
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (closeFilter.current && !closeFilter.current.contains(event.target)) {
        setShowFilter(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);
  const getFormattedDate = () => {
    if (currentView?.label === 'Month') {
      const inputDate = new Date(calendarTitle);

      // Extract month and year from the date
      const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, hence +1
      const year = inputDate.getFullYear();

      // Format the date as MM-yyyy
      const formattedDate = `${year}-${month}`;
      return formattedDate
    } else {
      const monthIndex = calendarTitle.split(' ')[0]; // Get first word before space
      const year = calendarTitle.split(',').pop().trim();
      if (currentView?.label === 'Week') {
        const monthAbbreviations = {
          "Jan": "01", "Feb": "02", "Mar": "03", "Apr": "04",
          "May": "05", "Jun": "06", "Jul": "07", "Aug": "08",
          "Sep": "09", "Oct": 10, "Nov": 11, "Dec": 12
        };
        return `${year}-${monthAbbreviations[monthIndex]}`
      } else {
        const monthAbbreviations = {
          "January": "01", "February": "02", "March": "03", "April": "04",
          "May": "05", "June": "06", "July": "07", "August": "08",
          "September": "09", "October": 10, "November": 11, "December": 12
        };
        return `${year}-${monthAbbreviations[monthIndex]}`
      }
    }
  }

  const getcalenderlist = async () => {
    if (calendarTitle) {
      try {
        let postdata = {
          "type": currentView?.label,
          "order_type": applyfilter?.OrderType?.length ? applyfilter?.OrderType : [],
          "status": applyfilter?.Status?.length ? applyfilter?.Status : [],
          "start_date": applyfilter?.start_date ? applyfilter?.start_date : "",
          "end_date": applyfilter?.end_date ? applyfilter?.end_date : "",
          "date": getFormattedDate()
        }
        let res = await _Api(postdata, `api/v1/App/calender/getCalenderData`)
        if (res?.code === 200) {
          setCalendarlist(res?.data)
          // setFilterData(postdata);
          setFilterCount(applyfilter?.OrderType?.length + applyfilter?.Status?.length + (applyfilter?.activedates !== "" ? 1 : 0))
        }
      }
      catch (err) {
        console.log(err, "err")
      }
    }
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (perioddropdownRef.current && !perioddropdownRef.current.contains(event.target)) {
        setShowPeriodDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    getcalenderlist();
    // eslint-disable-next-line
  }, [applyfilter, calendarTitle])
  useEffect(() => {
    setCalendarTitle(calendarRef?.current?.calendar?.currentData?.viewTitle)
  }, [calendarRef, currentView?.value])

  const handleEventDrop = async (dropInfo) => {
    try {
      let dragdatainfo = JSON.parse(dropInfo?.draggedEl.getAttribute('data'))
      if (modalRef.current) {
        let dragpostdata = {
          "start": dropInfo?.dateStr,
          "title": dragdatainfo?.Activity,
          "id": dragdatainfo?._id
        }
        modalRef.current.openModal();
        setDragdata(dragpostdata)
      }
    } catch (err) {
      console.log(err, "Err")
    }

  };
  let dragupdatedata = async () => {
    try {
      let GetloadingID = loading()
      let postdata = {
        "start": dragdata?.start,
        "title": dragdata?.title,
        "id": dragdata?.id
      }
      let res = await _Api(postdata, `api/v1/App/calender/update-date-drag`)
      if (res?.code === 200) {
        setTaskbarlistupdate(true)
        getcalenderlist()
        if (modalRef.current) {
          modalRef.current.closeModal();
        }
        Updateloading(t, GetloadingID, (res?.message))
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');

      }
    } catch (err) {
      console.log(err, "Err")
    }
  }

  const handleTitleClick = () => {
    if (currentView?.value === 'dayGridMonth') {
      setShowTitleCalendar(!showTitleCalendar)
    }
  };
  const handleTodayButtonClick = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.today();
    setCalendarTitle(calendarRef?.current?.calendar?.currentData?.viewTitle)
    setShowTitleCalendar(false);
  };

  const handlePrevButtonClick = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    setCalendarTitle(calendarRef?.current?.calendar?.currentData?.viewTitle)
    setShowTitleCalendar(false);
  };

  const handleNextButtonClick = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    setCalendarTitle(calendarRef?.current?.calendar?.currentData?.viewTitle)
    setShowTitleCalendar(false);
  };
  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setShowTitleCalendar(false);
    const calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(date);
    calendarApi.setOption('title', date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' }));
    setCalendarTitle(calendarRef?.current?.calendar?.currentData?.viewTitle)
  };
  return (
    <>
      <div className='d-flex w-100 position-relative overflow-hidden'>
        <div className="fullcanlendar-main-headerDropdown">
          <div className={`fullcanlendar-main-headerDropdown-calendartitle ${activePeriod === 'month' ? 'active' : ''}`} >
            <div className='cursor-pointer' onClick={handleTitleClick}><span>{calendarTitle}</span></div>
            {showTitleCalendar && activePeriod === 'Month' ?

              <div className='fullcanlendar-main-headerDropdown-calendarselector'>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  open
                /></div>
              : null
            }
          </div>

          <div ref={perioddropdownRef}>
            <div className='fullcanlendar-main-headerDropdown-title' onClick={() => setShowPeriodDropdown(!showPeriodDropdown)}>
              <span className='text-capitalize'>
                {t(activePeriod)}
              </span>
              <span className={showPeriodDropdown ? 'rotate-90' : "rotate-180"}><RightIcon color="#000000" />
              </span>
            </div>
            {showPeriodDropdown ?
              <div className='fullcanlendar-main-headerDropdown-content'>
                <span className='fullcanlendar-main-headerDropdown-content-head uppercase'>{t("Time Period")}</span>
                <div className='fullcanlendar-main-headerDropdown-content-list'>
                  <div className={`fullcanlendar-main-headerDropdown-content-list-item cursor-pointer ${activePeriod === 'Day' ? 'active' : ''}`}
                    onClick={() => { setCurrentView({ label: 'Day', value: 'timeGridDay' }); setActivePeriod('Day'); setShowPeriodDropdown(false); setShowTitleCalendar(false) }}>
                    <span>{t("Day")}</span>
                    <span>{t("D")}</span>
                  </div>
                  <div className={`fullcanlendar-main-headerDropdown-content-list-item cursor-pointer ${activePeriod === 'Week' ? 'active' : ''}`}
                    onClick={() => { setCurrentView({ label: 'Week', value: 'timeGridWeek' }); setActivePeriod('Week'); setShowPeriodDropdown(false); setShowTitleCalendar(false) }}>
                    <span>{t("Week")}</span>
                    <span>{t("W")}</span>
                  </div>
                  <div className={`fullcanlendar-main-headerDropdown-content-list-item cursor-pointer ${activePeriod === 'Month' ? 'active' : ''}`}
                    onClick={() => { setCurrentView({ label: 'Month', value: 'dayGridMonth' }); setActivePeriod('Month'); setShowPeriodDropdown(false); setShowTitleCalendar(false) }}>
                    <span>{t("Month")}</span>
                    <span>{t("M")}</span>
                  </div>
                </div>
              </div>
              : ""}
          </div>
        </div>
        <div className={`fullcanlendar-main`}>
          <FullCalendar
            key={currentView?.value || rerenderKey}
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'customTodayButton,customPrevButton,customNextButton',
              center: '',
              right: '',
            }}
            initialView={currentView?.value}
            selectMirror={true}
            events={calendarlist}
            droppable={true}
            eventContent={renderEventContent}
            eventClick={handleEventClick}
            drop={(info) => {
              handleEventDrop(info)
            }}
            customButtons={{
              customTodayButton: {
                text: t('Today'),
                click: handleTodayButtonClick,
              },
              customPrevButton: {
                icon: "",
                click: handlePrevButtonClick,
              },
              customNextButton: {
                icon: "",
                click: handleNextButtonClick,
              },
            }}
          />
        </div>
        {/* {taskbardata?.runapi && */}
        <TaskSidebar
          taskbardata={taskbardata}
          setTaskbardata={setTaskbardata}
          getcalenderlist={getcalenderlist}
          taskbarlistupdate={taskbarlistupdate}
          setTaskbarlistupdate={setTaskbarlistupdate}
        />
        {/* } */}
        <ConfirmDragDropdown ref={modalRef} dragupdatedata={dragupdatedata} />

      </div>
      {showFilter ?
        <div> <CalendarFilter handleShowFilter={handleShowFilter} setApplyfilter={setApplyfilter} applyfilter={applyfilter} />
        </div> : ""}
    </>
  )
}
function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <div className='cursor-pointer'>
        <i>{eventInfo.event.title}</i>
        <i>{`(${eventInfo.event.extendedProps.count})`} </i>
      </div>

    </>
  )
}
