import { jsPDF } from "jspdf";
import { getBarcodeBySku, getBarcodeByStockId, getQrcodeByProductId, getQrcodeByStockId } from "../helpers/barcodeHelper";
import { _Api } from "components/api/_call";
import { dataListExample } from "./dataListExample";


export const CustomTemplateExample = async (config = {},customTemplateConfig) => {
   let barcode = await _Api({ barcodedata: ["SB03218KYGDM-45"] , dataType:"sku" }, "api/v1/barcode/create")
    // let barcode = barcodeData.data

    let dataList = dataListExample
    console.log(barcode,'CustomTemplateExample')
    console.log(customTemplateConfig,'customTemplateConfig')

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }
    
      console.log(dataList)
      const fontSizeStoneBylength = {
        "2": 5,
        "3": 4,
        "4": 3,
        "5": 3
      }
    
      const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: [75, 13]
      });
    
      // console.log(InventoryList,'InventoryList')
      dataList.map((v, k) => {
        console.log(v.stock_id, 'vmap')
    
        doc.setFont('helvetica', 'bold');
    
        doc.setFontSize('6');
        doc.text(v.SKU, customTemplateConfig.sku.position.x, customTemplateConfig.sku.position.y, 0);
    
        doc.setFontSize('10');
    
    
        if (config.barcode === true) {
    
         doc.addImage(getBarcodeBySku(barcode.data, v.SKU), 'PNG', customTemplateConfig.barcode.position.x, customTemplateConfig.barcode.position.y, customTemplateConfig.barcode.size.width, customTemplateConfig.barcode.size.height, null, "SLOW");
         
        }
    
        // doc.addFont('ComicSansMS', 'Comic Sans', 'normal','StandardEncoding');
    
        doc.setFontSize('6');
        doc.setFont('helvetica', 'bold');
    
    
        // Remove the currency symbol and commas
    
    
    
        doc.text(v.stock_id, customTemplateConfig.stock_id.position.x, customTemplateConfig.stock_id.position.y, 0);
    
        if (config.metal === true) {
          if(v.metal_code){
                  doc.text(v.metal_code, customTemplateConfig.metal.position.x, customTemplateConfig.metal.position.y, 0);
    
          }
        }
    
    
        let numericString ;
        if(isNaN(v.price)){
          numericString = v.price.replace(/[^0-9.]/g, '');
        }else{
          numericString = v.price;
        }
    
        if (config.price === true) {
    
    if(v.price){
    
      if (config.price_display==="with_currency") {
        if (isNaN(numericString)) {
          doc.text(v.currency + " " +numberWithCommas(parseInt(numericString)), customTemplateConfig.price.position.x, customTemplateConfig.price.position.y, 0);
    
        } else {
          console.log('else esle')
          doc.text(v.currency + " " + numberWithCommas(parseInt(numericString)), customTemplateConfig.price.position.x, customTemplateConfig.price.position.y, 0);
    
        }
    
      } else {
        if (isNaN(numericString)) {
          console.log('mmm')
          doc.text(numberWithCommas(numericString), customTemplateConfig.price.position.x, customTemplateConfig.price.position.y, 0);
    
        }else{
          doc.text(numberWithCommas(parseFloat(numericString).toFixed(2)), customTemplateConfig.price.position.x, customTemplateConfig.price.position.y, 0);
    
        }
      }
      
    
    
    }
       
    
        }
        const nwt = v.NetWt + " G"
        const gwt = v.GrossWt + " G"
    
        if (config.weight === true) {
          if (config.weight_display === "nwt") {
            doc.text(nwt, customTemplateConfig.weight.position.x, customTemplateConfig.weight.position.y, 0);
            // doc.text( nwt, 47 ,12, 0);
    
          } else {
            doc.text(gwt, customTemplateConfig.weight.position.x, customTemplateConfig.weight.position.y, 0); //for no currency layout
            // doc.text( gwt, 47 ,12, 0);
    
          }
        }
    
        function checkType(value) {
          if (Array.isArray(value)) {
            return "Array";
          } else if (typeof value === "object" && value !== null) {
            return "Object";
          } else {
            return "Neither Object nor Array";
          }
        }
    let stone_list ;
    if(checkType( v.Stones_Data)  === "Array"){
      stone_list = v.Stones_Data
    }else{
      stone_list = []
      stone_list.push(v.Stones_Data)
    }
    console.log(stone_list,'stone_list')
    let list_stone = {};
    
    stone_list.map((v,k,arr)=>{
    
    // const key = `${config.stone_name&&v.stone_details._id?v.stone_details._id:"no_sc"}_${config.stone_group&&v.stone_group_details._id?v.stone_group_details._id:"no_sg"}_${config.clarity&&v.stone_clarity_details._id?v.stone_clarity_details._id:"no_clar"}_${config.shape&&v.stone_shape_details._id?v.stone_shape_details._id:"no_sh"}`;
    const key = `${config.stone_name&&v.stone_details?v.stone_details._id:"no_sc"}_${config.stone_group&&v.stone_group_details?v.stone_group_details._id:"no_sg"}_${config.clarity&&v.stone_clarity_details?v.stone_clarity_details._id:"no_clar"}_${config.shape&&v.stone_shape_details._id?v.stone_shape_details._id:"no_sh"}_${config.cut&&v.stone_cut_details?v.stone_cut_details._id:"no_cut"}_${config.color&&v.stone_color_details?v.stone_color_details._id:""}_${config.stone_size&&v.stone_size_details?v.stone_size_details._id:""}`;
    
      if (!list_stone[key]) {
    
        list_stone[key] = {...v};
    } else {
      console.log(list_stone,'else ees')
      // console.log(merged[key],' merged[key]')
      // console.log(merged[key].Cts,' merged[key].Cts')
      // console.log(stone.Cts,' stone.Cts')
      list_stone[key].Cts = parseFloat(parseFloat(list_stone[key].Cts)+ parseFloat(v.Cts)).toFixed(2) ;
      list_stone[key].Pcs = parseInt(list_stone[key].Pcs) + parseInt(v.Pcs);
    
    }
     
    })
    const stone_merge_array = Object.values(list_stone).map(stone => ({
      ...stone,
     
    }));
    
    stone_merge_array.map((v, k, arr) => {
         
          if (v.stone_details.code) {
            if (v.stone_details.code.length >= v.stone_shape_details.code.length) {
              doc.setFontSize(fontSizeStoneBylength[v.stone_details.code.length])
            } else {
              doc.setFontSize(fontSizeStoneBylength[v.stone_shape_details.code.length]);
            }
          }
       
          let space = customTemplateConfig.stone_data.line_space_offset;
          // const text = v.stone_code+" "+v.shape_code+" "+" "+v.Pcs+" "+v.Cts;
    
          if (config.stone_name === true) {
            if (config.stone_group === true){
              doc.text(v.stone_details.code, customTemplateConfig.stone_data.stone_name.position.x, space * (k + 1), 0);
    
              doc.text(v.stone_group_details.code, customTemplateConfig.stone_data.stone_group.position.x, space * (k + 1), 0);
    
            }else{
              if(config.clarity === false){
                          doc.text(v.stone_details.code, customTemplateConfig.stone_data.stone_name.position.x, space * (k + 1), 0);
    
              }
           
            }
    
          }else{
            if(config.clarity === false){
                      if(config.stone_group === true){
                      doc.text(v.stone_group_details.code, customTemplateConfig.stone_data.stone_group.position.x, space * (k + 1), 0);
    
                      }
            }
          }
    
          if (config.stone_group === true&&config.stone_name === false) {
                  
                            doc.text(v.stone_details.code, customTemplateConfig.stone_data.stone_name.position.x, space * (k + 1), 0);
    
            
    
          }
    
    
    
    
          if (config.clarity === true && config.stone_group === false && config.stone_name === false ) {
            if(v.clarity){
            doc.text(v.stone_clarity_details.code, customTemplateConfig.stone_data.clarity.position.x, space * (k + 1),);
    
    
            }
    
        }
    
    if(v.shape){
      if (config.shape === true) {
            if(config.clarity === true){
    
              doc.text(v.stone_shape_details.code, customTemplateConfig.stone_data.shape.position.x, space * (k + 1),);
    
            }
        }
        if(config.stone_group === false&&config.stone_name === false){
          doc.text(v.stone_shape_details.code,customTemplateConfig.stone_data.shape.position.x, space * (k + 1),);
        }
    }
          
    
    if(v.color){
      if (config.color === true) {
        if(v.stone_color_details){
          doc.text(v.stone_color_details.code,customTemplateConfig.stone_data.color.position.x , space * (k + 1),);
    
        }
    
        }
    }
          
    
    if(v.Cut){
      if (config.cut === true) {
        if(v.stone_cut_details){
        doc.text(v.stone_cut_details.code, customTemplateConfig.stone_data.cut.position.x, space * (k + 1),);
    
        }
      }
    }
          
          
    
        if(v.Size){
          if (config.stone_size === true) {
            doc.text(v.stone_size_details.code?v.stone_size_details.code:"", customTemplateConfig.stone_data.stone_size.position.x, space * (k + 1),);
          }
    
        }
         
        if(v.Pcs){
    
          if (config.stone_pcs === true) {
            doc.text(v.Pcs.toString(), customTemplateConfig.stone_data.stone_pcs.position.x, space * (k + 1));
    
          }
    
        }
    
          if (config.stone_weight === true) {
    
            doc.text(v.Cts.toString() + v.Unit.substring(0, 1).toLowerCase(), customTemplateConfig.stone_data.stone_weight.position.x, space * (k + 1),);
    
          }
        })
    
        if (k + 1 < dataList.length) {
          doc.addPage();
    
        }
      })
      return doc.output('datauristring');
    //   switch (returnType) {
    //     case "save":
    //       return doc.save("barcode.pdf");
     
    //     case "uri":
    //       return doc.output('datauristring');
       
    //     case "printout":
    //       doc.autoPrint({ variant: 'non-conform' })
    //       return doc.output('dataurlnewwindow');
    //       // return doc.addJS('print({});');
    
    
    
    //     default:
    //       return doc.save("barcode.pdf");
    //   }
    

}

export const CustomTemplate = async (barcode, dataList, qrcodedata, qrcode = "offline", config = {}, returnType = "save",customTemplateConfig) => {
    
    
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }
    
      console.log(dataList)
      const fontSizeStoneBylength = {
        "2": 5,
        "3": 4,
        "4": 3,
        "5": 3
      }
    
      const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: [75, 13]
      });
    
      // console.log(InventoryList,'InventoryList')
      dataList.map((v, k) => {
        console.log(v.stock_id, 'vmap')
    
        doc.setFont('helvetica', 'bold');
    
        doc.setFontSize('6');
        doc.text(v.SKU, customTemplateConfig.sku.position.x, customTemplateConfig.sku.position.y, 0);
    
        doc.setFontSize('10');
    
    
        if (config.barcode === true) {
    
         doc.addImage(getBarcodeBySku(barcode.data, v.SKU), 'PNG', customTemplateConfig.barcode.position.x, customTemplateConfig.barcode.position.y, customTemplateConfig.barcode.size.width, customTemplateConfig.barcode.size.height, null, "SLOW");
         
        }
    
        // doc.addFont('ComicSansMS', 'Comic Sans', 'normal','StandardEncoding');
    
        doc.setFontSize('6');
        doc.setFont('helvetica', 'bold');
    
    
        // Remove the currency symbol and commas
    
    
    
        doc.text(v.stock_id, customTemplateConfig.stock_id.position.x, customTemplateConfig.stock_id.position.y, 0);
    
        if (config.metal === true) {
          if(v.metal_code){
                  doc.text(v.metal_code, customTemplateConfig.metal.position.x, customTemplateConfig.metal.position.y, 0);
    
          }
        }
    
    
        let numericString ;
        if(isNaN(v.price)){
          numericString = v.price.replace(/[^0-9.]/g, '');
        }else{
          numericString = v.price;
        }
    
        if (config.price === true) {
    
    if(v.price){
    
      if (config.price_display==="with_currency") {
        if (isNaN(numericString)) {
          doc.text(v.currency + " " +numberWithCommas(parseInt(numericString)), customTemplateConfig.price.position.x, customTemplateConfig.price.position.y, 0);
    
        } else {
          console.log('else esle')
          doc.text(v.currency + " " + numberWithCommas(parseInt(numericString)), customTemplateConfig.price.position.x, customTemplateConfig.price.position.y, 0);
    
        }
    
      } else {
        if (isNaN(numericString)) {
          console.log('mmm')
          doc.text(numberWithCommas(numericString), customTemplateConfig.price.position.x, customTemplateConfig.price.position.y, 0);
    
        }else{
          doc.text(numberWithCommas(parseFloat(numericString).toFixed(2)), customTemplateConfig.price.position.x, customTemplateConfig.price.position.y, 0);
    
        }
      }
      
    
    
    }
       
    
        }
        const nwt = v.NetWt + " G"
        const gwt = v.GrossWt + " G"
    
        if (config.weight === true) {
          if (config.weight_display === "nwt") {
            doc.text(nwt, customTemplateConfig.weight.position.x, customTemplateConfig.weight.position.y, 0);
            // doc.text( nwt, 47 ,12, 0);
    
          } else {
            doc.text(gwt, customTemplateConfig.weight.position.x, customTemplateConfig.weight.position.y, 0); //for no currency layout
            // doc.text( gwt, 47 ,12, 0);
    
          }
        }
    
        function checkType(value) {
          if (Array.isArray(value)) {
            return "Array";
          } else if (typeof value === "object" && value !== null) {
            return "Object";
          } else {
            return "Neither Object nor Array";
          }
        }
    let stone_list ;
    if(checkType( v.Stones_Data)  === "Array"){
      stone_list = v.Stones_Data
    }else{
      stone_list = []
      stone_list.push(v.Stones_Data)
    }
    console.log(stone_list,'stone_list')
    let list_stone = {};
    
    stone_list.map((v,k,arr)=>{
    
    // const key = `${config.stone_name&&v.stone_details._id?v.stone_details._id:"no_sc"}_${config.stone_group&&v.stone_group_details._id?v.stone_group_details._id:"no_sg"}_${config.clarity&&v.stone_clarity_details._id?v.stone_clarity_details._id:"no_clar"}_${config.shape&&v.stone_shape_details._id?v.stone_shape_details._id:"no_sh"}`;
    const key = `${config.stone_name&&v.stone_details?v.stone_details._id:"no_sc"}_${config.stone_group&&v.stone_group_details?v.stone_group_details._id:"no_sg"}_${config.clarity&&v.stone_clarity_details?v.stone_clarity_details._id:"no_clar"}_${config.shape&&v.stone_shape_details._id?v.stone_shape_details._id:"no_sh"}_${config.cut&&v.stone_cut_details?v.stone_cut_details._id:"no_cut"}_${config.color&&v.stone_color_details?v.stone_color_details._id:""}_${config.stone_size&&v.stone_size_details?v.stone_size_details._id:""}`;
    
      if (!list_stone[key]) {
    
        list_stone[key] = {...v};
    } else {
      console.log(list_stone,'else ees')
      // console.log(merged[key],' merged[key]')
      // console.log(merged[key].Cts,' merged[key].Cts')
      // console.log(stone.Cts,' stone.Cts')
      list_stone[key].Cts = parseFloat(parseFloat(list_stone[key].Cts)+ parseFloat(v.Cts)).toFixed(2) ;
      list_stone[key].Pcs = parseInt(list_stone[key].Pcs) + parseInt(v.Pcs);
    
    }
     
    })
    const stone_merge_array = Object.values(list_stone).map(stone => ({
      ...stone,
     
    }));
    
    stone_merge_array.map((v, k, arr) => {
         
          if (v.stone_details.code) {
            if (v.stone_details.code.length >= v.stone_shape_details.code.length) {
              doc.setFontSize(fontSizeStoneBylength[v.stone_details.code.length])
            } else {
              doc.setFontSize(fontSizeStoneBylength[v.stone_shape_details.code.length]);
            }
          }
       
          let space = customTemplateConfig.stone_data.line_space_offset;
          // const text = v.stone_code+" "+v.shape_code+" "+" "+v.Pcs+" "+v.Cts;
    
          if (config.stone_name === true) {
            if (config.stone_group === true){
              doc.text(v.stone_details.code, customTemplateConfig.stone_data.stone_name.position.x, space * (k + 1), 0);
    
              doc.text(v.stone_group_details.code, customTemplateConfig.stone_data.stone_group.position.x, space * (k + 1), 0);
    
            }else{
              if(config.clarity === false){
                          doc.text(v.stone_details.code, customTemplateConfig.stone_data.stone_name.position.x, space * (k + 1), 0);
    
              }
           
            }
    
          }else{
            if(config.clarity === false){
                      if(config.stone_group === true){
                      doc.text(v.stone_group_details.code, customTemplateConfig.stone_data.stone_group.position.x, space * (k + 1), 0);
    
                      }
            }
          }
    
          if (config.stone_group === true&&config.stone_name === false) {
                  
                            doc.text(v.stone_details.code, customTemplateConfig.stone_data.stone_name.position.x, space * (k + 1), 0);
    
            
    
          }
    
    
    
    
          if (config.clarity === true && config.stone_group === false && config.stone_name === false ) {
            if(v.clarity){
            doc.text(v.stone_clarity_details.code, customTemplateConfig.stone_data.clarity.position.x, space * (k + 1),);
    
    
            }
    
        }
    
    if(v.shape){
      if (config.shape === true) {
            if(config.clarity === true){
    
              doc.text(v.stone_shape_details.code, customTemplateConfig.stone_data.shape.position.x, space * (k + 1),);
    
            }
        }
        if(config.stone_group === false&&config.stone_name === false){
          doc.text(v.stone_shape_details.code,customTemplateConfig.stone_data.shape.position.x, space * (k + 1),);
        }
    }
          
    
    if(v.color){
      if (config.color === true) {
        if(v.stone_color_details){
          doc.text(v.stone_color_details.code,customTemplateConfig.stone_data.color.position.x , space * (k + 1),);
    
        }
    
        }
    }
          
    
    if(v.Cut){
      if (config.cut === true) {
        if(v.stone_cut_details){
        doc.text(v.stone_cut_details.code, customTemplateConfig.stone_data.cut.position.x, space * (k + 1),);
    
        }
      }
    }
          
          
    
        if(v.Size){
          if (config.stone_size === true) {
            doc.text(v.stone_size_details.code?v.stone_size_details.code:"", customTemplateConfig.stone_data.stone_size.position.x, space * (k + 1),);
          }
    
        }
         
        if(v.Pcs){
    
          if (config.stone_pcs === true) {
            doc.text(v.Pcs.toString(), customTemplateConfig.stone_data.stone_pcs.position.x, space * (k + 1));
    
          }
    
        }
    
          if (config.stone_weight === true) {
    
            doc.text(v.Cts.toString() + v.Unit.substring(0, 1).toLowerCase(), customTemplateConfig.stone_data.stone_weight.position.x, space * (k + 1),);
    
          }
        })
    
        if (k + 1 < dataList.length) {
          doc.addPage();
    
        }
      })

      
      switch (returnType) {
        case "save":
          return doc.save("barcode.pdf");
     
        case "uri":
          return doc.output('datauristring');
       
        case "printout":
          doc.autoPrint({ variant: 'non-conform' })
          return doc.output('dataurlnewwindow');
          // return doc.addJS('print({});');
    
    
    
        default:
          return doc.save("barcode.pdf");
      }
    

}