import CookieConsent from 'Ecommerce/template_four/components/Cookie/CookieConsent';
import useScreenSize from 'Ecommerce/template_four/include/Common/useScreenSize';
import Footer from 'Ecommerce/template_four/include/Footer';
import HeaderNew from 'Ecommerce/template_four/include/HeaderNew';
import CustomSwitch from '../PublicCustomSwitch.js';
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Outlet, Route, useLocation } from 'react-router-dom';
import HomePage from 'Ecommerce/template_four/Pages/HomePage';
import MainPage from 'Ecommerce/template_four/Pages/Main/MainPage.js';
import ProductPage from 'Ecommerce/template_four/Pages/Productpage/ProductPage.js';
import ShoppinCart from 'Ecommerce/template_four/Pages/Cart/ShoppinCart';
import MainWishlistPage from 'Ecommerce/template_four/Pages/wishlist/MainWishlistPage.js';
import AppointmentPage from 'Ecommerce/template_four/Pages/Appointmen/AppointmentPage.js';
import Shipping from 'Ecommerce/template_four/Pages/Shipping/Shipping.js';
import AccountIndexPage from 'Ecommerce/template_four/Pages/Account/AccountIndexPage.js';
import AccountMainPage from 'Ecommerce/template_four/Pages/Account/AccountMainPage.js';
import AccountBillingAndShipping from 'Ecommerce/template_four/Pages/Account/AccountBillingAndShipping.js';
import AccountAddressAdd from 'Ecommerce/template_four/Pages/Account/AccountAddressAdd.js';
import AccountChangePassword from 'Ecommerce/template_four/Pages/Account/AccountChangePassword';
import AccountPurchaseHistory from 'Ecommerce/template_four/Pages/Account/AccountPurchaseHistory';
import AccountAppointmentPage from 'Ecommerce/template_four/Pages/Account/AccountAppointmentPage.js';
import MixAndMatchMainPage from 'Ecommerce/template_four/Pages/MixandMatch/MixAndMatchMainPage.js';
import LocationPage from 'Ecommerce/template_four/components/Location/LocationPage.js';
import LogOut from 'Ecommerce/template_four/auth/LogOut';
import { _Apiauth } from 'Ecommerce/common/api.js';
import AppointmentConfirm from 'Ecommerce/template_four/Pages/Appointmen/AppointmentConfirm.js';
import ConfirmPage from 'Ecommerce/template_four/Pages/Shipping/ConfirmPage.js';
import PolicyPage from 'Ecommerce/template_four/Pages/Policypage/PolicyPage.js'
export default function EcommerceRoutesFour() {
    const dataFetched = useRef();
    const [websiteinfo, setWebsiteinfo] = useState()
    const [showecomdata, setShowecomdata] = useState(false)
    const [openmodal, setOpenmodal] = useState({
        showLoginModel: false,
        showRegisterModel: false,
        showemailvarifymodal: false,
        forgetpassword: false,
        resetpassword: false,
        newpassword: false
    })
    const [newpassworddata, SetNewpassworddata] = useState()
    const [forgetemail, setForgetemail] = useState()
    const [emailverifydata, setEmailVerifydata] = useState()
    const ecominfo = async () => {
        try {
            let postdata = {}
            let res = await _Apiauth(postdata, `info`)
            if (res?.statusCode === 200) {
                setWebsiteinfo(res?.data)
            }
            setShowecomdata(true)
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const formatefunction = async () => {
        try {
            let postdata = {}
            let res = await _Apiauth(postdata, `formatefunction`)
            if (res?.statusCode === 200) {
                var jsonData = JSON.stringify(res?.data);
                localStorage.setItem("formatefunction", jsonData);
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    useEffect(() => {
        if (dataFetched.current) return;
        dataFetched.current = true
        ecominfo()
        formatefunction()
    }, [])
    const AdminReactActive = function () {

        return (
            <>
                <div className={openmodal.showLoginModel || openmodal.showRegisterModel || openmodal.showemailvarifymodal || openmodal.forgetpassword || openmodal.resetpassword || openmodal.newpassword ? "ecommerce-main-layout overflow-hidden vh-100" : "ecommerce-main-layout"}>
                    {showecomdata ?
                        <>
                            <div className="ecommerce-main-layout-header">
                                <HeaderNew
                                    websiteinfo={websiteinfo}
                                    openmodal={openmodal}
                                    setOpenmodal={setOpenmodal}
                                    SetNewpassworddata={SetNewpassworddata}
                                    newpassworddata={newpassworddata}
                                    setForgetemail={setForgetemail}
                                    forgetemail={forgetemail}
                                    setEmailVerifydata={setEmailVerifydata}
                                    emailverifydata={emailverifydata}
                                />

                            </div>
                            <Outlet />
                            <div className="">
                                <Footer websiteinfo={websiteinfo} />
                            </div>
                        </>
                        : ""}
                    <CookieConsent />
                </div>
            </>
        );

    }; 
  return (
    <>
    <Helmet>
        <title>{websiteinfo?.websiteInfo?.metaTitle}</title>
        <link rel="icon" type="image/png" href={websiteinfo?.websiteInfo?.favicon} sizes="16x16" />
        <meta name="description" content={websiteinfo?.websiteInfo?.metaDescription} />
    </Helmet>
    <CustomSwitch>
        <Route element={<AdminReactActive />}>
            <Route path="/" element={<HomePage />} />
            <Route path='/page/:token' element={<PolicyPage/>} />
            <Route path="/Collection" element={<MainPage/>} />
            {/* <Route path="/Collection/:token" element={<MainPage/>} />
            <Route path="/Category/:token" element={<MainPage />} />
            <Route path="/Style/:token" element={<MainPage />} /> */}
            <Route path="/:mainCategory" element={<MainPage />} />
            <Route path="/:mainCategory/:subCategory" element={<MainPage />} />
            <Route path="/product/:token" element={<ProductPage />} />
            <Route path="/shoppingcart" element={<ShoppinCart />} />
            <Route path="/wishlist" element={<MainWishlistPage />} />
            <Route path="/appointment" element={<AppointmentPage />} />
            <Route path="/shipping" element={<Shipping setOpenmodal={setOpenmodal} />} />
            <Route path="/appointmentconfirm" element={<AppointmentConfirm />} />
            <Route path="/account" element={<AccountIndexPage />} />
            <Route path="/account/details" element={<AccountMainPage />} />
            <Route path="/account/address" element={<AccountBillingAndShipping />} />
            <Route path="/account/addressdetails" element={<AccountAddressAdd />} />
            <Route path="/account/changepassword" element={<AccountChangePassword />} />
            <Route path="/account/purchasehistory" element={<AccountPurchaseHistory />} />
            <Route path="/account/appointmentdata" element={<AccountAppointmentPage />} />
            <Route path="/mixandmatch" element={<MixAndMatchMainPage />} />
            <Route path='/location' element={<LocationPage/>}/>
            <Route path="/logout" element={<LogOut />} />
            <Route path='/confirmpurchase' element={<ConfirmPage/>}/>
        </Route>
    </CustomSwitch>
</>
  )
 
}
