import React, { useEffect, useState } from 'react'
import CrossIcon from '../assets/icons/CrossIcon';
import EditIcon from '../assets/icons/EditIcon';
import { _Api } from '../../common/api';
import DeleteIcon from '../assets/icons/DeleteIcon';
import { useTranslation } from "react-i18next";
const ShippingAddrModel = (props) => {
    const [addresslist, setAdresslist] = useState([])
    const { t } = useTranslation();
    const [changeaddress, setChangeaddress] = useState()
    const { shipaddress, setShipaddress, setUserdata, setFromshow, setBillingaddress, dobvalues } = props
    const addressadd = async () => {
        try {
            let postdata = {}
            let res;
            if (shipaddress?.ship) {
                res = await _Api(postdata, `checkout/list-shipping`)
            } else if (shipaddress?.billing) {
                res = await _Api(postdata, `checkout/list-billing`)
            }
            if (res?.statusCode === 200) {
                let array = [...res?.data]
                array.forEach((result) => {
                    (
                        result.date_of_birth = {
                            date: result?.date_of_birth?.date,
                            month: dobvalues?.month.find(element => element?.value === result?.date_of_birth?.month),
                            year: result?.date_of_birth?.year
                        }
                    )
                    delete result.billing_address
                })
                setAdresslist(array)
            }

        }
        catch (err) {
            console.log(err, "err")
        }
    }
    useEffect(() => {
        if (shipaddress?.ship || shipaddress?.billing) {
            addressadd()
        }
        // eslint-disable-next-line
    }, [shipaddress])

    const deleteaddress = async (id, billing_address) => {
        try {
            let postdata = {
                "id": id,
                "billing_address": billing_address ? billing_address : false
            }
            let res = await _Api(postdata, `account/deleteAddress`)
            if (res?.statusCode === 200) {
                addressadd()
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    return (
        <div
            className="modal fade common-modal"
            id="shiipingAddrmodal"
            tabIndex={-1}
            aria-labelledby="shiipingAddrmodalLabel"
            data-bs-backdrop="static"
        >
            <div className="modal-dialog ">
                <div className="modal-content appointmentModal-contentBox ">
                    <div className="modal-header appointmentModal-contentBox-headerBox mb-3">
                        <h3 >{shipaddress?.ship ? t("Shipping Address") : t("Billing Address")}</h3>
                        <button className="modalCross-btn" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => setShipaddress({ ship: false, billing: false })}  ><CrossIcon width={17.41} height={17.41} /></button>
                    </div>
                    <div className="modal-body ">
                        <div className='d-flex flex-column gap-3 overflow-auto' style={{ maxHeight: '430px' }}>
                            {addresslist?.length ? addresslist.map((result, key) => {
                                return (
                                    <div style={{ border: '1px solid #D2D5D9', padding: '16px' }} key={key}>
                                        <div className="com-radiobtnShowBox">
                                            <input type="radio" className="com-radiobtnShowBox-inputField" id={key + "address"} name="shippingAddrss"
                                                onClick={() => setChangeaddress(result)} />
                                            <label className="com-radiobtnShowBox-labeltext w-100" htmlFor={key + "address"}>
                                                <div className="com-addressShowBox ">
                                                    <div className="com-addressShowBox-headerBox">
                                                        <h3 className="com-addressShowBox-headerBox-heading">
                                                            {result?.fname + " " + result?.lname}
                                                        </h3>
                                                        <div className="com-addressShowBox-headerBox-iconBox" >
                                                            {shipaddress?.ship ?
                                                                <span
                                                                    data-bs-dismiss="modal" aria-label="Close"
                                                                    onClick={(e) => {
                                                                        setUserdata((state) => ({
                                                                            ...state,
                                                                            shipping_address: result
                                                                        }));
                                                                        setFromshow(true);
                                                                        setShipaddress({ ship: false, billing: false })
                                                                    }
                                                                    }>
                                                                    <EditIcon />
                                                                    {!result?.is_default ?
                                                                        <span onClick={() => deleteaddress(result?.UDID, false)} className='ms-2'><DeleteIcon width={20} height={20} color={'#DA1414'} /></span>
                                                                        : ""}
                                                                </span> :
                                                                shipaddress?.billing ?
                                                                    <span
                                                                        data-bs-dismiss="modal" aria-label="Close"
                                                                        onClick={(e) => {
                                                                            setUserdata((state) => ({
                                                                                ...state,
                                                                                billingaddress: result
                                                                            }));
                                                                            setShipaddress({ ship: false, billing: false });
                                                                            setBillingaddress(false)
                                                                        }
                                                                        }>
                                                                        <EditIcon />
                                                                        {!result?.is_default ?
                                                                            <span onClick={() => deleteaddress(result?.UDID, false)} className='ms-2'><DeleteIcon width={20} height={20} color={'#DA1414'} /></span>
                                                                            : ""}
                                                                    </span>

                                                                    : ""
                                                            }
                                                        </div>
                                                    </div>
                                                    <p className="com-addressShowBox-AddrparaBox w-100 pe-3">
                                                        {result?.address}
                                                    </p>
                                                    <p className="com-addressShowBox-phonePara">{result?.contacts[0]['phoneCode'] ? `(+${result?.contacts[0]['phoneCode']}) ${result?.contacts[0]['number']}` : ""}</p>
                                                    {result?.is_default &&

                                                        <div className='com-ecomCancelBtn'>{t("Default Address")}</div>
                                                    }


                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                )
                            }) : <div className='text-center'>
                                {t(" Addresss is not Avalable")}
                            </div>}

                        </div>
                    </div>

                    {shipaddress?.ship ?
                        <div className=" appointmentModal-contentBox-footerBox mt-3"
                            data-bs-dismiss="modal" aria-label="Close">
                            <button className="com-ecomSendBtn w-100"
                                disabled={!changeaddress ? true : false}
                                onClick={(e) => {
                                    setUserdata((state) => ({
                                        ...state,
                                        shipping_address: changeaddress
                                    }));
                                    setShipaddress({ ship: false, billing: false })
                                }

                                }>
                                {shipaddress?.ship ? t("Add new Shipping Address") : t("Add new Billing Address")}
                            </button>
                        </div>
                        : shipaddress?.billing ?
                            <div className=" appointmentModal-contentBox-footerBox mt-3"
                                data-bs-dismiss="modal" aria-label="Close">
                                <button className="com-ecomSendBtn w-100"
                                    disabled={!changeaddress ? true : false}
                                    onClick={(e) => {
                                        setUserdata((state) => ({
                                            ...state,
                                            billingaddress: changeaddress
                                        }));
                                        setShipaddress({ ship: false, billing: false });
                                    }
                                    }
                                > {shipaddress?.ship ? t("Add new Shipping Address") : t("Add new Billing Address")}
                                </button>
                            </div>
                            : ""}
                </div>
            </div>
        </div >
    )
}

export default ShippingAddrModel
