import React from 'react'

const DownloadIcon = (props) => {
    const { width, height } = props;
    return (
        <svg width={width ? width : "24"} height={height ? height : "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3299_205579)">
                <path d="M2.5 18.5H21.5V19.5H2.5V18.5ZM16 10.793L12.5 14.293V4.5H11.5V14.293L8 10.793L7.293 11.5L12 16.207L16.707 11.5L16 10.793Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_3299_205579">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default DownloadIcon