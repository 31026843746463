import Sidebar from './Sidebar'
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import $ from 'jquery';
import { _Api } from 'Ecommerce/common/api';
import TableDropdown from 'Ecommerce/template_three/assets/icons/TableDropDown';
import Spinner from 'Ecommerce/common/Spinner';
import { useNavigate } from 'react-router-dom';
import useScreenSize from 'Ecommerce/template_three/include/Common/useScreenSize';
import LeftIcon from 'admin/common/icons/sidebar/LeftIcon';
import Calendar from 'admin/common/icons/sidebar/Calendar';
import Location from 'admin/common/icons/sidebar/Location';
import Barcode from 'Ecommerce/common/images/Barcode.png';
import DownloadIcon from 'admin/common/icons/DownloadIcon';
import ShareIcon from 'admin/common/icons/ShareIcon';
import SupportIcon from 'admin/common/icons/SupportIcon';
import CrossIcon from 'admin/common/icons/CrossIcon';

export default function AccountAppointmentPage() {
  const { t } = useTranslation();
  const [appoinmentlist, setAppoinmentlist] = useState([])
  const [loadeing, setloading] = useState(true);
  const navigate = useNavigate();
  const currentDate = new Date();
  const [activeCard, setActiveCard] = useState();
  const [activeHistoryCard, setActiveHistoryCard] = useState();
  const [subData, setSubData] = useState();
  console.log(currentDate, "Check Current Date")
  const screenSize = useScreenSize();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const getappoinmentdata = async () => {
    setloading(true)
    try {
      let postdata = {}
      let res = await _Api(postdata, `Appointment/get-Appointment`)
      if (res?.statusCode === 200) {
        setAppoinmentlist(res?.data)
        setloading(false)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  useEffect(() => {
    getappoinmentdata()
  }, [])
  const setSubTablecustom = async (id, product, location_id, currency) => {
    var e = document.querySelector("#table-btn_" + id);
    var row = document.querySelector(`common-main-table-wrap-${id}`);
    var classList_ = e.classList;
    if (classList_.contains("rotate-180")) {
      classList_.remove("rotate-180");
      let alllist_data = [...appoinmentlist];
      alllist_data.filter(item => item._id === id).forEach((result) => {
        result.subTabledata = []
        result.subTable = false
      })
      setAppoinmentlist(alllist_data);
    } else {
      classList_.add("rotate-180");
      let alllist_data = [...appoinmentlist];
      try {
        const postdata = {
          "Product": product,
          "location": location_id,
          "currency": currency ? currency : "USD"
        }
        let prioritydata = await _Api(postdata, `Appointment/get-Appointment-sublist`);
        if (prioritydata?.statusCode === 200) {
          alllist_data.filter(item => item._id === id).forEach((result) => {
            result.subTabledata = prioritydata?.data
            result.subTable = true

          })
          setAppoinmentlist(alllist_data);
          setSubData();
        } else {
          alllist_data.filter(item => item._id === id).forEach((result) => {
            result.subTabledata = []
            result.subTable = true
          })
          setAppoinmentlist(alllist_data);
        }
      } catch (err) {
        console.log(err, "err")
        alllist_data.filter(item => item._id === id).forEach((result) => {
          result.subTabledata = []
          result.subTable = true
        })
        setAppoinmentlist(alllist_data);
      }

      var checkRowSub = setInterval(function () {
        var rowSub = document.querySelector('#common-sub-table-wrap-' + id);
        if (rowSub) {
          $(row).after(rowSub);
          clearInterval(checkRowSub);
        }
      }, 100);

    }
  }
  return (
    <div className="account_page d-flex ecommerce-main-layout-bodyContent">
    <Sidebar/>
    <div className="account_details">
    {screenSize.width < 525 ?
    <div className="account_details_topbar">
        <div className='account_details_topbar-icon' onClick={()=>navigate('/account')}>
          <LeftIcon/>
        </div>
        <span>{t("My Appointment")}</span>
    </div>
    :null}
    {screenSize.width < 1200 && screenSize.width > 525 ?
    <div className="account_details_desktoptopbar">
        <div className='account_details_desktoptopbar-icon' onClick={()=>navigate('/account')}>
          <LeftIcon/>
        </div>
        <span>{t("My Appointment")}</span>
    </div>
    :null}
        <div className="address_appointment">
          <div className="address_appointment-headBox">
            <h2>{t("My Appointment")}</h2>
            <h6>{t("View your scheduled appointments or appointment history")}.</h6>
          </div>
          <div className="address_appointment-mainbox">
              <div className='address_appointment-mainbox-title'>
                <span>{t("Appointment Details")}</span>
              </div>
              <div className='address_appointment-mainbox-subtitle'>
                <span>{t("Coming up")}</span>
              </div>
              <div className='address_appointment-mainbox-appointmentcontainer'>
              { loadeing ? 
               <div className="com-ecomTableMain-table-tbody-trBox-noDataBox">
               <Spinner />
              </div>
              :
             <>
             { appoinmentlist?.length ? 
                  appoinmentlist.map((result, key) => {
                    return (
                      <div>
                        <div className={`address_appointment-mainbox-appointmentcontainer-item ${activeCard === key ? 'active' : ''}`} key={key} onClick={() => {console.log(activeCard); setActiveCard(activeCard === key ? null : key); setSubTablecustom(result?._id, result?.product, result?.location_id, result?.currency)}}>
                          <div className='address_appointment-mainbox-appointmentcontainer-item-left'>
                          {screenSize.width > 1200 ?
                            <span>
                            {result?.appointment_date ? result?.appointment_date : "-"}
                             {/* <span className='day'></span>
                            <span className='date'></span>
                            <span className='month'></span> */}
                            </span>
                           
                            :
                            <Calendar type="accountPage"/>
                            }
                            </div>
                          <div className='address_appointment-mainbox-appointmentcontainer-item-right'>
                          <div className='address_appointment-mainbox-appointmentcontainer-item-right-title'>{t("APPOINTMENT ID")} : #{t(`${result?.ref_no}`)}</div>
                          <div className='address_appointment-mainbox-appointmentcontainer-item-right-subdata'>
                              <span ><Calendar/> {result?.date ? result?.date : "-"} </span>
                              <span><Location filled={true}/> {result?.location ? result?.location : "-"}</span>
                          </div>
                          {screenSize.width < 1200 && <div className='btnwithborder'><span>{t("See details")}</span></div>}
                          </div>
                        </div>
                        <div className={`address_appointment-mainbox-appointmentcontainer-dropdown ${activeCard === key ? 'active' : ''}`}>
                          { activeCard === key ? 
                          <div className='address_appointment-mainbox-appointmentcontainer-dropdown-inner'>
                            <button className="crossIcon" onClick={() => {setActiveCard(null); setSubData(null)}}><CrossIcon width={17.41} height={17.41} /></button>
                              <div className='address_appointment-mainbox-appointmentcontainer-dropdown-inner-content'>
                                  <div className='address_appointment-mainbox-appointmentcontainer-dropdown-inner-content-title'>{t("APPOINTMENT ID")} : #{t(`${result?.ref_no}`)}</div>
                                  <div className='address_appointment-mainbox-appointmentcontainer-dropdown-inner-content-detail'>
                                    <span ><Calendar/> {result?.date ? result?.date : "-"} </span>
                                    <span><Location filled={true}/> {result?.location ? result?.location : "-"}</span>
                                  </div>
                                  <div className='address_appointment-mainbox-appointmentcontainer-dropdown-inner-content-metadata'>
                                    <span>{result?.name ? result?.name : "-"}</span>
                                    <span>{result?.email ? result?.email : '-'}</span>
                                    <span>{result?.contacts ? result?.contacts : '-'}</span>
                                  </div>
                                  <div className='address_appointment-mainbox-appointmentcontainer-dropdown-inner-content-barcode'>
                                    <span>{t("Barcode")}</span>
                                    <div className='address_appointment-mainbox-appointmentcontainer-dropdown-inner-content-barcode-image'>
                                      <img src={result?.barCode ? result?.barCode : Barcode} alt='img barcode'/></div>
                                  </div>
                              </div>
                              <div className='address_appointment-mainbox-appointmentcontainer-dropdown-inner-bottom'>
                                <div className='address_appointment-mainbox-appointmentcontainer-dropdown-inner-bottom-item'>
                                  <span><SupportIcon/></span>
                                  <span>{t("Support")}</span>
                                </div>
                                <div className='address_appointment-mainbox-appointmentcontainer-dropdown-inner-bottom-item'>
                                  <span><DownloadIcon/></span>
                                  <span>{t("Download")}</span>
                                </div>
                                <div className='address_appointment-mainbox-appointmentcontainer-dropdown-inner-bottom-item'>
                                  <span><ShareIcon/></span>
                                  <span>{t("Share")}</span>
                                </div>
                              </div>
                          </div>
                          : null}
                        </div>
                      </div>
                    )
                  }) 

                  :
                  <div className="com-ecomTableMain-table-tbody-trBox-noDataBox">
                  {t("No data available")}
                </div>
                }

             </>
              }
              </div>
              <div className='address_appointment-mainbox-subtitle'>
                <span>{t("History")}</span>
              </div>
              <div className='address_appointment-mainbox-appointmentcontainer'>
              { loadeing ? 
               <div className="com-ecomTableMain-table-tbody-trBox-noDataBox">
               <Spinner />
              </div>
              :
             <>
             { appoinmentlist?.length ? 
                  appoinmentlist.map((result, key) => {
                    return (
                      <div>
                        <div className={`address_appointment-mainbox-appointmentcontainer-item ${activeHistoryCard === key ? 'active' : ''} history`} key={key}  onClick={() => {setActiveHistoryCard(activeHistoryCard === key ? null : key); setSubTablecustom(result?._id, result?.product, result?.location_id, result?.currency)}}>
                          <div className='address_appointment-mainbox-appointmentcontainer-item-left'>
                            
                          {screenSize.width > 1200 ?
                            <span>
                            {result?.appointment_date ? result?.appointment_date : "-"}
                             {/* <span className='day'></span>
                            <span className='date'></span>
                            <span className='month'></span> */}
                            </span>
                           
                            :
                            <Calendar type="accountPage"/>
                            }
                            </div>
                          <div className='address_appointment-mainbox-appointmentcontainer-item-right'>
                          <div className='address_appointment-mainbox-appointmentcontainer-item-right-title'>{t("APPOINTMENT ID")} : #{t(`${result?.ref_no}`)}</div>
                          <div className='address_appointment-mainbox-appointmentcontainer-item-right-subdata'>
                              <span ><Calendar/> {result?.date ? result?.date : "-"} </span>
                              <span><Location filled={true}/> {result?.location ? result?.location : "-"}</span>
                          </div>
                         
                          </div>
                        </div>
                        <div className={`address_appointment-mainbox-appointmentcontainer-dropdown ${activeHistoryCard === key ? 'active' : ''}`}>
                        { activeHistoryCard === key ? 
                        <div className='address_appointment-mainbox-appointmentcontainer-dropdown-inner history'>
                           <button className='crossIcon' onClick={() => {setActiveHistoryCard(null); setSubData(null)}}><CrossIcon width={17.41} height={17.41} /></button>
                            <div className='address_appointment-mainbox-appointmentcontainer-dropdown-inner-content'>
                                <div className='address_appointment-mainbox-appointmentcontainer-dropdown-inner-content-title'>{t("APPOINTMENT ID")} : #{t(`${result?.ref_no}`)}</div>
                                <div className='address_appointment-mainbox-appointmentcontainer-dropdown-inner-content-detail'>
                                  <span ><Calendar/> {result?.date ? result?.date : "-"} </span>
                                  <span><Location filled={true}/> {result?.location ? result?.location : "-"}</span>
                                </div>
                                <div className='address_appointment-mainbox-appointmentcontainer-dropdown-inner-content-metadata'>
                                  <span>{result?.name ? result?.name : "-"}</span>
                                  <span>{result?.email ? result?.email : '-'}</span>
                                  <span>{result?.contacts ? result?.contacts : '-'}</span>
                                </div>
                                <div className='address_appointment-mainbox-appointmentcontainer-dropdown-inner-content-barcode'>
                                  <span>{t("Barcode")}</span>
                                  <div className='address_appointment-mainbox-appointmentcontainer-dropdown-inner-content-barcode-image'>
                                    <img src={result?.barCode ? result?.barCode : Barcode} alt='img barcode'/></div>
                                </div>
                            </div>
                            <div className='address_appointment-mainbox-appointmentcontainer-dropdown-inner-bottom'>
                              <div className='address_appointment-mainbox-appointmentcontainer-dropdown-inner-bottom-item'>
                                <span><SupportIcon/></span>
                                <span>{t("Support")}</span>
                              </div>
                              <div className='address_appointment-mainbox-appointmentcontainer-dropdown-inner-bottom-item'>
                                <span><DownloadIcon/></span>
                                <span>{t("Download")}</span>
                              </div>
                              <div className='address_appointment-mainbox-appointmentcontainer-dropdown-inner-bottom-item'>
                                <span><ShareIcon/></span>
                                <span>{t("Share")}</span>
                              </div>
                            </div>
                        </div>
                        : null}
                      </div>
                      </div>
                    )
                  }) 

                  :
                  <div className="com-ecomTableMain-table-tbody-trBox-noDataBox">
                  {t("No data available")}
                </div>
                }

             </>
              }
              </div>
          </div>
         
        </div>
      </div>
    </div>
  )
}
