import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './pages.scss'
import { useLocation } from 'react-router-dom';
import Apiauth from '../../../API/Apiauth';
import ConfirmationPopup from '../Common/ConfirmationPopup';
import ImageUploading from 'react-images-uploading';
// import { Link } from "react-router-dom";
// import Uppy from '@uppy/core'
// import XHRUpload from '@uppy/xhr-upload'
// import { Dashboard } from "@uppy/react";
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import CloseIcon from '../../common/icons/CloseIcon';
import ImageGallary from '../../../Ecommerce/template_two/default/assets/icons/ImageGallary';
import { _Apiauth } from '../../api/_call';
import { error, success } from '../../common/helpers/toastify';

export default function CmsBanners() {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const { state } = useLocation();
  const { t } = useTranslation();
  const [deleteBannerId, setDeleteBannerId] = useState();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [showAddConfirmation, setShowAddConfirmation] = useState(false);
  const [getLink, setLink] = useState();
  const [images, setImages] = useState([]);
  console.log(state, "state check")
  // const bannerArray = [
  //     {image : "https://gis247.s3.us-east-2.amazonaws.com/2022th0038/Customer/profile/1700877664188.png", name: "BannerImage.png"},
  //     {image : "https://gis247.s3.us-east-2.amazonaws.com/Location_store.svg", name: "TextBanner.png"},
  //     {image : "https://gis247.s3.us-east-2.amazonaws.com/2022th0038/Customer/profile/1700877664188.png", name: "BannerImages.png"},
  //     {image : "https://gis247.s3.us-east-2.amazonaws.com/Location_store.svg", name: "Image.png"},
  //     {image : "https://gis247.s3.us-east-2.amazonaws.com/2022th0038/Customer/profile/1700877664188.png", name: "BannerImage.png"},
  //     {image : "https://gis247.s3.us-east-2.amazonaws.com/Location_store.svg", name: "TextBanner.png"},
  //     {image : "https://gis247.s3.us-east-2.amazonaws.com/2022th0038/Customer/profile/1700877664188.png", name: "BannerImages.png"},
  //     {image : "https://gis247.s3.us-east-2.amazonaws.com/Location_store.svg", name: "Image.png"}
  // ]
  const [bannerArray, setBannerArray] = useState([]);

  useEffect(() => {
    getBanners()
  }, [])

  const onChange = async (imageList, addUpdateIndex) => {
    console.log(imageList, "image list array")
    try {
      let Output = await getImagetoUrl(imageList);

      if (Output) {
        console.log(Output, "check the final output", imageList?.[0]?.file?.name)
        setImages([{ data_url: Output, name: imageList?.[0]?.file?.name }])
      }
    } catch (error) {
      console.error("Error fetching image URL:", error);
    }
  };

  const getImagetoUrl = async (imagelist) => {
    console.log(imagelist, "check image we imported cms banner");
    const file = imagelist[0]?.file;

    if (file) {
      if (file.type.startsWith("image/")) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onloadend = async () => {
            try {
              const postdata = {
                image: reader.result,
                name: imagelist[0]?.file?.name,
              };
              console.log(postdata, "postdata");
              let res = await _Apiauth(postdata, `/APP/customer/uploadimage`);

              if (res.code === 200) {
                console.log(res?.data?.images?.url, "Here you get image url");
                resolve(res?.data?.images?.url);
              } else {
                reject(new Error("Failed to upload image"));
              }
            } catch (err) {
              reject(err);
            }
          };

          reader.onerror = (error) => {
            reject(new Error("File reading error: " + error));
          };

          reader.readAsDataURL(file);
        });
      }
    }

    return null; // Or handle the case where the file is not an image
  };

  const getBanners = async () => {
    try {
      const postdata = {
        "location_id": state?.location

      }
      let apiRes = await Apiauth(postdata, `cms/get-banner-file`);
      if (apiRes.code === 200) {
        setBannerArray(apiRes.data);
      }
    }
    catch (err) {
      console.error(err, 'err');
    }
  }

  const DeleteBanner = async () => {
    try {
      const postdata = { id: deleteBannerId }
      let result = await Apiauth(postdata, `cms/delete-banner-file`)
      if (result.code === 200) {
        console.log(result, "Check deleted item")
        getBanners();
        deleteBannerId(null);
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }

  const showPopup = () => {
    setIsPopupVisible(true);
  };

  const handleConfirm = () => {
    setIsPopupVisible(false);
    DeleteBanner();
  };

  const handleCancel = () => {
    setIsPopupVisible(false);
    setDeleteBannerId(null);
  };

  const showAddConfirmationPopup = () => {
    setShowAddConfirmation(true);
  };

  const handleAddConfirm = () => {
    setShowAddConfirmation(false);
    console.log(images, "Check Images")
    addBanner();
  };

  const handleAddCancel = () => {
    setShowAddConfirmation(false);
  };

  const addBanner = async () => {
    if (state?.location && images.length)
      try {
        let postdata = {
          "location_id": state?.location,
          "name": images?.[0]?.name,
          "type": "image",
          "Imageurl": images?.[0]?.data_url,
          "link": getLink ? getLink : ""
        }
        let result = await Apiauth(postdata, `cms/post-banner-file`)
        if (result.code === 200) {
          success(t("New Banner Added"))
          getBanners();
          setImages([]);
          setLink("");
        }
      }
      catch (err) {
        error(t("Something went wrong"))
      }
  }

  return (
    <div className='main-content-wrapper-body'>
      <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
        <div className="main-content-wrapper-body-top-bar-left">
          <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
            <span>{t("Banner Images")}</span>
          </h1>
        </div>

      </div>
      <div className='cmsbanner-wrapper row'>
        <div className='cmsbanner col-md-9'>
          <div className='cmsbanner-inner'>
            {bannerArray.length ?
              bannerArray.map((item, i) => {
                return (
                  <div className='cmsbanner-inner-bannercard' key={i}>
                    <div className='cmsbanner-inner-bannercard-imageholder'>
                      <img src={item.Imageurl} alt="banner_image" />
                      <div className='cmsbanner-inner-bannercard-imageholder-cover'>
                        <img
                          onClick={() => { showPopup(); setDeleteBannerId(item._id) }}
                          src={base_assets + "images/pos/icons/i5-w.png"} alt="" />
                      </div>
                    </div>
                    <button>{t(`${item.name}`)}</button>
                  </div>
                )
              })
              : ""}
          </div>
        </div>
        <div className='cmsbanner col-md-3'>
          <div className='cmsbanner-addimage'>
            <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center justify-content-end mb-2">
              <button className={`cmsbanner-addimage-button ${images?.length ? 'active' : ''}`} onClick={addBanner}>
                <img className="me-2" src={base_assets + "images/admin/icons/add-icon-white.png"} alt="" />{t("Add")}
              </button>
            </div>
            <div className="col-md-12 bg-white">
              <div className="v3-input-group">
                <label className="v3-input-group-label">{t("Link")}</label>
                <input type="text" className="v3-input-group-input w-100"
                  value={getLink}
                  onChange={(e) => setLink(e.target.value)}
                />
              </div>
            </div>
            <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              maxNumber={1}
              dataURLKey="data_url"

            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                  {imageList?.length ?
                    imageList.map((image, index) => (
                      <div key={index} className={`image-item cmsbanner-addimage-wrapper ${isDragging ? "active" : ""}`} onClick={() => onImageUpdate(index)} {...dragProps}>
                        <div className='cmsbanner-addimage-wrapper-close'
                          onClick={(e) => {
                            e.stopPropagation();
                            onImageRemove(index); setImages([]);
                          }}>
                          <CloseIcon />
                        </div>
                        <img src={image['data_url']} alt="" width="100" />
                      </div>
                    ))
                    :
                    <div className={`logo-uploader ${isDragging ? "active" : ""}`} onClick={onImageUpload}  {...dragProps}>
                      <div className='logo-uploader-texts'>
                        <div>
                          <ImageGallary width="24px" height="24px" />
                        </div>
                        <div className="">
                          <span>{("Drag and drop a file here or click")} </span>
                        </div>
                      </div>
                      <div className='pb-2 text-center'>
                        {t("Images jpg,.png only, 300kb or less")}
                      </div>
                    </div>
                  }
                </div>
              )}
            </ImageUploading>
          </div>
        </div>
      </div>
      <ConfirmationPopup
        isVisible={isPopupVisible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        sub="Are you sure you want to delete this banner ?"
      />
    </div>
  )
}
