import React from 'react'

export default function BlockListIcon({color}) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 19.8008H5.5V17.8008H7.5V19.8008ZM7.5 13.8008H5.5V11.8008H7.5V13.8008ZM7.5 5.80078V7.80078H5.5V5.80078H7.5Z" fill={color ? color : "#212121"}/>
    <path d="M13.5 19.8008H11.5V17.8008H13.5V19.8008ZM13.5 13.8008H11.5V11.8008H13.5V13.8008ZM13.5 5.80078V7.80078H11.5V5.80078H13.5Z" fill={color ? color : "#212121"}/>
    <path d="M19.5 19.8008H17.5V17.8008H19.5V19.8008ZM19.5 13.8008H17.5V11.8008H19.5V13.8008ZM19.5 5.80078V7.80078H17.5V5.80078H19.5Z" fill={color ? color : "#212121"}/>
    </svg>
  )
}
