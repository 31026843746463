import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import TableColumnModal from '../../components/common/modules/tableColumn'
import { Tablehead } from '../../components/common/modules/Tablehead'
import { posappointment_head } from '../../components/common/Tablehead'
import 'react-datepicker/dist/react-datepicker.css'
import { SetdataTable, UnSetdataTable } from '../common/api/setdatatable'
import { _Api } from '../common/api/_call'
import CheckoutBlank from '../checkout/checkoutBlank'
import { useTranslation } from 'react-i18next'
import { toFormatPrice } from '../common/helpers/function'
import Appointmentsubtbl from './Appointmentsubtbl'
import { Pagination } from "../common/Pagination";
import blankimg from "../../assets/images/icons/blankimg.png"
import Showloader from "../common/Showloader";
import Select from "react-select";
import $ from 'jquery';
import AppointmentFilter from './AppointmentFilter'
import { store } from '../../source/index'
const Appointment = () => {
    const { t } = useTranslation()
    let reduxdata = store.getState()
    let OrgSettings = reduxdata?.app?.posorganisation_settings
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    const [newcolumndata, setnewcolumndata] = useState(posappointment_head)
    const [TransactionDate, setTransactionDate] = useState()
    const [rowchecked, setRowchecked] = useState([])
    const [dataloder, setDataloder] = useState(false)
    const [appointmentedata, setAppointmentedata] = useState([])
    const [selecteditem, setSelecteditem] = useState()
    const [excuteTable, setexcuteTable] = useState(false);
    const [toggleAppointmentFilter, setToggleAppointmentFilter] = useState(false)
    const [statuscount, setStatuscount] = useState("")
    const [filtername, setFiltername] = useState('')
    const [filtre, setFilter] = useState()
    const selectTransactionDate = (input) => {
        setTransactionDate(input)
    }


    const colorStyles = {
        option: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: data.color
            };
        },
        singleValue: (styles, { data }) => ({
            ...styles,
            backgroundColor: data.color,
        })
    };
    useEffect(() => {
        callcolumns()
    }, [])
    const callcolumns = async () => {
        try {
            const postdata = {
                name: 'tbl_pos_appointment',
            }
            let columnhead = await _Api(
                postdata,
                `api/v1/Inventory/common/getColumnHeads`,
            )
            if (columnhead.code === 200) {
                setnewcolumndata(columnhead?.data?.data)
            }
        } catch (err) {
            console.log(err, 'err')
        }
    }
    const [state, setstate] = useState({
        totalPages: 20,
        currentPage: 1,
    });
    const filterbyname = (search) => {
        getappointment(currentPage, search);
        setstate({ ...state, currentPage: 1 });
    };
    const { totalPages, currentPage } = state;
    const handlePaginations = (current) => {
        setstate({ ...state, currentPage: current });
        getappointment(current, filtername);

    };
    const getappointment = async (current, search, lodaer) => {
        setexcuteTable(false);
        var expand_col = document.querySelector(".table_btn_expand_col");
        if (expand_col) { expand_col.classList.remove("rotate-90"); setRowchecked([]); }
        try {
            if (!lodaer) {
                setDataloder(true);
            }
            setTimeout(async function () {
                UnSetdataTable("com-data-area_table_appointment");
                let time = TransactionDate ? new Date(TransactionDate) : null;
                let formattedDate = time ? `${time.getFullYear()}-${(time.getMonth() + 1).toString().padStart(2, '0')}-${time.getDate().toString().padStart(2, '0')}` : null;

                let postdata = {
                    "search": search ? search : "",
                    "limit": 100,
                    "skip": current ? (current - 1) * 100 : 0,
                    "date": formattedDate,
                    "location": filtre?.location ? filtre?.location : [],
                    "status": filtre?.status ? filtre?.status : []
                };

                let res = await _Api(postdata, `api/v1/Customer/appointment/list`)
                if (res?.code === 200) {
                    setexcuteTable(true);
                    SetdataTable('com-data-area_table_appointment', "0", newcolumndata, [1])
                    setAppointmentedata(res?.data)
                    setstate({
                        ...state,
                        totalPages: Math.ceil(res?.count / postdata.limit),
                        currentPage: current ? current : 1
                    });
                    setDataloder(false);
                    setStatuscount(res?.statusCount)
                } else {
                    setDataloder(false);
                    SetdataTable('com-data-area_table_appointment', "0", newcolumndata, [1])
                }
            }, 100)
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    useEffect(() => {
        getappointment()
        // eslint-disable-next-line
    }, [TransactionDate, filtre])

    const setSubTablecustom = async (id, product, location_id, currency, exkey) => {
        var e = document.querySelector("#table-btn_" + id);
        var row = document.querySelector('#common-main-table-wrap-' + id);
        var classList_ = e.classList;
        if (classList_.contains("rotate-90")) {
            classList_.remove("rotate-90");
            let alllist_data = [...appointmentedata];
            alllist_data.filter(item => item._id === id).forEach((result) => {
                result.subTabledata = null
            })
            let ids = [...rowchecked]
            const newArray = ids.filter(item => item !== id);
            setRowchecked(newArray)
            setAppointmentedata(alllist_data);
        } else {
            classList_.add("rotate-90");
            let alllist_data = [...appointmentedata];
            const postdata = {
                "Product": product,
                "location": location_id,
                "currency": currency ? currency : "USD"
            };
            let prioritydata = await _Api(postdata, `api/v1/Customer/appointment/sub-list`);
            var html_ = Appointmentsubtbl(prioritydata, id, t, setSelecteditem, selecteditem);
            alllist_data.filter(item => item._id === id).forEach((result) => {
                result.subTabledata = html_
            })
            let ids = [...rowchecked]
            ids.push(id)
            setRowchecked(ids)
            setAppointmentedata(alllist_data);
            var checkRowSub = setInterval(function () {
                var rowSub = document.querySelector('#common-sub-table-wrap-' + id);
                if (rowSub) {
                    $(row).after(rowSub);
                    clearInterval(checkRowSub);
                }
            }, 100);
        }
    }


    const select_status = async (id, event) => {
        try {
            let postdata = {
                "id": id,
                "status": event?.value
            }
            let res = await _Api(postdata, `api/v1/Customer/appointment/status-update`)
            if (res?.code === 200) {
                getappointment(0, filtername, true)
            }
        }
        catch (err) {
            console.log(err, "Err")
        }
    }

    return (
        <>
            <div className="main-body main-content-wrapper-body">
                <div className="main-body-row">
                    <div className="main-body-row-left-col p-0">
                        <div className="com-data-area">
                            <div className="catalog-area-header-area detail-area-header">
                                <div className="catalog-area-header-area-control">
                                    <div className="catalog-area-header-area-control-left">
                                        <h1 className="heading">
                                            <Link
                                                to="/app/pos/menu"
                                                className="gray-text text-decoration-none"
                                            >
                                                {t('APPOINTMENT')}
                                            </Link>
                                        </h1>
                                    </div>

                                    <div className="catalog-area-header-area-control-right">
                                        <div className="com-search-area com-search-area-large ">
                                            <input
                                                value={filtername}
                                                type="text"
                                                placeholder={t('Search customer')}
                                                onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                                                onChange={(e) => setFiltername(e.target.value)}
                                            />
                                            {filtername ? <img className="com-search-area-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                                            <img
                                                onClick={() => filterbyname(filtername)}
                                                className="com-search-area-img"
                                                src={base_assets + 'images/pos/icons/magnifying-glass.png'}
                                                alt=""
                                            />
                                            <button className="com-search-area-large-filter btn-style" onClick={() => setToggleAppointmentFilter(!toggleAppointmentFilter)}>
                                                <img src={base_assets + 'images/icons/filter.png'} alt="" />
                                            </button>
                                        </div>

                                    </div>
                                </div>
                                <div id="appointmentHolder" className="collapse show mt-1">
                                    <div className="main-body-top-color-bar">
                                        <div className="main-body-top-color-bar-single">
                                            <div className='main-body-top-color-bar-single-color main-green-bg' />
                                            <span className='main-body-top-color-bar-single-color-status main-green-text'>
                                                {t("Open")}
                                            </span>
                                            <span>
                                                {statuscount?.open ? statuscount?.open : 0}
                                            </span>
                                        </div>
                                        <div className="main-body-top-color-bar-single">
                                            <div className='main-body-top-color-bar-single-color yellow-bg' />
                                            <span className='main-body-top-color-bar-single-color-status yellow-text'>
                                                {t("Close")}
                                            </span>
                                            <span>
                                                {statuscount?.close ? statuscount?.close : 0}
                                            </span>
                                        </div>

                                        <div className="main-body-top-color-bar-single">
                                            <div className='main-body-top-color-bar-single-color danger-bg' />
                                            <span className='main-body-top-color-bar-single-color-status danger-text'>
                                                {t("Cancel")}
                                            </span>
                                            <span className='mx-2'>
                                                {statuscount?.cancel ? statuscount?.cancel : 0}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="com-data-area-inner">
                                <div className="com-data-area-header">
                                    <h2 className="com-data-area-header-heading">
                                        {t("Appointment History")}
                                    </h2>
                                    <div className="customer-inner-data-payment-table-top-bar-right placeholder-up-input-date date">
                                        <DatePicker
                                            className="placeholder-up-input-field"
                                            selected={TransactionDate}
                                            onChange={selectTransactionDate}
                                            name="TransactionDate"
                                            dateFormat={OrgSettings?.date_picker}
                                            placeholderText="MM/DD/YYYY"
                                            autoComplete='none'
                                        />
                                        <img
                                            className="placeholder-up-input-field-calender"
                                            src={base_assets + 'images/icons/calendar-green.svg'}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="main-body-main-table-wrap posappointment">
                                    <table
                                        id="com-data-area_table_appointment"
                                        className="common-table first-row-gray w-100 "
                                    >
                                        <Tablehead
                                            tablehead={posappointment_head}
                                            tblName={'tbl_pos_appointment'}
                                            setfilterby={""}
                                        />

                                        <tbody >
                                            <>
                                                {appointmentedata?.length
                                                    ? appointmentedata.map((result, exkey) => {
                                                        var valueofstatus = []
                                                        let status = {}
                                                        if (result?.status === 0) {
                                                            status = { label: t("Open"), value: 0, color: "#5CC7C1" }
                                                            valueofstatus = [
                                                                { label: t("Close"), value: 1, color: "#F9BC44" },
                                                                { label: t("Cancel"), value: 2, color: "#FF5757" },
                                                            ]
                                                        } else if (result?.status === 1) {
                                                            status = { label: t("Close"), value: 1, color: "#F9BC44" }
                                                            valueofstatus = []
                                                        } else {
                                                            status = { label: t("Cancel"), value: 2, color: "#FF5757" }
                                                            valueofstatus = []
                                                        }
                                                        return (
                                                            <React.Fragment key={exkey}>
                                                                <tr key={exkey}
                                                                    id={`common-main-table-wrap-${result?._id}`}>
                                                                    <td className="row-check">
                                                                        <div className="com-check">
                                                                            <input
                                                                                className=''
                                                                                type="checkbox"
                                                                                id={exkey}
                                                                                onChange={(e) =>
                                                                                    setSubTablecustom(
                                                                                        result?._id,
                                                                                        result?.product,
                                                                                        result?.location_id,
                                                                                        result?.currency,
                                                                                        exkey
                                                                                    )}
                                                                                checked={rowchecked.includes(result?._id)}
                                                                            />
                                                                            <label
                                                                                htmlFor={exkey}
                                                                                className="com-check-label"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>{exkey + 1}</td>
                                                                    <td>
                                                                        <div className="pro-img">
                                                                            <img src={result?.image ? result?.image : blankimg} alt="" />
                                                                        </div>
                                                                    </td>
                                                                    <td>{result?.name}</td>
                                                                    <td className="link-color">
                                                                        {result?.date}
                                                                    </td>
                                                                    <td className="link-color">{result?.ref_no}</td>
                                                                    <td className='sku-col table_btn_expand_col'
                                                                        onClick={() =>
                                                                            setSubTablecustom(
                                                                                result?._id,
                                                                                result?.product,
                                                                                result?.location_id,
                                                                                result?.currency,
                                                                                exkey
                                                                            )}
                                                                        id={"table-btn_" + result?._id}>  <img
                                                                            className="sku-col-arrow cursor-pointer"
                                                                            src={
                                                                                base_assets +
                                                                                "/images/icons/right_green_button.png"
                                                                            }
                                                                            alt=""
                                                                        />
                                                                        {result?.SKU}</td>
                                                                    <td>{result?.location}</td>
                                                                    <td>{result?.appointment_date}</td>
                                                                    <td>{result?.qty}</td>
                                                                    <td>
                                                                        {toFormatPrice(result?.amount, {
                                                                            addSymbol: true,
                                                                        })}
                                                                    </td>
                                                                    <td>
                                                                        <div className={"dropdown status-dropdown status-dropdown-select m-1"} style={result?.status === 0 ? { backgroundColor: '#5CC7C1' } : result?.status === 1 ? { backgroundColor: '#F9BC44' } : { backgroundColor: '#FF5757' }}>
                                                                            <Select
                                                                                options={valueofstatus}
                                                                                value={status}
                                                                                onChange={(event) =>
                                                                                    select_status(result._id, event)
                                                                                }
                                                                                classNamePrefix="status-dropdown-select"
                                                                                styles={colorStyles}
                                                                                getOptionLabel={(e) => (
                                                                                    <div className="">
                                                                                        {e.label}
                                                                                    </div>
                                                                                )}
                                                                                isSearchable={false}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                {
                                                                    excuteTable ? (
                                                                        <>
                                                                            {typeof result.subTabledata == "undefined" ? (result.subTabledata = "") : result.subTabledata}
                                                                        </>
                                                                    ) : null
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    })
                                                    : ''}
                                            </>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    {dataloder ? <Showloader /> : ""}
                                </div>
                                <div className="com-data-area-footer-btn justify-content-end">
                                    {appointmentedata?.length > 0 ? (
                                        <Pagination
                                            total={totalPages}
                                            current={currentPage}
                                            pagination={(crPage) => handlePaginations(crPage)}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-body-row-right-col">
                        {(
                            <CheckoutBlank />
                        )}
                    </div>
                </div>
            </div>
            <TableColumnModal
                tablehead={posappointment_head}
                tblName={'tbl_pos_appointment'}
                setnewcolumndata={setnewcolumndata}
                calldatatable={getappointment}
            ></TableColumnModal>
            {toggleAppointmentFilter ? <AppointmentFilter setToggleAppointmentFilter={setToggleAppointmentFilter}
                applyfilter={setFilter} /> : ""}
        </>
    )
}

export default Appointment
