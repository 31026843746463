import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import RightArrow from '../../common/icons/right-arrow'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom';
import QuillEditor from "react-quill";
import "react-quill/dist/quill.snow.css";
import Apiauth from '../../../API/Apiauth';
import { loading, Updateloading } from '../../common/helpers/toastify';


export default function AddBlock() {
    const { state } = useLocation()
    const { t } = useTranslation();
    const navigate = useNavigate()
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    const [allValues, setAllValues] = useState({});
    const [value, setValue] = useState("");
    // Editor ref
    const quill = useRef();
    useEffect(() => {
        if (state?.pageId) {
            getPageDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // Handler to handle button clicked


    const getPageDetails = async () => {
        try {
            let postdata = { "id": state?.pageId }
            let result = await Apiauth(postdata, `cms/get-block-view`)
            if (result.code === 200) {
                setAllValues(result?.data)
                setValue(result?.data?.content)
                setAllValues((state) => ({ ...state, menutype: menuTypeOptions[result?.data?.menutype - 1], file: { image: result?.data?.featuredImage } }))
            }
        }
        catch (err) {
            console.log(err, "Error")
        }
    }

    const menuTypeOptions = [
        { label: "Header", value: 1 },
        { label: "Footer", value: 2 }
    ]

    const imageHandler = useCallback(() => {
        // Create an input element of type 'file'
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        // When a file is selected
        input.onchange = () => {
            const file = input.files[0];
            const reader = new FileReader();

            // Read the selected file as a data URL
            reader.onload = () => {
                const imageUrl = reader.result;
                const quillEditor = quill.current.getEditor();

                // Get the current selection range and insert the image at that index
                const range = quillEditor.getSelection(true);
                quillEditor.insertEmbed(range.index, "image", imageUrl, "user");
            };

            reader.readAsDataURL(file);
        };
    }, []);

    const [isHtmlMode, setIsHtmlMode] = useState(false);
    const [htmlContent, setHtmlContent] = useState("");

    const toggleHtmlView = useCallback(() => {
        const editor = quill.current.getEditor(); // Get Quill editor instance

        if (!isHtmlMode) {
            // Switching to HTML mode
            const html = editor.root.innerHTML; // Get current HTML content
            setHtmlContent(html); // Save HTML content
            setIsHtmlMode(true);

            // Replace editor content with raw HTML and make it non-editable
            editor.disable(); // Disable Quill editing
            editor.root.textContent = html; // Set raw HTML as text content
        } else {
            // Switching back to rich text mode
            editor.root.innerHTML = htmlContent; // Restore saved HTML content
            setIsHtmlMode(false);
            editor.enable(); // Re-enable Quill editing
        }
    }, [isHtmlMode, htmlContent]);

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ font: [] }],
                    [{ header: [2, 3, 4, false] }],
                    ["bold", "italic", "underline", "blockquote"],
                    [{ color: [] }],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                    ],
                    ["link", "image", "video"],
                    ["clean"],
                    ["customButton"], // Add the custom button
                ],
                handlers: {
                    image: imageHandler,
                    customButton: toggleHtmlView,
                },
            },
            clipboard: {
                matchVisual: true,
            },
        }),
        [imageHandler, toggleHtmlView] // Dependencies
    );

    const formats = [
        "font",
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "color",
        "clean",
    ];


    const handleSave = async () => {
        if (allValues?.title && value) {
            let GetloadingID = loading();

            try {
                console.log(allValues, "allValues");

                let postdata = {
                    "location_id": state?.location ? state?.location : "",
                    "content": value ? value : "",
                    "id": allValues.id,
                    "status": allValues.status ? 1 : 0,


                }

                let result = await Apiauth(postdata, `cms/block-edit`)
                if (result.code === 200) {
                    Updateloading(t, GetloadingID, t("Successfully edit"));

                } else {

                    Updateloading(t, GetloadingID, result?.message, 'error');
                }
            }
            catch (err) {
                console.log(err, "Error")
            }
        }
    }

    useEffect(() => {
        // Add label to the custom button
        const customButton = document.querySelector(".ql-customButton");
        if (customButton) {
            customButton.innerHTML = '<span style="font-size: 14px;"><i class="fa fa-code"></i></span>';
        }
    }, []);
    return (
        <div className='main-content-wrapper-body admincmsAddpage'>
            <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
                <div className="main-content-wrapper-body-top-bar-left">
                    <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0 cursor-pointer" onClick={() => navigate(-1)}>{t("HTML Blocks")} <RightArrow /> <span className="cursor-pointer">{t("Edit Block")}</span></h1>

                </div>
                <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">
                    <button className={"com-plus-text-btn text-white text-center fw-semibold d-flex align-items-center border-0 rounded-1 ms-3"} onClick={handleSave}>
                        <img className='w-100' src={base_assets + 'images/icons/white-save.png'} alt="" />
                    </button>
                </div>
            </div>
            <div className='row admincmsAddpage-content'>
                <div className='col-md-9 admincmsAddpage-content-section'>
                    <div className="col-md-12 bg-white p-2">
                        <div className="v3-input-group">
                            <label className="v3-input-group-label">{allValues?.title}</label>
                        </div>
                    </div>
                    <div className="col-md-12 bg-white p-2">
                        <div className="v3-input-group">
                            <label className="v3-input-group-label">{t("Description")}<span className='text-danger'> *</span></label>
                            <div>
                                <QuillEditor
                                    ref={(el) => (quill.current = el)}
                                    theme="snow"
                                    value={value}
                                    formats={formats}
                                    modules={modules}
                                    onChange={(value) => setValue(value)}
                                />


                            </div>
                        </div>
                    </div>

                </div>
                {/* section 2 */}
                <div className='col-md-3 gap admincmsAddpage-content-section'>
                    <div className="col-md-12 bg-white p-2 admincmsAddpage-content-section-inputfield">
                        <div className="v3-input-group">

                            <div className="form-check form-switch ps-0 text-nowrap align-items-center d-flex justify-content-between">
                                <label className="v3-input-group-label">{t("Status")}</label>
                                <input className="form-check-input float-none ms-0 me-2 cursor-pointer mb-0" type="checkbox"
                                    onChange={(e) => setAllValues((state) => ({ ...state, status: !state.status }))}
                                    checked={allValues?.status} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
