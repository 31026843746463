import React, { useState, useEffect } from "react";
import "../master.scss"
import Select from "react-select";
import { error, loading, Updateloading } from "../../common/helpers/toastify";
import { Getshapegroupgraph } from "../../common/Commongraphql"
import { useTranslation } from 'react-i18next';
import { _Api } from "../../api/_call";
const Editsizestone = (props) => {
    const { setEditItem, dataid, setShowedit, create, stCreate, setDataid } = props
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    const [shapedata, setShapedata] = useState()
    const { t } = useTranslation();
    const [name, setName] = useState("")
    const [code, setCode] = useState("")
    const [mixmatch, setMixMatch] = useState(false)
    const [active, setActive] = useState(true)
    const [pointergrp, setPointergrp] = useState()
    const [weight, setWeight] = useState()
    const [shapearrayList, setShapearrayList] = useState([])

    const viewmaster = async () => {
        if (create) {
            setShapedata([])
            setName("")
            setCode("")
            setMixMatch(0)
            setActive(true)
            setPointergrp("")
            setWeight("")
        } else if (dataid) {
            try {
                const postdata = {
                    "id": dataid,
                    "type": "SIZE"
                }
                const res = await _Api(postdata, `api/v1/App/master/stoneMasterview`)
                if (res?.code === 200) {
                    let arr = []
                    res?.data?.shape?.forEach((result) => {
                        arr.push({
                            value: result?._id,
                            label: result?.name
                        })
                    })
                    setShapedata(arr)
                    setName(res?.data?.name)
                    setCode(res?.data?.code)
                    setMixMatch(res?.data?.mixMatch)
                    if (res?.data?.Status === "1") {
                        setActive(true)
                    } else {
                        setActive(false)
                    }
                    setPointergrp(res?.data?.Pointer)
                    setWeight(res?.data?.Weight)
                }
            } catch (err) {
                console.log(err, "err")
            }
        }
    }
    useEffect(() => {
        if (dataid || create) {
            viewmaster();
        }
        // eslint-disable-next-line
    }, [dataid, create]);

    const createsize = async () => {
        if (!name) {
            error(t("Name is required."))
            return
        }
        if (!code) {
            error(t("Code is required."))
            return
        } else {
            if (typeof code === 'string') {
                let checkspace = code.includes(' ');
                if (checkspace) {
                    error(t('Please remove space in code'));
                    return;
                }
            }
        }
        let shapeid = []
        shapedata?.forEach((result) => {
            shapeid.push(result?.value)
        })
        let GetloadingID = loading();
        try {
            let postdata = {
                "type": "SIZE",
                "data": {
                    "name": name ? name : "",
                    "code": code ? code : "",
                    "Status": active ? "1" : "0",
                    "Pointer": pointergrp ? pointergrp : "",
                    "Weight": weight ? weight : "",
                    "mixMatch": mixmatch ? mixmatch : 0,
                    "shape": shapeid
                }
            }
            let res = await _Api(postdata, `api/v1/App/master/stoneMasterCreate`)
            if (res?.code === 200) {
                Updateloading(t, GetloadingID, (res?.message));
                setEditItem(false)
                setShowedit(true)
                stCreate(false)
                setDataid("")
            } else {
                Updateloading(t, GetloadingID, (res.errors), 'error');
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }

    const editsizestone = async () => {
        if (!name) {
            error(t("Name is required."))
            return
        }
        if (!code) {
            error(t("Code is required."))
            return
        } else {
            if (typeof code === 'string') {
                let checkspace = code.includes(' ');
                if (checkspace) {
                    error(t('Please remove space in code'));
                    return;
                }
            }
        }
        let shapeid = []
        shapedata.forEach((result) => {
            shapeid.push(result?.value)
        })
        let GetloadingID = loading();
        try {
            let postdata = {
                "id": dataid,
                "type": "SIZE",
                "data": {
                    "name": name ? name : "",
                    "code": code ? code : "",
                    "Status": active ? "1" : "0",
                    "Pointer": pointergrp ? pointergrp : "",
                    "Weight": weight ? weight : "",
                    "mixMatch": mixmatch ? mixmatch : 0,
                    "shape": shapeid
                }
            }
            let res = await _Api(postdata, `api/v1/App/master/stoneMasterEdit`)
            if (res?.code === 200) {
                Updateloading(t, GetloadingID, (res?.message));
                setEditItem(false)
                setShowedit(true)
                stCreate(false)
                setDataid("")
            } else {
                Updateloading(t, GetloadingID, (res.errors), 'error');
            }

        }
        catch (err) {
            console.log(err, "err")
        }
    }

    return (
        <>
            <div className="edit-item-area bg-white">
                <div className="edit-item-area-header d-flex align-items-center justify-space-between mb-4">
                    <div className="me-auto">
                        <label className="d-block">{t("Name")}: {name ? name : ""}</label>
                        <label className="d-block">{t("Code")}: {code ? code : ""}</label>
                    </div>
                    <button className="square-btn square-btn-white btn-close focus-none" onClick={() => {
                        setEditItem(false);
                        stCreate(false);
                        setDataid("")
                    }}></button>
                    <button className="square-btn"
                        onClick={() => dataid ? editsizestone() : createsize()}><img src={base_assets + 'images/icons/white-save.png'} alt="" /></button>
                </div>
                <div className="edit-item-area-body">
                    <div className="row mx-0">
                        <div className="col-md-8 ps-0">
                            <div className="v3-input-group">
                                <label className="v3-input-group-label">{t("Name")} <sup>*</sup></label>
                                <input className="v3-input-group-input w-100"
                                    value={name || ''}
                                    onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className="v3-input-group">
                                <label className="v3-input-group-label">{t("Code")} <sup>*</sup></label>
                                <input className="v3-input-group-input w-100"
                                    value={code || ''}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </div>
                            <div className="v3-input-group mb-0">
                                <label className="v3-input-group-label">{t("Shape")}</label>
                                <Select
                                    isMulti
                                    name="colors"
                                    options={shapearrayList}
                                    value={shapedata}
                                    onChange={(e) => setShapedata(e)}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Shape"
                                    isSearchable="false"
                                />
                            </div>


                            <div className="row">
                                <div className="col-md-6 pe-2">
                                    <div className="v3-input-group">
                                        <label className="v3-input-group-label">{t("Weight")} (Cts)</label>
                                        <input className="v3-input-group-input w-100"
                                            value={weight || ''}
                                            onChange={(e) => setWeight(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6 ps-2">
                                    <div className="v3-input-group">
                                        <label className="v3-input-group-label">{t("Pointer")}</label>
                                        <input className="v3-input-group-input w-100"
                                            value={pointergrp || ''}
                                            onChange={(e) => setPointergrp(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 offset-md-1 pe-0 text-end">
                            <div className="form-check form-switch ps-0">
                                <label className="form-check-label" htmlFor="mixmatch">{t("Mix & Match")}</label>
                                <input className="form-check-input float-none ms-2" type="checkbox" id="mixmatch"
                                    checked={mixmatch ? true : false}
                                    onChange={(e) => setMixMatch(e.target.checked ? 1 : 0)} />
                            </div>
                            <div className="form-check form-switch ps-0">
                                <label className="form-check-label" htmlFor="active">{t("Active")}</label>
                                <input className="form-check-input float-none ms-2" type="checkbox" id="active"
                                    checked={active ? true : false}
                                    onChange={(e) => setActive(e.target.checked)} />
                            </div>
                        </div>
                    </div>
                </div>
                <Getshapegroupgraph setShapearrayList={setShapearrayList} />
            </div>
        </>
    )
}

export default Editsizestone;