import React, { useEffect } from 'react'
import './CookieConsent.scss'
import CrossIcon from '../../assets/icons/CrossIcon'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { giveConsent } from '../../../../source/app/appSlice';


export default function CookieConsent() {
    const { t } = useTranslation();

    const dispatch = useDispatch();
  const consentGiven = useSelector((state) => state.app.consentGiven);

  useEffect(() => {
    const consentStatus = localStorage.getItem('cookieConsent');
    if (consentStatus === 'true') {
      dispatch(giveConsent());
    }
  }, [dispatch]);

  const handleAccept = () => {
    dispatch(giveConsent());
    localStorage.setItem('cookieConsent', 'true');
  };

  const handleReject = () => {
    dispatch(giveConsent());
    localStorage.setItem('cookieConsent', 'false');
  };

  if (consentGiven) return null;
  return (
    <div className='cookieconsentNew'>
        <div className='cookieconsentNew-card'>
        <div className='cookieconsentNew-card-inner'>
            <div className='cookieconsentNew-card-inner-content'>
                <div className='cookieconsentNew-card-inner-content-subdata'>{t(`We use cookies to ensure you get the best experience when visiting our site. By clicking 'I Accept',  or if you continue to use our site, you are agreeing to all our cookies. You may change your preferences at any time through our `)}<Link to="" className=''>{t("Cookie policy")}.</Link>You may also read our full <Link to="" className=''>{t("Privacy policy")}.</Link></div>
            </div>
            <div className='cookieconsentNew-card-inner-controls'>
                <button className='themeBtn mini' onClick={handleAccept}>{t("Accept all")}</button>
            </div>
        </div>
        </div>
    </div>
  )
}
