import RightArrow from 'admin/common/icons/right-arrow'
import './mixmatch.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom';
import SelectDiamond from './SelectDiamond';
import CollectionSlider from 'Ecommerce/template_three/include/Common/CollectionSlider';
import RecentViewProducts from 'Ecommerce/template_three/components/RecentViewProducts';
import OrderComplete from './OrderComplete';
import Tab from './Tab';
import { _Api } from 'Ecommerce/common/api';
import SelectProduct from './SelectProduct';
import Engraving from './Engraving/Engraving';
import CartItemsNotify from 'Ecommerce/template_three/components/Cart/CartItemsNotify';
import SizeGuildeModal from 'Ecommerce/template_three/components/SizeGuilde/SizeGuildeModal';
import AddCartModal from 'Ecommerce/template_three/components/Cart/AddCartModal';

export default function MixAndMatchMainPage() { 

    const { state } = useLocation();
    const [showEngravingPage, setShowEngravingPage] = useState(false);
    const [flowMode, setFLowMode] = useState('fromProduct')
    console.log(state, "your state is here")
  
  
    const { t } = useTranslation();
    // const [tabs, setTabs] = useState(order === 'select' ? orderSelect : orderDiamond );
    const [activeTab, setActiveTab] = useState(flowMode === 'fromDiamond' ? 'Diamond' : flowMode === 'fromProduct'  ? 'Product' : 'Final' );
    const [mobileTab, setMobileTab] = useState();
    const [getProductFilters, setGetProductFilters] = useState();
    const [getDiamondFilters, setGetDiamondFilters] = useState();
    const [wishlistdata, setWishliatdata] = useState({ id: "", remove: false, edit: false })
    const [productDetail, setProductdetail] = useState()
    const [diamondProduct, setDiamondProduct] = useState();
    const [engravingData, setEngravingData] = useState();
    const [productListArray, setProductListArray] = useState([]);
    const [isopencart, setisopencart] = useState(false)
    const [isopennotifycart, setIsopennotifycart] = useState(false);
    const [openCart, setOpenCart] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [reviewProductId, setReviewProductId] = useState(state? state : {})
    const [reviewProduct, setReviewProduct] = useState();
    const [displayBanner, setDisplayBanner] = useState();
  
    useEffect(()=>{
      console.log(reviewProductId, reviewProduct, "check reviews ")
        if(reviewProductId?.temp_cart_id){
            getAddtoCart()
        }
    },[])
  
    const getAddtoCart = async() =>{
      let data = localStorage.getItem('CartItems') ? JSON.parse(localStorage.getItem('CartItems')) : [];
      setActiveTab("");
      try {
        let params = {"temp_cart_id" : reviewProductId?.temp_cart_id}
        let res = await _Api(params, `mixMatchProducts/getAddtocart`)
        if (res?.status === 200) {
          console.log(res?.data, "check engraving data")
          let ourData = data.filter((item)=>item?.temp_cart_id === reviewProductId?.temp_cart_id)
          setReviewProduct(res?.data);
          console.log(ourData, ourData?.[0]?.diamonddetails, "check our data")
          setDiamondProduct(ourData?.[0]?.diamonddetails);
          setEngravingData(ourData?.[0]?.custom_design)
          setProductdetail(ourData?.[0]?.productdiamond)
          setActiveTab(reviewProductId?.activeTab)
          console.log(res, ourData ,"mix and match review product")
        }
    }
    catch (err) {
        console.log(err, "err")
    }
  }
    
  
  
    const addtocart = () => {
      const itemid = productDetail?.productdetails?._id;
      console.log(itemid, "Add to cart mix and match", engravingData, "check engraving data here", "Product detail", productDetail, "Diamond Detail :", diamondProduct)
      if (itemid) {
          let localitem = localStorage.getItem('CartItems') ? JSON.parse(localStorage.getItem('CartItems')) : [];
          console.log(localitem, "local cart items")
          let checkitem = localitem.filter(e => e?.id === itemid);
          console.log(checkitem, "looking for check item")
          if (checkitem?.length) {
            console.log(checkitem?.length, "first")
              localitem.forEach((e) => {
                  if (e?.id === itemid) {
                      // e.qty += parseInt(quantity);
                      e.custom_design = engravingData;
                      e.diamonddetails.diamonddata = diamondProduct
                      e.diamonddetails.productdiamond = productDetail
                  }
              });
              localStorage.setItem('CartItems', JSON.stringify(localitem));
          } else {
            console.log(checkitem?.length, "second")
              // localitem.push({ 'id': itemid, 'qty': quantity, 'diamonddetails' : {"diamonddata": diamondProduct, "productdiamond" : productDetail?.productSelection}, "custom_design" : engravingData });
              localitem.push({ 'id': itemid, 'qty': quantity, 'diamonddetails' : diamondProduct, "productdiamond" : {  "productdetails" : productDetail?.productdetails, "productSelection" :productDetail?.productSelection}, "custom_design" : engravingData });
              localStorage.setItem('CartItems', JSON.stringify(localitem));
             
          }
          setIsopennotifycart(true);
          handleMixAndMatch()
      }
  }
  const handleMixAndMatch = async() =>{
    let localitem = localStorage.getItem('CartItems') ? JSON.parse(localStorage.getItem('CartItems')) : [];
  
    let getLastItem = [];
    if(localitem.length){
      getLastItem.push(localitem[localitem.length-1]);
    }
    let postdata={
        diamond:{
              diamond_id: getLastItem?.[0]?.diamonddetails?.diamonddata?.id,
              note: getLastItem?.[0]?.diamonddetails?.productdiamond?.note
        },
        product:{
          id: getLastItem?.[0]?.id,
          qty: getLastItem?.[0]?.qty,
          metal: getLastItem?.[0]?.diamonddetails?.productdiamond?.metal?.value,
          shape: getLastItem?.[0]?.diamonddetails?.productdiamond?.shape?.shapeId,
          size: getLastItem?.[0]?.diamonddetails?.productdiamond?.size?._id,
          stone: getLastItem?.[0]?.diamonddetails?.productdiamond?.stone?.value,
          pointer: getLastItem?.[0]?.diamonddetails?.productdiamond?.pointer?.label,
        },
        custom_design: {
            engraving_text: getLastItem?.[0]?.custom_design?.customizeText,
            side: getLastItem?.[0]?.custom_design?.inorout,
            engraving_position : getLastItem?.[0]?.custom_design?.textposition?.label,
            engraving_logo: getLastItem?.[0]?.custom_design?.imageFile,
            font: getLastItem?.[0]?.custom_design?.fonts?.label,
            logo_position: getLastItem?.[0]?.custom_design?.imgPosition?.label
        }
    }
    try {
      let res = await _Api(postdata, `mixMatchProducts/addtocart`)
      if (res?.status === 200) {
        let newItem = localitem
        newItem[localitem.length - 1] = {
          ...newItem[localitem.length - 1], // Copy existing properties
          temp_cart_id: res?.data?.temp_cart_id // Add the new key-value pair
        };
        console.log(res?.data, "Mix and Match Product")
        localStorage.setItem('CartItems', JSON.stringify(newItem));
      }
  }
  catch (err) {
      console.log(err, "err")
  }
    console.log(localitem, postdata,"mixandmatch add to cart default")
    setOpenCart(false);
  }
  useEffect(()=>{
    console.log(openCart, "opencart")
    if(openCart){
      addtocart();
    }
  },[openCart])
  
  useEffect(()=>{
    getBanners();
},[]);

const getBanners = async() =>{
    try{
        let postdata = {}
        let res = await _Api(postdata, `Cms/get-ecom-bannerdata`);
        if(res.status === 200){
            setDisplayBanner(res.data[1])
            console.log(res, "Here is response")
        }
    }
    catch(err){
        console.log(err, "Error")
    }
}

  return (
    <div className='mix_match_page'>
    <div className='mix_match_page-wrapper'>
      <div className='mix_match_page-wrapper-header'>
        {!diamondProduct ?
        <div className='mix_match_page-wrapper-header-Banner'>
              <div className='mix_match_page-wrapper-header-Banner-imageholder'><img src={displayBanner ? displayBanner?.Imageurl : ""} alt='banner'/></div>
              <div className='mix_match_page-wrapper-header-Banner-taglines'>
                <div className='mix_match_page-wrapper-header-Banner-taglines-title'>{t("Create Your Own Engagement Ring with Mix & Match")}</div>
                <div className='mix_match_page-wrapper-header-Banner-taglines-subdata'>
                    <span className='mix_match_page-wrapper-header-Banner-taglines-subdata-status'>{t("Looking for the perfect symbol of love and commitment?")}</span>
                    <span className='mix_match_page-wrapper-header-Banner-taglines-subdata-status'>{t("Look no further than our exquisite diamond engagement rings.")}</span>
                    <span className='mix_match_page-wrapper-header-Banner-taglines-subdata-status'>{t("Each one is carefully crafted by our skilled artisans,")}</span>
                    <span className='mix_match_page-wrapper-header-Banner-taglines-subdata-status'>{t("ensuring that it is not only beautiful, but also of the highest quality")}</span>
                </div>
              </div>
        </div>
         :""}
      </div>
     <Tab  
     flowMode={flowMode} 
     mobileTab={mobileTab} 
     setMobileTab={setMobileTab} 
     activeTab={activeTab} 
     productDetail={productDetail} 
     diamondProduct={diamondProduct}
     setActiveTab={setActiveTab}
     getDiamondFilters={getDiamondFilters}
     getProductFilters={getProductFilters}
     engravingData={engravingData}
     />
     
     {showEngravingPage ?  <Engraving setActiveTab={setActiveTab} setShowEngravingPage={setShowEngravingPage} flowMode={flowMode} diamondProduct={diamondProduct} engravingData={engravingData} setEngravingData={setEngravingData}/>
     :
     <>
      {  activeTab === 'Product' ? 
      <SelectProduct 
      productDetail={productDetail} 
      flowMode={flowMode} 
      mobileTab={mobileTab} 
      diamondProduct={diamondProduct} 
      setDiamondProduct={setDiamondProduct} 
      engravingData={engravingData} 
      setEngravingData={setEngravingData}
      productListArray={productListArray}
      setProductListArray={setProductListArray}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      setProductdetail={setProductdetail}
      showEngravingPage={showEngravingPage}
      setShowEngravingPage={setShowEngravingPage}
      getProductFilters={getProductFilters}
      setGetProductFilters={setGetProductFilters}
      reviewProduct={reviewProduct}
      reviewProductId={reviewProductId}
      />
      : 
      activeTab === 'Diamond' ? 
      <SelectDiamond 
      productDetail={productDetail} 
      activeTab={activeTab} 
      setActiveTab={setActiveTab} 
      flowMode={flowMode} 
      mobileTab={mobileTab} 
      diamondProduct={diamondProduct} 
      setDiamondProduct={setDiamondProduct} 
      engravingData={engravingData} 
      setEngravingData={setEngravingData}
      showEngravingPage={showEngravingPage}
      setShowEngravingPage={setShowEngravingPage}
      getDiamondFilters={getDiamondFilters}
      setGetDiamondFilters={setGetDiamondFilters}
      reviewProduct={reviewProduct}
      reviewProductId={reviewProductId}
      />
      : 
      <OrderComplete 
        productDetail={productDetail} 
        diamondProductDetail={diamondProduct}
        engravingData={engravingData}
        setShowEngravingPage={setShowEngravingPage}
        setActiveTab={setActiveTab}
        setOpenCart={setOpenCart}
        setIsopennotifycart={setIsopennotifycart}
      />
      }
      </>
      }
      
      
    </div>
   
    { (activeTab === 'Product' || activeTab === 'Final')
     &&
     <>
      <div className="ecommerce-main-layout-collection">
     <CollectionSlider />
 </div> 
        <div className="ecommerce-main-layout-recent_view-product">
        <RecentViewProducts 
        currentid={state?.id}
        setWishliatdata={setWishliatdata} 
        wishlistdata={wishlistdata} 
        />
        </div>
      </>
    }
    
    <AddCartModal setisopencart={setisopencart} isopencart={isopencart} MixAndMatch={{"productDetail" : productDetail,  }}/>
      {isopennotifycart ? <CartItemsNotify isopennotifycart={isopennotifycart} setIsopennotifycart={setIsopennotifycart} setisopencart={setisopencart} /> : ''}
      <SizeGuildeModal />
  </div>
  )
}