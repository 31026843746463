import React from 'react'
import { useState } from 'react';
import theTap from '../../../common/images/the-tape-method 1.png'
import ScaleImg from '../../../common/images/disegno-misura-anello-1-1024x615 2.png'
import { useTranslation } from "react-i18next";
import './sizeguilde.scss'

export default function SizeGuildeModal() {
    const [activeTab, setActiveTab] = useState('tab1');

    const { t } = useTranslation();
        const handleTabClick = (tabName) => {
            setActiveTab(tabName);
        };

    const sizeContent = [
        {size:'44', us:"3", eu: "F", jp:"4"},
        {size:'45', us:"3.5", eu: "G", jp:"6"},
        {size:'46', us:"", eu: "", jp:""},
        {size:'47', us:"4", eu: "H", jp:"7"},
        {size:'48', us:"4.5", eu: "I 1/2", jp:"8"},
        {size:'49', us:"5", eu: "J 1/2", jp:"9"},
        {size:'50', us:"", eu: "", jp:""},
        {size:'51', us:"5.5", eu: "L", jp:"10"},
        {size:'52', us:"6", eu: "M", jp:"12"},
        {size:'53', us:"6.5", eu: "N", jp:"12"},
        {size:'54', us:"", eu: "", jp:""},
        {size:'55', us:"7", eu: "O", jp:"14"},
        {size:'56', us:"7.5", eu: "P", jp:"15"},
        {size:'57', us:"8", eu: "Q 1/2", jp:"16"},
        {size:'58', us:"8.5", eu: "R 1/2", jp:"17"},
        {size:'59', us:"9", eu: "R 1/2", jp:"18"},
        {size:'60', us:"", eu: "", jp:""},
        {size:'61', us:"9.5", eu: "S 1/2", jp:"19"},
        {size:'62', us:"10", eu: "T 1/2", jp:"20"},
        {size:'63', us:"10.5", eu: "U 1/2", jp:"22"},
        {size:'64', us:"", eu: "", jp:""},
        {size:'65', us:"11", eu: "V 1/2", jp:"23"},
        {size:'66', us:"11.5", eu: "W 1/2", jp:"24"},
        {size:'67', us:"", eu: "", jp:""},
        {size:'68', us:"12", eu: "Y", jp:"25"},
        {size:'69', us:"12.5", eu: "Z", jp:"26"},
        {size:'70', us:"13", eu: "", jp:"27"},
        {size:'71', us:"13.5", eu: "", jp:""},
        {size:'72', us:"14", eu: "", jp:""},
        {size:'73', us:"", eu: "", jp:""}
    ]
  return (
    <div>
    <div className="modal fade right modalonright" id="sizeGuideBack" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="sizeguide_modal">
                <div className="sizeguide_modal-headerBox">
                    <div className="sizeguide_modal-headtitle" id="sizeGuideBackLabel">{t("THE RING TEST")}</div>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className='sizeguide_modal-container'>
                        <div className='sizeguide_modal-container-box'>
                            <div className='title'>{t("WHAT YOU NEED")}</div>
                            <div className='sizeguide_modal-container-box-images'>
                                <img src='ruler'/>
                                <img src='ring'/>
                            </div>
                        </div>
                        <div className='sizeguide_modal-container-box'>
                            <div className='title'>{t("WHAT TO DO")}</div>
                            <div className='sizeguide_modal-container-box-content'>
                               <span>1. {t("Measue the diameter on the inside circle of the ring")}</span>
                               <span>2. {t("Use the chart below to find the corresponding ring size in the top row")}</span>
                            </div>
                        </div>
                        <div className='sizeguide_modal-container-tablebox'>
                            <div className='header'>{t("Ring Size")}</div>
                            <div className='sizeguide_modal-container-tablebox-top'>
                                <span>3</span>
                                <span>3.5</span>
                                <span>4</span>
                                <span>4.5</span>
                                <span>5</span>
                                <span>5.5</span>
                                <span>6</span>
                                <span>6.5</span>
                                <span>7</span>
                                <span>7.5</span>
                                <span>8</span>
                                <span>8.5</span>
                            </div>
                            <div className='sizeguide_modal-container-tablebox-bottom'>
                                <span>14.0</span>
                                <span>14.4</span>
                                <span>14.8</span>
                                <span>15.2</span>
                                <span>15.6</span>
                                <span>16.0</span>
                                <span>16.45</span>
                                <span>16.9</span>
                                <span>17.3</span>
                                <span>17.7</span>
                                <span>18.2</span>
                                <span>18.6</span>
                            </div>
                            <div className='sizeguide_modal-container-tablebox-measure'>
                                <span>{t("Measurement")}: {t("Mm")}</span>
                            </div>
                        </div>
                        <div className='sizeguide_modal-container-tablebox'>
                            <div className='header'>{t("Ring Size")}</div>
                            <div className='sizeguide_modal-container-tablebox-top'>
                                <span>9</span>
                                <span>9.5</span>
                                <span>10</span>
                                <span>10.5</span>
                                <span>11</span>
                                <span>11.5</span>
                                <span>12</span>
                                <span>12.5</span>
                                <span>13</span>
                                <span>13.5</span>
                                <span>14</span>
                                <span>14.5</span>
                            </div>
                            <div className='sizeguide_modal-container-tablebox-bottom'>
                                <span>19.0</span>
                                <span>19.4</span>
                                <span>19.8</span>
                                <span>20.2</span>
                                <span>20.6</span>
                                <span>21.0</span>
                                <span>21.4</span>
                                <span>21.8</span>
                                <span>22.2</span>
                                <span>22.6</span>
                                <span>23.0</span>
                                <span>23.4</span>
                            </div>
                            <div className='sizeguide_modal-container-tablebox-measure'>
                                <span>{t("Measurement")}: {t("Mm")}</span>
                            </div>
                        </div>
                    </div>
                    {/* <div className="m-32 w-100 d-flex justify-content-center">
                        <table className="w-100">
                            <thead>
                                <tr className="align-middle ">
                                    <th className="sizeguide_modal-headtitle text-center pt-24 pb-24 ">{t("Size")}({t("mm.")})</th>
                                    <th className="sizeguide_modal-headtitle text-center pt-24 pb-24 ">{t("US")}</th>
                                    <th className="sizeguide_modal-headtitle text-center pt-24 pb-24">{t("EU")}</th>
                                    <th className="sizeguide_modal-headtitle text-center pt-24 pb-24">{t("JP")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                { sizeContent.map((item, i)=>{
                                    return(
                                        <tr className="text-nowrap align-middle" key={i}>
                                            <td className="sizeguide_modal-subtitle text-center pb-32">{t(`${item.size}`)}</td>
                                            <td className="sizeguide_modal-subtitle text-center pb-32">{t(`${item.us ? item.us : "-"}`)}</td>
                                            <td className="sizeguide_modal-subtitle text-center pb-32">{t(`${item.eu ? item.eu : "-"}`)}</td>
                                            <td className="sizeguide_modal-subtitle text-center pb-32">{t(`${item.jp ? item.jp : "-"}`)}</td>
                                        </tr>
                                    )
                                })

                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='w-full   ' >
                        <h1 className='sizeguide_modal-headtitle mb-24 text-center' >{t("How to Measure")}</h1>
                        <div className="d-flex justify-content-center flex-column">
                            <div className=' d-flex justify-content-center gap-4  ' >


                                <button
                                    className={activeTab === 'tab1' ? 'sizeguide_modal-activeSizeButton sizeguide_modal-subtitle  ' : 'sizeguide_modal-notactiveSizeButton sizeguide_modal-subtitle '}
                                    onClick={() => handleTabClick('tab1')}
                                >
                                   {t(" The Hands-on Method")}
                                </button>
                                <button
                                    className={activeTab === 'tab2' ? 'sizeguide_modal-activeSizeButton sizeguide_modal-subtitle' : 'sizeguide_modal-notactiveSizeButton sizeguide_modal-subtitle'}
                                    onClick={() => handleTabClick('tab2')}
                                >
                                    {t("Measure on Existing Ring")}
                                </button>
                            </div>

                            <div id="tab1" className={activeTab === 'tab1' ? 'd-flex justify-content-center w-100  ' : 'd-none'}>
                                <div style={{ padding: '0px 32px', }} className='d-flex justify-content-center flex-column' >
                                    <img style={{ marginTop: '20px' , marginBottom:'10px' }} src={theTap} alt='The Tap' />
                                    <p className='sizeguide_modal-subtitle  ' >1.{t(" Grab a tape measure, length of string or strip of paper")}.</p>
                                    <p className='sizeguide_modal-subtitle' >2. {t("Wrap it around the finger you’ll wear your ring on - if you’re creating a ring stack")}, {t("consider where on your finger the ring will sit and mask the place where it joins")}.</p>
                                </div>

                            </div>
                            <div id="tab2" className={activeTab === 'tab2' ? 'd-flex justify-content-center w-100' : 'd-none'}>
                                <div style={{ padding: '0px 32px', }} className='d-flex justify-content-center flex-column gap-4  ' >
                                    <img src={ScaleImg} alt='The Tap' />
                                    <p className='sizeguide_modal-subtitle  ' >1.  {t("Choose a ring that already fits your finger well")}.</p>
                                    <p className='sizeguide_modal-subtitle' >2.  {t("Measure the interior diameter of the ring in millimeters(mm)")}.</p>
                                    <p className='sizeguide_modal-subtitle' >3. {t("Use the measurement chart above to match the size of your ring to the closet size in mm")}.</p>
                                </div>

                            </div>
                        </div>
                    </div> */}
                </div>
        </div>
    </div>
</div>
  )
}
