import CloseIcon from 'admin/common/icons/CloseIcon';
import MessageIcon from 'admin/common/icons/MessageIcon';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function ZoneSidebarPopup({ RemarksubmittedValue, setRemarkShowSidebar, onSubmit }) {
    const { t } = useTranslation();
    const [textareaValue, setTextareaValue] = useState(RemarksubmittedValue);
    const handleChange = (event) => {
        setTextareaValue(event.target.value); // Update state with textarea value
    };

    const handleSubmit = () => {
        onSubmit(textareaValue);
        setRemarkShowSidebar(false);
    };
    return (
        <div className='sidebarpopup'>
            <div className='sidebarpopup-inner'>
                <div className='sidebarpopup-inner-top'>
                    <div className='sidebarpopup-inner-top-content'>
                        <MessageIcon />
                        <span>{t("Remark")}</span>
                    </div>
                    <div className='cursor-pointer' onClick={() => setRemarkShowSidebar(false)}>
                        <CloseIcon />
                    </div>
                </div>
                <textarea
                    placeholder='Type your remark here.'
                    value={textareaValue ? textareaValue : RemarksubmittedValue}
                    onChange={handleChange}
                />
                <div className='sidebarpopup-inner-bottom'>
                    <button onClick={handleSubmit}>{t("Submit")}</button>
                </div>
            </div>
        </div>
    )
}
