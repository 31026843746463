import React from 'react'

export default function PlusIcon(props) {
    const { width, height, color } = props
    return (
        <svg width={width ? width : "16"} height={height ? height : "16"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M23 13C23 13.5523 22.5523 14 22 14H2C1.44772 14 1 13.5523 1 13C1 12.4477 1.44772 12 2 12H22C22.5523 12 23 12.4477 23 13Z" fill={color ? color : "#292D32"} />
            <path fillRule="evenodd" clipRule="evenodd" d="M12 24C11.4477 24 11 23.5523 11 23L11 3C11 2.44772 11.4477 2 12 2C12.5523 2 13 2.44772 13 3L13 23C13 23.5523 12.5523 24 12 24Z" fill={color ? color : "#292D32"} />
        </svg>

    )
}
