import React, { useEffect, useState } from 'react'
import "./common3.scss"
import SideBanner from "../assets/images/LoginSideBanner.png"
import CrossIcon from '../assets/icons/CrossIcon'
import { _Apiauth } from '../../common/api'
import { useTranslation } from 'react-i18next';
import Select from "react-select";
const Register = (props) => {
    const { setOpenmodal, setEmailVerifydata } = props
    const { t } = useTranslation()
    const [signupdata, setSignupdata] = useState()
    const [validation, setValidation] = useState()
    const [selectdata, setSelectdata] = useState({ country: [] });
    const [lodaer, setLodaer] = useState(false)
    const allsignupdata = (keyName, value) => {
        setSignupdata((prevState) => ({
            ...prevState,
            [keyName]: value,
        }))
    }
    // eslint-disable-next-line
    const webregister = async () => {
        let valid = () => {
            let fromisvalid = true
            if (!signupdata?.fname) {
                setValidation(prevState => ({
                    ...prevState,
                    namevalid: {
                        message: 'First Name is required',
                        status: true
                    }
                }));
                fromisvalid = false
            } else {
                setValidation(prevState => ({
                    ...prevState,
                    namevalid: {
                        message: "",
                        status: false
                    }
                }));
            }
            if (!signupdata?.lname) {
                setValidation(prevState => ({
                    ...prevState,
                    lnamevalid: {
                        message: "Last Name is required",
                        status: true
                    }
                }));
                fromisvalid = false
            } else {
                setValidation(prevState => ({
                    ...prevState,
                    lnamevalid: {
                        message: "",
                        status: false
                    }
                }));
            }
            if (!signupdata?.country?.value) {
                setValidation(prevState => ({
                    ...prevState,
                    countryvalid: {
                        message: "Country is required",
                        status: true
                    }
                }));
                fromisvalid = false
            } else {
                setValidation(prevState => ({
                    ...prevState,
                    countryvalid: {
                        message: "",
                        status: false
                    }
                }));
            }
            if (!signupdata?.email) {
                setValidation(prevState => ({
                    ...prevState,
                    emailvalid: {
                        message: "Email is required",
                        status: true
                    }
                }));
                fromisvalid = false
            } else {
                const isEmail = (email) =>
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
                if (!isEmail(signupdata?.email)) {
                    setValidation(prevState => ({
                        ...prevState,
                        emailvalid: {
                            message: "Invalid Email",
                            status: true
                        }
                    }));
                    fromisvalid = false
                } else {
                    setValidation(prevState => ({
                        ...prevState,
                        emailvalid: {
                            message: "",
                            status: false
                        }
                    }));
                }
            }
            if (!signupdata?.password) {
                setValidation(prevState => ({
                    ...prevState,
                    passwordvalid: {
                        message: "Password is required",
                        status: true
                    }
                }));
                fromisvalid = false
            } else {
                setValidation(prevState => ({
                    ...prevState,
                    passwordvalid: {
                        message: "",
                        status: false
                    }
                }));
            }
            if (!signupdata?.termsCondition) {
                setValidation(prevState => ({
                    ...prevState,
                    termsCondition: {
                        message: "Plese select term & conditions",
                        status: true
                    }
                }));
                fromisvalid = false
            } else {
                setValidation(prevState => ({
                    ...prevState,
                    termsCondition: {
                        message: "",
                        status: false
                    }
                }));
            }
            return fromisvalid;
        }

        if (valid()) {
            setLodaer(true)
            try {
                let signupobj = { ...signupdata }
                signupobj.country = signupdata?.country?.value
                delete signupobj?.termsCondition
                let postdata = signupobj
                var res = await _Apiauth(postdata, `auth/register`)
                if (res?.statusCode === 200) {
                    setLodaer(false)
                    if (res?.isVarified === 1) {
                        setEmailVerifydata({ accessToken: res?.accessToken, email: signupdata?.email })
                        setOpenmodal(prevState => ({
                            ...prevState,
                            showLoginModel: false,
                            showRegisterModel: false,
                            showemailvarifymodal: true,
                            forgetpassword: false,
                            resetpassword: false,
                            newpassword: false
                        }))
                    } else {
                        setLodaer(false)
                        localStorage.setItem('ecomaccesstoken', (res?.accessToken));
                        setValidation(prevState => ({
                            ...prevState,
                            sussess: {
                                message: "Register Successfully",
                                status: true
                            }
                        }));
                        setOpenmodal(prevState => ({
                            ...prevState,
                            showLoginModel: false,
                            showRegisterModel: false,
                            showemailvarifymodal: false,
                            forgetpassword: false,
                            resetpassword: false,
                            newpassword: false
                        }));
                    }
                } else {
                    setLodaer(false)
                    setValidation(prevState => ({
                        ...prevState,
                        sussess: {
                            message: res?.message,
                            status: true
                        }
                    }));
                }
            }
            catch (err) {
                setLodaer(false)
                if (err.response.status) {
                    setValidation(prevState => ({
                        ...prevState,
                        sussess: {
                            message: err.response.data.message,
                            status: true
                        }
                    }));
                }
                console.log(err, 'err')
            }
        }

    }
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                webregister();
            }
        };
        document.addEventListener('keypress', handleKeyPress);
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
        // eslint-disable-next-line
    }, [webregister]);
    const getcountry = async () => {
        try {
            let postdata = {};
            let res = await _Apiauth(postdata, `redis/countryList`)
            if (res?.statusCode === 200) {
                let countrydata = [];
                res?.data.forEach((result) => {
                    countrydata.push({
                        label: result?.name,
                        value: result?.id,
                    });
                });
                setSelectdata({ country: countrydata });
            }
        } catch (err) {
            console.log(err, "err");
        }
    };
    useEffect(() => {
        getcountry();
    }, []);
    return (
        <div className='ecommerce-loginpage-overlay'>
            <div className='ecommerce-loginpage'>
                <span className='Cross_icon'
                    onClick={() => {
                        setOpenmodal(prevState => ({
                            ...prevState,
                            showLoginModel: false,
                            showRegisterModel: false,
                            showemailvarifymodal: false,
                            forgetpassword: false,
                            resetpassword: false,
                            newpassword: false
                        }));
                    }} >
                    <CrossIcon width={30} height={30} />
                </span>
                <div className='ecommerce-loginpage-inner registerpage'>
                    <div className='formdata'>
                    <img className='ecommerce-loginpage-inner-logo' src={SideBanner} alt='side_banner' />
                    <div className='ecommerce-loginpage-inner-title'>
                        <div className='ecommerce-loginpage-inner-title-heading'>{t("Sign Up")}</div>
                        <div className='ecommerce-loginpage-inner-title-subhead'> {t("If you don’t have an account, Please sign up here")}</div>
                    </div >
                    <div className='ecommerce-loginpage-inner-input_section mt-0'>

                        <div className="ecom-formInputBox">
                            <label className={`ecom-formInputBox-label ${signupdata?.fname ? 'active' : ''}`}>
                                {t("First Name")}<span className='text-danger'> *</span>
                            </label>
                            <input
                                className={`ecom-formInputBox-input ${validation?.namevalid?.status ? 'border-danger' : ''}`}
                                value={signupdata?.fname ? signupdata?.fname : ""}
                                type='text'
                                onChange={(e) => allsignupdata("fname", e.target.value)}
                            />
                                {validation?.namevalid?.status  ? <div className='text-danger'>{t(`${validation?.namevalid?.message}`)}</div> : ""}
                        </div>

                            <div className="ecom-formInputBox">
                                <label className={`ecom-formInputBox-label ${signupdata?.lname ? 'active' : ''}`}>
                                    {t("Last Name")}<span className='text-danger'> *</span>
                                </label>
                                <input
                                    className={`ecom-formInputBox-input ${validation?.lnamevalid?.status ? 'border-danger' : ''}`}
                                    value={signupdata?.lname ? signupdata?.lname : ""}
                                    type='text'
                                    onChange={(e) => allsignupdata("lname", e.target.value)}
                                />
                                    {validation?.lnamevalid?.status  ? <div className='text-danger'>{t(`${validation?.lnamevalid?.message}`)}</div> : ""}
                            </div>

                            <div className="ecom-formInputBox">
                                <label className={`ecom-formInputBox-label ${signupdata?.email ? 'active' : ''}`}>
                                    {t("Email")}<span className='text-danger'> *</span>
                                </label>
                                <input
                                    className={`ecom-formInputBox-input ${validation?.emailvalid?.status ? 'border-danger' : ''}`}
                                    value={signupdata?.email ? signupdata?.email : ""}
                                    type='text'
                                    onChange={(e) => allsignupdata("email", e.target.value)}
                                />
                                    {validation?.emailvalid?.status  ? <div className='text-danger'>{t(`${validation?.emailvalid?.message}`)}</div> : ""}
                            </div>
                            <div className="ecom-formInputBox">
                                <label className={`ecom-formInputBox-label ${signupdata?.password ? 'active' : ''}`}>
                                    {t("Password")}<span className='text-danger'> *</span>
                                </label>
                                <input
                                    className={`ecom-formInputBox-input ${validation?.passwordvalid?.status ? 'border-danger' : ''}`}
                                    value={signupdata?.password ? signupdata?.password : ""}
                                    type='password'
                                    onChange={(e) => allsignupdata("password", e.target.value)}
                                />
                                    {validation?.passwordvalid?.status  ? <div className='text-danger'>{t(`${validation?.passwordvalid?.message}`)}</div> : ""}
                            </div>

                    
                        <div className="ecom-formInputBox">
                            <label className={`ecom-formInputBox-dropdownlabel ${selectdata?.country ? "active" : ""}`}>
                                {t("Country")} <span className="text-danger">*</span>
                            </label>
                            <Select
                                options={selectdata?.country}
                                className={`${validation?.countryvalid?.status ? "border-danger" : ""}`}
                                height='46px'
                                width='100%'
                                value={signupdata?.country || selectdata?.country[0]}
                                onChange={(e) => allsignupdata("country", e)}
                                isSearchable={true}
                                placeholder="Country"
                                classNamePrefix='newDropdown'
                                />
                               {validation?.countryvalid?.status ? <div className='text-danger'>{t(`${validation?.countryvalid?.message}`)}</div> : ""}
                        </div>
                       
                        <div className='ecom-darkthemed-checkbox d-flex align-items-center gap-2'> 
                                <input 
                                onChange={(e) => allsignupdata("termsCondition", !signupdata?.termsCondition)} 
                                className="itemscheckboxs" 
                                type='checkbox' 
                                id="t&c"
                                checked={signupdata?.termsCondition ? true : false}
                                />
                                <label className='ecom-darkthemed-checkbox-label' htmlFor='t&c'></label>
                                <span className={`${validation?.termsCondition?.status && !signupdata?.termsCondition ? 'text-danger' : ""}`}>{t("I agree with Terms & Conditions")}</span>
                            </div>
                        {validation?.sussess?.status ?
                            <div className='text-danger validation-message'>{t(validation?.sussess?.message)}</div> : ""
                        }
                        {lodaer ?
                            <button className='btnbold'>
                                <div className="border-bottom-0 border-width-2px text-white d-block mx-auto spinner-border flex-shrink-0" role="status"></div>
                            </button> :
                            <button className='btnbold' onClick={() => webregister()}>{t("Sign Up")}</button>}

                    </div>
                    <div className='ecommerce-loginpage-inner-create-account'>
                        <div className='ecommerce-loginpage-inner-create-account-register_btn'>
                            {t("Already have an account")}?
                            <span onClick={() => {
                                setOpenmodal(prevState => ({
                                    ...prevState,
                                    showLoginModel: true,
                                    showRegisterModel: false,
                                    showemailvarifymodal: false,
                                    forgetpassword: false,
                                    resetpassword: false,
                                    newpassword: false
                                }));
                            }}>
                                {t("Sign In")}
                            </span>
                        </div>
                        {/* <div className='ecommerce-loginpage-inner-create-account-privacy_notice'>
                            {t("By continuing, I confirm that I have read and agree to the")}
                        </div>
                        <div className='ecommerce-loginpage-inner-create-account-privacy_notice no_padding'>
                          {t("Privacy Notice")} {t("and Term and Conditions")}
                        </div> */}
                    </div>
                    </div>
                </div >
                <div className='ecommerce-loginpage-sidebanner'>
                    <div className='ecommerce-loginpage-sidebanner-holder'>
                        <img src={SideBanner} alt='side_banner' />
                    </div>
                </div >
            </div >
        </div>

    )
}

export default Register