import React, { useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";

export default function CheckoutForm({ onPaymentSuccess, successMessage, setCreditpaymentdata, creditpaymentdata }) {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState("");

    const handleSubmit = async (e) => {
        if (!stripe || !elements) {
            return;
        }
        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            redirect: "if_required"
        });

        if (!error) {
            setMessage(successMessage);
            onPaymentSuccess();
            setCreditpaymentdata((state) => ({
                ...state,
                payment: true,
                message: successMessage,
                submitdata: false,
                stripekey: paymentIntent?.id
            }))
        } else {
            if (error.type === "card_error" || error.type === "validation_error") {
                setCreditpaymentdata((state) => ({
                    ...state,
                    message: error.message,
                    payment: false,
                    submitdata: false
                }))
            } else {
                setCreditpaymentdata((state) => ({
                    ...state,
                    message: error.message,
                    submitdata: false,
                    payment: false,
                }))
            }
        }

    };
    useEffect(() => {
        if (creditpaymentdata?.submitdata) {
            handleSubmit()
        }
        // eslint-disable-next-line
    }, [creditpaymentdata?.submitdata])
    return (
        <div>

            <>
                <PaymentElement id="payment-element" />
            </>
            {/* Show any error or success messages */}
            {message && <div id="payment-message"><p>{message}</p></div>}

        </div>
    );
}
