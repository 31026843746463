import React, { useEffect } from 'react'
import "./common4.scss"
import loginsideBanner from "../assets/images/LoginSideBanner.png"
import CrossIcon from '../assets/icons/CrossIcon'
import { useState } from 'react'
import RightArrowIcon from "../assets/icons/RightArrowIcon"
import { _Apiauth } from '../../common/api'
import { useTranslation } from "react-i18next";
const ForgetPassword = (props) => {
    const { setOpenmodal, setForgetemail } = props
    const { t } = useTranslation();
    const [logindata, setLogindata] = useState()
    const [validation, setValidation] = useState()
    const [lodaer, setLodaer] = useState(false)
    const alllogindata = (keyName, value) => {
        setLogindata((prevState) => ({
            ...prevState,
            [keyName]: value,
        }))
    }
    // eslint-disable-next-line
    const forgetpasswordapi = async () => {
        if (!logindata?.email) {
            setValidation((state) => ({
                ...state,
                emailvalid: {
                    status: true,
                    message: "Email is required"

                }
            }))
            return;
        } else {
            setValidation((state) => ({
                ...state,
                emailvalid: {
                    status: false,
                    message: ""

                }
            }))
        }

        try {
            setLodaer(true)
            let postdata = logindata
            let res = await _Apiauth(postdata, `auth/forgot-password`)
            if (res?.statusCode === 200) {
                setLodaer(false)
                await setForgetemail((state) => ({
                    ...state,
                    email: logindata?.email,
                    resetToken: res?.resetToken
                })
                )
                setOpenmodal(prevState => ({
                    ...prevState,
                    showLoginModel: false,
                    showRegisterModel: false,
                    showemailvarifymodal: false,
                    forgetpassword: false,
                    resetpassword: true,
                    newpassword: false
                }))

            } else {
                setLodaer(false)
            }
        }
        catch (err) {
            setLodaer(false)
            console.log(err, "err")
            if (err.response.status) {
                setValidation(prevState => ({
                    ...prevState,
                    sussess: {
                        message: err.response.data.message,
                        status: true
                    }
                }));
            }
        }
    }
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                forgetpasswordapi();
            }
        };
        document.addEventListener('keypress', handleKeyPress);
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
        // eslint-disable-next-line
    }, [forgetpasswordapi]);
    return (
        <div className='ecommerce-loginpagenew-forgetpwd'>
            <div className='ecommerce-loginpagenew-forgetpwd-inner'>
                <span className='Cross_icon' onClick={() => {
                    setOpenmodal(prevState => ({
                        ...prevState,
                        showLoginModel: false,
                        showRegisterModel: false,
                        showemailvarifymodal: false,
                        forgetpassword: false,
                        resetpassword: false,
                        newpassword: false
                    }));
                }} >
                    <CrossIcon width={30} height={30} />
                </span>
                <div className='ecommerce-loginpagenew-forgetpwd-inner-content' >
                    <div className='formdata'>
                    <div className='d-flex flex-column gap-56'>
                        <div className='ecommerce-loginpagenew-forgetpwd-inner-title'>
                            <div className=''>{t("Reset Password")}</div>
                        </div >
                        <div className='ecommerce-loginpagenew-forgetpwd-inner-input_section'>
                            <div className='ecommerce-loginpagenew-forgetpwd-inner-input_section-subhead'>{t("Provide your account email address to receive an email to reset your password.")}</div>
                            
                            <div className="ecom-formInputBox">
                                <label className={`ecom-formInputBox-label ${logindata?.email ? 'active' : ''}`}>
                                    {t("Email")}
                                </label>
                                <input
                                    className={`ecom-formInputBox-input ${validation?.emailvalid?.status ? 'border-danger' : ''}`}
                                    value={logindata?.email ? logindata?.email : ""}
                                    onChange={(e) => alllogindata("email", e.target.value)} 
                                />
                                    {validation?.emailvalid?.status ? <div className='text-danger'>{t(`${validation?.emailvalid?.message}`)}</div> : ""}
                            </div>
                            
                            {/* <div className={`ecommerce-loginpagenew-forgetpwd-inner-input_section-inner ${validation?.emailvalid?.status && "required"}`}>
                                <label>
                                    {t("Enter Email Address")}
                                </label>
                                <input className={`ecommerce-loginpagenew-forgetpwd-inner-input_section-inner-input ${validation?.emailvalid?.status && "border-danger"}`}
                                    onChange={(e) => alllogindata("email", e.target.value)} />
                                {validation?.emailvalid?.status ?
                                    <div className='text-danger'>
                                        {t(`${validation?.emailvalid?.message}`)}
                                    </div>
                                    : ""}
                            </div> */}
                            {validation?.sussess?.status ?
                                <div className='text-danger'>{t(`${validation?.sussess?.message}`)}</div> : ""
                            }
                            {lodaer ?
                                <button className='themeBtn fit'>
                                    {/* loader */}
                                    <div className="border-bottom-0 border-width-2px text-white d-block mx-auto spinner-border flex-shrink-0" role="status">
                                    </div>
                                </button> 
                                :
                                <button className='themeBtn fit'
                                    onClick={() => {
                                        forgetpasswordapi()
                                    }} ><span>{t("Submit")}</span>
                                </button>
                            }
                             
                        </div>
                    </div>
                    </div>
                </div >
            </div >
        </div >
    )
}

export default ForgetPassword