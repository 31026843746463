import { atom } from "recoil";

export const selectedInventoryItemsState = atom({
    key: "selectedInventoryItemsState",
    default: []
})
export const currentSelectedInventoryItemsState = atom({
    key: "currentSelectedInventoryItemsState",
    default: []
})
export const templateTypeState = atom({
    key: "templateTypeState",
    default: false
})
export const exampleCustomPrintOutUriState = atom({
    key: "exampleCustomPrintOutUriState",
    default: false
})

export const templateProfileConfigDataState = atom({
    key: "templateProfileConfigDataState",
    default: false
})
export const currentCheckIdState = atom({
    key: "currentCheckIdState",
    default: []
})
export const paginationLinkListState = atom({
    key: "paginationLinkListState",
    default: false
})

export const currentSelectedIdState = atom({
    key: "currentSelectedIdState",
    default: []
})


export const selectedProfileState = atom({
    key: "selectedProfileState",
    default: false
})

export const showImportStockIdModalState = atom({
    key: "showImportStockIdModalState",
    default: false
})

export const countSelectedInventoryItemState = atom({
    key: "countSelectedInventoryItemState",
    default: 0
})

export const selectedInventoryIdState = atom({
    key: "selectedInventoryIdState",
    default: []
})


