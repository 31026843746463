import React, { useEffect, useState } from 'react';
import MinusIcon from "../../assets/icons/MinusIcon";
import PlusIcon from "../../assets/icons/PlusIcon";
import { Link, useNavigate } from 'react-router-dom';
import { _Api } from '../../../common/api';
import Spinner from '../../../common/Spinner';
import { useTranslation } from 'react-i18next';
import { toFormatPrice } from '../../../common/Formateprice';
const ShoppingCart = () => {
    let shipdata = JSON.parse(localStorage.getItem('CartItems'))
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [cartdata, setCartdata] = useState([])
    const [loading, setLoading] = useState(false)
    const [checkid, setCheckid] = useState([])
    const getQuantity = (index, lable, qty) => {
        let array = [...cartdata]
        if (lable === "plus") {
            array[index].qty = qty + 1
        } else if (lable === "minus") {
            array[index].qty = qty - 1
        }
        setCartdata(array)
        let setupdatearray = []
        array.forEach((result) => {
            setupdatearray.push({
                id: result?.id,
                qty: result?.qty
            })
        })
        localStorage.setItem('CartItems', JSON.stringify(setupdatearray));
    }
    const deleteItems = (removeid) => {
        let array = [...cartdata]
        let update = array.filter(e => e?.id !== removeid);
        setCartdata(update);
        let setupdatearray = []
        update.forEach((result) => {
            setupdatearray.push({
                id: result?.id,
                qty: result?.qty
            })
        })
        localStorage.setItem('CartItems', JSON.stringify(setupdatearray));

    }
    const getcarts = async (shipdata) => {
        setLoading(true)
        let cartids = [];
        if (shipdata?.length) { await shipdata?.map((result) => cartids.push(result?.id)) }
        try {
            let postdata = {
                cart_ids: cartids
            }
            let res = await _Api(postdata, `order/getCartDetails`)
            if (res?.statusCode === 200) {
                let array = [...res?.data];
                array?.map((result) => {
                    return result.qty = parseInt(shipdata.filter(e => e?.id === result?.id)[0]['qty']);
                })
                setCartdata(array)
                setLoading(false)
                if (res?.deleted_ids?.length) {
                    shipdata.forEach((result) => {
                        if (!res?.deleted_ids.includes(result?.id)) {
                            let data = []
                            data.push(result)
                            localStorage.setItem('CartItems', JSON.stringify(data));
                        }
                    })
                }
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const negvigatecheckout = (lable, data) => {
        if (lable === "checkout") {
            navigate("/shipping", { state: { checkoutItems: data } })
        }
        if (lable === "appoinment") {
            navigate("/appointment", { state: { checkoutItems: data } })
        }
    }
    useEffect(() => {
        getcarts(shipdata)
        // eslint-disable-next-line
    }, [])
    const checkcart = (event, data) => {
        if (data?.id) {
            setCheckid((prevChecked) =>
                event.target.checked
                    ? [...prevChecked, { id: data?.id, qty: data?.qty, price: data?.UnFormatPrice }]
                    : prevChecked.filter((item) => item?.id !== data?.id)
            )
        } else {
            let fulldata = []
            if (event.target.checked) {
                cartdata.forEach((result) => {
                    fulldata.push({ id: result?.id, qty: result?.qty, price: result?.UnFormatPrice })
                })
            }
            setCheckid(fulldata)
        }
    }
    return (
        <div className=' ecommerce-main-layout-bodyContent'>
            <div className='shoppingCartPage'>
                <div className='shoppingCartPage-headBox'>
                    <h3 className='shoppingCartPage-headBox-heading'>{t("Shopping Cart")}</h3>
                </div>
                {loading ? (<div className='h-100 d-flex justify-content-center align-items-center'>

                    <Spinner />

                </div>) : (
                    <>
                        {cartdata?.length ? (
                            <>
                                <div className='shoppingCartPage-cartTableBox'>
                                    <div>
                                        <div className='shoppingCartPage-cartTableBox-header'>
                                            <div className='shoppingCartPage-cartTableBox-header-left'>
                                                <div>
                                                    <div className="com-ecomCheckBox">
                                                        <input type="checkbox" className="itemscheckbox com-ecomCheckBox-inputcheck me-2 cursor-pointer"
                                                            onChange={(e) => checkcart(e, "")}
                                                            checked={checkid?.length === cartdata?.length}
                                                            id='allcartcheck' />
                                                        <label className="com-ecomCheckBox-labelBox cursor-pointer fw-semibold" htmlFor='allcartcheck'> {t("Product")}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='shoppingCartPage-cartTableBox-header-right'>
                                                <div className=''>{t("Price")}</div>
                                                <div className='responsive'>{t("Quantity")}</div>
                                                <div className='responsive'>{t("Total")}</div>
                                            </div>
                                        </div>
                                        <div>
                                            {cartdata?.length ? cartdata.map((result, key) => {
                                                return (
                                                    <div className='shoppingCartPage-cartTableBox-content' key={key}>
                                                        <div className='shoppingCartPage-cartTableBox-content-left'>
                                                            <div className='shoppingCartPage-cartTableBox-content-left-item' key={key}>
                                                                <div className="com-ecomCheckBox">
                                                                    <input type="checkbox" className="itemscheckbox com-ecomCheckBox-inputcheck me-2 cursor-pointer"
                                                                        id={result?.id}
                                                                        checked={checkid?.some(item => item.id === result?.id)}

                                                                        onChange={(e) => checkcart(e, result)} />
                                                                    <label className="com-ecomCheckBox-labelBox cursor-pointer fw-semibold align-items-center"
                                                                        htmlFor={result?.id}
                                                                    > <img className='shoppingCartPage-cartTableBox-content-left-item-img' src={result?.main_image} alt='' /></label>
                                                                </div>
                                                                <Link to={`/product/${result?.motherProduct?.Slug}?v=${result?.id}`}
                                                                    state={{ id: result?.motherProduct?._id }}
                                                                    className='shoppingCartPage-cartTableBox-content-left-item-name ms-2'>
                                                                    <p className='shoppingCartPage-cartTableBox-content-left-item-name'>{result.name} </p>
                                                                    <p className='shoppingCartPage-cartTableBox-content-left-item-subname'>{result?.price}</p>
                                                                    <p className='shoppingCartPage-cartTableBox-content-left-item-subname'>{result?.SKU ? result?.SKU : "-"}</p>
                                                                    <p className='shoppingCartPage-cartTableBox-content-left-item-subname'>{result?.Metal ? result?.Metal : ""}</p>

                                                                </Link>

                                                            </div>
                                                        </div>
                                                        <div className='cartQuantiyBox shoppingCartPage-cartTableBox-content-right'>
                                                            <div className='cartQuantiyBox shoppingCartPage-cartTableBox-content-right-price'>{result.price}</div>
                                                            <div className='cartQuantiyBox shoppingCartPage-cartTableBox-content-right-qty'>
                                                                <div className='cartQuantiyBox shoppingCartPage-cartTableBox-content-right-qty-btn'>
                                                                    <button disabled={result?.qty > 1 ? false : true} onClick={() => getQuantity(key, "minus", result?.qty)}><MinusIcon width={14} height={14} color={'#1D1D1F'} /></button>
                                                                    <div className='digit'>{result?.qty}</div>
                                                                    <button className='ml-1'
                                                                        onClick={() => getQuantity(key, "plus", result?.qty)}><PlusIcon width={14} height={14} color={'#1D1D1F'} />
                                                                    </button>
                                                                </div>
                                                                <div className='quantity cursor-pointer' onClick={() => deleteItems(result?.id)}>{t("Remove")}</div>
                                                            </div>
                                                            <div className='cartQuantiyBox shoppingCartPage-cartTableBox-content-right-total'>
                                                                {toFormatPrice(result.UnFormatPrice * result.qty, { addSymbol: true })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }) : ""}


                                            <div className="com-ecomTableMain-table-tbody-trBox-noDataBox text-center">
                                                <span onClick={() => window.location.reload()} className='cursor-pointer'>{t("Update cart")}</span>  || <Link to="/" className='text-decoration-none text-black mx-auto'><span>{t("Continue Shopping")}</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='shoppingCartPage-cartFooterbox'>
                                    <div className='shoppingCartPage-cartFooterbox-subTotalBox'>
                                        <p className=''>{t("SubTotal")}</p>
                                        <p> {toFormatPrice(cartdata.reduce(
                                            (accumulator, product) => accumulator + product.UnFormatPrice * product?.qty,
                                            0), { addSymbol: true }
                                        )}</p>
                                    </div>
                                </div>
                                {checkid?.length ? <div className='com-confirmPurchasePage-main-footerBox mb-4'>
                                    <button className='com-confirmPurchasePage-main-footerBox-completeBtn' onClick={() => negvigatecheckout("checkout", checkid)}>{t("Checkout")}</button>
                                    <button className='com-confirmPurchasePage-main-footerBox-completeBtn' onClick={() => negvigatecheckout("appoinment", checkid)} >{t("Appointment")}</button>
                                </div> : ""}
                            </>
                        ) : (
                            <>
                                <div className='d-flex justify-content-center w-100 flex-column'>
                                    <p className='text-center  SizeGuied-subtitle' >{t("Your cart is currently empty.")}</p>
                                    <p className='text-center'>{t("Still don't have something you want? Try checking out the new products in case you have something you like to add to your cart.")}</p>
                                    <Link to="/" className='continue_shoppingbtn text-decoration-underline text-black mx-auto'><span>{t("Continue Shopping")}</span></Link>
                                </div>
                            </>
                        )}

                    </>)}

            </div>
        </div>
    )
}

export default ShoppingCart