import React from 'react'

const SignUpBackIcon = (props) => {
    const { width, height } = props
    return (
        <svg  width={width ? width : "24"} height={height ? height : "24"} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.125 17.25C7.125 17.3495 7.08549 17.4448 7.01516 17.5152C6.94484 17.5855 6.84946 17.625 6.75 17.625H1.5C1.20163 17.625 0.915483 17.5065 0.704505 17.2955C0.493526 17.0845 0.375 16.7984 0.375 16.5V1.5C0.375 1.20163 0.493526 0.915483 0.704505 0.704505C0.915483 0.493526 1.20163 0.375 1.5 0.375H6.75C6.84946 0.375 6.94484 0.414509 7.01516 0.484835C7.08549 0.555161 7.125 0.650544 7.125 0.75C7.125 0.849456 7.08549 0.944839 7.01516 1.01516C6.94484 1.08549 6.84946 1.125 6.75 1.125H1.5C1.40054 1.125 1.30516 1.16451 1.23483 1.23484C1.16451 1.30516 1.125 1.40054 1.125 1.5V16.5C1.125 16.5995 1.16451 16.6948 1.23483 16.7652C1.30516 16.8355 1.40054 16.875 1.5 16.875H6.75C6.84946 16.875 6.94484 16.9145 7.01516 16.9848C7.08549 17.0552 7.125 17.1505 7.125 17.25ZM17.5153 8.73469L13.7653 4.98469C13.6949 4.91432 13.5995 4.87479 13.5 4.87479C13.4005 4.87479 13.3051 4.91432 13.2347 4.98469C13.1643 5.05505 13.1248 5.15049 13.1248 5.25C13.1248 5.34951 13.1643 5.44495 13.2347 5.51531L16.3444 8.625H6.75C6.65054 8.625 6.55516 8.66451 6.48483 8.73483C6.41451 8.80516 6.375 8.90054 6.375 9C6.375 9.09946 6.41451 9.19484 6.48483 9.26517C6.55516 9.33549 6.65054 9.375 6.75 9.375H16.3444L13.2347 12.4847C13.1643 12.5551 13.1248 12.6505 13.1248 12.75C13.1248 12.8495 13.1643 12.9449 13.2347 13.0153C13.3051 13.0857 13.4005 13.1252 13.5 13.1252C13.5995 13.1252 13.6949 13.0857 13.7653 13.0153L17.5153 9.26531C17.5502 9.23048 17.5778 9.18913 17.5967 9.1436C17.6156 9.09808 17.6253 9.04928 17.6253 9C17.6253 8.95072 17.6156 8.90192 17.5967 8.8564C17.5778 8.81087 17.5502 8.76952 17.5153 8.73469Z" fill="#1D1D1F"/>
</svg>

    )
}

export default SignUpBackIcon