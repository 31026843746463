import Diamond from 'admin/common/icons/Diamond';
import HelplineIcon from 'admin/common/icons/HelplineIcon';
import ShippingTruck from 'admin/common/icons/ShippingTruck';
import { toFormatPrice } from 'Ecommerce/common/Formateprice';
import MinusIcon from 'Ecommerce/template_three/assets/icons/MinusIcon';
import PlusIcon from 'Ecommerce/template_three/assets/icons/PlusIcon';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function OrderSummery(props) {

    const { t } = useTranslation();
    const { shippinglist, shippingdata, setShippinglist } = props;
    const [editToggle, setEditToggle] = useState(false)
    let localitems = JSON.parse(localStorage.getItem('CartItems'));
    const getQuantity = (index, lable, qty) => {
        let array = [...shippinglist];
        let newValue = [...localitems]
        let indexOf = newValue.findIndex(item=>item.id === array[index].id);
        if (lable === "plus") {
            array[index].qty = qty + 1;
            if(indexOf !== -1){
                newValue[indexOf].qty = qty+1
            }
        } else if (lable === "minus" && qty > 1) {
            array[index].qty = qty - 1
            if(indexOf !== -1){
                newValue[indexOf].qty = qty-1
            }
        }
        setShippinglist(array)
        localStorage.setItem('CartItems', JSON.stringify(newValue));
    }

  return (
    <>
    <div className='orderSummary'>
    <h3 className='com-shippingPage-headingMain m-0'>{t("Order Summary")}</h3>
        <div className='orderSummary-cardwrapper'>
            <div className='orderSummary-cardwrapper-cardholder'>
            {shippinglist?.length ? shippinglist.map((result, key) => {
                return (
                    <>
                     <div key={key} className={`ecom_cartproduct ${result?.diamond ? 'border-0 pb-0' : ""}`}>
                                                <div className="ecom_cartproduct-imageBox">
                                                  <span className="product-img-span">
                                                    <img src={result?.main_image} alt="" />
                                                  </span>
                                                </div>
                                                <div className=" ecom_cartproduct-detailsBox">
                                                  <div className="ecom_cartproduct-detailsBox-firstBox">
                                                    <p className="ecom_cartproduct-detailsBox-firstBox-productName">
                                                      <Link to={`/product/${result?.motherProduct?.Slug}?v=${result?.id}`}
                                                        state={{ id: result?.motherProduct?._id }}
                                                        className="ecom_cartproduct-detailsBox-firstBox-productName text-decoration-none">{result?.name}</Link>
                                                      <span className='ecom_cartproduct-detailsBox-firstBox-productName tab-productPriceBlock'>{result?.price}</span>
                                                    </p>
                                                    <div className="ecom_cartproduct-detailsBox-firstBox-instock">{t("In Stock")}</div>
                                                                                  <div className="ecom_cartproduct-detailsBox-firstBox-colorthemewrapper">
                                                                                      { (result?.Metal === '18KBK' || result?.Metal.includes('BK')) && <div className={`black`}></div>}
                                                                                      { (result?.Metal === '18KWG' || result?.Metal.includes('WG')) && <><div className={`titanium`}></div><span className="ecom_cartproduct-detailsBox-firstBox-colorthemewrapper-title">{t("Sliver")}</span></>}
                                                                                      { (result?.Metal === '18KYG' || result?.Metal.includes('YG')) && <><div className={`sunshine`}></div><span className="ecom_cartproduct-detailsBox-firstBox-colorthemewrapper-title">{t("Yellow Gold")}</span></>}
                                                                                      { (result?.Metal === '18KRG' || result?.Metal.includes('RG')) && <div className={`peach`}></div>}
                                                                                  </div>
                                                    <div className="ecom_cartproduct-detailsBox-firstBox-content">
                                                    <p className="contentPara">{t("Size")}: {result?.Size}</p>
                                                    <div className="ecom_cartproduct-detailsBox-firstBox-price">{result?.price}</div>
                                                    </div>
                                                  </div>
                                                  {result?.diamond ? null :
                                                  <div className='ecom_cartproduct-detailsBox-secondCountBox'>
                                                    <div className='setCountShow'>
                                                      <button onClick={()=>getQuantity(key,'minus', result?.qty)} className='setCountShow-minusIcon'>
                                                        <MinusIcon width={14} height={14} color={'#1D1D1F'} />
                                                      </button>
                                                      <span className='setCountShow-count'>{result?.qty}</span>
                                                      <button onClick={() => getQuantity(key,'plus', result?.qty)} className='setCountShow-plusIcon'>
                                                        <PlusIcon width={14} height={14} color={'#1D1D1F'} />
                                                      </button>
                                                    </div>
                                                  </div>
                                                    }
                                                </div>
                                              </div>
                                              {result?.diamond ?
                       <div key={key} className={`ecom_cartproduct pt-0`}>
                       <div className="ecom_cartproduct-imageBox">
                         <span className="product-img-span">
                           <img src={result?.diamond?.main_image} alt="diamond_image"/>
                         </span>
                       </div>
                       <div className=" ecom_cartproduct-detailsBox">
                         <div className="ecom_cartproduct-detailsBox-firstBox">
                           <p className="ecom_cartproduct-detailsBox-firstBox-productName">
                             <div
                               className="ecom_cartproduct-detailsBox-firstBox-productName text-decoration-none">{result?.diamond?.name}
                               </div>
                           </p>
                           <div className="ecom_cartproduct-detailsBox-firstBox-instock">{t("In Stock")}</div>
                                                     
                           <div className="ecom_cartproduct-detailsBox-firstBox-content">
                           <p className="contentPara">{result?.diamond?.Colour} {t("Colour")} / {result?.diamond?.Clarity} {t("Clarity")} {result?.diamond?.Shape} / {result?.diamond?.Cut} </p>
                           <div className="ecom_cartproduct-detailsBox-firstBox-price">{result?.diamond?.price}</div>
                           </div>
                         </div>
                         <div className='ecom_cartproduct-detailsBox-secondCountBox'>
                                                    <div className='setCountShow'>
                                                      <button onClick={()=>getQuantity(key,'minus', result?.qty)} className='setCountShow-minusIcon'>
                                                        <MinusIcon width={14} height={14} color={'#1D1D1F'} />
                                                      </button>
                                                      <span className='setCountShow-count'>{result?.qty}</span>
                                                      <button onClick={() => getQuantity(key,'plus', result?.qty)} className='setCountShow-plusIcon'>
                                                        <PlusIcon width={14} height={14} color={'#1D1D1F'} />
                                                      </button>
                                                    </div>
                                                  </div>
                       </div>
                     </div>
                      // <div key={key} className=" ecom_cartproduct diamonditem">
                      //   <div className="com-ecomCheckBox">

                      //   </div>
                      //   <div className="ecom_cartproduct-imageBox">
                      //     <span className="product-img-span">
                      //       <img src={result?.diamond?.main_image} alt="" />
                      //     </span>
                      //   </div>
                      //   <div className=" ecom_cartproduct-detailsBox">
                      //     <div className="ecom_cartproduct-detailsBox-firstBox">
                      //       <p className="ecom_cartproduct-detailsBox-firstBox-productName">
                      //         <div to={`/product/${result?.motherProduct?.Slug}?v=${result?.id}`}
                      //           state={{ id: result?.motherProduct?._id }}
                      //           // onClick={() => modalRef.current.hide()} 
                      //           className="ecom_cartproduct-detailsBox-firstBox-productName text-decoration-none">{result?.diamond?.name}
                      //           </div>
                      //         <span className='ecom_cartproduct-detailsBox-firstBox-productName tab-productPriceBlock'>{result?.diamond?.price}</span>
                      //       </p>
                      //       <div className="ecom_cartproduct-detailsBox-firstBox-content">
                      //         <p className="contentPara">{result?.diamond?.SKU}</p>
                      //       </div>
                      //     </div>
                      //   </div>
                      // </div>
                      : ""}
                        
                       
                    </>
                )
            }) : ""}
            </div>
            <div className='orderSummary-cardwrapper-addmore'>
                <span className='icon-holder'><PlusIcon/></span> {t("Add more item")}
            </div>
            <div className='orderSummary-amountCalcBox'>
                <div className='orderSummary-amountCalcBox-subTotalBox'>
                    <p className='orderSummary-amountCalcBox-subTotalBox-textCheck'>{t("Items")}</p>
                    <p className='orderSummary-amountCalcBox-subTotalBox-subAmountShow'>{shippinglist?.length}</p>
                </div>
                <div className='orderSummary-amountCalcBox-subTotalBox'>
                    <p className='orderSummary-amountCalcBox-subTotalBox-textCheck'>{t("Subtotal")}</p>
                    <p className='orderSummary-amountCalcBox-subTotalBox-subAmountShow'> 
                      {toFormatPrice(shippinglist?.length ? shippinglist.reduce(
                        (accumulator, product) => accumulator + product.UnFormatPrice * product?.qty,
                        0
                    ) : 0, { addSymbol: true })}
                    </p>
                </div>
                <div className='orderSummary-amountCalcBox-subTotalBox'>
                    <p className='orderSummary-amountCalcBox-subTotalBox-textCheck'>{t("Shipping")}</p>
                    <p className='orderSummary-amountCalcBox-subTotalBox-subAmountShow'>  {toFormatPrice(shippingdata?.shipprice, { addSymbol: true })}</p>
                </div>
                <div className='orderSummary-amountCalcBox-subTotalBox'>
                    <p className='orderSummary-amountCalcBox-subTotalBox-textCheck'>{t("Vat 7%")}</p>
                    <p className='orderSummary-amountCalcBox-subTotalBox-subAmountShow'>  {toFormatPrice(shippingdata?.shipprice, { addSymbol: true })}</p>
                </div>
            </div>
        </div>
        <div className='orderSummary-grandTotalBox'>
                <p className='grandTotalBox-grandTotalText'>{("Total")}</p>
                <p className=''> 
                    {toFormatPrice(shippinglist?.length ?
                    shippingdata?.shipprice ? shippingdata?.shipprice + shippinglist.reduce(
                        (accumulator, product) => accumulator + product.UnFormatPrice * product?.qty,
                        0
                    ) : shippinglist.reduce(
                        (accumulator, product) => accumulator + product.UnFormatPrice * product?.qty,
                        0
                    ) : 0 + 0, { addSymbol: true })}
                </p>
        </div>
    </div>
    {/* <div className="shippingpage-bothSideBox-rightSide-editBottomBox">
    <div className="com-shippingPage-headingMain mb-4">
        <span className="shippingpage-bothSideBox-rightSide-editBottomBox-item">{shippinglist?.length} {t("ITEMS")}</span>
        <span className="shippingpage-bothSideBox-rightSide-editBottomBox-edit" onClick={()=>setEditToggle(!editToggle)}>{t("Edit")}</span>
    </div>
    </div> */}
    <div className='instructionBox'>
        <div className='instructionBox-title'>
            {t("Buy with confidence")}
        </div>
        <div className='instructionBox-data'>
            <div className='instructionBox-data-item'>
                <span className='instructionBox-data-item-icon'><Diamond/></span>
                <div className='instructionBox-data-item-content'>
                    <span className='instructionBox-data-item-content-head'>{t("High Quality jewelry")}</span>
                    <span className='instructionBox-data-item-content-data'>{t("Find it better? We'll refund the difference")}</span>
                </div>
            </div>
            <div className='instructionBox-data-item'>
                <span className='instructionBox-data-item-icon'><ShippingTruck/></span>
                <div className='instructionBox-data-item-content'>
                    <span className='instructionBox-data-item-content-head'>{t("Shipping")}</span>
                    <span className='instructionBox-data-item-content-data'>{t("Safe transportation in every step")}</span>
                </div>
            </div>
            <div className='instructionBox-data-item'>
                <span className='instructionBox-data-item-icon'><HelplineIcon/></span>
                <div className='instructionBox-data-item-content'>
                    <span className='instructionBox-data-item-content-head'>{t("24/7 support")}</span>
                    <span className='instructionBox-data-item-content-data'>{t("We'll answer anything about our jewelry")}</span>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
