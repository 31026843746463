import React from 'react'

const CrossIcon = (props) => {
    const { width, height } = props;
    return (
        <svg width={width ? width : "24"} height={height ? height : "24"} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> 
        <path d="M0.999969 17.707L8.99997 9.70697L17 17.707L17.707 17L9.70697 8.99997L17.707 0.999969L17 0.292969L8.99997 8.29297L0.999969 0.292969L0.292969 0.999969L8.29297 8.99997L0.292969 17L0.999969 17.707Z" fill="black"/>
     </svg>
    )
}

export default CrossIcon