import CloseIcon from 'admin/common/icons/CloseIcon'
import PageWithPencile from 'admin/common/icons/PageWithPencile'
import PlusIcon from 'admin/common/icons/PlusIcon'
import RemarkMessageIcon from 'admin/common/icons/RemarkMessageIcon'
import RightArrow from 'admin/common/icons/right-arrow'
import SaveButtonIcon from 'admin/common/icons/SaveButtonIcon'
import TableLoader from 'admin/common/TableLoader'
import { Tablehead } from 'components/common/modules/Tablehead'
import { zonEditCreateHeader } from 'components/common/Tablehead'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Select from "react-select";
import DatePicker from 'react-datepicker';
import EditIcon from 'admin/common/icons/EditIcon'
import Calendar from 'admin/common/icons/sidebar/Calendar'
import ZoneRemarkSidebarPopup from './ZoneRemarkSidebarPopup.js'
import AddInventoryComponent from './AddInventory.js'
import ZoneSidebarPopup from './ZoneSidebarPopup.js'
import ZoneModal from './ZoneModal'
import DeleteIcon from 'admin/common/icons/DeleteIcon'
import Apiauth from 'API/Apiauth'
import { error, loading, Updateloading } from 'components/common/helpers/toastify';
import SearchIcon from 'admin/common/icons/SearchIcon';
export default function ZoneCreateAndEdit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { viewEditIssueId } = useParams();


  // const [limit, setLimit] = useState(100);
  // const [filtername, setFiltername] = useState("");
  // const [datefilter, setDatefilter] = useState([]);
  const [datalodaer, setDatsLodaer] = useState(false);
  // const [StockIdsubmittedValue, setStockIdSubmittedValue] = useState('');

  const [showStockidSidebar, setStockidShowSidebar] = useState(false);
  const [showRemarkSidebar, setRemarkShowSidebar] = useState(false);
  const [showAddInventoryPage, setShowAddInventoryPage] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [popupContent, setPopupContent] = useState({ header: '', subheader: '', withicon: false, type: false });
  const [selectedOption, setSelectedOption] = useState('issue');
  const [RemarksubmittedValue, setRemarkSubmittedValue] = useState('');
  const [viewEditIssueStatus, setViewEditIssueStatus] = useState(viewEditIssueId);

  // console.log(viewEditIssueId, "viewEditIssueId");



  const [zoneCaseScaneData, setZoneCaseScaneData] = useState([]);
  const [zones, setZones] = useState([]);
  const [salesPeople, setSalesPeople] = useState([]);
  const [LocationarrayList, setLocationarrayList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedZone, setSelectedZone] = useState(null);
  const [multiSalesPersonList, setMultiSalesPersonList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [refNumber, setRefNumber] = useState('');
  const [inputValue, setInputValue] = useState('');

  const [isChecked, setIsChecked] = useState(true); // Default checked
  const [poProductId, setPoProductId] = useState([]);
  const [cardValueUpdate, setCardValueUpdate] = useState({ issue: 0, sold: 0, return: 0, balance: 0 });
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAllStatus, setSelectAllStatus] = useState(false);


  const handleDateChange = (date) => {
    setSelectedDate(date); // Update the selected date in state or context
  };

  const getLocatioanAndZone = useCallback(
    async () => {

      try {
        const postdata = {
          hashkey: {}
        };
        let response = await Apiauth(postdata, `zone/get-locations`);
        if (response.code === 200) {
          const locationData = response.data.locations.map((location) => ({
            value: location._id,
            label: location.name,
            icon: <img src={location.image} alt={location.name} style={{ width: 20, height: 20, marginRight: 8 }} />,
          }));
          setLocationarrayList(locationData);

          const zoneData = response?.data?.zone;
          setZones(zoneData);

          const salesData = response?.data?.SalesPerson?.map((person) => ({
            value: person._id,
            label: person.name,
          }));
          setSalesPeople(salesData);

          // Auto-select values if editData is provided

        }

      } catch (err) {
        console.error(err, "API Error");
      } finally {

      }
    },
    [] // limit and currentPage as dependencies
  );

  useEffect(() => {
    getLocatioanAndZone();
  }, [getLocatioanAndZone]);




  const createIssueStocks = useCallback(async () => {
    if (!selectedLocation?.value) { error("Kindly choose a location to store."); return; } else
      if (!selectedZone) { error("Kindly choose a zone."); return; }
    if (multiSalesPersonList?.length === 0) { error("Kindly choose a Sales Person."); return; }
    let GetloadingID = loading();

    try {
      const postdata = {
        "LocationId": selectedLocation?.value,
        "ZoneId": selectedZone?.value,
        "SalePersonIds": multiSalesPersonList?.map((item) => item?.value),
        "IssueDate": selectedDate,
        "ReturnDate": selectedDate,
        "Status": isChecked ? 1 : 0,
        "issue": cardValueUpdate?.issue,
        "return": cardValueUpdate?.return,
        "sold": cardValueUpdate?.sold,
        "items": zoneCaseScaneData,
        type: selectedOption
      };
      if (RemarksubmittedValue) {
        postdata.remark = RemarksubmittedValue;
      }
      if (viewEditIssueId) {
        postdata.id = viewEditIssueId;
      }
      let response = await Apiauth(postdata, `zone/create-issue-stocks`);
      if (response.code === 200) {
        Updateloading(t, GetloadingID, t("Successfully Issue"));
        navigate("/app/inventory/zone");

      }

    } catch (err) {
      console.error(err, "API Error");
    } finally {

    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [RemarksubmittedValue, cardValueUpdate?.issue, cardValueUpdate?.return, cardValueUpdate?.sold, isChecked, multiSalesPersonList, navigate, selectedDate, selectedLocation?.value, selectedZone, t, zoneCaseScaneData] // limit and currentPage as dependencies
  );


  const calculateTotals = (data) => {
    // Initialize totals
    let totals = { issue: 0, sold: 0, return: 0, balance: 0 };

    // Loop through data and calculate totals
    data?.forEach((item) => {
      totals.issue += item.issue || 0;
      totals.sold += item.sold !== "-" ? parseInt(item.sold, 10) || 0 : 0;
      totals.return += item.return !== "-" ? parseInt(item.return, 10) || 0 : 0;
      totals.balance += item.balance || 0;
    });

    // Update state
    setCardValueUpdate(totals);
  };

  const manageReturnComment = (e, index) => {
    // Create a new array with the updated reason for the specific index
    const updatedData = zoneCaseScaneData.map((item, i) =>
      i === index ? { ...item, reason: e.target.value } : item
    );
    // Update state with the modified array
    setZoneCaseScaneData(updatedData);
  };

  const tableDataManagemenet = useCallback((data) => {
    if (data?.length) {
      // Merge current zoneCaseScaneData with the new data
      const combinedData = [...zoneCaseScaneData, ...data];

      // Create a map to track unique stock_ids
      const uniqueData = Array.from(
        new Map(combinedData.map((item) => [item.stock_id, item])).values()
      );

      // Update state and recalculate totals
      setZoneCaseScaneData(uniqueData);
      calculateTotals(uniqueData);
    }
  }, [zoneCaseScaneData]);


  const getStockSearch = useCallback(async (stockIdArrayData, Searchinputvalue) => {
    if (!selectedLocation) { error("Kindly choose a location to store."); return; } else
      // if (!selectedZone) { error("Kindly choose a zone."); return; }
      // let editData = false;
      try {
        const postdata = {
          "stock_id": stockIdArrayData ? stockIdArrayData : [],
          "search": Searchinputvalue ? Searchinputvalue : "",
          "po_product_id": poProductId,
          location_id: selectedLocation?.value,
          "type": selectedOption  // issue,

        };
        if (viewEditIssueId) {
          postdata.vault_id = viewEditIssueId
        }
        let response = await Apiauth(postdata, `zone/get-stocks-search`);
        if (response.code === 200) {
          tableDataManagemenet(response?.data);
          setInputValue("");
          setPoProductId([]);
          // Updateloading(t, GetloadingID, t("Seaching  successfully"));
        }

      } catch (err) {
        console.error(err, "API Error");
      } finally {

      }
  },
    [poProductId, selectedLocation, selectedOption, tableDataManagemenet, viewEditIssueId] // limit and currentPage as dependencies
  );

  useEffect(() => {
    if (poProductId?.length > 0) {
      getStockSearch();  // If edit mode then it;s call auto  
    }
  }, [getStockSearch, poProductId]);




  // Handle location change
  const handleLocationChange = (selectedOption) => {
    setSelectedLocation(selectedOption);
    const filteredZones = zones
      .filter((zone) => zone.location_id === selectedOption.value)
      .map((zone) => ({
        value: zone._id,
        label: zone.zone,
        icon: <img src={zone.zone_logo} alt={zone.zone} style={{ width: 20, height: 20, marginRight: 8 }} />,
      }));
    setZoneList(filteredZones);
    setSelectedZone(null); // Reset zone when location changes
  };

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

  };

  const handleStockIdTextareaSubmit = (stiockidString) => {
    setStockidShowSidebar(false);
    if (stiockidString) {
      const numbersArray = stiockidString.split(/\D+/)
      if (numbersArray?.length) {
        getStockSearch(numbersArray, "");
      } else {
        error("Somthing Went Wrong");
      }
    }
  };
  const handleRemarkTextareaSubmit = (value) => {
    setRemarkSubmittedValue(value); // Update state with value from child
    setRemarkShowSidebar(false);
  };

  const searchByStockidAndSku = (event) => {
    const searchInputValue = event?.target?.value;
    if (event.type === 'change') {
      setInputValue(searchInputValue);
    }

    if (event.type === 'keydown' && event.key === 'Enter') {
      getStockSearch([], searchInputValue);
    }
  };

  const handleSearchClick = () => {
    if (inputValue) {
      getStockSearch([], inputValue);
    }
    // Perform your search or other logic here
  };

  const deleteSelectedIds = useCallback((idsToRemove) => {
    if (idsToRemove?.length) {
      // Filter out items whose id is not in the idsToRemove array
      const updatedData = zoneCaseScaneData.filter(
        (item) => !idsToRemove.includes(item.id)
      );

      // Update state and recalculate totals
      setZoneCaseScaneData(updatedData);
      calculateTotals(updatedData);
    }
  }, [zoneCaseScaneData]);
  const ZoneCaseStatusChange = (e) => {
    if (!e.target.checked) {
      // Show confirmation modal
      setPopupContent({
        header: 'The missing return/sold!',
        subheader:
          'Changing the status to close with the missing return/sold. The closed status cannot be changed later.',
        withicon: true,
        type: false
      });
      setOpenModal(true);
    }
  };

  const ZoneCaseStatusConfirm = (type) => {
    // Close modal, update state, and trigger API call
    setOpenModal(false);
    setIsChecked(false);
    if (type) {
      deleteSelectedIds(selectedRows);
    }
    // Simulate API call
    // You can replace this with your actual API logic
  };


  const handleAddinventory = () => {
    if (!selectedLocation) { error("Kindly choose a location to store."); return false; }
    setShowAddInventoryPage(true);
  };
  const handleBackToParent = () => {
    setShowAddInventoryPage(false); // Show parent and hide child
  };
  const disableLayer = () => {
    setViewEditIssueStatus(false);

  };



  const preSelectionValue = useCallback((data) => {
    if (viewEditIssueId) {
      const selectedLoc = LocationarrayList.find((loc) => loc.value === data.LocationId);
      const filteredZones = zones
        .filter((zone) => zone.location_id === data.LocationId)
        .map((zone) => ({
          value: zone._id,
          label: zone.zone,
          icon: <img src={zone.zone_logo} alt={zone.zone} style={{ width: 20, height: 20, marginRight: 8 }} />,
        }));
      const selectedZn = filteredZones.find((zone) => zone.value === data.ZoneId);
      const selectedSales = salesPeople.filter((sp) => data.SalePersonIds?.includes(sp.value));

      setSelectedLocation(selectedLoc);
      setZoneList(filteredZones);
      setSelectedZone(selectedZn);
      setMultiSalesPersonList(selectedSales);
      setRefNumber(data.RefNo);
      setSelectedDate(data.IssueDate);
      setSelectedOption(data?.type ?? "issue");
      setRemarkSubmittedValue(data?.remark);
      setIsChecked(data?.Status)
    }

  },
    [LocationarrayList, salesPeople, viewEditIssueId, zones]
  );

  const getIssueStockList = useCallback(
    async () => {
      setDatsLodaer(true);
      try {
        const postdata = { id: viewEditIssueId };
        let response = await Apiauth(postdata, `zone/view-issue-stocks`);
        if (response.code === 200) {
          preSelectionValue(response?.data);
          tableDataManagemenet(response?.data?.items);
        }

      } catch (err) {
        console.error(err, "API Error");
      } finally {
        setDatsLodaer(false);

      }
    },
    [preSelectionValue, tableDataManagemenet, viewEditIssueId]
  );

  useEffect(() => {
    if (viewEditIssueId && LocationarrayList.length > 0) {
      getIssueStockList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewEditIssueId, LocationarrayList]);



  const handleChangeCheckbox = (event) => {
    if (event.target.checked) {
      const mainselected = [...selectedRows, event.target.value];

      setSelectedRows(mainselected);
    } else {
      const result = selectedRows.filter((element) => {
        return element !== event.target.value;
      });
      setSelectedRows(result);
    }


  }
  let selectAllStatus_arr = [];

  useEffect(() => {
    var allCheckbox = document.querySelectorAll('.zone_createAndEdit');
    if (selectAllStatus === true) {
      allCheckbox.forEach(function (checkBox_selectAble) {
        checkBox_selectAble.checked = true;
        if (checkBox_selectAble.value !== "on") {
          selectAllStatus_arr.push(checkBox_selectAble.value);
        }
      });
      let arrya = [];
      for (let i = 0; i < allCheckbox.length; i++) {
        if (allCheckbox[i].checked) {
          let product_id = allCheckbox[i].getAttribute('data-id');
          if (product_id !== "on") {
            arrya.push(product_id);
          }
        }
      }
      setSelectedRows(arrya);
    } else {
      allCheckbox.forEach(function (checkBox_selectAble) {
        checkBox_selectAble.checked = false;
      });
      setSelectedRows([]);
    }
    setSelectedRows(selectAllStatus_arr);

    // eslint-disable-next-line
  }, [selectAllStatus]);


  return (
    <>
      {showAddInventoryPage ? (
        <AddInventoryComponent selectedLocation={selectedLocation?.value} setPoProductId={setPoProductId} handleBackToParent={handleBackToParent} />
      ) : (
        <div className="main-body zone_module ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 d-flex justify-content-between">
                <h1 className="main-body-heading">
                  <span>
                    <Link to="/app/inventory/myInventory" className='text-decoration-none'> {t('Inventory')}</Link> <span className='ms-2'><RightArrow /></span>
                  </span>
                  <span>
                    <Link to="/app/inventory/zone" className='text-decoration-none'>{t("Zone")}</Link> <span className='ms-2'><RightArrow /></span>
                  </span>
                  {viewEditIssueId ? t("Edit Issue") : t("Create Issue")}
                </h1>
                <div className='d-flex gap-2 items-center'>
                  {!viewEditIssueStatus ?
                    <>
                      <Link to={'/app/inventory/zone'} className='border px-2 d-flex align-items-center justify-content-center'><CloseIcon /></Link>
                      <Link
                        onClick={createIssueStocks}
                        className={"main-body-top-tab-bar-right-col-a"}
                      >
                        <SaveButtonIcon color="#fff" />
                      </Link>
                    </>
                    :

                    <div onClick={disableLayer} className='cursor-pointer p-2 bg-white'><EditIcon colors="#A4A4A4" /></div>
                  }
                </div>
              </div>
              <div className={viewEditIssueStatus ? "zone_module-inner onlyviewclassAdd" : "zone_module-inner"}>
                <div className='zone_module-inner-firstbar zone_module-inner-header'>
                  <div className='zone_module-inner-header-block issue'>
                    <label>{t("Issue")}</label>
                    <div className='zone_module-inner-header-block-data'>{cardValueUpdate?.issue}</div>
                  </div>
                  <div className='zone_module-inner-header-block sold'>
                    <label>{t("Sold")}</label>
                    <div className='zone_module-inner-header-block-data'>{cardValueUpdate?.sold}</div>
                  </div>
                  <div className='zone_module-inner-header-block return'>
                    <label>{t("Return")}</label>
                    <div className='zone_module-inner-header-block-data'>{cardValueUpdate?.return}</div>
                  </div>
                  <div className='zone_module-inner-header-block balance'>
                    <label>{t("Balance")}</label>
                    <div className='zone_module-inner-header-block-data'>{cardValueUpdate?.balance}</div>
                  </div>
                </div>
                <div className='main-body-top-status-bar'>
                  <div className='single-inputHolder'>
                    <input readOnly className='single-inputHolder-input' type='text'
                      value={refNumber ? refNumber : "DOC-XXXX"} />

                  </div>
                  <div className="zone-datepicker">
                    <span className="zone-datepicker-icon">
                      <Calendar />
                    </span>
                    <DatePicker
                      selectsRange={false}
                      selected={selectedDate}
                      onChange={handleDateChange}
                      placeholderText="Date"
                      classNamePrefix="common-select"
                      dateFormat={state?.zoneData?.issuedate ? "dd-MM-yyyy" : "yyyy-MM-dd"}
                    />

                  </div>
                  <div className={'placeholder-up-input'} >
                    <Select
                      className="select-set select p-0 z-10 w-20"
                      placeholder={`Select Location`}
                      options={LocationarrayList}
                      value={selectedLocation}
                      onChange={handleLocationChange}
                      classNamePrefix="common-select"
                      getOptionLabel={(e) => (
                        <div className="common-select-icon">{e.icon}
                          <span>{e.label}</span></div>
                      )}
                      isSearchable={false}
                    />
                  </div>



                  <div className={'placeholder-up-input'} >
                    <Select
                      className=" select-set select p-0 z-10"
                      placeholder={`Select Zone`}
                      options={zoneList}
                      value={selectedZone}
                      onChange={setSelectedZone}
                      classNamePrefix="common-select"
                      getOptionLabel={(e) => (
                        <div className="common-select-icon">{e.icon}
                          <span>{e.label}</span></div>
                      )}
                      isSearchable={false}
                    />
                  </div>
                  <div className="zonemultiselect">

                    <Select
                      className="w-100"
                      placeholder={`Select Sales Person`}
                      options={salesPeople}
                      value={multiSalesPersonList}
                      onChange={(e) => setMultiSalesPersonList(e)}
                      isMulti={true}
                      classNamePrefix="common-select"
                      getOptionLabel={(e) => (
                        <div className="product-multi-select__multi-value__label-ct">
                          <span>{e.label}</span>
                        </div>
                      )}
                      isSearchable={false}
                    />

                  </div>
                </div>
                <div className="zone_module-inner-secondbar main-body-top-status-bar">

                  {/* <Select
                  className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                  isSearchable={false}
                /> */}
                  <div className='zone_module-inner-middlebar'>
                    <div className='zone_module-inner-middlebar-radiobtns'>
                      <div className='radiobutton'>
                        <label className='zonetype-radiobtn'>
                          <input
                            id='radio-issue'
                            type='radio'
                            name='radio'
                            value='issue'
                            checked={selectedOption === 'issue'}
                            onChange={handleOptionChange}
                          />
                        </label>
                        <label htmlFor='radio-issue'>Issue</label>
                      </div>
                      <div className='radiobutton'>
                        <label className='zonetype-radiobtn'>
                          <input
                            id='radio-return'
                            type='radio'
                            name='radio'
                            value='return'
                            checked={selectedOption === 'return'}
                            onChange={handleOptionChange}
                            disabled={!viewEditIssueId}
                          />
                        </label>
                        <label htmlFor='radio-return'>Return</label>
                      </div>
                    </div>

                    <div className='search'>
                      <input
                        type="text"
                        className=""
                        placeholder="Search/Scan by SKU or stock ID"
                        value={inputValue}
                        onChange={searchByStockidAndSku}
                        onKeyDown={searchByStockidAndSku}
                      />
                      <button onClick={handleSearchClick} className="main-body-top-status-bar-filter-search">
                        <SearchIcon color="#A4A4A4" />
                      </button>
                    </div>
                    <div className='stockidSidebar tooltip-area-up' onClick={() => { setStockidShowSidebar(true) }}>
                      <PageWithPencile />
                      <span className='tooltip-area-up-text whitenblack'>{t("Add Stock ID")}</span>
                    </div>
                    <div className='inventorubutton' onClick={() => { handleAddinventory(true); }}>
                      <PlusIcon type='doublelayered' />
                      <span>{t("Inventory")}</span>
                    </div>
                  </div>
                  <div className='last-section'>
                    {selectedRows?.length ?
                      <div className='cursor-pointer'
                        onClick={() => {
                          setPopupContent({ header: "Are you sure you want to delete this?", subheader: 'Are you sure you want to remove this item ?', subline2: " Yes, I want to delete it.", withicon: false, type: true });
                          setOpenModal(true);
                        }}
                      >
                        <DeleteIcon type="curved" />
                      </div>
                      : null}
                    <div className='last-section-toggle'>
                      <div className="form-check form-switch">
                        <input
                          type="checkbox"
                          id="togglebtn1"
                          className="form-check-input float-none"
                          checked={isChecked} // Default state
                          onChange={ZoneCaseStatusChange}
                        />
                        <label htmlFor="togglebtn1" className="form-check-label"></label>
                      </div>
                      <span className="last-section-toggle-tag">{isChecked ? t("Status Open") : t("Status Closed")}</span>
                    </div>
                    <div className='cursor-pointer tooltip-area-up' onClick={() => { setRemarkShowSidebar(true); }}>
                      <RemarkMessageIcon />
                      <span className='tooltip-area-up-text whitenblack'>{t("Remark")}</span>
                    </div>
                  </div>

                </div>
                <div className={`zone_module-inner-table withReason}`}>
                  <div
                    className="main-body-main-table-wrap position-relative"
                    style={{ maxWidth: '100%', overflowX: 'auto' }}
                  >
                    <table
                      id="zoneEditcreate_table"
                      className="stripe row-border order-column common-table table-striped main-table w-100"
                    >
                      <Tablehead tablehead={zonEditCreateHeader} tblName={'zoneEditcreate_table'} setShowoption={setSelectAllStatus} />
                      <tbody>
                        <>

                          {zoneCaseScaneData?.length ? zoneCaseScaneData.map((result, key) => {
                            return (
                              <tr key={key} className="">
                                <td>
                                  <div className={'com-check'}>
                                    <input key={key}
                                      className='inventorytablecheck inventorytableHead zone_createAndEdit'
                                      id={result?.id}
                                      data-id={result?.id}
                                      value={result?.id}
                                      type="checkbox"
                                      onChange={(e) => handleChangeCheckbox(e)}
                                    />
                                    <label htmlFor={result?.id} className="com-check-label" />
                                  </div>
                                </td>
                                <td>{key + 1}</td>
                                <td className="table-pro-img" >
                                  <img src={result?.main_image} alt={key + 1} />
                                </td>
                                <td className="">
                                  {result?.SKU ? result?.SKU : "-"}
                                </td>
                                <td>
                                  <div className="tooltip-area-up">{result?.item_name ? result?.item_name : "-"}</div>
                                </td>
                                <td>
                                  <div className="tooltip-area-up">{result?.stock_id ? result?.stock_id : "-"}</div>
                                </td>
                                <td className="">{result?.issue ? result?.issue : "-"}</td>
                                <td className="">{result?.sold ? result?.sold : "-"}</td>
                                <td className="">{result?.return ? result?.return : "-"}</td>
                                <td className="">{result?.balance ? result?.balance : "-"}</td>
                                <td className="text-danger">
                                  {result.reason && selectedOption === 'return' ?
                                    <input
                                      onChange={(e) => manageReturnComment(e, key)}
                                      className="bg-transperant border-none h-20 w-full border-1"
                                      value={result.reason || ""}
                                    />
                                    : ""}

                                </td>

                              </tr>
                            );
                          }) : ''}
                        </>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="6">{t("Total")}</td>
                          <td>{cardValueUpdate?.issue}</td>
                          <td>{cardValueUpdate?.sold}</td>
                          <td>{cardValueUpdate?.return}</td>
                          <td>{cardValueUpdate?.balance}</td>
                        </tr>
                      </tfoot>
                    </table>
                    {datalodaer && <TableLoader />}
                  </div>
                  {/* <div className='zone_module-inner-bottom'>
                  <div>
                    {t("Showing")} 1 {t("to")} 10 {t("of")} 10 {t("Entries")}
                  </div>
                  {zoneEditData.length > 0 ? (
                    <Pagination
                      total={totalPages}
                      current={currentPage}
                      pagination={(crPage) => handlePaginations(crPage)}
                    />
                  ) : (
                    ""
                  )}
                </div> */}
                </div>
              </div>
            </div>
          </div>

        </div>
      )}
      {showStockidSidebar ? <ZoneSidebarPopup setStockidShowSidebar={setStockidShowSidebar} onSubmit={handleStockIdTextareaSubmit} /> : null}
      {showRemarkSidebar ? <ZoneRemarkSidebarPopup RemarksubmittedValue={RemarksubmittedValue} setRemarkShowSidebar={setRemarkShowSidebar} onSubmit={handleRemarkTextareaSubmit} /> : null}



      {openModal && (
        <ZoneModal
          setOpenModal={setOpenModal}
          popupContent={popupContent}
          onConfirm={ZoneCaseStatusConfirm} // Pass confirmation handler
        />
      )}
    </>
  )
}
