import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SetdataTable, UnSetdataTable } from '../api/setdatatable';
import { _Api, graphqlPost } from '../api/_call';
import { Tablehead } from "../common/modules/Tablehead";
import TableColumnModal from "../common/modules/tableColumn";
import Select, { components } from "react-select";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { transcationhistorytableHead } from '../common/Tablehead';
import DateTime from "../common/DateTime";
import SocialModal from "../common/modules/SocialModal/SocialModal";
import { LocationList } from "../common/graphql/commonlayouts";
import { useTranslation } from 'react-i18next';

const TransactionHistory = (props) => {
  const { t } = useTranslation()
  const { state } = useLocation();
  const [, setfilterby] = useState("");
  const [image, setImage] = useState([])
  const [, setName] = useState()
  const [productId, setProductID] = useState()
  const [transaction_history_slider, setTransaction_history_slider] = useState();
  const [historycontent, setHistorycontent] = useState([]);
  const [type, setType] = useState({ value: state ? state?.status === 'All Transcation' ? '' : state?.status : '', label: state ? state?.status : '' });
  const [commanSelected_loc, setcommanSelected_loc] = useState();
  const [, setIsDelete] = useState(false);
  const [limit, setLimit] = useState(100);
  const [filtername, setFiltername] = useState('');
  const [transcationdata, setTranscationdata] = useState();
  const [SKUData, setSKUData] = useState();
  const [newcolumndata, setnewcolumndata] = useState(transcationhistorytableHead);
  const { ValueContainer, Placeholder } = components;
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  const Typeoption = [
    {
      value: "",
      label: "All Transcation",
    },
    {
      value: "reserve",
      label: "reserve",

    },
    {
      value: "sold",
      label: "sold",

    }
  ];
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_inventory_transcationhistory',
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    setTransaction_history_slider({
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    });
  }, []);
  useEffect(() => {
    callcolumns();
  }, []);
  const deleticon = (event) => {
    if (event.target.checked) {
      const values = [...historycontent, event.target.value];
      setHistorycontent(values);
    } else {
      var index = historycontent.indexOf(event.target.value);
      if (index > -1) {
        historycontent.splice(index, 1);
      }
    }
    setIsDelete(current => !current);
  };
  const filterbyname = (search) => {
    gettransactionlist(limit, search);
  }
  const gettransactionlist = async (firstlimit, search) => {
    UnSetdataTable("tbl_inventory_transcationhistory");
    try {
      const postdata = {
        "location_id": commanSelected_loc ? commanSelected_loc : state.location_id,
        "type": type?.value,
        "limit": firstlimit ? firstlimit : limit,
        "search": search ? search : "",
        "SKU": state ? state?.SKU : '',
        "stock_id": state?.Stock_id ? state?.Stock_id : '',
      };
      let res = await _Api(postdata, `api/v1/inventory/my/itemSearchTransection`);
      if (res.code === 200) {
        setTranscationdata(res.data);
        setProductID(res?.SKUData?.product_id)
        setSKUData(res.SKUData);
        SetdataTable('tbl_inventory_transcationhistory', '3', newcolumndata, 0);

      } else {
        SetdataTable('tbl_inventory_transcationhistory', '3', newcolumndata, 0);
      }
    } catch (err) {
      console.log(err, "err");
    }
  }

  const getImage = async () => {
    if (productId) {
      try {
        const postdata =
          `{
                 ProductImages(product_id:"` +
          productId +
          `") {
                  image
                  name
              }
           
      }`
        let responce = await graphqlPost(postdata, 'App/graphql')
        setImage(responce?.data?.ProductImages?.image)
        setName(responce?.data?.ProductImages?.name)
      } catch (err) {
        console.log(err, 'err')
      }
    }
  }
  //product_id
  useEffect(() => {
    gettransactionlist();
    // eslint-disable-next-line
  }, [commanSelected_loc, type]);

  useEffect(() => {
    if (productId) {
      getImage()
    }
    // eslint-disable-next-line
  }, [productId]);

  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
      sliderRef.current.innerSlider.unslick();
    }
  }, [image]);

  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]

  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <div className="item-search-wrap">
                <div className="item-search-wrap-left">
                  <div className="top-heading-area">
                    <div className="top-heading-area-left">
                      <div className='page-back-heading'>
                        <Link to="/app/inventory/itemSearch" className="page-back-btn">
                          <img
                            src={base_assets + "images/icons/arrow-left.png"}
                            alt=""
                          />
                          <h1 className="head-name">{t("Transaction History")}</h1>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="main-body-top-status-bar">
                    <Select
                      className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num z-10"
                      options={selectOptions}
                      value={{ label: limit, value: limit }}
                      onChange={(e) => { setLimit(e.value); gettransactionlist(e.value) }}
                      isSearchable={false}
                    />
                    <div className="main-body-top-status-bar-filter">
                      <input
                        type="text"
                        value={filtername}
                        className="main-body-top-status-bar-filter-input"
                        placeholder={t("Customer name/StockID/Location/sales ...")}
                        onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                        onChange={(e) => setFiltername(e.target.value)}
                      />
                      {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                      <button onClick={() => filterbyname(filtername)} className="main-body-top-status-bar-filter-search">
                        <i className="fa fa-search" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="main-body-top-tab-bar-right-col">
                      <div className="placeholder-up-input">
                        <Select
                          className="select-set select p-0 z-10"
                          placeholder={t("Type")}
                          options={Typeoption}
                          components={{
                            ValueContainer: CustomValueContainer,
                          }}
                          value={type}
                          onChange={(e) => setType(e)}
                          classNamePrefix="common-select"
                          getOptionLabel={(opt) => opt.label}
                          isSearchable={false}
                        />
                      </div>
                      <LocationList
                        setcommanSelected_loc={setcommanSelected_loc} locationid={state ? state.location_id : ''} />
                      <button className="print-export-dropdown-selected-icon d-none">
                        <img
                          src={base_assets + "images/icons/printer-icon.png"}
                          alt=""
                        />
                      </button>
                      <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {" "}
                          <img
                            src={base_assets + "images/icons/ellipsis-circular.png"}
                            alt=""
                          />{" "}
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li className="dropdown-item print-dropdown-item">
                            <div className="dropdown-item-img">
                              <img
                                src={base_assets + "images/icons/printer-icon.png"}
                                alt=""
                              />
                            </div>
                            <span>{t("print")}</span>
                          </li>
                          <li className="dropdown-item">
                            <div className="dropdown-item-img">
                              <img
                                src={base_assets + "images/icons/export-x-icon.png"}
                                alt=""
                              />
                            </div>
                            <span>{t("export")}</span>
                          </li>
                          <li className="dropdown-item">
                            <div className="dropdown-item-img">
                              <img
                                src={base_assets + "images/icons/share-icon.png"}
                                alt=""
                              />
                            </div>
                            <span data-bs-toggle="modal" data-bs-target="#socialModal">{t("share")}</span>
                          </li>
                          <li
                            className="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#columnModal"
                          >
                            <div className="dropdown-item-img">
                              <img
                                src={base_assets + "images/icons/column-icon.png"}
                                alt=""
                              />
                            </div>
                            <span>{t("column")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="item-search-wrap-left-box">
                    <div className="item-search-wrap-left-box-head">
                      <div className="item-search-wrap-left-box-head-left">
                        <h5 className="item-search-wrap-left-box-head-left-heading">
                          {t("SKU")} : <span>{SKUData?.SKU}</span>
                        </h5>
                        <p className="item-search-wrap-left-box-head-left-pro-name">
                          #{SKUData?.name}
                        </p>
                      </div>
                      <div className="item-search-wrap-left-box-head-right">
                        <p className="item-search-wrap-left-box-head-right-available">
                          {t("Available")} : <span>{SKUData?.avalable}</span>
                        </p>
                      </div>
                    </div>
                    <div className="main-body-main-table-wrap">
                      <table
                        id="tbl_inventory_transcationhistory"
                        className="common-table w-100"
                      >
                        <Tablehead
                          tablehead={transcationhistorytableHead}
                          tblName={"tbl_inventory_transcationhistory"}
                          setfilterby={setfilterby}

                        />
                        <tbody>
                          <>
                            {transcationdata ? transcationdata.map((result, key) => {
                              return (
                                <tr key={key}>
                                  <td>
                                    <div className="com-check">
                                      <input
                                        id={key}
                                        type="checkbox"
                                        onClick={deleticon}
                                      />
                                      <label htmlFor={key} className="com-check-label" />
                                    </div>
                                  </td>
                                  <td className="pe-0">{key + 1}</td>
                                  <td className="">{result?.Date ? result?.Date : ''}</td>
                                  <td className="">{result?.stock_id ? result?.stock_id : ''}</td>
                                  <td className="">{result?.customer_name ? result?.customer_name : '-'}</td>
                                  <td className="">{result?.location_name ? result?.location_name : ''}</td>
                                  <td className="">{result?.salesPerson_name ? result?.salesPerson_name : ''}</td>
                                  <td className="">{result?.final_amount ? result?.final_amount : ''}</td>
                                  <td className="text-end">{result?.QTY ? result?.QTY : '0'}</td>
                                </tr>
                              );
                            }) : ''}
                          </>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td className="col-highlight" />
                            <td className="col-highlight" />
                            <td className="col-highlight" />
                            <td className="col-highlight" />
                            <td className="col-highlight" />
                            <td className="col-highlight" />
                            <td className="col-highlight" />
                            <td className="col-highlight" />
                            <td className="col-highlight text-end">{transcationdata?.reduce(
                              (interation, val) => (interation = interation + parseInt(val.QTY)),
                              0
                            )}</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="item-search-wrap-right">
                  {image?.length ?
                    <Slider className='product-slider' {...transaction_history_slider}>
                      {image.map((result, key) => (
                        <div key={key}>
                          <img src={result?.original} alt="" />
                        </div>
                      ))}
                    </Slider>
                    : ""}

                  <div className="item-search-wrap-right-item-specification-box">
                    <h5 className="item-search-wrap-right-item-specification-box-name">{SKUData?.Matatag}</h5>
                    <div className="item-search-wrap-right-item-specification-box-metal">
                      <div className="item-search-wrap-right-item-specification-box-metal-div">
                        <span className="item-search-wrap-right-item-specification-box-metal-div-span-1">{t("metal")}:</span>
                        <span className="item-search-wrap-right-item-specification-box-metal-div-span-2">{SKUData?.metal_name}</span>
                      </div>
                      <div className="item-search-wrap-right-item-specification-box-metal-div">
                        <span className="item-search-wrap-right-item-specification-box-metal-div-span-1">{t("Stone")} :</span>
                        <span className="item-search-wrap-right-item-specification-box-metal-div-span-2">{SKUData?.stone_name}</span>
                      </div>
                      <div className="item-search-wrap-right-item-specification-box-metal-div">
                        <span className="item-search-wrap-right-item-specification-box-metal-div-span-1">{t("Size")} :</span>
                        <span className="item-search-wrap-right-item-specification-box-metal-div-span-2">{SKUData?.size_name}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SocialModal />
      </div>
      <TableColumnModal
        tablehead={transcationhistorytableHead}
        tblName={"tbl_inventory_transcationhistory"}
        calldatatable={gettransactionlist}
        setnewcolumndata={setnewcolumndata}
      ></TableColumnModal>
    </React.Fragment>

  );


}

export default TransactionHistory;