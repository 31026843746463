import React from 'react'

export default function HelplineIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.25 15.275C26.25 8.4125 20.925 3.75 15 3.75C9.1375 3.75 3.75 8.3125 3.75 15.35C3 15.775 2.5 16.575 2.5 17.5V20C2.5 21.375 3.625 22.5 5 22.5H6.25V14.875C6.25 10.0375 10.1625 6.125 15 6.125C19.8375 6.125 23.75 10.0375 23.75 14.875V23.75H13.75V26.25H23.75C25.125 26.25 26.25 25.125 26.25 23.75V22.225C26.9875 21.8375 27.5 21.075 27.5 20.175V17.3C27.5 16.425 26.9875 15.6625 26.25 15.275Z" fill="#4F4A3E"/>
<path d="M11.25 17.5C11.9404 17.5 12.5 16.9404 12.5 16.25C12.5 15.5596 11.9404 15 11.25 15C10.5596 15 10 15.5596 10 16.25C10 16.9404 10.5596 17.5 11.25 17.5Z" fill="#4F4A3E"/>
<path d="M18.75 17.5C19.4404 17.5 20 16.9404 20 16.25C20 15.5596 19.4404 15 18.75 15C18.0596 15 17.5 15.5596 17.5 16.25C17.5 16.9404 18.0596 17.5 18.75 17.5Z" fill="#4F4A3E"/>
<path d="M22.5001 13.7875C22.202 12.0302 21.2918 10.435 19.9306 9.28423C18.5694 8.1335 16.845 7.50146 15.0626 7.5C11.2751 7.5 7.20008 10.6375 7.52508 15.5625C9.0665 14.9317 10.4279 13.9294 11.4879 12.6447C12.5479 11.3601 13.2735 9.83315 13.6001 8.2C15.2376 11.4875 18.6001 13.75 22.5001 13.7875Z" fill="#4F4A3E"/>
</svg>
  )
}
