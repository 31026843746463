import CloseIcon from 'admin/common/icons/CloseIcon'
import RightArrow from 'admin/common/icons/right-arrow'
import SortIcon from 'Ecommerce/template_one/default/assets/icons/SortIcon'
import Hamburger from 'Ecommerce/template_three/assets/icons/Hamburger'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export default function BottomSliderBar(props) {

    const { headerdata, setToggleMobileFilter, toggleMobileFilter, filters, datatype, setDatatype } = props
    const {t} = useTranslation();
    const navigate = useNavigate();

    console.log(headerdata, "check header data")

    const [header, setHeader] = useState();
    const [showSubCategories, setShowSubCategories] = useState();

    const handleApply = () => {
        console.log("Handle Apply")
    }


  return (
    <div className='mobilefilter' onClick={()=>setToggleMobileFilter(false)}>
    <div className='mobilefilter-inner' onClick={(e)=>e.stopPropagation()}>
        <div className='mobilefilter-inner-wrapper'>
            { header?.length ? 
             <div className='mobilefilter-inner-wrapper-header'>
             <span className='lefticon' onClick={()=>setHeader("")}><RightArrow/></span>
             <span className='tag'>{header?.[0]?.name}</span>
             <span className='closeicon' onClick={()=>setToggleMobileFilter(false)}><CloseIcon/></span>
           </div>
            :
            <div className='mobilefilter-inner-wrapper-header_default'>
              <span className='text'>
                <Hamburger/><span >{t("Menu")}</span>
              </span>
              <span onClick={()=>setToggleMobileFilter(false)}><CloseIcon/></span>
            </div>
            }
        </div>
        <div className='mobilefilter-inner-wrapper-content'>
        {!header?.length &&
        <div className='mobilefilter-inner-wrapper-content-list'>
                          <div className='mobilefilter-inner-wrapper-content-list-item'>
                            <div className='title'>{t("All")}</div>
                          </div>
                          {headerdata?.length ? 

                            headerdata.map((result,i)=>{
                                return(
                                    <div className='mobilefilter-inner-wrapper-content-list-item' onClick={()=> { setHeader([result]); console.log(header, "header console")}} key={i}>
                                        <div className='title'>{t(`${result?.name}`)}</div>
                                        <span><RightArrow/></span>
                                    </div>
                                )
                            })
                        
                           : null }
                          
                          <div className='mobilefilter-inner-wrapper-content-list-item'>
                            <div className='title' onClick={()=>navigate("/appointment")}>{t("Appointment")}</div>
                          </div>
         </div>
            }
            <div className='mobilefilter-inner-wrapper-content-filterdata mw-100'>
            {  header?.[0]?.subcategories?.length ? 
                        header?.[0]?.subcategories?.map((result, key) => (
                            <div className="mobilefilter-inner-wrapper-content-filterdata-holder gap-4" key={key}>
                                    {  result?.subcategories?.length ?
                                    <div className='d-grid'>
                                        <div className='mobilefilter-inner-wrapper-content-list-item d-flex w-100 justify-content-between cursor-pointer' onClick={()=> { setShowSubCategories === key ? setShowSubCategories(null) : setShowSubCategories(key)}}>
                                            <div className='title'>{t(`${result?.name}`)}</div>
                                            {showSubCategories === key ? <span className='ps-2'><RightArrow/></span> : <span className='ps-2'><RightArrow/></span>}
                                        </div>
                                        { showSubCategories === key ? 
                                             result?.subcategories?.map((item, index)=>{
                                                console.log(showSubCategories, key, "chekc system");
                                               return <span key={index} onClick={()=>navigate(`/${header?.[0]?.name}/${item?.slug}`)} className='mobilefilter-inner-wrapper-content-filterdata-holder-label ps-4'> {t(`${item.name}`)}</span>
                                             })
                                        : null }
                                    </div>
                                    :
                                    <span onClick={()=>navigate(`/${header?.[0]?.name}/${result?.slug}`)} className='mobilefilter-inner-wrapper-content-filterdata-holder-label'> {t(`${result.name}`)}</span>
                                    }
                            </div>
                        )) : null}
              </div>
            <button className='btnbold' onClick={()=>handleApply()}>{t("Apply")}</button>
        </div>
    </div>
</div>
  )
}
