import React, { useState, useEffect } from 'react'
import RightArrow from '../../common/icons/right-arrow'
import { useTranslation } from 'react-i18next'
import { _Api } from '../../api/_call'
import './barcode.scss'
import TableLoader from '../../common/TableLoader'
import { loading, Updateloading } from '../../common/helpers/toastify'
import Select from 'react-select'
import { Pagination } from '../../common/Pagination'
import SearchIcon from '../../common/icons/SearchIcon'
import CloseIcon from '../../common/icons/CloseIcon'

const BarcodeLogs = () => {
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    const { t } = useTranslation();
    const [state, setstate] = useState({
        totalPages: 20,
        currentPage: 1,
      });
    const [showloader, setShowloader] = useState(false);
    const { totalPages, currentPage } = state;
    const [showSearch, setShowSearch] = useState(false);
    const [filtername, setFiltername] = useState('');
    const [limit, setLimit] = useState(100);

    const handlePaginations = (current) => {
      setstate({ ...state, currentPage: current });
      getlogdata(current);
  
    };

    const getlimit = (limit) => {
        setLimit(limit);
        getlogdata(1, limit);
        setstate({ ...state, currentPage: 1 });
      };

      const filterbyname = () => {
        getlogdata(currentPage);
        setstate({ ...state, currentPage: 1 });
      };

    const getlogdata = async (current, firstlimit) => {
        setShowloader(true);
        try {
          var skipNumber = current ? (current - 1) * limit : 0;
          let postdata = {
            "search": filtername,
            "limit": firstlimit ? firstlimit : limit,
            "skip": skipNumber,
          }
          let res = await _Api(postdata, `api/v1/App/applicationkey/get-list`)
          if (res?.code === 200) {
            // setlog(res?.data)
            setShowloader(false)
            setstate({
              ...state,
              totalPages: Math.ceil(res?.total / postdata.limit),
              currentPage: current ? current : 1
            });
          }
        }
        catch (err) {
          console.log(err, "err")
        }
      }

    const selectOptions = [
        { label: 100, value: 100 },
        { label: 200, value: 200 },
        { label: 300, value: 300 }
      ]

      const logdata = [
        {user: "User", date: "22/07/2024", time: "1:11:50 PM", SKU: "SB025145", stockid: "1890089", template : "TEMP01 test add profile"},
        {user: "User", date: "22/07/2024", time: "1:11:50 PM", SKU: "SB025145", stockid: "1890089", template : "TEMP01 test add profile"},
        {user: "User", date: "22/07/2024", time: "1:11:50 PM", SKU: "SB025145", stockid: "1890089", template : "TEMP01 test add profile"},
        {user: "User", date: "22/07/2024", time: "1:11:50 PM", SKU: "SB025145", stockid: "1890089", template : "TEMP01 test add profile"},
        {user: "User", date: "22/07/2024", time: "1:11:50 PM", SKU: "SB025145", stockid: "1890089", template : "TEMP01 test add profile"},
        {user: "User", date: "22/07/2024", time: "1:11:50 PM", SKU: "SB025145", stockid: "1890089", template : "TEMP01 test add profile"},
        {user: "User", date: "22/07/2024", time: "1:11:50 PM", SKU: "SB025145", stockid: "1890089", template : "TEMP01 test add profile"}
      ]
    return (
        <>
            <div className="main-content-wrapper-body">
                <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
                    <div className="main-content-wrapper-body-top-bar-left">
                        <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
                            {t('Setup')} <RightArrow />{t('Barcode')} <RightArrow />
                            <span className="">{t('Logs')}</span>
                        </h1>
                    </div>
                </div>
                <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center justify-content-between">
                    <Select
                        className="limit-select"
                        options={selectOptions}
                        value={{ label: limit, value: limit }}
                        onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                    />
                    <div className={`com-search-bar2 position-relative ${showSearch ? "active" : ""}`}>
                        <span className="com-search-bar2-searchbar" onClick={()=>{setShowSearch(!showSearch); filterbyname()}}><SearchIcon/></span>
                        
                        <input
                        placeholder="Search Device"
                        className="h-100 border-0 bg-transparent"
                          onKeyPress={(e) => e.key === 'Enter' && filterbyname()}
                          onChange={(e) => setFiltername(e.target.value)}
                        />
                        <span className='com-search-bar2-closeicon' onClick={()=>{setShowSearch(false);setFiltername("")}}><CloseIcon/></span>
                    </div>
                </div>
                <div className="row mt-4">
          <div className="col-md-12">
            <div
              className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
            >
              <table
                id="my_customer"
                className="com-custom-table-tag table align-middle">
                <thead>
                  <tr className="bg-white text-nowrap align-middle">
                    <th className="bg-white">#</th>
                    <th className="bg-white">{t("User")}	</th>
                    <th className="bg-white">{t("Date & Time")}	</th>
                    <th className="bg-white">{t(" SKU")}	</th>
                    <th className="bg-white">{t("Stock ID")}</th>
                    <th className="bg-white">{t("Template")}	</th>
                  </tr>
                </thead>
                <tbody>
                  {logdata.length > 0 ? logdata.map((result, key) => {
                    return (
                      <tr className="hoverrow text-nowrap" key={key}>
                        <td><span>{key + 1}</span></td>
                        <td><span>{result?.user ? result?.user + key+1 : "-"}</span></td>
                        <td><span>{result?.date ? result?.date : "-"} {result?.time ? result?.time : "-"}</span></td>
                        <td><span>{result?.SKU}</span></td>
                        <td><span>{result?.stockid ? result?.stockid : "-"}</span></td>
                        <td><span>{result?.template ? result?.template : "-"}</span></td>
                      </tr>
                    )
                  }) :
                    <tr>
                      <td colSpan="50" className="text-center">
                        {' '}
                        {t("No Data Available In Table")}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
              {showloader && <TableLoader />}
            </div>
            {logdata.length > 0 && (
              <Pagination
                total={totalPages}
                current={currentPage}
                pagination={(crPage) => handlePaginations(crPage)}
              />
            )}
          </div>
                </div>
            </div>

        </>
    )
}

export default BarcodeLogs
