import React, { useState, useEffect } from "react";
import "../master.scss"
import { _Api } from "../../api/_call";
import Select from "react-select";
import { error, loading, Updateloading } from "../../common/helpers/toastify";
import { Getstonegroupgraph } from "../../common/Commongraphql"
import { useTranslation } from 'react-i18next';

const EditMultiSelect = (props) => {
    const { setEditItem, dataid, setShowedit, Create, setDataid, setCreate } = props
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    const [name, setName] = useState("")
    const { t } = useTranslation();
    const [code, setCode] = useState("")
    const [active, setActive] = useState(true)
    const [stonegroupharray, setStonegroupharray] = useState([])
    const [stonegrp, setStonegrp] = useState()
    const editstoneview = async () => {
        if (Create) {
            setName("")
            setCode("")
            setStonegrp({
                value: "",
                label: ""
            })
            setActive(true)
        } else if (dataid) {
            try {
                let postdata = {
                    "id": dataid,
                    "type": "Stone"
                }
                let res = await _Api(postdata, "api/v1/App/master/stoneMasterview")
                if (res?.code === 200) {
                    setName(res?.data?.name)
                    setCode(res?.data?.code)
                    setStonegrp({
                        value: res?.data?.StoneGroup?._id,
                        label: res?.data?.StoneGroup?.name
                    })
                    if (res?.data?.Status === "1") {
                        setActive(true)
                    } else {
                        setActive(false)
                    }
                }
            }
            catch (err) {
                console.log(err, "err")
            }
        }

    }
    useEffect(() => {
        if (dataid || Create) {
            editstoneview()
        }
        // eslint-disable-next-line
    }, [dataid, Create])

    const createstone = async () => {
        if (!name) {
            error(t("Name is required."))
            return
        }
        if (!code) {
            error(t("Code is required."))
            return
        } else {
            if (typeof code === 'string') {
                let checkspace = code.includes(' ');
                if (checkspace) {
                    error(t('Please remove space in code'));
                    return;
                }
            }
        }
        setShowedit(false)
        let GetloadingID = loading();
        try {
            let postdata = {
                "type": "Stone",
                "data": {
                    "name": name,
                    "code": code,
                    "stonegroup": stonegrp?.value,
                    "Status": active ? "1" : "0"
                }
            }
            let res = await _Api(postdata, `api/v1/App/master/stoneMasterCreate`)
            if (res?.code === 200) {
                Updateloading(t, GetloadingID, (res?.message));
                setEditItem(false)
                setShowedit(true)
                setDataid("")
                setCreate(false)
            } else {
                Updateloading(t, GetloadingID, (res.errors), 'error');
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const editstone = async () => {
        if (!name) {
            error(t("Name is required."))
            return
        }
        if (!code) {
            error(t("Code is required."))
            return
        } else {
            if (typeof code === 'string') {
                let checkspace = code.includes(' ');
                if (checkspace) {
                    error(t('Please remove space in code'));
                    return;
                }
            }
        }
        setShowedit(false)
        let GetloadingID = loading();
        try {
            let postdata = {
                "id": dataid,
                "type": "Stone",
                "data": {
                    "name": name,
                    "code": code,
                    "stonegroup": stonegrp?.value,
                    "Status": active ? "1" : "0"
                }
            }
            let res = await _Api(postdata, `api/v1/App/master/stoneMasterEdit`)
            if (res?.code === 200) {
                Updateloading(t, GetloadingID, (res?.message));
                setEditItem(false)
                setShowedit(true)
                setDataid("")
                setCreate(false)
            } else {
                Updateloading(t, GetloadingID, (res.errors), 'error');
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }

    return (
        <>
            <div className="edit-item-area bg-white">
                <div className="edit-item-area-header d-flex align-items-center justify-space-between mb-4">
                    <div className="me-auto">
                        <label className="d-block">{t("Name")}: {name ? name : ""}</label>
                        <label className="d-block">{t("Code")}: {code ? code : ""}</label>
                    </div>
                    <button className="square-btn square-btn-white btn-close focus-none" onClick={() => {
                        setEditItem(false);
                        setDataid("");
                        setCreate(false)
                    }}></button>
                    <button className="square-btn " onClick={() => dataid ? editstone() : createstone()}><img src={base_assets + 'images/icons/white-save.png'} alt="" /></button>
                </div>
                <div className="edit-item-area-body">
                    <div className="row mx-0">
                        <div className="col-md-8 ps-0">
                            <div className="v3-input-group">
                                <label className="v3-input-group-label">{t("Name")} <sup>*</sup></label>
                                <input className="v3-input-group-input w-100"
                                    value={name || ''}
                                    onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className="v3-input-group">
                                <label className="v3-input-group-label">{t("Code")} <sup>*</sup></label>
                                <input className="v3-input-group-input w-100"
                                    value={code || ''}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </div>
                            <div className="v3-input-group mb-0">
                                <label className="v3-input-group-label">{t("Stone Group")}</label>
                                <Select
                                    className="select-set select  p-0"
                                    placeholder=""
                                    value={stonegrp}
                                    onChange={(e) => { setStonegrp(e) }}
                                    options={stonegroupharray}
                                    classNamePrefix="common-select"
                                    isSearchable={false}
                                />
                            </div>
                        </div>
                        <div className="col-md-3 offset-md-1 pe-0 text-end">
                            <div className="form-check form-switch ps-0">
                                <label className="form-check-label" htmlFor="active">{t("Active")}</label>
                                <input className="form-check-input float-none ms-2" type="checkbox" id="active"
                                    checked={active ? true : false}
                                    onChange={(e) => setActive(e.target.checked)} />
                            </div>
                        </div>
                    </div>
                </div>
                <Getstonegroupgraph setStonegroupharray={setStonegroupharray} />
            </div>
        </>
    )
}

export default EditMultiSelect;