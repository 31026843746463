import React from 'react'

export default function AppointmentCheckIcon({width, color, height}) {
  return (
    <svg width={width ? width : "32"} height={height ? height : "32"} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.5664 27.666H2.6664C2.22444 27.666 1.80059 27.4904 1.48808 27.1779C1.17557 26.8654 1 26.4416 1 25.9996V4.33322C0.999895 4.11431 1.04292 3.89754 1.12662 3.69527C1.21032 3.493 1.33304 3.3092 1.48779 3.15438C1.64254 2.99955 1.82628 2.87673 2.02851 2.79294C2.23074 2.70914 2.4475 2.66602 2.6664 2.66602H29.3336C29.5525 2.66602 29.7693 2.70914 29.9715 2.79294C30.1737 2.87673 30.3575 2.99955 30.5122 3.15438C30.667 3.3092 30.7897 3.493 30.8734 3.69527C30.9571 3.89754 31.0001 4.11431 31 4.33322V25.9996C31 26.4416 30.8244 26.8654 30.5119 27.1779C30.1994 27.4904 29.7756 27.666 29.3336 27.666H23.4336" stroke="#4F4A3E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1 11H31M16 11C13.3478 11 10.8043 12.0536 8.92893 13.9289C7.05357 15.8043 6 18.3478 6 21C6 23.6522 7.05357 26.1957 8.92893 28.0711C10.8043 29.9464 13.3478 31 16 31C18.6522 31 21.1957 29.9464 23.0711 28.0711C24.9464 26.1957 26 23.6522 26 21C26 18.3478 24.9464 15.8043 23.0711 13.9289C21.1957 12.0536 18.6522 11 16 11Z" stroke="#4F4A3E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.3335 1V6M22.6663 1V6M12.6663 22.6664L14.3335 24.3336L19.3335 19.3336" stroke="#3B3B3B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
