import React, { useState } from 'react'
import './ZoneModal.scss'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import Apiauth from '../../API/Apiauth.js';
export default function ZoneFilter({ setToggleFilter, setFilterItemData, filterItemData, setSearchWithFilter }) {
  const { t } = useTranslation();
  const [filterData, setFilterData] = useState({})



  const getFilterOptions = async () => {
    try {
      const postdata = {

      };
      let apiRes = await Apiauth(postdata, `Inventory/my/filter`);

      if (apiRes.code === 200) {

        setFilterData(apiRes.data)

      }
    } catch (err) {
      console.error(err, "API Error");
    } finally {
    }
  }


  useEffect(() => {
    getFilterOptions();
  }, []);
  const handleApply = () => {
    setSearchWithFilter((prev) => !prev)
  }

  const handleSelect = (category, itemId) => {
    setFilterItemData((prevState) => {
      const updatedCategory = prevState[category].includes(itemId)
        ? prevState[category].filter((id) => id !== itemId)
        : [...prevState[category], itemId];

      return {
        ...prevState,
        [category]: updatedCategory,
      };
    });
  };

  const handleSelectAll = (category, items) => {
    setFilterItemData((prevState) => {
      const isAllSelected = items.every(item => prevState[category].includes(item._id));
      const updatedCategory = isAllSelected
        ? []
        : items.map(item => item._id);

      return {
        ...prevState,
        [category]: updatedCategory,
      };
    });
  };


  const handleClearFilter = () => {
    setFilterItemData({ item: [], collection: [], metal: [], stone: [], size: [], location: [] });
  }
  return (
    <div className="zonefilter">
      <div className="zonefilter-inner">
        <div className="zonefilter-inner-heading">
          <div className="head">{t("Filter")}</div>
          <div className="clear" onClick={() => { setToggleFilter(false); handleClearFilter() }}>
            {t("Clear Filters")}
          </div>
        </div>
        <div className="zonefilter-inner-container">


          {Object.keys(filterData).length === 0 ? (
            ""
          ) : (
            Object.keys(filterData).map((key, i) => {
              // Skip rendering for the "location" key
              if (key === "location") return null;

              const details = filterData[key];
              return (
                <div className="zonefilter-inner-container-filter" key={key}>
                  <div className="zonefilter-inner-container-filter-header">
                    <span className="title">
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </span>

                    <span
                      className="select"
                      onClick={() => handleSelectAll(key, details)}
                    >
                      {t("Select All")}
                    </span>
                  </div>
                  <div className="zonefilter-inner-container-filter-data">
                    {details?.length
                      ? details.map((item, index) => {
                        const isSelected = filterItemData[key].includes(item._id);
                        return (
                          <div
                            key={item._id || index} // Use unique keys if possible
                            className="zonefilter-inner-container-filter-data-card"
                            onClick={() => handleSelect(key, item._id)}
                            style={{
                              backgroundColor: isSelected ? "#52CBC4" : "",
                              color: isSelected ? "black" : "",
                            }}
                          >
                            {item.name}
                          </div>
                        );
                      })
                      : null}
                  </div>
                </div>
              );
            })
          )}


        </div>
        <div className="zonefilter-inner-bottom">
          <button onClick={() => handleApply()}>{t("Apply")}</button>
        </div>
      </div>
    </div>
  );
}
