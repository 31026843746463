import React from 'react'

const FIlterIcon = (props) => {
    const { width, height } = props
    return (
        <svg width={width ? width : "24"} height={height ? height : "24"} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 10L12.5 15L17.5 10" stroke="#1D1D1F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>


    )
}

export default FIlterIcon