import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { _Api } from '../../api/_call';
import { loading, Updateloading } from '../helpers/toastify';
export const MasterCurrecyEditModal = (props) => {
    const { t } = useTranslation();
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    const { setShowedit } = props
    const [currencyarr, setCurrencyarr] = useState()

    const getcurrencylist = async () => {
        try {
            let postdata = {}
            let res = await _Api(postdata, `api/v1/App/master/currencyMasterGloblelist`)
            if (res?.code === 200) {
                setCurrencyarr(res?.data);
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    useEffect(() => {
        getcurrencylist();

        // eslint-disable-next-line 
    }, [])


    const editcurrency = async () => {
        let GetloadingID = loading()
        try {
            let postdata = {
                currencyarr
            }
            let res = await _Api(postdata, `api/v1/App/master/currencyMasterEdit`)
            if (res?.code === 200) {
                Updateloading(t, GetloadingID, (res?.message));
                setShowedit(new Date());

            } else {
                Updateloading(t, GetloadingID, (res?.errors), 'error');
            }

        }
        catch (err) {
            console.log(err, "err")
        }
    }

    const handleInputChange = (index, event) => {
        console.log(index, "fdfdf", event.target.value);

        const newData = [...currencyarr];
        newData[index].rate = event.target.value;
        setCurrencyarr(newData);

    };

    const handleSwitchChange = (index, checked) => {
        console.log(index, "Switch changed to:", checked);

        const newData = currencyarr.map((item, idx) => ({
            ...item,
            is_default: idx === index ? (checked ? 1 : 0) : 0
        }));

        setCurrencyarr(newData);
    };
    return (
        <div
            className="modal fade import-modal"
            id="MasterCurrecyModal"
            tabIndex={-1}
            aria-labelledby="MasterCurrecyModalModalLabel"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="ProductViewModalLabel"> {t("Currency Exchange Rates")}</h5>
                        <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button></div>
                    <div className="modal-body">
                        <div style={{ overflowX: "auto" }}>
                            <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                <thead>
                                    <tr style={{ textAlign: "center" }}>
                                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>#</th>
                                        <th style={{ border: "1px solid #ddd", padding: "20px" }}></th>
                                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>{t("Currency")}</th>
                                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>{t("Exchange Rate")}</th>
                                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>{t("Default Currency")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currencyarr?.map((row, index) => (
                                        <tr key={index} style={{ textAlign: "center" }}>
                                            <td style={{ border: "1px solid #ddd", padding: "8px" }}>{index + 1}</td>
                                            <td style={{ border: "1px solid #ddd", padding: "8px" }} className="px-1">
                                                <img style={{ width: "1.6vw" }} src={base_assets + 'images/square-flags/' + row?.flag} alt="" />
                                            </td>
                                            <td style={{ border: "1px solid #ddd", padding: "8px" }}>{row.currency}</td>
                                            <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                                                <input className='v3-input-group-input w-70 border-start-1'
                                                    type="text"
                                                    value={row.rate}
                                                    onChange={(event) => handleInputChange(index, event)}

                                                />
                                            </td>
                                            <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                                                <div className="form-check form-switch ps-0">
                                                    <input className="form-check-input float-none ms-2" type="checkbox" id="active"
                                                        // checked={row?.is_default}
                                                        checked={row.is_default === 1}
                                                        onChange={(e) => handleSwitchChange(index, e.target.checked)} />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div id="clickonme" className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                editcurrency();

                            }}
                            data-bs-dismiss="modal"
                            className="btn modal-content-yes"
                        >
                            {t("Submit")}
                        </button>

                    </div>
                </div>
            </div>
        </div>
    )
};
export default MasterCurrecyEditModal;
