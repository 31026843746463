import React from 'react'
import { useTranslation } from 'react-i18next';
import { toFormatPrice } from '../../../common/Formateprice';
const OrderSummaryBox = (props) => {
    const { t } = useTranslation();
    const { shippinglist, shippingdata } = props;
    return (
        <div className='orderSummaryCont'>
            <h3 className='com-shippingPage-headingMain m-0'>{t("Order Summary")}</h3>
            {shippinglist?.length ? shippinglist.map((result, key) => {
                return (
                    <div className=" com-ecomCartProductContShow m-0" key={key}>
                        <div className="com-ecomCartProductContShow-imageBox orderSummaryCont-imageBox">
                            <span className="product-img-span">
                                <img src={result?.main_image} alt="" />
                            </span>
                        </div>
                        <div className=" com-ecomCartProductContShow-detailsBox">
                            <div className="com-ecomCartProductContShow-detailsBox-firstBox">
                                <p className="com-ecomCartProductContShow-detailsBox-firstBox-productName">
                                    {result?.name}
                                </p>
                                <div className="com-ecomCartProductContShow-detailsBox-firstBox-content">
                                    <p className="contentPara">{result?.SKU}</p>
                                    <p className="contentPara">{result?.Metal}</p>
                                    <p className="contentPara">{t("Size")}:
                                        <span>{result?.Size}</span>
                                    </p>
                                </div>
                            </div>
                            <div className='com-ecomCartProductContShow-detailsBox-secondCountBox-priceShow text-end'>
                                {toFormatPrice(result?.UnFormatPrice * result?.qty, { addSymbol: true })}</div>
                        </div>
                    </div>
                )
            }) : ""}

            <div className='orderSummaryCont-amountCalcBox'>
                <div className='orderSummaryCont-amountCalcBox-subTotalBox'>
                    <p className='orderSummaryCont-amountCalcBox-subTotalBox-textCheck'>{t("Subtotal")} ({shippinglist?.length} {t("Items")})</p>
                    <p className='orderSummaryCont-amountCalcBox-subTotalBox-subAmountShow'> {toFormatPrice(shippinglist?.length ? shippinglist.reduce(
                        (accumulator, product) => accumulator + product.UnFormatPrice * product?.qty,
                        0
                    ) : 0, { addSymbol: true })}</p>
                </div>
                <div className='orderSummaryCont-amountCalcBox-subTotalBox'>
                    <p className='orderSummaryCont-amountCalcBox-subTotalBox-textCheck'>{t("Shipping")}</p>
                    <p className='orderSummaryCont-amountCalcBox-subTotalBox-subAmountShow'>  {toFormatPrice(shippingdata?.shipprice, { addSymbol: true })}</p>
                </div>
            </div>
            <div className='orderSummaryCont-amountCalcBox m-0'>
                <div className='grandTotalBox'>
                    <p className='grandTotalBox-grandTotalText'>{t("Subtotal")} ({shippinglist?.length} {t("Items")})</p>
                    <p className='com-shippingPage-headingMain m-0'> {toFormatPrice(shippinglist?.length ?
                        shippingdata?.shipprice ? shippingdata?.shipprice + shippinglist.reduce(
                            (accumulator, product) => accumulator + product.UnFormatPrice * product?.qty,
                            0
                        ) : shippinglist.reduce(
                            (accumulator, product) => accumulator + product.UnFormatPrice * product?.qty,
                            0
                        ) : 0 + 0, { addSymbol: true })}</p>
                </div>
            </div>
        </div>
    )
}

export default OrderSummaryBox