import React, { useEffect, useState } from 'react'
import PersonQuesIcon from '../../assets/images/personQuestionMark.svg';
import ShareIcon from '../../assets/icons/ShareIcon';
import DownloadIcon from '../../assets/icons/DownloadIcon';
import PrintIcon from '../../assets/icons/PrintIcon';
import PlusIcon from '../../assets/icons/PlusIcon';
import MinusIcon from '../../assets/icons/MinusIcon';
import HeartBgIcon from '../../assets/icons/HeartBgIcon';
import { _Api } from "../../../common/api"
import { Link } from 'react-router-dom';
import AddToCartModal from "../../components/AddToCart";
import { useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import { wishlistobjectheader } from "../../../../../source/app/appSlice";
import { useDispatch } from 'react-redux';
import Spinner from "../../../common/Spinner";
const WishlistPage = ({ setOpenmodal }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loadeing, setloading] = useState(true)
    let localmywishlist = localStorage.getItem('mywishlist') ? JSON.parse(localStorage.getItem('mywishlist')) : [];
    let localmywishlistremove = localStorage.getItem('mywishlistremove') ? JSON.parse(localStorage.getItem('mywishlistremove')) : [];
    const accessToken = localStorage.getItem("ecomaccesstoken");
    const [wishlistdata, setWishlistdata] = useState([]);
    const [isopencart, setisopencart] = useState(false);
    const getwishlist = async () => {
        setloading(true)
        try {
            let postdata = {
                "wishlistproduct": localmywishlist,
                "wishlistremove": localmywishlistremove,
            }
            let res = await _Api(postdata, `product/wishlist-product-info`)
            if (res?.statusCode === 200) {
                setloading(false)
                let array = [];
                if (res.data.length) {
                    res.data.forEach((result) => {
                        result.qty = 1;
                        array.push(result);
                    })
                }
                setWishlistdata(array)
            } else {
                setloading(false)
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    useEffect(() => {
        getwishlist()
        // eslint-disable-next-line
    }, [])
    const removewishlist = async (id) => {
        if (accessToken) {
            try {
                let postdata = {
                    "id": id
                }
                let res = await _Api(postdata, `product/deleteWishList`)
                if (res?.statusCode === 200) {
                    let deletearry = [...wishlistdata]
                    const updated = deletearry.filter(removeid => removeid?._id !== id);
                    setWishlistdata(updated)
                    let removewislistarr = [...localmywishlistremove]
                    removewislistarr.push(id)
                    const uniqueIdsSet = new Set(removewislistarr);
                    let unique_ids = Array.from(uniqueIdsSet);
                    localStorage.setItem('mywishlistremove', JSON.stringify(unique_ids ? unique_ids : []));
                    let updateaddlist = [...localmywishlist]
                    const updatedData = updateaddlist.filter(removeid => removeid !== id);
                    localStorage.setItem('mywishlist', JSON.stringify(updatedData ? updatedData : []));
                    dispatch(wishlistobjectheader(updatedData));
                }
            }
            catch (err) {
                console.log(err, "err")
            }
        } else {
            let deletearry = [...wishlistdata]
            const updated = deletearry.filter(removeid => removeid?._id !== id);
            setWishlistdata(updated)
            let removewislistarr = [...localmywishlistremove]
            removewislistarr.push(id)
            const uniqueIdsSet = new Set(removewislistarr);
            let unique_ids = Array.from(uniqueIdsSet);
            localStorage.setItem('mywishlistremove', JSON.stringify(unique_ids ? unique_ids : []));
            let updateaddlist = [...localmywishlist]
            const updatedData = updateaddlist.filter(removeid => removeid !== id);
            localStorage.setItem('mywishlist', JSON.stringify(updatedData ? updatedData : []));
            dispatch(wishlistobjectheader(updatedData));
        }
    }
    const changeqty = (label, id) => {
        const updatedWishlistData = wishlistdata.map(e => {
            if (e._id === id) {
                if (label === 'minus' && e.qty > 1) {
                    return { ...e, qty: e.qty - 1 };
                } else if (label !== 'minus') {
                    return { ...e, qty: e.qty + 1 };
                }
            }
            return e;
        });

        setWishlistdata(updatedWishlistData);
    };
    const addtocart = (id, qty) => {
        let localitem = localStorage.getItem('CartItems') ? JSON.parse(localStorage.getItem('CartItems')) : [];
        localitem.push({ 'id': id, 'qty': qty });
        localStorage.setItem('CartItems', JSON.stringify(localitem));
        setisopencart(true);
    }
    const downloadPageAsPNG = () => {
        const rootElement = document.getElementById('wishlistpng');
        html2canvas(rootElement).then((canvas) => {
            const imageURL = canvas.toDataURL('image/png');
            const anchor = document.createElement('a');
            anchor.download = 'wishlist.png';
            anchor.href = imageURL;
            anchor.click();
        });
        // const rootElement = document.getElementById('wishlistpng');
        // const images = rootElement.getElementsByTagName('img');
        // for (let img of images) {
        //   img.crossOrigin = 'anonymous';
        // }
        // html2canvas(rootElement, {
        //   letterRendering: 1,
        //   allowTaint: false, 
        //   useCORS: true 
        // }).then(canvas => {
        //   const imageURL = canvas.toDataURL('image/png');
        //   const anchor = document.createElement('a');
        //   anchor.download = 'wishlist.png';
        //   anchor.href = imageURL;
        //   anchor.click();
        // }).catch(err => {
        //   console.error('Error capturing canvas:', err);
        // });
    };
    const handlePrint = () => {
        window.print();
    };
    return (
        <div className='wishlistMain ecommerce-main-layout-bodyContent' id='wishlistpng'>
            <div className='com-path-show'><span className='com-path-show-startPath'>{t("Collection")}</span> <span className='SlashPath'>/</span> <span className='com-path-show-currectPath'>{t("Wishlist")}</span></div>
            {
                loadeing ?
                    <div className=" w-100 d-flex justify-content-center align-items-center  ">
                        <Spinner />
                    </div> :
                    <div className='wishlistPage'>
                        <div className='wishlistMain-container'>
                            <div className='wishlistMain-container-headerBox list-product-header'>
                                <div className='wishlistMain-container-headerBox-headerName'>{t("MY WISHLIST")}</div>
                                <div className='wishlistMain-container-headerBox-secondBox'>

                                    <div className='wishlistMain-container-headerBox-secondBox-leftSide'>
                                        {!accessToken ? "To view items saved in your account, please sign in." : ""}</div>

                                    <div className='wishlistMain-container-headerBox-secondBox-rightSideBox'>
                                        {!accessToken ?
                                            <div className='wishlistMain-container-headerBox-secondBox-rightSideBox-questShopper'
                                                onClick={() => setOpenmodal(
                                                    {
                                                        showLoginModel: true,
                                                        showRegisterModel: false,
                                                        showemailvarifymodal: false,
                                                        forgetpassword: false,
                                                        resetpassword: false,
                                                        newpassword: false
                                                    }
                                                )}>
                                                <span className='d-flex' ><img src={PersonQuesIcon} alt='' /></span>
                                                <span className='text-show ' > {t("Guest Shopper")}</span>
                                            </div> : ""}
                                        <div className='wishlistMain-container-headerBox-secondBox-rightSideBox-iconBox'>
                                            <ShareIcon />
                                            <span onClick={() => downloadPageAsPNG()}><DownloadIcon /></span>
                                            <span onClick={() => handlePrint()}><PrintIcon /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='wishlistMain-container-bodyProCont'>
                                {wishlistdata?.length ? wishlistdata.map((result, key) => {
                                    return (
                                        <div className='wishlistMain-container-bodyProCont-singlePro' key={key}>
                                            <div className='wishlistMain-container-bodyProCont-singlePro-productType'>
                                                <div className='wishlistMain-container-bodyProCont-singlePro-productType-imageBox'>
                                                    <span className='product-img-span'><img src={result?.main_image} alt='' /></span>
                                                </div>
                                                <div className='wishlistMain-container-bodyProCont-singlePro-productType-details'>
                                                    <div className='wishlistMain-container-bodyProCont-singlePro-productType-details-firstBox'>
                                                        <Link to={`/product/${result?.Slug}`} state={{ id: result?._id }}
                                                            className='text-decoration-none'>
                                                            <p className='wishlistMain-container-bodyProCont-singlePro-productType-details-firstBox-productName'>{result?.name}</p>
                                                        </Link>
                                                        <div className='wishlistMain-container-bodyProCont-singlePro-productType-details-firstBox-content'>
                                                            <p className='contentPara'>{result?.SKU}</p>
                                                            <p className='contentPara'>{result?.Metal}</p>
                                                            <p className='contentPara'><span>{t("Size")}:</span> {result?.Size} </p>
                                                        </div>
                                                    </div>
                                                    <div className='wishlistMain-container-bodyProCont-singlePro-productType-details-secondBox'>
                                                        <div className='priceShow marginLeftAuto'>{result?.price}</div>
                                                        <div className='productCountShow marginLeftAuto'><button
                                                            onClick={() => changeqty('minus', result?._id
                                                            )} className='productCountShow-minusIcon'><MinusIcon width={14} height={14} color={'#1D1D1F'} /></button><span className='productCountShow-count'>{result?.qty}</span><button onClick={() => changeqty('plus', result?._id
                                                            )} className='productCountShow-plusIcon'><PlusIcon width={14} height={14} color={'#1D1D1F'} /></button>
                                                        </div>
                                                        <div className='wishAddToCartBtn marginLeftAuto' data-bs-toggle="modal" data-bs-target="#AddToCartModal" onClick={() => addtocart(result?._id
                                                            , result?.qty)}>{t("Add to Cart")}</div>
                                                        <div className='product-iconListBox '>
                                                            <div className='product-iconListBox-removeBtn marginLeftAuto'>
                                                                <span className='heart-icon-box'><HeartBgIcon width={24} height={24} /></span>
                                                                <span className='product-iconListBox-removeBtn-text'
                                                                    onClick={() => removewishlist(result?._id)}>{t("Remove from Wishlist")}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) :
                                    <div className='wishlistMain-container-bodyBox'>
                                        <div className='wishlistMain-container-bodyBox-innerContent'>
                                            <p className='wishlistMain-container-bodyBox-innerContent-para'>{t("Add To My Wishlist")}</p>
                                            <Link className='text-decoration-none' to="/">
                                                <div className='wishlistMain-container-bodyBox-innerContent-addProductBtnBox'>
                                                    <PlusIcon width={16} height={16} color={"#FFFFFF"} />
                                                    <span>{t("Add Product")}</span>
                                                </div>
                                            </Link>

                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
            }

            <AddToCartModal setisopencart={setisopencart} isopencart={isopencart} />
        </div>
    )
}

export default WishlistPage