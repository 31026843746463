import { useTranslation } from 'react-i18next';
import CircledTick from '../../common/icons/sidebar/CircledTick';
import { useCallback, useEffect, useRef, useState } from 'react';
import UserIcon from '../../common/icons/UserIcon';
import { _Api, graphqlPost } from '../../api/_call';
import ConfirmationPopup from './ConfirmationPopup';
import KanbanModal from './KanbanModal';
import { Link } from 'react-router-dom';
import CloseIcon from '../../common/icons/CloseIcon';
import FilterIconMain from '../../common/icons/sidebar/FilterIconMain';
import BacklogFilter from './BacklogFilter';

export default function Purchase({ setApplyfilter, applyfilter }) {

    const { t } = useTranslation();
    const modalRef = useRef(null);
    const pubacklogRef = useRef(null);
    const [draggedItem, setDraggedItem] = useState();
    const [PUlist, setPUlist] = useState([]);
    const [targetCard, setTargetCard] = useState();
    const [confirmationResponse, setConfirmationResponse] = useState('');
    const [GetOrderDetails, setGetOrderDetails] = useState({ order_id: "", order_number: "", order_type: "", order_status: "" });
    const [createPUList, setCreatePUList] = useState([]);
    const [getpoOrderIds, setPoOrderIds] = useState('');
    const [getsupplierId, setsupplierId] = useState('');
    const [showbacklogFilters, setShowbacklogFilters] = useState(false);
    const [location, setLocation] = useState();
    const [activeScroll, setActiveScroll] = useState();
    const [cardBordListUpdate, setCardBordListUpdate] = useState(false);

    const getLocationList = useCallback(async () => {
        try {
            const postData = `{
            locations { 
              name    
            }
          }`;
            const response = await graphqlPost(postData);
            let locationArray = [];
            if (response.data?.locations) {
                const filteredLocations = response.data?.locations;
                filteredLocations.map((item) => { locationArray.push(item?.name) })
                setLocation(prev => prev = locationArray)
                // setBacklogFilters((prev)=>({...prev,location : locationArray})); 
            }
        } catch (err) {
            console.error(err, "err");
        }

    }, []);
    useEffect(() => {
        getLocationList();
        // Function to close popup when clicked outside
        const handleClickOutside = (event) => {
            if (pubacklogRef.current && !pubacklogRef.current.contains(event.target)) {
                setShowbacklogFilters(false);
            }
        };

        // Attach event listener to the document
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line  

    }, [getLocationList]);


    const [backlogFilters, setBacklogFilters] = useState({ "create_at": [], "location": [], "order_type": [] })

    const [activeLocation, setActiveLocation] = useState(null);

    const handleDragStart = (event, data, index) => {
        let dummydata = index === 1 ? 'pending' : index === 2 ? 'approved' : index === 3 ? 'cancel' : index === 4 ? 'completed' : 'error'
        if (dummydata !== 'error') {
            setDraggedItem([dummydata, data]);
        }
    };

    const getShortForm = (location) => {
        const words = location.split(' ');
        const initials = words.map(word => word.charAt(0).toUpperCase());
        return initials.join('');
    }

    useEffect(() => {
        handleDragandDrop();
        // eslint-disable-next-line  
    }, [confirmationResponse])

    const handleDragandDrop = async () => {
        const modalElement = modalRef.current;
        const bootstrapModal = window.bootstrap.Modal.getOrCreateInstance(modalElement);
        bootstrapModal.hide();
        if (confirmationResponse === 'confirm') {
            try {
                let postdata = {
                    'pu_order_id': draggedItem[1]._id,
                    'status': draggedItem[0] === 'pending' && targetCard === 'approved' ? 1 : draggedItem[0] === 'pending' && targetCard === 'cancel' ? 0 : draggedItem[0] === 'approved' && targetCard === 'pending' ? 2 : null
                }
                let res = await _Api(postdata, `api/v1/Inventory/purchase/editPUStatus`);
                if (res?.code === 200) {
                    getKanbanPUlistApi();

                }
            }
            catch (err) {
                console.log(err, "err")
            }
            setConfirmationResponse('')
            setDraggedItem([]);
        } else {
            setConfirmationResponse('');
            setDraggedItem([]);
        }
    }

    const handleDrop = (event, targetindex) => {

        event.preventDefault();
        let dummydata = targetindex === 1 ? 'pending' : targetindex === 2 ? 'approved' : targetindex === 3 ? 'cancel' : targetindex === 4 ? 'completed' : 'error'
        if (dummydata !== 'error') {
            // remove from actual card
            if ((draggedItem[0] === 'pending' && dummydata === 'approved') || (draggedItem[0] === 'approved' && dummydata === 'pending') || (draggedItem[0] === 'pending' && dummydata === 'cancel')) {
                const modalElement = modalRef.current;
                const bootstrapModal = window.bootstrap.Modal.getOrCreateInstance(modalElement);
                bootstrapModal.show();
                setTargetCard(prev => prev = dummydata);

                // let arraytobeupdated = getData?.[draggedItem[0]]
                // arraytobeupdated = arraytobeupdated.filter((result) => result.item !== draggedItem[1].item);
                // setGetData(prev => ({ ...prev, [draggedItem[0]]: arraytobeupdated }))


                // let newData = getData?.[dummydata]
                // newData = [...newData, draggedItem[1]]
                // console.log(newData, "after drop and addding");
                // setGetData(prev => ({ ...prev, [dummydata]: newData }))

            } else {
                setDraggedItem(null);
            }
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleLocationItems = (backLogDataResult) => {
        const poProductIds = backLogDataResult?.po_product_ids || [];
        const poOrdersIds = backLogDataResult?._id;
        const isChecked = createPUList.some(id => poProductIds.includes(id));
        if (activeLocation !== null) {
            // Uncheck all checkboxes
            if (activeLocation === backLogDataResult?.location_id) {
                setActiveLocation(null);
                setCreatePUList([]);
                setPoOrderIds('');
                setsupplierId('');

            } else {
                return; // Prevent checking other locations if one is already selected
            }
        } else {
            setActiveLocation(backLogDataResult?.location_id);
            if (isChecked) {
                setCreatePUList(prev => prev.filter(id => !poProductIds.includes(id)));
                setPoOrderIds(backLogDataResult?._id)
                setsupplierId(backLogDataResult?.supplier_id)
            } else {
                setCreatePUList(prev => [...prev, ...poProductIds]);
                setPoOrderIds(poOrdersIds);
                setsupplierId(backLogDataResult?.supplier_id)


            }
        }
    };


    useEffect(() => {
        if (Array.isArray(applyfilter?.location)) {
            getKanbanPUlistApi()
        }
        // eslint-disable-next-line  
    }, [backlogFilters, applyfilter])

    const getKanbanPUlistApi = useCallback(async () => {
        try {
            let postdata = {
                "order_type": applyfilter?.order_type?.length ? applyfilter?.order_type : [],
                "location": applyfilter?.location?.length ? applyfilter?.location : [],
                "create_at": applyfilter?.create_at ? applyfilter?.create_at : [],
                "search": applyfilter?.search ? applyfilter?.search : "",
                "duesFilters": applyfilter?.duesFilters ? applyfilter?.duesFilters : [],
                "backlog": { "create_at": backlogFilters?.create_at ? backlogFilters?.create_at : [], "location": backlogFilters?.location ? backlogFilters?.location : [], "order_type": backlogFilters?.order_type ? backlogFilters?.order_type : [] }
            }
            let res = await _Api(postdata, `api/v1/App/kanban/getkanbanPUlist`);
            if (res?.code === 200) {
                setPUlist(res?.data);
                setActiveLocation(null);
                setCreatePUList([]);
                setPoOrderIds('');
                setsupplierId('');
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }, [applyfilter, backlogFilters]);
    useEffect(() => {
        if (cardBordListUpdate) {
            getKanbanPUlistApi();
        }
        // eslint-disable-next-line
    }, [cardBordListUpdate]);
    const handleShowFilter = () => {
        setShowbacklogFilters(!showbacklogFilters)
    }


    const handleScroll = (e, type) => {
        console.log(e, "handleScroll");
        setActiveScroll(type);
    }

    return (
        <div className='kanbanpage-content-po'>
            <div className='kanbanpage-content-po-cardcontainer'>
                {/* backlog card started */}
                {applyfilter?.Status?.includes('Backlog') || applyfilter?.Status?.length <= 0 ?
                    <div className={`kanbanpage-content-po-cardcontainer-card ${showbacklogFilters ? 'withfilter' : ""}${activeScroll === 'backlog' ? 'showscroll' : ''}`} onScroll={(e) => handleScroll(e, 'backlog')} onMouseLeave={() => setActiveScroll('')} ref={pubacklogRef}>
                        <div className='kanbanpage-content-po-cardcontainer-card-top'>
                            <div className='kanbanpage-content-po-cardcontainer-card-top-title'>
                                <span className='kanbanpage-content-po-cardcontainer-card-top-title-icon'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 5H21M11 9H16" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6 5H4C3.44772 5 3 5.44772 3 6V8C3 8.55228 3.44772 9 4 9H6C6.55228 9 7 8.55228 7 8V6C7 5.44772 6.55228 5 6 5Z" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M11 15H21M11 19H16" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6 15H4C3.44772 15 3 15.4477 3 16V18C3 18.5523 3.44772 19 4 19H6C6.55228 19 7 18.5523 7 18V16C7 15.4477 6.55228 15 6 15Z" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <span className='text'>{t("Backlog")}</span>
                            </div>
                            <div className='kanbanpage-content-po-cardcontainer-card-top-count'>
                                {PUlist?.backlogs?.backlogsdata?.length || 0}
                            </div>
                        </div>
                        <div className='kanbanpage-content-po-cardcontainer-card-subhead'>
                            <Link className='plusicon'
                                to={"/app/inventory/purchaseOrdersOutstanding"}
                                state={{
                                    purchaseoustandingids: createPUList,
                                    po_order_id: getpoOrderIds,
                                    location_id: activeLocation,
                                    supplier_id: getsupplierId,
                                    warehouse_id: "",
                                }}><span>{t('Create')}</span><CloseIcon /></Link>
                            <span className='filtericon' onClick={() => setShowbacklogFilters(!showbacklogFilters)}><span>{t('Filter')}</span><FilterIconMain width={24} height={24} /></span>
                        </div>
                        <div className='kanbanpage-content-po-cardcontainer-card-lists'>
                            {
                                PUlist?.backlogs?.backlogsdata?.map((result, index) => {
                                    return (
                                        <div className='kanbanpage-content-po-cardcontainer-card-lists-content backlog'
                                            key={index}
                                            data-bs-toggle="modal"
                                            data-bs-target="#showtaskmodel"
                                            data-id={result?._id}
                                            onClick={() => setGetOrderDetails({ order_id: result?._id, order_number: result?.pu_no, order_type: "PO", order_status: 3 })}
                                        >
                                            <div className="custom-checkbox">
                                                <input
                                                    type='checkbox'
                                                    id={result?._id}
                                                    value={result?._id}
                                                    onClick={() => handleLocationItems(result)}
                                                    checked={activeLocation === result?.location_id && createPUList.some(id => result.po_product_ids.includes(id))}

                                                />
                                                <label htmlFor='myCheckbox' />
                                            </div>
                                            <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                                <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-detail'>
                                                    <div className='title'>
                                                        <div className='titletextpu'>{result?.pu_no}</div>
                                                        {result?.PosQty > 0 ? (
                                                            <div className='iconholder kanbanpage-tooltipholder'>
                                                                <UserIcon /> <span className='numaric'> {result?.PosQty}</span>
                                                                <span className='kanbanpage-tooltip'>{getShortForm(result?.location_name)} {result?.pu_no}</span>
                                                            </div>

                                                        ) : ""}
                                                    </div>
                                                    <div className='date'
                                                        style={{
                                                            color:
                                                                result.Date.includes("Due in") ? "#FABB2D" :
                                                                    result.Date.includes("Overdue") ? "#FF5757" : ""
                                                        }}
                                                    >{result?.Date}</div>
                                                </div>
                                                <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-tag'>
                                                    {result?.location_name}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {showbacklogFilters ? <BacklogFilter handleShowFilter={handleShowFilter} backlogFilters={backlogFilters} setBacklogFilters={setBacklogFilters} type="purchase" location={location} /> : ""}
                    </div>
                    : ""}
                {/* backlog card ended */}
                {/* pending card started */}
                {applyfilter?.Status?.includes('Pending') || applyfilter?.Status?.length <= 0 ?
                    <div className={`kanbanpage-content-po-cardcontainer-card ${activeScroll === 'pending' ? 'showscroll' : ''}`} onScroll={(e) => handleScroll(e, 'pending')} onMouseLeave={() => setActiveScroll('')}>
                        <div className='kanbanpage-content-po-cardcontainer-card-top pending'
                        >
                            <div className='kanbanpage-content-po-cardcontainer-card-top-title'>
                                <span className='kanbanpage-content-po-cardcontainer-card-top-title-icon'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 6V10L13 13M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <span className='text'>{t("Pending")}</span>
                            </div>
                            <div className='kanbanpage-content-po-cardcontainer-card-top-count'>
                                {PUlist?.pending?.length || 0}
                            </div>
                        </div>
                        <div className='kanbanpage-content-po-cardcontainer-card-lists' onDrop={(e) => handleDrop(e, 1)}
                            onDragOver={handleDragOver}>
                            {draggedItem?.[0] === 'approved' ?
                                <div className='kanbanpage-content-po-cardcontainer-card-lists-content draganddrop'>
                                    <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                        <div>Drag and Drop here</div>
                                    </div>
                                </div>
                                : ""
                            }
                            {PUlist?.pending?.map((pending, index) => {
                                return (
                                    <div className='kanbanpage-content-po-cardcontainer-card-lists-content cursor-grab'
                                        key={index}
                                        draggable
                                        onDragStart={(e) => handleDragStart(e, pending, 1)}
                                        data-bs-toggle="modal"
                                        data-bs-target="#showtaskmodel"
                                        data-id={pending?._id}
                                        onClick={() => setGetOrderDetails({ order_id: pending?._id, order_number: pending?.pu_no, order_type: "PU", order_status: 2 })}

                                    >
                                        <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                            <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-detail'>
                                                <div className='title'><span>{pending?.pu_no}</span>
                                                    {pending?.PosQty > 0 ? (
                                                        <span>
                                                            <UserIcon /> {pending?.PosQty}
                                                        </span>
                                                    ) : ""}

                                                </div>
                                                <div className='date'
                                                    style={{
                                                        color:
                                                            pending.Date.includes("Due in") ? "#FABB2D" :
                                                                pending.Date.includes("Overdue") ? "#FF5757" : ""
                                                    }}
                                                >{pending?.Date}</div>
                                            </div>
                                            <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-tag'>
                                                {pending?.location_name}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    : ""}
                {/* pending card ended */}
                {/* approved card started */}
                {applyfilter?.Status?.includes('Approved') || applyfilter?.Status?.length <= 0 ?
                    <div className={`kanbanpage-content-po-cardcontainer-card ${activeScroll === 'approved' ? 'showscroll' : ''}`} onScroll={(e) => handleScroll(e, 'approved')} onMouseLeave={() => setActiveScroll('')}>
                        <div className='kanbanpage-content-po-cardcontainer-card-top approved'>
                            <div className='kanbanpage-content-po-cardcontainer-card-top-title'>
                                <span className='kanbanpage-content-po-cardcontainer-card-top-title-icon'>
                                    <CircledTick />
                                </span>
                                <span className='text'>{t("Approved")}</span>
                            </div>
                            <div className='kanbanpage-content-po-cardcontainer-card-top-count'>
                                {PUlist?.approved?.length || 0}
                            </div>
                        </div>
                        <div className='kanbanpage-content-po-cardcontainer-card-lists' onDrop={(e) => handleDrop(e, 2)}
                            onDragOver={handleDragOver}>
                            {draggedItem?.[0] === 'pending' ?
                                <div className='kanbanpage-content-po-cardcontainer-card-lists-content draganddrop'>
                                    <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                        <div>Drag and Drop here</div>
                                    </div>
                                </div>
                                : ""
                            }
                            {
                                PUlist?.approved?.map((approve, index) => {
                                    return (
                                        <div className='kanbanpage-content-po-cardcontainer-card-lists-content cursor-grab' key={index}
                                            draggable
                                            onDragStart={(e) => handleDragStart(e, approve, 2)}

                                            data-bs-toggle="modal"
                                            data-bs-target="#showtaskmodel"
                                            data-id={approve?._id}
                                            onClick={() => setGetOrderDetails({ order_id: approve?._id, order_number: approve?.pu_no, order_type: "PU", order_status: 1 })}

                                        >
                                            <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                                <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-detail'>
                                                    <div className='title'><span>{approve.pu_no}</span>
                                                        {approve?.PosQty > 0 ? (
                                                            <span>
                                                                <UserIcon /> {approve?.PosQty}
                                                            </span>
                                                        ) : ""}
                                                    </div>
                                                    <div className='date'>{approve?.Date}</div>
                                                </div>
                                                <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-tag'>
                                                    {approve?.location_name}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    : ""}
                {/* approved card ended */}
                {/* cancel card started */}
                {applyfilter?.Status?.includes('Cancel') || applyfilter?.Status?.length <= 0 ?
                    <div className={`kanbanpage-content-po-cardcontainer-card ${activeScroll === 'cancel' ? 'showscroll' : ''}`} onScroll={(e) => handleScroll(e, 'cancel')} onMouseLeave={() => setActiveScroll('')}>
                        <div className='kanbanpage-content-po-cardcontainer-card-top cancel'>
                            <div className='kanbanpage-content-po-cardcontainer-card-top-title'>
                                <span className='kanbanpage-content-po-cardcontainer-card-top-title-icon'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 12L10 10M10 10L12 8M10 10L8 8M10 10L12 12M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <span className='text'>{t("Cancel")}</span>
                            </div>
                            <div className='kanbanpage-content-po-cardcontainer-card-top-count'>
                                {PUlist?.cancel?.length}
                            </div>
                        </div>
                        <div className='kanbanpage-content-po-cardcontainer-card-lists' onDrop={(e) => handleDrop(e, 3)}
                            onDragOver={handleDragOver}>
                            {draggedItem?.[0] === 'pending' ?
                                <div className='kanbanpage-content-po-cardcontainer-card-lists-content draganddrop'>
                                    <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                        <div>Drag and Drop here</div>
                                    </div>
                                </div>
                                : ""
                            }
                            {PUlist?.cancel?.map((canceldata, index) => {
                                return (
                                    <div className='kanbanpage-content-po-cardcontainer-card-lists-content' key={index}
                                        data-bs-toggle="modal"
                                        data-bs-target="#showtaskmodel"
                                        data-id={canceldata?._id}
                                        onClick={() => setGetOrderDetails({ order_id: canceldata?._id, order_number: canceldata?.pu_no, order_type: "PU", order_status: 0 })}

                                    >
                                        <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                            <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-detail'>
                                                <div className='title'><span>{canceldata?.pu_no}</span></div>
                                                <div className='date'>{canceldata?.Date}</div>
                                            </div>
                                            <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-tag'>
                                                {canceldata?.location_name}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    : ""}
                {/* cancel card ended */}
            </div>
            <ConfirmationPopup setConfirmationResponse={setConfirmationResponse} modalRef={modalRef} />
            <KanbanModal GetOrderDetails={GetOrderDetails} Activity={t('Purchase')} setCardBordListUpdate={setCardBordListUpdate} />

        </div>
    )
}
