import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import ImageGallery from 'react-image-gallery';
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import CollectionSlider from "Ecommerce/template_three/include/Common/CollectionSlider";
import PlusIcon from "Ecommerce/template_three/assets/icons/PlusIcon";
import MinusIcon from "Ecommerce/template_three/assets/icons/MinusIcon";
import HeartIcon from "admin/common/icons/HeartIcon";
import Spinner from "Ecommerce/common/Spinner";
import { wishlistobjectheader } from "source/app/appSlice";
import { _Api } from "../../../common/api";
import ArroeIcontop from '../../assets/images/arrowIcontop.png'
import LeftIcon from "admin/common/icons/sidebar/LeftIcon";
import './Productpage.scss'
import BottomBar from "Ecommerce/template_three/include/Common/BottomBar";
import SizeGuildeModal from "Ecommerce/template_three/components/SizeGuilde/SizeGuildeModal";
import AddCartModal from "Ecommerce/template_three/components/Cart/AddCartModal";
import RecentViewProducts from "Ecommerce/template_three/components/RecentViewProducts";
import CartItemsNotify from "Ecommerce/template_three/components/Cart/CartItemsNotify";

export default function ProductPage() {
    const dispatch = useDispatch();
    const { state } = useLocation()
    const { t } = useTranslation();
    const [quantity, setQuantity] = useState(1);
    const [showdropDown, setShowDropdown] = useState(false)
    const [productdetails, setProductdetails] = useState()
    const [variantcombo, setVariantcombo] = useState({ metal: '', stone: '', size: '' });
    const [isopennotifycart, setIsopennotifycart] = useState(false)
    const [isopencart, setisopencart] = useState(false);
    const [activeThumbnail, setActiveThumbnail] = useState(1);
    const [loading, setloading] = useState(true)
    const fullPath = useLocation();
    const parts = fullPath.pathname.split('/');
    const params = new URLSearchParams(fullPath.search);
    const vValue = params.get('v');
    let localmywishlist = localStorage.getItem('mywishlist') ? JSON.parse(localStorage.getItem('mywishlist')) : [];
    let localmywishlistremove = localStorage.getItem('mywishlistremove') ? JSON.parse(localStorage.getItem('mywishlistremove')) : [];
    let navigate = useNavigate();
    const [variantdata, setVarientdata] = useState([])
    const [varientloading, setVarientloading] = useState(false)
    const [wishlistdata, setWishliatdata] = useState({ id: "", remove: false, edit: false })
    const setwishlist = () => {
        let setobj = { ...productdetails }
        if (setobj.isWishlist) {
            setobj.isWishlist = 0
            let removewislistarr = [...localmywishlistremove]
            removewislistarr.push(setobj?._id)
            const uniqueIdsSet = new Set(removewislistarr);
            let unique_ids = Array.from(uniqueIdsSet);
            localStorage.setItem('mywishlistremove', JSON.stringify(unique_ids ? unique_ids : []));
            if (localmywishlist?.length) {
                const updatedData = localmywishlist.filter(id => id !== setobj?._id);
                localStorage.setItem('mywishlist', JSON.stringify(updatedData ? updatedData : []));
                dispatch(wishlistobjectheader(updatedData));
            }
        } else {
            setobj.isWishlist = 1
            let addwislistarr = [...localmywishlist]
            addwislistarr.push(setobj?._id)
            const uniqueIdsSet = new Set(addwislistarr);
            let unique_ids = Array.from(uniqueIdsSet);
            localStorage.setItem('mywishlist', JSON.stringify(unique_ids ? unique_ids : []));
            dispatch(wishlistobjectheader(unique_ids));
            if (localmywishlistremove?.length) {
                const updatedData = localmywishlistremove.filter(removeid => removeid !== setobj?._id);
                localStorage.setItem('mywishlistremove', JSON.stringify(updatedData ? updatedData : []));
            }
        }
        setProductdetails(setobj)
    }
    const getproductdetails = async () => {
        setloading(true)

        let localRecentViewed = localStorage.getItem('RecentViewed') ? JSON.parse(localStorage.getItem('RecentViewed')) : [];
        setTimeout(() => {
            if (!localRecentViewed.filter(e => e === state?.id).length) {
                localRecentViewed.push(state?.id);
                localStorage.setItem('RecentViewed', JSON.stringify(localRecentViewed));
            }
        }, 15000)

        try {
            let postdata = {
                id: parts[2],
                "wishlistproduct": localmywishlist,
                "wishlistremove": localmywishlistremove,
            }
            let res = await _Api(postdata, `product/getProductDetails`)
            if (res?.statusCode === 200) {
                setProductdetails(res?.data);
                if (!vValue) {
                    const combo = { metal: res?.data?.metal?.length ? res?.data?.metal[0] : '', stone: res?.data?.stone?.length ? res?.data?.stone[0] : '', size: '' }
                    setVariantcombo(combo);
                }

            }
            setloading(false)
        }
        catch (err) {
            console.log(err, "err")
        }
    }

    const getvarient = async () => {
        setVarientloading(true)
        try {
            let postdata = {
                id: parts[2],
                "wishlistproduct": localmywishlist,
                "wishlistremove": localmywishlistremove,
            }
            let res = await _Api(postdata, `product/get-product-variants`)
            if (res?.statusCode === 200) {
                setVarientdata(res?.data)
                if (vValue) {
                    if (res?.data?.length) {
                        res?.data?.forEach((result) => {
                            if (vValue === result?._id) {
                                const combo = { metal: result?.metal_name ? result?.metal_name : '', stone: result?.stone_name ? result?.stone_name : '', size: result?.size_name ? result?.size_name : '' }
                                setVariantcombo(combo);
                            }
                        });
                    }
                }
                setVarientloading(false)
            } else {
                setVarientloading(false)
            }
        }
        catch (err) {
            console.log(err, "err")
        }

    }
    const combination_variant = (variant, data) => {
        let variant_sku = '';
        if (variant === 'metal') {
            variant_sku = productdetails?.SKU + data?.value + (variantcombo?.stone ? variantcombo?.stone?.value : '') + '-' + (variantcombo?.size ? variantcombo?.size?.value : '');
            setVariantcombo({ ...variantcombo, metal: data });
        }
        if (variant === 'stone') {
            variant_sku = productdetails?.SKU + (variantcombo?.metal ? variantcombo?.metal?.value : '') + data?.value + '-' + (variantcombo?.size ? variantcombo?.size?.value : '');
            setVariantcombo({ ...variantcombo, stone: data });
        }
        if (variant === 'size') {
            let allsize = document.querySelectorAll(".sizeselect");
            allsize.forEach(sizze => {
                sizze.classList.remove('selectedSize');
                if (sizze.textContent === data?.label) {
                    sizze.classList.add('selectedSize');
                }
            });
            variant_sku = productdetails?.SKU + (variantcombo?.metal ? variantcombo?.metal?.value : '') + (variantcombo?.stone ? variantcombo?.stone?.value : '') + '-' + data?.value;
            setVariantcombo({ ...variantcombo, size: data });
        }
        if (variant_sku) {
            let variant = variantdata?.length ? variantdata.filter(e => e?.SKU === variant_sku)[0] : '0.00';
            document.getElementById("variantprice").textContent = variant?.price;
            document.getElementById("variantprice").setAttribute('data-caritemid', variant?._id);
            if (variant?.main_image) {
                let imagearray = [{
                    "original": variant?.main_image,
                    "thumbnail": variant?.main_image
                }];
                if (productdetails?.images?.length) {
                    const newArr2 = [...productdetails?.images];
                    newArr2[0] = imagearray[0];
                    setProductdetails({
                        ...productdetails,
                        images: newArr2
                    });
                }

            }
        }
    }
    const addtocart = () => {
        const itemid = document.getElementById("variantprice").getAttribute('data-caritemid');
        if (itemid) {
            let localitem = localStorage.getItem('CartItems') ? JSON.parse(localStorage.getItem('CartItems')) : [];
            let checkitem = localitem.filter(e => e?.id === itemid);
            if (checkitem?.length) {
                localitem.forEach((e) => {
                    if (e?.id === itemid) {
                        e.qty += parseInt(quantity);
                    }
                });
                localStorage.setItem('CartItems', JSON.stringify(localitem));
            } else {
                localitem.push({ 'id': itemid, 'qty': quantity });
                localStorage.setItem('CartItems', JSON.stringify(localitem));
            }
            setIsopennotifycart(true);

        }

    }
    useEffect(() => {
        if (state?.id || parts[2]) {
            getproductdetails();
            getvarient()
        } else {
            navigate("/");
        }
        // eslint-disable-next-line 
    }, [state?.id, vValue, parts[2]])

    const handleThubnailclick = (i) =>{
        setActiveThumbnail(i+1)
    }

  return (
    <>
    {
        loading ?
            (<div className=" w-100 vh-100 d-flex justify-content-center align-items-center" >
                <Spinner />
            </div >) : (


                <div className="ecommerce-main-layout productpage">
                    <Link className="productpage-header" to="/collection">
                        <LeftIcon/> <span>{t("Back to Main Collection")}</span>
                    </Link>
                    <div className="productpage-product-details">
                    <div className="productpage-product-details-image-section">
                            <ImageGallery
                                items={productdetails?.images ? productdetails?.images : []}
                                showPlayButton={false}
                                showFullscreenButton={false}
                                showNav={true}
                                infinite={true}
                                showThumbnails={true}
                                disableSwipe={false}
                                additionalClass="img-view-slider"
                                disableThumbnailSwipe={false}
                                onSlide={(e)=> setActiveThumbnail(e+1)}
                                onThumbnailClick={(e, index) => handleThubnailclick(index)}
                            />
                            <div className="productpage-product-details-image-section-progressbar">
                            <progress className="productpage-product-details-image-section-progressbar-container" max={productdetails?.images.length} value={activeThumbnail}></progress>
                            </div>
                        </div>
                        <div className="productpage-product-details-section">
                            <div className="productpage-product-details-section-product-details">
                                <div className="productpage-product-details-section-product-details-title">{t("Home")}&bull; <span className="name">{productdetails?.name}</span></div>
                                <div className={`productpage-product-details-section-product-details-name d-flex justify-content-between align-items-center ${productdetails?.isWishlist ? "likeHeartBtn" : ""}`}>
                                    {productdetails?.name}
                                    <div className="d-inline-block" onClick={() => setwishlist()}>
                                        <HeartIcon />
                                    </div>
                                </div>
                                <div className="productpage-product-details-section-product-details-collectionNameresponsive">
                                    {productdetails?.Matatag}
                                </div>
                                <span className="productpage-product-details-section-product-details-serialNumber">
                                    {productdetails?.SKU}
                                </span>
                                <div className="productpage-product-details-section-product-details-collectionName d-flex justify-content-between align-items-center">
                                    {productdetails?.Matatag}
                                </div>
                            </div>
                            <div className="productpage-product-details-section-product-metal">
                                   {t("Metal")}: <span>{productdetails?.Matatag}</span>
                            </div>
                            {productdetails?.metal?.length ?
                            <div className="productpage-product-details-section-product-colorthemewrapper">
                                  <div className={`black ${variantcombo?.metal?.value === '18KBK' ? 'active' : ''}`}  onClick={() => combination_variant('metal', {label: "18KBK",value: "18KBK"})}></div>
                                  <div className={`titanium ${variantcombo?.metal?.value === '18KWG' ? 'active' : ''}`} onClick={() => combination_variant('metal', {label: "18KWG",value: "18KWG"})}></div>
                                  <div className={`sunshine ${variantcombo?.metal?.value === '18KYG' ? 'active' : ''}`} onClick={() => combination_variant('metal', {label: "18KYG",value: "18KYG"})}></div>
                                  <div className={`peach ${variantcombo?.metal?.value === '18KRG' ? 'active' : ''}`} onClick={() => combination_variant('metal', {label: "18KRG",value: "18KRG"})}></div>
                            </div>
                            :""}
                            <span className="productpage-product-details-section-product-price " id="variantprice" data-caritemid={productdetails?._id} >
                                {productdetails?.price}
                            </span>
                            {varientloading ? (<div style={{ marginTop: '20px', marginBottom: '20px' }} >
                                <Spinner />
                            </div>) : (
                                <div>
                                    {/* {productdetails?.metal?.length ?
                                        <div className="productpage-product-details-section-product-attributes">
                                            <label>{t("Metal")}</label>
                                            <Select
                                                className="ecommerce-dropdown"
                                                options={productdetails?.metal}
                                                value={variantcombo?.metal}
                                                onChange={(e) => combination_variant('metal', e)}
                                                classNamePrefix="common-select"
                                                isSearchable={false}

                                            />
                                        </div>
                                        : ''} */}
                                    {productdetails?.stone?.length ?
                                        <div className="productpage-product-details-section-product-attributes">
                                            <label>{t("Stone")}</label>
                                            <Select
                                                className="ecommerce-dropdown"
                                                options={productdetails?.stone}
                                                value={variantcombo?.stone}
                                                onChange={(e) => combination_variant('stone', e)}
                                                classNamePrefix="common-select"
                                                isSearchable={false}

                                            />
                                        </div>
                                        : ""}

                                    {productdetails?.size?.length ?
                                        <>
                                            <div className="productpage-product-details-section-product-attributes">
                                                <div className="d-flex justify-content-between align-items-center my-3">
                                                    <label className="text-decoration-underline" data-bs-toggle="modal" data-bs-target="#sizeGuideBack">{t("Size Guide")}</label>
                                                    {/* <button  data-bs-toggle="modal" data-bs-target="#sizeGuideBack" className="size-guide">{t("Size Guide")} <span className="size-guide-imgBox"> <img src={ArroeIcontop} alt="" /></span></button> */}
                                                </div>
                                                <div className="productpage-product-details-section-product-attributes-ringSizes-container d-flex flex-wrap align-items-center">
                                                    {productdetails?.size?.length ? productdetails?.size.map((result, key) => {
                                                        return (
                                                            <span key={key} onClick={() => combination_variant('size', result)} className={variantcombo?.size?.label === result?.label ? "d-inline-flex align-items-center justify-content-center sizeselect selectedSize" : "d-inline-flex align-items-center justify-content-center sizeselect"}>
                                                                {result?.label}
                                                            </span>
                                                        )
                                                    }) : ""}
                                                </div>
                                            </div>
                                        </>
                                        : ""}
                                </div>
                            )}

                            <div className="productpage-product-details-section-product-attributes add_to_cart">
                                <div className="productpage-product-details-section-product-attributes-qutantityCounter">
                                    <button disabled={quantity > 1 ? false : true} onClick={() => { setQuantity(quantity - 1) }} className="minusCountBtn">-</button>
                                    <span className="d-inline-block text-center quantityShow">{quantity}</span>
                                    <button onClick={() => setQuantity(quantity + 1)} className="plusCountBtn">+</button>
                                </div>
                                <button disabled={!productdetails?.size?.length ? (!variantcombo?.size ? false : true) : (!variantcombo?.size ? true : false)}
                                onClick={addtocart} className="productpage-product-details-section-addToCartBtn">
                                {t("Add to Cart")}
                                </button>
                            </div>

                            <Link 
                               to='/appointment' className="productpage-product-details-section-appointmentbtn btnwithborder" state={{checkoutItems : [{id :  productdetails?._id, price: productdetails?.UnFormatPrice, qty : 1}]}}>
                                {t("Appointment")}
                            </Link>

                            <div className={`productpage-product-details-section-product-discription`}>
                                <div className="title" onClick={() => setShowDropdown(!showdropDown)}>
                                    <label>{t("Description")}</label>
                                </div>
                               <div className="productpage-product-details-section-product-discription-text">
                                    {productdetails?.Description}
                                </div>
                            </div>
                            <div className={`productpage-product-details-section-productdetails`}>
                                <div className="title" onClick={() => setShowDropdown(!showdropDown)}>
                                    <label>{t("Product Details")}</label>
                                </div>
                               <div className="productpage-product-details-section-productdetails-data">
                                    <div className="productpage-product-details-section-productdetails-data-item">
                                        <div className="tag">{t("Material")}:</div>
                                        <div className="value">{t(variantcombo?.metal?.value)}</div>
                                    </div>
                                    <div className="productpage-product-details-section-productdetails-data-item">
                                        <div className="tag">{t("Stone Weight")}:</div>
                                        <div className="value">{t(variantcombo?.stone?.value)}</div>
                                    </div>
                                    <div className="productpage-product-details-section-productdetails-data-item">
                                        <div className="tag">{t("Reference Number")}:</div>
                                        <div className="value">{t(`${productdetails?.SKU}`)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>

                    <div className="ecommerce-main-layout-collection">
                        <CollectionSlider title="Our Jewellery Collection"/>
                    </div>
                    <BottomBar withTitle={false}/>
                    <div className="ecommerce-main-layout-recent_view-product">
                        <RecentViewProducts currentid={state?.id}
                            setWishliatdata={setWishliatdata} wishlistdata={wishlistdata} />
                    </div>
                    <AddCartModal setisopencart={setisopencart} isopencart={isopencart} />
                     {isopennotifycart ? <CartItemsNotify isopennotifycart={isopennotifycart} setIsopennotifycart={setIsopennotifycart} setisopencart={setisopencart} /> : ''}
                    <SizeGuildeModal />
                </div >
            )}
</>
  )
}
              