import React, { useCallback, useEffect, useRef, useState } from 'react'
import './calendarlist.scss'
import { useTranslation } from 'react-i18next';
import Accordion from './Accordion';
import { MultipleUserIcon } from '../../common/icons/MultipleUserIconFilled';
import Customer from '../../common/icons/sidebar/Customer';
import SearchIcon from '../../common/icons/SearchIcon';
import CloseIcon from '../../common/icons/CloseIcon';
import CalendarFilter from '../CalendarFilter';
import { _Api, graphqlPost } from '../../api/_call';
import GreenTick from '../../common/icons/GreenTick';

export default function List({ showFilter, setShowFilter, handleShowFilter }) {
  const [applyfilter, setApplyfilter] = useState({ OrderType: [], Status: [], start_date: "", end_date: "", activedates: "" })

  // const itemsData = [
  //     { title: 'Repair Order', color: '#9FA0F2',content: [] },
  //     { title: 'Custom Order', color: '#FE918F' ,content: [] },
  //     { title: 'Reserved', color: '#FFCA64', content:[] },
  //     { title: 'Purchase Order', color: '#A9D6F2', content: [] },
  //     { title: 'Appointment', color: '#9EE0DB', content: [] },
  //   ];

  const { t } = useTranslation();
  const [showSubtasks, setShowSubtasks] = useState(false);
  const [activeSubtask, setActiveSubtask] = useState('Collapse all');
  const [activeCustomer, setActiveCustomer] = useState([]);
  const [customerPopup, setCustomerPopup] = useState(false);
  const [showSalePerson, setShowSalePerson] = useState(false);
  const [customerList, setCustomerList] = useState();
  const [activeSalePerson, setActiveSalePerson] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [searchCardValue, setSearchCardValue] = useState('')
  const [actualdata, setActualdata] = useState([]);
  const [salePersonList, setSalePersonList] = useState([]);
  const [items, setItems] = useState([]);
  const subtaskRef = useRef(null);
  const customerpopupRef = useRef(null);
  const salepersonRef = useRef(null);

  useEffect(() => {
    getData();
    getCustomerlist();
    getSalePersons();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    fiterbyFilterbar();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // Function to close popup when clicked outside
    const handleClickOutside = (event) => {
      if (subtaskRef.current && !subtaskRef.current.contains(event.target)) {
        setShowSubtasks(false);
      }
      if (customerpopupRef.current && !customerpopupRef.current.contains(event.target)) {
        setCustomerPopup(false);
      }
      if (salepersonRef.current && !salepersonRef.current.contains(event.target)) {
        setShowSalePerson(false);
      }
    };

    // Attach event listener to the document
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getData = useCallback(async () => {
    let postdata = {}
    try {
      let res = await _Api(postdata, `api/v1/App/calender/getCalender-orders-info`)
      if (res?.code === 200) {
        const newArray = [
          { title: 'Repair Order', color: '#9FA0F2', content: res?.Data?.RepairOrder },
          { title: 'Custom Order', color: '#FE918F', content: res?.Data?.CustomOrder },
          { title: 'Reserved', color: '#FFCA64', content: res?.Data?.ReservedOrder },
          { title: 'Purchase Order', color: '#A9D6F2', content: res?.Data?.PurchaseOrder },
          { title: 'Appointment', color: '#9EE0DB', content: res?.Data?.Appointment },
        ]
        setActualdata(newArray);
        setItems(newArray)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }, [])

  const getCustomerlist = useCallback(async () => {
    let postdata = {
      "search": ''
    }
    try {
      let res = await _Api(postdata, `api/v1/App/calender/getCalender-customer-info`)
      if (res?.code === 200) {
        setCustomerList(res?.Data);
      }
    }
    catch (err) {
      console.log(err, "err")
    }
    // eslint-disable-next-line
  }, [])

  const getSalePersons = useCallback(async () => {
    try {
      const postdata = `{ 
              Salespersons{
                  name
                  lname
                  logo
                  id
                  position
                    
              }
            }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      setSalePersonList(responce.data.Salespersons);
    } catch (err) {
      console.log(err, "err");
    }
  }, []);

  const handleSearchCard = (event) => {
    setSearchCardValue(event.target.value);
    let dummyarray = [...actualdata];
    const lowerCaseInput = event.target.value.toLowerCase();
    dummyarray = dummyarray.map(category => ({
      ...category,
      content: category.content.filter(order =>
        order.OrderNo.toLowerCase().includes(lowerCaseInput)
      )
    }))
      .filter(category =>
        category.title.toLowerCase().includes(lowerCaseInput) ||
        category.content.length > 0
      );

    setItems(prev => [...dummyarray])
  }

  const handleCustomer = useCallback((type, cid) => {
    if (type === 'saleperson') {
      setActiveSalePerson((prevActiveSaleperson) => {
        const isActive = prevActiveSaleperson.includes(cid);
        const updatedActiveSaleperson = isActive
          ? prevActiveSaleperson.filter(id => id !== cid)
          : [...prevActiveSaleperson, cid];
        if (updatedActiveSaleperson.length === 0) {
          setItems(prev => [...actualdata]);
        } else {
          // Call the API with the updated active customers list
          fetchCustomerData(type, updatedActiveSaleperson);
        }
        // Call the API with the updated active customers list

        return updatedActiveSaleperson;
      });
    } else {
      setActiveCustomer((prevActiveCustomers) => {
        const isActive = prevActiveCustomers.includes(cid);
        const updatedActiveCustomers = isActive
          ? prevActiveCustomers.filter(id => id !== cid)
          : [...prevActiveCustomers, cid];
        if (updatedActiveCustomers.length === 0) {
          setItems(prev => [...actualdata]);
        } else {
          // Call the API with the updated active customers list
          fetchCustomerData(type, updatedActiveCustomers);
        }
        // Call the API with the updated active customers list

        return updatedActiveCustomers;
      });
    }
  }, [actualdata]);

  const fiterbyFilterbar = useCallback(async () => {
    let postdata = {
      "sales_person_id": "",
      "customer_id": "",
      "search": "",
      "order_type": applyfilter.OrderType ? applyfilter.OrderType : [],
      "status": applyfilter.Status ? applyfilter.Status : [],
      "start_date": applyfilter?.start_date ? applyfilter?.start_date : "",
      "end_date": applyfilter?.end_date ? applyfilter?.end_date : "",
      "activedates" : applyfilter?.activedates ? applyfilter?.activedates : ""
    }
   
    try {
      let res = await _Api(postdata, `api/v1/App/calender/getCalender-orders-info`)
      if (res?.code === 200) {
        const dummy = [
          { title: 'Repair Order', color: '#9FA0F2', content: res?.Data.RepairOrder },
          { title: 'Custom Order', color: '#FE918F', content: res?.Data.CustomOrder },
          { title: 'Reserved', color: '#FFCA64', content: res?.Data.ReservedOrder },
          { title: 'Purchase Order', color: '#A9D6F2', content: res?.Data?.PurchaseOrder },
          { title: 'Appointment', color: '#9EE0DB', content: res?.Data?.Appointment },
        ];

        setItems(prev => [...dummy])
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }, [applyfilter])

  const fetchCustomerData = async (type, data) => {
    let postdata = {
      "sales_person_id": type === 'saleperson' ? data : "",
      "customer_id": type === 'customer' ? data : "",
      "search": "",
      "order_type": [],
      "status": [],
      "start_date": ""

    }
    try {
      let res = await _Api(postdata, `api/v1/App/calender/getCalender-orders-info`)
      if (res?.code === 200) {
        const dummy = [
          { title: 'Repair Order', color: '#9FA0F2', content: res?.Data.RepairOrder },
          { title: 'Custom Order', color: '#FE918F', content: res?.Data.CustomOrder },
          { title: 'Reserved', color: '#FFCA64', content: res?.Data.ReservedOrder },
          { title: 'Purchase Order', color: '#A9D6F2', content: res?.Data?.PurchaseOrder },
          { title: 'Appointment', color: '#9EE0DB', content: res?.Data?.Appointment },
        ];

        setItems(prev => [...dummy])
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  };

  const handleClearFilters = () => {
    setActiveCustomer(prev => []);
    setItems(actualdata)
    setActiveSubtask('');
    setActiveSalePerson([]);
  }

  const handleSelectAll = (type) => {
    let dummy = []
    if (type === 'customer') {
      customerList.map((item) => dummy.push(item._id));
      setActiveCustomer(dummy)
    } else {
      salePersonList.map((item) => dummy.push(item.id));
      setActiveSalePerson(dummy)
    }
    fetchCustomerData(type, dummy)
  }

  const handleclosefilter = () => {
    if (showFilter) {
      handleShowFilter();
    }
  }

  const applyFiltersonList = () =>{

  }
  useEffect(() => {
    fiterbyFilterbar();
  }, [applyfilter])
  

  return (
    <div className='calendarlist'>
      <div className='calendarlist-headbar'>
        <div className='calendarlist-headbar-left'>
          <span className='calendarlist-headbar-left-title'>{t("Smart Filters")}:</span>
          <div className='position-relative' ref={salepersonRef}>
            <div className={`calendarlist-headbar-left-group ${activeSalePerson.length > 0 ? 'active' : ''}`} onClick={() => { setShowSalePerson(!showSalePerson); handleclosefilter() }}>
              <span className='calendarlist-headbar-left-group-salepersonicon'><Customer color={activeSalePerson.length > 0 ? "#52CBC4" : "#A0A0A0"} stroke="1" /></span>
              <span>{t("Sale person")}</span>
            </div>
            {showSalePerson ?
              <div className='calendarlist-headbar-left-customerpopup'>
                <div className='calendarlist-headbar-left-customerpopup-inner'>
                  <div className='calendarlist-headbar-left-customerpopup-inner-title'>
                    <div className='calendarlist-headbar-left-customerpopup-inner-title-tag'>{t('Quick customer filter')}</div>
                    <div className='calendarlist-headbar-left-customerpopup-inner-title-options' onClick={() => handleSelectAll('saleperson')}>
                      <span>{t("Select All")}</span>
                    </div>
                  </div>
                  <div className='calendarlist-headbar-left-customerpopup-inner-subtitle'>
                    {t('Filter items and subitems by customers')}
                  </div>
                  <div className='calendarlist-headbar-left-customerpopup-inner-list'>
                    {salePersonList?.map((saleperson, i) => {
                      return (
                        <div className='calendarlist-headbar-left-customerpopup-inner-list-user' key={i} onClick={() => handleCustomer('saleperson', saleperson?.id)}>
                          <div className='calendarlist-headbar-left-customerpopup-inner-list-user-left'>
                            <img src={saleperson?.logo} alt='img' />
                            <span>{saleperson?.name} {" "} {saleperson?.lname}</span>
                          </div>
                          {activeSalePerson.includes(saleperson?.id) ?
                            <div>
                              <GreenTick />
                            </div>
                            : ""}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              : ""
            }
          </div>
          {
            <div className='position-relative' ref={customerpopupRef}>
              <div className={`calendarlist-headbar-left-group ${activeCustomer.length > 0 ? 'active' : ''}`} onClick={() => { setCustomerPopup(!customerPopup); handleclosefilter() }}>
                <MultipleUserIcon color={activeCustomer.length > 0 ? "#52CBC4" : "#A0A0A0"} />
                <span>{t("Customer")}</span>
              </div>
              {customerPopup ?
                <div className='calendarlist-headbar-left-customerpopup'>
                  <div className='calendarlist-headbar-left-customerpopup-inner'>
                    <div className='calendarlist-headbar-left-customerpopup-inner-title'>
                      <div className='calendarlist-headbar-left-customerpopup-inner-title-tag'>{t("Quick customer filter")}</div>
                      <div className='calendarlist-headbar-left-customerpopup-inner-title-options' onClick={() => handleSelectAll('customer')}>
                        <span>{t("Select All")}</span>
                      </div>
                    </div>
                    <div className='calendarlist-headbar-left-customerpopup-inner-subtitle'>
                      {t("Filter items and subitems by customers")}
                    </div>
                    <div className='calendarlist-headbar-left-customerpopup-inner-list'>
                      {customerList?.map((customer, i) => {
                        return (
                          <div className='calendarlist-headbar-left-customerpopup-inner-list-user' key={i} onClick={() => handleCustomer('customer', customer?._id)}>
                            <div className='calendarlist-headbar-left-customerpopup-inner-list-user-left'>
                              <img src={customer?.profile} alt='img' />
                              <span>{customer?.name}</span>
                            </div>
                            {activeCustomer.includes(customer?._id) ?
                              <div>
                                <GreenTick />
                              </div>
                              : ""}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                : ""
              }
            </div>
          }
          <div className='position-relative' ref={subtaskRef}>
            <div className={`calendarlist-headbar-left-subtasks ${activeSubtask === 'Expand all' ? 'active' : ''}`} onClick={() => { setShowSubtasks(!showSubtasks); handleclosefilter() }}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 13C17.1046 13 18 12.1046 18 11C18 9.89543 17.1046 9 16 9C14.8954 9 14 9.89543 14 11C14 12.1046 14.8954 13 16 13Z" stroke={activeSubtask === 'Expand all' ? '#52CBC4' : "#A0A0A0"} />
                <path d="M16 21C17.1046 21 18 20.1046 18 19C18 17.8954 17.1046 17 16 17C14.8954 17 14 17.8954 14 19C14 20.1046 14.8954 21 16 21Z" stroke={activeSubtask === 'Expand all' ? '#52CBC4' : "#A0A0A0"} />
                <path d="M5 3C3.89543 3 3 3.89543 3 5C3 6.10457 3.89543 7 5 7C6.10457 7 7 6.10457 7 5C7 3.89543 6.10457 3 5 3Z" stroke={activeSubtask === 'Expand all' ? '#52CBC4' : "#A0A0A0"} />
                <path d="M5 8V15C5 16.8856 5 17.8284 5.58579 18.4142C6.17157 19 7.11438 19 9 19H14" stroke={activeSubtask === 'Expand all' ? '#52CBC4' : "#A0A0A0"} />
                <path d="M5 7C5 8.88562 5 9.82843 5.58579 10.4142C6.17157 11 7.11438 11 9 11H14" stroke={activeSubtask === 'Expand all' ? '#52CBC4' : "#A0A0A0"} />
              </svg>
              <span>{t("Subtasks")}</span>
            </div>
            {showSubtasks ?
              <div className='calendarlist-headbar-left-subtaskdropdown'>
                <div className='calendarlist-headbar-left-subtaskdropdown-inner'>
                  <div className='calendarlist-headbar-left-subtaskdropdown-inner-title'>{t("Show Subtasks")}</div>
                  <div className='calendarlist-headbar-left-subtaskdropdown-inner-list'>
                    <div className='calendarlist-headbar-left-subtaskdropdown-inner-list-item' onClick={() => { setActiveSubtask('Collapse all'); setShowSubtasks(!showSubtasks) }}>
                      <div className='heading'>
                        {t("Collapse all")}
                        <span className='default'>{t("(default)")}</span>
                      </div>
                      {activeSubtask === 'Collapse all' ?
                        <div>
                          <GreenTick />
                        </div>
                        : ""}
                    </div>
                    <div className='calendarlist-headbar-left-subtaskdropdown-inner-list-item' onClick={() => { setActiveSubtask('Expand all'); setShowSubtasks(!showSubtasks) }}>
                      <div>{t("Expand all")}</div>
                      {activeSubtask === 'Expand all' ?
                        <div>
                          <GreenTick />
                        </div>
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
              : ""}
          </div>
          {activeCustomer.length > 0 || activeSubtask === 'Expand all' || activeSalePerson.length > 0 ?
            <div className='calendarlist-headbar-left-clearfilter' onClick={handleClearFilters}>
              {t("Clear Filters")}
            </div> : ""
          }
        </div>
        <div className={`calendarlist-headbar-right ${showSearch ? 'active' : ''}`} >
          {!showSearch ?
            <div className='calendarlist-headbar-right-search' onClick={() => setShowSearch(!showSearch)}>
              <span className='calendarlist-headbar-right-search-icon'><SearchIcon /></span>
              <span>Search</span>
            </div>
            :
            <div className='calendarlist-headbar-right-searchbar'>
              <input className='calendarlist-headbar-right-searchbar-input' type='text' placeholder='Search' value={searchCardValue} onChange={handleSearchCard} />
              <span className='calendarlist-headbar-right-searchbar-input-searchicon'><SearchIcon /></span>
              <span className='calendarlist-headbar-right-searchbar-input-closeicon' onClick={() => { setShowSearch(!showSearch); setSearchCardValue(""); setItems(actualdata) }}><CloseIcon /></span>
            </div>
          }
        </div>
      </div>
      <div className='calendarlist-container'>
        {items.length > 0 ?
          items.map((item, i) => {
            return (
              <Accordion item={item} index={i} activeSubtask={activeSubtask} key={i} />
            )
          })
          :
          <div className='loader-holder'>
            <div id="dark_loader" className="mt-4">
              <div className="dots">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        }

      </div>
      {showFilter ?
        <div> <CalendarFilter handleShowFilter={handleShowFilter} setShowFilter={setShowFilter} setApplyfilter={setApplyfilter} applyfilter={applyfilter} />
        </div> : ""}
    </div>
  )
}


