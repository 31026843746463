import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { _Api } from '../common/api/_call'
import CustomerSearchModal from '../common/module/customerSearch'
import { Saleperson } from '../common/graphql/commonlayouts'
import 'react-datepicker/dist/react-datepicker.css'
import '../catalog/catalog.scss'
import EmailModal from "../common/Modals/EmailModal"
import { store } from '../../source/index'
import { success, error } from '../common/helpers/toastify'
import './checkout.scss'
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import Select from 'react-select'
import { updateCustomer, updateSaleperson } from "../../source/app/appSlice";
import { useDispatch, useSelector } from 'react-redux'
import { toFormatPrice, toUnformatPrice } from '../common/helpers/function'
import Servicelabourmodal from "../common/Modals/Servicelabourmodal"
import addressicon from '../../assets/images/pos/icons/script.png'
import AddCustomerAddressPopup from './addCustomerPopup/AddCustomerAddressPopup';
import CustomerInformation from '../../admin/customers/customerInformation/CustomerInformation'
import notaddressicon from '../../assets/images/pos/icons/scriptnot.png'
const CheckoutSell = (props) => {
  const { t } = useTranslation();
  let state = store.getState()
  const dispatch = useDispatch();
  const [toggleAddressCustomer, setToggleAddressCustomer] = useState(false);
  let OrgSettings = state?.app?.posorganisation_settings
  const generalsettings = useSelector((state) => state.app.generalsettings)
  const {
    setShowcheckout,
    setUpdatedCart,
    setdeletestatus,
    setShowpayment,
    showpayment,
    UpdatedCart,
    searchproductstatus,
    setsearchproductstatus,
    parkid,
    order_id,
    ordertype,
    order_edit,
    order_type,
    mixmatchedit,
    by_custom_order,
    reserve_status,
    by_repair_order,
    stockid,
    inv_orderid,
    inv_ordertype,
    customsecrive,
    setCustomcart,
    scrvicelistmixmatch,
    getproducts,
    setStockid
  } = props
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  let navigate = useNavigate()
  const location = useLocation()
  const [cusid, setCusid] = useState();
  const [showorder, setShoworder] = useState(false);
  const [otpnumber, setOtpnumber] = useState([]);
  const [checkoutdetails, setcheckoutdetails] = useState();
  const [parksentence, setParksentence] = useState('');
  const [quatesentence, setQuatesentence] = useState('');
  const [loader, setLoader] = useState(false);
  const [deletecartids, setDeletecartids] = useState([]);
  const customer = useSelector((state) => state.app.customer)
  const saleperson = useSelector((state) => state.app.saleperson)
  const [cartdetails, setCartdetails] = useState([]);
  const [cartdelete, setCartdelete] = useState()
  const [service_enable, setService_enable] = useState(false)
  const [scrviceremark, setScrviceremark] = useState("")
  const [service_saved, setService_saved] = useState(false)
  const [servicelabourlist, setServicelabourlist] = useState([])
  const [showmodal, setShowmodal] = useState(false)
  const [scrvicelist, setScrvicelist] = useState([])
  const [editservicelaboure, setEditservicelaboure] = useState([])
  const [scrviceedit, setScrviceedit] = useState(false)
  const [scrvicecharge, setScrvicecharge] = useState()
  const [cart_id, setCart_id] = useState()
  const [product_price, setProduct_price] = useState()
  const [oldorderid, setOldorderid] = useState()
  const status_type = cartdetails?.length ? cartdetails[0]?.type : ''
  const [taxes, setTaxes] = useState({
    prect: UpdatedCart?.sell_info?.summary_order?.tax_prect
      ? UpdatedCart?.sell_info?.summary_order?.tax_prect
      : 0,
    amount: UpdatedCart?.sell_info?.summary_order?.tax_amount
      ? UpdatedCart?.sell_info?.summary_order?.tax_amount
      : 0,
    type: UpdatedCart?.sell_info?.summary_order?.tax_type
      ? UpdatedCart?.sell_info?.summary_order?.tax_type
      : '',
    taxintamount: UpdatedCart?.sell_info?.summary_order?.tax_amount_int
      ? UpdatedCart?.sell_info?.summary_order?.tax_amount_int
      : 0
  })
  const [discount, setDiscount] = useState({
    prect: UpdatedCart?.sell_info?.summary_order?.discount_percent
      ? UpdatedCart?.sell_info?.summary_order?.discount_percent
      : 0,
    amount: UpdatedCart?.sell_info?.summary_order?.discount
      ? UpdatedCart?.sell_info?.summary_order?.discount
      : 0,
  })
  const [pricelabour, setPricelabour] = useState(
    parseFloat(
      UpdatedCart?.sell_info?.summary_order?.labour
        ? UpdatedCart?.sell_info?.summary_order?.labour
        : 0,
    ),
  )
  const [priceshipping, setPriceshipping] = useState(
    UpdatedCart?.sell_info?.summary_order?.shipping
      ? UpdatedCart?.sell_info?.summary_order?.shipping
      : 0,
  )
  const [priceloyality, setPriceloyality] = useState(
    UpdatedCart?.sell_info?.summary_order?.loyality_points
      ? UpdatedCart?.sell_info?.summary_order?.loyality_points
      : 0,
  )
  const [depositprect, setDepositprect] = useState({
    label: UpdatedCart?.sell_info?.summary_order?.deposit
      ? UpdatedCart?.sell_info?.summary_order?.deposit + '%'
      : cartdetails?.length ? cartdetails[0]?.type === 'pos_order' || cartdetails[0]?.type === 'gift_card_order' || cartdetails[0]?.type === 'deposit' ? '0%' : '10%' : '10%',
    value: UpdatedCart?.sell_info?.summary_order?.deposit
      ? UpdatedCart?.sell_info?.summary_order?.deposit
      : cartdetails?.length ? cartdetails[0]?.type === 'pos_order' || cartdetails[0]?.type === 'gift_card_order' || cartdetails[0]?.type === 'deposit' ? 0 : 10 : 10,
  })
  const [TransactionDate, setTransactionDate] = useState(new Date());
  let mainstatustype = cartdetails?.length ? cartdetails[0]?.type : ''
  const depositoptionarray = [
    {
      label: '0%',
      value: 0,
    },
    {
      label: '10%',
      value: 10,
    },
    {
      label: '25%',
      value: 25,
    },
    {
      label: '50%',
      value: 50,
    },
    {
      label: '75%',
      value: 75,
    },
    {
      label: '100%',
      value: 100,
    },
  ]

  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const parts = location.pathname.split('/');
  const selectTransactionDate = (input) => {
    setTransactionDate(input)
  }
  const GetCartdetail = async (order_edit, order_type, order_id) => {
    try {
      const postdata = {
        "mixmatchedit": mixmatchedit,
        "order_type": inv_ordertype ? inv_ordertype : order_type,
        "order_edit": order_edit,
        "order_id": inv_orderid ? inv_orderid : order_id,
        "by_custom_order": by_custom_order
      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/getCartItems`)
      if (res.code === 200) {
        if ((order_edit && order_type && order_id) || (inv_orderid && (inv_ordertype === "custom_order" || inv_ordertype === "repair_order"))) {
          if (inv_ordertype === "custom_order" || inv_ordertype === "repair_order") {
            if (inv_ordertype === "custom_order") {
              setOldorderid(res?.data?.sell_info?.old_custom_order_id)
            }
            setCartdetails(res?.data?.sell_info?.cart);
          } else {
            setCartdetails(UpdatedCart?.sell_info?.cart)
            setUpdatedCart(res?.data);
            setShowcheckout('sell');
          }
          let sum = 0;
          res?.data?.sell_info?.cart.forEach(item => {
            if (item?.Service_labour?.service_laburelist?.length) {
              item?.Service_labour?.service_laburelist.forEach(service => {
                sum += service?.scrviceamount;
              });
            }
          });
          setPricelabour(sum)
          setPriceshipping(res?.data?.sell_info?.summary_order?.shipping)
          setPriceloyality(res?.data?.sell_info?.summary_order?.loyality_points)
          setDiscount(previousInputs => ({ ...previousInputs, prect: (parseInt(res?.data?.sell_info?.summary_order?.discount_percent)) }))
          setDiscount(previousInputs => ({ ...previousInputs, amount: (parseInt(res?.data?.sell_info?.summary_order?.discount)) }))
          setTaxes(previousInputs => ({ ...previousInputs, prect: (parseInt(res?.data?.sell_info?.summary_order?.tax_prect)) }))
          setTaxes(previousInputs => ({ ...previousInputs, amount: (parseInt(res?.data?.sell_info?.summary_order?.tax_amount)) }))
          setCartdelete(res?.data?.sell_info?.summary_order)
          setDepositprect(previousInputs => ({ ...previousInputs, label: (res?.data?.sell_info?.summary_order?.deposit + "%") }))
          setDepositprect(previousInputs => ({ ...previousInputs, value: (res?.data?.sell_info?.summary_order?.deposit) }))
          setsearchproductstatus(true);
        } else {
          setCartdetails(res.data);
          setsearchproductstatus(false);
          let total = 0;
          for (var i = 0; i < res?.data?.length; i++) {
            if (res?.data[i]?.Service_labour_total_charges) {

              total = total + res?.data[i].Service_labour_total_charges;
            }
            setPricelabour(total)
          }
          if (res?.data?.length) {
            res?.data.forEach((result, key) => {
              if (ordertype === 'mix_and_match') {
                setCustomcart(result?.custom_cart_id)
              }
            }
            )

          }

        }
        if (res?.data?.sell_info?.cart?.length) {
          res?.data?.sell_info?.cart?.forEach((result, key) => {
            setService_enable(result?.Service_labour_exsist)
          }
          )

        }
      } else {
        navigate('/app/pos/custom/createYourOwn')
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }


  const deletereserve = async () => {
    if (cartdetails.length) {
      cartdetails.forEach((result, key) => {
        if (stockid === result?.stock_id) {
          deletecart(result?.custom_cart_id, result?.amount)
        }
      })
    }
  }

  useEffect(() => {
    if (stockid) {
      deletereserve()
    }
    // eslint-disable-next-line
  }, [stockid])
  const deletecart = async (cartid, transaction_type, amount, sessioncartid) => {
    if ((ordertype === 'exchange_order' || ordertype === 'refund_order' || ordertype === 'Quotation') && !order_edit) {
      setCartdetails((current) =>
        current.filter((item) => item?.custom_cart_id !== cartid)
      );
      setShowcheckout('blank');
    }
    else {
      if (!customer?.id) {
        error(t('Customer is Requried!!'))
        return
      }
      try {
        const postdata = {
          custom_cart_id: cartid,
          customer_id: customer?.id,
          park_id: parkid,
        }
        let res = await _Api(postdata, `api/v1/POS/customOrder/deleteCartItem`)
        if (res.code === 200) {
          setDeletecartids(previousInputs => ([...previousInputs, cartid]));
          if (ordertype === 'deposit') {
            setdeletestatus({ status: true, type: transaction_type, amount: amount })
          } else {
            setdeletestatus(true);
          }
          if (ordertype !== 'exchange_order' && ordertype !== 'refund_order') {
            if (order_edit && order_type && order_id) {
              GetCartdetail(order_edit, order_type, order_id)
            } else {
              GetCartdetail()
            }
          }
          if (cartdetails?.length === 1) {
            if (ordertype === "custom_order") {
              navigate('/app/pos/custom/createYourOwn', {
                state: { order_id: order_id, order_edit: order_edit, order_type: order_type },
              })
            } else if (ordertype === "mix_and_match") {
              navigate("/app/pos/custom/mixAndMatch");
            } else if (ordertype === "pos_order") {
              navigate("/app/pos/catalog/CollectionList")
            } else if (ordertype === "repair_order") {
              navigate("/app/pos/repair")
            } else if (ordertype === "reserve") {
              navigate("/app/pos/reserve")
            } else if (ordertype === 'exchange_order' || ordertype === 'refund_order' || ordertype === 'gift_card_order') {
              setShowcheckout('blank');
            }
            setShowcheckout('blank');
          } else if (ordertype === "reserve") {
            if (cartdetails.length) {
              cartdetails.forEach((result, key) => {
                if (cartid === result?.custom_cart_id) {
                  setStockid(result?.stock_id)
                }
              })
            }
          }
        }
      } catch (err) {
        console.log(err, 'err')
      }
    }
  }

  const changeQty = (i, label, po_QTY) => {
    let array = [...cartdetails]
    if (label === 'add') {
      if (ordertype === "custom_order") {
        array[i] = {
          ...array[i],
          'Qty': (array[i]['Qty'] ? array[i]['Qty'] + 1 : array[i]['Qty']),
        }
      } else if (ordertype === "repair_order" || ordertype === "refund_order") {
        array[i] = {
          ...array[i],
          'Qty': (po_QTY > array[i]['Qty'] ? array[i]['Qty'] + 1 : array[i]['Qty']),
        }
      } else {
        array[i] = {
          ...array[i],
          'Qty': (po_QTY > array[i]['Qty'] ? array[i]['Qty'] + 1 : array[i]['Qty']),
        }
      }

    } else {
      if (array[i]['Qty'] > 1) {
        array[i] = {
          ...array[i],
          'Qty': array[i]['Qty'] - 1,
        }
      }
    }
    setCartdetails(array)
  }
  const changeprice = (i, value, name, qty) => {
    let array = [...cartdetails]
    if (name === "price") {
      array[i]['discount_percent'] = 0;
      array[i]['Discount_Amount'] = 0;
      array[i] = {
        ...array[i],
        [name]: value,
      }
    }
    else if (name === "stock_id") {
      array[i]['po_QTY'] = qty;
    } else if (name === "delivery_date") {
      if (value.getTime() >= new Date().getTime()) {
        array[i]["delivery_date"] = new Date(value)
      }

    }

    setCartdetails(array)
  }

  const changediscount = (lable, value) => {
    let total = cartdetails?.length
      ? cartdetails?.reduce(
        (interation, val) =>
          (interation = interation + parseInt(val.Qty) * parseFloat(val?.price)),
        0,
      )
      : 0
    if (lable === 'discountprecent') {
      let precent = (total * value) / 100
      setDiscount((existingValues) => ({ ...existingValues, prect: parseFloat(value * 100) / 100 }))
      setDiscount((existingValues) => ({ ...existingValues, amount: precent }))
    } else if (lable === 'discountamount') {
      let amount = (value / total) * 100
      setDiscount((existingValues) => ({ ...existingValues, prect: parseFloat((amount * 100).toFixed(2)) / 100 }))
      setDiscount((existingValues) => ({ ...existingValues, amount: value }))
    } else {
      let precent = (discount?.amount * 100) / total;
      let amount = discount?.amount
      setDiscount((existingValues) => ({ ...existingValues, prect: parseFloat(precent) }))
      setDiscount((existingValues) => ({ ...existingValues, amount: amount }))
    }
  }

  const changecartdiscount = (value, key, lable) => {
    let array = [...cartdetails];
    let price = array[key]['retailprice_Inc'];
    if (lable === 'discountprecent') {
      if (value <= 100) {
        let precent = (price * value) / 100;
        let finalprice = price - precent;
        array[key] = {
          ...array[key],
          'discount_percent': Math.floor(value * 100) / 100,
          'Discount_Amount': precent,
          'price': finalprice
        }
        setCartdetails(array);
      } else {
        error(t("Discount Tax should be less than 100"));
      }
    } else {
      if (value <= price) {
        let amount = (value / price) * 100;
        let finalprice = price - value;
        array[key] = {
          ...array[key],
          'discount_percent': Math.floor(amount * 100) / 100,
          'Discount_Amount': value,
          'price': finalprice
        }
        setCartdetails(array);
      } else {
        error(t("Discount Amount should be less than") + price);
      }

    }
  }
  const changeTax = (lable, taxvalue) => {
    let type = OrgSettings?.tax?.type
    let value = taxvalue ? parseFloat(taxvalue) : ''
    let sub_totalAmount = cartdetails?.length
      ? cartdetails.reduce(
        (interation, val) =>
          (interation = interation + parseInt(val.Qty) * parseInt(val?.price)),
        0,
      ) + parseInt(pricelabour + priceshipping + priceloyality - discount?.amount)
      : 0
    if (ordertype === 'exchange_order' || ordertype === 'refund_order' || ordertype === 'deposit' || status_type === "custom_order") {
      setTaxes({
        prect: 0,
        amount: 0,
        type: '',
        taxintamount: 0
      })
    } else {
      if (lable === 'default' || lable === 'taxprecent') {
        if (type === 'Inclusive') {
          let TaxAmounts = (value * sub_totalAmount) / (100 + value)
          setTaxes({
            prect: value ? value : 0,
            amount: parseFloat(TaxAmounts).toFixed(2),
            type: 'Inclusive',
            taxintamount: parseFloat(TaxAmounts)
          })
        } else if (type === 'Exclusive') {
          let TaxAmounts = (value * sub_totalAmount) / 100
          setTaxes({
            prect: value ? value : 0,
            amount: parseFloat(TaxAmounts).toFixed(2),
            type: 'Exclusive',
            taxintamount: parseFloat(TaxAmounts)
          })
        }
      }
    }

  }
  const addclasstocart = (key) => {
    let array = [...cartdetails]
    if (array[key]?.addclass) {
      array[key] = { ...array[key], addclass: false }
    } else {
      array[key] = { ...array[key], addclass: true }
    }
    setCartdetails(array)
  }

  const callpaymentcheckout = async () => {
    if (!customer?.id) {
      error(t("Customer is Requried!!"));
      return;
    }
    if (!saleperson?.value) {
      error(t("SalePerson is Requried!!"));
      return;
    }
    let carts = [...cartdetails]


    let deposit_details = { cash: 0, credit_card: 0, bank: 0 }
    if (ordertype === 'deposit') {
      let allcash = 0;
      let allcreditcard = 0;
      let allbank = 0;
      if (carts?.length) {
        carts.forEach((result) => {
          if (result?.transaction_type === 'cash') {
            allcash = parseFloat(allcash) + parseFloat(result?.price)
          }
          if (result?.transaction_type === 'creditcard') {
            allcreditcard = parseFloat(allcreditcard) + parseFloat(result?.price)
          }
          if (result?.transaction_type === 'bank') {
            allbank = parseFloat(allbank) + parseFloat(result?.price)
          }
        })
      }
      deposit_details = { cash: allcash, credit_card: allcreditcard, bank: allbank }
    }

    let totalamount =
      UpdatedCart?.sell_info?.summary_order?.checkOutAmount ? UpdatedCart?.sell_info?.summary_order?.checkOutAmount :
        ordertype === "repair_order" ?
          cartdetails?.reduce(
            (interation, val) =>
              parseFloat(interation = interation + val?.price), 0,
          ) +
          parseFloat(
            pricelabour +
            priceshipping +
            priceloyality -
            discount?.amount +
            (taxes?.type !== 'Inclusive' ? taxes?.taxintamount : ''),
          ) :
          cartdetails?.reduce(
            (interation, val) =>
              (interation = interation + parseFloat(val.Qty) * val?.price),
            0,
          ) +
          parseFloat(
            pricelabour +
            priceshipping +
            priceloyality -
            discount?.amount +
            (taxes?.type !== 'Inclusive' ? taxes?.taxintamount : ''),
          )
    let depositamount =
      ordertype === "repair_order" ?
        (cartdetails?.reduce(
          (interation, val) =>
          (interation =
            interation + toUnformatPrice(val?.price)),
          0,
        ) +
          parseFloat(
            pricelabour +
            priceshipping +
            priceloyality -
            discount?.amount +
            (taxes?.type !== 'Inclusive' ? taxes?.taxintamount : ''),
          )) *
        (depositprect?.value / 100)
        :
        (cartdetails?.reduce(
          (interation, val) =>
          (interation =
            interation + parseFloat(val.Qty) * toUnformatPrice(val?.price)),
          0,
        ) +
          parseFloat(
            pricelabour +
            priceshipping +
            priceloyality -
            discount?.amount +
            (taxes?.type !== 'Inclusive' ? taxes?.taxintamount : ''),
          )) *
        (depositprect?.value / 100)
    let subtotal =
      ordertype === "repair_order" ?
        cartdetails?.reduce(
          (interation, val) =>
          (interation =
            interation +
            toUnformatPrice(val?.price)),
          0,
        ) +
        parseFloat(
          pricelabour +
          priceshipping +
          priceloyality -
          discount?.amount -
          (taxes?.type === 'Inclusive' ? taxes?.taxintamount : ''),
        )
        :
        cartdetails?.reduce(
          (interation, val) =>
          (interation =
            interation +
            parseFloat(val.Qty) * toUnformatPrice(val?.price)),
          0,
        ) +
        parseFloat(
          pricelabour +
          priceshipping +
          priceloyality -
          discount?.amount -
          (taxes?.type === 'Inclusive' ? taxes?.taxintamount : ''),
        )


    let summaryorder = {
      labour: pricelabour,
      shipping: priceshipping,
      loyality_points: priceloyality,
      customer_id: customer,
      sales_person_id: saleperson,
      discount: discount?.amount,
      discount_percent: discount?.prect,
      deposit: status_type === 'pos_order' || ordertype === 'gift_card_order' || ordertype === 'deposit' || reserve_status === 0 ? 0 : depositprect?.value,
      deposit_amount: status_type === 'pos_order' || ordertype === ' ' || ordertype === 'deposit' ? 0 : depositamount,
      tax_amount: taxes?.amount,
      tax_amount_int: taxes?.taxintamount,
      tax_prect: taxes?.prect,
      tax_type: taxes?.type,
      Sub_Total: subtotal
    }
    try {
      const postdata = {}
      const credit_notepostdata = {
        customer_id: customer?.id
      }
      let res = await _Api(
        postdata,
        `api/v1/POS/checkout/payment/getCashMethodList`,
      )
      let res_credit_note = await _Api(
        credit_notepostdata,
        `api/v1/POS/checkout/creditnote/getCreditNote`,
      )
      if (res.code === 200) {
        setUpdatedCart({
          sell_info: {
            cart: carts,
            deletecart_ids: deletecartids,
            summary_order: summaryorder,
            status_type: reserve_status === 0 ? "pos_order" : status_type ? status_type : mainstatustype ? mainstatustype : ordertype,
            totalamount: parseFloat(totalamount),
            park_id: parkid,
            order_id: order_id,
            service_labour: editservicelaboure,
            old_custom_order_id: oldorderid
          },
          service_enable: service_enable,

          totalamount: status_type === 'pos_order' || ordertype === 'gift_card_order' || ordertype === 'deposit' || reserve_status === 0 ? totalamount : parseFloat(depositamount),
          ordertype: ordertype,
          deposit_details: ordertype === 'deposit' ? deposit_details : '',
          payment_Options: res?.data,
          credit_note_options: res_credit_note?.data,
          oldcredit_note_options: res_credit_note?.data,
        })
        setShowcheckout('payment')
        setShowpayment(true)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const callpark = async () => {
    if (!customer?.id) {
      error(t("Customer is Requried!!"));
      return;
    }
    if (!saleperson?.value) {
      error(t("SalePerson is Requried!!"));
      return;
    }
    let carts = [...cartdetails]
    let totalamount =
      cartdetails?.reduce(
        (interation, val) =>
          (interation = interation + parseInt(val.Qty) * val?.price),
        0,
      ) +
      parseFloat(
        pricelabour +
        priceshipping +
        priceloyality -
        discount?.amount +
        (taxes?.type !== 'Inclusive' ? taxes?.taxintamount : ''),
      )

    let depositamount =
      (cartdetails?.reduce(
        (interation, val) =>
        (interation =
          interation + parseInt(val.Qty) * toUnformatPrice(val?.price)),
        0,
      ) +
        parseFloat(
          pricelabour +
          priceshipping +
          priceloyality -
          discount?.amount +
          (taxes?.type !== 'Inclusive' ? taxes?.taxintamount : ''),
        )) *
      (depositprect?.value / 100)
    let subtotal = cartdetails?.reduce(
      (interation, val) =>
      (interation =
        interation +
        parseInt(val.Qty) * toUnformatPrice(val?.price)),
      0,
    ) +
      parseFloat(
        pricelabour +
        priceshipping +
        priceloyality -
        discount?.amount -
        (taxes?.type === 'Inclusive' ? taxes?.taxintamount : ''),
      )

    let summaryorder = {
      labour: pricelabour,
      shipping: priceshipping,
      loyality_points: priceloyality,
      customer_id: customer,
      sales_person_id: saleperson,
      discount: discount?.amount,
      discount_percent: discount?.prect,
      deposit: status_type === 'pos_order' || ordertype === 'gift_card_order' || ordertype === 'deposit' ? '0' : depositprect?.value,
      deposit_amount: status_type === 'pos_order' || ordertype === 'gift_card_order' || ordertype === 'deposit' ? '0' : depositamount,
      tax_amount: taxes?.amount,
      tax_amount_int: taxes?.taxintamount,
      tax_prect: taxes?.prect,
      tax_type: taxes?.type,
      Sub_Total: subtotal
    }
    try {
      const postdata = {
        sell_info: {
          cart: carts,
          summary_order: summaryorder,
          status_type: status_type ? status_type : mainstatustype ? mainstatustype : ordertype,
          totalamount: totalamount,
        },
        totalamount: status_type === 'pos_order' || ordertype === 'gift_card_order' || ordertype === 'deposit' ? totalamount : parseFloat(depositamount),
        parktime: parksentence,
      }
      let res = await _Api(
        postdata,
        `api/v1/POS/park/savepark`,
      )
      if (res.code === 200) {
        setShowcheckout('blank');
      } else {
        error((res?.message));
      }
    } catch (err) {
      console.log(err, 'err')
    }


  }
  const savequte = async () => {
    if (!customer?.id) {
      error(t("Customer is Requried!!"));
      return;
    }
    if (!saleperson?.value) {
      error(t("SalePerson is Requried!!"));
      return;
    }
    let carts = [...cartdetails]
    let totalamount =
      cartdetails?.reduce(
        (interation, val) =>
          (interation = interation + parseInt(val.Qty) * val?.price),
        0,
      ) +
      parseInt(
        pricelabour +
        priceshipping +
        priceloyality -
        discount?.amount +
        (taxes?.type !== 'Inclusive' ? taxes?.taxintamount : ''),
      )
    let depositamount =
      (cartdetails?.reduce(
        (interation, val) =>
        (interation =
          interation + parseInt(val.Qty) * toUnformatPrice(val?.price)),
        0,
      ) +
        parseInt(
          pricelabour +
          priceshipping +
          priceloyality -
          discount?.amount +
          (taxes?.type !== 'Inclusive' ? taxes?.taxintamount : ''),
        )) *
      (depositprect?.value / 100)
    let subtotal = cartdetails?.reduce(
      (interation, val) =>
      (interation =
        interation +
        parseInt(val.Qty) * toUnformatPrice(val?.price)),
      0,
    ) +
      parseInt(
        pricelabour +
        priceshipping +
        priceloyality -
        discount?.amount -
        (taxes?.type === 'Inclusive' ? taxes?.taxintamount : ''),
      )
    let summaryorder = {
      labour: pricelabour,
      shipping: priceshipping,
      loyality_points: priceloyality,
      customer_id: customer,
      sales_person_id: saleperson,
      discount: discount?.amount,
      discount_percent: discount?.prect,
      deposit: status_type === 'pos_order' || ordertype === 'gift_card_order' || ordertype === 'deposit' ? '0' : depositprect?.value,
      deposit_amount: status_type === 'pos_order' || ordertype === 'gift_card_order' || ordertype === 'deposit' ? '0' : depositamount,
      tax_amount: taxes?.amount,
      tax_amount_int: taxes?.taxintamount,
      tax_prect: taxes?.prect,
      tax_type: taxes?.type,
      Sub_Total: subtotal
    }
    try {
      const postdata = {
        sell_info: {
          cart: carts,
          summary_order: summaryorder,
          status_type: status_type ? status_type : mainstatustype ? mainstatustype : ordertype,
          totalamount: totalamount,
        },

        totalamount: parseFloat(totalamount),
        quatetime: quatesentence,
        duedate: TransactionDate
      }
      let res = await _Api(
        postdata,
        `api/v1/POS/quatation/saveQuatation`,
      )
      if (res.code === 200) {
        setShowcheckout('blank');
        setTimeout(() => {
          navigate("/app/pos/Quotation");
        }, 100);
      } else {
        error((res?.message));
      }
    }
    catch (err) {
      console.log(err, 'err')
    }

  }
  const ordercheckout = async () => {
    let carts = [...cartdetails]
    let totalamount = deletecartids.length ? 0 :
      ordertype === "exchange_order" ?
        cartdetails?.reduce(
          (interation, val) =>
            (interation = interation + parseInt(val.Qty) * val?.perProductPrice),
          0,
        ) +
        parseInt(
          pricelabour +
          priceshipping +
          priceloyality -
          discount?.amount +
          (taxes?.type !== 'Inclusive' ? taxes?.taxintamount : ''),
        )
        :
        cartdetails?.reduce(
          (interation, val) =>
            (interation = interation + parseInt(val.Qty) * val?.price),
          0,
        ) +
        parseInt(
          pricelabour +
          priceshipping +
          priceloyality -
          discount?.amount +
          (taxes?.type !== 'Inclusive' ? taxes?.taxintamount : ''),
        )
    let depositamount =
      (cartdetails?.reduce(
        (interation, val) =>
        (interation =
          interation + parseInt(val.Qty) * toUnformatPrice(val?.price)),
        0,
      ) +
        parseInt(
          pricelabour +
          priceshipping +
          priceloyality -
          discount?.amount +
          (taxes?.type !== 'Inclusive' ? taxes?.taxintamount : ''),
        )) *
      (depositprect?.value / 100)
    let subtotal =
      ordertype === "exchange_order" ?
        cartdetails?.reduce(
          (interation, val) =>
          (interation =
            interation +
            parseInt(val.Qty) * toUnformatPrice(val?.perProductPrice)),
          0,
        ) +
        parseFloat(
          pricelabour +
          priceshipping +
          priceloyality -
          discount?.amount -
          (taxes?.type === 'Inclusive' ? taxes?.taxintamount : ''),
        )
        :
        cartdetails?.reduce(
          (interation, val) =>
          (interation =
            interation +
            parseInt(val.Qty) * toUnformatPrice(val?.price)),
          0,
        ) +
        parseFloat(
          pricelabour +
          priceshipping +
          priceloyality -
          discount?.amount -
          (taxes?.type === 'Inclusive' ? taxes?.taxintamount : ''),
        )
    let summaryorder = {
      labour: cartdelete?.labour === 0 ? 0 : pricelabour,
      shipping: cartdelete?.shipping === 0 ? 0 : priceshipping,
      loyality_points: cartdelete?.loyality_points === 0 ? 0 : priceloyality,
      customer_id: customer,
      sales_person_id: saleperson,
      discount: cartdelete?.discount === 0 ? 0 : discount?.amount,
      discount_percent: cartdelete?.discount_percent === 0 ? 0 : discount?.prect,
      deposit: cartdelete?.deposit === 0 ? 0 : status_type === 'pos_order' || ordertype === 'gift_card_order' || ordertype === 'deposit' ? '0' : depositprect?.value,
      deposit_amount: cartdelete?.deposit_amount === 0 ? 0 : status_type === 'pos_order' || ordertype === 'gift_card_order' || ordertype === 'deposit' ? '0' : depositamount,
      tax_amount: cartdelete?.tax_amount === 0 ? 0 : taxes?.amount,
      tax_amount_int: cartdelete?.tax_amount_int === 0 ? 0 : taxes?.taxintamount,
      tax_prect: cartdelete?.tax_prect === 0 ? 0 : taxes?.prect,
      tax_type: taxes?.type,
      Sub_Total: cartdelete?.Sub_Total === 0 ? 0 : subtotal
    }
    if (!customer?.id) {
      error(t("Customer is Requried!!"));
      return;
    }
    if (!saleperson?.value) {
      error(t("SalePerson is Requried!!"));
      return;
    }
    setLoader(true);
    const postdata = {
      sell_info: {
        cart: carts,
        summary_order: summaryorder,
        status_type: status_type ? status_type : mainstatustype ? mainstatustype : ordertype,
        totalamount: totalamount,
        deletecart_ids: deletecartids,
      },
      "payment_info": {
        "balance_due": (UpdatedCart?.sell_info?.totalamount - UpdatedCart?.totalamount ? UpdatedCart?.sell_info?.totalamount - UpdatedCart?.totalamount : 0),
        "balance_deposit": UpdatedCart?.totalamount
      },
      "customer_id": customer?.id,
      "sales_person_id": saleperson?.value,
      "transaction_date": TransactionDate,
      'order_id': order_id,
      'order_type': ordertype,
      "shippingInfo": {}
    };
    let res = await _Api(postdata, `api/v1/POS/customOrder/saveCustomOrder`);
    if (res.code === 200) {
      setLoader(false);
      localStorage.removeItem("showcheckout")
      setcheckoutdetails(res?.data);
      setShoworder(true);
    } else {
      setLoader(false);
      error((res?.message));
    }
  }
  const editcheckoutorder = async () => {
    let carts = [...cartdetails]
    let totalamount = deletecartids.length ? 0 :
      cartdetails?.reduce(
        (interation, val) =>
          (interation = interation + parseInt(val.Qty) * val?.price),
        0,
      ) +
      parseInt(
        pricelabour +
        priceshipping +
        priceloyality -
        discount?.amount +
        (taxes?.type !== 'Inclusive' ? taxes?.taxintamount : ''),
      )
    let depositamount =
      (cartdetails?.reduce(
        (interation, val) =>
        (interation =
          interation + parseInt(val.Qty) * toUnformatPrice(val?.price)),
        0,
      ) +
        parseInt(
          pricelabour +
          priceshipping +
          priceloyality -
          discount?.amount +
          (taxes?.type !== 'Inclusive' ? taxes?.taxintamount : ''),
        )) *
      (depositprect?.value / 100)
    let subtotal = cartdetails?.reduce(
      (interation, val) =>
      (interation =
        interation +
        parseInt(val.Qty) * toUnformatPrice(val?.price)),
      0,
    ) +
      parseInt(
        pricelabour +
        priceshipping +
        priceloyality -
        discount?.amount -
        (taxes?.type === 'Inclusive' ? taxes?.taxintamount : ''),
      )
    let summaryorder = {
      labour: cartdelete?.labour === 0 ? 0 : pricelabour,
      shipping: cartdelete?.shipping === 0 ? 0 : priceshipping,
      loyality_points: cartdelete?.loyality_points === 0 ? 0 : priceloyality,
      customer_id: customer,
      sales_person_id: saleperson,
      discount: cartdelete?.discount === 0 ? 0 : discount?.amount,
      discount_percent: cartdelete?.discount_percent === 0 ? 0 : discount?.prect,
      deposit: cartdelete?.deposit === 0 ? 0 : status_type === 'pos_order' || ordertype === 'gift_card_order' || ordertype === 'deposit' ? '0' : depositprect?.value,
      deposit_amount: cartdelete?.deposit_amount === 0 ? 0 : status_type === 'pos_order' || ordertype === 'gift_card_order' || ordertype === 'deposit' ? '0' : depositamount,
      tax_amount: cartdelete?.tax_amount === 0 ? 0 : taxes?.amount,
      tax_amount_int: cartdelete?.tax_amount_int === 0 ? 0 : taxes?.taxintamount,
      tax_prect: cartdelete?.tax_prect === 0 ? 0 : taxes?.prect,
      tax_type: taxes?.type,
      Sub_Total: cartdelete?.Sub_Total === 0 ? 0 : subtotal
    }
    setLoader(true);
    const postdata = {
      sell_info: {
        cart: carts,
        summary_order: summaryorder,
        status_type: status_type ? status_type : mainstatustype ? mainstatustype : ordertype,
        totalamount: totalamount,
        deletecart_ids: deletecartids,
      },
      "payment_info": {
        "balance_due": (UpdatedCart?.sell_info?.totalamount - UpdatedCart?.totalamount),
        "balance_deposit": UpdatedCart?.totalamount
      },
      "customer_id": customer?.id,
      "sales_person_id": saleperson?.value,
      "transaction_date": TransactionDate,
      "order_type": UpdatedCart?.ordertype ? UpdatedCart?.ordertype : UpdatedCart?.sell_info?.status_type ? UpdatedCart?.sell_info?.status_type : order_type,
      "order_id": order_id ? order_id : '',
      "order_edit": order_edit

    };
    try {
      let res = await _Api(postdata, `api/v1/POS/customOrder/EditCustomOrder`);
      if (res.code === 200) {
        localStorage.removeItem("showcheckout")
        setLoader(false);
        setcheckoutdetails(res?.data);
        setShoworder(true);
      } else {
        error(t("Please Try Again!!"));
      }
    } catch (err) {
      console.log(err, "err");
    }
  }
  const verfypin = async () => {
    try {
      const postdata = {
        "verfypin": otpnumber.join().replace(/,/g, '')
      };
      let res = await _Api(postdata, `api/v1/POS/common/loginPINVerfy`);
      if (res.code === 200) {
        if (order_edit && order_id && order_type) {
          editcheckoutorder()
        } else {
          ordercheckout();
        }

      } else {
        error(t("Please Try Again!!"));
        setOtpnumber([]);
      }
    } catch (err) {
      console.log(err, "err");
    }
  }
  useEffect(() => {
    if (generalsettings?.data?.generalsetup?.Users_to_enter_PIN_for_every_sale === '1') {
      if (otpnumber?.length === 6) {
        verfypin();
      }
    }
    // eslint-disable-next-line
  }, [otpnumber]);
  useEffect(() => {
    if (UpdatedCart !== undefined) {
      // dispatch(updateCustomer(UpdatedCart?.sell_info?.summary_order?.customer_id));
      dispatch(updateSaleperson(UpdatedCart?.sell_info?.summary_order?.sales_person_id));
      let jsondata = JSON.stringify(UpdatedCart?.sell_info?.summary_order?.sales_person_id);
      localStorage.setItem('salesdetails', jsondata);
      setsearchproductstatus(false);
      setCartdetails(UpdatedCart?.sell_info?.cart);
    } else {
      if (order_edit && order_type && order_id) {
        GetCartdetail(order_edit, order_type, order_id)
        setCartdetails(UpdatedCart?.sell_info?.cart);
      } else {
        if (customsecrive) {
          setService_enable(true)
        }
        if (searchproductstatus) {
          GetCartdetail()
        }
      }
    }
    // eslint-disable-next-line
  }, [searchproductstatus, customsecrive, UpdatedCart])

  useEffect(() => {
    changediscount('all')
    // eslint-disable-next-line
  }, [cartdetails])
  useEffect(() => {
    if (
      OrgSettings &&
      UpdatedCart === undefined &&
      (cartdetails !== undefined || order_edit || by_custom_order || by_repair_order)
    ) {
      changeTax(
        'default',
        UpdatedCart?.sell_info?.summary_order?.tax_prect
          ? UpdatedCart?.sell_info?.summary_order?.tax_prect
          : OrgSettings?.tax?.value
      );
    }
    // eslint-disable-next-line
  }, [OrgSettings, cartdetails, pricelabour, priceshipping, priceloyality, discount])



  const [isSummaryActive, setIsSummaryActive] = useState(false);

  const summaryToggle = event => {
    setIsSummaryActive(current => !current);
  };

  const edit = (cart_id) => {
    let carts = [...cartdetails];
    let esditservicearray = [...editservicelaboure];
    carts.forEach((result) => {
      if (result?.custom_cart_id === cart_id) {
        result.service_labour = {
          product_price: product_price,
          service_remark: scrviceremark,
          service_laburelist: scrvicelist,
          cart_id: result?.custom_cart_id,
          totalscrviceamount: scrvicecharge
        };
        if (esditservicearray?.length && scrviceedit) {
          esditservicearray.forEach((index) => {
            index.service_labour = { ...result.service_labour };
          });
        } else {
          result.Service_labour_exsist = true;
          esditservicearray.push(result.service_labour);

          let total = esditservicearray.reduce((acc, curr) => {
            return acc + (curr?.totalscrviceamount || 0);
          }, 0);

          setPricelabour(total);
        }
        result.Service_labour = result.service_labour
        delete result.service_labour
      }
    });
    setEditservicelaboure(esditservicearray);
  };
  const checkstockid = async (oldstockid, newstockid, custom_cart_id, qty) => {
    try {
      let postdata = {
        old_stockId: oldstockid ? oldstockid : "",
        new_stockId: newstockid ? newstockid : "",
        cart_id: custom_cart_id ? custom_cart_id : "",
        qty: qty ? qty : ""
      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/stockIdManage`)
      if (res?.code === 200) {
        success("Stockid Updated")
        GetCartdetail()
        if (parts[3] === "products" && parts?.length === 4) {
          getproducts()
        }
      } else {
        error("Somthing Went Wrong")
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const editcustomer = async (order_id) => {
    try {
      let postdata = {
        order_id: order_id
      }
      let res = await _Api(postdata, `api/v1/pos/customOrder/edit-details`)
      if (res?.code === 200) {
        let customerdata = { id: res?.data?.customer_id, name: res?.data?.name, existsInAddress: true }
        dispatch(updateCustomer(customerdata));
        let jsondata = JSON.stringify(customerdata);
        localStorage.setItem('customerdetails', jsondata);
        let lastobj = { billing_address: res?.data?.billing_address, shipping_address: res?.data?.shipping_address }
        let data = JSON.stringify(lastobj);
        localStorage.setItem('addcustomerdtaa', data);

      } else {
        error((res?.message))
      }
    }
    catch (err) {
      console.log(err, "Err")
    }
  }
  useEffect(() => {
    if (order_edit && order_id && order_type) {
      if (order_type === "custom_order" || order_type === "pos_order" || order_type === "mix_and_match" || order_type === "repair_order" || order_type === "gift_card_order") {
        editcustomer(order_id)
      }
    } else if (order_id && (by_custom_order || by_repair_order)) {
      editcustomer(order_id)
    }
    // eslint-disable-next-line
  }, [order_id])
  useEffect(() => {
    if (order_edit && order_id && order_type) {
      let sum = 0;
      if (cartdetails?.length) {
        cartdetails.forEach(item => {
          if (item?.Service_labour?.service_laburelist?.length) {
            item.Service_labour.service_laburelist.forEach(service => {
              sum += service?.scrviceamount;
            });
          }
        });
        setPricelabour(sum)
      }
    }
    // eslint-disable-next-line 
  }, [cartdetails])
  return (
    <>
      <div className="main-body-row-right-col-inner">
        <div className="main-body-row-right-col-inner-ct-top" style={{ height: '12%' }}>
          <div className="main-body-row-right-col-inner-ct-top-inner h-100 d-flex flex-column justify-content-evenly">
            <div className="main-body-row-right-col-inner-ct-top-inner-date-area">
              <div className="main-body-row-right-col-inner-ct-top-inner-date-area-inner">
                <span>{status_type === 'custom_order' || status_type === 'mix_and_match' ? 'CO-XXX' : status_type === 'pos_order' ? 'POS-XXX' : 'XXXX'}</span> :
                <div className="main-body-row-right-col-inner-ct-top-inner-date-area-inner-input date">
                  <DatePicker
                    className="date-field"
                    selected={TransactionDate}
                    onChange={(input) => setTransactionDate(input)}
                    name="TransactionDate"
                    dateFormat={OrgSettings?.date_picker}
                    autoComplete="off"
                    popperPlacement='top-start'
                  />
                  <img
                    className="date-img"
                    src={base_assets + 'images/icons/calendar-green.svg'}
                    alt=""
                  />
                </div>
              </div>
              <div className={
                saleperson?.value
                  ? 'main-body-row-right-col-inner-ct-top-inner-date-area-select ms-2'
                  : 'main-body-row-right-col-inner-ct-top-inner-date-area-select grey-select ms-2'
              }>
                <Saleperson />
              </div>
            </div>
            <div className="main-body-row-right-col-inner-ct-top-inner-search">
              <CustomerSearchModal
                setCusid={setCusid}
              ></CustomerSearchModal>
              {parmissiondatashow?.pos_customer_reports?.Add ?
                <span className="main-body-row-right-col-inner-ct-top-inner-search-user">
                  <img
                    data-bs-toggle="modal"
                    data-bs-target="#customerAddModal"
                    src={base_assets + 'images/pos/icons/add-user.png'}
                    alt=""
                  />
                </span>
                : ""}
              {customer?.id ?
                <span
                  className={
                    parts[3] !== "exchange" && parts[3] !== "refund" && parts[3] !== "reserve" && parts[3] !== "receive"
                      ? "main-body-row-right-col-inner-ct-top-inner-search-user"
                      : "d-none"
                  }
                  onClick={() => { setToggleAddressCustomer(!toggleAddressCustomer) }}

                >
                  {customer?.existsInAddress || order_edit ?
                    <img
                      src={addressicon}
                      alt=""
                    />
                    :
                    <img
                      className='iconwithnotification'
                      src={notaddressicon}
                      alt=""
                    />}
                </span> :
                <span
                  className={
                    parts[3] !== "exchange" && parts[3] !== "refund" && parts[3] !== "reserve" && parts[3] !== "receive"
                      ? "main-body-row-right-col-inner-ct-top-inner-search-user"
                      : "d-none"
                  }
                  onClick={() => { error(t("Customer is Requried!!")) }}

                >
                  <img
                    src={addressicon}
                    alt=""
                  />
                </span>}
            </div>
          </div>
        </div>
        <div style={{ height: '88%' }}>
          {toggleAddressCustomer && customer?.id ?
            <AddCustomerAddressPopup />

            : <>
              {ordertype === 'exchange_order' ?
                <div className="main-body-row-right-col-inner-progress mt-1" style={{ height: '12%' }}>
                  <div className="main-body-row-right-col-inner-progress-line">
                    <div className="main-body-row-right-col-inner-progress-line-single col active"></div>
                    <div className="main-body-row-right-col-inner-progress-line-single col"></div>
                  </div>

                  <div className="main-body-row-right-col-inner-progress-step space-evenly">
                    <div className="main-body-row-right-col-inner-progress-step-single active">
                      <div className="main-body-row-right-col-inner-progress-step-single-circle">
                        <span className="main-body-row-right-col-inner-progress-step-single-circle-dot"></span>
                      </div>
                      <span className="main-body-row-right-col-inner-progress-step-single-text">
                        {t("Exchange")}
                      </span>
                    </div>
                    <div className="main-body-row-right-col-inner-progress-step-single">
                      <div className="main-body-row-right-col-inner-progress-step-single-circle"></div>
                      <span className="main-body-row-right-col-inner-progress-step-single-text">
                        {t("done")}
                      </span>
                    </div>
                  </div>
                </div>
                :
                <div className="main-body-row-right-col-inner-progress mt-1" style={{ height: '12%' }}>
                  <div className="main-body-row-right-col-inner-progress-line">
                    <div className="main-body-row-right-col-inner-progress-line-single col active"></div>
                    <div className="main-body-row-right-col-inner-progress-line-single col"></div>
                    <div className="main-body-row-right-col-inner-progress-line-single col"></div>
                    <div className="main-body-row-right-col-inner-progress-line-single col"></div>
                  </div>
                  <div className="main-body-row-right-col-inner-progress-step">
                    <div className="main-body-row-right-col-inner-progress-step-single active">
                      <div className="main-body-row-right-col-inner-progress-step-single-circle">
                        <span className="main-body-row-right-col-inner-progress-step-single-circle-dot"></span>
                      </div>
                      <span className="main-body-row-right-col-inner-progress-step-single-text">
                        {ordertype === 'refund_order' ? t("Refund") : t("sell")}
                      </span>
                    </div>
                    <div
                      className={
                        showpayment
                          ? 'main-body-row-right-col-inner-progress-step-single second active'
                          : 'main-body-row-right-col-inner-progress-step-single second'
                      }
                      onClick={() => {
                        if (showpayment) {
                          callpaymentcheckout()
                        }
                      }}
                    >
                      <div className="main-body-row-right-col-inner-progress-step-single-circle"></div>
                      <span className="main-body-row-right-col-inner-progress-step-single-text">
                        {t("Payment")}
                      </span>
                    </div>
                    <div className="main-body-row-right-col-inner-progress-step-single">
                      <div className="main-body-row-right-col-inner-progress-step-single-circle"></div>
                      <span className="main-body-row-right-col-inner-progress-step-single-text">
                        {t("done")}
                      </span>
                    </div>
                  </div>
                </div>
              }
              <div className={ordertype === 'deposit' ? "main-body-row-right-col-inner-list box-1 position-sticky event-none d-flex flex-column justify-content-between" : "main-body-row-right-col-inner-list box-1 position-sticky d-flex flex-column justify-content-between"} style={{ height: '88%', maxHeight: '88%' }}>
                <table className="add-to-cart-table" id='cards'>
                  <tbody>
                    <>
                      {cartdetails?.length
                        ? cartdetails.map((result, ckey) => {
                          return (
                            <tr key={ckey}>
                              <td>
                                <table>
                                  <tbody>
                                    <tr>
                                      {result?.status?.is_mix_match ? (
                                        ''
                                      ) : (
                                        <td className="add-to-cart-table-s-no">
                                          {ckey + 1}
                                        </td>
                                      )}
                                      {result?.main_image ?
                                        <td
                                          className="add-to-cart-table-pro-img"
                                          rowSpan="5"
                                        >

                                          <img src={result?.main_image} alt="" />
                                        </td>

                                        : ''}
                                      <td
                                        className="add-to-cart-table-pro-name"
                                        colSpan="2"
                                      >
                                        {result?.name}
                                      </td>
                                      {result?.status?.is_mix_match ? (
                                        ''
                                      ) : (
                                        <td className="add-to-cart-table-delete" colSpan="2">
                                          <button
                                            className="btn-style"
                                            onClick={(e) => deletecart(result?.custom_cart_id, result?.transaction_type, result?.amount, result?.sessioncartid)
                                            }
                                          >
                                            <img
                                              src={
                                                base_assets +
                                                'images/pos/icons/delete-icon.png'
                                              }
                                              alt=""
                                            />
                                          </button>
                                        </td>
                                      )}
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td className="add-to-cart-table-sku">
                                        {result?.SKU}
                                      </td>
                                      <td className="add-to-cart-table-no">
                                        {result?.size_name ? '#' + result?.size_name : ""}
                                      </td>
                                      {ordertype === "exchange_order" || ordertype === "repair_order" || ordertype === "refund_order" ?
                                        <td
                                          className="add-to-cart-table-add-pro"
                                          rowSpan="2"
                                        >
                                          <div className="add-to-cart-table-add-pro-inner">
                                            <button
                                              className="add-to-cart-table-add-pro-inner-img btn-style"
                                              onClick={() => changeQty(ckey, 'remove', result?.qtyToBe)}
                                            >
                                              <img
                                                src={
                                                  base_assets +
                                                  'images/icons/minus-icon.png'
                                                }
                                                alt=""
                                              />
                                            </button>
                                            <span className="add-to-cart-table-add-pro-inner-count">
                                              {result?.Qty ? result?.Qty : 1}
                                            </span>
                                            <button
                                              className="add-to-cart-table-add-pro-inner-img btn-style"
                                              onClick={() => changeQty(ckey, 'add', result?.qtyToBe)}
                                            >
                                              <img
                                                src={
                                                  base_assets +
                                                  'images/icons/add-icon.png'
                                                }
                                                alt=""
                                              />
                                            </button>
                                          </div>
                                        </td> :
                                        <td
                                          className="add-to-cart-table-add-pro"
                                          rowSpan="2"
                                        >
                                          <div className="add-to-cart-table-add-pro-inner">
                                            <button
                                              className="add-to-cart-table-add-pro-inner-img btn-style"
                                              onClick={() => changeQty(ckey, 'remove', result?.po_QTY)}
                                            >
                                              <img
                                                src={
                                                  base_assets +
                                                  'images/icons/minus-icon.png'
                                                }
                                                alt=""
                                              />
                                            </button>
                                            <span className="add-to-cart-table-add-pro-inner-count">
                                              {result?.Qty ? result?.Qty : 1}
                                            </span>
                                            <button
                                              className="add-to-cart-table-add-pro-inner-img btn-style"
                                              onClick={() => changeQty(ckey, 'add', result?.po_QTY)}
                                            >
                                              <img
                                                src={
                                                  base_assets +
                                                  'images/icons/add-icon.png'
                                                }
                                                alt=""
                                              />
                                            </button>
                                          </div>
                                        </td>
                                      }
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td className="add-to-cart-table-s-no">
                                        {result?.stock_id_options?.length && result?.stock_id_options[0]?.value && result?.stock_id ?
                                          result?.status_type === "custom_order" || result?.status_type === "mix_match" ?
                                            (
                                              <Select
                                                value={{
                                                  value: result?.stock_id,
                                                  label: result?.stock_id,
                                                }}
                                                onChange={(input) => {
                                                  changeprice(
                                                    ckey,
                                                    input?.value,
                                                    'stock_id',
                                                    input?.po_QTY

                                                  );
                                                }
                                                }
                                                options={result?.stock_id_options}
                                                classNamePrefix="common-select"
                                                isSearchable={false}
                                                menuPlacement='auto'
                                              />
                                            ) :
                                            (
                                              <Select
                                                value={{
                                                  value: result?.stock_id,
                                                  label: result?.stock_id,
                                                }}
                                                onChange={(input) => {
                                                  changeprice(
                                                    ckey,
                                                    input?.value,
                                                    'stock_id',
                                                    input?.po_QTY

                                                  ); checkstockid(result?.stock_id, input?.value, result?.custom_cart_id, result?.Qty)
                                                }
                                                }
                                                options={result?.stock_id_options}
                                                classNamePrefix="common-select"
                                                isSearchable={false}
                                                menuPlacement='auto'
                                              />
                                            ) : (
                                            ''
                                          )}
                                      </td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      {ordertype === 'pos_order' || ordertype === 'repair_order' ? (
                                        <>

                                          <td className="add-to-cart-table-input dis">
                                            <div className='dis-row'>
                                              {ordertype === 'pos_order' ?
                                                result?.Service_labour_exsist || result?.service_labour_exsist ? <div className='service-modal-btn cursor-pointer'>
                                                  <img src={base_assets + "images/pos/icons/labour_green.png"} alt="" />
                                                </div> :

                                                  <div data-bs-toggle="modal" data-bs-target="#servicelabour" className={generalsettings?.data?.generalsetup?.servicelabers === '1' ? 'service-modal-btn cursor-pointer' : "d-none"}
                                                    onClick={() => {
                                                      setCart_id(result?.custom_cart_id);
                                                      setProduct_price(result?.price);
                                                      setScrvicelist([])
                                                      setServicelabourlist([])

                                                    }}>
                                                    <img src={base_assets + "images/pos/icons/labour_gray.png"} alt="" />
                                                  </div> : ""}
                                              <div className="placeholder-up-input form-floating w-100 h-100 ps-2">
                                                <input className='main-body-top-status-bar-filter-input placeholder-up-input-field form-control position-relative border-start-0 ps-0 text-truncate bg-white arrow-none' type="number"
                                                  value={result?.discount_percent} onChange={(e) =>
                                                    changecartdiscount(e.target.value, ckey, "discountprecent")} placeholder="Dis %" />
                                                <label className="placeholder-up-input-label">{t("Dis")}(%)</label>
                                              </div>
                                            </div>
                                          </td>
                                          <td className="add-to-cart-table-input">
                                            <div className="placeholder-up-input form-floating w-100 h-100 ps-2">
                                              <input className='main-body-top-status-bar-filter-input placeholder-up-input-field form-control position-relative border-start-0 ps-0 text-truncate bg-white arrow-none' type="number" value={result?.Discount_Amount} onChange={(e) =>
                                                changecartdiscount(e.target.value, ckey, "discountamount")} placeholder="Discount" />
                                              <label className="placeholder-up-input-label">{t("Dis(Amt)")}</label>
                                            </div>
                                          </td>
                                        </>
                                      ) : (
                                        <td className="add-to-cart-table-input dis">
                                          {result?.delivery_date ?
                                            <div className="placeholder-up-input placeholder-up-input-date date">
                                              <DatePicker
                                                className="placeholder-up-input-field"
                                                selected={new Date(result?.delivery_date)}
                                                onChange={(input) => changeprice(ckey, input, 'delivery_date')}
                                                name="DeliveryDate"
                                                dateFormat={OrgSettings?.date_picker}
                                                autoComplete="off"
                                                minDate={new Date().toISOString().split('T')[0]}
                                              />
                                              <img
                                                className="placeholder-up-input-field-calender"
                                                src={
                                                  base_assets +
                                                  'images/icons/calendar-green.svg'
                                                }
                                                alt=""
                                              />
                                            </div> : ''}

                                        </td>
                                      )}
                                      {
                                        ordertype === "exchange_order" ?
                                          <td
                                            colSpan="2"
                                            className="add-to-cart-table-price"
                                          >
                                            <input
                                              type="text"
                                              value={
                                                result?.addclass
                                                  ? toUnformatPrice(result?.perProductPrice)
                                                  : toFormatPrice(result?.perProductPrice, {
                                                    addSymbol: true,
                                                  })
                                              }
                                              onChange={(e) => { changeprice(ckey, e.target.value, 'price') }
                                              }
                                              className={
                                                result?.addclass
                                                  ? 'active-input arrow-none'
                                                  : ''
                                              }
                                            />
                                            <img
                                              className="add-to-cart-table-price-edit"
                                              onClick={() => addclasstocart(ckey)}
                                              src={
                                                base_assets +
                                                'images/pos/icons/edit-icon.png'
                                              }
                                              alt=""
                                            />
                                          </td> :
                                          <td
                                            colSpan="2"
                                            className="add-to-cart-table-price"
                                          >
                                            <input
                                              type='text'
                                              inputMode='decimal'
                                              pattern="[0-9]*[.,]?[0-9]*"
                                              value={
                                                !result?.addclass
                                                  ? toFormatPrice(result?.price, {
                                                    addSymbol: true
                                                  })
                                                  : result?.price
                                              }
                                              onChange={(e) => {
                                                const value = e.target.value;
                                                if (/^[0.00-9]*$/.test(value)) {
                                                  changeprice(ckey, e.target.value, 'price')
                                                }
                                              }
                                              }
                                              className={
                                                result?.addclass
                                                  ? 'active-input arrow-none'
                                                  : ''
                                              }
                                            />
                                            <img
                                              className="add-to-cart-table-price-edit"
                                              onClick={() => addclasstocart(ckey)}
                                              src={
                                                base_assets +
                                                'images/pos/icons/edit-icon.png'
                                              }
                                              alt=""
                                            />
                                          </td>
                                      }
                                    </tr>
                                    {result?.Service_labour_exsist || result?.service_labour_exsist
                                      ?

                                      <tr>
                                        <td></td>
                                        <td colSpan="4">
                                          <div className='add-to-cart-table-service-labour my-3'>
                                            <span>{t("Service Labour")} ({result?.Service_labour?.service_laburelist?.length ? result?.Service_labour?.service_laburelist?.length : result?.service_labour?.service_laburelist?.length})</span>
                                            <span
                                              onClick={() => { setServicelabourlist(result?.Service_labour?.service_laburelist); setShowmodal(true) }}
                                              className='d-flex align-items-center'>

                                              {toFormatPrice(
                                                result?.Service_labour?.service_laburelist?.length
                                                  ? result?.Service_labour?.service_laburelist?.reduce(
                                                    (interation, val) =>
                                                    (interation =
                                                      interation +
                                                      toUnformatPrice(val?.scrviceamount)),
                                                    0,
                                                  )
                                                  : result?.service_labour?.service_laburelist?.reduce(
                                                    (interation, val) =>
                                                    (interation =
                                                      interation +
                                                      toUnformatPrice(val?.scrviceamount)),
                                                    0,
                                                  ),
                                              )}
                                              {result?.type === 'pos_order' ?
                                                <svg data-bs-toggle="modal" data-bs-target="#servicelabour" className="ms-3 cursor-pointer p-1" style={{ minWidth: "24px", height: "20px" }} width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                                  setCart_id(result?.custom_cart_id);
                                                  setProduct_price(result?.Service_labour?.product_price);
                                                  setScrvicelist(result?.Service_labour?.service_laburelist);
                                                  setScrviceedit(true)
                                                  setServicelabourlist(result?.Service_labour?.service_laburelist)
                                                }}>
                                                  <path d="M6.4349 5.74861L2.61969 8.99345C2.15501 9.38866 1.62333 9.47685 1.02463 9.25803C0.424959 9.04004 0.125122 8.65025 0.125122 8.08864L0.125122 1.66136C0.125122 1.09975 0.424959 0.709957 1.02463 0.491971C1.62333 0.273151 2.15501 0.361344 2.61969 0.75655L6.4349 4.00139C6.58164 4.12619 6.6917 4.26139 6.76507 4.40699C6.83844 4.5526 6.87512 4.7086 6.87512 4.875C6.87512 5.0414 6.83844 5.1974 6.76507 5.34301C6.6917 5.48861 6.58164 5.62381 6.4349 5.74861Z" fill="#6A6A6A" />
                                                </svg> : ""}
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                      : ""}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          )
                        })
                        : ''}
                    </>
                  </tbody>
                </table>
                <div className='main-body-row-right-col-inner-summary-wrap position-sticky'>
                  <div className={ordertype === 'exchange_order' || ordertype === 'refund_order' || ordertype === 'deposit' || reserve_status === 0 ? "main-body-row-right-col-inner-summary box-2 event-none" : "main-body-row-right-col-inner-summary box-2"} style={{ height: 'fit-content', maxHeight: 'fit-content' }}>
                    <div className={isSummaryActive ? '' : 'd-none'}>
                      <h2 className="main-body-row-right-col-inner-summary-heading">
                        {t("Summary Order")}
                        <span>
                          {cartdetails?.length
                            ? cartdetails?.reduce(
                              (interation, val) =>
                                (interation = interation + parseInt(val.Qty)),
                              0,
                            )
                            : ''}<span> </span>
                          <label>{''}{t("Items")}</label>
                        </span>
                      </h2>
                      <div className="main-body-row-right-col-inner-summary-price-data total-boxes">
                        <div className="main-body-row-right-col-inner-summary-price-data-total">
                          <span className="main-body-row-right-col-inner-summary-price-data-total-text">
                            {t("Total")}
                          </span>
                          {
                            ordertype === "exchange_order" ?
                              <span className="main-body-row-right-col-inner-summary-price-data-total-text ms-auto">
                                {cartdetails?.length ?
                                  toFormatPrice(
                                    cartdetails?.reduce(
                                      (interation, val) =>
                                      (interation =
                                        interation +
                                        parseInt(val.Qty) * toUnformatPrice(val?.perProductPrice)),
                                      0,
                                    ),

                                    { addSymbol: true },
                                  )
                                  : 0.0}
                              </span>
                              :
                              ordertype === "repair_order" ?
                                <span className="main-body-row-right-col-inner-summary-price-data-total-text ms-auto">
                                  {cartdetails?.length ?
                                    toFormatPrice(
                                      cartdetails?.reduce(
                                        (interation, val) =>
                                        (interation =
                                          interation +
                                          toUnformatPrice(val?.price)),
                                        0,
                                      ),

                                      { addSymbol: true },
                                    )
                                    : 0.0}
                                </span>
                                :
                                <span className="main-body-row-right-col-inner-summary-price-data-total-text ms-auto">
                                  {cartdetails?.length ?
                                    toFormatPrice(
                                      cartdetails?.reduce(
                                        (interation, val) =>
                                        (interation =
                                          interation +
                                          parseInt(val.Qty) * toUnformatPrice(val?.price)),
                                        0,
                                      ),

                                      { addSymbol: true },
                                    )
                                    : 0.0}
                                </span>
                          }
                        </div>

                        <div className={by_custom_order || by_repair_order ? "main-body-row-right-col-inner-summary-price-data-box event-none" : "main-body-row-right-col-inner-summary-price-data-box"}>
                          <div className="main-body-row-right-col-inner-summary-price-data-box-single">
                            <label className="main-body-row-right-col-inner-summary-price-data-box-single-label">
                              {t("Labour")}
                            </label>
                            <span className="main-body-row-right-col-inner-summary-price-data-box-single-price">
                              <input
                                type="number"
                                disabled={service_enable || scrvicelistmixmatch ? true : false}
                                className="arrow-none"
                                value={pricelabour ? pricelabour : 0}
                                onChange={(e) =>
                                  setPricelabour(
                                    e.target.value ? parseFloat(e.target.value) : 0,
                                  )
                                }
                              />
                            </span>
                          </div>
                          <div className="main-body-row-right-col-inner-summary-price-data-box-single">
                            <label className="main-body-row-right-col-inner-summary-price-data-box-single-label">
                              {t("Shipping")}
                            </label>
                            <span className="main-body-row-right-col-inner-summary-price-data-box-single-price">
                              <input
                                type="number"
                                className="arrow-none"
                                value={priceshipping ? priceshipping : 0}
                                onChange={(e) =>
                                  setPriceshipping(
                                    e.target.value ? parseFloat(e.target.value) : 0,
                                  )
                                }
                              />
                            </span>
                          </div>
                          <div className="main-body-row-right-col-inner-summary-price-data-box-single">
                            <label className="main-body-row-right-col-inner-summary-price-data-box-single-label">
                              {t("Loyalty points")}
                            </label>
                            <span className="main-body-row-right-col-inner-summary-price-data-box-single-price">
                              <input
                                type="number"
                                className="arrow-none"
                                value={priceloyality ? priceloyality : 0}
                                onChange={(e) =>
                                  setPriceloyality(
                                    e.target.value ? parseFloat(e.target.value) : "",
                                  )
                                }
                              />
                            </span>
                          </div>
                        </div>
                        <div className={by_custom_order || by_repair_order ? "main-body-row-right-col-inner-summary-price-data-box  event-none" : "main-body-row-right-col-inner-summary-price-data-box"}>
                          <div className="main-body-row-right-col-inner-summary-price-data-box-single">
                            <label className="main-body-row-right-col-inner-summary-price-data-box-single-label">
                              {t("Discount")} (%)
                            </label>
                            <span className="main-body-row-right-col-inner-summary-price-data-box-single-price">
                              <input
                                type="number"
                                className="arrow-none"
                                value={discount?.prect ? discount?.prect : 0}
                                min={0}
                                max={100}
                                step={1}
                                onChange={(e) => {
                                  const value = Math.max(0, Math.min(100, Number(e.target.value)));
                                  changediscount('discountprecent', value);
                                }}
                              />
                              %
                            </span>
                          </div>
                          <div className="main-body-row-right-col-inner-summary-price-data-box-single">
                            <label className="main-body-row-right-col-inner-summary-price-data-box-single-label">
                              {t("Discount (Amount)")}
                            </label>
                            <span className="main-body-row-right-col-inner-summary-price-data-box-single-price">
                              <input
                                type="number"
                                className="arrow-none"
                                onChange={(e) =>
                                  changediscount('discountamount', e.target.value)
                                }
                                value={discount?.amount ? parseFloat(discount?.amount) : 0}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="main-body-row-right-col-inner-summary-price-data-total">
                          <span className="main-body-row-right-col-inner-summary-price-data-total-text">
                            {t("Sub Total1")}
                          </span>
                          {
                            ordertype === "exchange_order" ?
                              <span className="main-body-row-right-col-inner-summary-price-data-total-text ms-auto">

                                {
                                  cartdetails?.length
                                    ? toFormatPrice(
                                      cartdetails?.reduce(
                                        (interation, val) =>
                                        (interation =
                                          interation +
                                          parseInt(val.Qty) * toUnformatPrice(val?.perProductPrice)),
                                        0,
                                      ) +
                                      parseFloat(
                                        pricelabour +
                                        priceshipping +
                                        priceloyality -
                                        discount?.amount -
                                        (taxes?.type === 'Inclusive' ? taxes?.taxintamount : ''),
                                      ),
                                      { addSymbol: true },
                                    )
                                    : 0.0}
                              </span>
                              :
                              ordertype === "repair_order" ?
                                <span className="main-body-row-right-col-inner-summary-price-data-total-text ms-auto">

                                  {
                                    cartdetails?.length
                                      ? toFormatPrice(
                                        cartdetails?.reduce(
                                          (interation, val) =>
                                          (interation =
                                            interation +
                                            toUnformatPrice(val?.price)),
                                          0,
                                        ) +
                                        parseFloat(
                                          pricelabour +
                                          priceshipping +
                                          priceloyality -
                                          discount?.amount -
                                          (taxes?.type === 'Inclusive' ? taxes?.taxintamount : ''),
                                        ),
                                        { addSymbol: true },
                                      )
                                      : 0.0}
                                </span> :
                                <span className="main-body-row-right-col-inner-summary-price-data-total-text ms-auto">
                                  {
                                    cartdetails?.length
                                      ? toFormatPrice(
                                        cartdetails?.reduce(
                                          (interation, val) =>
                                          (interation =
                                            interation +
                                            parseInt(val.Qty) * toUnformatPrice(val?.price)),
                                          0,
                                        ) +
                                        parseFloat(
                                          pricelabour +
                                          priceshipping +
                                          priceloyality -
                                          discount?.amount -
                                          (taxes?.type === 'Inclusive' ? taxes?.taxintamount : ''),
                                        ),
                                        { addSymbol: true },
                                      )
                                      : 0.0}
                                </span>
                          }
                        </div>
                        <div className="main-body-row-right-col-inner-summary-price-data-box">
                          <div className="main-body-row-right-col-inner-summary-price-data-box-single">
                            <label className="main-body-row-right-col-inner-summary-price-data-box-single-label">
                              Tax (%)
                            </label>
                            <span className="main-body-row-right-col-inner-summary-price-data-box-single-price">
                              <input
                                type="number"
                                className="arrow-none"
                                value={taxes?.prect}
                                onChange={(e) => changeTax('taxprecent', e.target.value)}
                              />
                              %
                            </span>
                          </div>
                          <div className="main-body-row-right-col-inner-summary-price-data-box-single">
                            <label className="main-body-row-right-col-inner-summary-price-data-box-single-label">
                              {t("Tax (Amount)")}
                            </label>
                            <span className="main-body-row-right-col-inner-summary-price-data-box-single-price">
                              {parseFloat(taxes?.amount)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="main-body-row-right-col-inner-summary-price-data mt-2">
                      <div className="main-body-row-right-col-inner-summary-price-data-total">
                        <span className="main-body-row-right-col-inner-summary-price-data-total-text text-dark">
                          {t("Grand Total")}
                        </span>
                        {ordertype === "exchange_order" ?
                          <span className="main-body-row-right-col-inner-summary-price-data-total-text text-dark ms-auto">

                            {UpdatedCart?.sell_info?.summary_order?.checkOutAmount ?
                              toFormatPrice(UpdatedCart?.sell_info?.summary_order?.checkOutAmount, { addSymbol: true }) :
                              cartdetails?.length ?

                                toFormatPrice(
                                  cartdetails?.reduce(
                                    (interation, val) =>
                                    (interation =
                                      interation +
                                      parseInt(val.Qty) * toUnformatPrice(val?.perProductPrice)),
                                    0,
                                  ) +
                                  parseFloat(
                                    pricelabour +
                                    priceshipping +
                                    priceloyality -
                                    discount?.amount +
                                    (taxes?.type !== 'Inclusive' ? taxes?.taxintamount : ''),
                                  ),
                                  { addSymbol: true },
                                ) : 0.0}
                          </span> :
                          ordertype === "repair_order" ?
                            <span className="main-body-row-right-col-inner-summary-price-data-total-text text-dark ms-auto">

                              {UpdatedCart?.sell_info?.summary_order?.checkOutAmount ?
                                toFormatPrice(UpdatedCart?.sell_info?.summary_order?.checkOutAmount, { addSymbol: true }) :
                                cartdetails?.length ?

                                  toFormatPrice(
                                    cartdetails?.reduce(
                                      (interation, val) =>
                                      (interation =
                                        interation +
                                        toUnformatPrice(val?.price)),
                                      0,
                                    ) +
                                    parseFloat(
                                      pricelabour +
                                      priceshipping +
                                      priceloyality -
                                      discount?.amount +
                                      (taxes?.type !== 'Inclusive' ? taxes?.taxintamount : ''),
                                    ),
                                    { addSymbol: true },
                                  ) : 0.0}
                            </span> :
                            <span className="main-body-row-right-col-inner-summary-price-data-total-text text-dark ms-auto">

                              {UpdatedCart?.sell_info?.summary_order?.checkOutAmount ?
                                toFormatPrice(UpdatedCart?.sell_info?.summary_order?.checkOutAmount, { addSymbol: true }) :
                                cartdetails?.length ?

                                  toFormatPrice(
                                    cartdetails?.reduce(
                                      (interation, val) =>

                                      (interation =
                                        interation +
                                        parseInt(val.Qty) * parseFloat(val?.price)),
                                      0,
                                    ) +
                                    parseFloat(
                                      pricelabour +
                                      priceshipping +
                                      priceloyality -
                                      discount?.amount +
                                      (taxes?.type !== 'Inclusive' ? taxes?.taxintamount : ''),
                                    ),
                                    { addSymbol: true },
                                  ) : 0.00}

                            </span>
                        }
                        <img onClick={summaryToggle} className={isSummaryActive ? 'ms-2 cursor-pointer p-1' : 'ms-2 cursor-pointer p-1 rotate-90'} style={{ minWidth: "24px" }} src={base_assets + 'images/pos/icons/arrow-up.png'} alt="" />
                      </div>
                      {ordertype === 'pos_order' || ordertype === 'gift_card_order' || ordertype === 'deposit' || ordertype === 'exchange_order' || ordertype === "refund_order" || reserve_status === 0 ? '' :

                        <div className="main-body-row-right-col-inner-summary-price-data-total">
                          <span className="main-body-row-right-col-inner-summary-price-data-total-text text-dark">
                            {t("Deposit")}
                            <Select
                              value={depositprect}
                              onChange={(e) => setDepositprect(e)}
                              options={depositoptionarray}
                              classNamePrefix="common-select"
                              isSearchable={false}
                              className="select-position-up"
                              menuPlacement='auto'
                            />
                          </span>
                          {ordertype === 'repair_order' ?
                            <span className="main-body-row-right-col-inner-summary-price-data-total-text text-dark ms-auto">
                              {cartdetails?.length
                                ? toFormatPrice(
                                  (cartdetails?.reduce(
                                    (interation, val) =>
                                    (interation =
                                      interation +
                                      toUnformatPrice(val?.price)),
                                    0,
                                  ) +
                                    parseInt(
                                      pricelabour +
                                      priceshipping +
                                      priceloyality -
                                      discount?.amount +
                                      (taxes?.type !== 'Inclusive' ? taxes?.taxintamount : ''),
                                    )) *
                                  (depositprect?.value / 100),
                                  { addSymbol: true },
                                )
                                : 0.0}
                            </span> :
                            <span className="main-body-row-right-col-inner-summary-price-data-total-text text-dark ms-auto">
                              {cartdetails?.length
                                ? toFormatPrice(
                                  (cartdetails?.reduce(
                                    (interation, val) =>
                                    (interation =
                                      interation +
                                      parseInt(val.Qty) * toUnformatPrice(val?.price)),
                                    0,
                                  ) +
                                    parseInt(
                                      pricelabour +
                                      priceshipping +
                                      priceloyality -
                                      discount?.amount +
                                      (taxes?.type !== 'Inclusive' ? taxes?.taxintamount : ''),
                                    )) *
                                  (depositprect?.value / 100),
                                  { addSymbol: true },
                                )
                                : 0.0}
                            </span>}

                        </div>
                      }

                      {status_type !== 'pos_order' ?
                        ordertype === 'deposit' || ordertype === 'exchange_order' || ordertype === "refund_order" || ordertype === 'pos_order' || ordertype === 'gift_card_order' || reserve_status === 0 ? '' :
                          <div className="main-body-row-right-col-inner-summary-price-data-total">
                            <span className="main-body-row-right-col-inner-summary-price-data-total-text danger-text">
                              {t("Outstanding Balance")}
                            </span>
                            {ordertype === "repair_order" ?
                              <span className="main-body-row-right-col-inner-summary-price-data-total-text danger-text ms-auto">
                                {cartdetails?.length
                                  ? toFormatPrice(
                                    cartdetails?.reduce(
                                      (interation, val) =>
                                      (interation =
                                        interation +
                                        toUnformatPrice(val?.price)),
                                      0,
                                    ) +
                                    parseInt(
                                      pricelabour +
                                      priceshipping +
                                      priceloyality -
                                      discount?.amount +
                                      (taxes?.type !== 'Inclusive' ? taxes?.taxintamount : ''),
                                    ) -
                                    (cartdetails?.reduce(
                                      (interation, val) =>
                                      (interation =
                                        interation +
                                        toUnformatPrice(val?.price)),
                                      0,
                                    ) +
                                      parseInt(
                                        pricelabour +
                                        priceshipping +
                                        priceloyality -
                                        discount?.amount +
                                        (taxes?.type !== 'Inclusive'
                                          ? taxes?.taxintamount
                                          : ''),
                                      )) *
                                    (depositprect?.value / 100),
                                    { addSymbol: true },
                                  )
                                  : 0.0}
                              </span>
                              :
                              <span className="main-body-row-right-col-inner-summary-price-data-total-text danger-text ms-auto">
                                {cartdetails?.length
                                  ? toFormatPrice(
                                    cartdetails?.reduce(
                                      (interation, val) =>
                                      (interation =
                                        interation +
                                        parseInt(val.Qty) * toUnformatPrice(val?.price)),
                                      0,
                                    ) +
                                    parseInt(
                                      pricelabour +
                                      priceshipping +
                                      priceloyality -
                                      discount?.amount +
                                      (taxes?.type !== 'Inclusive' ? taxes?.taxintamount : ''),
                                    ) -
                                    (cartdetails?.reduce(
                                      (interation, val) =>
                                      (interation =
                                        interation +
                                        parseInt(val.Qty) * toUnformatPrice(val?.price)),
                                      0,
                                    ) +
                                      parseInt(
                                        pricelabour +
                                        priceshipping +
                                        priceloyality -
                                        discount?.amount +
                                        (taxes?.type !== 'Inclusive'
                                          ? taxes?.taxintamount
                                          : ''),
                                      )) *
                                    (depositprect?.value / 100),
                                    { addSymbol: true },
                                  )
                                  : 0.0}
                              </span>}

                          </div>
                        : ''
                      }

                    </div>
                  </div>
                  <div className="main-body-row-right-col-inner-footer">
                    <div className="main-body-row-right-col-inner-footer-row">
                      {ordertype === 'pos_order' ? (
                        <button
                          className={generalsettings?.data?.generalsetup?.isPark === '1' && cartdetails?.length ?
                            "btn main-body-row-right-col-inner-footer-row-btn status-pending" : "d-none"}
                          data-bs-toggle="modal"
                          data-bs-target="#parkModal"
                        >
                          {t("park")}
                        </button>
                      ) : ordertype === "custom_order" || ordertype === "mix_and_match" || ordertype === "repair_order" ? (
                        parmissiondatashow?.Quotation?.Add && generalsettings?.data?.generalsetup?.isQuotation === '1' && cartdetails?.length ?
                          <button
                            data-bs-toggle="modal"
                            data-bs-target="#quoteModal"
                            className="btn main-body-row-right-col-inner-footer-row-btn light-blue"
                          >
                            {t("Quote")}
                          </button>
                          : "") : ""}
                      {
                        ordertype === "exchange_order" ?
                          <button
                            data-bs-toggle="modal"
                            data-bs-target={ordertype === "exchange_order" ? "#checkoutorderModal" : "#checkoutorderrefoundModal"}
                            className={cartdetails?.length ? "btn checkout main-body-row-right-col-inner-footer-row-btn linear-bg w-100" : "btn checkout main-body-row-right-col-inner-footer-row-btn linear-bg w-100  btn-disabled pointer-none"}
                          >
                            {t("checkout")}
                          </button>
                          : <button
                            className={cartdetails?.length ? "btn checkout main-body-row-right-col-inner-footer-row-btn linear-bg w-100" : "btn checkout main-body-row-right-col-inner-footer-row-btn linear-bg w-100  btn-disabled pointer-none"}
                            onClick={() => callpaymentcheckout()}
                          >
                            {t("checkout")}
                          </button>
                      }
                    </div>
                    <p className="footer">{t("© 2022. All rights reserved.")}</p>
                  </div>
                </div>
              </div>


            </>
          }
        </div>

      </div >
      <div
        className="modal fade park-quote-modal common-modal"
        id="parkModal"
        tabIndex="-1"
        aria-labelledby="parkModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <h1 className="modal-title">{t("Park this sale?")}</h1>
              <p className="park-quote-modal-para">
                {t("Note for the sale you want to park")}
              </p>
              <div className="placeholder-up-input">
                <input
                  className="placeholder-up-input-field"
                  placeholder="Ex. Customer will come back in 10 minutes."
                  value={parksentence} onChange={(e) => setParksentence(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="com-btn-outline com-btn"
                data-bs-dismiss="modal"
              >
                {t("Cancel")}
              </button>
              <button onClick={callpark} data-bs-dismiss="modal" type="button" className="com-btn">
                {t("Save")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade park-quote-modal common-modal"
        id="quoteModal"
        tabIndex="-1"
        aria-labelledby="quoteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <h1 className="modal-title">{t("Add Note ?")}</h1>
              <p className="park-quote-modal-para">
                {t("Note for the order you want a quotation")}
              </p>
              <div className="placeholder-up-input">
                <input
                  className="placeholder-up-input-field"
                  placeholder="The customer wants to quotation."
                  value={quatesentence} onChange={(e) => setQuatesentence(e.target.value)}
                />
              </div>
              <div className="park-quote-modal-date-area">
                {t("Due Date")}
                <span className="d-flex align-items-cente main-green-text">
                  <div className="main-body-row-right-col-inner-ct-top-inner-date-area-inner-input date">
                    <img
                      className="date-img"
                      src={base_assets + 'images/icons/calendar-green.svg'}
                      alt=""
                    />
                    <DatePicker
                      className="date-field"
                      selected={TransactionDate}
                      onChange={selectTransactionDate}
                      name="TransactionDate"
                      dateFormat={OrgSettings?.date_picker}
                      autoComplete='none'
                      popperPlacement='top-start'
                    />
                  </div>
                </span>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="com-btn-outline com-btn"
                data-bs-dismiss="modal"
              >
                {t("Cancel")}
              </button>
              {<Link to="#">
                <button onClick={savequte} type="button"
                  className="com-btn-outline com-btn"
                  data-bs-dismiss="modal" >
                  {t("Save")}
                </button></Link>}
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade common-modal" id="checkoutorderrefoundModal" tabIndex="-1" aria-labelledby="checkoutModalLabel">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <img className='modal-body-img common-modal-exclamation-img' src={base_assets + "images/icons/exclamation-mark-green.png"} alt="" />
              <p className="modal-content-text">{t("Do you want to refund ?")}</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn modal-content-no"
                data-bs-dismiss="modal"
              >{t("No")}</button>
              {generalsettings?.data?.generalsetup?.Users_to_enter_PIN_for_every_sale === '1' ?
                <button type="button" className="btn modal-content-yes" data-bs-toggle="modal" data-bs-target="#pinModal">{t("yes")}</button>
                :
                <button type="button" className="btn modal-content-yes" data-bs-toggle="modal" data-bs-target="#pinModal" onClick=
                  {order_edit ? editcheckoutorder : ordercheckout}>{t("yes")}</button>
              }

            </div>
          </div>
        </div>
      </div>
      <div className="modal fade common-modal" id="checkoutorderModal" tabIndex="-1" aria-labelledby="checkoutModalLabel">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <img className='modal-body-img common-modal-exclamation-img' src={base_assets + "images/icons/exclamation-mark-green.png"} alt="" />
              <p className="modal-content-text">{t("Do you want to Exchange ?")}</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn modal-content-no"
                data-bs-dismiss="modal"
              >{t("No")}</button>
              {generalsettings?.data?.generalsetup?.Users_to_enter_PIN_for_every_sale === '1' ?
                <button type="button" className="btn modal-content-yes" data-bs-toggle="modal" data-bs-target="#pinModal">{t("yes")}</button>
                :
                <button type="button" className="btn modal-content-yes" data-bs-toggle="modal" data-bs-target="#pinModal" onClick=
                  {order_edit ? editcheckoutorder : ordercheckout}>{t("yes")}</button>
              }

            </div>
          </div>
        </div>
      </div>

      <div className={generalsettings?.data?.generalsetup?.Users_to_enter_PIN_for_every_sale === '0' && showorder === true ? "modal fade common-modal pin-modal show" : "modal fade common-modal pin-modal "} data-bs-backdrop="false" id="pinModal" tabIndex="-1" aria-labelledby="pinModalLabel">
        {showorder ?
          <div className="modal common-modal order-successful-modal d-block" id="orderSuccessfulModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <img className='order-successful-modal-img' src={base_assets + "images/pos/icons/check-success.png"} alt="" />
                  <h5 className='order-successful-modal-heading'>{t("Order Successful")}</h5>
                  <table className='order-successful-modal-table'>
                    <tbody>
                      <tr>
                        <td>{t("Order Summary")}</td>
                        <td>{checkoutdetails?.count} {t("Item")}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td> {t("Grand Total")}</td>
                        <td><span> {
                          toFormatPrice(checkoutdetails?.deposit_amount, { addSymbol: true })}</span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <div className='order-successful-modal-icons'>
                    <a className={parmissiondatashow?.pos_exchange?.Print ? 'btn-style' : "d-none"} href={checkoutdetails?.url ? checkoutdetails?.url : '/app/pos/custom/custom'} target={'_blank'} rel="noreferrer">
                      <img src={base_assets + "images/pos/icons/g-printer.png"} alt="" />
                    </a>
                    <button className='btn-style' data-bs-toggle="modal" data-bs-target="#emailmodal">
                      <img src={base_assets + "images/pos/icons/g-msg.png"} alt="" />
                    </button>
                    {/* <button className='btn-style'>
                      <img src={base_assets + "images/pos/icons/g-upload.png"} alt="" />
                    </button> */}
                  </div>
                  <Link to="/app/pos/menu" className="btn linear-bg order-successful-modal-btn">{t("BACK TO POS")}</Link>
                </div>
              </div>
            </div>
          </div>
          : generalsettings?.data?.generalsetup?.Users_to_enter_PIN_for_every_sale === '1' ?
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <button type="button" className="btn-style close" data-bs-dismiss="modal">
                  <img src={base_assets + "images/pos/icons/close-green.png"} alt="" />
                </button>
                <div className="modal-body">
                  <h5 className='pin-modal-heading'>{t("Enter PIN")}</h5>
                  <div className={'pin-modal-password active-' + otpnumber?.length + ''}>
                    <span className='pin-modal-password-inner'>
                      <span className='pin-modal-password-inner-dot'></span>
                    </span>
                    <span className='pin-modal-password-inner'>
                      <span className='pin-modal-password-inner-dot'></span>
                    </span>
                    <span className='pin-modal-password-inner'>
                      <span className='pin-modal-password-inner-dot'></span>
                    </span>
                    <span className='pin-modal-password-inner'>
                      <span className='pin-modal-password-inner-dot'></span>
                    </span>
                    <span className='pin-modal-password-inner'>
                      <span className='pin-modal-password-inner-dot'></span>
                    </span>
                    <span className='pin-modal-password-inner'>
                      <span className='pin-modal-password-inner-dot'></span>
                    </span>
                  </div>
                  <button className='pin-modal-forgot btn-style'>{t("Forgot PIN")}</button>
                  <div className='pin-modal-keys'>
                    <div className='pin-modal-keys-row'>
                      {[...new Array(10)].map((each, index) => {
                        return (
                          otpnumber?.length < 6 ?
                            <button className='btn-style pin-modal-keys-row-single' onClick={() => { setOtpnumber([...otpnumber, (index === 9 ? 0 : index + 1)]) }} key={index}>{index === 9 ? 0 : index + 1}</button>
                            :
                            <button className='btn-style pin-modal-keys-row-single' key={index}>{index === 9 ? 0 : index + 1}</button>
                        );
                      })}
                    </div>
                    <button className='btn-style clear' onClick={() => setOtpnumber(otpnumber.slice(0, -1))}>
                      <img src={base_assets + "images/pos/icons/clear-btn.png"} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            : ''
        }
      </div>
      {
        loader ?
          <div className="dot-loader-wrap">
            <div className="dot-loader">
              <div className='dot-loader-dot dot1'></div>
              <div className='dot-loader-dot dot2'></div>
              <div className='dot-loader-dot dot3'></div>
              <div className='dot-loader-dot dot4'></div>
              <div className='dot-loader-dot dot5'></div>
              <div className='dot-loader-dot dot6'></div>
              <div className='dot-loader-dot dot7'></div>
              <div className='dot-loader-dot dot8'></div>
            </div>
          </div>
          : ''
      }
      <CustomerInformation
        cusid={cusid}
        setCusid={setCusid} />
      {
        ordertype === 'exchange_order' ?
          <EmailModal type={ordertype} email={checkoutdetails?.email} id={checkoutdetails?.id} /> : ''
      }
      {
        <Servicelabourmodal
          setService_enable={setService_enable}
          setScrviceremark={setScrviceremark}
          setScrvicelist={setScrvicelist}
          setScrvicecharge={setScrvicecharge}
          scrvicelist={scrvicelist}
          scrviceremark={scrviceremark}
          cart_id={cart_id}
          GetCartdetail={GetCartdetail}
          order_edit={order_edit}
          order_type={order_type}
          order_id={order_id}
          product_price={product_price}
          service_enable={service_enable}
          setService_saved={setService_saved}
          service_saved={service_saved}
          servicelabourlist={servicelabourlist}
          setServicelabourlist={setServicelabourlist}
          setShowmodal={setShowmodal}
          setPricelabour={setPricelabour}
          edit={edit}
          showmodal={showmodal} />
      }
    </>
  )
}

export default CheckoutSell
