import React from 'react'

const ChangePasswordIcon = (props) => {
    const { width, height } = props
    return (
        <svg width={width ? width : "24"} height={height ? height : "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 7.00008C15.5304 7.00008 16.0391 7.21079 16.4142 7.58586C16.7893 7.96093 17 8.46964 17 9.00008M21 9.00008C21.0003 9.93725 20.781 10.8615 20.3598 11.6986C19.9386 12.5358 19.3271 13.2627 18.5744 13.821C17.8216 14.3792 16.9486 14.7535 16.0252 14.9136C15.1018 15.0737 14.1538 15.0153 13.257 14.7431L11 17.0001H9V19.0001H7V21.0001H4C3.73478 21.0001 3.48043 20.8947 3.29289 20.7072C3.10536 20.5196 3 20.2653 3 20.0001V17.4141C3.00006 17.1489 3.10545 16.8946 3.293 16.7071L9.257 10.7431C9.00745 9.91809 8.93857 9.04902 9.05504 8.19502C9.17152 7.34102 9.47062 6.52215 9.93199 5.79412C10.3934 5.0661 11.0062 4.44602 11.7287 3.97609C12.4512 3.50617 13.2665 3.19743 14.1191 3.07088C14.9716 2.94434 15.8415 3.00296 16.6693 3.24276C17.4972 3.48256 18.2637 3.89791 18.9166 4.46054C19.5696 5.02317 20.0936 5.71987 20.4531 6.50322C20.8127 7.28656 20.9992 8.13817 21 9.00008Z" stroke="#1D1D1F" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default ChangePasswordIcon