import React, { useEffect, useRef, useState } from 'react'
import './engravingcss.scss'
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import ImageUploading from 'react-images-uploading';
import ImageGallary from '../../../assets/icons/ImageGallary';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ArroeIcontop from '../../../assets/images/arrowIcontop.png'
import PlusIcon from '../../../assets/icons/PlusIcon';
import { _Api, _Apiauth } from '../../../../common/api';
import ConfirmationModal from './ConfirmationModal';
import LeftArrow from 'Ecommerce/common/icons/LeftArrow';

export default function Engraving({setActiveTab, flowMode, diamondProduct, engravingData, setEngravingData, setShowEngravingPage}) {

    const itemArray =  [
        { label: 'Left', value: 'Left' },
        { label: 'Right', value: 'Right' },
        { label: 'Center', value: 'Center' }
      ]

      const fontarray = [
        { value: 'Serif', label: 'Serif' },
        { value: 'Sans-serif', label: 'Sans-serif' },
        { value: 'Monospace ', label: 'Monospace ' },
      ]
    
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    const [DBimages, setDBImages] = useState();
    const navigate = useNavigate();
    const [images, setImages] = useState([]);
    const { t } = useTranslation();
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [engravingFormData, setEngravingFormData] = useState(engravingData ? engravingData : {customizeText: "", inorout: "", textposition: itemArray[0], fonts:fontarray[0], imageFile: {}, imgPosition: itemArray[0], preview: ""})
    const engravingPageRef = useRef();
    const showPopup = () => {
        // setIsPopupVisible(true);
        handleConfirm();
      };

      const handleConfirm = () => {
        console.log(engravingData, engravingFormData,"findEngraving data")
        setEngravingData((state)=>engravingFormData)
        setIsPopupVisible(false)
        setShowEngravingPage(false)
        setActiveTab('Final')
      };
    
      const handleCancel = () => {
        setIsPopupVisible(false);
      };





      const onChange = (imageList, addUpdateIndex) => {
       
        const file = imageList[0]?.file;
        console.log(file, "imageList")
        if(file){
            if (file.type.startsWith("image/")) {
               
            const reader = new FileReader();
            console.log(file.type.startsWith("image/"), reader?.result,"result")
            reader.onloadend = async () => {
                try {
                    const postdata = {
                        image: reader.result,
                    };
                    console.log(postdata, "postdaa")
                    let res = await _Api(postdata, `redis/uplaod`)
                    if (res.statusCode === 200) {
                        console.log(res?.url, "engraving logo")
                        setEngravingFormData((state)=>({...state, imageFile: {data_url : res?.url, name : file?.name, size : file?.size}}))
                    }
                }
                catch (err) {
                    console.log(err, "err")
                }
            };
            if (file) {
                reader?.readAsDataURL(file);
            }
        }
        }
        // setEngravingFormData((state)=>({...state, imageFile: imageList[0]}))
      };

      const handleCLoseICon = (index) =>{
        setEngravingFormData((state)=>({...state, imageFile : {}}))
      }

      const handelBacktosettings = () =>{
        setShowEngravingPage(false);
      }



      useEffect(()=>{
        if (engravingPageRef.current) {
            engravingPageRef.current.scrollIntoView({ behavior: 'smooth' });
          }
      },[])
  return (
    <div className='engraving' ref={engravingPageRef}>
        <div className='engraving-inner'>
            {/* <div className='engraving-inner-header'>
                <span>{t("Engraving")}</span>
            </div> */}
            
            <div className='engraving-inner-wrapper'>
                <div className='engraving-inner-wrapper-left'>
                <div className='engraving-inner-wrapper-left-title'>
                        <span>{t("Engrave")}</span>
                    </div>
                    <div className='engraving-inner-wrapper-left-content'>
                        <div className='engraving-inner-wrapper-left-content-checkboxholder'>
                            <div className='ecom-darkthemed-checkbox d-flex align-items-center gap-2'> 
                                <input 
                                onChange={(e)=>setEngravingFormData((state)=>({...state, inorout : 'Outside' }))} 
                                className="itemscheckboxs" 
                                type='checkbox' 
                                id="outside"
                                checked={engravingFormData?.inorout === "Outside" ? true : false}
                                />
                                <label className='ecom-darkthemed-checkbox-label' htmlFor='outside'></label>
                                <span>{t("Outside")}</span>
                            </div>
                            <div className='ecom-darkthemed-checkbox d-flex align-items-center gap-2'> 
                                <input 
                                onChange={(e)=>setEngravingFormData((state)=>({...state, inorout : 'Inside' }))}  
                                className="" 
                                type='checkbox' 
                                id="inside"
                                checked={engravingFormData?.inorout === "Inside" ? true : false}
                                />
                                <label className='ecom-darkthemed-checkbox-label' htmlFor='inside'></label>
                                <span>{t("Inside")}</span>
                                </div>
                        </div>
                    </div>
                    <div className='engraving-inner-wrapper-left-content'>
                        <div className='engraving-inner-wrapper-left-content-head light'>1. {t("Write your Text here")}</div>
                        <textarea className='engraving-inner-wrapper-left-content-textarea' placeholder='Type something' onChange={(e)=>setEngravingFormData((state)=>({...state, customizeText : e.target.value}))}>{engravingFormData?.customizeText}</textarea>
                    </div>
                   
                    <div className='engraving-inner-wrapper-left-content'>
                        <div className='engraving-inner-wrapper-left-content-head'>2. {t("Position")}</div>
                        <Select
                            options={itemArray}
                            className=""
                            height='46px'
                            width='100%'
                            isSearchable={false}
                            value={engravingFormData?.textposition}
                            onChange={(e)=>{setEngravingFormData((state)=>({...state, textposition : e}))}}
                            classNamePrefix='newDropdown'
                        />
                    </div>
                    <div className='engraving-inner-wrapper-left-content'>
                        <div className='engraving-inner-wrapper-left-content-head'>3. {t("Fonts")}</div>
                        <Select
                            options={fontarray}
                            className=""
                            height='46px'
                            width='100%'
                            isSearchable={false}
                            value={engravingFormData?.fonts}
                            onChange={(e)=>{setEngravingFormData((state)=>({...state, fonts : e}))}}
                            classNamePrefix='newDropdown'
                        />
                    </div>
                    <div className='engraving-inner-wrapper-left-content'>
                        <div className='engraving-inner-wrapper-left-content-head light'>{t("Preview")}</div>
                            <div
                                        className="engraving-inner-wrapper-left-content-preview"
                                        style={{fontFamily: engravingFormData?.fonts?.value, justifyContent: engravingFormData?.textposition?.value}}
                                      >
                                        <span   
                                       >
                                        {
                                          engravingFormData?.customizeText ? engravingFormData?.customizeText : "Message text goes here"
                                        }
                                        </span>
                                      </div>
                    </div>
                </div>
                <div className='engraving-inner-wrapper-right'>
                {/* <div className='engraving-inner-wrapper-left-title'>
                        <span>{t("Image")}</span>
                    </div> */}
                <div className='engraving-inner-wrapper-left-content'>
                        <div className='engraving-inner-wrapper-left-content-head light'>1. {t("Upload Image")}</div>
                        <div className='engraving-inner-wrapper-left-content-dragdrop'>
                        <ImageUploading
                            value={images}
                            onChange={onChange}
                            // maxNumber={maxNumber}
                            dataURLKey="data_url"
                            >
                            {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                            }) => (
                                <div className={`engraving-inner-wrapper-left-content-dragdrop-inner ${isDragging ? 'border-active' : ""}`}
                                {...dragProps}  onClick={onImageUpload}>
                                        <div className="engraving-inner-wrapper-left-content-dragdrop-inner-container">
                                        <div><ImageGallary/></div>
                                        <div className="engraving-inner-wrapper-left-content-dragdrop-inner-container-message">
                                            {/* <span
                                            className='heighlight'
                                           
                                            >
                                           {t("Click to upload")}
                                            </span> */}
                                            <span>{("Choose a file or drag & drop it here")} </span>
                                        </div>
                                        <div className='engraving-inner-wrapper-left-content-dragdrop-inner-container-submsg'>{("only JPEG, PNG up to 500KB")}</div>
                                        </div>
                                </div>
                            )}
                            </ImageUploading>
                            
                        </div>
                    </div>
                    {/* <div className='engraving-inner-wrapper-left-content-subdata'>{t("Only support .jpg, .png, .svg and zip files")}</div> */}
                
                {/* <div className='engraving-inner-wrapper-left-barrier'><div className='engraving-inner-wrapper-left-barrier-line'></div><div className='or'>{t("OR")}</div><div className='engraving-inner-wrapper-left-barrier-line'></div></div> */}
                
                {/* <div className='engraving-inner-wrapper-left-title'>
                        <span>{t("Upload from URL")}</span>
                    </div> */}

                    {/* <div className='engraving-inner-wrapper-left-uploadurl'>
                            <input className='' placeholder={t("Add file URL")} />
                            <button>{t("Upload")}</button>
                    </div> */}
                   
                            {engravingFormData?.imageFile?.data_url ? 
                             <div className='engraving-inner-wrapper-left-imagelist'>
                                        <div className='engraving-inner-wrapper-left-imagelist-data'>
                                            <div className='engraving-inner-wrapper-left-imagelist-data-content'>
                                                <img src={engravingFormData?.imageFile?.data_url} alt='logo'/>
                                                <div className='engraving-inner-wrapper-left-imagelist-data-content-details'>
                                                    <div className='name'>{engravingFormData?.imageFile?.name}</div>
                                                    <div className='size'>{engravingFormData?.imageFile?.size ? engravingFormData?.imageFile?.size : ""}</div>
                                                </div>
                                            </div>
                                            <div className='engraving-inner-wrapper-left-imagelist-data-closeicon' onClick={()=>handleCLoseICon()}>
                                                <PlusIcon/>
                                            </div>
                                        </div>
                                </div>
                        : ""}
                    
                    <div className='engraving-inner-wrapper-left-content'>
                        <div className='engraving-inner-wrapper-left-content-head'>2. {t("Position")}</div>
                        <Select
                            options={itemArray}
                            className=""
                            height='46px'
                            width='100%'
                            isSearchable={false}
                            value={engravingFormData?.imgPosition}
                            onChange={(e)=>{setEngravingFormData((state)=>({...state, imgPosition : e}))}}
                            classNamePrefix='newDropdown'
                        />
                    </div>
                </div>
            </div>
            <div className='engraving-inner-footer'>
            <div className="" onClick={()=>handelBacktosettings()}>
                <span className="diamond-product-details-section-buttons-backtosetting-img"><LeftArrow/></span> <span className="diamond-product-details-section-buttons-backtosetting-title">{t("Back")}</span>
            </div>
            <div 
                                        onClick={showPopup}
                                        className="btnbold">
                                        <span>{t("Next")}</span>
                                    </div>
            </div>
        </div>
        <ConfirmationModal 
          isVisible={isPopupVisible}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          message="Confirm Customize Your Item"
        />
    </div>
  )
}
