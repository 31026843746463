import CloseIcon from 'admin/common/icons/CloseIcon';
import LinkAttachmentIcon from 'admin/common/icons/LinkAttachmentIcon';
import RightArrow from 'admin/common/icons/right-arrow';
import SearchIcon from 'admin/common/icons/SearchIcon';
import FilterIconMain from 'admin/common/icons/sidebar/FilterIconMain';
import TableLoader from 'admin/common/TableLoader';
import { Tablehead } from 'components/common/modules/Tablehead';
import { Pagination } from 'components/common/Pagination';
import { zoneAddInventory } from 'components/common/Tablehead';
import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import Select from "react-select";
import ZoneFilter from './ZoneFilter';
import Apiauth from '../../API/Apiauth.js';

export default function AddInventory({ selectedLocation, handleBackToParent, setPoProductId }) {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [limit, setLimit] = useState(100);
  const [total, setTotal] = useState(100);
  const [filtername, setFiltername] = useState("");
  const [datalodaer, setDatsLodaer] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [state, setstate] = useState({ totalPages: 20, currentPage: 1, });
  const [inventoryData, setInventoryData] = useState([])
  const [searchFlag, setSearchFlag] = useState(false)
  const [filterItemData, setFilterItemData] = useState({ item: [], collection: [], metal: [], stone: [], size: [], location: [] })
  const [searchWithFilter, setSearchWithFilter] = useState(false)
  const [selectAllStatus, setSelectAllStatus] = useState(false);
  const [selecteditem, setSelecteditem] = useState([]);

  const { totalPages, currentPage } = state;
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]

  const getlimit = (limit) => {
    setLimit(limit);
  };

  const getInventoryList = useCallback(
    async (page = currentPage, firstLimit = limit, postdataitem) => {

      setDatsLodaer(true);
      try {

        const postdata = {
          search: openSearch ? filtername : "",
          item: postdataitem?.item ? postdataitem?.item : [],
          collection: postdataitem?.collection ? postdataitem?.collection : [],
          metal: postdataitem?.metal ? postdataitem?.metal : [],
          size: postdataitem?.size ? postdataitem?.size : [],
          stone: postdataitem?.stone ? postdataitem?.stone : [],
          location: [selectedLocation],
          limit: firstLimit,
          skip: (page - 1) * firstLimit,
        };
        let apiRes = await Apiauth(postdata, `zone/get-inventory-list`);

        if (apiRes.code === 200) {
          setInventoryData(apiRes.data);
          setTotal(apiRes.count);
          setSelectAllStatus(false);

          setstate((prevPages) => ({
            ...prevPages,

            totalPages: Math.ceil(apiRes.count / postdata.limit),

          }));
        }
      } catch (err) {
        console.error(err, "API Error");
      } finally {
        setDatsLodaer(false);
      }
    },
    [currentPage, limit, openSearch, filtername, selectedLocation]
  );

  useEffect(() => {
    getInventoryList(currentPage, limit, filterItemData);
  }, [currentPage, limit, searchFlag, searchWithFilter, getInventoryList, filterItemData]);



  const handlePaginations = (current) => {

    setstate({ ...state, currentPage: current });


  };

  const getSearchData = (key = "") => {
    if (openSearch && key === "") {
      setSearchFlag(!searchFlag)
    }
    if (key === "Enter") {
      setSearchFlag(!searchFlag)

    }
  }



  const handleChange = (event) => {
    if (event.target.checked) {
      const mainselected = [...selecteditem, event.target.value];

      setSelecteditem(mainselected);
    } else {
      const result = selecteditem.filter((element) => {
        return element !== event.target.value;
      });
      setSelecteditem(result);
    }


  }
  let selectAllStatus_arr = [];

  useEffect(() => {
    var allCheckbox = document.querySelectorAll('.zone_addinventory');
    if (selectAllStatus === true) {
      allCheckbox.forEach(function (checkBox_selectAble) {
        checkBox_selectAble.checked = true;
        if (checkBox_selectAble.value !== "on") {
          selectAllStatus_arr.push(checkBox_selectAble.value);
        }
      });
      let arrya = [];
      for (let i = 0; i < allCheckbox.length; i++) {
        if (allCheckbox[i].checked) {
          let product_id = allCheckbox[i].getAttribute('data-id');
          if (product_id !== "on") {
            arrya.push(product_id);
          }
        }
      }
      setSelecteditem(arrya);
    } else {
      allCheckbox.forEach(function (checkBox_selectAble) {
        checkBox_selectAble.checked = false;
      });
      setSelecteditem([]);
    }
    setSelecteditem(selectAllStatus_arr);

    // eslint-disable-next-line
  }, [selectAllStatus]);

  const onConfirm = () => {
    setPoProductId(selecteditem);
    handleBackToParent();
  }

  return (
    <>
      <div className="main-body zone_module">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-between">
              <h1 className="main-body-heading">
                <span>
                  <Link onClick={handleBackToParent} className='text-decoration-none'> {t('Create Issue')}</Link> <span className='ms-2'><RightArrow /></span>
                </span>
                {t("Add Inventory")}
              </h1>
              <Link
                onClick={onConfirm}
                className="confirmbtn"
              >
                {t("Confirm")}
              </Link>
            </div>
            <div className='zone_module-inner'>
              <div className="main-body-top-status-bar">
                <Select
                  className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                  isSearchable={false}
                />

                <div className="main-body-top-status-bar-multi-date-picker">
                  <div className={`main-body-top-status-bar-multi-date-picker-iconholder ${openSearch ? 'active' : ''}`}><span onClick={() => { setOpenSearch(true); getSearchData(); }}><SearchIcon color="#A4A4A4" /></span><div className={`main-body-top-status-bar-multi-date-picker-iconholder-searchbar ${openSearch ? 'active' : ""}`}>
                    <input
                      type="text"
                      className='main-body-top-status-bar-filter-input'
                      value={filtername}
                      placeholder={t("Search")}
                      onChange={(e) => setFiltername(e.target.value)}
                      onKeyDown={(e) => getSearchData(e.key)}
                    />
                    <span onClick={() => setOpenSearch(false)}><CloseIcon /></span> </div></div>
                  <span onClick={() => setToggleFilter(!toggleFilter)} className='main-body-top-status-bar-multi-date-picker-iconholder'><FilterIconMain width="24px" height="24px" /></span>


                </div>
              </div>
              <div className='zone_module-inner-table'>
                <div
                  className="main-body-main-table-wrap position-relative"
                  style={{ maxWidth: '100%', overflowX: 'auto' }}
                >
                  <table
                    id="my_inventory"
                    className="stripe row-border order-column common-table table-striped main-table w-100"
                  >
                    <Tablehead tablehead={zoneAddInventory} tblName={'zoneEditcreate_table'} setShowoption={setSelectAllStatus} />
                    <tbody>
                      <>

                        {inventoryData?.length ? inventoryData.map((result, key) => {
                          return (
                            <tr key={key} className="">
                              <td>
                                <div className={'com-check'}>
                                  <input key={key}
                                    className='inventorytablecheck inventorytableHead gisClassTh zone_addinventory'
                                    id={result?.id}
                                    data-id={result?.id}
                                    value={result?.id}
                                    type="checkbox"
                                    onChange={(e) => handleChange(e)}
                                  />
                                  <label htmlFor={result?.id} className="com-check-label" />
                                </div>
                              </td>
                              <td className="">{key + 1}</td>
                              <td className="table-pro-img" >
                                <img src={result?.main_image} alt={key + 1} />
                              </td>
                              <td className="">
                                {result?.SKU ? result?.SKU : "-"}
                              </td>
                              <td>
                                <div className="tooltip-area-up">{result?.item_name ? result?.item_name : "-"}</div>
                              </td>
                              <td>
                                <div className="tooltip-area-up">{result?.size_name ? result?.size_name : "-"}</div>
                              </td>
                              <td>
                                <div className={`tooltip-area-up ${result?.pos_Qty > 0 ? 'stockactive cursor-pointer' : ""}`}>{result?.stock_id ? result?.stock_id : "-"} {result?.pos_Qty > 0 ? <LinkAttachmentIcon /> : null} </div>
                              </td>
                              <td className="">{result?.po_QTY ? result?.po_QTY : "-"}</td>

                              <td className=''>
                                {
                                  result?.status_type === "stock" ?
                                    <div className="status-dot light-blue-bg  tooltip-area-up"><span className='tooltip-area-up-text'>{t("Stock")}</span></div> :
                                    result?.status_type === "transit" ?
                                      <div className="status-dot green-bg  tooltip-area-up"><span className='tooltip-area-up-text'>{t("Transit")}</span></div> :
                                      result?.status_type === "warehouse" ?
                                        <div className="status-dot status-gray-bg tooltip-area-up"><span className='tooltip-area-up-text'>{t("Warehouse")}</span></div> :
                                        result?.status_type === "reserve" ?
                                          <div className="status-dot yellow-bg tooltip-area-up"><span className='tooltip-area-up-text'>{t("Reserve")}</span></div> :
                                          result?.status_type === "repair_order" ?
                                            <div className="status-dot purple-bg tooltip-area-up"><span className='tooltip-area-up-text'>{t("Repair Order")}</span></div> :
                                            <div className="status-dot danger-bg tooltip-area-up"><span className='tooltip-area-up-text'>{t("Custom Order")}</span></div>
                                }
                              </td>





                            </tr>
                          );
                        }) : ''}
                      </>
                    </tbody>
                  </table>
                  {datalodaer && <TableLoader />}
                </div>
              </div>
              <div className='zone_module-inner-bottom'>
                <div>
                  {t("Showing")} {total > 0 ? ((currentPage * limit) - limit + 1) : 0} {t("to")} {currentPage * limit <= total ? currentPage * limit : total} {t("of")} {total} {t("Entries")}
                </div>
                {inventoryData.length > 0 ? (
                  <Pagination
                    total={totalPages}
                    current={currentPage}
                    pagination={(crPage) => handlePaginations(crPage)}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className='zone_module-inner-back' onClick={() => navigate(-1)}>
                {t("Back")}
              </div>
            </div>
          </div>
        </div>
      </div>
      {toggleFilter &&
        <ZoneFilter setToggleFilter={setToggleFilter} setFilterItemData={setFilterItemData} setSearchWithFilter={setSearchWithFilter} filterItemData={filterItemData} />
      }
    </>
  )
}
