import React from 'react'

const RightArrowIcon = (props) => {
    const { width, height, wvw } = props;
    return (
        <svg width={wvw ? wvw : width ? width : "24"} height={height ? height : "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <g clipPath="url(#clip0_3289_402663)">
                <path d="M13 3.29297L4.29297 12L13 20.707L13.707 20L6.20697 12.5H19.5V11.5H6.20697L13.707 3.99997L13 3.29297Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_3289_402663">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg >
    )
}

export default RightArrowIcon