import React, { useEffect, useState,useRef } from 'react'
import Earth from '../../../common/icons/Earth'
import AppointmentStandardIcon from '../../../common/icons/AppointmentStandardIcon'
import CustomerSupportIcon from '../../../common/icons/CustomerSupportIcon'
import DiamondIcon from '../../../common/icons/DiamondIcon'
import SearchIcon from '../../../../admin/common/icons/SearchIcon'
import { useTranslation } from 'react-i18next'
import Calendar from 'admin/common/icons/sidebar/Calendar'
import { _Api } from '../../../common/api';
export default function BottomBar({withTitle}) {
    const bottomRef = useRef(null)
    const [barData ,setBarData] = useState([])

    const BottomBarData = async () => {
        try {
            let postdata = {
                slug:["block-info"]
            }
            let res = await _Api(postdata, `Cms/get-ecom-blocks`)
            if (res?.status === 200) {
               
                console.log(res,'slug data')
                setBarData(res?.data)
                
                if (bottomRef.current) {
                  const content = res?.data && Array.isArray(res.data) && res.data[0]?.content
                    ? res.data[0].content
                    : "";
                  bottomRef.current.innerHTML = content;
                }
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
      useEffect(()=>{
 BottomBarData();
      },[])
    const {t} = useTranslation();
   
  return (
    <div className="ecommerce-landingpage-bottombar">
        {withTitle ? 
        <div className="ecommerce-landingpage-bottombar-title">
        {t("Our Services")}
        </div>
        : null }
        <div ref={bottomRef}></div>

</div>
  )
}


