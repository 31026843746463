import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from "react-select";
import HeartIcon from '../../assets/icons/HeartIcon';
import SortIcon from '../../assets/icons/SortIcon';
import Slider from 'rc-slider';
import { useTranslation } from 'react-i18next';
import { _Api } from '../../../common/api';
import MixandMatchProduct from './MixandMatchProduct';
import ArrowDownside from '../../assets/icons/ArrowDownSide';
import Spinner from '../../../common/Spinner';
import NoProductAvilable from '../../../common/NoProductAvilable';

export default function SelectProduct({reviewProduct, reviewProductId, getProductFilters, setGetProductFilters, productListArray, setShowEngravingPage, setProductdetail, setActiveTab, setProductListArray, productDetail, flowMode, mobileTab, diamondProduct, setDiamondProduct, engravingData, setEngravingData}) {
 const {t} = useTranslation();
 let isArabic = localStorage.getItem('i18nextLng');
 const [productPageFilters, setProductPageFilters] = useState([]);
 const [step1filters, setStep1filters] = useState(getProductFilters ? getProductFilters : {itemType: "6355f7a8bc8a8f3720133d21", settingStyle:[], shape: [], price:[], metal:[]});
 const [wishlistdata, setWishliatdata] = useState({ id: "", remove: false, edit: false });
 const [showProductDetailPage, setShowProductDetailPage] = useState(false);
 const [activeProductId, setActiveProductId] = useState();
 const productpageRef = useRef();
 const [skip, setSkip] = useState(0)
 const [totaldata, setTotaldata] = useState();
 const [shortobj, setShortobj] = useState({})
 const [loadeing, setloading] = useState(true);
 const [shortByActiveTab, setShortByActiveTab] = useState('')
 const [dataload, setDataload] = useState(false)

 const options = productPageFilters?.itemList?.map(item => ({
    value: item._id,
    label: item.name
  })) || [];

  useEffect(()=>{
    if (productpageRef.current) {
      productpageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    getFilters();
    if(reviewProductId?.temp_cart_id && productDetail){
      setShowProductDetailPage(true);
      console.log(productDetail, "check productdetails on selectProduct")
    }
  },[])

  const getFilters = async() => {
    try {
      let prams =  {
    }
      let res = await _Api(prams, `mixMatchProducts/get-mixmatch-productsfilters`)
      if (res?.status === 200) {
         setProductPageFilters((prev)=> prev = res?.data);
         setStep1filters((state)=>({...state, price: [res?.data?.Price?.[0]]}))
      }
  }
  catch (err) {
      console.log(err, "err")
  }
  }

  useEffect(()=>{
    getDataList();
  },[step1filters, shortobj])
  
  const getDataList = async(skipdata) =>{
  
    try {
      let prams = 
       {
        "item": step1filters?.itemType,
        "limit": 12,
        "skip": skipdata ? skipdata : 0,
        "sort": shortobj?.value ? {[shortobj?.key]: shortobj?.value} : {name : 1},
        "shape": step1filters?.shape,
        "setting_type": step1filters?.settingStyle,
        "metal": step1filters?.metal,
        "price":[
        {
            "min":step1filters?.price?.[0]?.min,
            "max": step1filters?.price?.[0]?.max
        }
    ]
    }
    if (prams?.skip === 0) {
      setloading(true)
    } else {
      setDataload(true)
    }
      let res = await _Api(prams, `mixMatchProducts/get-mixmatch-products-list`)
      if (res?.status === 200) {
        setSkip(12 + prams?.skip)
       
        if (prams?.skip === 0) {
          setProductListArray(res?.data);
        } else {
          let finaldata = productListArray.concat(res?.data)
          setProductListArray(finaldata);
        }
        setTotaldata(res?.total);
        setloading(false)
        setDataload(false)
      }
  }
  catch (err) {
      console.log(err, "err")
  }
  }

  const handleChange = selectedOption => {
    setStep1filters(prev => ({
      ...prev,
      itemType: selectedOption ? selectedOption.value : null
    }));
  };

  const selectedOption = options?.find(option => option.value === step1filters?.itemType);
  const handlePriceSlider = (e) =>{
    setStep1filters(state => 
      ({...state, "price" : [{min : e[0], max : e[1]}] })
    )
  }

  const handleMinInputChange = (e) => {
    const value = Number(e.target.value);
    const newLavel = step1filters?.price?.[0]
    newLavel.min = value;
    if (value >= productPageFilters?.Price?.[0]?.min && value <= step1filters?.price?.[0]?.max) { // Ensure min is less than max
      setStep1filters(state => 
        ({...state, "price" : [newLavel]})
      )
    }
  };
  
  const handleMaxInputChange = (e) => {
    const value = Number(e.target.value);
    const newLavel = step1filters?.price?.[0]
    newLavel.max = value;
    if (value >= step1filters?.price?.[0]?.min && value <= productPageFilters?.Price?.[0]?.max) { // Ensure min is less than max
      setStep1filters(state => 
        ({...state, "price" : [newLavel]})
      )
    }
  };


  const handlePrdouctClick = async(slug, itemid) =>{
    let localRecentViewed = localStorage.getItem('RecentViewed') ? JSON.parse(localStorage.getItem('RecentViewed')) : [];
        setTimeout(() => {
            if (!localRecentViewed.filter(e => e === itemid).length) {
                localRecentViewed.push(itemid);
                localStorage.setItem('RecentViewed', JSON.stringify(localRecentViewed));
            }
        }, 15000)
    try {
      let params = {
        "id": slug,
        "Metal": "",
        "Size": "",
        "Stone": "",
        "type": "catalog"
    }
      let res = await _Api(params, `mixMatchProducts/get-mixmatch-productdetailsById`)
      if (res?.code === 200) {
        console.log("product from selectproduct", res?.data)
        setProductdetail({"productdetails" : res?.data});
         setGetProductFilters(step1filters);
        setShowProductDetailPage(true);
      }
  }
  catch (err) {
      console.log(err, "err")
  }
  }

const handleFilterChange = (label, value) =>{
  let updatedArray = step1filters[label];
  let CheckIndex = updatedArray.some(item => 
    item === value._id);
  if (CheckIndex) {
    updatedArray = updatedArray.filter(item => item !== value?._id);
} else {
    updatedArray.push(value?._id);
}
  setStep1filters(state => 
      ({...state, [label]: updatedArray })
  )
}

const handleClear = (tab) => {
  if(tab === 'itemType'){
    setStep1filters((state)=>({...state, 'itemType' : ""}))
  }else{
    if(tab === 'price'){
      console.log(productPageFilters, "product page filters")
      setStep1filters((state)=>({...state, [tab] : productPageFilters?.Price}))
    }else{
    setStep1filters((state)=>({...state, [tab] : []}))
    }
  }
}

    return (
    <> 
    { !showProductDetailPage ? 
    <>
    <div className='mixandmatchpage-wrapper-filters' ref={productpageRef}>
    <div className={`mixandmatchpage-wrapper-filters-container ${mobileTab === 2 ? 'actibeTab' : ''}`}>
       
       {/* Item filter */}
        <div className="mixandmatchpage-wrapper-filters-container-item">
          <div className="headings"><span className='headings-name'>{t("Choose an item")}</span><span onClick={()=>handleClear('itemType')} className='headings-close'>{t("Clear")}</span></div>
          <div className='mixandmatchpage-wrapper-filters-container-item-inner'>
          <Select
              options={options}
              className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
              height='46px'
              width='100%'
              value={selectedOption || null}
              onChange={handleChange}
              isSearchable={false}
            />
          </div>
        </div>

        {/* setting-style filter */}
        { productPageFilters?.SettingType?.length > 0 ?
             <div className="mixandmatchpage-wrapper-filters-container-style">
             <div className="headings"><span className='headings-name'>{t("Setting Style")}</span><span className='headings-close' onClick={()=>handleClear('settingStyle')}>{t("Clear")}</span></div>
                 <div className="mixandmatchpage-wrapper-filters-container-style-wrapper">
                   {productPageFilters?.SettingType?.map((style, i)=>{
                     return(
                         <div className={`mixandmatchpage-wrapper-filters-container-style-wrapper-item ${step1filters?.settingStyle?.includes(style._id) ? 'active' : ""}`} key={i} onClick={()=>handleFilterChange("settingStyle", style)}>
                           <div className="mixandmatchpage-wrapper-filters-container-style-wrapper-item-image"><img src={style.logo} alt={style.name}/></div>
                           <div className='mixandmatchpage-wrapper-filters-container-style-wrapper-item-tag'>{style.name}</div>
                         </div>
                     )
                   })}
               </div>
             </div>
             :
             ""
        }
       
       
        {/* shape filter */}
        { productPageFilters?.Shape?.length > 0 ?
        <div className="mixandmatchpage-wrapper-filters-container-shape">
        <div className="headings"><span className='headings-name'>{t("Shape")}</span><span onClick={()=>handleClear('shape')} className='headings-close'>{t("Clear")}</span></div>
            <div className="mixandmatchpage-wrapper-filters-container-shape-inner">
              {productPageFilters?.Shape?.map((shapes,i)=>{
                return(
                  <div className={`mixandmatchpage-wrapper-filters-container-shape-inner-diamond ${step1filters?.shape?.includes(shapes._id) ? 'active' : ""}`} key={i} onClick={()=>handleFilterChange("shape", shapes)}>
                    <div className='mixandmatchpage-wrapper-filters-container-shape-inner-diamond-imgholder'><img src={shapes?.logo} alt={shapes?.name}/></div>
                    <span className=''>{shapes?.name}</span>
                  </div>
                )
              })}
            </div>
        </div>
        :""}
        <hr></hr>

        {/* Metal Filter */}
        { productPageFilters?.Metal?.length > 0 ?
        <div className="mixandmatchpage-wrapper-filters-container-metal">
        <div className="headings"><span className='headings-name'>{t("Metal")}</span><span className='headings-close' onClick={()=>handleClear("metal")}>{t("Clear")}</span></div>
          { productPageFilters?.Metal?.map((metal,i)=>{
            return(
              <div className={`mixandmatchpage-wrapper-filters-container-metal-content ${step1filters?.metal?.includes(metal._id) ? 'active' : ""}`} key={i} >
                <div className=''>
                  <input className="fill-com-check form-check-input filter-checkbox d-inline-block m-0 cursor-pointer" type='checkbox' checked={step1filters?.metal?.includes(metal._id) ? true : ""} onChange={()=>handleFilterChange("metal", metal)}/>
                 </div>
                <label className='form-check-label cursor-pointer'>{metal?.name}</label>
                </div>
            )
          })

          }
        </div>
        : "" }
        <hr></hr>

        {/* price Filter */}
        {productPageFilters?.Price?.length > 0 ?
        <div className="mixandmatchpage-wrapper-filters-container-price">
        <div className="headings"><span className='headings-name'>{t("Price")}</span><span className='headings-close' onClick={()=>handleClear("price")}>{t("Clear")}</span></div>
        <div className='mixandmatchpage-wrapper-filters-container-price-rangebar'>
          {/* <input className='mixandmatchpage-wrapper-filters-container-price-rangebar-range' type='range' min="10,000" max="52,500"/> */}
          <Slider
              range
              min={productPageFilters?.Price?.[0]?.min}
              max={productPageFilters?.Price?.[0]?.max}
              onChange={handlePriceSlider}
              value={[step1filters?.price?.[0]?.min, step1filters?.price?.[0]?.max]}
            />
          <div className='mixandmatchpage-wrapper-filters-container-price-rangebar-limitation'>
            <div className='mixandmatchpage-wrapper-filters-container-price-rangebar-limitation-min'>
              <div>{t("Min Price:")}</div>
              <input 
               type="number"
               value={step1filters?.price?.[0]?.min}
               onChange={handleMinInputChange}
              />
            </div>
            <div className='mixandmatchpage-wrapper-filters-container-price-rangebar-limitation-min'>
              <div>{t("Max Price:")}</div>
              <input 
               type="number"
               value={step1filters?.price?.[0]?.max}
               onChange={handleMaxInputChange}
              />
            </div>
          </div>
        </div>
        </div>
        :""}
    </div>
   
    </div>
    <div className='mixandmatchpage-wrapper-container'>
          <div className='mixandmatchpage-wrapper-container-navbar'>
            <div className='mixandmatchpage-wrapper-container-navbar-details'>{t("Showing")} {productListArray?.length} {t("of")} {totaldata} {t("items")}</div>
            <div className='mixandmatchpage-wrapper-container-navbar-sortby'
                  id="headeraccountdropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false" 
            >
              <span className='mixandmatchpage-wrapper-container-navbar-sortby-text'>{t("Sort by")}</span>
              <span className='mixandmatchpage-wrapper-container-navbar-sortby-icon'><SortIcon width={14} height={16} /></span>
              <ul
                className="dropdown-menu sortByDropdown-List-box"
                aria-labelledby="headeraccountdropdown"
              >
                <li className={shortByActiveTab === 'bestselling' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => setShortByActiveTab('bestselling')}>
                  {t("Best selling")}
                </li>
                <li className={shortByActiveTab === 'nameascending' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "name", value: 1 }); setShortByActiveTab('nameascending') }} >
                  {t("Alphabetically, A-Z")}
                </li>
                <li className={shortByActiveTab === 'namedescending' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "name", value: -1 }); setShortByActiveTab('namedescending') }}>
                  {t("Alphabetically, Z-A")}
                </li>
                <li className={shortByActiveTab === 'pricehtol' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "price", value: -1 }); setShortByActiveTab('pricehtol') }}>
                  {t("Price, high to low")}
                </li>
                <li className={shortByActiveTab === 'priceltoh' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "price", value: 1 }); setShortByActiveTab('priceltoh') }} >
                  {t("Price, low to high")}
                </li>
                <li className={shortByActiveTab === 'oldtonew' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "createdAt", value: 1 }); setShortByActiveTab('oldtonew') }} >
                  {t("Date, old to new")}
                </li>
                <li className={shortByActiveTab === 'newtoold' ? 'avtive' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "createdAt", value: -1 }); setShortByActiveTab('newtoold') }}>
                  {t("Date, new to old")}
                </li>
              </ul>
            </div>
          </div>

          {loadeing ?
            (<div className=" w-100 d-flex justify-content-center align-items-center  ">
              <Spinner />
            </div>) :
          <div className='mixandmatchpage-wrapper-container-content'>
                    <div className='mixandmatchpage-wrapper-container-content-inner'>
                        { productListArray.length > 0 &&
                            productListArray.map((item, key) => {
                                return (

                                    <div 
                                    // to={`/mixandmatch/${item?.Slug}`} 
                                    // state={flowMode === 'fromDiamond' ? {id: item?._id, Slug: item?.Slug , filters: step1filters, diamondProduct: diamondProduct, flowMode : flowMode } :{ id: item?._id, Slug: item?.Slug , filters: step1filters, productDetail: productDetail, flowMode  }} 
                                    onClick={()=>handlePrdouctClick(item?.Slug, item?._id)} 
                                    className='mixandmatchpage-wrapper-container-content-inner-product' 
                                    key={key}>
                                        <div className='mixandmatchpage-wrapper-container-content-inner-product-image'>
                                            <div className="mixandmatchpage-wrapper-container-content-inner-product-image-imgBoxShow"><img src={item?.main_image} alt='product' /></div>
                                            <div className={isArabic === 'arabic' ? 'heartIconBoxrtl' : 'heartIconBox'} onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}>
                                                {item?.isWishlist ?
                                                    <i onClick={() => {
                                                        setWishliatdata((state) => ({
                                                            ...state,
                                                            id: item?._id,
                                                            add: false,
                                                            remove: true,
                                                            index: key
                                                        }))
                                                    }} className="fa fa-sharp fa-solid fa-heart text-danger" style={{ fontSize: "20px" }}></i> :
                                                    <span
                                                        onClick={() => {
                                                            setWishliatdata((state) => ({
                                                                ...state,
                                                                id: item?._id,
                                                                add: true,
                                                                remove: false,
                                                                index: key
                                                            }))
                                                        }
                                                        }>   <HeartIcon color={"red"} width={24} height={24} /></span>}
                                            </div>


                                        </div>
                                        <div className="mixandmatchpage-wrapper-container-content-inner-product-details text-decoration-none ">
                                            <div className='mixandmatchpage-wrapper-container-content-inner-product-details-wrapper'>
                                                <div className="mixandmatchpage-wrapper-container-content-inner-product-details-wrapper-productName">
                                                    {item?.name}
                                                </div>
                                                <span>{item?.SKU} </span>
                                            </div>
                                            <div className='mixandmatchpage-wrapper-container-content-inner-product-price'>
                                                {item?.price}
                                            </div>
                                        </div>
                                    </div>

                                )
                            })
                        }
                    </div>
          </div>
            }

          {skip < totaldata && productListArray.length ? 
              dataload ? 
                <div className=" w-100 d-flex justify-content-center align-items-center">
                  <Spinner />
                </div> 
                :
                <div className="text-center cursor-pointer loadMoreBtnMain" 
                onClick={() => getDataList(skip)}
                > 
                  <span>{t("Load more")}</span> <ArrowDownside />
                </div>
              : 
              ""}
    </div>
    </>
    :
    <MixandMatchProduct reviewProduct={reviewProduct} diamondProduct={diamondProduct}  productDetail={productDetail} getProductFilters={getProductFilters} productPageFilters={step1filters} setGetProductFilters={setGetProductFilters} setShowEngravingPage={setShowEngravingPage}  activeProductId={activeProductId} setProductdetail={setProductdetail} flowMode={flowMode} setActiveTab={setActiveTab} setShowProductDetailPage={setShowProductDetailPage} engravingData={engravingData} setEngravingData={setEngravingData}/>
    }
    </>
  )
}
