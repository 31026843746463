import React, { useEffect, useState } from 'react'
import FitlerIconTab from '../../assets/images/filterIcon.png';
import { useTranslation } from 'react-i18next'
import CrossIcon from '../../assets/icons/CrossIcon';
import MinusIcon from '../../../template_one/default/assets/icons/MinusIcon';
import PlusIcon from '../../../template_one/default/assets/icons/PlusIcon';
import Slider from 'rc-slider';
import RightArrow from '../../../../admin/common/icons/right-arrow';
import './filter.scss'
import { max } from 'moment';
import SortIcon from 'Ecommerce/template_three/assets/icons/SortIcon';
import CloseIcon from 'admin/common/icons/CloseIcon';


export default function FilterBar(props) {

    const { filterdata, setFilters, closeMobileFilter, toggleMobileFilter, filters, datatype, setDatatype } = props
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { t } = useTranslation();
    const [filtercheck, setfiltercheck] = useState(filters);
    const [openFilters, setOpenFilters] = useState([{metal : ''},{stone: ''},{size: ''}, {category: ''},{availability : ''}, {price: ''}]);
    const [activeFilter, setActiveFilter] = useState("")
    const [price, setPrice] = useState({min : filterdata?.price?.min, max : filterdata?.price?.max})
    const [header, setHeader] = useState('');
    const [mobresponsiveData, setMobResponsiveData] = useState({});
    const toggleCollapse = (key, status) => {
      setIsCollapsed((privace) => ({
        ...privace,
        [key]: status
      }));
    };


    const reaponcefilter = (event, lable, filter) => {
      let data = { ...filtercheck }
      if (lable === "item") {
        data.item = filter?._id
      }
      if (lable === "size") {
        if (event.target.checked) {
          data.size.push(filter)
        } else {
          data.size = data.size.filter((item) => item?._id !== filter?._id)
        }
      }
      if (lable === "stone") {
        console.log(data, filter, "from stone type")
        if (event.target.checked) {
          data.stone.push(filter)
        } else {
          data.stone = data.stone.filter((item) => item?._id !== filter?._id)
        }
      }
      if (lable === "metal") {
        if (event.target.checked) {
          data.metal.push(filter)
        } else {
          data.metal = data.metal.filter((item) => item?._id !== filter?._id)
        }
      }
      if (lable === "price") {
        data.price.min = filter?.min
        data.price.max = filter?.max
      }
      if (lable === "clearfilter") {
        data.item = ""
        data.size = []
        data.stone = []
        data.metal = []
        data.price = { min: filterdata?.price?.min, max: filterdata?.price?.max }
        setPrice([filterdata?.price?.min, filterdata?.price?.max])
      }
      setFilters(data)
      setfiltercheck(data)
    }
    const mobResponsiveFilter = (event, lable, filter) => {
    //  mobresponsiveData = { ...filtercheck }
    //   if (lable === "item") {
    //     mobresponsiveData.item = filter?._id
    //   }
      if (lable === "size") {
        if (event.target.checked) {
          setMobResponsiveData((state)=>({...state, filter}))
          // mobresponsiveData.size.push(filter)
        } else {
          mobresponsiveData.size = mobresponsiveData.size.filter((item) => item?._id !== filter?._id)
        }
      }
      if (lable === "stone") {
        console.log(mobresponsiveData, filter, "from stone type")
        if (event.target.checked) {
          mobresponsiveData.stone.push(filter)
        } else {
          mobresponsiveData.stone = mobresponsiveData.stone.filter((item) => item?._id !== filter?._id)
        }
      }
      if (lable === "metal") {
        if (event.target.checked) {
          setMobResponsiveData((state)=>({...state, 'metal' : [...state.metal, filter]}))
          // mobresponsiveData.metal.push(filter)
        } else {
          mobresponsiveData.metal = mobresponsiveData.metal.filter((item) => item?._id !== filter?._id)
        }
      }
      if (lable === "price") {
        mobresponsiveData.price.min = filter?.min
        mobresponsiveData.price.max = filter?.max
      }
      if (lable === "clearfilter") {
        mobresponsiveData.item = ""
        mobresponsiveData.size = []
        mobresponsiveData.stone = []
        mobresponsiveData.metal = []
        mobresponsiveData.price = { min: filterdata?.price?.min, max: filterdata?.price?.max }
        setPrice([filterdata?.price?.min, filterdata?.price?.max])
      }
      
    }

    const handleApply = () =>{
      console.log(mobresponsiveData, "apply filter")
      // setFilters(mobresponsiveData)
      // setfiltercheck(mobresponsiveData)
    }
    
    useEffect(() => {
      setfiltercheck((privacestate) => ({
        ...privacestate,
        price: { min: filterdata?.price?.min, max: filterdata?.price?.max }
      }))
    }, [filterdata])

    const handleFilterToggle = (type) =>{
        if(type === activeFilter){
        setActiveFilter("")
        }else{
            setActiveFilter(type)  
        }
    }

    const toggleFilter = (type) =>{
        setOpenFilters((state)=>({...state, [type] : !openFilters?.[type]}))
    }
  
      if(toggleMobileFilter){
        return (
          <div className='filtersidebar' onClick={closeMobileFilter}>
              <div className='filtersidebar-inner' onClick={(e)=>e.stopPropagation()}>
                  <div className='filtersidebar-inner-wrapper'>
                      <div className='filtersidebar-inner-wrapper-header_default'>
                        <span onClick={closeMobileFilter}><CloseIcon width="18px" height="18px"/></span>
                      </div>
                      {/* } */}
                  </div>
                  <div className='filtersidebar-inner-wrapper second'>
                      <div className='filtersidebar-inner-wrapper-header_default'>
                        <span className='text'>
                          <span >{t("Filters")}</span>
                        </span>
                        <span className='right' >{t("Clear All")}</span>
                      </div>
                      {/* } */}
                  </div>
                  <div className='filtersidebar-inner-wrapper-content'>
                    {/* {!header.length &&
                    <div className='filtersidebar-inner-wrapper-content-list'>
                      {  Object.entries(filterdata).filter(([id, name])=> id !== 'item' &&   id !== 'currency').map(([id, name]) => {
                        return(
                          <div className='filtersidebar-inner-wrapper-content-list-item' key={id} onClick={()=> setHeader(id)}>
                            <div className='title'>{t(`${id}`)}</div>
                            <span onClick={closeMobileFilter}><RightArrow/></span>
                          </div>
                        )
                      })}
                      </div>
                    } */}

                  {/* Available filter start */}
                    <div className='filtersidebar-inner-wrapper-content-filterdata'>
                        <div className='header'>
                          <div className='header-title' onClick={()=>toggleFilter('availability')}><span className='title'>{t("Availability")}</span><span><MinusIcon/></span></div>
                        </div>
                        { openFilters?.availability ? 
                        <div className='wrapper'>
                          <div className='wrapper-heading justify-content-right'><span className='righttext'>{t("Reset")}</span></div>
                            <div className='wrapper-content'>
                               <div className='filtersidebar-inner-wrapper-content-filterdata-item'>{t("In stock")}</div>
                            </div> 
                          </div>
                        : null}
                         </div>
                    {/* Available filter ends */}

                    {/* category filter start */}
                    <div className='filtersidebar-inner-wrapper-content-filterdata'>
                        <div className='header'>
                          <div className='header-title' onClick={()=>toggleFilter('category')}><span className='title'>{t("Category")}</span><span><MinusIcon/></span></div>
                        </div>
                        { openFilters?.category ? 
                        <div className='wrapper'>
                          <div className='wrapper-heading'><span className='subtitle'>{0}{t("Selected")}</span><span className='righttext'>{t("Reset")}</span></div>
                            <div className='wrapper-content'>
                                {filterdata?.item?.length ? filterdata.item.map((result, key) => (
                                      <span key={key} className={`filtersidebar-inner-wrapper-content-filterdata-item ${filtercheck?.item && filtercheck.item.some(item => item._id === result._id) ? 'active' : ''}`}>{t(`${result?.name}`)}</span>
                                  )) : null}
                            </div> 
                          </div>
                        : null}
                         </div>
                         {/* category filter ends */}

                    {/* metal filter start */}
                      <div className='filtersidebar-inner-wrapper-content-filterdata'>
                        <div className='header'>
                          <div className='header-title' onClick={()=>toggleFilter('metal')}><span className='title'>{t("Metal")}</span><span><MinusIcon/></span></div>
                        </div>
                        { openFilters?.metal ? 
                        <div className='wrapper'>
                          <div className='wrapper-heading'><span className='subtitle'>{0}{t("Selected")}</span><span className='righttext'>{t("Reset")}</span></div>
                            <div className='wrapper-content'>
                                {filterdata?.metal?.length ? filterdata.metal.map((result, key) => (
                                      <div className="filtersidebar-inner-wrapper-content-filterdata-holder ecom-themed-checkbox" key={key}>
                                          <input
                                          type="checkbox"
                                          id={result.name}
                                            onChange={(e) => mobResponsiveFilter(e, "metal", result)}
                                            checked={mobresponsiveData?.metal && mobresponsiveData.metal.filter(item => item._id === result._id)}
                                          />
                                          <label htmlFor={result.name} className="ecom-themed-checkbox-label">
                                          </label>
                                          <span className='filtersidebar-inner-wrapper-content-filterdata-holder-label'> {t(`${result.name}`)}</span>
                                      </div>
                                  )) : null}
                            </div> 
                          </div>
                        : null}
                         </div>
                         {/* metal filter ends */}

                        {/* stone filter starts */}
                         <div className='filtersidebar-inner-wrapper-content-filterdata'>
                         <div className='header'>
                          <div className='header-title' onClick={()=>toggleFilter('stone')}><span className='title'>{t("Stone")}</span><span><MinusIcon/></span></div>
                        </div>
                        { openFilters?.stone ? 
                        <div className='wrapper'>
                          <div className='wrapper-heading'><span className='subtitle'>{0}{t("Selected")}</span><span className='righttext'>{t("Reset")}</span></div>
                            <div className='wrapper-content'>
                              {filterdata?.stone?.length ? filterdata?.stone.map((result, key) => {
                          return (
                            // <div className="filtersidebar-inner-wrapper-content-filterdata-holder ecom-themed-checkbox" key={key}>
                            //     <input
                            //     type='checkbox' 
                            //     id={result?.name}
                            //     onChange={(e) => mobResponsiveFilter(e, "stone", result)}
                            //     checked={filtercheck?.stone && filtercheck.stone.some(item => item._id === result._id)}
                            //     />
                            //     <label className='ecom-themed-checkbox-label' htmlFor={result?.name}></label>
                                <span key={key} className={`filtersidebar-inner-wrapper-content-filterdata-item ${filtercheck?.stone && filtercheck.stone.some(item => item._id === result._id) ? 'active' : ''}`}>{t(`${result?.name}`)}</span>
                            // </div>
                          )
                                }) : ""} 
                            </div>
                        </div> 
                        : null}
                        </div>
                        {/* stone filter ends */}

                         {/* size filter starts */}
                         <div className='filtersidebar-inner-wrapper-content-filterdata'>
                         <div className='header'>
                          <div className='header-title' onClick={()=>toggleFilter('size')}><span className='title'>{t("Size")}</span><span><MinusIcon/></span></div>
                        </div>
                        { openFilters?.size ? 
                        <div className='wrapper'>
                          <div className='wrapper-heading'><span className='subtitle'>{0}{t("Selected")}</span><span className='righttext'>{t("Reset")}</span></div>
                            <div className='wrapper-content'>
                        <div className='d-flex flex-wrap gap-4'>
                         {filterdata?.size?.length  ? filterdata?.size.map((result, key) => {
                          
                            return (
                            // <div className='filtersidebar-inner-wrapper-content-filterdata-holder  ecom-themed-checkbox' key={key}>
                            //     <input 
                            //     type='checkbox' 
                            //     id={result?.name} 
                            //     onChange={(e) => { mobResponsiveFilter(e, "size", result) }}
                            //     checked={filtercheck?.size && filtercheck.size.some(item => item._id === result._id)}
                            //     />
                            //     <label className='ecom-themed-checkbox-label' htmlFor={result?.name}></label>
                                <span key={key} className={`filtersidebar-inner-wrapper-content-filterdata-item ${filtercheck?.size && filtercheck.size.some(item => item._id === result._id) ? 'active' : ''}`}  onChange={(e) => { mobResponsiveFilter(e, "size", result) }}>{t(`${result?.name}`)}</span>
                            // </div>
                           
                            )
                        }) : ""}
                         </div>
                         </div> 
                         </div> : null}

                         </div>
                        {/* size filter ends */}

                        {/* price filter starts */}
                          <div className='filtersidebar-inner-wrapper-content-filterdata'>
                          <div className='header'>
                          <div className='header-title' onClick={()=>toggleFilter('price')}><span className='title'>{t("Price")}</span><span><MinusIcon/></span></div>
                        </div>
                        { openFilters?.price ?
                         <div className='wrapper'>
                          <div className='wrapper-heading'><span className='subtitle'>{t("The highest price is")} {filterdata?.price?.max}</span><span className='righttext'>{t("Reset")}</span></div>
                          {filterdata?.price?.max  ?
                          <div className='filtersidebar-inner-wrapper-content-filterdata-price'>
                            <div className='filtersidebar-inner-wrapper-content-filterdata-price-block'>
                              <span className='title'>{t("Min price")}:</span>
                              <input type='number' min="0" max={filterdata?.price?.max}/>
                            </div>
                            <div className='filtersidebar-inner-wrapper-content-filterdata-price-block'>
                              <span className='title'>{t("Max price")}:</span>
                              <input type='number' value={filterdata.price?.max} readOnly/>
                            </div>
                          </div>
                      : ""}
                        </div>
                        : null}
                          </div>
                        {/* price filter ends */}

                  </div>
                  {/* <button className='btnbold m-auto' onClick={()=>handleApply()}>{t("Apply")}</button> */}
              </div>
          </div>
        )
      }
      // return (
      //   <div className='filterbar'>
      //       <div className='filterbar-inner'>
      //           <div className='filterbar-inner-title'>
      //               <img src={FitlerIconTab} alt="" />
      //               <span>{t("Filter by")}:</span>
      //           </div>
      //           <div className='filterbar-inner-list'>
      //              <div className='filterbar-inner-list-item' onClick={()=>handleFilterToggle("metal")}>
      //                   <span className='filterbar-inner-list-item-tag'>{t("Metal")}</span>
      //                   <span className={`filterbar-inner-list-item-icon ${activeFilter === 'metal' ? "active" : ""}`}><RightArrow/></span>
      //              </div>
      //              <div className='filterbar-inner-list-item' onClick={()=>handleFilterToggle("stone")}>
      //                   <span className='filterbar-inner-list-item-tag'>{t("Stone")}</span>
      //                   <span className={`filterbar-inner-list-item-icon ${activeFilter === 'stone' ? "active" : ""}`}><RightArrow/></span>
      //              </div>
      //              <div className='filterbar-inner-list-item' onClick={()=>handleFilterToggle("size")}>
      //                   <span className='filterbar-inner-list-item-tag'>{t("Size")}</span>
      //                   <span className={`filterbar-inner-list-item-icon ${activeFilter === 'size' ? "active" : ""}`}><RightArrow/></span>
      //              </div>
      //              <div className='filterbar-inner-list-item' onClick={()=>handleFilterToggle("price")}>
      //                   <span className='filterbar-inner-list-item-tag'>{t("Price")}</span>
      //                   <span className={`filterbar-inner-list-item-icon ${activeFilter === 'price' ? "active" : ""}`}><RightArrow/></span>
      //              </div>
      //           </div>
      //           <div className='filterbar-inner-stock'>
      //           <div className="ecom-customToggleCheck">
      //                                       <input type="checkbox" id="togglebtn1" className="ecom-customToggleCheck-toogleInput"
      //                                           checked={datatype === "inventory" ? true : false}
      //                                           onChange={(e) => setDatatype(datatype === 'inventory' ? 'catalog' : 'inventory')}
      //                                       />
      //                                       <label htmlFor="togglebtn1" className="ecom-customToggleCheck-toggleLabel"></label>
      //           </div>
      //           <span className='filterbar-inner-stock-label'>{t("Available")} ({t("In-Stock")})</span>
      //           </div>
              
      //       </div>
      //           <div className={`filterbar-content ${activeFilter ? "active" : ""}`}>
      //               <div className='filterbar-content-wrapper'>
      //                   {filterdata?.metal?.length && activeFilter === 'metal' ? filterdata.metal.map((result, key) => (
      //                       <div className="filterbar-content-metal ecom-themed-checkbox" key={key}>
      //                           <input
      //                           type="checkbox"
      //                           id={result.name}
      //                             onChange={(e) => reaponcefilter(e, "metal", result)}
      //                             checked={filtercheck?.metal && filtercheck.metal.some(item => item._id === result._id)}
      //                           />
      //                           <label htmlFor={result.name} className="ecom-themed-checkbox-label">
      //                           </label>
      //                           <span className='filterbar-content-metal-label'> {t(`${result.name}`)}</span>
      //                       </div>
      //                   )) : null}
      //                   {filterdata?.size?.length && activeFilter === 'size' ? filterdata?.size.map((result, key) => {
      //                       return (
      //                       <div className='filterbar-content-metal ecom-themed-checkbox' key={key}>
      //                           <input 
      //                           type='checkbox' 
      //                           id={result?.name} 
      //                           onChange={(e) => { reaponcefilter(e, "size", result) }}
      //                           checked={filtercheck?.size && filtercheck.size.some(item => item._id === result._id)}
      //                           />
      //                           <label className='ecom-themed-checkbox-label' htmlFor={result?.name}></label>
      //                           <span className="filterbar-content-metal-label">{t(`${result?.name}`)}</span>
      //                       </div>
      //                       )
      //             }) : ""}
      //             {filterdata?.stone?.length && activeFilter === 'stone' ? filterdata?.stone.map((result, key) => {
      //               return (
      //                 <div className="filterbar-content-metal ecom-themed-checkbox" key={key}>
      //                     <input
      //                     type='checkbox' 
      //                     id={result?.name}
      //                     onChange={(e) => reaponcefilter(e, "stone", result)}
      //                     checked={filtercheck?.stone && filtercheck.stone.some(item => item._id === result._id)}
      //                     />
      //                      <label className='ecom-themed-checkbox-label' htmlFor={result?.name}></label>
      //                     <span className="filterbar-content-metal-label">{t(`${result?.name}`)}</span>
      //                 </div>
      //               )
      //             }) : ""}
      //              {filterdata?.price?.max && activeFilter === 'price' ?
      //                   <div className='filterbar-content-price'>
      //                     <div className='filterbar-content-price-wrapper'>
      //                     {priceFilter.map((result, key) => {
      //                     return (
      //                       <div className="filterbar-content-metal ecom-themed-checkbox" key={key}>
      //                           <input
      //                           type='checkbox' 
      //                           id={result?.min}
      //                           onChange={(e) => setPrice({min: result?.min, max: result?.max})}
      //                           checked={price.min == result.min && price.max == result.max ? true : false}
      //                           />
      //                           <label className='ecom-themed-checkbox-label' htmlFor={result?.min}></label>
      //                           <span className="filterbar-content-metal-label"> {key !== priceFilter.length -1 ? `${t(`${result?.min}`)} - ${t(`${result?.max}`)}` : "Over 50,000"} </span>
      //                       </div>
      //                     )})}
      //                   </div>
      //                   <div className="filterbar-content-price-inputblock">
      //                   <div className="filterbar-content-price-inputblock-inner">
      //                           <span className="filterbar-content-price-inputblock-inner-text">{t("Customize your Price Range")}</span>
      //                           <div className="filterbar-content-price-inputblock-inner-range">
      //                           <div className="filterbar-content-price-inputblock-inner-range-single">
      //                               <span className="Dollar_sign">{filterdata?.currency}</span>
      //                               <span className="price_FilterContainer-rangeBox-numShow-single-unit">{price?.min}</span>
      //                           </div>
      //                           <span className="price-betwn-to">{t("to")}</span>
      //                           <div className="filterbar-content-price-inputblock-inner-range-single">
      //                               <span className="Dollar_sign">{filterdata?.currency}</span>
      //                               <span className="price_FilterContainer-rangeBox-numShow-single-unit">{price?.max}</span>
      //                           </div>
      //                           </div>
      //                           <button className='btnbold smallrounded' onClick={(e) => reaponcefilter(e, "price", price)}>
      //                             {t("Apply")}
      //                           </button>
      //                   </div>
      //                   </div>
      //                   </div>
      //               : ""}
      //               </div>
      //           </div>
      //   </div>
      // )

}
