import Diamond from 'admin/common/icons/Diamond'
import RightArrow from 'admin/common/icons/right-arrow'
import Ring from 'admin/common/icons/Ring'
import CircledTick from 'admin/common/icons/sidebar/CircledTick'
import { toFormatPrice } from 'Ecommerce/common/Formateprice'
import FIlterIcon from 'Ecommerce/template_three/assets/icons/ArrowDownSide'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export default function Tab({engravingData, getDiamondFilters, getProductFilters, flowMode, mobileTab, setMobileTab, activeTab, productDetail, noFilter, diamondProduct, setActiveTab}) {
  
    const {t} = useTranslation();
    
    useEffect(()=>{
      console.log(activeTab, "active tab from tab component")
    },[activeTab])

    const handleReviewandChange = (tabName) =>{
      // console.log( "diamond filters : ", getDiamondFilters, "Product Filters : ", getProductFilters, "Diamond Products : ", diamondProduct, "Product Details : ", productDetail, "all details via change")
      setActiveTab(tabName);
    }
  
    return (
        <div>
        {/* <div className='mix_match_page-wrapper-mobiletab'>
         <div className={`mix_match_page-wrapper-mobiletab-tab ${mobileTab === 1 ? 'active' : ''}`} onClick={()=>{mobileTab === 1 ? setMobileTab(null) : setMobileTab(1)}}>
             <span className="mix_match_page-wrapper-mobiletab-tab-icon">
             <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M12.25 7H20.25" stroke="#767676" strokeLinecap="round"/>
               <path d="M4.25 7H8.25" stroke="#767676" strokeLinecap="round"/>
               <path d="M17.25 17H20.25" stroke="#767676" strokeLinecap="round"/>
               <path d="M4.25 17H12.25" stroke="#767676" strokeLinecap="round"/>
               <path d="M8.25 7C8.25 8.10457 9.14543 9 10.25 9C11.3546 9 12.25 8.10457 12.25 7C12.25 5.89543 11.3546 5 10.25 5C9.14543 5 8.25 5.89543 8.25 7Z" stroke="#767676" strokeLinecap="round"/>
               <path d="M13.25 17C13.25 18.1046 14.1454 19 15.25 19C16.3546 19 17.25 18.1046 17.25 17C17.25 15.8954 16.3546 15 15.25 15C14.1454 15 13.25 15.8954 13.25 17Z" stroke="#767676" strokeLinecap="round"/>
             </svg>
             </span>
             <span className='mix_match_page-wrapper-mobiletab-tab-title'>{t("Funnel Step")}</span>
         </div>
         {!noFilter ? 
         <div className={`mix_match_page-wrapper-mobiletab-tab ${mobileTab === 2 ? 'active' : ''}`} onClick={()=>{mobileTab === 2 ? setMobileTab(null) : setMobileTab(2)}}>
             <span><FIlterIcon/></span>
             <span>{t("Product Filter")}</span>
         </div>
         : "" }
       </div> */}
       <div className={`mix_match_page-wrapper-tabwrapper ${mobileTab === 1 ? 'actibeTab' : ''}`}>
         {
           flowMode === 'fromDiamond' ? 
           <>
           <div className={`mix_match_page-wrapper-tabwrapper-tab ${activeTab === 'Diamond' ? 'active' : ''}`}>
            {diamondProduct ?  
               <div className='mix_match_page-wrapper-tabwrapper-tab-detailedcontainer'>
               <div className='mix_match_page-wrapper-tabwrapper-tab-detailedcontainer-data'>
                 <div className={`mix_match_page-wrapper-tabwrapper-tab-detailedcontainer-data-name ${activeTab === 'Diamond' ? 'active' : ''}`}>{diamondProduct?.Cut} {diamondProduct?.Carat} {t("Carat")} {diamondProduct?.Culet} {("Diamond")}</div>
                 <div className={`mix_match_page-wrapper-tabwrapper-tab-detailedcontainer-data-subdata ${activeTab === 'Diamond' ? 'active' : ''}`}><span>{t("Color")} : {diamondProduct?.Colour} {t("Certified by")} : {diamondProduct?.GradedBy}</span> <span onClick={()=> handleReviewandChange('Diamond')}>{t("Review")}</span> <span onClick={()=> handleReviewandChange('Diamond')}>{t("Change")}</span></div>
               </div>
               <img src={diamondProduct?.image} alt='item'/>
             </div>
             :
             <div className='mix_match_page-wrapper-tabwrapper-tab-label'>
                 <span className="mix_match_page-wrapper-tabwrapper-tab-label-icon"><Diamond/></span>
                 <span className={`mix_match_page-wrapper-tabwrapper-tab-label-tag ${activeTab === 'Diamond' ? 'active' : ''}`}>{t("Select your center stone")}</span>
                 
             </div>
         }
         </div>
         <div className='middlearrow'>
            <RightArrow/>
         </div>
         <div className={`mix_match_page-wrapper-tabwrapper-tab ${activeTab === 'Product' ? 'active' : ''}`}>
             {/* <div className={`mix_match_page-wrapper-tabwrapper-tab-order ${activeTab === 'Product' ? 'active' : ''}${productDetail && activeTab !== 'Product' ? 'detailed' : ''}`}>2</div> */}
            {productDetail  ? 
             <div className='mix_match_page-wrapper-tabwrapper-tab-detailedcontainer'>
              <div className='mix_match_page-wrapper-tabwrapper-tab-label'>
                 <span className="mix_match_page-wrapper-tabwrapper-tab-label-icon"><Ring/></span>
                 <span className={`mix_match_page-wrapper-tabwrapper-tab-label-tag ${activeTab === 'Product' ? 'active' : ''}`}>{t("Choose your setting")}</span>
             </div>
               <div className='mix_match_page-wrapper-tabwrapper-tab-detailedcontainer-data'>
                 <div className={`mix_match_page-wrapper-tabwrapper-tab-detailedcontainer-data-name ${activeTab === 'Product' ? 'active' : ''}`}>{productDetail?.productdetails?.name}</div>
                 <div className={`mix_match_page-wrapper-tabwrapper-tab-detailedcontainer-data-subdata ${activeTab === 'Product' ? 'active' : ''}`}><span>{toFormatPrice(productDetail?.productdetails?.price, { addSymbol: true })}</span> <span onClick={()=> handleReviewandChange('Product')}>{t("Review")}</span> <span onClick={()=> handleReviewandChange('Product')}>{t("Change")}</span></div>
               </div>
               {/* <img src={productDetail?.productdetails?.images?.[0]?.thumbnail} alt='item'/> */}
             </div>
            :
             <div className='mix_match_page-wrapper-tabwrapper-tab-label'>
                 <span className="mix_match_page-wrapper-tabwrapper-tab-label-icon"><Ring/></span>
                 <span className={`mix_match_page-wrapper-tabwrapper-tab-label-tag ${activeTab === 'Product' ? 'active' : ''}`}>{t("Choose your setting")}</span>
             </div>
             }
         </div>
           </>
           :
           <>
            <div className={`mix_match_page-wrapper-tabwrapper-tab ${activeTab === 'Product' ? 'active' : ''}`}>
            
            {productDetail && activeTab !== "Product" ? 
             <div className='mix_match_page-wrapper-tabwrapper-tab-detailedcontainer'>
              <div className='mix_match_page-wrapper-tabwrapper-tab-label'>
                 <span className="mix_match_page-wrapper-tabwrapper-tab-label-icon"><Ring /></span>
                 <span className={`mix_match_page-wrapper-tabwrapper-tab-label-tag ${activeTab === 'Product' ? 'active' : ''}`}>{t("Step 1")} :</span>
                 <span className={`mix_match_page-wrapper-tabwrapper-tab-label-tag ${activeTab === 'Product' ? 'active' : ''}`}>{t("Choose Setting")}</span>
             </div>
               <div className='mix_match_page-wrapper-tabwrapper-tab-detailedcontainer-data'>
                 <div className={`mix_match_page-wrapper-tabwrapper-tab-detailedcontainer-data-name ${activeTab === 'Product' ? 'active' : ''}`}>{productDetail?.productdetails?.name}</div>
                 <div className={`mix_match_page-wrapper-tabwrapper-tab-detailedcontainer-data-subdata ${activeTab === 'Product' ? 'active' : ''}`}>
                  <span>{toFormatPrice(productDetail?.productdetails?.price, { addSymbol: true })}</span> 
                  <span onClick={()=>handleReviewandChange('Product')}>{t("Review")}</span> 
                  <span onClick={()=>handleReviewandChange('Product')}>{t("Change")}</span>
                  </div>
               </div>
               {/* <img src={productDetail?.productdetails?.images?.[0]?.thumbnail} alt='item'/> */}
             </div>
            :
             <div className='mix_match_page-wrapper-tabwrapper-tab-label'>
                 <span className="mix_match_page-wrapper-tabwrapper-tab-label-icon"><Ring /></span>
                 <span className={`mix_match_page-wrapper-tabwrapper-tab-label-tag ${activeTab === 'Product' ? 'active' : ''}`}>{t("Step 1")} :</span>
                 <span className={`mix_match_page-wrapper-tabwrapper-tab-label-tag ${activeTab === 'Product' ? 'active' : ''}`}>{t("Choose Setting")}</span>
             </div>
             }
         </div>
         <div className='middlearrow'>
            <RightArrow/>
         </div>
         <div className={`mix_match_page-wrapper-tabwrapper-tab ${activeTab === 'Diamond' ? 'active' : ''}`}>
         {diamondProduct && activeTab !== 'Diamond'  ?  
               <div className='mix_match_page-wrapper-tabwrapper-tab-detailedcontainer'>
               <div className='mix_match_page-wrapper-tabwrapper-tab-detailedcontainer-data'>
               <div className='mix_match_page-wrapper-tabwrapper-tab-label'>
                 <span className="mix_match_page-wrapper-tabwrapper-tab-label-icon"><Diamond/></span>
                 <span className={`mix_match_page-wrapper-tabwrapper-tab-label-tag ${activeTab === 'Diamond' ? 'active' : ''}`}>{t("Step 2")} : </span>
                 <span className={`mix_match_page-wrapper-tabwrapper-tab-label-tag ${activeTab === 'Diamond' ? 'active' : ''}`}>{t("Select a Diamond")}</span>
                 
             </div>
                 <div className={`mix_match_page-wrapper-tabwrapper-tab-detailedcontainer-data-name ${activeTab === 'Diamond' ? 'active' : ''}`}> {diamondProduct?.Carat} {t("Carat")} {diamondProduct?.Culet} {("Diamond")}</div>
                 <div className={`mix_match_page-wrapper-tabwrapper-tab-detailedcontainer-data-subdata ${activeTab === 'Diamond' ? 'active' : ''}`}><span>{diamondProduct?.Cut} {t("Diamond")} : <span>{toFormatPrice(diamondProduct?.price, { addSymbol: true })}</span> </span> <span onClick={()=> handleReviewandChange('Diamond')}>{t("Review")}</span> <span onClick={()=> handleReviewandChange('Diamond')}>{t("Change")}</span></div>
               </div>
               {/* <img src={diamondProduct?.image} alt='item'/> */}
             </div>
             :
             <div className='mix_match_page-wrapper-tabwrapper-tab-label'>
                 <span className="mix_match_page-wrapper-tabwrapper-tab-label-icon"><Diamond/></span>
                 <span className={`mix_match_page-wrapper-tabwrapper-tab-label-tag ${activeTab === 'Diamond' ? 'active' : ''}`}>{t("Step 2")} : </span>
                 <span className={`mix_match_page-wrapper-tabwrapper-tab-label-tag ${activeTab === 'Diamond' ? 'active' : ''}`}>{t("Select a Diamond")}</span>
                 
             </div>
         }
         </div>
           </>
         }
         <div className='middlearrow'>
            <RightArrow/>
         </div>
        {/* Final Step */}
         <div className={`mix_match_page-wrapper-tabwrapper-tab ${activeTab === 'Final' ? 'active' : ''}`}>
             {/* { engravingData ?
             <div className='mix_match_page-wrapper-tabwrapper-tab-detailedcontainer'>
             <div className={`mix_match_page-wrapper-tabwrapper-tab-detailedcontainer-data ${activeTab === 'Final' ? 'active' : ''}`}>
               <div className={`mix_match_page-wrapper-tabwrapper-tab-detailedcontainer-data-name ${activeTab === 'Final' ? 'active' : ''}`}>{t("Place Complete")}</div>
               <div className={`mix_match_page-wrapper-tabwrapper-tab-detailedcontainer-data-subdata ${activeTab === 'Final' ? 'active' : ''}`}>
                 <span>{t("Subtoal : ")}  
                   {toFormatPrice((parseFloat(productDetail?.productSelection?.pointer?.value) + (parseFloat(diamondProduct?.UnformattedPrice))),{ addSymbol: true })}</span></div>
             </div>
             <img src={diamondProduct?.image} alt='item'/>
           </div>
           : */}
             <div className='mix_match_page-wrapper-tabwrapper-tab-label'>
                 <span className="mix_match_page-wrapper-tabwrapper-tab-label-icon"><CircledTick width='18px' height='18px'/></span>
                 <span className={`mix_match_page-wrapper-tabwrapper-tab-label-tag ${activeTab === 'Final' ? 'active' : ''}`}>{t("Step 3")} : </span>
                 <span className={`mix_match_page-wrapper-tabwrapper-tab-label-tag ${activeTab === 'Final' ? 'active' : ''}`}>{t("Complete")}</span>
             </div>
           {/* } */}
           </div>
         </div>
   </div>
  )
}
