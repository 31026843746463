import React from 'react'
const HeartIcon = (props) => {
    const { width, height, color } = props;
    return (
        <svg width={width ? width : "32"} height={height ? height : "33"} viewBox="0 0 32 33" fill="#5CC7C0" xmlns="http://www.w3.org/2000/svg" >
            <g clipPath="url(#clip0_3144_2432)">
                <path d="M5.62966 18.4993L16.0003 28.4993L26.371 18.4993C28.171 17.144 29.3337 14.9953 29.3337 12.5733C29.3337 8.48468 26.015 5.16602 21.9263 5.16602C19.5043 5.16602 17.3483 6.33668 16.0003 8.13668C14.6523 6.33668 12.4963 5.16602 10.0743 5.16602C5.98566 5.16602 2.66699 8.48468 2.66699 12.5733C2.66699 14.9953 3.82966 17.144 5.62966 18.4993ZM10.0743 6.49935C11.9743 6.49935 13.7903 7.41002 14.933 8.93535L16.0003 10.3607L17.0677 8.93602C18.2103 7.41002 20.0263 6.49935 21.9263 6.49935C25.2757 6.49935 28.0003 9.22402 28.0003 12.5733C28.0003 14.4987 27.1143 16.2707 25.5683 17.434L25.5037 17.4827L25.445 17.5393L16.0003 26.6474L6.55566 17.5393L6.49699 17.4827L6.43233 17.434C4.88699 16.2707 4.00033 14.4987 4.00033 12.5733C4.00033 9.22402 6.72499 6.49935 10.0743 6.49935Z" fill={color ? color : "black"} />
            </g>
            <defs>
                <clipPath id="clip0_3144_2432">
                    <rect width="32" height="32" fill="red" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg >
    )
}

export default HeartIcon