// App.js
import React, { useRef, useEffect, Suspense, useState, useCallback } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import PublicRoutes from "./Route/PublicRoutes.js"
import EcommerceRoutes from "./Route/EcommerceRoutes.js"
import EcommerceRoutesTwo from "./Route/EcommerceRoutesTwo.js"
import EcommerceRoutesThree from "./Route/EcommerceRoutesThree.js"
import InventoryRoutes from "./Route/InventoryRoutes.js"
import PosRoutes from './Route/PosRoutes.js';
import AdminCmsRoutes from './Route/AdminCmsRoutes.js';
import AdminRoutes from './Route/AdminRoutes.js';
import DefaultErrorPage from './defaultErrorPage/DefaultErrorPage.js';
import UpdateTitle from './source/UpdateTitle.js';

import {
    updateGeneralSettings,
    OrganisationSettings,
    updateUserPermission,
    updateModulePermission

} from './source/app/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { _Api } from './components/api/_call.js';
import Apiauth from './API/Apiauth.js';
import graphqlPost from './API/GraphqlPost.js';

import { isAuthenticated } from "./components/AuthMiddleware.js";
import Showloader from './components/common/Showloader';
import ProductRoutes from './Route/ProductRoutes.js';
import UserRoutes from './Route/UserRoutes.js'
import Loader from './components/common/loader.js';
import EcommerceRoutesFour from 'Route/EcommerceRoutesFour.js';
const App = () => {
    const dataFetched = useRef();
    const Fullhandle = useFullScreenHandle();
    const [loading, setLoading] = useState(true);
    const [checkstore, setChackstore] = useState()
    const dispatch = useDispatch();
    const updateModulePermissiondata = localStorage.getItem("updateModulePermission")
    const setupdateModulePermission = JSON.parse(updateModulePermissiondata);
    const updateUserPermissiondata = localStorage.getItem("updateUserPermission")
    const setupdateUserPermission = JSON.parse(updateUserPermissiondata);
    const ModulePermission = useSelector((state) => state.app.ModulePermission);
    useEffect(() => {
        dispatch(updateModulePermission(setupdateModulePermission));
        dispatch(updateUserPermission(setupdateUserPermission));
        // eslint-disable-next-line 
    }, [])

    const getgeneralsetting = useCallback(async () => {
        try {
            const postdata = `{
            generalsetup {
            id 
            productChoice
            allowWarehouse
            POS_change_price
            Template_with_Image
            logistic_status
            purchaseOrder_delivery
            Users_to_enter_PIN_for_every_sale
            servicelabers
            isPark
            isQuotation
            POS_partial_payment
                  
        }
    }`;
            let res = await graphqlPost(postdata, "App/graphql");
            dispatch(updateGeneralSettings(res));
        } catch (err) {
            console.log(err, "err");
        }
    }, [dispatch])

    const FormatPrice = useCallback(async () => {
        try {
            const postdata = {};
            let countdata = await _Api(postdata, `api/v1/Inventory/common/getOrganisationSettings`);
            if (countdata.code === 200) {
                dispatch(OrganisationSettings(countdata?.data));
            }
        } catch (err) {
            console.log(err, "err");
        }
    }, [dispatch])

    useEffect(() => {
        if (window.location.href.indexOf('msg=sessionexpired') !== -1) {
            window.location.href = '/login';
        }
        // eslint-disable-next-line
    }, [window.location.href.indexOf('msg=sessionexpired') !== -1])

    const checkdomian = async () => {
        try {
            const fullPath = window.location.hostname;
            let postdata = { "domain": fullPath }
            // postdata = { "domain": 'jubilee.gis247.net' }
            let res = await Apiauth(postdata, `Auth/Web/check-store`)
            if (res?.code === 200) {
                setLoading(false)
                setChackstore(res?.data)
                localStorage.setItem('storetoken', (res?.data?.store));
                if (res?.data?.DomanType === 1) {
                    if (await isAuthenticated()) {
                        getUserData()
                    }
                }
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }

    const getUserData = useCallback(async () => {
        FormatPrice();
        getgeneralsetting();
        // eslint-disable-next-line
    }, [FormatPrice, getgeneralsetting]);

    useEffect(() => {
        if (dataFetched.current) return;
        dataFetched.current = true
        checkdomian();
        // eslint-disable-next-line
    }, [dataFetched]);

    useEffect(() => {
        const handleRouteChange = () => {
            localStorage.setItem('lastVisitedRoute', window.location.pathname);
        };

        window.addEventListener('popstate', handleRouteChange);
        localStorage.setItem('lastVisitedRoute', window.location.pathname);

        return () => {
            window.removeEventListener('popstate', handleRouteChange);
        };
    }, []);

    useEffect(() => {
        const lastVisitedRoute = localStorage.getItem('lastVisitedRoute');
        if (lastVisitedRoute && lastVisitedRoute !== window.location.pathname) {
            window.history.replaceState(null, '', lastVisitedRoute);
        }
    }, []);

    return (
        <Suspense fallback={<div> <Showloader /></div>}>
            {loading ? (
                <div>
                    <Loader /></div>

            ) : (
                <BrowserRouter>
                    <FullScreen handle={Fullhandle}>
                        <Routes>
                            {checkstore?.DomanType === 1 ?
                                <>
                                    <Route path="/app/admin/cms/:locationId_url/*" element={<AdminCmsRoutes />} />
                                    <Route path="/app/admin/*" element={<AdminRoutes />} />
                                    {ModulePermission?.Inventory &&
                                        <Route path="/app/inventory/*" element={<><UpdateTitle title="Inventory Management" /><InventoryRoutes Fulhandle={Fullhandle} /></>} />
                                    }
                                    {ModulePermission?.Product &&
                                        <Route path="/app/product/*" element={<><UpdateTitle title="Products Management" /><ProductRoutes Fulhandle={Fullhandle} /></>} />
                                    }
                                    {ModulePermission?.User &&
                                        <Route path="/app/users/*" element={<><UpdateTitle title="Users Management" /><UserRoutes Fulhandle={Fullhandle} /></>} />
                                    }
                                    {ModulePermission?.Point_of_sale ?
                                        <Route path="/app/pos/*" element={<><UpdateTitle title="POS Management Software" /><PosRoutes /></>} />
                                        : ""}
                                    <Route path="/*" element={<><UpdateTitle /><PublicRoutes /></>} />
                                </>
                                : checkstore?.DomanType === 2 ?
                                    checkstore?.template === 'template_one' ?
                                        <Route path={`/*`} element={<EcommerceRoutes />} /> :
                                        checkstore?.template === 'template_two' ?
                                            <Route path={`/*`} element={<EcommerceRoutesTwo />} /> :
                                            checkstore?.template === 'template_three' ?
                                                <Route path={`/*`} element={<EcommerceRoutesThree />} /> :
                                                checkstore?.template === 'template_four' ?
                                                    <Route path={`/*`} element={<EcommerceRoutesFour />} /> :
                                                    <Route path={`/*`} element={<EcommerceRoutes />} />
                                    : checkstore?.DomanType === 0 ?
                                        <Route path="/*" element={<DefaultErrorPage />} /> :
                                        <Route path={`/*`} element={<EcommerceRoutes />} />
                            }
                        </Routes>

                    </FullScreen>
                </BrowserRouter>
            )}
        </Suspense >
    );
};

export default App;
