import React from 'react'
const SortIcon = (props) => {
    const { width, height } = props
    return (
        <svg width={width ? width : "24"} height={height ? height : "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0364 14.9037L7.27359 20.6517V2.71406C7.27359 2.59472 7.22618 2.48026 7.14179 2.39586C7.0574 2.31147 6.94294 2.26406 6.82359 2.26406C6.70425 2.26406 6.58979 2.31147 6.50539 2.39586C6.421 2.48026 6.37359 2.59472 6.37359 2.71406V20.6265L1.61079 14.9031C1.57301 14.8576 1.52665 14.82 1.47434 14.7925C1.42204 14.7649 1.36482 14.748 1.30596 14.7425C1.2471 14.7371 1.18774 14.7433 1.13128 14.7608C1.07482 14.7783 1.02236 14.8068 0.976893 14.8446C0.931429 14.8823 0.893852 14.9287 0.866308 14.981C0.838763 15.0333 0.82179 15.0905 0.816358 15.1494C0.810926 15.2083 0.817141 15.2676 0.834648 15.3241C0.852155 15.3805 0.880611 15.433 0.918393 15.4785L6.48939 22.1727C6.53161 22.2235 6.58452 22.2645 6.64436 22.2926C6.70419 22.3207 6.76949 22.3353 6.83559 22.3353H6.83619C6.96999 22.3353 7.09659 22.2753 7.18239 22.1727L12.73 15.4785C12.8062 15.3865 12.8428 15.268 12.8316 15.1491C12.8205 15.0301 12.7626 14.9205 12.6706 14.8443C12.5786 14.768 12.4601 14.7315 12.3412 14.7426C12.2223 14.7538 12.1126 14.8117 12.0364 14.9037ZM23.0794 8.52086L17.509 1.82666C17.4668 1.77594 17.414 1.73508 17.3542 1.70699C17.2945 1.6789 17.2294 1.66424 17.1634 1.66406C17.0296 1.66406 16.9018 1.72406 16.8172 1.82666L11.269 8.52086C11.1939 8.61288 11.1582 8.73085 11.1698 8.84905C11.1813 8.96725 11.2391 9.07611 11.3305 9.15188C11.422 9.22765 11.5397 9.2642 11.658 9.25356C11.7763 9.24292 11.8855 9.18594 11.962 9.09506L16.7248 3.34766V21.2853C16.7248 21.4046 16.7722 21.5191 16.8566 21.6035C16.941 21.6879 17.0554 21.7353 17.1748 21.7353C17.2941 21.7353 17.4086 21.6879 17.493 21.6035C17.5774 21.5191 17.6248 21.4046 17.6248 21.2853V3.37226L22.3876 9.09566C22.4251 9.14137 22.4713 9.17919 22.5235 9.20692C22.5757 9.23465 22.6329 9.25174 22.6918 9.25721C22.7507 9.26268 22.8101 9.25642 22.8665 9.23878C22.9229 9.22114 22.9753 9.19248 23.0206 9.15446C23.1124 9.07823 23.1702 8.96866 23.1812 8.84984C23.1922 8.73102 23.1556 8.61269 23.0794 8.52086Z" fill="black" />
        </svg>

    )
}

export default SortIcon