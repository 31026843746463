import React, { useState, useEffect, useCallback } from "react";
import "./login-signup.scss";
import { Link, useNavigate } from 'react-router-dom';
import Apiauth from "../../../API/Apiauth";
import { useTranslation } from 'react-i18next';
const Loginwithpin = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    const LOGINWITHPIN = localStorage.getItem("LOGINWITHPIN");
    useEffect(() => {
        let otptimer;
        if (otptimer > 0) {
            setInterval(() => {

            }, 1000);
        }
    }, []);
    const [lodindata, setLogindata] = useState({ "pin": "", "authToken": LOGINWITHPIN })
    const [loginvalid, setLoginvalid] = useState({})
    const logingis = useCallback(async () => {
        setLoginvalid(prevState => ({
            ...prevState,
            checkerror: {
                lodar: true
            }
        }));
        const handleValidation = (e) => {
            let formIsValid = true;
            if (!lodindata?.pin) {
                formIsValid = false;
                setLoginvalid(prevState => ({
                    ...prevState,
                    PIN: {
                        message: "Pleace enter PIN",
                        status: true
                    }
                }));
                setLoginvalid(prevState => ({
                    ...prevState,
                    checkerror: {
                        lodar: false
                    }
                }));
            } else {
                formIsValid = true;
                setLoginvalid(prevState => ({
                    ...prevState,
                    PIN: {
                        message: "",
                        status: false
                    }
                }));
            }
            return formIsValid;
        }
        if (handleValidation()) {
            localStorage.removeItem('UserToken');
            localStorage.removeItem('updateModulePermission');
            localStorage.removeItem('updateUserPermission');
            try {
                let postdata = lodindata
                let res = await Apiauth(postdata, `Auth/Web/quickLogin `)
                if (res?.code === 200) {
                    if (res?.data?.twofactorauth) {
                        navigate('/appauth/verification', {
                            state: {
                                access_tokens: res?.data?.access_tokens,
                                maskEmail: res?.data?.maskEmail
                            },
                        })
                    } else {
                        setLoginvalid(prevState => ({
                            ...prevState,
                            checkerror: {
                                lodar: false
                            }
                        }));
                        if (res?.data?.storeUrl) {
                            if (res?.data?.loginPinToken) {
                                localStorage.setItem('LOGINWITHPIN', (res?.data?.loginPinToken));
                            } else {
                                localStorage.setItem('LOGINWITHPIN', "");
                            }
                            let rediractDomain = res?.data?.storeUrl;
                            const tokenPath = `/authentication/${res?.data?.access_tokens}`;
                            if (window.location.hostname !== new URL(rediractDomain).hostname) {
                                // Redirect to subdomain or different domain
                                const tokenPath_r = `authentication/${res?.data?.access_tokens}`;
                                window.location.assign(rediractDomain + tokenPath_r);
                            } else {
                                // Same domain: use React navigate
                                navigate(tokenPath, { state: { domain: rediractDomain } });
                            }

                        } else {
                            navigate('/login');

                        }
                    }
                } else {

                    setLoginvalid(prevState => ({
                        ...prevState,
                        error: {
                            message: res?.message,
                            status: true
                        }
                    }));
                    setLoginvalid(prevState => ({
                        ...prevState,
                        checkerror: {
                            lodar: false
                        }
                    }));
                }
            }
            catch (err) {
                console.log(err, "err")
            }
        }
    }, [lodindata, navigate])
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                logingis();
            }
        };
        document.addEventListener('keypress', handleKeyPress);
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, [logingis]);
    useEffect(() => {
        let bodyid = document.querySelector('#body_id');
        if (localStorage.getItem("UserLang") === 'SA.png') {
            bodyid.classList.add('rtl');
        } else {
            bodyid.classList.remove('rtl');
        }
    }, [])
    return (
        <div className="main-body bg-white p-0">
            <div className="login-page position-relative d-flex align-items-center justify-content-center">
                <img className="login-page-bg position-absolute end-0 bottom-0 w-75" alt="login-bg.png" src={base_assets + "images/login-bg.png"} />
                <div className="login-page-row d-flex justify-content-center align-items-center w-100">
                    <div className="text-center login-page-left-col">
                        <Link to="/">
                            <img className="login-page-left-col-img" alt="gis-xl-logo.png" src={base_assets + "images/gis-xl-logo.png"} />
                        </Link>
                        <span className="d-block mt-4 fs-4 fw-semibold login-page-left-col-text">{t("Start your journey with us")} <span className="main-green-text">{t(" today")}</span></span>
                    </div>
                    <div className="login-page-right-col">
                        <h1 className="login-page-right-col-heading fs-2 fw-semibold">{t("Welcome!")}</h1>
                        <h2 className="login-page-right-col-sub-heading fs-4 fw-semibold">{t("Login With PIN")}</h2>
                        <div className="login-page-right-col-form">
                            <div className="col-md-12">
                                <div className={`placeholder-up-input w-100 form-floating ${loginvalid?.PIN?.status ? "md-1" : "mb-4"}`}>
                                    <input type="number" id="StoreName"
                                        className={`placeholder-up-input-field form-control fs-6 px-3 ${loginvalid?.PIN?.status ? "border-danger" : ""}`}
                                        placeholder={t("Enter PIN")}
                                        onChange={(e) => setLogindata(prevState => ({
                                            ...prevState,
                                            pin: e.target.value
                                        }))} />
                                    <label className="placeholder-up-input-label fs-6" htmlFor="">
                                        {t("Enter PIN")}
                                    </label>
                                </div>
                                {loginvalid?.PIN?.status ? <span className="d-block mb-4 text-danger">{loginvalid?.PIN?.message ?
                                    t(loginvalid?.PIN?.message) : ""}</span> : ""}
                            </div>
                            <div className="login-page-right-col-form-forgot-pass d-flex justify-content-between">
                                <p className="text-start">
                                    <Link to="/login" className="text-decoration-none fw-semibold">
                                        {t("Login With Password")}
                                    </Link>
                                </p>
                                <p className="text-end">
                                    <Link to="/appauth/Changepin" className="text-decoration-none fw-semibold">{t("Forgot PIN")} ?</Link>
                                </p>
                            </div>
                            <p>
                                {loginvalid?.error?.status ? <span className="d-block mb-4 text-danger">{loginvalid?.error?.message ?
                                    t(loginvalid?.error?.message) : ""}</span> : ""}
                            </p>
                            <button className="login-page-right-col-form-login-btn main-green-bg text-white text-center w-100 border-0 rounded-1 fs-6 fw-semibold d-flex align-items-center justify-content-center">
                                {loginvalid?.checkerror?.lodar ?
                                    <div className="border-bottom-0 border-width-2px d-block text-white mx-auto spinner-border flex-shrink-0" role="status"></div> :
                                    <span className="h-100 w-100 justify-content-center align-items-center d-flex" onClick={() => {
                                        logingis()
                                    }}> {t("Login")}</span>}


                            </button>

                            <p className="login-page-right-col-form-foot text-center mb-0">{t("Require an account")} ? <br />
                                <Link to={process.env.REACT_APP_BASE_URL + "appauth/signup"} className="main-green-text fw-semibold text-decoration-none">{t("Sign Up")} </Link>
                                {t("to fill your information")}
                            </p>
                        </div>
                        <div className="">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loginwithpin;