import React, { useEffect, useState } from 'react'
import './customerlist.scss'
import CellPhoneGreen from '../../common/icons/sidebar/CellPhoneGreen'
import Messegeboxblue from '../../common/icons/sidebar/Messegeboxblue'
import RightIcon from '../../common/icons/sidebar/RightIcon'
import { useTranslation } from 'react-i18next';
import Location from '../../common/icons/sidebar/Location'
import CloseIcon from '../../common/icons/CloseIcon'
import { _Api } from '../../api/_call'
import { Link } from 'react-router-dom'

export default function CustomerList({ setCustomerCount, customerData }) {
  const customerslist = []
  const { t } = useTranslation();
  const [openIndex, setOpenIndex] = useState(null);
  const [customerDataa,] = useState(customerData ? customerData : customerslist);
  const [searchValue, setSearchValue] = useState('');
  const [customerArrayDuplicate, setCustomerArrayDuplicate] = useState(customerData ? customerData : customerslist);
  const [activeAccordionDetails, setActiveAccordionDetails] = useState();
  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    setCustomerCount(customerDataa.length)
    // getCustomerlistDetails();
  })
  const getCustomerlistDetails = async (id, index) => {
    if (openIndex !== index) {
      let postdata = {
        "customer_id": id
      }
      try {
        let res = await _Api(postdata, `api/v1/App/calender/getCalender-customer-infoById`)
        if (res?.code === 200) {
          setActiveAccordionDetails(res?.Data)
        }
      }
      catch (err) {
        console.log(err, "err")
      }
    }
  }

  const handleSearch = (e) => {
    setSearchValue(e.target.value)
    let updatedArray = [...customerDataa];
    updatedArray = updatedArray?.filter((item) => ((item?.name).toLowerCase()).includes((e.target.value).toLowerCase()) || (item?.email).includes((e.target.value).toLowerCase()) || (item.contacts).includes(e.target.value));
    setCustomerArrayDuplicate(state => [...updatedArray]);
    setOpenIndex("")
  }

  const handleSearchClose = () => {
    setSearchValue("");
    setCustomerArrayDuplicate(customerDataa)
    setOpenIndex(null)
  }

  // const handleAccordionData = async(id) => {
  //     let data = [];

  //   return(

  //   )

  // }

  return (
    <div className='customers'>
      <div className='customers-search'>
        <input type='text' value={searchValue} className='customers-search-input' placeholder='Search' onChange={(e) => handleSearch(e)} />
        {searchValue.length ? <div className='customers-search-closeicon' onClick={handleSearchClose}><CloseIcon color="#767676" /></div> : ""}
      </div>
      <div className='customers-list'>
        {customerArrayDuplicate.length > 0 ?
          customerArrayDuplicate.map((customer, i) => {
            return (
              <div key={i}>
                <div className='customers-list-wrapper' onClick={() => { toggleAccordion(i); getCustomerlistDetails(customer?._id, i) }} key={i}>
                  <div className='customers-list-wrapper-img'><img src={customer?.profile ? customer?.profile : ""} alt="" /></div>
                  <div className='customers-list-wrapper-content'>
                    <div className='customers-list-wrapper-content-title'>{customer?.name}</div>
                    <div className='customers-list-wrapper-content-details'>
                      <div className='customers-list-wrapper-content-details-products'>{t("SKU")} {customer?.SKU} . {t("QTY")} {customer?.QTY}</div>
                      <div className='customers-list-wrapper-content-details-contact' onClick={(e) => e.stopPropagation()}>
                        <Link className='customers-list-wrapper-content-details-contact-icons' to={`tel:${customer?.contacts}`}><CellPhoneGreen /></Link>
                        <span className='customers-list-wrapper-content-details-contact-icons'><Link to={`mailto:${customer?.email}`}><Messegeboxblue /></Link></span>
                      </div>
                    </div>
                  </div>
                  <div className={`customers-list-wrapper-dropdown ${openIndex === i ? 'open' : ''}`}>
                    <RightIcon />
                  </div>
                </div>

                <div className={`customers-list-accordiandropdown ${openIndex === i ? 'open' : ""}`}>
                  {activeAccordionDetails ?
                    <>
                      {activeAccordionDetails?.PurchaseOrder.length > 0 ?
                        <div className='customers-list-accordiandropdown-inner'>
                          <span className='customers-list-accordiandropdown-inner-title'>{t("Purchase Order")}</span>

                          <div className='customers-list-accordiandropdown-inner-itemlist' >
                            {activeAccordionDetails?.PurchaseOrder?.map((pos, i) => {
                              const statusTag = (status) => {
                                switch (status) {
                                  case 0:
                                    return 'Canceled';
                                  case 1:
                                    return 'Completed';
                                  case 2:
                                    return 'Pending';
                                  case 3:
                                    return 'Approved';
                                  default:
                                    return '';
                                }
                              }
                              const statusColor = (status) => {
                                switch (status) {
                                  case 0:
                                    return '#FF5757';
                                  case 1:
                                    return '#68b8f2';
                                  case 2:
                                    return '#F9BC44';
                                  case 3:
                                    return '#72D3CE';
                                  default:
                                    return '';
                                }
                              }
                              return (
                                <div className='customers-list-accordiandropdown-inner-itemlist-product' key={i}>
                                  <div className='customers-list-accordiandropdown-inner-itemlist-product-status'>
                                    <div className='customers-list-accordiandropdown-inner-itemlist-product-status-symbol'><div className='dot purchase'></div></div>
                                    <div className='d-grid w-100'>
                                      <div className='customers-list-accordiandropdown-inner-itemlist-product-status-content'>
                                        <div className='customers-list-accordiandropdown-inner-itemlist-product-status-content-productid'>{pos?.OrderNo}</div>
                                        <div className='customers-list-accordiandropdown-inner-itemlist-product-status-content-statustag' style={{ backgroundClip: statusColor(pos?.status) }}>{statusTag(pos?.status)}</div>
                                      </div>
                                      <div className='customers-list-accordiandropdown-inner-itemlist-product-status-about'>
                                        <div className='customers-list-accordiandropdown-inner-itemlist-product-status-about-location'><Location color="#A0A0A0" /> <span> {pos?.location} </span></div>
                                        <div className='customers-list-accordiandropdown-inner-itemlist-product-status-about-time'>{pos?.delievery_date}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='customers-list-accordiandropdown-inner-itemlist-product-productdetails'>
                                    <img className='customers-list-accordiandropdown-inner-itemlist-product-productdetails-img' src={pos?.profile} alt='posimg' />
                                    <div className='customers-list-accordiandropdown-inner-itemlist-product-productdetails-data'>
                                      <div className='customers-list-accordiandropdown-inner-itemlist-product-productdetails-data-details'>
                                        <div>{pos?.sku}</div>
                                        <div>{pos?.qty} pcs</div>
                                      </div>
                                      <div className='customers-list-accordiandropdown-inner-itemlist-product-productdetails-data-amount'>
                                        <div className=''>
                                          {pos?.price}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                        : ""}
                      {activeAccordionDetails?.CustomOrder.length > 0 ?
                        <div className='customers-list-accordiandropdown-inner'>
                          <span className='customers-list-accordiandropdown-inner-title'>{t("Customer Order")}</span>
                          <div className='customers-list-accordiandropdown-inner-itemlist'>
                            {activeAccordionDetails?.CustomOrder?.map((details, i) => {
                              const statusTag = (status) => {
                                switch (status) {
                                  case 1:
                                    return 'Open Order';
                                  case 2:
                                    return 'Production';
                                  case 3:
                                    return 'MGF Stock';
                                  case 4:
                                    return 'Transit';
                                  case 5:
                                    return 'Stock';
                                  case 6:
                                    return 'Sold Out';
                                  case 9:
                                    return 'Close Order';
                                  default:
                                    return '';
                                }
                              }
                              const statusColor = (status) => {
                                switch (status) {
                                  case 1:
                                    return '#F9BC44';
                                  case 2:
                                    return '#FFC27B';
                                  case 3:
                                    return '#72D3CE';
                                  case 4:
                                    return '#97BFD9';
                                  case 5:
                                    return '#81C5F7';
                                  case 6:
                                    return '#F08585';
                                  case 9:
                                    return '#d9d9d9';
                                  default:
                                    return '#fff';
                                }
                              }
                              return (
                                <div className='customers-list-accordiandropdown-inner-itemlist-product' key={i}>
                                  <div className='customers-list-accordiandropdown-inner-itemlist-product-status'>
                                    <div className='customers-list-accordiandropdown-inner-itemlist-product-status-symbol'><div className='dot customer'></div></div>
                                    <div className='d-grid w-100'>
                                      <div className='customers-list-accordiandropdown-inner-itemlist-product-status-content'>
                                        <div className='customers-list-accordiandropdown-inner-itemlist-product-status-content-productid'>{details?.OrderNo}</div>
                                        {details?.status ? <div className='customers-list-accordiandropdown-inner-itemlist-product-status-content-statustag' style={{ backgroundColor: statusColor(details?.status) }}>{statusTag(details?.status)}</div> : ""}
                                      </div>
                                      <div className='customers-list-accordiandropdown-inner-itemlist-product-status-about'>
                                        <div className='customers-list-accordiandropdown-inner-itemlist-product-status-about-location'><Location color="#A0A0A0" /> <span> {details?.location} </span></div>
                                        <div className='customers-list-accordiandropdown-inner-itemlist-product-status-about-time'>{(details?.delievery_date)}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='customers-list-accordiandropdown-inner-itemlist-product-productdetails'>
                                    <img className='customers-list-accordiandropdown-inner-itemlist-product-productdetails-img' src={details?.profile} alt='skuimg' />
                                    <div className='customers-list-accordiandropdown-inner-itemlist-product-productdetails-data'>
                                      <div className='customers-list-accordiandropdown-inner-itemlist-product-productdetails-data-details'>
                                        <div>{details?.sku}</div>
                                        <div>{details?.qty} pcs</div>
                                      </div>
                                      <div className='customers-list-accordiandropdown-inner-itemlist-product-productdetails-data-amount'>
                                        <div className=''>
                                          $ {details?.price}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                        : ""}
                      {activeAccordionDetails?.Appointment.length > 0 ?
                        <div className='customers-list-accordiandropdown-inner'>
                          <span className='customers-list-accordiandropdown-inner-title'>{t("Appointment Order")}</span>
                          <div className='customers-list-accordiandropdown-inner-itemlist'>
                            {activeAccordionDetails?.Appointment?.map((appointment, i) => {
                              const statusTag = (status) => {
                                switch (status) {
                                  case 0:
                                    return 'Open';
                                  case 1:
                                    return 'Close';
                                  case 2:
                                    return 'Cancel';
                                  default:
                                    return 'Unknown status';
                                }
                              }
                              const statusColor = (status) => {
                                switch (status) {
                                  case 0:
                                    return '#5CC7C1';
                                  case 1:
                                    return '#F9BC44';
                                  case 2:
                                    return '#FF5757';
                                  default:
                                    return 'Unknown status';
                                }
                              }
                              return (
                                <div className='customers-list-accordiandropdown-inner-itemlist-product' key={i}>
                                  <div className='customers-list-accordiandropdown-inner-itemlist-product-status'>
                                    <div className='customers-list-accordiandropdown-inner-itemlist-product-status-symbol'><div className='dot appointment'></div></div>
                                    <div className='d-grid w-100'>
                                      <div className='customers-list-accordiandropdown-inner-itemlist-product-status-content'>
                                        <div className='customers-list-accordiandropdown-inner-itemlist-product-status-content-productid'>{appointment?.OrderNo}</div>
                                        <div className='customers-list-accordiandropdown-inner-itemlist-product-status-content-statustag' style={{ backgroundColor: statusColor(appointment?.status) }}>{statusTag(appointment?.status)}</div>
                                      </div>
                                      <div className='customers-list-accordiandropdown-inner-itemlist-product-status-about'>
                                        <div className='customers-list-accordiandropdown-inner-itemlist-product-status-about-location'><Location color="#A0A0A0" /> <span> {appointment?.location} </span></div>
                                        <div className='customers-list-accordiandropdown-inner-itemlist-product-status-about-time'>{(appointment?.delievery_date)}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='customers-list-accordiandropdown-inner-itemlist-product-productdetails'>
                                    <img className='customers-list-accordiandropdown-inner-itemlist-product-productdetails-img' src={appointment?.profile} alt='skuimg' />
                                    <div className='customers-list-accordiandropdown-inner-itemlist-product-productdetails-data'>
                                      <div className='customers-list-accordiandropdown-inner-itemlist-product-productdetails-data-details'>
                                        <div>{appointment?.sku}</div>
                                        <div>{appointment?.qty} pcs</div>
                                      </div>
                                      <div className='customers-list-accordiandropdown-inner-itemlist-product-productdetails-data-amount'>
                                        <div className=''>
                                          $ {appointment?.price}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                        : ""}
                      {activeAccordionDetails?.RepairOrder.length > 0 ?
                        <div className='customers-list-accordiandropdown-inner'>
                          <span className='customers-list-accordiandropdown-inner-title'>{t("Repair Order")}</span>
                          <div className='customers-list-accordiandropdown-inner-itemlist'>
                            {activeAccordionDetails?.RepairOrder?.map((repair, i) => {
                              const statusTag = (status) => {
                                switch (status) {
                                  case 1:
                                    return 'Open Order';
                                  case 2:
                                    return 'Production';
                                  case 3:
                                    return 'MGF Stock';
                                  case 4:
                                    return 'Transit';
                                  case 5:
                                    return 'Stock';
                                  case 6:
                                    return 'Sold Out';
                                  case 9:
                                    return 'Close Order';
                                  default:
                                    return 'Unknown status';
                                }
                              }
                              const statusColor = (status) => {
                                switch (status) {
                                  case 1:
                                    return '#F9BC44';
                                  case 2:
                                    return '#FFC27B';
                                  case 3:
                                    return '#72D3CE';
                                  case 4:
                                    return '#97BFD9';
                                  case 5:
                                    return '#81C5F7';
                                  case 6:
                                    return '#F08585';
                                  case 9:
                                    return '#d9d9d9';
                                  default:
                                    return 'Unknown status';
                                }
                              }
                              return (
                                <div className='customers-list-accordiandropdown-inner-itemlist-product' key={i}>
                                  <div className='customers-list-accordiandropdown-inner-itemlist-product-status'>
                                    <div className='customers-list-accordiandropdown-inner-itemlist-product-status-symbol'><div className='dot repair'></div></div>
                                    <div className='d-grid w-100'>
                                      <div className='customers-list-accordiandropdown-inner-itemlist-product-status-content'>
                                        <div className='customers-list-accordiandropdown-inner-itemlist-product-status-content-productid'>{repair?.OrderNo}</div>
                                        <div className='customers-list-accordiandropdown-inner-itemlist-product-status-content-statustag' style={{ backgroundColor: statusColor(repair?.status) }}>{statusTag(repair?.status)}</div>
                                      </div>
                                      <div className='customers-list-accordiandropdown-inner-itemlist-product-status-about'>
                                        <div className='customers-list-accordiandropdown-inner-itemlist-product-status-about-location'><Location color="#A0A0A0" /> <span> {repair?.location} </span></div>
                                        <div className='customers-list-accordiandropdown-inner-itemlist-product-status-about-time'>{(repair?.delievery_date)}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='customers-list-accordiandropdown-inner-itemlist-product-productdetails'>
                                    <img className='customers-list-accordiandropdown-inner-itemlist-product-productdetails-img' src={repair?.profile} alt='skuimg' />
                                    <div className='customers-list-accordiandropdown-inner-itemlist-product-productdetails-data'>
                                      <div className='customers-list-accordiandropdown-inner-itemlist-product-productdetails-data-details'>
                                        <div>{repair?.sku}</div>
                                        <div>{repair?.qty} pcs</div>
                                      </div>
                                      <div className='customers-list-accordiandropdown-inner-itemlist-product-productdetails-data-amount'>
                                        <div className=''>
                                          $ {repair?.price}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                        : ""}
                      {activeAccordionDetails?.ReservedOrder.length > 0 ?
                        <div className='customers-list-accordiandropdown-inner'>
                          <span className='customers-list-accordiandropdown-inner-title'>{t("Reserve Order")}</span>
                          <div className='customers-list-accordiandropdown-inner-itemlist'>
                            {activeAccordionDetails?.ReservedOrder?.map((reserve, i) => {
                              const statusTag = (status) => {
                                switch (status) {
                                  case 0:
                                    return 'Reserve';
                                  case 1:
                                    return 'Close Order';
                                  default:
                                    return 'Unknown status';
                                }
                              }
                              const statusColor = (status) => {
                                switch (status) {
                                  case 0:
                                    return '#FFC27B';
                                  case 1:
                                    return '#d9d9d9';
                                  default:
                                    return 'Unknown status';
                                }
                              }
                              return (
                                <div className='customers-list-accordiandropdown-inner-itemlist-product' key={i}>
                                  <div className='customers-list-accordiandropdown-inner-itemlist-product-status'>
                                    <div className='customers-list-accordiandropdown-inner-itemlist-product-status-symbol'><div className='dot reserve'></div></div>
                                    <div className='d-grid w-100'>
                                      <div className='customers-list-accordiandropdown-inner-itemlist-product-status-content'>
                                        <div className='customers-list-accordiandropdown-inner-itemlist-product-status-content-productid'>{reserve?.OrderNo}</div>
                                        <div className='customers-list-accordiandropdown-inner-itemlist-product-status-content-statustag' style={{ backgroundColor: statusColor(reserve?.status) }}>{statusTag(reserve?.status)}</div>
                                      </div>
                                      <div className='customers-list-accordiandropdown-inner-itemlist-product-status-about'>
                                        <div className='customers-list-accordiandropdown-inner-itemlist-product-status-about-location'><Location color="#A0A0A0" /> <span> {reserve?.location} </span></div>
                                        <div className='customers-list-accordiandropdown-inner-itemlist-product-status-about-time'>{(reserve?.delievery_date)}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='customers-list-accordiandropdown-inner-itemlist-product-productdetails'>
                                    <img className='customers-list-accordiandropdown-inner-itemlist-product-productdetails-img' src={reserve?.profile} alt='profileimage' />
                                    <div className='customers-list-accordiandropdown-inner-itemlist-product-productdetails-data'>
                                      <div className='customers-list-accordiandropdown-inner-itemlist-product-productdetails-data-details'>
                                        <div>{reserve?.sku}</div>
                                        <div>{reserve?.qty} pcs</div>
                                      </div>
                                      <div className='customers-list-accordiandropdown-inner-itemlist-product-productdetails-data-amount'>
                                        <div className=''>
                                          $ {reserve?.price}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                            }
                          </div>
                        </div>
                        : ""}
                    </> :
                    <div className='loader-holder'>
                      <div id="dark_loader" className="mt-4">
                        <div className="dots">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  }

                </div>
              </div>
            )
          }) :
          <div className='loader-holder'>
            <div id="dark_loader" className="mt-4">
              <div className="dots">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}
