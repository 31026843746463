import React from 'react'

export default function CheckoutBucketIcon({type}) {
  return (
    <>
    {type === 'normal' ? 

    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.75 0.75H3.75L5.76 10.7925C5.82858 11.1378 6.01643 11.448 6.29066 11.6687C6.56489 11.8895 6.90802 12.0067 7.26 12H14.55C14.902 12.0067 15.2451 11.8895 15.5193 11.6687C15.7936 11.448 15.9814 11.1378 16.05 10.7925L17.25 4.5H4.5M7.5 15.75C7.5 16.1642 7.16421 16.5 6.75 16.5C6.33579 16.5 6 16.1642 6 15.75C6 15.3358 6.33579 15 6.75 15C7.16421 15 7.5 15.3358 7.5 15.75ZM15.75 15.75C15.75 16.1642 15.4142 16.5 15 16.5C14.5858 16.5 14.25 16.1642 14.25 15.75C14.25 15.3358 14.5858 15 15 15C15.4142 15 15.75 15.3358 15.75 15.75Z" stroke="#181818" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    : 

    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 8.61125L12.14 7.75L14.7575 5.125H8.625V3.875H14.7575L12.1325 1.25L13 0.39L17.11 4.5L13 8.61125ZM7.135 23.395C6.6075 23.395 6.16292 23.2142 5.80125 22.8525C5.43958 22.4908 5.25917 22.0467 5.26 21.52C5.26083 20.9933 5.44125 20.5487 5.80125 20.1862C6.16292 19.8254 6.6075 19.645 7.135 19.645C7.6625 19.645 8.10667 19.8254 8.4675 20.1862C8.82833 20.5471 9.00917 20.9917 9.01 21.52C9.01083 22.0483 8.83 22.4925 8.4675 22.8525C8.105 23.2125 7.66083 23.3933 7.135 23.395ZM18.865 23.395C18.3383 23.395 17.8942 23.2142 17.5325 22.8525C17.1708 22.4908 16.99 22.0467 16.99 21.52C16.99 20.9933 17.1708 20.5487 17.5325 20.1862C17.8942 19.8254 18.3383 19.645 18.865 19.645C19.3917 19.645 19.8363 19.8254 20.1988 20.1862C20.5596 20.5479 20.74 20.9925 20.74 21.52C20.74 22.0467 20.5596 22.4908 20.1988 22.8525C19.8371 23.2142 19.3925 23.395 18.865 23.395ZM0.5 1.375V0.125H3.56L8.68 10.895H16.9737C17.1179 10.895 17.2463 10.8588 17.3588 10.7863C17.4704 10.7137 17.5662 10.6137 17.6462 10.4862L22.0162 2.625H23.4412L18.7113 11.1687C18.5304 11.4729 18.2954 11.7117 18.0062 11.885C17.7171 12.0583 17.3992 12.145 17.0525 12.145H8.125L6.60625 14.9325C6.47792 15.125 6.47375 15.3333 6.59375 15.5575C6.71375 15.7817 6.89417 15.8942 7.135 15.895H20.74V17.145H7.135C6.40583 17.145 5.86042 16.8387 5.49875 16.2262C5.13708 15.6137 5.12958 14.995 5.47625 14.37L7.35625 11.02L2.76 1.375H0.5Z" fill="black"/>
    </svg>
    }
    </>
  )
}
