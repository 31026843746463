import axios from 'axios';
const graphqlPost = function (querystring = "", url = 'Inventory/graphql') {
    return new Promise(async (resolve, reject) => {
        var data = JSON.stringify({
            query: querystring,
            variables: {}
        });
        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + 'api/v1/' + url,
            headers: {
                'authorization': localStorage.getItem("UserToken"),
                'Content-Type': 'application/json'
            },
            data: data
        };
        await axios(config)
            .then(function (response) {
                if (response?.data?.code === 403) {
                    localStorage.removeItem("UserToken");
                    localStorage.removeItem('updateModulePermission');
                    localStorage.removeItem('updateUserPermission');
                    var currentPath = window.location.pathname;
                    window.location.href = "/login?msg=sessionexpired&history=" + encodeURIComponent(currentPath);
                } else { resolve(response?.data); }


            })
            .catch(function (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else if (error.response) {
                    console.error('Server responded with an error:', error.response.data);
                    console.error('Status code:', error.response.status);
                } else if (error.request) {
                    console.error('No response received:', error.request);
                } else {
                    console.error('Error during request setup:', error.message);
                }
            });
    });
};


export default graphqlPost;
