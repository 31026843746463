import React, { useEffect, useState } from 'react'
import PlusIcon from '../../assets/icons/PlusIcon';
import { _Api } from "../../../common/api"
import { Link, useLocation } from 'react-router-dom';
import Appointmentmodal from './Appointmentmodal';
import { useTranslation } from 'react-i18next';
import Spinner from "../../../common/Spinner";
const Appointment = () => {
    const { state } = useLocation()
    const [loadeing, setloading] = useState(true)
    const { t } = useTranslation();
    const [appointmentdtaa, setAppointmentdtaa] = useState([])
    const [locationdata, setLocationdata] = useState([])
    const [allfromdata, setAllfromdata] = useState()
    const [isOpenOrder, setIsOpenOrder] = useState(false);
    const getappointmentdata = async () => {
        setloading(true)
        try {
            let cartids = [];
            if (state?.checkoutItems?.length) { state?.checkoutItems?.map((result) => cartids.push(result?.id)) }
            let postdata = {
                cart_ids: cartids
            }
            let res = await _Api(postdata, `order/getCartDetails`)
            if (res?.statusCode === 200) {
                setloading(false)
                let array = [...res?.data];
                array?.forEach((result) => {
                    result.qty = parseInt(state?.checkoutItems.filter(e => e?.id === result?.id)[0]['qty']);
                })
                setAppointmentdtaa(array)
                setAllfromdata((privcestate) => ({
                    ...privcestate,
                    product: state?.checkoutItems
                }))
                if (res?.deleted_ids?.length) {
                    state?.checkoutItems.forEach((result) => {
                        if (!res?.deleted_ids.includes(result?.id)) {
                            let data = []
                            data.push(result)
                            localStorage.setItem('CartItems', JSON.stringify(data));
                        }
                    })
                }
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const getlocationdata = async () => {
        try {
            let postdata = {}
            let res = await _Api(postdata, `redis/shipingMathod`)
            if (res?.statusCode === 200) {
                setLocationdata(res?.StorePickup)
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const toggleDiv = () => {
        setIsOpenOrder(!isOpenOrder);
    };

    useEffect(() => {
        getappointmentdata()
        getlocationdata()
        // eslint-disable-next-line
    }, [])
    return (
        <div className='wishlistMain ecommerce-main-layout-bodyContent'>
            <div className='com-path-show'><span className='com-path-show-startPath'>{t("Collection")}</span> <span className='SlashPath'>/</span> <span className='com-path-show-currectPath'>{t("Appointment")}</span></div>
            <div className='wishlistPage appointmentPage'>
                <div className='wishlistMain-container appointmentPage-container'>
                    <div className='wishlistMain-container-headerBox app'>
                        <div className={`wishlistMain-container-headerBox-headerName appointmentdeskHead ${appointmentdtaa?.length ? 'd-none' : 'd-block'} `} >{t("Appointment")}</div>
                    </div>
                    {loadeing ?
                        (<div className=" w-100 d-flex justify-content-center align-items-center  ">
                            <Spinner />
                        </div>) :
                        appointmentdtaa?.length ?
                            <div className='row  appointmentPage-container-contentPage' >
                                <div className={`tabHideOrder-box ${isOpenOrder ? 'open' : 'bg-white'}`} onClick={toggleDiv}>
                                    <p className='tabHideOrder-box-contentText'>{t("Hide order Appointment")}</p>
                                    <span className='arrow'></span>
                                </div>
                                <div className={isOpenOrder ? 'active wishlistMain-container-bodyProCont col-8 h-100  overflow-auto appointmentPage-container-contentPage-leftSideBox' : ' wishlistMain-container-bodyProCont col-8 h-100  overflow-auto appointmentPage-container-contentPage-leftSideBox'} id='appointmentOrder'
                                    style={{ scrollbarColor: "transparent transparent" }}>
                                    {appointmentdtaa?.length ? appointmentdtaa.map((result, key) => {
                                        return (
                                            <div className='wishlistMain-container-bodyProCont-singlePro  ps-0 appointmentPage-container-contentPage-leftSideBox-singleProductdetail' key={key}>
                                                <div className='wishlistMain-container-bodyProCont-singlePro-productType'>
                                                    <div className='wishlistMain-container-bodyProCont-singlePro-productType-imageBox'>
                                                        <span className='product-img-span'><img src={result?.main_image} alt='' /></span>
                                                    </div>
                                                    <div className='wishlistMain-container-bodyProCont-singlePro-productType-details'>
                                                        <div className='wishlistMain-container-bodyProCont-singlePro-productType-details-firstBox'>
                                                            <p className='wishlistMain-container-bodyProCont-singlePro-productType-details-firstBox-productName'>{result?.name}</p>
                                                            <div className='wishlistMain-container-bodyProCont-singlePro-productType-details-firstBox-content'>
                                                                <p className='contentPara'>{result?.SKU}</p>
                                                                <p className='contentPara'>{result?.Metal}</p>
                                                                <p className='contentPara'><span>{t("Size")}:</span> {result?.Size} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : ""}
                                </div>

                                <div className='col h-100 overflow-auto d-flex flex-column gap-4  appointmentPage-container-contentPage-rightSideBox'>
                                    <div className='appointmentTabMain_header'>{t("Appointment")}</div>
                                    {locationdata?.length ? locationdata.map((result, key) => {
                                        return (
                                            <div className="com-ShippAddressBox w-100" key={key} >
                                                <div className="com-ShippAddressBox-contentBox" key={key}>
                                                    <div className="userDetailShipp">
                                                        <h3 className="com-ShippAddressBox-contentBox-boldText">{result?.name}</h3>
                                                        <p className="com-ShippAddressBox-contentBox-paraText">{result?.store_Address}</p>
                                                    </div>
                                                    <div className="d-flex gap-1">
                                                        <h3 className="com-ShippAddressBox-contentBox-boldText">{t("Phone Number")}:</h3>
                                                        <p className="com-ShippAddressBox-contentBox-lightText">{result?.phone}</p>
                                                    </div>
                                                    <div className="d-flex gap-1" >
                                                        <h3 className="com-ShippAddressBox-contentBox-boldText">{t("Store Hours")}</h3>
                                                        <p className="com-ShippAddressBox-contentBox-lightText"></p>
                                                    </div>
                                                    <div className='shipping-timeSetBox' >
                                                        {Object.keys(result?.store_timeSchedule || {}).map((key) => (
                                                            <div className='shipping-timeSetBox-singleDay' key={key}>
                                                                <div className='shipping-timeSetBox-singleDay-day'>
                                                                    <p className='com-ShippAddressBox-contentBox-lightText' >{key}</p>
                                                                </div>
                                                                <div className='shipping-timeSetBox-singleDay-time'>
                                                                    <p className='com-ShippAddressBox-contentBox-lightText'>{result?.store_timeSchedule[key]}</p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <button className="com-ecomSendBtn mt-2 w-100" data-bs-toggle="modal" data-bs-target="#Appointmentmodal"
                                                        onClick={() => setAllfromdata((start) => ({
                                                            ...start,
                                                            location: result,
                                                            "name": "",
                                                            "mobile": "",
                                                            "email": "",
                                                            "message": "",
                                                            "appointment_date": "",
                                                            "phonecode": ""
                                                        }))}
                                                    >
                                                        {t("Appointment")}
                                                    </button>
                                                </div>
                                            </div>

                                        )
                                    }) : ""}
                                </div>
                            </div>
                            :
                            <div className='wishlistMain-container-bodyBox'>
                                <div className='wishlistMain-container-bodyBox-innerContent'>
                                    <p className='wishlistMain-container-bodyBox-innerContent-para'>{t("This list is empty")}.</p>
                                    <Link className='text-decoration-none' to="/">
                                        <div className='wishlistMain-container-bodyBox-innerContent-addProductBtnBox'>
                                            <PlusIcon width={16} height={16} color={"#FFFFFF"} />
                                            <span>{t("Add Product")}</span>
                                        </div>
                                    </Link>

                                </div>
                            </div>
                    }
                </div>
            </div>
            <Appointmentmodal
                allfromdata={allfromdata}
                setAllfromdata={setAllfromdata}
            />
        </div >
    )
}

export default Appointment