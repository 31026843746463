import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Slider from 'rc-slider';
import SortIcon from '../../assets/icons/SortIcon';
import BlockListIcon from '../../assets/icons/BlockListIcon';
import ListIcon from '../../assets/icons/ListIcon';
import ArrowDownside from '../../assets/icons/ArrowDownSide';
import Eye from '../../assets/icons/Eye';
import Spinner from '../../../common/Spinner';
import CollectionSlider from 'Ecommerce/template_three/include/Common/CollectionSlider';
import DiamondProductPage from './DiamondProductPage';
import { _Api } from 'Ecommerce/common/api';
import NoProductAvilable from 'Ecommerce/common/NoProductAvilable';
import FilterIconMain from 'admin/common/icons/sidebar/FilterIconMain';
import DownArrow from 'Ecommerce/template_three/assets/icons/DownArrow';




export default function SelectDiamond({reviewProduct, reviewProductId, getDiamondFilters, setGetDiamondFilters, productDetail, activeTab, setShowEngravingPage, setActiveTab, mobileTab, diamondProduct,flowMode, setDiamondProduct, engravingData, setEngravingData}) {
 
    const [getDiamondlist, setGetDiamondList] = useState();
    const [contentDisplayType, setContentDisplayType] = useState('block');
    const [wishlist ,setWishliatdata] = useState();
    const [dataload, setDataload] = useState(false)
    const [diamondpageData, setDiamondpagedata] = useState();
    const [showDiamondProduct, setShowDiamondProduct] = useState(false);
    const [diamondFilters, setDiamondFilters] = useState({"limit":20,"skip":10, "Cut": [], "Clarity": [], "Stonecolor": [], "Polish": [], "Symmetry": [],"fluorescence": [], "Lbs": [],"Status": [], "Location": [], "Shape": [], "Price": [], "Carat": "","Depth": [],"Table": [], "Colour" : []})
    const [minAndMax, setMinAndMax] = useState([getDiamondlist?.Price?.min, getDiamondlist?.Price?.max]);
    const { t } = useTranslation();
    const diamondPageRef = useRef();
    const [skip, setSkip] = useState(0)
    const [totaldata, setTotaldata] = useState();
    const [shortobj, setShortobj] = useState({})
    const [loadeing, setloading] = useState(true);
    const [showFilters ,setShowFilters] = useState(false)
    const [shortByActiveTab, setShortByActiveTab] = useState('')
    useEffect(()=>{
     scrollTOTop();
      getDiamonds();
      getFilters();
      if(getDiamondFilters !== undefined){
        let newArray = {...getDiamondFilters}
      setDiamondFilters((prev)=>prev = newArray)
      }
    },[]);
  
    const scrollTOTop = () =>{
      if (diamondPageRef.current) {
        diamondPageRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  
    useEffect(()=>{
      if(reviewProduct){
        setShowDiamondProduct(true);
        console.log(diamondProduct, "check diamond data on selectDiamond page")
      }
    },[diamondProduct])
  
    useEffect(()=>{
      getFilters();
    },[diamondFilters, shortobj])
  
  
  
  
    const getDiamonds = async() => {
      try {
        let prams = {}
        let res = await _Api(prams, `mixMatch/get-diamond-filters`)
        if (res?.status === 200) {
           setGetDiamondList(res?.data);
           setDiamondFilters((state)=>({...state, Price: [res?.data?.Price]}))
        }
    }
    catch (err) {
        console.log(err, "err")
    }
    }
  
   useEffect(()=>{
  
   },[diamondpageData])
  
    const getFilters = async(skipdata) => {
      console.log(skip, "find skip")
      try {
        let prams =  {
          "limit":12,
          "skip": skipdata ? skipdata : 0,
          "sort": { [shortobj?.key]: shortobj?.value },
          "CUT": diamondFilters?.Cut,
          "Clarity": diamondFilters?.Clarity,
          "Stonecolor": diamondFilters?.Colour,
          "Polish": diamondFilters?.Polish,
          "Symmetry": diamondFilters?.Symmetry,
          "fluorescence": diamondFilters?.fluorescence,
          "Lbs": [],
          "Status": [],
          "Location": [],
          "Shape": diamondFilters?.Shape,
          "Price": diamondFilters?.Price,
          "Carat": diamondFilters?.Carat,
          "Depth": [],
          "Table": [],
      }
      if (prams?.skip === 0) {
        setloading(true)
      } else {
        setDataload(true)
      }
        let res = await _Api(prams, `mixMatch/get-diamond-listwithfilters`)
        if (res?.code === 200) {
            setSkip(12 + prams?.skip)
           setTotaldata(res?.totalResult)
           if (prams?.skip === 0) {
            setDiamondpagedata(res?.data)
          } else {
            let finaldata = diamondpageData.concat(res?.data)
            setDiamondpagedata(finaldata);
          }
          setloading(false)
          setDataload(false)
        }
    }
    catch (err) {
        console.log(err, "err")
    }
    }
  
    
  
    const handleDiamondItem = async(id) =>{
      let localRecentViewed = localStorage.getItem('RecentViewedDiamonds') ? JSON.parse(localStorage.getItem('RecentViewedDiamonds')) : [];
          setTimeout(() => {
              if (!localRecentViewed.filter(e => e === id).length) {
                  localRecentViewed.push(id);
                  localStorage.setItem('RecentViewedDiamonds', JSON.stringify(localRecentViewed));
              }
          }, 15000)
      try {
        let  prams = {
          "id": id
      }
      
        let res = await _Api(prams, `mixMatch/get-diamond-details`)
        if (res?.code === 200) {
           setDiamondProduct(res?.data);
           setShowDiamondProduct(true)
        }
    }
    catch (err) {
        console.log(err, "err")
    }
    }
  
    const handleChangeFilter = (label, value) =>{
      let updatedArray = diamondFilters[label];
      let CheckIndex = updatedArray.some(item => 
        item === value._id);
      if (CheckIndex) {
          updatedArray = updatedArray.filter(item => item !== value?._id);
      } else {
          updatedArray.push(value?._id);
      }
      setDiamondFilters(state => 
          ({...state, [label]: updatedArray })
      )
    }
  
    const handlePriceSlider = (e) =>{
      setDiamondFilters(state => 
        ({...state, "Price" : [{min : e[0], max : e[1]}] })
      )
    }
    const handleMinInputChange = (e) => {
  
      const value = Number(e.target.value);
      const newLevel = diamondFilters.min
      if (value >= getDiamondlist?.Price?.[0]?.min && value <= getDiamondlist?.Price?.[0]?.max) { // Ensure min is less than max
        setDiamondFilters(state => 
          ({...state, "Price" : [newLevel] })
        )
      }
    };
    
    const handleMaxInputChange = (e) => {
      const value = Number(e.target.value);
      const newLavel = diamondFilters?.Price?.[0]
      if (value >= diamondFilters?.Price?.[0]?.min && value <= getDiamondlist?.Price?.[0]?.max) { // Ensure max is greater than min
        setMinAndMax([minAndMax[0], value]);
        setDiamondFilters(state => 
          ({...state, "Price" : [newLavel] })
        )
      }
    };
  
    const handleClear = (tab) =>{
      console.log(tab, diamondFilters, "diamondfilters" )
      setDiamondFilters((state)=>({...state, [tab] : []}))
    }
  
 
 
    return (
        <>
    <div className='mix_match_diamond' ref={diamondPageRef}>
      { !showDiamondProduct ? 
      <>
      
        <div className={`mix_match_diamond-filters ${mobileTab === 2 ? 'activeTab' : ""}`}>
        <div className='mix_match_diamond-filters-hiddenbar' onClick={()=>setShowFilters(!showFilters)}>
            <div className='mix_match_diamond-filters-hiddenbar-left'>
                <span><FilterIconMain shape="cone"/></span>
                <span>{t("Filter")}</span>
            </div>
            <div className={`mix_match_diamond-filters-hiddenbar-right ${showFilters ? 'active' : ""}`}>
                <span>{t("Hide")}</span>
                <span><DownArrow/></span>
            </div>
        </div>
            <div className='mix_match_diamond-filters-section'>
              <div className='mix_match_diamond-filters-section-shape'>
              <div className="headings"><span className='headings-name'>{t("Shape")}</span><span className='headings-close' onClick={()=>handleClear('Shape')}>{t("Clear")}</span></div>
              <div className="mix_match_diamond-filters-section-shape-inner">
                  { getDiamondlist ? getDiamondlist?.Shape?.map((shapes,i)=>{
                    return(
                      <div className={`mix_match_diamond-filters-section-shape-inner-diamond ${diamondFilters?.Shape?.includes(shapes?._id) ? "active" : ""}`} onClick={()=>handleChangeFilter('Shape', shapes)} key={i}>
                        <div className='mix_match_diamond-filters-section-shape-inner-diamond-imgholder'><img src={shapes?.logo} alt={shapes?.name}/></div>
                        <span className=''>{shapes?.name}</span>
                      </div>
                    )
                  }) : ""}
                </div>
              </div>
              <div className='mix_match_diamond-filters-section-allfilters'>
                {getDiamondlist?.Carat ? 
                <div className='mix_match_diamond-filters-section-allfilters-rangefilter'>
                <div className="headings"><span className='headings-name'>{t("Carat")}</span><span className='headings-close' onClick={()=>handleClear('Carat')}>{t("Clear")}</span></div>
              
                    <div className='mix_match_diamond-filters-section-allfilters-rangefilter-inner '>
                         <Slider
                          step={0.01}
                          min={getDiamondlist?.Carat?.min ||0}
                          max={getDiamondlist?.Carat?.max|| 3}
                          value={diamondFilters?.Carat}
                          onChange={(e)=>{
                            setDiamondFilters(state => 
                              ({...state, "Carat" : e })
                            )
                          }}
                        />
                        <div className='mix_match_diamond-filters-section-allfilters-rangefilter-inner-subdata'>
                          <span>{getDiamondlist?.Carat?.min}</span>
                          <span>{getDiamondlist?.Carat?.max}</span>
                        </div>
                    </div>
                </div>
                : "" }
                {getDiamondlist?.Color?.length > 0 ? 
                <div className='mix_match_diamond-filters-section-allfilters-tabfilter'>
                <div className="headings"><span className='headings-name'>{t("Color")}</span><span className='headings-close' onClick={()=>handleClear('Colour')}>{t("Clear")}</span></div>
                    <div className='mix_match_diamond-filters-section-allfilters-tabfilter-inner'>
                    {  getDiamondlist?.Color?.map((colors,i)=>{
                      return(
                    <div className={`mix_match_diamond-filters-section-allfilters-tabfilter-inner-tab ${diamondFilters?.Colour?.includes(colors?._id) ? "active" : ""}`} onClick={()=>handleChangeFilter('Colour', colors)} key={i}>
                       <span>{colors?.name}</span>
                    </div>
                      )
                    })
                    }
                    </div>
                </div>
                : "" }
                {getDiamondlist?.Clarity?.length > 0 ? 
                <div className='mix_match_diamond-filters-section-allfilters-tabfilter'>
                <div className="headings"><span className='headings-name'>{t("Clarity")}</span><span className='headings-close' onClick={()=>handleClear('Clarity')}>{t("Clear")}</span></div>
                    <div className='mix_match_diamond-filters-section-allfilters-tabfilter-inner'>
                    {  getDiamondlist?.Clarity?.map((calrity,i)=>{
                      return(
                    <div className={`mix_match_diamond-filters-section-allfilters-tabfilter-inner-tab ${diamondFilters?.Clarity?.includes(calrity._id) ? "active" : ""}`} onClick={()=>handleChangeFilter('Clarity', calrity)} key={i}>
                       <span>{calrity?.name}</span>
                    </div>
                      )
                    })
                    }
                    </div>
                </div>
                : "" }
                {getDiamondlist?.Cut?.length > 0 ? 
                <div className='mix_match_diamond-filters-section-allfilters-tabfilter'>
                <div className="headings"><span className='headings-name'>{t("Cut")}</span><span className='headings-close' onClick={()=>handleClear('Cut')}>{t("Clear")}</span></div>
                    <div className='mix_match_diamond-filters-section-allfilters-tabfilter-inner'>
                    {  getDiamondlist?.Cut?.map((cut,i)=>{
                      return(
                    <div className={`mix_match_diamond-filters-section-allfilters-tabfilter-inner-tab ${diamondFilters?.Cut?.includes(cut._id) ? "active" : ""}`} onClick={()=>handleChangeFilter('Cut', cut)} key={i}>
                       <span>{cut?.name}</span>
                    </div>
                      )
                    })
                    }
                    </div>
                </div>
                : "" }
                {getDiamondlist?.Polish?.length > 0 ? 
                <div className='mix_match_diamond-filters-section-allfilters-tabfilter'>
                <div className="headings"><span className='headings-name'>{t("Polish")}</span><span className='headings-close' onClick={()=>handleClear('Polish')}>{t("Clear")}</span></div>
                    <div className='mix_match_diamond-filters-section-allfilters-tabfilter-inner'>
                    {  getDiamondlist?.Polish?.map((polish,i)=>{
                      return(
                    <div className={`mix_match_diamond-filters-section-allfilters-tabfilter-inner-tab ${diamondFilters?.Polish?.includes(polish._id) ? "active" : ""}`} onClick={()=>handleChangeFilter('Polish', polish)} key={i}>
                       <span>{polish?.name}</span>
                    </div>
                      )
                    })
                    }
                    </div>
                </div>
                : "" }
                {getDiamondlist?.Price ? 
                <div className='mix_match_diamond-filters-section-allfilters-price'>
                <div className="headings"><span className='headings-name'>{t("Price")}</span><span className='headings-close' onClick={()=>handleClear('Price')}>{t("Clear")}</span></div>
            <div className='mix_match_diamond-filters-section-allfilters-price-rangebar'>
              {/* <input className='mix_match_diamond-filters-section-allfilters-price-rangebar-range' type='range' min="10,000" max="52,500"/> */}
              <Slider
                  range
                  min={getDiamondlist?.Price?.min}
                  max={getDiamondlist?.Price?.max}
                  onChange={handlePriceSlider}
                  value={[diamondFilters?.Price?.[0]?.min, diamondFilters?.Price?.[0]?.max]}
                />
              <div className='mix_match_diamond-filters-section-allfilters-price-rangebar-limitation'>
                <div className='mix_match_diamond-filters-section-allfilters-price-rangebar-limitation-min'>
                  <div>{t("Min Price:")}</div>
                  <input 
                   type="number"
                   value={minAndMax[0]}
                   onChange={handleMinInputChange}
                  />
                </div>
                <div className='mix_match_diamond-filters-section-allfilters-price-rangebar-limitation-min'>
                  <div>{t("Max Price:")}</div>
                  <input 
                   type="number"
                   value={minAndMax[1]}
                   onChange={handleMaxInputChange}
                  />
                </div>
              </div>
            </div>
            </div>
                : "" }
                {getDiamondlist?.Fluorescence?.length > 0 ? 
                <div className='mix_match_diamond-filters-section-allfilters-tabfilter'>
                <div className="headings"><span className='headings-name'>{t("Fluorescence")}</span><span className='headings-close' onClick={()=>handleClear('fluorescence')}>{t("Clear")}</span></div>
                    <div className='mix_match_diamond-filters-section-allfilters-tabfilter-inner'>
                    {  getDiamondlist?.Fluorescence?.map((data,i)=>{
                      return(
                    <div className={`mix_match_diamond-filters-section-allfilters-tabfilter-inner-tab ${diamondFilters?.fluorescence?.includes(data._id) ? "active" : ""}`} onClick={()=>handleChangeFilter('fluorescence', data)} key={i}>
                       <span>{data?.name}</span>
                    </div>
                      )
                    })
                    }
                    </div>
                </div>
                : "" }
                {getDiamondlist?.Symmetry?.length > 0 ? 
                <div className='mix_match_diamond-filters-section-allfilters-tabfilter'>
                <div className="headings"><span className='headings-name'>{t("Symmetry")}</span><span className='headings-close' onClick={()=>handleClear('Symmetry')}>{t("Clear")}</span></div>
                    <div className='mix_match_diamond-filters-section-allfilters-tabfilter-inner'>
                    {  getDiamondlist?.Symmetry?.map((data,i)=>{
                      return(
                    <div className={`mix_match_diamond-filters-section-allfilters-tabfilter-inner-tab ${diamondFilters?.Symmetry?.includes(data._id) ? "active" : ""}`} onClick={()=>handleChangeFilter('Symmetry', data)} key={i}>
                       <span>{data?.name}</span>
                    </div>
                      )
                    })
                    }
                    </div>
                </div>
                : "" }
              </div>
            </div>
        </div>
        <div className='mix_match_diamond-container'>
              <div className='mix_match_diamond-container-navbar'>
                <div className='mix_match_diamond-container-navbar-details'>
                  {t("Showing")} {diamondpageData?.length} {t("results")}
                  {/* <span onClick={()=>setContentDisplayType('block')}><BlockListIcon color={contentDisplayType === 'block' ? '' : '#767676'}/></span>
                  <span onClick={()=>setContentDisplayType('list')}><ListIcon color={contentDisplayType === 'list' ? '' : '#767676'}/></span> */}
                </div>
                <div className='mix_match_diamond-container-navbar-sortby'
                      id="headeraccountdropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false" 
                >
                  <span className='mix_match_diamond-container-navbar-sortby-text'>{t("Sort by")}</span>
                  <span className='mix_match_diamond-container-navbar-sortby-icon'><SortIcon width={14} height={16} /></span>
                  <ul
                className="dropdown-menu sortByDropdown-List-box"
                aria-labelledby="headeraccountdropdown"
              >
                <li className={shortByActiveTab === 'bestselling' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => setShortByActiveTab('bestselling')}>
                  {t("Best selling")}
                </li>
                <li className={shortByActiveTab === 'nameascending' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "name", value: 1 }); setShortByActiveTab('nameascending') }} >
                  {t("Alphabetically, A-Z")}
                </li>
                <li className={shortByActiveTab === 'namedescending' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "name", value: -1 }); setShortByActiveTab('namedescending') }}>
                  {t("Alphabetically, Z-A")}
                </li>
                <li className={shortByActiveTab === 'pricehtol' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "price", value: -1 }); setShortByActiveTab('pricehtol') }}>
                  {t("Price, high to low")}
                </li>
                <li className={shortByActiveTab === 'priceltoh' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "price", value: 1 }); setShortByActiveTab('priceltoh') }} >
                  {t("Price, low to high")}
                </li>
                <li className={shortByActiveTab === 'oldtonew' ? 'active' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "createdAt", value: 1 }); setShortByActiveTab('oldtonew') }} >
                  {t("Date, old to new")}
                </li>
                <li className={shortByActiveTab === 'newtoold' ? 'avtive' : ''} style={{ padding: '8px 16px' }} onClick={() => { setShortobj({ key: "createdAt", value: -1 }); setShortByActiveTab('newtoold') }}>
                  {t("Date, new to old")}
                </li>
              </ul>
                </div>
              </div>
                  <div className='mix_match_diamond-container-viewsection'>
                    <span>{t("view")}</span>
                  <span onClick={()=>setContentDisplayType('block')}><BlockListIcon color={contentDisplayType === 'block' ? '' : '#767676'}/></span>
                  <span onClick={()=>setContentDisplayType('list')}><ListIcon color={contentDisplayType === 'list' ? '' : '#767676'}/></span>
                  </div>
              {loadeing ?
            (<div className=" w-100 d-flex justify-content-center align-items-center  ">
              <Spinner />
            </div>) :
              <div className='mix_match_diamond-container-content'>
              { diamondpageData?.length > 0 && contentDisplayType === 'block' ?
                        <div className='mix_match_diamond-container-content-inner'>
                              {
                                diamondpageData?.map((item, key) => {
                                    return (

                                        <div onClick={()=>handleDiamondItem(item?._id)} className='mix_match_diamond-container-content-inner-product' key={key}>
                                            <div className='mix_match_diamond-container-content-inner-product-image'>
                                                <div className="mix_match_diamond-container-content-inner-product-image-imgBoxShow"><img src={item?.image} alt='product' /></div>
                                                
                                            </div>
                                            <div className="mix_match_diamond-container-content-inner-product-details text-decoration-none ">
                                                <div className='mix_match_diamond-container-content-inner-product-details-wrapper'>
                                                    <div className="mix_match_diamond-container-content-inner-product-details-wrapper-productName">
                                                    {item?.Cut} {item?.Carat} {t("Carat")} {item?.Culet} {("Diamond")}
                                                    </div>
                                                    <span> {t("Color")}: {item?.Color} {t("Certified by")}: {item?.Gradedby} {t("Cut")}: {item?.Cut}</span>
                                                </div>
                                                <div className='mix_match_diamond-container-content-inner-product-price'>
                                                    {item?.FormattaedPrice}
                                                </div>
                                            </div>
                                        </div>

                                    )
                                })
                              }
                        </div>
              : contentDisplayType === 'block' ? <NoProductAvilable /> : ""}
                        <div className='mix_match_diamond-container-content-listformat'>
                        { diamondpageData?.length > 0 && contentDisplayType === 'list' ?
                                      <table>
                                            <tr>
                                              <th className='ps-2'>#</th>
                                              {/* <th></th> */}
                                              <th>{t("Shape")}</th>
                                              <th>Carat</th>
                                              <th>Color</th>
                                              <th>Clarity</th>
                                              <th>Cut</th>
                                              <th>Polish</th>
                                              <th>Symmetry</th>
                                              <th>Certificate</th>
                                              <th>Price</th>
                                              <th>Location</th>
                                              <th></th>
                                            </tr>
                                            { diamondpageData?.map((item, key) => {
                                                 return (
                                                  <tr key={key}>
                                                    <td className='ps-2'>{t(`${key+1}`)}</td>
                                                    {/* <td className='image'><img src={item?.image}/></td> */}
                                                    <td>{item?.Shape}</td>
                                                    <td className='certificate'>{item?.Carat}</td>
                                                    <td>{item?.Colour}</td>
                                                    <td>{item?.Clarity}</td>
                                                    <td>{item?.Cut}</td>
                                                    <td>{item?.Polish}</td>
                                                    <td>{item?.Symmetry}</td>
                                                    <td className='certificate'>{item?.Certification}</td>
                                                    <td>{item?.FormattaedPrice}</td>
                                                    <td>{item?.Location}</td>
                                                    <td className='eyeicon'><span onClick={()=>handleDiamondItem(item?._id)}><Eye/></span></td>
                                                  </tr>
                                                 )
                                            })
                                            }
                                      </table>
                                      
                                : ""
                            }
                        </div>
              </div >
              }

              {skip < totaldata && diamondpageData.length ? 
              dataload ? 
                <div className=" w-100 d-flex justify-content-center align-items-center">
                  <Spinner />
                </div> 
                :
                <div className="text-center cursor-pointer loadMoreBtnMain" 
                onClick={() => getFilters(skip)}
                > 
                  <span>{t("Load more")}</span> <ArrowDownside />
                </div>
              : 
              ""}
        </div>
        <div className="ecommerce-main-layout-collection">
          <CollectionSlider />
        </div>
        </>
        :
        <DiamondProductPage productDetail={productDetail} diamondFilters={diamondFilters} getDiamondFilters={getDiamondFilters} setGetDiamondFilters={setGetDiamondFilters} setShowEngravingPage={setShowEngravingPage} setActiveTab={setActiveTab} flowMode={flowMode} diamondProduct={diamondProduct} setDiamondProduct={setDiamondProduct} setShowDiamondProduct={setShowDiamondProduct} engravingData={engravingData} setEngravingData={setEngravingData}/>
        }
    </div>
    
    </>
  )
}
