import React, { useEffect, useState,useRef} from "react";
import { useTranslation } from "react-i18next";
import './HomePage.scss'
import { _Api } from "../../common/api";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import SaleContent from "../include/Common/SaleContent";
import CollectionBlock from "../include/Common/CollectionBlock";
import CollectionBestSeller from "../include/Common/CollectionBestSeller";
import CollectionSlider from "../include/Common/CollectionSlider";
import CollectionNewArrival from "../include/Common/CollectionNewArrival";
import RightArrowIcon from "../assets/icons/RightArrowIcon";
import BottomBar from "../include/Common/BottomBar";
import { useNavigate } from "react-router-dom";
import LeftIcon from "admin/common/icons/sidebar/LeftIcon";

const HomePage = (props) => {
    const { t } = useTranslation();
    const [bannerList, setBannerList] = useState([]);
    const [loading, setloading] = useState(true);
    const [dataload, setDataload] = useState(false);
    const [totaldata, setTotaldata] = useState();
    const [productdata, setProductdata] = useState([]);
    const [activeBestSeller, setActiveBestSeller] = useState();
    const [skip, setSkip] = useState(0)
    const navigate = useNavigate();
    const sliderRef = useRef(null);
    const bestSellersData = [
       "Rings", "Errings", "Bracelets" , "Necklaces & Pandants", "Anklets"
    ]

    const productlist = async (skipdata) => {
        
        try {
    
    
          let postdata = {
            "wishlistproduct": "",
            "wishlistremove": "",
            "sort": "",
            "limit": 20,
            "skip": skipdata ? skipdata : 0,
            "type": "catalog",
            "size":  [],
            "stone":  [],
            "item": "",
            "metal": [],
            "category_slug": "new-arrival",
            "collection_slug":  "",
            "price": ""
          }
          if (postdata?.skip === 0) {
            setloading(true)
          } else {
            setDataload(true)
          }
          let res = await _Api(postdata, `product/list`)
          if (res?.statusCode === 200) {
            setTotaldata(res?.count)
            setSkip(20 + postdata?.skip)
            if (postdata?.skip === 0) {
              setProductdata(res?.data)
            } else {
              let finaldata = productdata.concat(res?.data)
              setProductdata(finaldata)
            }
            setloading(false)
            setDataload(false)
          }
    
        }
        catch (err) {
          console.log(err, "err")
        }
      }

    useEffect(()=>{
        getBanners();
        productlist();
    },[]);

    const getBanners = async() =>{
        try{
            let postdata = {}
            let res = await _Api(postdata, `Cms/get-ecom-bannerdata`);
            if(res.status === 200){
                setBannerList(res.data)
                console.log(res, "Here is response")
            }
        }
        catch(err){
            console.log(err, "Error")
        }
    }
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        fade: true,
      };
    return (
        <>
            <div className={'ecommerce-main-layout-bodyContent position-relative template_four'}>
                {/* product filter */}
                <div className="ecommerce-landingpage">
                    {/* <SubHeader/> */}
                    <div className="ecommerce-landingpage-top_banner">
                        <Slider ref={sliderRef} {...settings}>
                           {bannerList?.length ? bannerList.map((bannerItem, key)=>{
                            return(
                                <div className="banner_holder" key={key}>
                                    <img src={bannerItem.Imageurl} alt="banner"/>
                                <div className="banner_holder_content">
                                    <span className="top">{t("Live a stylish life")}</span>      
                                    <div className="title">
                                        {t("Look gorgeous and dreamy with the most intriguing jewelry ever.")}
                                    </div> 
                                    <div className="subtitle">
                                        {t("Mara. Ambistat proskade tempofiering, reamatisk megaosmos. Memil galna chips-sjukans till IVPA. Näringslots fronta varat i kameratelefon liksom giganing.")}
                                    </div>
                                    <span onClick={()=>navigate('/Collection')} className="further">{t("Shop collection")}</span>
                                </div>
                                <div className="banner_holder_controlers">
                                    <span onClick={() => sliderRef.current.slickPrev()}
                                    ><LeftIcon/></span>
                                    <span className="right" onClick={() => sliderRef.current.slickNext()}><LeftIcon/></span>
                                </div>
                                </div>
                            )
                           }) : 
                           <div className="banner_holder">
                                <img src="https://gis247.s3.amazonaws.com/2023in0076/Customer/banner2.png/1727945683102.png" alt="defaultbanner"/>
                              
                            </div>
                            }
                        </Slider>
                    </div>
                    <div className="ecommerce-landingpage-home_collectionslider">
                        <div className="ecommerce-landingpage-home_collectionslider-title">
                           {t("Timeless jewelry made with dedication and love.")}
                        </div>
                           <CollectionSlider/>
                    </div>
                    <div className="ecommerce-landingpage-newArrivals">
                        <div className="ecommerce-landingpage-newArrivals-heading">
                            <span className="title">{t("New Arrivals")}</span>
                            <span className="subtitle">{t("Timeless jewelry made with dedication and love.")}</span>
                        </div>
                        <div className="ecommerce-products">
                           <CollectionNewArrival/>
                        </div>
                    </div>
                    <div className="ecommerce-homepage-collections">
                           <CollectionBlock/>
                    </div>
                    <div className="ecommerce-landingpage-bestsellers">
                        <div className="ecommerce-landingpage-bestsellers-title">{t("Best Sellers")}</div>
                        <div className="ecommerce-landingpage-bestsellers-subtitle">{t("Each collection is exclusively made in small batches to create as little as possible.")}</div>
                        {/* { bestSellersData.length ?
                        <div className="ecommerce-landingpage-bestsellers-tab">
                        {  bestSellersData.map((item, key)=>{
                            return(
                                <>
                                <div className={`ecommerce-landingpage-bestsellers-tab-item ${activeBestSeller == item ? 'active' : ''}`} key={key} onClick={()=> setActiveBestSeller(item)}>
                                   <span className="text"> {t(`${item}`)}</span>
                                    { key !== bestSellersData.length-1 ? <span className="slash">/</span> : null}
                                </div>
                                </>
                            )
                           })
                           }
                        </div>
                        :null} */}
                        <div className="ecommerce-landingpage-bestsellers-cards">
                                   <CollectionBestSeller/>
                        </div>
                         <div className="ecommerce-landingpage-exploreallbtn">
                        <button className="themeBtn" onClick={()=>navigate("/Collection")}>{t("View all products")}</button>
                    </div>
                    </div>
                   
                    <BottomBar withTitle={false}/>
                    {/* <div className="ecommerce-landingpage-bottombar">
                        <div className="ecommerce-landingpage-bottombar-title">
                            {t("Our Services")}
                            </div>
                        <div className="ecommerce-landingpage-bottombar-inner">
                            
                            {bottomdata.length ? bottomdata.map((item, i)=>{
                                return(
                                    <div className={`ecommerce-landingpage-bottombar-inner-container ${i === 0 ? "" : "hasborder"}`}>
                                        <span className="ecommerce-landingpage-bottombar-inner-container-icon">{ item.icon ? item.icon : <SearchIcon/>}</span>
                                        <div className="ecommerce-landingpage-bottombar-inner-container-content">
                                            <span className="ecommerce-landingpage-bottombar-inner-container-content-title">{item.title}</span>
                                            <span className="ecommerce-landingpage-bottombar-inner-container-content-subtitle">{item.subtitle}</span>
                                        </div>
                                    </div>
                                )
                            }) : null}
                            
                        </div>
                    </div> */}
                </div>
               
            </div >
        </>
    );
}
export default HomePage;