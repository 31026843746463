export const getSelectedDataList = (selectedList, DataList, dataField = "") => {
  let data = [];
  // console.log(selectedList,'getSelectedDataList selectedList')
  // console.log(DataList,'getSelectedDataList DataList')
  if (selectedList.length > 0) {
    selectedList.map(function (v) {
      console.log(v)
      const filteredDataList = DataList.filter((l, idx) => l.id === v.id);
      if (filteredDataList.length > 0) {
        if (dataField !== "") {
          data.push(filteredDataList[0][dataField])
        } else {
          data.push(filteredDataList[0])

        }
      }
    })
  } else {
    return [];
  }

  return data;
}
function checkType(value) {
  if (Array.isArray(value)) {
    return "Array";
  } else if (typeof value === "object" && value !== null) {
    return "Object";
  } else {
    return "Neither Object nor Array";
  }
}
export function stoneMerge(config,stoneData){
  let stone_list ;
  if(checkType(stoneData)  === "Array"){
    stone_list = stoneData
  }else{
    stone_list = []
    stone_list.push(stoneData)
  }
  console.log(stone_list,'stone_list')
  let list_stone = {};
  
  stone_list.map((v,k,arr)=>{
  
  // const key = `${config.stone_name&&v.stone_details._id?v.stone_details._id:"no_sc"}_${config.stone_group&&v.stone_group_details._id?v.stone_group_details._id:"no_sg"}_${config.clarity&&v.stone_clarity_details._id?v.stone_clarity_details._id:"no_clar"}_${config.shape&&v.stone_shape_details._id?v.stone_shape_details._id:"no_sh"}`;
  const key = `${config.stone_name&&v.stone_details?v.stone_details._id:"no_sc"}_${config.stone_group&&v.stone_group_details?v.stone_group_details._id:"no_sg"}_${config.clarity&&v.stone_clarity_details?v.stone_clarity_details._id:"no_clar"}_${config.shape&&v.stone_shape_details._id?v.stone_shape_details._id:"no_sh"}_${config.cut&&v.stone_cut_details?v.stone_cut_details._id:"no_cut"}_${config.color&&v.stone_color_details?v.stone_color_details._id:""}_${config.stone_size&&v.stone_size_details?v.stone_size_details._id:""}`;
  
    if (!list_stone[key]) {
  
      list_stone[key] = {...v};
  } else {
    console.log(list_stone,'else ees')
    // console.log(merged[key],' merged[key]')
    // console.log(merged[key].Cts,' merged[key].Cts')
    // console.log(stone.Cts,' stone.Cts')
    list_stone[key].Cts = parseFloat(parseFloat(list_stone[key].Cts)+ parseFloat(v.Cts)).toFixed(2) ;
    list_stone[key].Pcs = parseInt(list_stone[key].Pcs) + parseInt(v.Pcs);
  
  }
   
  })
  const stone_merge_array = Object.values(list_stone).map(stone => ({
    ...stone,
   
  }));
return stone_merge_array;
  
}

export function getBarcodeByStockId(barcodeArray,stock_id){
  let barcode  = '';
  console.log(barcodeArray)
  
  barcodeArray.map((v,k)=>{
   const skustring = v.stock_id
   const search = skustring.search(stock_id);
   console.log(v,'vv')
   console.log(stock_id,'sku')
   console.log(v.barcodeImg,'v.barcodeImg')
   console.log(search,'search')
     if(search>=0){
 
       barcode =  v.barcodeImg;
         }else{
       console.log("else")
       
      
 
     }
   })
   return barcode;
 
 }

export function getQrcodeByStockId(QrArray=[],stock_id=""){
  let barcode  = '';
  QrArray.map((v,k)=>{
   const skustring = v.stock_id
   const search = skustring.search(stock_id);

     if(search>=0){
 
       barcode =  v.qrcodeImg;
         }else{
       console.log("else")
       
      
 
     }
   })
   return barcode;
 
 }
export function getQrcodeByProductId(QrArray=[],product_id=""){
  let barcode  = '';
  QrArray.map((v,k)=>{
   const skustring = v.product_id
   const search = skustring.search(product_id);

     if(search>=0){
 
       barcode =  v.qrcodeImg;
         }else{
       console.log("else")
       
      
 
     }
   })
   return barcode;
 
 }



 export function getBarcodeBySku(barcodeArray,sku){
 let barcode  = '';
 console.log(barcodeArray,"getBarcodeBySku")
 barcodeArray.map((v,k)=>{
  const skustring = v.sku
  const search = skustring.search(sku);

    if(search>=0){

      barcode =  v.barcodeImg;
        }else{
      console.log("else")
      
     

    }
  })
  return barcode;

}