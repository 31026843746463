import React, { useState, useEffect, useCallback } from "react";
import "./login-signup.scss";
import { Link, useNavigate } from 'react-router-dom';
import { _Apiauth } from "../../api/_call";
import { useTranslation } from 'react-i18next';
const Loginpage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [showPassword, setShowPassword] = useState(true);
  const LOGINWITHPIN = localStorage.getItem("LOGINWITHPIN");
  useEffect(() => {
    let otptimer;
    if (otptimer > 0) {
      setInterval(() => {

      }, 1000);
    }
  }, []);
  const [lodindata, setLogindata] = useState(
    {
      "email": "",
      "password": "",
      "storeName": ""
    }
  )
  const [loginvalid, setLoginvalid] = useState({})
  const logingis = useCallback(async () => {
    setLoginvalid(prevState => ({
      ...prevState,
      checkerror: {
        lodar: true
      }
    }));
    const handleValidation = (e) => {
      let formIsValid = true;
      if (!lodindata?.storeName) {
        formIsValid = false;
        setLoginvalid(prevState => ({
          ...prevState,
          storeName: {
            message: "Pleace enter store name",
            status: true
          }
        }));
        setLoginvalid(prevState => ({
          ...prevState,
          checkerror: {
            lodar: false
          }
        }));
      } else {
        formIsValid = true;
        setLoginvalid(prevState => ({
          ...prevState,
          storeName: {
            message: "",
            status: false
          }
        }));
      }
      if (!lodindata?.email) {
        formIsValid = false;
        setLoginvalid(prevState => ({
          ...prevState,
          email: {
            message: "Pleace enter email",
            status: true
          }
        }));
        setLoginvalid(prevState => ({
          ...prevState,
          checkerror: {
            lodar: false
          }
        }));
      } else {
        formIsValid = true;
        setLoginvalid(prevState => ({
          ...prevState,
          email: {
            message: "",
            status: false
          }
        }));
      }
      if (!lodindata?.password) {
        formIsValid = false;
        setLoginvalid(prevState => ({
          ...prevState,
          password: {
            message: "Pleace enter password",
            status: true
          }
        }));
        setLoginvalid(prevState => ({
          ...prevState,
          checkerror: {
            lodar: false
          }
        }));
      } else {
        formIsValid = true;
        setLoginvalid(prevState => ({
          ...prevState,
          password: {
            message: "",
            status: false
          }
        }));
      }
      return formIsValid;
    }
    if (handleValidation()) {
      localStorage.removeItem('UserToken');
      localStorage.removeItem('updateModulePermission');
      localStorage.removeItem('updateUserPermission');
      try {
        let postdata = lodindata
        let res = await _Apiauth(postdata, `Auth/Web/store-login`)
        if (res?.code === 200) {
          if (res?.data?.twofactorauth) {
            navigate('/appauth/verification', {
              state: {
                access_tokens: res?.data?.access_tokens,
                maskEmail: res?.data?.maskEmail
              },
            })
          } else {
            setLoginvalid(prevState => ({
              ...prevState,
              checkerror: {
                lodar: false
              }
            }));
            if (res?.data?.storeUrl) {
              // let rediractDomain =  res?.data?.storeUrl;
              // // let rediractDomain =  "http://localhost:3000/";
              // window.location.href = rediractDomain + 'authentication/' + res?.data?.access_tokens;
              // if (res?.data?.loginPinToken) {
              //   localStorage.setItem('LOGINWITHPIN', (res?.data?.loginPinToken));
              // } else {
              //   localStorage.setItem('LOGINWITHPIN', "");
              // }

              const rediractDomain = res?.data?.storeUrl;
              const tokenPath = `/authentication/${res?.data?.access_tokens}`;

              if (res?.data?.loginPinToken) {
                localStorage.setItem('LOGINWITHPIN', res?.data?.loginPinToken);
              } else {
                localStorage.setItem('LOGINWITHPIN', "");
              }

              if (window.location.hostname !== new URL(rediractDomain).hostname) {
                // Redirect to subdomain or different domain
                const tokenPath_r = `authentication/${res?.data?.access_tokens}`;

                window.location.assign(rediractDomain + tokenPath_r);
              } else {
                // Same domain: use React navigate
                navigate(tokenPath, { state: { domain: rediractDomain } });
              }


            } else {
              navigate('/login');

            }
          }
        } else {

          setLoginvalid(prevState => ({
            ...prevState,
            error: {
              message: res?.message,
              status: true
            }
          }));
          setLoginvalid(prevState => ({
            ...prevState,
            checkerror: {
              lodar: false
            }
          }));
        }
      }
      catch (err) {
        console.log(err, "err")
      }
    }
  }, [lodindata, navigate])
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        logingis();
      }
    };
    document.addEventListener('keypress', handleKeyPress);
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [logingis]);
  useEffect(() => {
    let bodyid = document.querySelector('#body_id');
    if (localStorage.getItem("UserLang") === 'SA.png') {
      bodyid.classList.add('rtl');
    } else {
      bodyid.classList.remove('rtl');
    }
  }, [])
  return (
    <div className="main-body bg-white p-0">
      <div className="login-page position-relative d-flex align-items-center justify-content-center">
        <img className="login-page-bg position-absolute end-0 bottom-0 w-75" alt="login-bg.png" src={base_assets + "images/login-bg.png"} />
        <div className="login-page-row d-flex justify-content-center align-items-center w-100">
          <div className="text-center login-page-left-col">
            <Link to="/">
              <img className="login-page-left-col-img" alt="gis-xl-logo.png" src={base_assets + "images/gis-xl-logo.png"} />
            </Link>
            <span className="d-block mt-4 fs-4 fw-semibold login-page-left-col-text">{t("Start your journey with us ")}
              <span className="main-green-text">{t(" today")}</span>
            </span>
          </div>
          <div className="login-page-right-col">
            <h1 className="login-page-right-col-heading fs-2 fw-semibold">{t("Welcome!")}</h1>
            <h2 className="login-page-right-col-sub-heading fs-4 fw-semibold">{t("Login to Account")}</h2>
            <div className="login-page-right-col-form">
              <div className="col-md-12">
                <div className={`placeholder-up-input w-100 form-floating ${loginvalid?.storeName?.status ? "md-1" : "mb-4"}`}>
                  <input type="text" id="StoreName"
                    className={`placeholder-up-input-field form-control fs-6 px-3 ${loginvalid?.storeName?.status ? "border-danger" : ""}`}
                    placeholder="Store Name"
                    onChange={(e) => setLogindata(prevState => ({
                      ...prevState,
                      storeName: e.target.value
                    }))} />
                  <label className="placeholder-up-input-label fs-6" htmlFor="">
                    {t("Store Name")}
                  </label>
                  <span className="placeholder-up-input-inner-position main-green-text position-absolute">.gis247.net</span>
                </div>
                {loginvalid?.storeName?.status ? <span className="d-block mb-4 text-danger">{loginvalid?.storeName?.message ?
                  t(loginvalid?.storeName?.message) : ""}</span> : ""}
              </div>
              <div className="col-md-12">
                <div className={`placeholder-up-input w-100 form-floating ${loginvalid?.email?.status ? "md-1" : "mb-4"}`}>
                  <input type="email" id="StoreEmail" className={`placeholder-up-input-field form-control fs-6 px-3 ${loginvalid?.email?.status ? "border-danger" : ""}`} placeholder="Email"
                    onChange={(e) => setLogindata(prevState => ({
                      ...prevState,
                      email: e.target.value
                    }))} />
                  <label className="placeholder-up-input-label fs-6" htmlFor="">
                    {t("Email")}
                  </label>
                </div>
                {loginvalid?.email?.status ? <span className="d-block mb-4 text-danger">{loginvalid?.email?.message ?
                  t(loginvalid?.email?.message) : ""}</span> : ""}
              </div>
              <div className="col-md-12">
                <div className={`placeholder-up-input w-100 form-floating ${loginvalid?.password?.status ? "md-1" : "mb-3"}`}>
                  <input id="StorePassword" type={showPassword ? "password" : "text"} className={`placeholder-up-input-field form-control fs-6 px-3 ${loginvalid?.password?.status ? "border-danger" : ""}`} placeholder="Password"
                    onChange={(e) => setLogindata(prevState => ({
                      ...prevState,
                      password: e.target.value
                    }))} />
                  <label className="placeholder-up-input-label fs-6" htmlFor="">
                    {t("Password")}
                  </label>
                  <div className="eye position-absolute">
                    <i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"} onClick={(e) => setShowPassword(current => !current)} aria-hidden="true"></i>
                  </div>
                </div>
                {loginvalid?.password?.status ? <span className="d-block mb-4 text-danger">{loginvalid?.password?.message ?
                  t(loginvalid?.password?.message) : ""}</span> : ""}
              </div>
              <div className="login-page-right-col-form-forgot-pass d-flex justify-content-between">
                {LOGINWITHPIN ? <p className="text-start">
                  <Link to="/loginwithpin" className="text-decoration-none fw-semibold">
                    {t("Login With PIN")}
                  </Link>
                </p> : ""}
                <p className="text-end">
                  <Link to="/appauth/forgotpassword" className="text-decoration-none fw-semibold">{t("Forgot Password")}?</Link>
                </p>
              </div>
              <p>
                {loginvalid?.error?.status ? <span className="d-block mb-4 text-danger">{loginvalid?.error?.message ?
                  t(loginvalid?.error?.message) : ""}</span> : ""}
              </p>
              <button className="login-page-right-col-form-login-btn main-green-bg text-white text-center w-100 border-0 rounded-1 fs-6 fw-semibold d-flex align-items-center justify-content-center">
                {loginvalid?.checkerror?.lodar ?
                  <div className="border-bottom-0 border-width-2px d-block text-white mx-auto spinner-border flex-shrink-0" role="status"></div> :
                  <span className="h-100 w-100 justify-content-center align-items-center d-flex" onClick={() => {
                    logingis()
                  }}> {t("Login")}</span>}
              </button>

              <p className="login-page-right-col-form-foot text-center mb-0">{t("Require an account")}? <br />
                <Link to={process.env.REACT_APP_BASE_URL + "appauth/signup"} className="main-green-text fw-semibold text-decoration-none">{t("Sign Up")} </Link>
                {t("to fill your information")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loginpage;