import { useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { setUserToken, isAuthenticated, isCurrentDomain } from "./AuthMiddleware";
import { useSelector } from 'react-redux';
import {
  updateGeneralSettings,
  OrganisationSettings,
  updateUserPermission,
  updateModulePermission

} from '../source/app/appSlice';
import { useDispatch } from 'react-redux';
import { _Api, graphqlPost } from "./api/_call";
const AccessControl = (props) => {
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const moduleparmissiondatashow = useSelector((state) => state.app.ModulePermission);
  const setparmission = useCallback(async () => {
    try {
      const postdata = {};
      let res = await _Api(postdata, `api/v1/Inventory/permissions/getModulePermissions`);
      if (res.code === 200) {
        dispatch(updateModulePermission(res.data));
        let data = JSON.stringify(res.data);
        localStorage.setItem('updateModulePermission', data);
        if (res?.data?.Dashboard) {
          navigate("/app/admin/dashboard")
        } else {
          navigate("/app/admin/profile")
        }
      }
    } catch (err) {
      console.log(err, "err");
    }
    // eslint-disable-next-line
  }, [dispatch])

  const getcheckpermission = useCallback(async () => {
    try {
      const postdata = {};
      let res = await _Api(postdata, `api/v1/Inventory/common/checkPermission`);
      if (res.code === 200) {
        setparmission();
        dispatch(updateUserPermission(res.data));
        let data = JSON.stringify(res.data);
        localStorage.setItem('updateUserPermission', data);
      }
    } catch (err) {
      console.log(err, "err");
    }

  }, [dispatch, setparmission])
  const getgeneralsetting = useCallback(async () => {
    try {
      const postdata = `{
        generalsetup {
        id 
        productChoice
        allowWarehouse
        POS_change_price
        Template_with_Image
        logistic_status
        purchaseOrder_delivery
        Users_to_enter_PIN_for_every_sale
        servicelabers
        isPark
        isQuotation
        POS_partial_payment
              
    }
}`;
      let res = await graphqlPost(postdata, "App/graphql");
      dispatch(updateGeneralSettings(res));
    } catch (err) {
      console.log(err, "err");
    }
  }, [dispatch])
  const FormatPrice = useCallback(async () => {
    try {
      const postdata = {};
      let countdata = await _Api(postdata, `api/v1/Inventory/common/getOrganisationSettings`);
      if (countdata.code === 200) {
        dispatch(OrganisationSettings(countdata?.data));
      }
    } catch (err) {
      console.log(err, "err");
    }
  }, [dispatch])
  const getUserData = async () => {
    if (token) {
      setUserToken(token);
      getcheckpermission();
      FormatPrice();
      getgeneralsetting();
    } else {
      if (await isAuthenticated()) {
        moduleparmissiondatashow?.Dashboard ? navigate("/app/admin/dashboard") : navigate("/app/admin/profile")

      } else if (await isCurrentDomain('gis247.net')) {
        window.location.href = "/landing/";

      } else if (await isCurrentDomain('www.gis247.net')) {
        window.location.href = "/landing/";

      } else {
        navigate("/logout");
      }

    }
  };

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line
  }, []);


  return (
    <div className="main-body">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div id="table_loader" >
              <div className="dots Deepak">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );


};
export default AccessControl;
