import './appointment.scss'
import React, { useEffect, useState } from 'react'
import { _Api, _Apiauth } from 'Ecommerce/common/api'
import PlusIcon from 'Ecommerce/template_three/assets/icons/PlusIcon'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Spinner from 'Ecommerce/common/Spinner'
import Select from "react-select";
import DatePicker from 'react-datepicker'
import Calendar from 'admin/common/icons/sidebar/Calendar'
import BottomBar from 'Ecommerce/template_three/include/Common/BottomBar';
import LeftArrow from 'Ecommerce/common/icons/LeftArrow';
import AppointmentCheckIcon from 'Ecommerce/common/icons/AppointmentCheckIcon';
import EmpltyList from 'Ecommerce/common/images/outOfStock.png'
import AppointmentMap from 'Ecommerce/template_two/default/assets/images/appointment_map.png'
import CalendarIcon from 'admin/common/icons/sidebar/CalendarIcon';
import CircleClockIcon from 'Ecommerce/common/icons/CircleClockIcon';
import CellPhoneGreen from 'admin/common/icons/sidebar/CellPhoneGreen';
import Messegeboxblue from 'admin/common/icons/sidebar/Messegeboxblue';
import Location from 'admin/common/icons/sidebar/Location';
import useScreenSize from 'Ecommerce/template_three/include/Common/useScreenSize';
import CrossIcon from 'Ecommerce/template_three/assets/icons/CrossIcon';
import ConfirmationNewIcon from 'Ecommerce/common/icons/ConfirmationNewIcon';
import PrintIcon from 'Ecommerce/template_three/assets/icons/PrintIcon';
import DownloadIcon from 'Ecommerce/template_three/assets/icons/DownloadIcon';
import ShareIcon from 'Ecommerce/template_three/assets/icons/ShareIcon';
import MinusIcon from 'Ecommerce/template_three/assets/icons/MinusIcon';
import Barcode from 'Ecommerce/common/images/Barcode.png';
import html2canvas from 'html2canvas';
import CircledTick from 'admin/common/icons/sidebar/CircledTick';
import GreenTick from 'admin/common/icons/GreenTick';
import CircledTickGray from 'admin/common/icons/sidebar/CircledTickGray';

export default function AppointmentPage() {

    const { state } = useLocation();
    const screenSize = useScreenSize();
    const [loadeing, setloading] = useState(true);
    const { t } = useTranslation();
    const [cartitems, setCartitems] = useState([]);
    let localitem = localStorage.getItem('CartItems') ? JSON.parse(localStorage.getItem('CartItems')) : [];
    const [appointmentdtaa, setAppointmentdtaa] = useState([])
    const [locationdata, setLocationdata] = useState([])
    const [allfromdata, setAllfromdata] = useState({"appointment_date" : new Date()})
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isOpenOrder, setIsOpenOrder] = useState(false);
    const [enableButton, setEnableButton] = useState(false);
    const [selectdata, setSelectdata] = useState();
    const [validation, setValidation] = useState();
    const [showInformationPopup ,setShowInformationPopup] = useState(false)
    const [activeStep, setActiveStep] = useState(1);
    const [locationAlldata, setLocationAlldata] = useState();
    const [confirmationData, setConfirmationData] = useState();
    const navigate = useNavigate();
    let cartdata = JSON.parse(localStorage.getItem('CartItems'))
    const getappointmentdata = async () => {
        console.log(state, "Check the state")
        setloading(true)
        try {
            let cartids = [];
            let diamondids = [];
            let hasMixandmatch = false
            if (state?.checkoutItems?.length){ 
                state?.checkoutItems?.map((result) => {
                    if(result?.temp_cart_id){
                hasMixandmatch = true
            }})
            if(hasMixandmatch){
                state?.checkoutItems?.map((result)=>{
                    let newItem = {...result}
                    delete newItem.price
                    diamondids.push(newItem);
                }) 
            }else{
                state?.checkoutItems?.map((result)=>{
                    cartids.push(result?.id)
                })
            }
            }else{
                cartids.push(state?.checkoutItems)
            }
           
            let postdata = diamondids?.length ? {diamond_ids: diamondids} : {cart_ids: cartids}
            let res = await _Api(postdata, `order/getCartDetails`)
            if (res?.statusCode === 200) {
                setloading(false)
                let array = [...res?.data];
                array?.forEach((result) => {
                    result.qty = parseInt(state?.checkoutItems.filter(e => e?.id === result?.id)[0]['qty']);
                })
                setAppointmentdtaa(array);
                setCartitems(array)
               
                console.log(array, "find new data")
                setAllfromdata((privcestate) => ({
                    ...privcestate,
                    product: state?.checkoutItems
                }))
                if (res?.deleted_ids?.length) {
                    if(state?.checkoutItems.length){
                    state?.checkoutItems.forEach((result) => {
                        if (!res?.deleted_ids.includes(result?.id)) {
                            let data = []
                            data.push(result)
                            localStorage.setItem('CartItems', JSON.stringify(data));
                        }
                    })
                }else{
                    localStorage.setItem('CartItems', JSON.stringify([]))
                }
                }
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const getlocationdata = async () => {  
        try {
            let postdata = {}
            let res = await _Api(postdata, `redis/shipingMathod`)
            if (res?.statusCode === 200) {
                if(res?.StorePickup?.length){
                    let NewLocation = [];
                    res?.StorePickup.map((item)=>{
                        NewLocation.push({label: item.name, value: item._id})
                    })
                    console.log(NewLocation, "Check new location");
                    setLocationdata(NewLocation)
                    setLocationAlldata(res?.StorePickup);
                }
                
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const toggleDiv = () => {
        setIsOpenOrder(!isOpenOrder);
    };
    const getcountry = async () => {
        try {
            let postdata = {}
            let res = await _Apiauth(postdata, `redis/countryList`) 
            if (res?.statusCode === 200) {
                let phonecode = []
                res?.data.forEach((result) => {
                    phonecode.push({
                        label: result?.phoneCode,
                        value: result?.phoneCode
                    })
                })
                setSelectdata(phonecode)
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const timeArray =[
        {label: "00:00 AM", value: "00:00 AM"}, {label: "01:00 AM", value: "01:00 AM"}, {label :"02:00 AM", value: "02:00 AM"},{label :"03:00 AM", value: "03:00 AM"},
        {label :"04:00 AM", value: "04:00 AM"}, {label :"05:00 AM", value: "05:00 AM"}, {label :"06:00 AM", value: "06:00 AM"}, {label :"07:00 AM", value: "07:00 AM"},
        {label :"08:00 AM", value: "08:00 AM"}, {label :"09:00 AM", value: "09:00 AM"}, {label :"10:00 AM", value: "10:00 AM"}, {label :"11:00 AM", value: "11:00 AM"},
        {label :"12:00 AM", value: "12:00 AM"},
        
        {label: "00:00 PM", value: "00:00 PM"}, {label: "01:00 PM", value: "01:00 PM"}, {label :"02:00 PM", value: "02:00 PM"},{label :"03:00 PM", value: "03:00 PM"},
        {label :"04:00 PM", value: "04:00 PM"}, {label :"05:00 PM", value: "05:00 PM"}, {label :"06:00 PM", value: "06:00 PM"}, {label :"07:00 PM", value: "07:00 PM"},
        {label :"08:00 PM", value: "08:00 PM"}, {label :"09:00 PM", value: "09:00 PM"}, {label :"10:00 PM", value: "10:00 PM"}, {label :"11:00 PM", value: "11:00 PM"},
        {label :"12:00 PM", value: "12:00 PM"}
    ]
    const setalldata = (keyname, value) => {
        setAllfromdata((state) => ({
            ...state,
            [keyname]: value
        }))
        setValidation((state) => ({
            ...state,
            [keyname] : ""
        }))
    }
    useEffect(() => {
        getappointmentdata()
        getlocationdata()
        getcountry()
        // eslint-disable-next-line
    }, []);

    useEffect(()=>{
        if(appointmentdtaa.length){
           setEnableButton(true)
        }else{
            if(allfromdata?.interests?.length > 0){
                    setEnableButton(true)
                }
        }
    },[allfromdata])


    const interestArray = [
        "Earrings", "Necklaces & Pendants", "Rings", "Anklets", "Other Inquiries"
    ]

    const location = [
        "centeral lefparao", "Central world"
    ]

    const handleChange = (e, type) => {
        console.log(e, type, "on change")
    }

    const handleAppointmentConfirm = async() =>{
        console.log( allfromdata, validation,"check form data")
        const validfrom = () => {
            let fromisvalid = true
            if (!allfromdata?.appointment_date) {
                setValidation((state) => ({
                    ...state,
                    appointment_date: "Date is required"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    appointment_date: ""
                }))
            }
            if (!allfromdata?.name) {
                setValidation((state) => ({
                    ...state,
                    name: "Please enter your full name."
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    name: ""
                }))
            }
            if (!allfromdata?.email) {
                setValidation((state) => ({
                    ...state,
                    email: "Please enter your email"
                }))
                fromisvalid = false
            } else {
                const isEmail = (email) =>
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
                if (!isEmail(allfromdata?.email)) {
                    setValidation(prevState => ({
                        ...prevState,
                        email: "Please enter valid email"
                    }));
                    fromisvalid = false
                } else {
                    setValidation((state) => ({
                        ...state,
                        email: ""
                    }))
                }
            }
            if(!allfromdata?.time){
                setValidation((state) => ({
                    ...state,
                    time: "Please fill in all mandatory fields"
                }))
                fromisvalid = false
            }else {
                setValidation((state) => ({
                    ...state,
                    time: ""
                }))
            }
            if(!allfromdata?.store){
                setValidation((state) => ({
                    ...state,
                    store: "Please Select a store"
                }))
                fromisvalid = false
            }else {
                setValidation((state) => ({
                    ...state,
                    store: ""
                }))
            }
           
        
            
            if (!allfromdata?.mobile || !allfromdata?.phonecode) {
                setValidation((state) => ({
                    ...state,
                    mobile: "Mobile number is required"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    mobile: ""
                }))
            }
            
            return fromisvalid;
        }
        if (validfrom()) {
            showPopup();
           
        }
    }

    const downloadPageAsPNG = () => {
        const rootElement = document.getElementById('appointment_page');
        html2canvas(rootElement).then((canvas) => {
            const imageURL = canvas.toDataURL(`image/png`);
            const anchor = document.createElement('a');
            anchor.download = `${state?.appointment_id}.png`;
            anchor.href = imageURL;
            anchor.click();
            showPopup();
        });
       
    }

    const finaliseAppointment = async() =>{
         setloading(true)
            try {
                let obj = { ...allfromdata }
                obj.name = allfromdata.firstname + " " + allfromdata.lastname
                delete obj?.firstname
                delete obj?.lastname
                obj.mobile = "+" + allfromdata?.phonecode + "-" + allfromdata?.mobile
                if(obj.product.length){
                obj?.product?.forEach((result) => {
                    delete result?.price
                })
                }else{
                    delete obj?.product?.price
                }
                
                delete obj?.phonecode
                delete obj?.store
                delete obj?.policy
                
                if(!appointmentdtaa.length){
                    obj = {
                        ...obj, product : []
                    }
                }
                obj.appointment_date = new Date(allfromdata?.appointment_date.setHours(12, 10, 10, 999))
                obj = {
                    ...obj, location : locationAlldata?.filter((item)=> item?._id === allfromdata?.store?.value)[0], time : obj?.time?.value, message : allfromdata?.message ? allfromdata?.message : ""
                }
                delete obj?.location?.location_logo
                delete obj?.location?.Location_image
                let postdata = obj
                console.log(postdata, "checkdata")
                let res = await _Api(postdata, `Appointment/create-Appointment`)
                setloading(false);
                
                if (res?.statusCode
                    === 200) {
                    // if (modalRef.current) {
                    //     modalRef.current.hide();
                    // }
                   setConfirmationData(res?.data)
                   setloading(false)
                    let setupdatearray = [...postdata?.product]
                    const newArray1 = cartdata.filter((obj1) => !setupdatearray.some((obj2) => obj2.id === obj1.id));
                    console.log(postdata?.product, setupdatearray, newArray1,  localStorage,"Check out new items")
                    localStorage.setItem('CartItems', JSON.stringify(newArray1));
                    setActiveStep(4);
                } else {
                    setloading(false)
                    setValidation(prevState => ({
                        ...prevState,
                        "error": res?.message
                    }));
                }
            }
            catch (err) {
                console.log(err, "err")

                if (err.response.status) {
                    setValidation(prevState => ({
                        ...prevState,
                        "error": err.response.data.message
                    }));
                }
            }
    }

    const showPopup = () => {
        setIsPopupVisible(true);
      };

      const handleNextStep = () => {
        let fromisvalid = true;
        if(activeStep === 1){
            if(allfromdata.store){
                setActiveStep(2)
            }else{
                if(!allfromdata?.store){
                    setValidation((state) => ({
                        ...state,
                        store: "Please Select a store"
                    }))
                    fromisvalid = false
                }else {
                    setValidation((state) => ({
                        ...state,
                        store: ""
                    }))
                }
            }
        }
        if(activeStep === 2 ){
            if(allfromdata.appointment_date && allfromdata.time){
                setActiveStep(3)
            }else{
                if (!allfromdata?.appointment_date) {
                    setValidation((state) => ({
                        ...state,
                        appointment_date: "Date is required"
                    }))
                    fromisvalid = false
                } else {
                    setValidation((state) => ({
                        ...state,
                        appointment_date: ""
                    }))
                }
                if(!allfromdata?.time){
                    setValidation((state) => ({
                        ...state,
                        time: "Please fill in all mandatory fields"
                    }))
                    fromisvalid = false
                }else {
                    setValidation((state) => ({
                        ...state,
                        time: ""
                    }))
                }
            }
        }
        if(activeStep === 3 ){
            const isEmail = (email) =>
                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
            if(allfromdata.firstname && allfromdata.lastname &&  allfromdata.phonecode && allfromdata.mobile ){
                finaliseAppointment();
            }else{
                if (!allfromdata?.firstname) {
                    setValidation((state) => ({
                        ...state,
                        firstname: "Please enter your full name."
                    }))
                    fromisvalid = false
                } else {
                    setValidation((state) => ({
                        ...state,
                        firstname: ""
                    }))
                }
                if (!allfromdata?.lastname) {
                    setValidation((state) => ({
                        ...state,
                        lastname: "Please enter your full name."
                    }))
                    fromisvalid = false
                } else {
                    setValidation((state) => ({
                        ...state,
                        lastname: ""
                    }))
                }
                if (!allfromdata?.mobile || !allfromdata?.phonecode) {
                    setValidation((state) => ({
                        ...state,
                        mobile: "Mobile number is required"
                    }))
                    fromisvalid = false
                } else {
                    setValidation((state) => ({
                        ...state,
                        mobile: ""
                    }))
                }
                if (!allfromdata?.email) {
                    setValidation((state) => ({
                        ...state,
                        email: "Please enter your email"
                    }))
                    fromisvalid = false
                } else {
                    const isEmail = (email) =>
                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
                    if (!isEmail(allfromdata?.email)) {
                        setValidation(prevState => ({
                            ...prevState,
                            email: "Please enter valid email"
                        }));
                        fromisvalid = false
                    } else {
                        setValidation((state) => ({
                            ...state,
                            email: ""
                        }))
                    }
                }
            }
        }
        if(activeStep === 4){
            setActiveStep(1)
           
            setAllfromdata({})
        }
      }

      const handleSteps = (stepno) => {
        console.log(allfromdata, "Check all form data");
        let fromisvalid = true
        if(stepno === 1 ){
            if(allfromdata.store){
                setActiveStep(1)
            }else{
                if(!allfromdata?.store){
                    setValidation((state) => ({
                        ...state,
                        store: "Please Select a store"
                    }))
                    fromisvalid = false
                }else {
                    setValidation((state) => ({
                        ...state,
                        store: ""
                    }))
                }
            }
        }
        if(stepno === 2 && allfromdata.appointment_date && allfromdata.time){
            setActiveStep(2)
        }
        if(stepno === 3 && allfromdata.firstname && allfromdata.lastname && allfromdata.email && allfromdata.phoneCode && allfromdata.mobile ){
            setActiveStep(3)
        }
        if(stepno === 4 && allfromdata.store && allfromdata.appointment_date && allfromdata.time && allfromdata.firstname && allfromdata.lastname && allfromdata.email && allfromdata.phonecode && allfromdata.mobile){
            setActiveStep(4)
        }
      }

      const changeqty = (label, id) => {
        let array = [...cartitems];
        let updatedLocalItems = [...localitem];
    
        array = array.map(e => {
          if (e.id === id) {
            if (label === 'minus' && e.qty > 1) {
              e.qty -= 1;
            } else if (label !== 'minus') {
              e.qty += 1;
            }
          }
          return e;
        });
    
        updatedLocalItems = updatedLocalItems.map(e => {
          if (e.id === id) {
            if (label === 'minus' && e.qty > 1) {
              e.qty -= 1;
            } else if (label !== 'minus') {
              e.qty += 1;
            }
          }
          return e;
        });
    
        localStorage.setItem('CartItems', JSON.stringify(updatedLocalItems));
        // setCartitems(array);
      };

      const handlePrint = () => {
        window.print();
        
    }; 

  return (
    <div className='appointment_page ecommerce-main-layout-bodyContent' id='appointment_page'>
    <div className='appointment_page-header cursor-pointer'>
       <LeftArrow/><span>{t("Back to Home")}</span>
    </div>
        <div className='appointment_page-container'>
            <div className='appointment_page-container-headerBox'>
                <div className={`appointment_page-container-headerBox-headerName`}><span className='appointment_page-container-headerBox-headerName-iconholder'><AppointmentCheckIcon/></span> {t("Appointment")}</div>
                <div className={`appointment_page-container-headerBox-subhead`}>{t("To Book an Appointment, please select a store")}</div>
            </div>
            {loadeing ?
                (<div className=" w-100 d-flex justify-content-center align-items-center  ">
                    <Spinner themed={true}/>
                </div>) :

                //defaultpage
                // <div className='appointment_page-container-initialPage'>
                //     <div className='appointment_page-container-initialPage-inner'>
                //         <img src={AppointmentImage} alt='appointmentimage'/>
                //         <span className='appointment_page-container-initialPage-inner-title'>{t("Add Your Appointment")}</span>
                //         <div className='appointment_page-container-initialPage-inner-subtitle'>
                //             <span>{t("Add items to your appointment as you browse")} </span>
                //             <span>{t("on product details and they will appear here.")}</span>
                //         </div>
                //         <div className='btnbold'>
                //             <div><LeftArrow/> <span>{t("Browse Our Main Collection")}</span></div>
                //         </div>
                //     </div>
                // </div>

                // appointmentmainpage

                <div className='appointment_page-container-content'>
                    <div className='appointment_page-container-content-steps'>
                        <div className={`appointment_page-container-content-steps-item ${activeStep > 0 ? 'active' : ""}`} onClick={()=>handleSteps(1)}><span className='appointment_page-container-content-steps-item-number'>1</span><span className='appointment_page-container-content-steps-item-tag'>{t("Store")}</span></div>
                        <div className={`appointment_page-container-content-steps-line ${activeStep > 1 ? 'active' : ""}`}></div>
                        <div className={`appointment_page-container-content-steps-item ${activeStep > 1 ? 'active' : ""}`} onClick={()=>handleSteps(2)}><span className='appointment_page-container-content-steps-item-number'>2</span><span className='appointment_page-container-content-steps-item-tag'>{t("Date/Time")}</span></div>
                        <div className={`appointment_page-container-content-steps-line ${activeStep > 2 ? 'active' : ""}`}></div>
                        <div className={`appointment_page-container-content-steps-item ${activeStep > 2 ? 'active' : ""}`} onClick={()=>handleSteps(3)}><span className='appointment_page-container-content-steps-item-number'>3</span><span className='appointment_page-container-content-steps-item-tag'>{t("Information")}</span></div>
                        <div className={`appointment_page-container-content-steps-line ${activeStep > 3 ? 'active' : ""}`}></div>
                        <div className={`appointment_page-container-content-steps-item ${activeStep > 3 ? 'active' : ""}`} onClick={()=>handleSteps(4)}><span className='appointment_page-container-content-steps-item-number'>4</span><span className='appointment_page-container-content-steps-item-tag'>{t("Confirmation")}</span></div>
                    </div>
                   
                    <div className='appointment_page-container-content-wrapper'>
                        {activeStep === 1 ?
                    <div className='appointment_page-container-content-wrapper-inner'>
                    <div className='appointment_page-container-content-wrapper-inner-title'>
                        <span>{t("Choose a Store")}</span>
                    </div>
                    <div className='appointment_page-container-content-wrapper-inner-details'>
                        <div className="appointment_page-container-content-wrapper-inner-details-singledetail m-auto">
                            <div className={`appointment_page-container-content-wrapper-inner-details-singledetail-name ${validation?.store ? "error" : ""}`}>
                                <span className='headings-name'>{t("Store")}</span>
                            </div>
                            <div className="appointment_page-container-content-wrapper-inner-details-singledetail-option">
                                <Select
                                    options={locationdata}
                                    className={`${validation?.store ? "border-danger" : ""}`}
                                    height='46px'
                                    width='100%'
                                    value={allfromdata?.store || null}
                                    placeholder={"Centeral World"}
                                    onChange={(e)=>setalldata("store", e)}
                                    isSearchable={false}
                                    classNamePrefix='newDropdown'
                                />
                            </div>
                                {validation?.store ? 
                                    <div className='appointment_page-container-content-wrapper-inner-holder-left-top-details-singledetail-error text-danger'>
                                       {t(`${validation?.store}`)}
                                    </div>
                                : 
                                
                                    null
                            
                            }
                        </div> 
                    </div>
                                <div className='appointment_page-container-content-wrapper-inner-holder'>
                            
                                    <div className='appointment_page-container-content-wrapper-inner-holder-left'>
                                        <div className='appointment_page-container-content-wrapper-inner-holder-left-top'>
                                            <span className=''>{t("List")}</span>
                                        </div>
                                        { appointmentdtaa?.length > 0 ? 
                                        <div className='appointment_page-container-content-wrapper-inner-holder-left-list'>
                                            <div className="appointment_page-container-content-wrapper-inner-holder-left-list-inner">
                                            {appointmentdtaa?.map((result, key) => {
                                            return (
                                                <div key={key} className={`ecom_cartproduct ${result?.diamond ? 'mixandmatch' : ""}`}>
                                                <div className="ecom_cartproduct-imageBox">
                                                  <span className="product-img-span">
                                                    <img src={result?.main_image} alt="" />
                                                  </span>
                                                </div>
                                                <div className=" ecom_cartproduct-detailsBox">
                                                  <div className="ecom_cartproduct-detailsBox-firstBox">
                                                    <p className="ecom_cartproduct-detailsBox-firstBox-productName">
                                                      <Link to={`/product/${result?.motherProduct?.Slug}?v=${result?.id}`}
                                                        state={{ id: result?.motherProduct?._id }}
                                                        className="ecom_cartproduct-detailsBox-firstBox-productName text-decoration-none">{result?.name}</Link>
                                                      <span className='ecom_cartproduct-detailsBox-firstBox-productName tab-productPriceBlock'>{result?.price}</span>
                                                    </p>
                                                    <div className="ecom_cartproduct-detailsBox-firstBox-instock">{t("In Stock")}</div>
                                                                                  <div className="ecom_cartproduct-detailsBox-firstBox-colorthemewrapper">
                                                                                      { (result?.Metal === '18KBK' || result?.Metal.includes('BK')) && <div className={`black`}></div>}
                                                                                      { (result?.Metal === '18KWG' || result?.Metal.includes('WG')) && <><div className={`titanium`}></div><span className="ecom_cartproduct-detailsBox-firstBox-colorthemewrapper-title">{t("Sliver")}</span></>}
                                                                                      { (result?.Metal === '18KYG' || result?.Metal.includes('YG')) && <><div className={`sunshine`}></div><span className="ecom_cartproduct-detailsBox-firstBox-colorthemewrapper-title">{t("Yellow Gold")}</span></>}
                                                                                      { (result?.Metal === '18KRG' || result?.Metal.includes('RG')) && <div className={`peach`}></div>}
                                                                                  </div>
                                                    <div className="ecom_cartproduct-detailsBox-firstBox-content">
                                                    <p className="contentPara">{t("Size")}: {result?.size}</p>
                                                    <div className="ecom_cartproduct-detailsBox-firstBox-price">{result?.price}</div>
                                                    </div>
                                                  </div>
                                                  {/* {result?.diamond ?
                      
                                                  <div key={key} className=" ecom_cartproduct diamonditem">
                                                    <div className="com-ecomCheckBox">
                      
                                                    </div>
                                                    <div className="ecom_cartproduct-imageBox">
                                                      <span className="product-img-span">
                                                        <img src={result?.diamond?.main_image} alt="" />
                                                      </span>
                                                    </div>
                                                    <div className=" ecom_cartproduct-detailsBox">
                                                      <div className="ecom_cartproduct-detailsBox-firstBox">
                                                        <p className="ecom_cartproduct-detailsBox-firstBox-productName">
                                                          <Link to={`/product/${result?.motherProduct?.Slug}?v=${result?.id}`}
                                                            state={{ id: result?.motherProduct?._id }}
                                                            onClick={() => modalRef.current.hide()} className="ecom_cartproduct-detailsBox-firstBox-productName text-decoration-none">{result?.diamond?.name}</Link>
                                                          <span className='ecom_cartproduct-detailsBox-firstBox-productName tab-productPriceBlock'>{result?.diamond?.price}</span>
                                                        </p>
                                                        <div className="ecom_cartproduct-detailsBox-firstBox-content">
                                                          <p className="contentPara">{result?.diamond?.SKU}</p>
                                                        </div>
                                                      </div>
                                                      <div className='ecom_cartproduct-detailsBox-secondCountBox'>
                                                        <div className='setCountShow'>
                                                          <button onClick={!result?.diamond ? () => changeqty('minus', result?.id) : null} className='setCountShow-minusIcon'>
                                                            <MinusIcon width={14} height={14} color={'#1D1D1F'} />
                                                          </button>
                                                          <span className='setCountShow-count'>{result?.diamond ? 1 : result?.qty}</span>
                                                          <button onClick={!result?.diamond ? () => changeqty('plus', result?.id) : null} className='setCountShow-plusIcon'>
                                                            <PlusIcon width={14} height={14} color={'#1D1D1F'} />
                                                          </button>
                                                        </div>
                                                        <div className='ecom_cartproduct-detailsBox-secondCountBox-priceShow desktop-productPriceShow'>{result?.diamond?.price}</div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  : ""} */}
                                                  <div className='ecom_cartproduct-detailsBox-secondCountBox'>
                                                    <div className='setCountShow'>
                                                      <button onClick={()=>changeqty('minus', result?.id)} className='setCountShow-minusIcon'>
                                                        <MinusIcon width={14} height={14} color={'#1D1D1F'} />
                                                      </button>
                                                      <span className='setCountShow-count'>{result?.qty}</span>
                                                      <button onClick={() => changeqty('plus', result?.id)} className='setCountShow-plusIcon'>
                                                        <PlusIcon width={14} height={14} color={'#1D1D1F'} />
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                                )
                                                })}
                                            </div>
                                        </div>
                                        :
                                        <div className='appointment_page-container-content-wrapper-inner-holder-left-emptylist'>
                                            
                                            <span className='appointment_page-container-content-wrapper-inner-holder-left-emptylist-title'>{t("Your List is empty.")}</span>
                                            <img src={EmpltyList} alt='appointment'/>
                                            <span className='appointment_page-container-content-wrapper-inner-holder-left-emptylist-metadata'>{t("Still don't have something you want?")}</span>
                                            <span className='appointment_page-container-content-wrapper-inner-holder-left-emptylist-metadata'>{t("Try checking out the new products in case you have something you like to add to your cart.")}</span>
                                            <div className='appointment_page-container-content-wrapper-inner-holder-left-emptylist-btn'>
                                                <div className='btnbold'><span>{t("Continue Shopping")}</span></div>
                                                <div className='btnwithborder'><span>{t("Next")}</span></div>
                                            </div>
                                        </div>
                                    }
                                    </div>
                                    <div className='appointment_page-container-content-wrapper-inner-holder-right'>
                                        <div className='appointment_page-container-content-wrapper-inner-holder-right-map'>
                                            <img src={AppointmentMap} alt='map'/>
                                        </div>
                                        {/* <div className='appointment_page-container-content-wrapper-inner-holder-right-title'>{("Your Details")}</div>
                                        <div className="appointment_page-container-content-wrapper-inner-holder-left-top-details-singledetail">
                                                    <div className={`appointment_page-container-content-wrapper-inner-holder-left-top-details-singledetail-name ${validation?.name ? "error" : ""}`}>
                                                        <span className='headings-name'>{t("Full Name")}<span className='important'>*</span></span>
                                                    </div>
                                                    <div className="appointment_page-container-content-wrapper-inner-holder-left-top-details-singledetail-option">
                                                    <input className={`appointment_page-container-content-wrapper-inner-holder-left-top-details-singledetail-option-input ${validation?.name ? "error" : ""}`} type='text' value={allfromdata?.name}
                                                        onChange={(e) => setalldata("name", e.target.value)}/>
                                                    </div>
                                                    {validation?.name ? 
                                                    <div className='appointment_page-container-content-wrapper-inner-holder-left-top-details-singledetail-error'>
                                                        {t(`${validation?.name}`)}
                                                    </div>
                                                    : "" }
                                                
                                                </div>
                                                <div className="appointment_page-container-content-wrapper-inner-holder-left-top-details-singledetail">
                                                    <div className={`appointment_page-container-content-wrapper-inner-holder-left-top-details-singledetail-name ${validation?.email ? "error" : ""}`}>
                                                        <span className='headings-name'>{t("Email")}<span className='important'>*</span></span>
                                                    </div>
                                                    <div className="appointment_page-container-content-wrapper-inner-holder-left-top-details-singledetail-option">
                                                    <input className={`appointment_page-container-content-wrapper-inner-holder-left-top-details-singledetail-option-input ${validation?.email ? "border-danger" : ""}`} type='text'  value={allfromdata?.email}
                                                        onChange={(e) => setalldata("email", e.target.value)}/>
                                                    </div>
                                                    {validation?.email ? 
                                                    <div className='appointment_page-container-content-wrapper-inner-holder-left-top-details-singledetail-error'>
                                                        {t(`${validation?.email}`)}
                                                    </div>
                                                    : "" }
                                                </div>
                                                <div className="appointment_page-container-content-wrapper-inner-holder-left-top-details-singledetail ">
                                                    <div className={`appointment_page-container-content-wrapper-inner-holder-left-top-details-singledetail-name ${validation?.mobile ? "error" : ""}`}>
                                                        <span className='headings-name'>{t("Phone Number")}<span className='important'>*</span></span>
                                                    </div>
                                                    <div className={`appointment_page-container-content-wrapper-inner-holder-left-top-details-singledetail-option phonefield ${validation?.mobile ? "border-danger" : ""}`}>
                                                        <Select
                                                            options={selectdata}
                                                            className={`ecommerce-dropdown noborder border-0 ${validation?.mobile ? "noborder" : ""}`}
                                                            classNamePrefix="common-select"
                                                            value={{ label: allfromdata?.phonecode, value: allfromdata?.phonecode }}
                                                            onChange={(e) => setalldata("phonecode", e.value)}
                                                        />
                                                        <input className={`appointment_page-container-content-wrapper-inner-holder-left-top-details-singledetail-option-input border-0`} 
                                                        type='number' 
                                                        value={allfromdata?.mobile}
                                                        onChange={(e) => setalldata("mobile", e.target.value)}
                                                        placeholder='Telephone Number'
                                                        />
                                                
                                                    </div>
                                                    {validation?.mobile ? 
                                                    <div className='appointment_page-container-content-wrapper-inner-holder-left-top-details-singledetail-error'>
                                                        {t(`${validation?.mobile}`)}
                                                    </div>
                                                    : "" }
                                                </div>
                                                <div className='appointment_page-container-content-wrapper-inner-holder-right-inputtype'>
                                                <div className="appointment_page-container-content-wrapper-inner-holder-left-top-details-singledetail">
                                                    <div className="appointment_page-container-content-wrapper-inner-holder-left-top-details-singledetail-name">
                                                        <span className='headings-name'>{t("Message")}</span>
                                                    </div>
                                                    <div className="appointment_page-container-content-wrapper-inner-holder-left-top-details-singledetail-option">
                                                    <textarea className='appointment_page-container-content-wrapper-inner-holder-left-top-details-singledetail-option-textarea' placeholder='Message goes here'  value={allfromdata?.message}
                                                        onChange={(e) => setalldata("message", e.target.value)}></textarea>
                                                    </div>
                                                </div>
                                                </div>
                                                <div className='appointment_page-container-content-wrapper-inner-holder-right-tnc'>
                                                    <div className='appointment_page-container-content-wrapper-inner-holder-right-tnc-one'>
                                                    <div className="com-ecomCheckBox">
                                                            <input type="checkbox" className="itemscheckbox com-ecomCheckBox-inputcheck me-2 cursor-pointer"
                                                                id="one" />
                                                            <label className="com-ecomCheckBox-labelBox cursor-pointer fw-semibold appointment_page-container-content-wrapper-inner-holder-left-interest-interestlist-option-tag" htmlFor="one"> {t("I would like to receive updates from JEWELRY using any of the method(s) that I have provided above. You can unsubscribe at any time.")}</label>
                                                    </div>
                                                    </div>
                                                    <div className='appointment_page-container-content-wrapper-inner-holder-right-tnc-one'>
                                                    <div className="com-ecomCheckBox">
                                                            <input type="checkbox" className="itemscheckbox com-ecomCheckBox-inputcheck me-2 cursor-pointer" checked={allfromdata?.policy}
                                                                id="two" onChange={(e)=>e.target.checked ? setalldata("policy", true) : setalldata("policy", false)}/>
                                                            <label className="com-ecomCheckBox-labelBox cursor-pointer fw-semibold appointment_page-container-content-wrapper-inner-holder-left-interest-interestlist-option-tag" htmlFor="two">
                                                            <span><span className='important'>*</span> 
                                                                By submitting this form, I certify that I have read and accepted the <span className='pp'> Privacy Policy</span> of JEWELRY.</span> 
                                                            </label>
                                                    </div>
                                                    {validation?.policy ? 
                                                    <div className='appointment_page-container-content-wrapper-inner-holder-left-top-details-singledetail-error'>
                                                        {t(`${validation?.policy}`)}
                                                    </div>
                                                    : "" }
                                                    </div>
                                                    <div></div>
                                                </div> */}
                                    </div>
                                </div>
                    </div>
                   : 
                   activeStep === 2 ?
                   <div className='appointment_page-container-content-wrapper-inner'>
                   <div className='appointment_page-container-content-wrapper-inner-title'>
                       <span>{t("Choose your Date/Time")}</span>
                   </div>
                   <div className='appointment_page-container-content-wrapper-inner-details'>
                                   <div className="appointment_page-container-content-wrapper-inner-details-singledetail">
                                       <div className="text-center appointment_page-container-content-wrapper-inner-details-singledetail-name">
                                           <span>{t("Select Date")}</span>
                                       </div>
                                       <div className="appointment_page-container-content-wrapper-inner-details-singledetail-option">
                                        {allfromdata?.appointment_date ? <span className='tag'>{t("Date")}</span> : null}
                                        {!allfromdata?.appointment_date ? <span className='iconindicator'><CalendarIcon/></span> : null}
                                        <DatePicker
                                            selected={allfromdata?.appointment_date}
                                            onChange={(date) => setalldata("appointment_date", date)}
                                            dateFormat="dd/MM/yyyy"
                                            className={allfromdata?.appointment_date ? 'isfocused' : ""}
                                            minDate={new Date()}
                                            autoComplete='none'
                                            classNamePrefix="newDatepicker"
                                        />
                                       </div>
                                   </div>
                                   <div className="appointment_page-container-content-wrapper-inner-details-singledetail">
                                       <div className={`text-center appointment_page-container-content-wrapper-inner-details-singledetail-name ${validation?.time ? "error" : ""}`}>
                                           <span className='headings-name'>{t("Select Time")}</span>
                                       </div>
                                       <div className="appointment_page-container-content-wrapper-inner-details-singledetail-option">
                                       {allfromdata?.time ? <span className='tag'>{t("Time")}</span> : null}
                                       {!allfromdata?.time ? <span className='iconindicator'><CircleClockIcon/></span> : null}
                                           <Select
                                               options={timeArray}
                                               className={` ${validation?.time ? "border-danger" : ""}`}
                                               height='46px' 
                                               width='100%'
                                               value={allfromdata?.time}
                                               onChange={(date) => setalldata("time", date)}
                                               placeholder={"Select Time"}
                                               isSearchable={false}
                                               classNamePrefix='newDropdown'
                                               />
                                       </div>
                                       {validation?.time ? 
                                       <div className='appointment_page-container-content-wrapper-inner-details-singledetail-error'>
                                           {t(`${validation?.time}`)}
                                       </div>
                                       : "" }
                                   </div>
                   </div>
                   </div>
                   : 
                   activeStep === 3 ? 

                   <div className='appointment_page-container-content-wrapper-inner'>
                        { screenSize.width < 1200 ? <div className='appointment_page-container-content-wrapper-inner-responsive'><div className='btnbold' onClick={()=>setShowInformationPopup(true)}><span>{t("Get an Appointment")}</span></div></div> : null}
                        <div className='appointment_page-container-content-wrapper-inner-holder gap-0'>
                           
                            <div className='appointment_page-container-content-wrapper-inner-holder-leftContact'>
                                <div className='appointment_page-container-content-wrapper-inner-holder-leftContact-title'>
                                    <span className=''>{t("Contact Us")}</span>
                                </div>
                                <div className='appointment_page-container-content-wrapper-inner-holder-leftContact-properties'>
                                        <div className='appointment_page-container-content-wrapper-inner-holder-leftContact-properties-metadata'>
                                            {t("We are ready to serve you.")}<br/>
                                            {t("If you have any questions, feel free to contact us!")}
                                        </div>
                                        <div className={screenSize.width > 1200 ? 'btnbold' : 'btnwithborder'}>
                                            <span className='cellphone'><CellPhoneGreen/></span> <span>{t("+66 999-999")}</span>
                                        </div>
                                        <div className={screenSize.width > 1200 ? 'btnbold' : 'btnwithborder'}>
                                            <span className='messageicon'><Messegeboxblue/></span> <span>{t("centralworld@gmail.com")}</span>
                                        </div>
                                        <div className={screenSize.width > 1200 ? 'btnbold' : 'btnwithborder'}>
                                            <Location filled={true}/> <span>{t("Central World, Bangkok, thailand")}</span>
                                        </div>
                                        <div className='appointment_page-container-content-wrapper-inner-holder-leftContact-properties-socials'>
                                            <div className='icon-holder'><Messegeboxblue/></div>
                                        </div>
                                </div>    
                                {/* <div className='appointment_page-container-content-wrapper-inner-holder-left-interest'>
                                <div className='appointment_page-container-content-wrapper-inner-holder-left-interest-title'>{("Please select one or more interests")} <span className='important'>*</span></div>
                                <div className='appointment_page-container-content-wrapper-inner-holder-left-interest-interestlist'>
                                    {interestArray.map((item,i)=>{
                                        return(
                                        <div className='appointment_page-container-content-wrapper-inner-holder-left-interest-interestlist-option' key={i}>
                                            <div className="com-ecomCheckBox">
                                                <input type="checkbox" className="itemscheckbox com-ecomCheckBox-inputcheck me-2 cursor-pointer"
                                                    id={item} onClick={()=>setAllfromdata((state)=>({...state, "interests" :  state.interests ? [...state.interests, item] : [item]}))}/>
                                                <label className="com-ecomCheckBox-labelBox cursor-pointer fw-semibold appointment_page-container-content-wrapper-inner-holder-left-interest-interestlist-option-tag" htmlFor={item}> {t(`${item}`)}</label>
                                            </div>
                                        </div>
                                        )
                                    })}
                                </div>
                                </div> */}
                            </div>
                        {screenSize.width > 1200 ?
                            <div className='appointment_page-container-content-wrapper-inner-holder-rightContact'>
                                <div className='appointment_page-container-content-wrapper-inner-holder-rightContact-title'>
                                    {t("Information")}
                                </div>
                                <div className='appointment_page-container-content-wrapper-inner-holder-rightContact-form'>
                                    <div className='appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper'>
                                        <div className="appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail">
                                            <div className={`appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-option ${allfromdata?.firstname ? "active" : ""}`}>
                                                {allfromdata?.firstname ? <div className='tag'>{t("First Name")}<span className='text-danger'>*</span></div> : null }
                                            <input className={`appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-option-input ${validation?.firstname ? "border-danger" : ""}`} type='text' value={allfromdata?.firstname}
                                                onChange={(e) => setalldata("firstname", e.target.value)} placeholder='First Name'/>
                                            </div>
                                            {validation?.firstname ? 
                                            <div className='appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-error text-danger'>
                                                {t(`${validation?.firstname}`)}
                                            </div>
                                            : "" }
                                        </div> 
                                        <div className="appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail">
                                            <div className={`appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-option ${allfromdata?.lastname ? "active" : ""} ${validation?.lastname ? "error" : ""}`}>
                                            {allfromdata?.lastname ? <div className='tag'>{t("Last Name")}<span className='text-danger'>*</span></div> : null }
                                            <input className={`appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-option-input ${validation?.lastname ? "error" : ""}`} type='text' value={allfromdata?.lastname}
                                                onChange={(e) => setalldata("lastname", e.target.value)} placeholder='Last Name'/>
                                            </div>
                                            {validation?.lastname ? 
                                            <div className='appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-error text-danger'>
                                                {t(`${validation?.lastname}`)}
                                            </div>
                                            : "" }
                                        </div>  
                                    </div>
                                    <div className='appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper'>
                                    <div className="appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail ">
                                            <div className={`appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-option phonefield ${allfromdata?.phoneCode || allfromdata.mobile ? "active" : ""} ${validation?.mobile ? "border-danger" : ""}`}>
                                            {allfromdata?.phoneCode || allfromdata.mobile ? <div className='tag'>{t("Phone Number")}<span className='text-danger'>*</span></div> : null }
                                                <Select
                                                    options={selectdata}
                                                    className={`border-0`}
                                                    value={{ label: allfromdata?.phonecode, value: allfromdata?.phonecode }}
                                                    onChange={(e) => setalldata("phonecode", e.value)}
                                                    classNamePrefix="newDropdown"
                                                />
                                                <input className={`border-0 appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-option-input`} 
                                                type='number' 
                                                value={allfromdata?.mobile}
                                                onChange={(e) => setalldata("mobile", e.target.value)}
                                                placeholder='Phone Number'
                                                />
                                        
                                            </div>
                                            {validation?.mobile ? 
                                            <div className='appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-error text-danger'>
                                                {t(`${validation?.mobile}`)}
                                            </div>
                                            : "" }
                                    </div>
                                        <div className="appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail">
                                            <div className={`appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-option ${allfromdata?.email ? "active" : ""}`}>
                                            {allfromdata?.email ? <div className='tag'>{t("Email")}<span className='text-danger'>*</span></div> : null }
                                            <input className={`appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-option-input ${validation?.email ? "border-danger" : ""}`} type='text'  value={allfromdata?.email}
                                                onChange={(e) => setalldata("email", e.target.value)} placeholder='email'/>
                                            </div>
                                            {validation?.email ? 
                                            <div className='appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-error text-danger'>
                                                {t(`${validation?.email}`)}
                                            </div>
                                            : "" }
                                        </div>
                                    </div>
                                </div>
                                        <div className='appointment_page-container-content-wrapper-inner-holder-rightContact-form-inputtype'>
                                        <div className="appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail">
                                            <div className={`appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-option ${allfromdata?.message ? "active" : ""}`}>
                                            <div className='tag z-0'>{t("Message")}</div>
                                            <textarea className='appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-option-textarea' value={allfromdata?.message}
                                                onChange={(e) => setalldata("message", e.target.value)} placeholder="Write message here"></textarea>
                                            </div>
                                        </div>
                                        </div>
                            </div>
                            :
                            <>
                            {showInformationPopup && 
                            <div className='appointment_page-container-content-wrapper-inner-holder-popup' onClick={()=>setShowInformationPopup(false)}>
                                <div className='appointment_page-container-content-wrapper-inner-holder-popup-modal' onClick={(e)=>e.stopPropagation()}>
                                    <button type="button" className="modalonright-headerBox-crossbtn" onClick={()=>setShowInformationPopup(false)}>
                                        <CrossIcon width={18} height={18} />
                                    </button>
                                    <div className='appointment_page-container-content-wrapper-inner-holder-popup-modal-title'>{t("Customer Information")}</div>
                                    <div className='appointment_page-container-content-wrapper-inner-holder-popup-modal-form'>
                                    <div className="appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail">
                                            <div className={`appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-option ${allfromdata?.firstname ? "active" : ""}`}>
                                                {allfromdata?.firstname ? <div className='tag'>{t("First Name")}<span className='text-danger'>*</span></div> : null }
                                            <input className={`appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-option-input ${validation?.firstname ? "error" : ""}`} type='text' value={allfromdata?.firstname}
                                                onChange={(e) => setalldata("firstname", e.target.value)} placeholder='First Name'/>
                                            </div>
                                            {validation?.firstname ? 
                                            <div className='appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-error text-danger'>
                                                {t(`${validation?.firstname}`)}
                                            </div>
                                            : "" }
                                        </div> 
                                        <div className="appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail">
                                            <div className={`appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-option ${allfromdata?.lastname ? "active" : ""} ${validation?.lastname ? "error" : ""}`}>
                                            {allfromdata?.lastname ? <div className='tag'>{t("Last Name")}<span className='text-danger'>*</span></div> : null }
                                            <input className={`appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-option-input ${validation?.lastname ? "error" : ""}`} type='text' value={allfromdata?.lastname}
                                                onChange={(e) => setalldata("lastname", e.target.value)} placeholder='Last Name'/>
                                            </div>
                                            {validation?.lastname ? 
                                            <div className='appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-error text-danger'>
                                                {t(`${validation?.lastname}`)}
                                            </div>
                                            : "" }
                                        </div>
                                        <div className="appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail ">
                                            <div className={`appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-option phonefield ${allfromdata?.phoneCode || allfromdata.mobile ? "active" : ""} ${validation?.mobile ? "border-danger" : ""}`}>
                                            {allfromdata?.phoneCode || allfromdata.mobile ? <div className='tag'>{t("Phone Number")}<span className='text-danger'>*</span></div> : null }
                                                <Select
                                                    options={selectdata}
                                                    className={`border-0`}
                                                    value={{ label: allfromdata?.phonecode, value: allfromdata?.phonecode }}
                                                    onChange={(e) => setalldata("phonecode", e.value)}
                                                    classNamePrefix="newDropdown"
                                                />
                                                <input className={`border-0 appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-option-input`} 
                                                type='number' 
                                                value={allfromdata?.mobile}
                                                onChange={(e) => setalldata("mobile", e.target.value)}
                                                placeholder='Phone Number'
                                                />
                                        
                                            </div>
                                            {validation?.mobile ? 
                                            <div className='appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-error text-danger'>
                                                {t(`${validation?.mobile}`)}
                                            </div>
                                            : "" }
                                        </div>
                                        <div className="appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail">
                                            <div className={`appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-option ${allfromdata?.email ? "active" : ""}`}>
                                            {allfromdata?.email ? <div className='tag'>{t("Email")}<span className='text-danger'>*</span></div> : null }
                                            <input className={`appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-option-input ${validation?.email ? "border-danger" : ""}`} type='text'  value={allfromdata?.email}
                                                onChange={(e) => setalldata("email", e.target.value)} placeholder='email'/>
                                            </div>
                                            {validation?.email ? 
                                            <div className='appointment_page-container-content-wrapper-inner-holder-rightContact-form-wrapper-singledetail-error text-danger'>
                                                {t(`${validation?.email}`)}
                                            </div>
                                            : "" }
                                        </div>  
                                        <div className='btnbold' onClick={()=>handleNextStep()}>
                                            <span>{t("Submit")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            </>             
                            }
                        </div>
                   </div>
                    : 
                    <div className='appointment_page-container-content-wrapper-inner'>
                        <div className='appointment_page-container-content-wrapper-inner-confirmation'>
                            <span className='successicon'><ConfirmationNewIcon/></span>
                            <div className='subdata1'>{t("Appointment Confirmation")}</div>
                            <div className='subdata2'>{t("Thankyou for your Appointment")}</div>
                            <div className='appointment_page-container-content-wrapper-inner-confirmation-block'>
                                <div className='appointment_page-container-content-wrapper-inner-confirmation-block-label'>{t("APPOINTMENT ID")}: #{confirmationData?.appointment_id}</div>
                                <div className='appointment_page-container-content-wrapper-inner-confirmation-block-metadata'>
                                    <div className='item'><span className='icon-holder'><Location filled={true}/></span> <span>{confirmationData?.branchDetails?.name}</span></div>
                                    <div className='item'><span className='icon-holder'><Calendar/></span> <span>{confirmationData?.appointment_date}</span></div>
                                </div>
                                <div className='appointment_page-container-content-wrapper-inner-confirmation-block-data'>
                                    <span>{confirmationData?.appointmentDetails?.name}</span>
                                    <span>{confirmationData?.appointmentDetails?.email}</span>
                                    <span>{confirmationData?.appointmentDetails?.contact}</span>
                                </div>
                                <div className='appointment_page-container-content-wrapper-inner-confirmation-block-barcode'>
                                    <span>{t("Barcode")}</span>
                                    <div className='img'><img src={Barcode} alt='barcode'/></div>
                                </div>
                                <div className='appointment_page-container-content-wrapper-inner-confirmation-block-features'>
                                    <span className='item'><span className='icon-holder' onClick={() => handlePrint()}><PrintIcon/></span><span>{t("Print")}</span></span>
                                    <span className='item'><span className='icon-holder'  onClick={() => downloadPageAsPNG()}><DownloadIcon/></span><span>{t("Download")}</span></span>
                                    <span className='item'><span className='icon-holder'><ShareIcon/></span><span>{t("Share")}</span></span>
                                </div>
                            </div>
                            <div className='appointment_page-container-content-wrapper-inner-confirmation-warning'>
                                <span>{t("***Please come to the store 15-30 minutes before your appointment time***")}</span>
                            </div>
                        </div>
                    </div>
                   }
                    {/* <div className={`appointment_page-container-content-bottom ${enableButton ? "" : "disable"}`}>
                    <button className=""
                                                onClick={enableButton ? () => handleAppointmentConfirm() : null}
                                            >
                                                {t("Book My Appointment")}
                                            </button>
                    </div> */}
                    </div>
                    <div className='appointment_page-container-content-nextStepBtn'>
                        <div className='btnbold' onClick={()=>handleNextStep()}>
                               <span> {activeStep === 4 ? t("Submit") : t("Continue")}</span><span><LeftArrow/></span>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center'>
                        {t(`${activeStep}`)}/{t("4")}
                    </div>
                </div>
                     }
              
        </div>
        <BottomBar/>
        {/* <ConfirmPopup 
        isVisible={isPopupVisible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        message="Confirm Book An Appointment"
        sub="Are sure you want to cancel this appointment?"
        leftbtn={"Cancel"}
        rightbtn={"Yes"}
        /> */}
        {isPopupVisible &&
            <div className='appointment_page-container-content-wrapper-inner-holder-downloadpopup' onClick={()=>setIsPopupVisible(false)}>
                                <div className='appointment_page-container-content-wrapper-inner-holder-downloadpopup-modal' onClick={(e)=>e.stopPropagation()}>
                                <button type="button" className="modalonright-headerBox-crossbtn" onClick={()=>setIsPopupVisible(false)}>
                                        <CrossIcon width={18} height={18} />
                                </button>
                                <div className='appointment_page-container-content-wrapper-inner-holder-downloadpopup-download'>
                                    <div className='appointment_page-container-content-wrapper-inner-holder-downloadpopup-download-title'><span>{t("Download Complete")}</span></div>
                                    <div className='appointment_page-container-content-wrapper-inner-holder-downloadpopup-download-icon'><CircledTickGray/> </div>
                                    <div className='appointment_page-container-content-wrapper-inner-holder-downloadpopup-download-metadata'>
                                        <span>{t("Files Downloaded !")}</span>
                                        <span>{t("Save your file on the drive")}</span>
                                        <span>{t("Show your appointment barcode at the store")}</span>
                                    </div>
                                </div>
                                </div>
            </div>
        }
</div >
  )
}
