import { useEffect , useState } from "react";
import { _Api } from "../api/_call";
import {  useParams } from 'react-router-dom';
import { stoneMerge } from "components/common/helpers/barcodeHelper";
const ProductViewer = () => { 
    const [productDetail,setProductDetail]  = useState({});
const {product_id,dbname,config_template_id} = useParams();
const [expend,setExpend] = useState(false)

let stone_code = []
let stone_size = []
let stone_shape = []
let stone_clarity = []
let stone_cut = []
const [templateConfigData,setConfigTemplateData] = useState(false)
function checkType(value) {
    if (Array.isArray(value)) {
      return "Array";
    } else if (typeof value === "object" && value !== null) {
      return "Object";
    } else {
      return "Neither Object nor Array";
    }
  }

  
    useEffect(()=>{
        const fetchData = async () => {
            try {
              const postdata = {id: product_id,data:dbname}
              const profile_template_id = config_template_id?config_template_id:""
              if(profile_template_id!=""){
                let profile_data = await _Api( {_id:profile_template_id}, `api/v1/template-printout/getByid`);

                setConfigTemplateData(profile_data.data)
              }
              let res = await _Api(postdata, `api/v1/qr-code/getGeneral`);
              console.log(res)
         
                const {data} = await res;
                setProductDetail(data[0])
             
              
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
      
          fetchData();

        
    }

    ,[])
    return (

     <>

 <div className="container qr_viewer">
          <div className="row">
            <div className="col-sm-12">
                <h1>{productDetail.name}</h1>
                
                <div className="row">
                    <img src={productDetail.main_image} className="w-100"/>
                </div>
                <p>
                     <b>SKU : </b> {productDetail.SKU}<br/>

                </p>
                <p className={expend?"description_product_viewer expend":"description_product_viewer"}>
                <b>Description : </b> {productDetail.Description}<br/>
                </p>
                <span className="expend_more" onClick={()=>{
                    setExpend(!expend)
                }}> {expend ? <i className="fa fa-angle-double-up"></i> : <i className="fa fa-angle-double-down"></i>}</span>
               
               
               <div className="stone_detail">


{productDetail.Metal ? 
  <> <b>Metal :</b>  <span>{productDetail.metal_name?productDetail.metal_name:""}</span> </> 
:  ""}


</div>

               
                <div className="stone_detail">


                    
                         <b>Stones : </b> 
                         
                        {productDetail.Stones_Data?stoneMerge(templateConfigData.profile_setup_info , productDetail.Stones_Data).map((v,k)=>{
console.log(productDetail.Stones_Data,'productDetail.Stones_Data')

                           
                                return (
                                    <li key={k}>
                                                 {v.stone_details?<span>{v.stone_details.name}</span>:""}
                                                 {v.stone_shape_details?<span>{v.stone_shape_details.name}</span>:""}
                                                 {v.Pcs?<span>{v.Pcs}</span>:""}
                                                 {v.Cts?<span>{v.Cts}{v.Unit.substring(0,1).toLocaleUpperCase()}</span>:""}
                                   
                                    </li>  
                                )
                            


                        }):""}
                
                </div>

          



            </div>
 </div>
</div>
</>   
    )


}

export default ProductViewer;