import React, { useState, useEffect, useRef } from "react";
import RightArrow from "../common/icons/right-arrow";
import { useTranslation } from 'react-i18next';
import EditSalesPerson from './common/EditSalesPerson';
import { _Api, Api_form } from "../api/_call";
import Confirmationmodel from "../common/modules/confirmationmodel"
import { success, error, loading, Updateloading } from "../common/helpers/toastify"
import { Pagination } from "../common/Pagination";
import { Importmodal } from "../common/modules/Importmodal"
import { useSelector } from 'react-redux';
import Select from "react-select";
import TableLoader from "../common/TableLoader";
const MasterSalesPerson = () => {
  const dropdownRefname = useRef(null);
  const dropdownRefposition = useRef(null);
  const dropdownRefstatus = useRef(null);
  const dropdownReflocation = useRef(null)
  const dropdownRefcountry = useRef(null)
  const salepersonListRef = useRef();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation();
  const [salespersonlist, setSalespersonlist] = useState([])
  const [editItem, setEditItem] = useState(false);
  const [dataid, setDataid] = useState("")
  const [filtername, setFiltername] = useState('');
  const [editsales, setEditSales] = useState()
  const [create, setCreate] = useState(false)
  const [searchVal, setSearchVal] = useState("");
  const [namefilter, setNamefilter] = useState([])
  const [namechecked, setNamehecked] = useState([])
  const [showloader, setShowloader] = useState(false)
  const [positionfilter, setpositionfilter] = useState([])
  const [positionchecked, setpositionchecked] = useState([]);
  const [statuschecked, setStatuschecked] = useState("")
  const [statusfilter, setStatusfilter] = useState([])
  const [locationchecked, setLocationchecked] = useState([])
  const [locationfilter, setLocationfilter] = useState([])
  const [countryfilter, setcountryfilter] = useState([])
  const [countrychecked, setcountrychecked] = useState([])
  const [shortobj, setShortobj] = useState({})
  const [exportid, setExportid] = useState([])
  const [importfile, setImportfile] = useState()
  const [limit, setLimit] = useState(100);
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const filterbyname = (search) => {
    getsalesperson(currentPage, limit, search);
    setstate({ ...state, currentPage: 1 });
  };
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });
  const { totalPages, currentPage } = state;
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current });
    getsalesperson(current, "");
  };
  const getsalesperson = async (current, firstlimit, search) => {
    setShowloader(true);
    if (editsales) {
      setpositionchecked([])
      setNamehecked([]);
      setStatuschecked("")
      setLocationchecked([])
      setcountrychecked([])
    }
    try {
      var skipNumber = current ? (current - 1) * limit : 0;
      let postdata = {
        "search": search ? search : "",
        "limit": firstlimit ? firstlimit : limit,
        "skip": skipNumber,
        "filter": {
          "position": positionchecked ? positionchecked : [],
          "name": namechecked ? namechecked : [],
          "status": statuschecked ? statuschecked : "",
          "location": locationchecked ? locationchecked : [],
          "country": countrychecked ? countrychecked : [],
        },
        "sort": { [shortobj?.key]: shortobj?.value }
      }
      let res = await _Api(postdata, `api/v1/App/master/salesPersonsMasterView`)
      if (res.code === 200) {
        setSalespersonlist(res?.data)
        setShowloader(false);
        setstate({
          ...state,
          totalPages: Math.ceil(res?.total / postdata.limit),
          currentPage: current ? current : 1
        });
      }
    } catch (err) {
      console.log(err, "err")
    }
  }
  const getlimit = (limit) => {
    setLimit(limit);
    getsalesperson(1, limit);
    setstate({ ...state, currentPage: 1 });
  };

  const salspersonfilter = async () => {
    try {
      let postdata = {}
      let res = await _Api(postdata, `api/v1/App/master/salesPersonfilter`)
      if (res?.code === 200) {
        setNamefilter(res?.data?.name);
        setpositionfilter(res?.data?.position)
        setStatusfilter(res?.data?.status)
        setLocationfilter(res?.data?.location)
        setcountryfilter(res?.data?.country)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  useEffect(() => {
    getsalesperson()
    salspersonfilter()
    // eslint-disable-next-line
  }, [editsales, shortobj])

  const salespersondelete = async () => {
    if (!dataid) {
      error("ID is required")
      return;
    }
    let GetloadingID = loading();
    try {
      let postdata = {
        id: dataid
      }
      let res = await _Api(postdata, `api/v1/App/master/salesPersonMasterDelete`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, t("Successfully Delete"));
        getsalesperson()
      } else {
        Updateloading(t, GetloadingID, t("Somthing Went Wrong"), 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }

  const hendledropdownhide = (lable) => {
    if (lable === "name") {
      dropdownRefname.current.classList.remove("show")
    } else if (lable === "position") {
      dropdownRefposition.current.classList.remove("show")
    } else if (lable === "status") {
      dropdownRefstatus.current.classList.remove("show")
    } else if (lable === "location") {
      dropdownReflocation.current.classList.remove("show")
    } else if (lable === "country") {
      dropdownRefcountry.current.classList.remove("show")
    }
  }
  const handleCheckAllChange = (e, lable) => {
    if (lable === "name") {
      setNamehecked(
        e.target.checked ? namefilter.map((c) => c) : []
      );
    } else if (lable === "position") {
      setpositionchecked(
        e.target.checked ? positionfilter.map((c) => c) : []
      );
    } else if (lable === "location") {
      setLocationchecked(
        e.target.checked ? locationfilter.map((c) => c?._id) : []
      );
    } else if (lable === "country") {
      setcountrychecked(
        e.target.checked ? countryfilter.map((c) => c?.id) : []
      );
    } else if (lable === "export") {
      setExportid(
        e.target.checked ? salespersonlist.map((c) => c?._id) : []
      )
    }

  };
  const handlefilerChange = (event, data, lable) => {
    if (lable === "name") {
      setNamehecked((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data]
          : prevChecked.filter((item) => item !== data)

      );
    } else if (lable === "position") {
      setpositionchecked((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data]
          : prevChecked.filter((item) => item !== data)

      );
    } else if (lable === "location") {
      setLocationchecked((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data?._id]
          : prevChecked.filter((item) => item !== data?._id)

      );
    } else if (lable === "country") {
      setcountrychecked((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data?.id]
          : prevChecked.filter((item) => item !== data?.id)
      );
    } else if (lable === "export") {
      setExportid((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data]
          : prevChecked.filter((item) => item !== data)
      )
    }


  };

  const getscarchvalue = async (event, lable) => {
    if (lable === "name") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...namefilter];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setNamefilter(arr)
        });
      } else {
        salspersonfilter()
      }
    } else if (lable === "position") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...positionfilter];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setpositionfilter(arr)
        });
      } else {
        salspersonfilter()
      }
    } else if (lable === "location") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...locationfilter];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.name?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setLocationfilter(arr)
        });
      } else {
        salspersonfilter()
      }
    } else if (lable === "country") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...countryfilter];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.name?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setcountryfilter(arr)
        });
      } else {
        salspersonfilter()
      }
    }
  }
  const SampleExcel = () => {
    const exportLinkElement = document.createElement('a')
    exportLinkElement.hidden = true
    exportLinkElement.download = 'salesPersonExport.xlsx'
    exportLinkElement.href = base_assets + "master_xlsx/salesPersonExport.xlsx"
    exportLinkElement.text = 'downloading...'
    document.body.appendChild(exportLinkElement)
    exportLinkElement.click()
    exportLinkElement.remove()
  }
  const downloadexclsheet = async () => {
    let GetloadingID = loading()
    try {
      let postdata = {
        ids: exportid
      }
      let res = await _Api(postdata, `api/v1/App/Export/salesPersonExport`)
      if (res?.code === 200) {
        window.open(res?.filexlsx, '_blank', 'noreferrer')
        Updateloading(t, GetloadingID, (res?.message))
      } else {
        Updateloading(t, GetloadingID, res?.errors, 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const readExcel = async () => {
    if (!importfile) {
      error(t('Choose file!'));
      return;
    }
    try {
      const formData = new FormData()
      formData.append('file', importfile)
      formData.append('type', 'SalesPerson')
      let res = await Api_form(
        formData,
        `api/v1/App/Import/salesPersonImport`,
      )
      if (res.code === 200) {
        success(
          t(res?.message),
        )
        getsalesperson()
      } else {
        error(t(res?.message))
      }
    } catch (err) {
      console.log(err, 'err')
    }

  }
  const selectOptions = [
    { label: "100", value: "100" },
    { label: "200", value: "200" },
    { label: "300", value: "300" }
  ]
  const handleScrollToThetop = () =>{
    setTimeout(()=>{
      if (salepersonListRef.current) {
        salepersonListRef.current.scrollIntoView({ behavior: 'smooth', block: 'start'});
      }
    },100)
  }
  return (
    <>
      <div className="main-content-wrapper-body">
        <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
          <div className="main-content-wrapper-body-top-bar-left">
            <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">{t("Master")} <RightArrow /> <span className="cursor-pointer" onClick={() => setEditSales(true)}>{t("Sales Person")}</span></h1>
          </div>
          <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">
            <Select
              className="limit-select mx-3"
              options={selectOptions}
              value={{ label: limit, value: limit }}
              onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
            />
            <div className="com-search-bar position-relative">
              <img className="com-search-bar-search-img"
                onClick={filterbyname} src={base_assets + "images/admin/icons/gray-search.png"} alt="" />
              <input placeholder="Search Sales Person" value={filtername} className="h-100 border-0 bg-transparent"
                onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                onChange={(e) => setFiltername(e.target.value)} />
              {filtername ? <img className="search-bar-clear" onClick={(e) => {
                setFiltername("");
                getsalesperson(1, 100, "")
              }} src={base_assets + 'images/icons/False.png'} alt="" /> : ""}
            </div>
            <div className="print-export-dropdown dropdown">
              <button
                className="btn dropdown-toggle text-center d-flex align-items-center rounded-1 ms-3 justify-content-center bg-white toggle-btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={base_assets + "images/admin/icons/gray-ellipsis.png"} alt="" />
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li className="dropdown-item print-dropdown-item">
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + "images/icons/printer-icon.png"}
                      alt=""
                    />
                  </div>
                  <span>{t("print")}</span>
                </li>
                <li className="dropdown-item"
                  onClick={() => downloadexclsheet()}>
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + "images/icons/export-x-icon.png"}
                      alt=""
                    />
                  </div>
                  <span>{t("export")}</span>
                </li>
                <li className="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#ImportModal">
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + "images/icons/export.png"}
                      alt=""
                    />
                  </div>
                  <span >{t("Import")}</span>
                </li>
              </ul>
            </div>
            <button className={parmissiondatashow?.Master?.Add ? "com-plus-text-btn text-white text-center fw-semibold d-flex align-items-center border-0 rounded-1 ms-3" : "d-none"}
              onClick={() => { setEditItem(true); setCreate(true) }}>
              <img className="me-2" src={base_assets + "images/admin/icons/add-icon-white.png"} alt="" />{t("Create")}
            </button>
          </div>
        </div>

        <div className="row">
          <div className={editItem ? "col-md-6" : "col-md-12"}>
            <div
              className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
            >
              <table
                id="my_customer"
                className="com-custom-table-tag table align-middle">
                <thead>
                  <tr className="text-nowrap align-middle">
                    <th className="pe-0">
                      <div className="com-check">
                        <input id="check1" type="checkbox"
                          onChange={(e) => handleCheckAllChange(e, "export")}
                          checked={salespersonlist?.length ? exportid?.length === salespersonlist?.length : false} />
                        <label htmlFor="check1" className="com-check-label"
                        />
                      </div>
                    </th>
                    <th>#</th>
                    <th className="px-2"></th>
                    <th className="user-name-width">{t("Name")}
                      <div className="dropdown d-inline-block com-custom-table-tag-filter">
                        <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                          <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                        </button>
                        <div className={"dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"}
                          ref={dropdownRefname}>
                          <div className="asc-des-order">
                            <div onClick={() => { setShortobj({ key: "name", value: 1 }); hendledropdownhide("name") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                            <div onClick={() => { setShortobj({ key: "name", value: -1 }); hendledropdownhide("name") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-search">
                            <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                              <img src={base_assets + "images/icons/search-icon.png"} alt="" />
                              <input className="border-0" type="text" placeholder="Search"
                                onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "name")}
                                onChange={(e) => setSearchVal(e.target.value)} />
                            </div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-select">
                            <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                              <div>
                                <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="name"
                                  onChange={(e) => handleCheckAllChange(e, "name")}
                                  checked={namechecked.length === namefilter.length} />
                                <label className="form-check-label cursor-pointer fw-semibold" htmlFor="name">{t("Select All")}</label>
                              </div>
                              <label>{namechecked.length}/{namefilter.length} {t("Selected")}</label>
                            </div>
                            <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                              {namefilter?.length ? namefilter.map((result, key) => {
                                return (
                                  <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                    <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={"name" + key}
                                      checked={
                                        namechecked ? namechecked.includes(result) : ""
                                      }
                                      onChange={(e) => handlefilerChange(e, result, "name")} />
                                    <label className="form-check-label cursor-pointer" htmlFor={"name" + key}>{result}</label>
                                  </li>
                                )
                              }) : ""}

                            </ul>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2"
                          >
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                              onClick={() => { setNamehecked([]); setShortobj({}); hendledropdownhide("name") }}>{t("Clear")}</button>
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { getsalesperson(); hendledropdownhide("name") }}> {("Apply")}</button>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th>{t("Position")}
                      <div className="dropdown d-inline-block com-custom-table-tag-filter">
                        <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                          <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                        </button>
                        <div className="dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden code"
                          ref={dropdownRefposition} >
                          <div className="asc-des-order">
                            <div onClick={() => { setShortobj({ key: "position", value: 1 }); hendledropdownhide("position") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                            <div onClick={() => { setShortobj({ key: "position", value: -1 }); hendledropdownhide("position") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-search">
                            <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                              <img src={base_assets + "images/icons/search-icon.png"} alt="" />
                              <input className="border-0" type="text" placeholder="Search"
                                onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "position")}
                                onChange={(e) => setSearchVal(e.target.value)} />
                            </div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-select">
                            <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                              <div>
                                <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="position"
                                  onChange={(e) => handleCheckAllChange(e, "position")}
                                  checked={positionchecked.length === positionfilter.length} />
                                <label className="form-check-label cursor-pointer fw-semibold" htmlFor="position">{t("Select All")}</label>
                              </div>
                              <label>{positionchecked.length}/{positionfilter.length} {t("Selected")}</label>
                            </div>
                            <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                              {positionfilter?.length ? positionfilter.map((result, key) => {
                                return (
                                  <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                    <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={"position" + key}
                                      checked={
                                        positionchecked ? positionchecked.includes(result) : ""
                                      }
                                      onChange={(e) => handlefilerChange(e, result, "position")} />
                                    <label className="form-check-label cursor-pointer" htmlFor={"position" + key}>{result}</label>
                                  </li>
                                )
                              }) : ""}

                            </ul>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2">
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                              onClick={() => { setpositionchecked([]); setShortobj({}); hendledropdownhide("position") }}>{t("Clear")}</button>
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { getsalesperson(); hendledropdownhide("position") }}> {("Apply")}</button>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th>{t("Country")}
                      <div className="dropdown d-inline-block com-custom-table-tag-filter">
                        <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                          <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                        </button>
                        <div className={"dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"}
                          ref={dropdownRefcountry}>
                          <div className="asc-des-order">
                            <div onClick={() => { setShortobj({ key: "country", value: 1 }); hendledropdownhide("country") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                            <div onClick={() => { setShortobj({ key: "country", value: -1 }); hendledropdownhide("country") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-search">
                            <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                              <img src={base_assets + "images/icons/search-icon.png"} alt="" />
                              <input className="border-0" type="text" placeholder="Search"
                                onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "country")}
                                onChange={(e) => setSearchVal(e.target.value)} />
                            </div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-select">
                            <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                              <div>
                                <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="country"
                                  onChange={(e) => handleCheckAllChange(e, "country")}
                                  checked={countrychecked.length === countryfilter.length} />
                                <label className="form-check-label cursor-pointer fw-semibold" htmlFor="country">{t("Select All")}</label>
                              </div>
                              <label>{countrychecked.length}/{countryfilter.length} {t("Selected")}</label>
                            </div>
                            <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                              {countryfilter?.length ? countryfilter.map((result, key) => {
                                return (
                                  <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                    <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={result?.id}
                                      checked={
                                        countrychecked ? countrychecked.includes(result?.id) : ""
                                      }
                                      onChange={(e) => handlefilerChange(e, result, "country")} />
                                    <label className="form-check-label cursor-pointer" htmlFor={result?.id}>{result?.name}</label>
                                  </li>
                                )
                              }) : ""}

                            </ul>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2"
                          >
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                              onClick={() => { setcountrychecked([]); setShortobj({}); hendledropdownhide("country") }}>{t("Clear")}</button>
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { getsalesperson(); hendledropdownhide("country") }}> {("Apply")}</button>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th>{t("Phone No.")} </th>
                    <th>{t("Location")}
                      <div className="dropdown d-inline-block com-custom-table-tag-filter">
                        <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                          <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                        </button>
                        <div className="dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"
                          ref={dropdownReflocation}>
                          <div className="asc-des-order">
                            <div onClick={() => { setShortobj({ key: "location", value: 1 }); hendledropdownhide("location") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                            <div onClick={() => { setShortobj({ key: "location", value: -1 }); hendledropdownhide("location") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-search">
                            <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                              <img src={base_assets + "images/icons/search-icon.png"} alt=""
                              />
                              <input className="border-0" type="text" placeholder="Search"
                                onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "location")}
                                onChange={(e) => setSearchVal(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-select">
                            <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                              <div>
                                <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="location"
                                  onChange={(e) => handleCheckAllChange(e, "location")}
                                  checked={locationchecked.length === locationfilter.length} />
                                <label className="form-check-label cursor-pointer fw-semibold" htmlFor="location">{t("Select All")}</label>
                              </div>
                              <label>{locationchecked?.length}/{locationfilter?.length} {t("Selected")}</label>
                            </div>
                            <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                              {locationfilter?.length ? locationfilter.map((result, key) => {
                                return (
                                  <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                    <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox"
                                      id={result?._id}
                                      onChange={(e) => handlefilerChange(e, result, "location")}
                                      checked={
                                        locationchecked ? locationchecked.includes(result?._id) : ""
                                      } />
                                    <label className="form-check-label cursor-pointer" htmlFor={result?._id}>{result?.name}</label>
                                  </li>
                                )
                              }) : ""}
                            </ul>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2">
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                              onClick={() => { setLocationchecked([]); setShortobj({}); hendledropdownhide("location") }}>{t("Clear")}</button>
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { getsalesperson(); hendledropdownhide("location") }}> {("Apply")}</button>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th>{t("Commission")} (%) </th>
                    <th>{t("Last Modified Date")}</th>
                    <th>{t("Status")}
                      <div className="dropdown d-inline-block com-custom-table-tag-filter">
                        <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                          <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                        </button>
                        <div className="dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"
                          ref={dropdownRefstatus}
                        >
                          <div className="asc-des-order">
                            <div onClick={() => { setShortobj({ key: "Status", value: -1 }); hendledropdownhide("status") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                            <div onClick={() => { setShortobj({ key: "Status", value: 1 }); hendledropdownhide("status") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-select">
                            <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                              {statusfilter?.length ? statusfilter.map((result, key) => {
                                return (
                                  <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                    <input className="fill-com-check form-check-input radio d-inline-block me-2 cursor-pointer" name="mixmatchradio" type="radio" id={"status" + key}
                                      onClick={(e) => setStatuschecked(result?.value)} />
                                    <label className="form-check-label cursor-pointer" htmlFor={"status" + key}>{result?.lable}</label>
                                  </li>
                                )
                              }) : ""}

                            </ul>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2">
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                              onClick={(e) => { setStatuschecked(""); setShortobj({}); hendledropdownhide("status") }} >{t("Clear")}</button>
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { getsalesperson(); hendledropdownhide("status") }}> {("Apply")}</button>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {salespersonlist?.length ? salespersonlist.map((result, key) => {
                    return (
                      <tr className="text-nowrap" key={key}>
                        <td className="pe-0">
                          <div className="com-check">
                            <input id={result?._id} type="checkbox"
                              onChange={(e) => handlefilerChange(e, result?._id, "export")}
                              checked={
                                exportid ? exportid.includes(result?._id) : ""
                              } />
                            <label htmlFor={result?._id} className="com-check-label" />
                          </div>
                        </td>
                        <td>{key + 1}</td>
                        <td className="px-2">
                          <img className="table-user-img rounded-circle" src={result?.profile ? result?.profile : base_assets + "/images/icons/customer-card-icon.png"} alt="" />
                        </td>
                        {parmissiondatashow?.Master?.Edit ?
                          <td className="link-color cursor-pointer" onClick={() => { setEditItem(true); setDataid(result?._id); setCreate(false); handleScrollToThetop() }} >{result?.name ? result?.name : "-"}</td> :
                          <td>{result?.name ? result?.name : "-"}</td>
                        }
                        <td>{result?.position ? result?.position : "-"}</td>
                        <td>{result?.country ? result?.country : "-"}</td>
                        <td>{result?.contacts[0].code ? "+" : ""}{result?.contacts[0].code ? `${result?.contacts[0]?.code}` : ""}{result?.contacts[0]?.no ? -result?.contacts[0]?.no : ""} </td>
                        <td>{result?.location ? result?.location : "-"}</td>
                        <td>{result?.commission?.percentage ? result?.commission?.percentage : "-"}</td>
                        <td>{result?.updated ? result?.updated : "-"}</td>
                        <td>
                          {result?.Status === "1" ?
                            <span className="active-badge  rounded-pill px-2 py-1 fw-semibold"> <span className="active-badge-dot me-2"></span>{t("Active")}</span>
                            :
                            <span className="inactive-badge  rounded-pill px-2 py-1 fw-semibold">
                              <span className="inactive-badge-dot me-2"></span>{t("InActive")}</span>
                          }

                        </td>
                        <td className="text-center">
                          <img
                            onClick={() => { setEditItem(true); setDataid(result?._id); setCreate(false); handleScrollToThetop() }}
                            className={parmissiondatashow?.Master?.Edit ? "cursor-pointer me-4" : "d-none"}
                            src={base_assets + "images/admin/icons/Edit.png"} alt="" />
                          <img
                            data-bs-toggle="modal" data-bs-target="#createPurchaseModal"
                            onClick={(e) => setDataid(result?._id)}
                            className={parmissiondatashow?.Master?.Delete ? "cursor-pointer" : "d-none"}
                            src={base_assets + "images/admin/icons/Delete.png"} alt="" />
                        </td>
                      </tr>
                    )
                  }) :
                    <tr>
                      <td colSpan="50" className="text-center"> {t("No Data Available In Table")}</td>
                    </tr>
                  }

                </tbody>
              </table>
              {showloader && <TableLoader />}
            </div>
            {salespersonlist?.length > 0 ? (
              <Pagination
                total={totalPages}
                current={currentPage}
                pagination={(crPage) => handlePaginations(crPage)}
              />
            ) : (
              ""
            )}
          </div>
          <div className={editItem ? "col-md-6 d-block" : "d-none"} ref={salepersonListRef}>
            <EditSalesPerson setEditItem={setEditItem}
              dataid={dataid}
              setEditSales={setEditSales}
              create={create}
              setDataid={setDataid}
              setCreate={setCreate}
            />
          </div>
        </div>
        <Confirmationmodel statement={"Delete"} handler_submit={salespersondelete} ></Confirmationmodel>
        <Importmodal
          setImportfile={setImportfile}
          readExcel={readExcel}
          importfile={importfile}
          SampleExcel={SampleExcel} />
      </div>
    </>
  )
}

export default MasterSalesPerson;