import React, { useEffect, useRef, useState } from "react";
import ImageGallery from 'react-image-gallery';
import Select from "react-select";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RecentViewProducts from "Ecommerce/template_three/components/RecentViewProducts";
import CollectionSlider from "Ecommerce/template_three/include/Common/CollectionSlider";
import HeartIcon from "admin/common/icons/HeartIcon";
import MinusIcon from "Ecommerce/common/icons/MinusIcon";
import PlusIcon from "Ecommerce/common/icons/PlusIcon";
import { _Api } from "Ecommerce/common/api";
import AddCartModal from "Ecommerce/template_three/components/Cart/AddCartModal";
import CartItemsNotify from "Ecommerce/template_three/components/Cart/CartItemsNotify";
import SizeGuildeModal from "Ecommerce/template_three/components/SizeGuilde/SizeGuildeModal";
import Spinner from "Ecommerce/common/Spinner";
import { updateTemplateTwoTabsData, wishlistobjectheader } from "source/app/appSlice"
import { toFormatPrice } from "Ecommerce/common/Formateprice";
import LeftArrow from "Ecommerce/common/icons/LeftArrow";

export default function ProductPage({productPageFilters,productDetail, getProductFilters, setGetProductFilters, activeProductId, setShowEngravingPage, setShowProductDetailPage, setActiveTab, flowMode, setProductdetail, engravingData, setEngravingData,  diamondProduct }) {
    const dispatch = useDispatch();
    const { state } = useLocation()
    const { t } = useTranslation();
    const [activeThumbnail, setActiveThumbnail] = useState(1);
    const [showdropDown, setShowDropdown] = useState("")
    const [productdetails, setProductdetails] = useState(productDetail?.productdetails)
    const [variantcombo, setVariantcombo] = useState({ metal: '18KBK', stone: '', size: '', shape: '' });
    const [getDataOfproduct, setGetDataOfproduct] = useState({metal:[], shape:[], stone:[], size:[]});
    const fullPath = useLocation();
    const [productpageSelections, setProductpageSelections] = useState(productDetail?.productSelection ? productDetail?.productSelection : {metal: {}, shape: {}, size: {}, stone: {}, pointer:{}})
    const parts = fullPath.pathname.split('/');
    const params = new URLSearchParams(fullPath.search);
    const vValue = params.get('v');
    let localmywishlist = localStorage.getItem('mywishlist') ? JSON.parse(localStorage.getItem('mywishlist')) : [];
    let localmywishlistremove = localStorage.getItem('mywishlistremove') ? JSON.parse(localStorage.getItem('mywishlistremove')) : [];
    const [variantdata, setVarientdata] = useState([])
    const [varientloading, setVarientloading] = useState(false)
    const [wishlistdata, setWishliatdata] = useState({ id: "", remove: false, edit: false })

    const [activePointersArray, setActivePointersArray] = useState([]);
    const productpageref = useRef();
    useEffect(()=>{
        if (productpageref.current) {
            productpageref.current.scrollIntoView({ behavior: 'smooth' });
          }
        getSelectOptions()
        console.log(productdetails, "Check product details")
    },[])

    const setwishlist = () => {
        let setobj = { ...productdetails }
        if (setobj.isWishlist) {
            setobj.isWishlist = 0
            let removewislistarr = [...localmywishlistremove]
            removewislistarr.push(setobj?._id)
            const uniqueIdsSet = new Set(removewislistarr);
            let unique_ids = Array.from(uniqueIdsSet);
            localStorage.setItem('mywishlistremove', JSON.stringify(unique_ids ? unique_ids : []));
            if (localmywishlist?.length) {
                const updatedData = localmywishlist.filter(id => id !== setobj?._id);
                localStorage.setItem('mywishlist', JSON.stringify(updatedData ? updatedData : []));
                dispatch(wishlistobjectheader(updatedData));
            }
        } else {
            setobj.isWishlist = 1
            let addwislistarr = [...localmywishlist] 
            addwislistarr.push(setobj?._id)
            const uniqueIdsSet = new Set(addwislistarr);
            let unique_ids = Array.from(uniqueIdsSet);
            localStorage.setItem('mywishlist', JSON.stringify(unique_ids ? unique_ids : []));
            dispatch(wishlistobjectheader(unique_ids));
            if (localmywishlistremove?.length) {
                const updatedData = localmywishlistremove.filter(removeid => removeid !== setobj?._id);
                localStorage.setItem('mywishlistremove', JSON.stringify(updatedData ? updatedData : []));
            }
        }
        setProductdetails(setobj)
    }

    const getvarient = async () => {
        setVarientloading(true)
        try {
            let postdata = {
                id: parts[2],
                "wishlistproduct": localmywishlist,
                "wishlistremove": localmywishlistremove,
            }
            let res = await _Api(postdata, `product/get-product-variants`)
            if (res?.statusCode === 200) {
                setVarientdata(res?.data)
                if (vValue) {
                    if (res?.data?.length) {
                        res?.data?.forEach((result) => {
                            if (vValue === result?._id) {
                                const combo = { metal: result?.metal_name ? result?.metal_name : '', stone: result?.stone_name ? result?.stone_name : '', size: result?.size_name ? result?.size_name : '' }
                                setVariantcombo(combo);
                            }
                        });
                    }
                }
                setVarientloading(false)
            } else {
                setVarientloading(false)
            }
        }
        catch (err) {
            console.log(err, "err")
        }

    }

    const combination_variant = (variant, data) => {
        let variant_sku = '';
        if (variant === 'metal') {
            variant_sku = productdetails?.SKU + data?.value + (variantcombo?.stone ? variantcombo?.stone?.value : '') + '-' + (variantcombo?.size ? variantcombo?.size?.value : '');
            setVariantcombo({ ...variantcombo, metal: data });
            setProductpageSelections((state)=>({...state, "metal" : data}))
        }
        if (variant === 'stone') {
            variant_sku = productdetails?.SKU + (variantcombo?.metal ? variantcombo?.metal?.value : '') + data?.value + '-' + (variantcombo?.size ? variantcombo?.size?.value : '');
            setVariantcombo({ ...variantcombo, stone: data });
            setProductpageSelections((state)=>({...state, "stone" : data}))
        }
        if (variant === 'size') {
            setProductpageSelections((state)=>({...state, size: data}))
            // variant_sku = productdetails?.SKU + (variantcombo?.metal ? variantcombo?.metal?.value : '') + (variantcombo?.stone ? variantcombo?.stone?.value : '') + '-' + data?.value;
            // setVariantcombo({ ...variantcombo, size: data });
        }
        if(variant === 'shape'){
           if(productpageSelections?.shape?.shapeName !== data?.shapeName){
            setProductpageSelections((state)=>({...state, "shape" : data}));
            let pointerdata = [];
            data?.prices?.map((item)=>{
                pointerdata.push({label: item?.point, value: item?.price})
            })
            console.log(data, pointerdata, "check data")
            setActivePointersArray(pointerdata)
            setProductpageSelections((state)=>({...state, "pointer" : pointerdata?.[0]}));
           }
        }
        if(variant === 'pointer'){
            console.log(data, "checkdata")
            setProductpageSelections((state)=>({...state, "pointer" : data}));
            setProductdetails((state)=>({...state, "price" : data?.value}))
        }   
    }

    useEffect(() => {
        // if (state?.id || parts[2]) {
            // getproductdetails();
            getvarient()
        // } else {
        //     navigate("/");
        // }
        // eslint-disable-next-line 
    }, [vValue, parts[2]])



const handleSaveData = () =>{
        setGetProductFilters(productPageFilters);
        setShowProductDetailPage(false);
        if( diamondProduct !== undefined ){
            setActiveTab('Final')
        }else{
            setActiveTab('Diamond')
        }
        setProductdetail({"productdetails" : productdetails, "productSelection" : productpageSelections})
        console.log(productDetail, "check your proudct detal", productpageSelections );
    }

const handleEngravingClick = () =>{
    setGetProductFilters(productPageFilters);
    setShowProductDetailPage(false);
    setProductdetail({"productdetails" : productdetails, "productSelection" : productpageSelections})
   
    setShowEngravingPage(true);
}


const handlePlaceComplete = () =>{
    setActiveTab("Final")
}


const getSelectOptions = () =>{
    if(productdetails?.metal_value?.length){
        productdetails?.metal_value.map((item)=>{
            getDataOfproduct?.metal.push({label: item?.name, value: item?._id})
        })
        setProductpageSelections((state)=>({...state, "metal" : getDataOfproduct?.metal?.[0]}));
    }
    if(productdetails?.stone_value?.length){
        productdetails?.stone_value?.map((item)=>{
            getDataOfproduct?.stone.push({label: item?.name, value: item?._id})
        })
        setProductpageSelections((state)=>({...state, "stone" : getDataOfproduct?.stone?.[0]}));
    }
    if(productdetails?.pointers?.length){
        setProductpageSelections((state)=>({...state, "shape" : productdetails?.pointers?.[0]}));
        
        let pointerdata = [];
        productdetails?.pointers?.[0]?.prices?.map((item)=>{
            pointerdata.push({label: item?.point, value: item?.price})
        })
        console.log(pointerdata, "check data")
        setActivePointersArray(pointerdata);
        setProductpageSelections((state)=>({...state, "pointer" :  pointerdata?.[0]}))
    }
}
const handleThubnailclick = (i) =>{
    setActiveThumbnail(i+1)
}
  
    return (
        <>
        <div className="ecommerce-main-layout" ref={productpageref}>
        <div className='mix_match_page-wrapper'>
         
            </div>
            <div className="mix_match-product-details">
                <div className="mix_match-product-details-image-section">
                    <ImageGallery
                        items={productdetails?.images ? productdetails?.images : []}
                        showPlayButton={false}
                        showFullscreenButton={false}
                        showNav={true}
                        infinite={true}
                        disableSwipe={false}
                        disableThumbnailSwipe={false}
                        additionalClass="img-view-slider"
                        onSlide={(e)=> setActiveThumbnail(e+1)}
                        onThumbnailClick={(e, index) => handleThubnailclick(index)}
                    />
                    <div className="mix_match-product-details-image-section-progressbar">
                        <progress className="mix_match-product-details-image-section-progressbar-container" max={productdetails?.images.length} value={activeThumbnail}></progress>
                    </div>
                </div>
                <div className="mix_match-product-details-section">
                    <div className="mix_match-product-details-section-product-details">
                        <div className={`mix_match-product-details-section-product-details-name ${productdetails?.isWishlist ? "likeHeartBtn" : ""}`}>
                            <span>{productdetails?.name}</span>
                            <div className="d-inline-block" onClick={() => setwishlist()}>
                            {productdetails?.isWishlist ?
                                    <i onClick={() => {
                                        setWishliatdata((state) => ({
                                            ...state,
                                            id: productdetails?._id,
                                            add: false,
                                            remove: true
                                        }))
                                    }} className="fa fa-sharp fa-solid fa-heart text-danger" style={{ fontSize: "20px" }}></i> :
                                    <span
                                        onClick={() => {
                                            setWishliatdata((state) => ({
                                                ...state,
                                                id: productdetails?._id,
                                                add: true,
                                                remove: false
                                            }))
                                        }
                                        }>   <HeartIcon color={"red"} width={24} height={24} /></span>}
                                {/* <HeartIcon width="26px" height="24px"/> */}
                            </div>
                        </div>
                        <span className="mix_match-product-details-section-product-details-serialNumber">
                            {productdetails?.SKU}
                        </span>
                        <div className="mix_match-product-details-section-product-details-collectionName d-flex justify-content-between align-items-center">
                            {productdetails?.Matatag}
                        </div>
                    </div>
                    <span className="mix_match-product-details-section-product-price " >
                        {productpageSelections?.pointer?.value ?  toFormatPrice(productdetails?.price, { addSymbol: true }) : productdetails?.price} 
                    </span>
                    {varientloading ? (
                        <div style={{ marginTop: '20px', marginBottom: '20px' }} >
                        <Spinner />
                    </div>) : (
                        <div>
                            {productdetails?.metal_value?.length ?
                            
                                <div className="mix_match-product-details-section-product-attributes">
                                    <label>{t("Metal")} : <span>{variantcombo?.metal?.value === '18KBK' ? 'Silver' : ""}</span></label>
                                    {/* {getDataOfproduct?.metal?.map((item, i)=>{
                                        return(
                                            <div>{item.label}</div>
                                        )
                                    })
                                    } */}
                            <div className="productpage-product-details-section-product-colorthemewrapper">
                                  <div className={`black ${variantcombo?.metal?.value === '18KBK' ? 'active' : ''}`}  onClick={() => combination_variant('metal', {label: "18KBK",value: "18KBK"})}></div>
                                  <div className={`titanium ${variantcombo?.metal?.value === '18KWG' ? 'active' : ''}`} onClick={() => combination_variant('metal', {label: "18KWG",value: "18KWG"})}></div>
                                  <div className={`sunshine ${variantcombo?.metal?.value === '18KYG' ? 'active' : ''}`} onClick={() => combination_variant('metal', {label: "18KYG",value: "18KYG"})}></div>
                                  <div className={`peach ${variantcombo?.metal?.value === '18KRG' ? 'active' : ''}`} onClick={() => combination_variant('metal', {label: "18KRG",value: "18KRG"})}></div>
                            </div>
                           
                                    {/* <Select
                                        className="ecommerce-dropdown"
                                        options={getDataOfproduct?.metal}
                                        value={productpageSelections?.metal}
                                        onChange={(e) => combination_variant('metal', e)}
                                        classNamePrefix="common-select"
                                        isSearchable={false}
                                    /> */}
                                </div>
                                : ''}
                            {productdetails?.stone_value?.length ?
                                <div className="mix_match-product-details-section-product-attributes">
                                    <label>{t("Stone")}</label>
                                    <Select
                                        className=""
                                        options={getDataOfproduct?.stone}
                                        value={productpageSelections?.stone}
                                        onChange={(e) => combination_variant('stone', e)}
                                        classNamePrefix="newDropdown"
                                        isSearchable={false}

                                    />
                                </div>
                                : ""}

                                {productdetails?.size_value?.length ?
                                <>
                                    <div className="mix_match-product-details-section-product-attributes">
                                        <div className="mix_match-product-details-section-product-attributes-size">
                                            <label>{t("Size")}</label>
                                            <button style={{ border: 'none', background: 'none' }} data-bs-toggle="modal" data-bs-target="#sizeGuideBack" className="size-guide">{t("Size Guide")} <span className="size-guide-imgBox">
                                                 {/* <img src={ArroeIcontop} alt="" /> */}
                                                 </span></button>
                                        </div>
                                        <div className="mix_match-product-details-section-product-attributes-ringSizes-container d-flex flex-wrap align-items-center">
                                            { productdetails?.size_value.map((result, key) => {
                                                return (
                                                    <div key={key} onClick={() => combination_variant('size', result)} className={`mix_match-product-details-section-product-attributes-ringSizes-container-sizecube ${productpageSelections?.size?.name === result?.name ? "selectedsize" : ""} ${result?.name === '57' ? 'canceled' : ''}`}>
                                                        {result?.name === '57' ? <div className="horizontal"> </div> : "" } {result?.name}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </>
                                : ""}


                                {
                                    productdetails?.pointers?.length > 0 ?
                                    <div className="mix_match-product-details-section-product-attributes">
                                    <label>{t("Stone Shape")}</label>
                                    <div className="mix_match-product-details-section-product-attributes-stoneshape">
                                    {productdetails?.pointers?.map((shapes,i)=>{
                                        return(
                                        <div className={`mix_match-product-details-section-product-attributes-stoneshape-diamond ${productpageSelections?.shape?.shapeName === shapes.shapeName ? 'active' : ""}`} onClick={()=>combination_variant('shape', shapes)} key={i}>
                                            <div className='mix_match-product-details-section-product-attributes-stoneshape-diamond-imgholder'><img src={shapes.shapeImage} alt={shapes.shapeName}/></div>
                                            <span className=''>{shapes.shapeName}</span>
                                        </div>
                                        )
                                    })}
                                    </div>
                                </div>
                                : ""
                                }
                                   
                                    {
                                        activePointersArray.length ?
                                        <div className="mix_match-product-details-section-product-attributes">
                                        <label>{t("Pointer")}</label>
                                        <Select
                                            className=""
                                            options={activePointersArray}
                                            value={productpageSelections?.pointer}
                                            onChange={(e) => combination_variant('pointer', e)}
                                            classNamePrefix="newDropdown"
                                            isSearchable={false}
                                        />
                                        </div>
                                        : ""
                                    }
                                    

                           
                        </div>
                    )}

                    <div className="mix_match-product-details-section-buttons">

                        <div 
                        className="mix_match-product-details-section-buttons-backtosetting"
                        onClick={()=>setShowProductDetailPage(false)}
                        >
                            <span className="mix_match-product-details-section-buttons-backtosetting-img">
                               <LeftArrow/>
                                </span> <span className="mix_match-product-details-section-buttons-backtosetting-title">{t("Back to Settings")}</span>
                        </div>

                        { flowMode === 'fromProduct' ?
                            <div 
                            onClick={handleSaveData}
                                // onClick={addtocart} 
                                className={`mix_match-product-details-section-buttons-addToCartBtn ${productpageSelections?.metal?.label && productpageSelections?.size?.name && productpageSelections?.pointer?.label && productpageSelections?.stone?.label && productpageSelections?.shape?.shapeName ? 'active' : "disabled"}`}>
                                    {t("Select Setting")}
                            </div>
                        : ""}
                    </div>
                    {flowMode === 'fromDiamond' ?  engravingData ? 
                    <button onClick={handlePlaceComplete} className={`mix_match-product-details-section-buttons-addToCartBtn ${productpageSelections?.metal?.label && productpageSelections?.size?.name && productpageSelections?.pointer?.label && productpageSelections?.stone?.label && productpageSelections?.shape?.shapeName ? 'active' : "disabled"}`}>
                    {t("Place Complete")}
                    </button> :
                    <button className={`diamond-product-details-section-engravingbtn ${productpageSelections?.metal?.label && productpageSelections?.size?.name && productpageSelections?.pointer?.label && productpageSelections?.stone?.label && productpageSelections?.shape?.shapeName ? 'active' : "disabled"}`} onClick={()=>handleEngravingClick()}>
                        {t("Engraving")}
                    </button>
                    : ""}
                    <div className={`mix_match-product-details-section-product-discription border-line`}>
                        <div className="title">
                            <span>{t("Description")}</span>
                            {/* {showdropDown === 'Description' ? <MinusIcon width={20} height={20} /> :
                                <PlusIcon width={20} height={20} />} */}
                        </div>
                        
                            <div className="mix_match-product-details-section-product-discription-text">
                                {productdetails?.Description}
                            </div> 
                       
                    </div>

                    <div className={`mix_match-product-details-section-product-productdetails`}>
                        <div className="title" onClick={() => setShowDropdown('Product')}>
                            <label>{t("Product Details")}</label>
                           
                        </div>
                        {showdropDown === 'Product' ? 
                        <div className="mix_match-product-details-section-product-productdetails-inner">
                            <div className="mix_match-product-details-section-product-productdetails-inner-item">
                                <span className="mix_match-product-details-section-product-productdetails-inner-item-product">{t("Material")}</span>
                                <span className="mix_match-product-details-section-product-productdetails-inner-item-data">{t("18K Gold")}</span>
                            </div>
                            <div className="mix_match-product-details-section-product-productdetails-inner-item">
                                <span className="mix_match-product-details-section-product-productdetails-inner-item-product">{t("Stone Weight")}</span>
                                <span className="mix_match-product-details-section-product-productdetails-inner-item-data">{t("0.35 Cr")}</span>
                            </div>
                            <div className="mix_match-product-details-section-product-productdetails-inner-item">
                                <span className="mix_match-product-details-section-product-productdetails-inner-item-product">{t("Reference Number")}</span>
                                <span className="mix_match-product-details-section-product-productdetails-inner-item-data">{t("CLEO-R71")}</span>
                            </div>
                        </div> : null}
                    </div>
                </div>
            </div>

            {/* <div className="ecommerce-main-layout-collection">
                <CollectionSlider />
            </div> */}
            
            {/* <AddToCartModal setisopencart={setisopencart} isopencart={isopencart} />
            {isopennotifycart ? <CartNotification isopennotifycart={isopennotifycart} setIsopennotifycart={setIsopennotifycart} setisopencart={setisopencart} /> : ''} */}
            <SizeGuildeModal />
        </div >
    {/* )} */}
</>
  )
}
