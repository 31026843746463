import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

const CheckoutForm = () => {
    const [loading, setLoading] = useState(false);
    const stripePromise =
        loadStripe('pk_test_51M35bVDJsj5ubp2uc1u3ci6PyZfYj6W4OB2btMoD07tn54QJ6jcUHHP93WaCMQAYKhRjODIym5evPezhrt9hDSqj00wQaRIVEM');

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);

        const stripe = await stripePromise;
        const elements = stripe.elements();

        const cardElement = elements.getElement('card');

        const { error } = await stripe.createToken(cardElement);

        if (error) {
            console.error(error);
        }
        setLoading(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div id="card-element">
                {/* A Stripe Element will be inserted here. */}
            </div>

            {/* Used to display form errors. */}
            <div id="card-errors" role="alert"></div>

            <button type="submit" disabled={loading}>
                {loading ? 'Processing...' : 'Submit Payment'}
            </button>
        </form>
    );
};

export default CheckoutForm;
