export const dataListExample = [
    {
        "createdAt": "2024-01-12T00:44:18.448Z",
        "updatedAt": "2024-01-12T01:00:19.084Z",
        "__v": 0,
        "location_id": "634f6d59ae461466b73d0959",
        "supplier_id": "637f235eae461466b73d09ff",
        "po_no": "HQPO-0323",
        "voucher_id": "647d9ca6f358a963864566a1",
        "delivery_date": "09/02/2024",
        "sales_order_id": "",
        "parentproduct_id": "6568539897b0da5e821c3f1e",
        "product_id": "6568539997b0da5e821c3f9d",
        "so_no": "",
        "labor": 300,
        "remark": "",
        "thumb": "https://gis247.s3.us-east-2.amazonaws.com/2022th0038/products/image/SB03218KYGDM-41.png",
        "main_image": "https://gis247.s3.us-east-2.amazonaws.com/2022th0038/products/image/SB03218KYGDM-41.png",
        "oldStock_id": "",
        "po_QTY": 1,
        "po_QTY_noChange": 1,
        "price": "฿ 31,800.00",
        "product_type": "variant",
        "is_product": 0,
        "is_variant": 0,
        "is_design": 1,
        "SKU": "SB03218KYGDM-45",
        "name": "C-CLASSIC PAVE BAND SOLITAIRE COLLECTION",
        "Matatag": "C-CLASSIC PAVE BAND SOLITAIRE COLLECTION",
        "Description": "“Signature Air Lily” pavé diamond engagement ring represents our proudest masterpiece that inherits a significant inspiration from the delicate rare wildflowers with a glamorous twist. The 2.5mm band design with special blend 18k gold setting gently holds the round diamond center of your carat choice. Meticulously crafted, the gold wires from the band weave to create 6 floral prongs, and our artist heightens each side of the ring – forming the overall ring into pear shape. This diamond solitaire is further adorned with small 24 diamonds in total of 0.07ct on the setting. Therefore, when viewed through the finger, you can experience the utmost beauty both from the diamond bridge and the dazzling diamond’s floral crown.",
        "ID": "100061-5",
        "Item": "6355f7a8bc8a8f3720133d21",
        "Collection": "636b28ed1b6e0807473865fa",
        "Style": "6355f8d0243c42797d24c532",
        "Metal": "636d1f451b6e080747386611",
        "Color": "6355f957ae461466b73d096b",
        "Size": "636c6ea32572146aa404d30b",
        "Unit": "Pcs",
        "product_variants_enable": "2",
        "variant_Metal_value": [
            {
                "Status": 1,
                "id": [
                    "636d1f451b6e080747386611",
                    "6355fbddc734e545a850dd02",
                    "6355fbaee8fbcc2c3a13b961",
                    "6355fb99243c42797d24c534"
                ]
            }
        ],
        "variant_Size_value": [
            {
                "Status": 1,
                "id": [
                    "63806f69a47d7c17742612ee",
                    "636c6e81f358a963864565b6",
                    "636c6e8d17ae947408110ea6",
                    "636c6e94c734e545a850dd4b",
                    "636c6ea32572146aa404d30b",
                    "636c6eabdcf7ec0c616fea60",
                    "6355fc9fae461466b73d096d",
                    "6355fcd017ae947408110e67",
                    "6355fce6c0d74d7f332ea98b",
                    "6355fe00bc8a8f3720133d27",
                    "6355fe0828021528d413c078",
                    "635f7db31b6e0807473865d7",
                    "636c6ec517ae947408110ea8",
                    "636c6ed0c734e545a850dd4d",
                    "636c6ede708cc962f67dbd94",
                    "636c6ee6f358a963864565b8",
                    "636c6f222572146aa404d30f",
                    "636c6f2ab645d00d7b187612",
                    "636c6f36ae461466b73d09ad",
                    "636c6ea4708cc962f67dbd92",
                    "636c6ebc044ba54bde4d2a41",
                    "636c6ec7a47d7c17742612af",
                    "636c6ed72572146aa404d30d",
                    "636c6ee0b645d00d7b187610",
                    "636c6ee8ae461466b73d09ab",
                    "636c6ef0bc8a8f3720133d59",
                    "636c6efde8fbcc2c3a13b994",
                    "636c6f1dc734e545a850dd4f",
                    "636c6f26708cc962f67dbd96",
                    "636c6f30f358a963864565ba",
                    "636c6f37bc8a8f3720133d5b",
                    "636c6f3fe8fbcc2c3a13b996"
                ]
            }
        ],
        "variant_Stone_value": [
            {
                "Status": 1,
                "id": [
                    "6356061d2572146aa404d2d2"
                ]
            }
        ],
        "GrossWt": "5.00",
        "NetWt": "5.00",
        "Stones": [
            {
                "stone_group": "63560539dcf7ec0c616fea1f",
                "stone": "6356061d2572146aa404d2d2",
                "Variant": "2",
                "Align": "",
                "shape": "63560779c0d74d7f332ea992",
                "Cut": "635607932572146aa404d2d6",
                "quality": "",
                "clarity": "636c7b71671a877a9c779c81",
                "color": "636c7c4a28021528d413c0b9",
                "Size": "63560917044ba54bde4d2a16",
                "Unit": "Cts",
                "Pcs": "1",
                "Cts": "0.20",
                "Price": "20000",
                "Pointer": "0.20",
                "setting_type": "636c8418bc8a8f3720133d64",
                "certificate": {
                    "type": "",
                    "number": ""
                },
                "size_name": "3.80MM",
                "stone_name": "Diamond",
                "shape_name": "Brilliant Round",
                "clarity_name": "VS2",
                "color_name": "J",
                "setting_name": "Solitaire",
                "cut_name": "Heart & Arrow"
            },
            {
                "stone_group": "63560539dcf7ec0c616fea1f",
                "stone": "6356061d2572146aa404d2d2",
                "Variant": "0",
                "Align": "",
                "shape": "63560779c0d74d7f332ea992",
                "Cut": "6375cf6b3c04c604447519ea",
                "quality": "",
                "clarity": "636c7b71671a877a9c779c81",
                "color": "636c7c4a28021528d413c0b9",
                "Size": "63560917044ba54bde4d2a16",
                "Unit": "Cts",
                "Pcs": "100",
                "Cts": "0.20",
                "Price": "0.00",
                "Pointer": "0",
                "setting_type": "635648d4c0d74d7f332ea994",
                "certificate": {
                    "type": "",
                    "number": ""
                },
                "size_name": "3.80MM",
                "stone_name": "Diamond",
                "shape_name": "Brilliant Round",
                "clarity_name": "VS2",
                "color_name": "J",
                "setting_name": "Pave",
                "cut_name": "Normal Cut"
            },
            {
                "stone_group": "63560539dcf7ec0c616fea1f",
                "stone": "6356061d2572146aa404d2d2",
                "Variant": "0",
                "Align": "",
                "shape": "63560779c0d74d7f332ea992",
                "Cut": "6375cf6b3c04c604447519ea",
                "quality": "",
                "clarity": "636c7b8928021528d413c0b7",
                "color": "636c7c4a28021528d413c0b9",
                "Size": "63858e2ec0d74d7f332eaa15",
                "Unit": "Cts",
                "Pcs": "2",
                "Cts": "0.20",
                "Price": "0.00",
                "Pointer": "0",
                "setting_type": "635648d4c0d74d7f332ea994",
                "certificate": {
                    "type": "",
                    "number": ""
                },
                "size_name": "1.25MM",
                "stone_name": "Diamond",
                "shape_name": "Brilliant Round",
                "clarity_name": "VVS2",
                "color_name": "J",
                "setting_name": "Pave",
                "cut_name": "Normal Cut"
            }
        ],
        "OtherDetails": [
            {
                "location": "634f7bf72572146aa404d2c5",
                "currentstock": 0,
                "availablestock": 0,
                "reorderpoint": 1,
                "reorderquantity": 1
            },
            {
                "location": "634f6d59ae461466b73d0959",
                "currentstock": 0,
                "availablestock": 0,
                "reorderpoint": 1,
                "reorderquantity": 1
            },
            {
                "location": "634f716bb645d00d7b1875d2",
                "currentstock": 0,
                "availablestock": 0,
                "reorderpoint": 1,
                "reorderquantity": 1
            },
            {
                "location": "634f7f95c734e545a850dcf1",
                "currentstock": 0,
                "availablestock": 0,
                "reorderpoint": null,
                "reorderquantity": null
            }
        ],
        "PriceDetails": [
            {
                "location": "634f7bf72572146aa404d2c5",
                "currency": "THB",
                "taxrate": 0,
                "tax": 0,
                "retailprice_Inc": "31800"
            },
            {
                "location": "634f6d59ae461466b73d0959",
                "currency": "THB",
                "taxrate": 0,
                "tax": 0,
                "retailprice_Inc": "31800"
            },
            {
                "location": "634f716bb645d00d7b1875d2",
                "currency": "THB",
                "taxrate": 0,
                "tax": 0,
                "retailprice_Inc": "31800"
            },
            {
                "location": "634f7f95c734e545a850dcf1",
                "currency": "THB",
                "taxrate": 0,
                "tax": 0,
                "retailprice_Inc": null
            }
        ],
        "extra_po_information": "",
        "status": {
            "po_status": 1,
            "priority": 1,
            "warehouse_status": 2,
            "pu_status": 1,
            "Inventorylist_status": 1,
            "stock_transferStartendStatus": 0,
            "st_status": 4,
            "has_stocktransfer": 1,
            "is_stock_transfer_started": 0,
            "is_show_in_inventory": 1,
            "stock_transferStatus": 4
        },
        "UDID": "2024170502059698302787436",
        "user_actions": {
            "created_by": "636c6137f358a963864565b4",
            "updated_by": "636c6137f358a963864565b4"
        },
        "main_po_product_id": "65a08b62f3ff48b822055c1f",
        "pu_order_id": "65a08cb2f3ff48b8220568d9",
        "stock_id": "100",
        "warehouse_id": "",
        "transfer_from_loc": "634f716bb645d00d7b1875d2",
        "po_parentproduct_id": "65a08cb4f3ff48b822056ab3",
        "stock_transfer": {
            "transfer_from_loc": "634f716bb645d00d7b1875d2",
            "transfer_to_location": "634f6d59ae461466b73d0959",
            "old_location_id": "634f6d59ae461466b73d0959"
        },
        "now": "STOCK",
        "Stones_Data": [
            {
                "stone_group": "63560539dcf7ec0c616fea1f",
                "stone": "6356061d2572146aa404d2d2",
                "Variant": "2",
                "Align": "",
                "shape": "63560779c0d74d7f332ea992",
                "Cut": "635607932572146aa404d2d6",
                "quality": "",
                "clarity": "636c7b71671a877a9c779c81",
                "color": "636c7c4a28021528d413c0b9",
                "Size": "63560917044ba54bde4d2a16",
                "Unit": "Cts",
                "Pcs": "1",
                "Cts": "0.20",
                "Price": "20000",
                "Pointer": "0.20",
                "setting_type": "636c8418bc8a8f3720133d64",
                "certificate": {
                    "type": "",
                    "number": ""
                },
                "size_name": "3.80MM",
                "stone_name": "Diamond",
                "shape_name": "Brilliant Round",
                "clarity_name": "VS2",
                "color_name": "J",
                "setting_name": "Solitaire",
                "cut_name": "Heart & Arrow",
                "stone_group_id": "63560539dcf7ec0c616fea1f",
                "stone_size_id": "63560917044ba54bde4d2a16",
                "stone_id": "6356061d2572146aa404d2d2",
                "stone_shape_id": "63560779c0d74d7f332ea992",
                "stone_clarity_id": "636c7b71671a877a9c779c81",
                "item_size_id": "63560917044ba54bde4d2a16",
                "stone_color_id": "636c7c4a28021528d413c0b9",
                "stone_cut_id": "635607932572146aa404d2d6",
                "stone_group_details": {
                    "_id": "63560539dcf7ec0c616fea1f",
                    "name": "Diamond",
                    "code": "DM",
                    "Status": "1",
                    "from": "",
                    "to": "",
                    "is_delete": 0,
                    "mixMatch": 0,
                    "createdAt": "2022-10-24T03:23:37.000Z",
                    "updatedAt": "2022-10-24T03:23:37.000Z"
                },
                "stone_size_details": {
                    "_id": "63560917044ba54bde4d2a16",
                    "name": "3.80MM",
                    "code": "3.80MM",
                    "shape": "63560779c0d74d7f332ea992",
                    "Status": "1",
                    "from": "5.20 mm",
                    "to": "",
                    "mixMatch": 1,
                    "Pointer": "0.20",
                    "Weight": "0.20",
                    "is_delete": 0,
                    "createdAt": "2022-10-24T03:40:07.000Z",
                    "updatedAt": "2022-11-10T04:44:07.000Z"
                },
                "stone_details": {
                    "_id": "6356061d2572146aa404d2d2",
                    "name": "Diamond",
                    "code": "DM",
                    "stonegroup": "63560539dcf7ec0c616fea1f",
                    "from": "Diamond",
                    "to": "",
                    "Status": "1",
                    "is_delete": 0,
                    "mixMatch": 0,
                    "createdAt": "2022-10-24T03:27:25.000Z",
                    "updatedAt": "2022-11-10T04:05:45.000Z"
                },
                "stone_shape_details": {
                    "_id": "63560779c0d74d7f332ea992",
                    "logo": "http://ananta.gismile.com/assets/images/icon/camera_profile.png",
                    "name": "Brilliant Round",
                    "code": "BR",
                    "Status": "1",
                    "from": "Brilliant Round",
                    "to": "",
                    "mixMatch": 1,
                    "is_delete": 0,
                    "createdAt": "2022-10-24T03:33:13.000Z",
                    "updatedAt": "2022-12-03T11:51:51.000Z",
                    "shapegroup": "637c8a1bd3001af825b7195a"
                },
                "stone_clarity_details": {
                    "_id": "636c7b71671a877a9c779c81",
                    "name": "VS2",
                    "code": "VS2",
                    "Status": "1",
                    "from": "",
                    "to": "",
                    "is_delete": 0,
                    "mixMatch": 0,
                    "createdAt": "2022-11-10T04:17:53.000Z",
                    "updatedAt": "2022-11-10T04:17:53.000Z"
                },
                "stone_cut_details": {
                    "_id": "635607932572146aa404d2d6",
                    "name": "Heart & Arrow",
                    "code": "HA",
                    "Status": "1",
                    "from": "Heart & Arrow",
                    "to": "",
                    "is_delete": 0,
                    "mixMatch": 0,
                    "createdAt": "2022-10-24T03:33:39.000Z",
                    "updatedAt": "2022-12-03T11:57:32.000Z"
                }
            },
            {
                "stone_group": "63560539dcf7ec0c616fea1f",
                "stone": "6356061d2572146aa404d2d2",
                "Variant": "0",
                "Align": "",
                "shape": "63560779c0d74d7f332ea992",
                "Cut": "6375cf6b3c04c604447519ea",
                "quality": "",
                "clarity": "636c7b71671a877a9c779c81",
                "color": "636c7c4a28021528d413c0b9",
                "Size": "63560917044ba54bde4d2a16",
                "Unit": "Cts",
                "Pcs": "100",
                "Cts": "0.20",
                "Price": "0.00",
                "Pointer": "0",
                "setting_type": "635648d4c0d74d7f332ea994",
                "certificate": {
                    "type": "",
                    "number": ""
                },
                "size_name": "3.80MM",
                "stone_name": "Diamond",
                "shape_name": "Brilliant Round",
                "clarity_name": "VS2",
                "color_name": "J",
                "setting_name": "Pave",
                "cut_name": "Normal Cut",
                "stone_group_id": "63560539dcf7ec0c616fea1f",
                "stone_size_id": "63560917044ba54bde4d2a16",
                "stone_id": "6356061d2572146aa404d2d2",
                "stone_shape_id": "63560779c0d74d7f332ea992",
                "stone_clarity_id": "636c7b71671a877a9c779c81",
                "item_size_id": "63560917044ba54bde4d2a16",
                "stone_color_id": "636c7c4a28021528d413c0b9",
                "stone_cut_id": "6375cf6b3c04c604447519ea",
                "stone_group_details": {
                    "_id": "63560539dcf7ec0c616fea1f",
                    "name": "Diamond",
                    "code": "DM",
                    "Status": "1",
                    "from": "",
                    "to": "",
                    "is_delete": 0,
                    "mixMatch": 0,
                    "createdAt": "2022-10-24T03:23:37.000Z",
                    "updatedAt": "2022-10-24T03:23:37.000Z"
                },
                "stone_size_details": {
                    "_id": "63560917044ba54bde4d2a16",
                    "name": "3.80MM",
                    "code": "3.80MM",
                    "shape": "63560779c0d74d7f332ea992",
                    "Status": "1",
                    "from": "5.20 mm",
                    "to": "",
                    "mixMatch": 1,
                    "Pointer": "0.20",
                    "Weight": "0.20",
                    "is_delete": 0,
                    "createdAt": "2022-10-24T03:40:07.000Z",
                    "updatedAt": "2022-11-10T04:44:07.000Z"
                },
                "stone_details": {
                    "_id": "6356061d2572146aa404d2d2",
                    "name": "Diamond",
                    "code": "DM",
                    "stonegroup": "63560539dcf7ec0c616fea1f",
                    "from": "Diamond",
                    "to": "",
                    "Status": "1",
                    "is_delete": 0,
                    "mixMatch": 0,
                    "createdAt": "2022-10-24T03:27:25.000Z",
                    "updatedAt": "2022-11-10T04:05:45.000Z"
                },
                "stone_shape_details": {
                    "_id": "63560779c0d74d7f332ea992",
                    "logo": "http://ananta.gismile.com/assets/images/icon/camera_profile.png",
                    "name": "Brilliant Round",
                    "code": "BR",
                    "Status": "1",
                    "from": "Brilliant Round",
                    "to": "",
                    "mixMatch": 1,
                    "is_delete": 0,
                    "createdAt": "2022-10-24T03:33:13.000Z",
                    "updatedAt": "2022-12-03T11:51:51.000Z",
                    "shapegroup": "637c8a1bd3001af825b7195a"
                },
                "stone_clarity_details": {
                    "_id": "636c7b71671a877a9c779c81",
                    "name": "VS2",
                    "code": "VS2",
                    "Status": "1",
                    "from": "",
                    "to": "",
                    "is_delete": 0,
                    "mixMatch": 0,
                    "createdAt": "2022-11-10T04:17:53.000Z",
                    "updatedAt": "2022-11-10T04:17:53.000Z"
                },
                "stone_cut_details": {
                    "_id": "6375cf6b3c04c604447519ea",
                    "name": "Normal Cut",
                    "code": "NC",
                    "Status": "1",
                    "from": "Normal Cut",
                    "to": "",
                    "mixMatch": 0,
                    "is_delete": 0,
                    "createdAt": "2022-11-17T06:06:35.335Z",
                    "updatedAt": "2022-12-03T11:57:10.000Z",
                    "__v": 0
                }
            },
            {
                "stone_group": "63560539dcf7ec0c616fea1f",
                "stone": "6356061d2572146aa404d2d2",
                "Variant": "0",
                "Align": "",
                "shape": "63560779c0d74d7f332ea992",
                "Cut": "6375cf6b3c04c604447519ea",
                "quality": "",
                "clarity": "636c7b8928021528d413c0b7",
                "color": "636c7c4a28021528d413c0b9",
                "Size": "63858e2ec0d74d7f332eaa15",
                "Unit": "Cts",
                "Pcs": "2",
                "Cts": "0.20",
                "Price": "0.00",
                "Pointer": "0",
                "setting_type": "635648d4c0d74d7f332ea994",
                "certificate": {
                    "type": "",
                    "number": ""
                },
                "size_name": "1.25MM",
                "stone_name": "Diamond",
                "shape_name": "Brilliant Round",
                "clarity_name": "VVS2",
                "color_name": "J",
                "setting_name": "Pave",
                "cut_name": "Normal Cut",
                "stone_group_id": "63560539dcf7ec0c616fea1f",
                "stone_size_id": "63858e2ec0d74d7f332eaa15",
                "stone_id": "6356061d2572146aa404d2d2",
                "stone_shape_id": "63560779c0d74d7f332ea992",
                "stone_clarity_id": "636c7b8928021528d413c0b7",
                "item_size_id": "63858e2ec0d74d7f332eaa15",
                "stone_color_id": "636c7c4a28021528d413c0b9",
                "stone_cut_id": "6375cf6b3c04c604447519ea",
                "stone_group_details": {
                    "_id": "63560539dcf7ec0c616fea1f",
                    "name": "Diamond",
                    "code": "DM",
                    "Status": "1",
                    "from": "",
                    "to": "",
                    "is_delete": 0,
                    "mixMatch": 0,
                    "createdAt": "2022-10-24T03:23:37.000Z",
                    "updatedAt": "2022-10-24T03:23:37.000Z"
                },
                "stone_size_details": {
                    "_id": "63858e2ec0d74d7f332eaa15",
                    "name": "1.25MM",
                    "code": "1.25MM",
                    "shape": "6378846f1ce86bed3b006ad4",
                    "Status": "1",
                    "from": "1.25MM",
                    "to": "",
                    "mixMatch": 1,
                    "Pointer": "0.01",
                    "Weight": "0.01",
                    "is_delete": 0,
                    "createdAt": "2022-11-29T04:44:30.000Z",
                    "updatedAt": "2022-11-29T05:07:38.000Z"
                },
                "stone_details": {
                    "_id": "6356061d2572146aa404d2d2",
                    "name": "Diamond",
                    "code": "DM",
                    "stonegroup": "63560539dcf7ec0c616fea1f",
                    "from": "Diamond",
                    "to": "",
                    "Status": "1",
                    "is_delete": 0,
                    "mixMatch": 0,
                    "createdAt": "2022-10-24T03:27:25.000Z",
                    "updatedAt": "2022-11-10T04:05:45.000Z"
                },
                "stone_shape_details": {
                    "_id": "63560779c0d74d7f332ea992",
                    "logo": "http://ananta.gismile.com/assets/images/icon/camera_profile.png",
                    "name": "Brilliant Round",
                    "code": "BR",
                    "Status": "1",
                    "from": "Brilliant Round",
                    "to": "",
                    "mixMatch": 1,
                    "is_delete": 0,
                    "createdAt": "2022-10-24T03:33:13.000Z",
                    "updatedAt": "2022-12-03T11:51:51.000Z",
                    "shapegroup": "637c8a1bd3001af825b7195a"
                },
                "stone_clarity_details": {
                    "_id": "636c7b8928021528d413c0b7",
                    "name": "VVS2",
                    "code": "VVS2",
                    "Status": "1",
                    "from": "",
                    "to": "",
                    "is_delete": 0,
                    "mixMatch": 0,
                    "createdAt": "2022-11-10T04:18:17.000Z",
                    "updatedAt": "2022-11-10T04:18:17.000Z"
                },
                "stone_cut_details": {
                    "_id": "6375cf6b3c04c604447519ea",
                    "name": "Normal Cut",
                    "code": "NC",
                    "Status": "1",
                    "from": "Normal Cut",
                    "to": "",
                    "mixMatch": 0,
                    "is_delete": 0,
                    "createdAt": "2022-11-17T06:06:35.335Z",
                    "updatedAt": "2022-12-03T11:57:10.000Z",
                    "__v": 0
                }
            }
        ],
        "stone_group_details": [
            {
                "_id": "63560539dcf7ec0c616fea1f",
                "name": "Diamond",
                "code": "DM",
                "Status": "1",
                "from": "",
                "to": "",
                "is_delete": 0,
                "mixMatch": 0,
                "createdAt": "2022-10-24T03:23:37.000Z",
                "updatedAt": "2022-10-24T03:23:37.000Z"
            }
        ],
        "stone_size_details": [
            {
                "_id": "63560917044ba54bde4d2a16",
                "name": "3.80MM",
                "code": "3.80MM",
                "shape": "63560779c0d74d7f332ea992",
                "Status": "1",
                "from": "5.20 mm",
                "to": "",
                "mixMatch": 1,
                "Pointer": "0.20",
                "Weight": "0.20",
                "is_delete": 0,
                "createdAt": "2022-10-24T03:40:07.000Z",
                "updatedAt": "2022-11-10T04:44:07.000Z"
            },
            {
                "_id": "63858e2ec0d74d7f332eaa15",
                "name": "1.25MM",
                "code": "1.25MM",
                "shape": "6378846f1ce86bed3b006ad4",
                "Status": "1",
                "from": "1.25MM",
                "to": "",
                "mixMatch": 1,
                "Pointer": "0.01",
                "Weight": "0.01",
                "is_delete": 0,
                "createdAt": "2022-11-29T04:44:30.000Z",
                "updatedAt": "2022-11-29T05:07:38.000Z"
            }
        ],
        "stone_shape_details": [
            {
                "_id": "63560779c0d74d7f332ea992",
                "logo": "http://ananta.gismile.com/assets/images/icon/camera_profile.png",
                "name": "Brilliant Round",
                "code": "BR",
                "Status": "1",
                "from": "Brilliant Round",
                "to": "",
                "mixMatch": 1,
                "is_delete": 0,
                "createdAt": "2022-10-24T03:33:13.000Z",
                "updatedAt": "2022-12-03T11:51:51.000Z",
                "shapegroup": "637c8a1bd3001af825b7195a"
            }
        ],
        "stone_details": [
            {
                "_id": "6356061d2572146aa404d2d2",
                "name": "Diamond",
                "code": "DM",
                "stonegroup": "63560539dcf7ec0c616fea1f",
                "from": "Diamond",
                "to": "",
                "Status": "1",
                "is_delete": 0,
                "mixMatch": 0,
                "createdAt": "2022-10-24T03:27:25.000Z",
                "updatedAt": "2022-11-10T04:05:45.000Z"
            }
        ],
        "item_size_details": [],
        "stone_clarity_details": [
            {
                "_id": "636c7b71671a877a9c779c81",
                "name": "VS2",
                "code": "VS2",
                "Status": "1",
                "from": "",
                "to": "",
                "is_delete": 0,
                "mixMatch": 0,
                "createdAt": "2022-11-10T04:17:53.000Z",
                "updatedAt": "2022-11-10T04:17:53.000Z"
            },
            {
                "_id": "636c7b8928021528d413c0b7",
                "name": "VVS2",
                "code": "VVS2",
                "Status": "1",
                "from": "",
                "to": "",
                "is_delete": 0,
                "mixMatch": 0,
                "createdAt": "2022-11-10T04:18:17.000Z",
                "updatedAt": "2022-11-10T04:18:17.000Z"
            }
        ],
        "stone_color_details": [],
        "stone_cut_details": [
            {
                "_id": "635607932572146aa404d2d6",
                "name": "Heart & Arrow",
                "code": "HA",
                "Status": "1",
                "from": "Heart & Arrow",
                "to": "",
                "is_delete": 0,
                "mixMatch": 0,
                "createdAt": "2022-10-24T03:33:39.000Z",
                "updatedAt": "2022-12-03T11:57:32.000Z"
            },
            {
                "_id": "6375cf6b3c04c604447519ea",
                "name": "Normal Cut",
                "code": "NC",
                "Status": "1",
                "from": "Normal Cut",
                "to": "",
                "mixMatch": 0,
                "is_delete": 0,
                "createdAt": "2022-11-17T06:06:35.335Z",
                "updatedAt": "2022-12-03T11:57:10.000Z",
                "__v": 0
            }
        ],
        "allocatable": true,
        "pos_Qty": 0,
        "pos_no": "",
        "location_name": "Central World",
        "currency": "THB",
        "id": "65a08e45f3ff48b82205bb36",
        "retailprice_Inc": 31800,
        "due_days": 299,
        "item_name": "Ring",
        "collection_name": "Classic Ring",
        "metal_name": "18KYG",
        "item_code": "RG",
        "collection_code": "CR",
        "metal_code": "18KYG",
        "customer_name": "",
        "size_code": "45",
        "size_name": "45",
        "stone_code": "DM",
        "stone_name": "Diamond",
        "aging": 327,
        "status_type": "stock",
        "status_stock_id": "stock"
    }
]