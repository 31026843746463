import React from 'react'

const UserIcon = (props) => {
    const { width, height } = props
    return (
        <svg width={width ? width : "24"} height={height ? height : "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2934_101550)">
                <path d="M14.5445 12.3735C16.3 11.4545 17.5 9.619 17.5 7.5C17.5 4.4625 15.0375 2 12 2C8.9625 2 6.5 4.4625 6.5 7.5C6.5 9.619 7.7 11.4545 9.4555 12.3735C5.445 13.545 2.5 17.4045 2.5 22H21.5C21.5 17.4045 18.555 13.545 14.5445 12.3735ZM7.5 7.5C7.5 5.0185 9.5185 3 12 3C14.4815 3 16.5 5.0185 16.5 7.5C16.5 9.9815 14.4815 12 12 12C9.5185 12 7.5 9.9815 7.5 7.5ZM12 13C16.3675 13 19.9765 16.5065 20.448 21H3.552C4.0235 16.5065 7.6325 13 12 13Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_2934_101550">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default UserIcon