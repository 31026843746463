import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { _Api } from "../../../common/api";
import './PolicyPage.scss';
import BottomBar from "Ecommerce/template_four/include/Common/BottomBar";
import RightArrow from "admin/common/icons/right-arrow";
export default function ProductPage() {
    const content = useRef(null);
    const { t } = useTranslation();
    const [loading, setloading] = useState(true);
    const [data, setData] = useState({});
    const fullPath = useLocation();
    const parts = fullPath.pathname.split('/');
    const navigate = useNavigate();

    const getPageData = async () => {
        setloading(true);
        try {
            let postdata = {
                "slug": parts[2],
            };
            let res = await _Api(postdata, `Cms/get-ecom-pagesmenuData`);
            if (res?.status === 200 && res?.data) {
                setData({ ...res.data });
            }
            setloading(false);
        } catch (err) {
            console.log(err, "err");
            setloading(false);
        }finally{
            setloading(false)
        }
    };

    useEffect(() => {
        if (parts[2]) {
            getPageData();
        } else {
            navigate("/");
        }
    }, [parts[2]]);

    useEffect(() => {
        if (data?.content && content.current) {
            content.current.innerHTML = data.content;
        }
    }, [data]);

    return (
        <div className="policy-page-container">
            <div className="page-path">
            <span onClick={()=>navigate(-1)} className="cursor-pointer"> {t("Home")}</span><span> <RightArrow/> </span><span className="current">{t(`${data?.title ? data?.title : "Detail Page"}`)}</span>
            </div>
            <div className="policy-page">
                { data?.content ? (
                    <div className="policy-page-content" ref={content}></div>
                ) : !loading && (
                    <div className="policy-page-empty">
                        <h1>Oops! That page can’t be found.</h1>
                        <p>It looks like nothing was found at this location. Maybe try a search?</p>
                    </div>
                )}
            </div>
            <BottomBar />
        </div>
    );
}
