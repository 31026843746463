import React, { useState, useRef, useEffect } from 'react'
import Select from "react-select"
import GraphqlPost from '../../../API/GraphqlPost.js';
import Apiauth from '../../../API/Apiauth.js';
import { error, loading, Updateloading } from "../../common/helpers/toastify";
import { useTranslation } from 'react-i18next';
import Lodingspinner from '../../../components/common/Lodingspinner'
import RightArrow from '../../common/icons/right-arrow.js';
import { Pagination } from '../../common/Pagination.js';
import TableLoader from '../../common/TableLoader.js';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ConfirmationPopup from '../Common/ConfirmationPopup.js';
import Spinner from '../../../Ecommerce/template_one/common/Spinner.js';

const PageList = () => {
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [showloader, setShowloader] = useState(false);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [limit, setLimit] = useState(100);
    const [initialPageList, setInitialPageList] = useState();
    const [dataArray, setDataArray] = useState();
    const [pageDeleteId, setPageDeleteId] = useState();
    const [states, setstates] = useState({
        totalPages: 20,
        currentPage: 1,
    });
    const { totalPages, currentPage } = states;

    const [searchBar, setSearchBar] = useState()

    useEffect(() => {
        getPageList(1, 100, "");
    }, [])


    const getPageList = async (current, firstlimit, search) => {
        setShowloader(true)
        try {
            var skipNumber = current ? (current - 1) * limit : 0;
            let postdata = {
                "location_id": state?.location,
                "Limit": firstlimit ? firstlimit : limit,
                "skip": skipNumber,
                "search": search ? search : ""
            }
            let result = await Apiauth(postdata, `cms/get-pages-list`)
            if (result.code === 200) {
                setInitialPageList(result?.data)
                setDataArray(result?.data)
                setShowloader(false)
                setstates({
                    ...states,
                    totalPages: Math.ceil(result?.total / postdata.Limit),
                    currentPage: current ? current : 1
                });
            }
        } catch (err) {
            console.log(err, "error")
        }
    }

    const selectOptions = [
        { label: "100", value: "100" },
        { label: "200", value: "200" },
        { label: "300", value: "300" }
    ]

    const getlimit = (limit) => {
        setLimit(limit);
        getPageList(1, limit);
        setstates({ ...states, currentPage: 1 });
    };

    const handlePaginations = (current) => {
        setstates({ ...states, currentPage: current });
        getPageList(current, "");
    };

    // const allData = [
    //   {title: "Title1", url: "url1", lastmodify: "2024/05/23", status: 1 },
    //   {title: "Title2", url: "url2", lastmodify: "2024/05/23", status: 0 },
    //   {title: "Title3", url: "url3", lastmodify: "2024/05/23", status: 1 },
    //   {title: "Title4", url: "url4", lastmodify: "2024/05/23", status: 0 },
    //   {title: "Title5", url: "url5", lastmodify: "2024/05/23", status: 1 },
    //   {title: "Title6", url: "url6", lastmodify: "2024/05/23", status: 0 },
    //   {title: "Title7", url: "url7", lastmodify: "2024/05/23", status: 0 },
    //   {title: "Title8", url: "url8", lastmodify: "2024/05/23", status: 0 },
    //   {title: "Title9", url: "url9", lastmodify: "2024/05/23", status: 0 },
    //   {title: "Title10", url: "url10", lastmodify: "2024/05/23", status: 1 },
    //   {title: "Title11", url: "url11", lastmodify: "2024/05/23", status: 1 },
    //   {title: "Title12", url: "url12", lastmodify: "2024/05/23", status: 1 },
    // ]



    const showPopup = () => {
        setIsPopupVisible(true);
    };

    const handleConfirm = () => {
        setIsPopupVisible(false);
        handleDelete();
    };

    const handleCancel = () => {
        setIsPopupVisible(false);
        setPageDeleteId(null);
    };



    const handleDelete = async () => {
        console.log(pageDeleteId, "item to be deleted")
        try {
            let postdata = {
                "id": pageDeleteId
            }
            let result = await Apiauth(postdata, `cms/delete-page`)
            if (result.code === 200) {
                getPageList();
            }
        } catch (err) {
            console.log(err, "Error")
        }
    }

    return (
        <div className="main-content-wrapper-body">
            <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
                <div className="main-content-wrapper-body-top-bar-left">
                    <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0 cursor-pointer" onClick={() => navigate("/app/admin/settings/cmslocations")}>{t("CMS")} <RightArrow /> <span className="cursor-pointer">{t("Pages")}</span></h1>
                </div>
                <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">
                    <Select
                        className="limit-select mx-3"
                        options={selectOptions}
                        value={{ label: limit, value: limit }}
                        onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                    />
                    <div className="com-search-bar position-relative">
                        <img className="com-search-bar-search-img"
                            src={base_assets + "images/admin/icons/gray-search.png"} alt="" />
                        <input placeholder="Search Page"
                            className="h-100 border-0 bg-transparent"
                            value={searchBar}
                            onKeyPress={(e) => e.key === 'Enter' && getPageList(1, 100, e.target.value)}
                            onChange={(e) => setSearchBar(e.target.value)}
                        />
                        {/* {filtername ?  */}
                        {searchBar?.length ?
                            <img onClick={() => { setSearchBar(""); getPageList(1, 100, "") }} className="search-bar-clear position-absolute end-0 mt-2"
                                // onClick={(e) => {setFiltername(""); scrvicelabour(1, 100, "")}} 
                                src={base_assets + 'images/icons/False.png'} alt="" /> : ""}
                        {/* : ""} */}
                        {/* : ""} */}

                    </div>

                    <Link to={"add"} state={{ location: state?.location }} className={"com-plus-text-btn text-white text-center fw-semibold d-flex align-items-center border-0 rounded-1 ms-3"}
                    >
                        <img className="me-2" src={base_assets + "images/admin/icons/add-icon-white.png"} alt="" />{t("Create")}
                    </Link>
                </div>
            </div>
            <div className="row">
                <div className={"col-md-12"}>
                    <div
                        className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
                    >
                        <table
                            id="my_customer"
                            className="com-custom-table-tag table align-middle">
                            <thead>
                                <tr className="text-nowrap align-middle">
                                    <th>#</th>
                                    <th className="user-name-width">{t("Title")}
                                        {/* <div className="dropdown d-inline-block com-custom-table-tag-filter">
                        <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                          <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                        </button>
                        <div className={"dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"}
                          ref={dropdownRefname}>
                          <div className="asc-des-order">
                            <div onClick={() => { setShortobj({ key: "name", value: 1 }); hendledropdownhide("name") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                            <div onClick={() => { setShortobj({ key: "name", value: -1 }); hendledropdownhide("name") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-search">
                            <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                              <img src={base_assets + "images/icons/search-icon.png"} alt="" />
                              <input className="border-0" type="text" placeholder="Search"
                                onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "name")}
                                onChange={(e) => setSearchVal(e.target.value)} />
                            </div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-select">
                            <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                              <div>
                                <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="name"
                                  onChange={(e) => handleCheckAllChange(e, "name")}
                                  checked={namechecked.length === namefilter.length} />
                                <label className="form-check-label cursor-pointer fw-semibold" htmlFor="name">{t("Select All")}</label>
                              </div>
                              <label>{namechecked.length}/{namefilter.length} {t("Selected")}</label>
                            </div>
                            <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                              {namefilter?.length ? namefilter.map((result, key) => {
                                return (
                                  <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                    <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={key + "name"}
                                      checked={
                                        namechecked ? namechecked.includes(result) : ""
                                      }
                                      onChange={(e) => handlefilerChange(e, result, "name")} />
                                    <label className="form-check-label cursor-pointer" htmlFor={key + "name"}>{result}</label>
                                  </li>
                                )
                              }) : ""}

                            </ul>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2"
                          >
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                              onClick={() => { setNamehecked([]); setShortobj({}); hendledropdownhide("name") }}>{t("Clear")}</button>
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { scrvicelabour(); hendledropdownhide("name") }}>{t("Apply")}</button>
                          </div>
                        </div>
                      </div> */}
                                    </th>
                                    <th>{t("URL")}
                                        {/* <div className="dropdown d-inline-block com-custom-table-tag-filter">
                        <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                          <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                        </button>
                        <div className="dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden code"
                          ref={dropdownRefcode} >
                          <div className="asc-des-order">
                            <div onClick={() => { setShortobj({ key: "code", value: 1 }); hendledropdownhide("code") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                            <div onClick={() => { setShortobj({ key: "code", value: -1 }); hendledropdownhide("code") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-search">
                            <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                              <img src={base_assets + "images/icons/search-icon.png"} alt="" />
                              <input className="border-0" type="text" placeholder="Search"
                                onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "code")}
                                onChange={(e) => setSearchVal(e.target.value)} />
                            </div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-select">
                            <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                              <div>
                                <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="code"
                                  onChange={(e) => handleCheckAllChange(e, "code")}
                                  checked={codechecked.length === codefilter.length} />
                                <label className="form-check-label cursor-pointer fw-semibold" htmlFor="code">{t("Select All")}</label>
                              </div>
                              <label>{codechecked.length}/{codefilter.length} {t("Selected")}</label>
                            </div>
                            <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                              {codefilter?.length ? codefilter.map((result, key) => {
                                return (
                                  <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                    <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={"code" + key}
                                      checked={
                                        codechecked ? codechecked.includes(result) : ""
                                      }
                                      onChange={(e) => handlefilerChange(e, result, "code")} />
                                    <label className="form-check-label cursor-pointer" htmlFor={"code" + key}>{result}</label>
                                  </li>
                                )
                              }) : ""}

                            </ul>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2">
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                              onClick={() => { setCodechecked([]); setShortobj({}); hendledropdownhide("code") }}>{t("Clear")}</button>
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { scrvicelabour(); hendledropdownhide("code") }}>{t("Apply")}</button>
                          </div>
                        </div>
                      </div> */}
                                    </th>
                                    <th>{t("Last Modified Date")} </th>
                                    <th>{t("Status")}
                                        {/* <div className="dropdown d-inline-block com-custom-table-tag-filter">
                        <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                          <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                        </button>
                        <div className="dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"
                          ref={dropdownRefstatus}
                        >
                          <div className="asc-des-order">
                            <div onClick={() => { setShortobj({ key: "Status", value: -1 }); hendledropdownhide("status") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                            <div onClick={() => { setShortobj({ key: "Status", value: 1 }); hendledropdownhide("status") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-select">
                            <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                              {statusfilter?.length ? statusfilter.map((result, key) => {
                                return (
                                  <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                    <input className="fill-com-check form-check-input radio d-inline-block me-2 cursor-pointer" name="mixmatchradio" type="radio" id={key + "status"} value={result?.value}
                                      onClick={(e) => setStatuschecked(result?.value)} />
                                    <label className="form-check-label cursor-pointer" htmlFor={key + "status"}>{result?.lable}</label>
                                  </li>
                                )
                              }) : ""}

                            </ul>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2">
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                              onClick={(e) => { setStatuschecked(""); setShortobj({}); hendledropdownhide("status") }} >{t("Clear")}</button>
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { scrvicelabour(); hendledropdownhide("status") }}>{t("Apply")}</button>
                          </div>
                        </div>
                      </div> */}
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>

                                {dataArray?.length ?
                                    dataArray.map((result, key) => {
                                        return (
                                            <tr className="text-nowrap" key={key}>
                                                <td>{key + 1}</td>
                                                <td className='link-color cursor-pointer text-decoration-none'><Link className='link-color text-decoration-none' to={`add/${result?.id}`} state={{ pageDetails: result, location: state?.location, pageId: result?.id }}>  {result?.title ? result?.title : ""}</Link></td>
                                                <td>{result?.url ? result?.url : ""}</td>
                                                <td>{result?.lastModified ? result?.lastModified : ""}</td>
                                                <td>
                                                    {result?.status === 1 ?
                                                        <span className="active-badge  rounded-pill px-2 py-1 fw-semibold"> <span className="active-badge-dot me-2"></span>{t("Active")}</span>
                                                        :
                                                        <span className="inactive-badge  rounded-pill px-2 py-1 fw-semibold"> <span className="inactive-badge-dot me-2"></span>{t("InActive")}</span>
                                                    }

                                                </td>
                                                <td className="text-center">
                                                    {result?.is_delete === 1 ?
                                                        <>
                                                            <Link to={`add/${result?.id}`} state={{ pageDetails: result, location: state?.location, pageId: result?.id }}>
                                                                <img
                                                                    // onClick={() => { setEditItem(true); setDataid(result?.id); setCreate(false) }}
                                                                    className={"cursor-pointer me-4"}
                                                                    src={base_assets + "images/admin/icons/Edit.png"} alt="" />
                                                            </Link>
                                                            <img
                                                                className='cursor-pointer'
                                                                src={base_assets + "images/admin/icons/Delete.png"} alt=""
                                                                onClick={() => { showPopup(); setPageDeleteId(result.id) }}
                                                            />
                                                        </>
                                                        : null}
                                                </td>
                                            </tr>
                                        )
                                    }) :
                                    <tr>

                                        <td colSpan="50" className="text-center">  {!dataArray?.length && showloader ? <Spinner /> : t("No Data Available In Table")}</td>

                                    </tr>
                                }
                            </tbody>
                        </table>

                    </div>

                    {dataArray?.length > 0 ? (
                        <Pagination
                            total={totalPages}
                            current={currentPage}
                            pagination={(crPage) => handlePaginations(crPage)}
                        />
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <ConfirmationPopup
                isVisible={isPopupVisible}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                message="Delete This Page ?"
            />
        </div>
    )
}

export default PageList
