import React from 'react'
import { useTranslation } from 'react-i18next';
import './Common.scss'


export default function ConfirmationPopup({ isVisible, onConfirm, onCancel, message, sub, leftbtn, rightbtn }) {
    const {t} = useTranslation();
    if (!isVisible) return null;
  return (
    <div className="confirmationpopup" onClick={onCancel}>
    <div className="confirmationpopup-inner" onClick={(e)=>e.stopPropagation()}>
        {/* <button
                      type="button"
                      className="closebtn shadow-none border-0 bg-transparent"
                      onClick={onCancel}
                    >
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 13L7 7M7 7L1 1M7 7L13 1M7 7L1 13" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </button> */}
     <div className='confirmationpopup-inner-header'>
        <div className='confirmationpopup-inner-header-title'>{t("Confirm")}</div>
        {sub ? <div className='confirmationpopup-inner-header-subtitle'>{ sub }</div> : null}
     </div>
      <div className="confirmationpopup-inner-footer">
        <button className="confirmationpopup-inner-footer-buttons no" onClick={onCancel}>{ leftbtn ? leftbtn : t("No")}</button>
        <button className="confirmationpopup-inner-footer-buttons yes" onClick={onConfirm}>{rightbtn ? rightbtn : t("Yes")}</button>
      </div>
    </div>
  </div>
  // <div
  //   className="modal fade confirmpopop"
  //   >
  //       <div className="modal-dialog modal-dialog-centered confirmpopop-inner" role="document">
  //           <div className={`modal-content confirmpopop-inner-section`}>
  //               <div className='confirmpopop-inner-section-title'>{message}</div>
  //               {sub ? <div className='confirmpopop-inner-section-subtitle'>{ sub }</div> : null}
  //               <div className='confirmpopop-inner-section-footer'>
  //                   <button className='cancel' onClick={onCancel}>{ leftbtn ? leftbtn : t("No")}</button>
  //                   <button className='confirm'  onClick={onConfirm}>{rightbtn ? rightbtn : t("Yes")}</button>
  //               </div>
  //           </div>
  //       </div>
  //   </div>
  )
}
