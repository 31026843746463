import DeleteIcon from 'Ecommerce/template_three/assets/icons/DeleteIcon';
import React from 'react'
import { useTranslation } from 'react-i18next';
import './Delete.scss'

export default function Deletedeletepopup({ isVisible, onConfirm, onCancel, message}) {
    const { t } = useTranslation();
    if (!isVisible) return null;
  return (
    <div className="commondelete" onClick={onCancel}>
    <div className="commondelete-inner" onClick={(e)=>e.stopPropagation()}>
     <div className='commondelete-inner-deleteheader'>
        <div className='commondelete-inner-deleteheader-bigtitle'>
            <DeleteIcon type='curved'/>
            <span className='commondelete-inner-deleteheader-bigtitle-text'>{t("Remove Items")}</span>
        </div>
        <div className='commondelete-inner-deleteheader-subtitle'>{("Would you like to remove the item(s) from your shopping cart?")}</div>
     </div>
      <div className="commondelete-inner-footer">
        <button className="commondelete-inner-footer-buttons no" onClick={onCancel}>{t("Cancel")}</button>
        <button className="commondelete-inner-footer-buttons remove" onClick={onConfirm}>{t("Remove")}</button>
      </div>
    </div>
  </div>
  )
}
